import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { DataGrid } from "@material-ui/data-grid";
import { GetNewsList, PostNews, DeleteNews } from "~stores/AdminNewsSetting";

const LabelFromType = (type) => {
    switch (type) {
        case "1":
            return "通常";
        case "2":
            return "ブログ更新";
        default:
            return "不明";
    }
};
const AdminNewsSetting = () => {
    const dispatch = useDispatch();
    const NewsSettingState = useSelector((state) => state.AdminNewsSetting);
    const [search_type, set_search_type] = useState("");

    useEffect(() => {
        const params = {
            search_type: search_type,
        };
        dispatch(GetNewsList(params));
    }, []);

    const HandleDeleteNews = (row) => {
        const params = {
            page: NewsSettingState.currentPage,
            search_type: search_type,
            news_id: row.id,
        };
        dispatch(DeleteNews(params));
    };

    const columns = [
        { field: "id", headerName: "ID", width: 120 },
        {
            field: "news",
            headerName: "本文",
            flex: true,
        },
        {
            field: "link",
            headerName: "Link",
            flex: true,
        },
        {
            field: "type",
            headerName: "タイプ",
            width: 120,
            valueGetter: (params) => LabelFromType(params.row?.type),
        },
        {
            field: "",
            headerName: "",
            width: 100,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => HandleDeleteNews(params.row)}
                >
                    削除
                </Button>
            ),
        },
    ];

    const HandleCreate = (e) => {
        e.preventDefault();
        const elem = e.target.elements;
        const params = {
            search_type: search_type,
            page: NewsSettingState.currentPage,
            create_data: {
                news: elem?.news?.value,
                link: elem?.link?.value,
                type: "1",
            },
        };

        dispatch(PostNews(params)).then((data) => {
            document.create_news_form.reset();
        });
    };

    const onPageChange = (page) => {
        const params = {
            page: page + 1,
            search_type: search_type,
        };
        dispatch(GetNewsList(params));
    };

    return (
        <React.Fragment>
            <ButtonNavi left_label="Home画面" left_link="/AdminHome" />
            <h1>ニュース設定</h1>
            <form
                onSubmit={HandleCreate}
                autoComplete="off"
                name="create_news_form"
                key="1"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        border: "solid 1px",
                        padding: "2rem",
                        marginBottom: "2rem",
                        margin: "2%",
                        width: "96%",
                    }}
                >
                    <TextField
                        style={{ marginBottom: "1rem" }}
                        fullWidth
                        label="内容"
                        required
                        name="news"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    ></TextField>
                    <TextField
                        style={{ marginBottom: "1rem" }}
                        fullWidth
                        type="url"
                        label="リンク"
                        name="link"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    ></TextField>

                    <Button variant="outlined" type="submit">
                        投稿
                    </Button>
                </Grid>
            </form>
            <Grid style={{ padding: "0rem 2rem" }}>
                <TextField
                    select
                    value={search_type}
                    SelectProps={{ native: true }}
                    onChange={(e) => {
                        set_search_type(e.target.value);
                        const params = {
                            search_type: e.target.value,
                        };
                        dispatch(GetNewsList(params));
                    }}
                >
                    <option value="">すべて</option>
                    <option value="1">通常</option>
                    <option value="2">ブログ更新</option>
                </TextField>
            </Grid>
            <Container>
                <DataGrid
                    autoHeight
                    rows={NewsSettingState?.news_list}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    // checkboxSelection
                    disableSelectionOnClick
                    onPageChange={onPageChange}
                    paginationMode="server"
                    page={NewsSettingState.currentPage - 1}
                    rowCount={NewsSettingState.total}
                />
            </Container>
        </React.Fragment>
    );
};

export default AdminNewsSetting;
