import React from "react";
import { InputMaxLength } from "~CJS/atoms/TextFields";
import InputImagePreview from "~CJS/atoms/InputImagePreview";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import SelectPrefectures from "~CJS/atoms/SelectPrefectures";

const CompanyInput = (props) => {
  const { disabled, data, ...other } = props;

  const email_address = data?.email_address;
  const password = data?.password;

  const company_page = data?.company_page;
  const company_name = data?.company_name ? data.company_name : "";
  const address_1 = data?.address_1;
  const address_2 = data?.address_2;
  const staff_email_address = data?.staff_email_address;
  const image_big = data?.image_big;
  const image = data?.image;
  const city = data?.city;
  const prefectures = data?.prefectures ? data.prefectures : "";

  return (
    <React.Fragment>
      <InputImagePreview comment={false} height={200} image_big={image_big} image={image} disabled={disabled} />
      <Box
        style={{
          border: "solid 1px",
          padding: "1rem"
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Typography>アカウント情報</Typography>
        </Box>
        <InputMaxLength
          label="メールアドレス"
          name="email_address"
          inputProps={{ maxLength: 255 }}
          disabled={disabled}
          value={email_address}
          required
        />
        <InputMaxLength
          label="パスワード"
          type="pasword"
          name="password"
          inputProps={{ maxLength: 255 }}
          disabled={disabled}
          value={password}
          required
        />
      </Box>
      <InputMaxLength
        label="会社名"
        name="company_name"
        inputProps={{ maxLength: 255 }}
        disabled={disabled}
        value={company_name}
        required
      />

      <SelectPrefectures
        disabled={disabled}
        value={prefectures}
        prefectures={prefectures}
        required
        city={{ defaultValue: city }}
      />

      <InputMaxLength
        label="市から下の住所"
        name="address_1"
        inputProps={{ maxLength: 255 }}
        disabled={disabled}
        value={address_1}
      />
      <InputMaxLength
        label="ビル名・階数など"
        name="address_2"
        inputProps={{ maxLength: 255 }}
        disabled={disabled}
        value={address_2}
      />
      <InputMaxLength
        label="企業ホームページ"
        name="company_page"
        inputProps={{ maxLength: 255 }}
        disabled={disabled}
        value={company_page}
      />
      <InputMaxLength
        label="担当者のメールアドレス"
        name="staff_email_address"
        inputProps={{ maxLength: 255 }}
        disabled={disabled}
        value={staff_email_address}
        required
      />
    </React.Fragment>
  );
};

export default CompanyInput;
