import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Stateの初期状態
const initialState = {};

// マスコット一覧取得
export const CreateQR = createAsyncThunk(
    "管理者/加盟店QRコード作成",
    async (params, thunkAPI) => {
        let api_key = thunkAPI.getState().Information.admin.admin_token;

        if (!api_key) api_key = "";

        try {
            const res = await axios.get(
                "/api/shop_qr_create?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );
            return res.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "AdminShopQRCreate",
    initialState,
    reducers: {
        set_shop_qr_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        [CreateQR.rejected]: (state, action) => {
            if (action.payload) {
                action.payload.error_message =
                    "時間をおいてもう一度お試しください。";
            } else {
                action.payload = {
                    error_message: "時間をおいてもう一度お試しください。",
                };
            }
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_shop_qr_value } = slice.actions;
