import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { LinkButton } from "~CJS/atoms/LinkButton";
import Button from "@material-ui/core/Button";

import { useDispatch, useSelector } from "react-redux";

import { BackButton } from "~CJS/atoms/BackButton";
// import FixedBand from "~/atoms/FixedBand";

import OfferDetailInput from "~CJS/block/OfferDetailInput";
// import CompanyCard from "~CJS/block/CompanyCard";
import { initialize, OfferCreate, GetDetail } from "~stores/TALENTBANK/Offer";
import { ButtonNavi } from "~CJS/block/ButtonNavi";

const OfferNew = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(initialize());
        // dispatch(GetDetail(offer_id));
    }, []);

    // const OfferDetailState = useSelector((state) => state.OfferDetail);

    const handleSubmit = (e) => {
        e.preventDefault();
        const elements = e.target?.elements;

        const getValue = (name) => {
            let value = "";
            if (elements[name]?.value) {
                value = elements[name]?.value;
            }

            return value;
        };

        const offer_info = {
            company_id: getValue("company_id"),
            offer_title: getValue("offer_title"),
            prefectures: getValue("prefectures"),
            city: getValue("city"),
            guarantee: getValue("guarantee"),
            recruitment_count: getValue("recruitment_count"),
            job_start_time: getValue("job_start_time"),
            job_end_time: getValue("job_end_time"),
            job_date: getValue("job_date"),
            job_detail: getValue("job_detail"),
            image_big: getValue("image_big"),
            image: getValue("image"),
            status: getValue("status"),
            entry_close: getValue("entry_close"),
        };

        const params = {
            offer_info: offer_info,
            input_option: [],
            category: getValue("category"),
        };

        dispatch(OfferCreate(params)).then((action) => {
            if (action.payload.result == "1") {
                history.push(
                    `/OfferDetail/${action.payload?.offer_info?.offer_id}`
                );
                alert("お仕事を作成しました。");
            }
        });
    };

    return (
        <React.Fragment>
            <ButtonNavi left_label="お仕事検索" left_link="/OfferSearch" />

            <Typography variant="h1">お仕事作成</Typography>

            <Container>
                <form onSubmit={handleSubmit}>
                    <OfferDetailInput Create/>
                    <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        type="submit"
                    >
                        作成
                    </Button>
                </form>
            </Container>
        </React.Fragment>
    );
};

export default OfferNew;
