import React, { useState, useEffect } from "react";

import HttpFunction from "../utils/HttpFunction";
import { set_guide } from "../../stores/Guide";
import { useSelector, useDispatch } from "react-redux";

import Accordion from "@material-ui/core/Accordion";

import {
    makeStyles,
    Typography,
    AccordionSummary,
    AccordionDetails,
    CircularProgress,
    Grid,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function groupBy(objectArray, property) {
    return objectArray.reduce(function (acc, obj) {
        let key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
}

const Guide = () => {
    const guideState = useSelector((state) => state.Guide);
    const informationState = useSelector((state) => state.Information);
    const dispatch = useDispatch();

    const GetGuide = () => {
        const api_key = "";
        const params = "";
        let type = "how_to_nologin";

        switch (true) {
            case informationState.user.user_login:
                type = "how_to_general";
                break;
            case informationState.shop.shop_login:
                type = "how_to_shop";
                break;
            case informationState.admin.admin_login:
                type = "how_to_all";
                break;
            default:
                break;
        }
        HttpFunction("GET", "/api/" + type, params, api_key)
            .then((data) => {
                dispatch(
                    set_guide(["guide_data_list", groupBy(data, "title2")])
                );

                dispatch(set_guide(["guide_data", data]));
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    const GetDetail = (id) => {
        const api_key = "";
        const params = { id: id };

        HttpFunction("GET", "/api/how_to_detail", params, api_key)
            .then((data) => {
                if (!data.text) data.text = "ERROR:データがありません";

                dispatch(set_guide([id, data, "guide_detail"]));
            })
            .catch((err) => {
                dispatch(
                    set_guide([
                        id,
                        { text: "ERROR:情報取得に失敗しました。" },
                        "guide_detail",
                    ])
                );
                // console.log(err);
            });
    };

    useEffect(() => {
        GetGuide();
    }, []);

    const GuideText = (props) => {
        const id = props.id;

        const text =
            guideState.guide_detail[id] && guideState.guide_detail[id].text
                ? guideState.guide_detail[id].text
                : "";

        return (
            <>
                <div
                    className="html-preview"
                    dangerouslySetInnerHTML={{
                        __html: text ? text.replace(/\n/g, "<br>") : "",
                    }}
                ></div>
            </>
        );
    };
    const OnDetail = (id) => {
        if (guideState.guide_detail[id]) return;
        GetDetail(id);
    };

    const TypographyDom = (props) => {
        return (
            <>
                {guideState.guide_detail[props.id] ? (
                    <GuideText id={props.id} />
                ) : (
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                    </Grid>
                )}
            </>
        );
    };

    return (
        <React.Fragment>
            <h1 style={{ fontSize: "2rem" }}>利用方法</h1>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                {/* <img
                    src="/images/Common/使い方漫画.png"
                    style={{ width: "100%" }}
                /> */}
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/utOQJEtSLQo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ucRrvE-Y68E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </Grid>

            {/* <div style={{ margin: "0.5rem" }}>
                {Object.keys(guideState.guide_data_list).map((value, index) => (
                    <React.Fragment key={index}>
                        <Accordion>
                            <AccordionSummary
                                onClick={() => OnDetail(value.id)}
                                expandIcon={<ExpandMoreIcon />}
                                style={{ backgroundColor: "mistyrose" }}
                            >
                                {value.title1}
                            </AccordionSummary>
                            <AccordionDetails
                                style={{
                                    display: "block",
                                    padding: "1rem",
                                }}
                            >
                                <>
                                    <TypographyDom id={value.id} />
                                </>
                            </AccordionDetails>
                        </Accordion>
                    </React.Fragment>
                ))}
            </div> */}

            <div style={{ margin: "0.5rem" }}>
                {guideState.guide_data.map((value, index) => (
                    <React.Fragment key={index}>
                        <Accordion>
                            <AccordionSummary
                                onClick={() => OnDetail(value.id)}
                                expandIcon={<ExpandMoreIcon />}
                                style={{ backgroundColor: "mistyrose" }}
                            >
                                {value.title1}
                            </AccordionSummary>
                            <AccordionDetails
                                style={{ display: "block", padding: "1rem" }}
                            >
                                <>
                                    <TypographyDom id={value.id} />
                                </>
                            </AccordionDetails>
                        </Accordion>
                    </React.Fragment>
                ))}
            </div>
        </React.Fragment>
    );
};

export default Guide;
