import React, { useEffect, useRef } from "react";
import Image from "react-bootstrap/Image";
import { makeStyles } from "@material-ui/core/";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Chip from "@material-ui/core/Chip";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import {
    GetMascotList,
    set_admin_mascot,
    mascot_input_initialize,
} from "../../stores/AdminMascot";

const useStyles = makeStyles((theme) => ({
    card_box: {
        width: "360px",
        padding: "5px",
    },
    card_action_area: {
        height: "150px",
        "&:hover": {
            backgroundColor: "#ffc0cb4d",
        },
    },
    image_box: {
        width: "120px",
        height: "inherit",
        padding: "20px 5px",
        display: "inline-block",
        verticalAlign: "top",
    },
    info_box: {
        width: "230px",
        height: "inherit",
        padding: "5px",
        display: "inline-block",
        verticalAlign: "top",
    },
    info_box_in: {
        display: "flex",
        flexFlow: "column",
        justifyContent: "space-between",
        height: "inherit",
    },
    card_image: {
        display: "block",
        position: "relative",

        "&:before": {
            content: '""',
            display: "block",
            paddingTop: "100%",
        },

        "& img": {
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
        },
    },
    card_name: {
        margin: "0",
        paddingLeft: "10px",
        fontSize: "1.2rem",
        fontWeight: "bold",
    },
    card_point: {
        textAlign: "right",
        paddingRight: "10px",
        fontSize: "1rem",
        fontWeight: "bold",
    },
}));

const MascotCard = (props) => {
    const { mascotData, ...other } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const handleClick = () => {
        dispatch(set_admin_mascot(["mascot_id", mascotData.mascot_id]));
        dispatch(
            set_admin_mascot([
                "mascot_image_small",
                mascotData.mascot_image_small,
            ])
        );
        dispatch(
            set_admin_mascot(["mascot_image_big", mascotData.mascot_image_big])
        );
        dispatch(set_admin_mascot(["mascot_name", mascotData.mascot_name]));
        dispatch(set_admin_mascot(["mascot_point", mascotData.mascot_point]));
        dispatch(set_admin_mascot(["draw_title", mascotData.draw_title]));
        dispatch(set_admin_mascot(["draw_detail", mascotData.draw_detail]));
        dispatch(set_admin_mascot(["mascot_url", mascotData.mascot_url]));
        dispatch(set_admin_mascot(["memo", mascotData.memo]));
        dispatch(
            set_admin_mascot([
                "release_start_date",
                mascotData.release_start_date,
            ])
        );
        dispatch(
            set_admin_mascot(["release_end_date", mascotData.release_end_date])
        );
        dispatch(set_admin_mascot(["control_kubun", mascotData.control_kubun]));
        dispatch(set_admin_mascot(["active_flag", mascotData.active_flag]));
        dispatch(set_admin_mascot(["updated_at", mascotData.updated_at]));
        history.push(`/AdminMascotDetail/${mascotData.mascot_id}`);
    };

    return (
        <Card onClick={handleClick}>
            <CardActionArea className={classes.card_action_area}>
                <div className={classes.image_box}>
                    <div className={classes.card_image}>
                        <Image
                            src={
                                mascotData.mascot_image_small
                                    ? mascotData.mascot_image_small
                                    : "null"
                            }
                            fluid
                            onError={(e) => {
                                e.target.onerror = "null";
                                e.target.src = "/images/no_img.png";
                            }}
                        />
                    </div>
                </div>
                <div className={classes.info_box}>
                    <div className={classes.info_box_in}>
                        <div style={{ textAlign: "right" }}>
                            <Chip
                                label={
                                    mascotData.active_flag === "0"
                                        ? "公開中"
                                        : "非公開"
                                }
                                size="small"
                                color={
                                    mascotData.active_flag === "0"
                                        ? "primary"
                                        : "default"
                                }
                            />
                        </div>
                        <p className={classes.card_name}>
                            {mascotData.mascot_name}
                        </p>
                        <p className={classes.card_point}>
                            {mascotData.mascot_point} P
                        </p>
                    </div>
                </div>
            </CardActionArea>
        </Card>
    );
};

const AdminMascotList = () => {
    const thisState = useSelector((state) => state.AdminMascot);
    const dispatch = useDispatch();
    const classes = useStyles();
    const isFirstRendering = useRef(true);

    // 新規登録ボタン押下時
    const handleClickCreate = () => {
        dispatch(mascot_input_initialize());
    };

    const handleChangePage = (e, value) => {
        dispatch(set_admin_mascot(["page", value]));
    };

    // 初回レンダリング後
    useEffect(() => {
        let params;
        if (thisState.back_flag) {
            // マスコット新規登録/詳細画面から戻ってきたときは前に開いていたページを再表示
            params = { page: thisState.page };
            dispatch(set_admin_mascot(["back_flag", false]));
        } else {
            // ホーム画面から来たときは1ページ目を表示
            params = { page: 1 };
            dispatch(set_admin_mascot(["page", 1]));
        }
        dispatch(GetMascotList(params));

        // 初回レンダリングフラグをfalseに
        isFirstRendering.current = false;
    }, []);

    // 2回目以降のレンダリング後
    useEffect(() => {
        if (!isFirstRendering.current) {
            const params = {
                page: thisState.page,
            };
            dispatch(GetMascotList(params));
        }
    }, [thisState.page]);

    return (
        <>
            <ButtonNavi
                left_label="HOME"
                left_link="/AdminHome"
                right_label="新規登録"
                right_link="/AdminMascotNew"
                right_props={{
                    onClick: handleClickCreate,
                }}
            />

            <h1>ログインボーナス設定</h1>

            <Grid container style={{ width: "1080px", margin: "auto" }}>
                {thisState.mascot_list.length > 0 ? (
                    thisState.mascot_list.map((data) => (
                        <div key={data.mascot_id} className={classes.card_box}>
                            <MascotCard mascotData={data} />
                        </div>
                    ))
                ) : (
                    <>
                        {thisState.search_error ? (
                            <p>{thisState.error_message}</p>
                        ) : (
                            <p>マスコットが登録されていません</p>
                        )}
                    </>
                )}
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Pagination
                    count={thisState.last_page}
                    variant="outlined"
                    color="primary"
                    page={thisState.page}
                    onChange={handleChangePage}
                />
            </Grid>
        </>
    );
};

export default AdminMascotList;
