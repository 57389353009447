import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { LinkPanelBig } from "~CJS/atoms/LinkButton";
import { set_alert } from "../../stores/ErrorAlert";
import {
    set_value,
    GetSetting,
    UpdateSetting,
    initialize,
} from "../../stores/AdminPointSetting";
import ModalProgress from "../utils/ModalProgress";
import { InputText } from "../utils/InputText";

import {
    Grid,
    Button,
    Dialog,
    TextField,
    ButtonBase,
    Divider,
} from "@material-ui/core";

const AdminPointSetting = () => {
    const thisState = useSelector((state) => state.AdminPointSetting);
    const dispatch = useDispatch();
    const history = useHistory();

    const onAdminRedeemingPointList = () => {
        history.push("/AdminRedeemingPointList");
    };

    useEffect(() => {
        dispatch(initialize()), dispatch(GetSetting());
    }, []);

    useEffect(() => {
        dispatch(set_alert(thisState.error_message));
    }, [thisState.error_message]);

    useEffect(() => {
        // console.log(thisState.visit_point);
    }, [thisState.visit_point]);

    const SendSettings = () => {
        dispatch(UpdateSetting());
    };

    const SetValue = (e) => {
        dispatch(set_value([e.currentTarget.name, e.target.value]));
    };
    return (
        <Container>
            <ButtonNavi left_label="Home画面" left_link="/AdminHome" />
            <h1>パカッティー設定</h1>
            <p style={{ textAlign: "center" }}>半角数字で入力してください。</p>
            <ModalProgress open={thisState.modal_progress} />
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
            >
                <Grid container item xs={3} alignItems="flex-end"></Grid>
                <Grid
                    container
                    item
                    xs={4}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    変更設定
                </Grid>
                <Grid container item xs={2} alignItems="flex-end"></Grid>

                <Grid container item xs={3} alignItems="center">
                    現在の設定
                </Grid>
            </Grid>
            <PointInput
                defaultValue={thisState.visit_point}
                name={"visit_point"}
                label={"来店(全店共通)"}
                before={thisState.before.visit_point}
                SetValue={SetValue}
            />
            <PointInput
                defaultValue={thisState.review_point}
                name={"review_point"}
                label={"レビュー"}
                before={thisState.before.review_point}
                SetValue={SetValue}
            />
            <PointInput
                defaultValue={thisState.friend_introduction_point}
                name={"friend_introduction_point"}
                label={"友達紹介"}
                before={thisState.before.friend_introduction_point}
                SetValue={SetValue}
            />
            <PointInput
                defaultValue={thisState.shop_introduction_point}
                name={"shop_introduction_point"}
                label={"店舗紹介"}
                before={thisState.before.shop_introduction_point}
                SetValue={SetValue}
            />
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: "1rem", textAlign: "center" }}
                >
                    <div className="blink">
                        <b>
                            設定クリック時に、
                            <br />
                            パカッティーが反映されます。
                        </b>
                    </div>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                style={{ marginTop: "1rem" }}
            >
                <Button
                    color="primary"
                    variant="contained"
                    style={{
                        padding: "0.5rem 1.5rem",
                        marginRight: "1rem",
                        borderRadius: "0.5rem",
                    }}
                    onClick={SendSettings}
                >
                    設定
                </Button>
            </Grid>

            {/* <h1>ポイント引換設定</h1> */}
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                {/* <LinkPanelBig
                    label="ポイント引換設定"
                    to="/AdminRedeemingPointList"
                    icon={<ShoppingCartIcon style={{ fontSize: "80px" }} />}
                /> */}
                {/* <Button
                    style={{ padding: "1rem 3rem", borderRadius: "0.8rem" }}
                    color="primary"
                    variant="contained"
                    onClick={onAdminRedeemingPointList}
                >
                    編集
                </Button> */}
            </Grid>
        </Container>
    );
};

const PointInput = (props) => {
    const label = props.label;
    const after = props.after;
    const before = props.before;
    const name = props.name;
    const defaultValue = props.defaultValue;
    const SetValue = props.SetValue;

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
            >
                <Grid container item xs={4} alignItems="flex-end">
                    {label}
                </Grid>
                <Grid container item xs={3} alignItems="flex-end">
                    <InputText
                        style={{ width: "90%" }}
                        size="small"
                        type="text"
                        required
                        variant="outlined"
                        name={name}
                        defaultValue={before}
                        onBlur={SetValue}
                        inputProps={{ maxLength: 8, pattern: "^[+]?[0-9]+$" }}
                    ></InputText>
                </Grid>
                <Grid container item xs={2} alignItems="flex-end">
                    パカッティー
                </Grid>

                <Grid
                    container
                    item
                    xs
                    alignItems="flex-end"
                    justifyContent="space-around"
                    alignItems="center"
                    direction="row"
                    style={{ textAlign: "end" }}
                >
                    <Grid style={{ fontSize: "1.2rem" }}>{before}</Grid>
                    <Grid>パカッティー</Grid>
                </Grid>
            </Grid>
            <Divider
                style={{
                    marginTop: "0.1rem",
                    marginBottom: "0.1rem",
                }}
            />
        </>
    );
};

export default AdminPointSetting;
