import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";
import { set_value } from "./Information";

// Stateの初期状態
const initialState = {
    history_list: [],
    currentPage: 1,
    lastPage: 1,
    total: 0,
    count: 0,
};

export const GetShopHistory = createAsyncThunk(
    "ADMIN/店舗履歴取得",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/admin_shop_history";

        const params = arg;

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const PutShopHistory = createAsyncThunk(
    "ADMIN/店舗履歴の修正",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/chenge_admin_shop_history";

        const params = arg;

        try {
            const response = await axios.post(url, arg, {
                headers: {
                    "API-KEY": api_key,
                    "content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "AdminShopUsageHistory",
    initialState,
    reducers: {
        admin_shop_usage_history_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
        initialize: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addMatcher(GetShopHistory, (state, action) => {
            if (action.payload?.history_list) {
                state.history_list = action.payload.history_list;
                state.lastPage = action.payload.lastPage;
                state.currentPage = action.payload.currentPage - 1;
                state.total = action.payload.total;
                state.count = action.payload.count;
            }
        });
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { admin_shop_usage_history_set, initialize } = slice.actions;
