import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";

// Stateの初期状態
const initialState = {
    qas: [],
    open_error_dialog: false,
};

export const FetchFAQ = createAsyncThunk(
    "FAQ/FetchFAQ",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;

        let url;
        if (informationState.shop.shop_login) {
            url = "/api/qa_shop";
        } else if (informationState.user.user_login) {
            url = "/api/qa_general";
        } else {
            url = "/api/qa_nologin";
        }

        HttpFunction("GET", url, {}, "")
            .then((data) => {
                if (data) {
                    let thisCategories = [];
                    let thisQAs = [];
                    for (var qa of data) {
                        if (!thisCategories.includes(qa.qa_id)) {
                            thisCategories.push(qa.qa_id);
                            thisQAs.push([]);
                        }
                    }
                    thisCategories.sort();
                    let index;
                    for (var qa of data) {
                        index = thisCategories.indexOf(qa.qa_id);
                        thisQAs[index].push(qa);
                    }
                    dispatch(set_value(["qas", thisQAs]));
                }
            })
            .catch((err) => {
                // console.log(err);
                dispatch(set_value(["open_error_dialog", true]));
            });
    }
);

const isRejectedAction = (action) => {
    return action.type.startsWith("FAQ") && action.type.endsWith("rejected");
};

// Sliceを生成する
const slice = createSlice({
    name: "FAQ",
    initialState,
    reducers: {
        set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(isRejectedAction, (state, action) => {
            state["open_error_dialog"] = true;
        });
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_value } = slice.actions;
