import React from "react";
import Grid from "@material-ui/core/Grid";
import OfferCard from "~CJS/block/OfferCard";

const OfferCardList = (props) => {
    const { offerlist, ...other } = props;

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                {...other}
            >
                {offerlist.map((item, index) => (
                    <React.Fragment key={index}>
                        <OfferCard data={item} />
                    </React.Fragment>
                ))}
            </Grid>
        </React.Fragment>
    );
};

export default OfferCardList;
