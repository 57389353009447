import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { BackButton } from "~CJS/atoms/BackButton";
import { LinkButton } from "~CJS/atoms/LinkButton";
// import { SelectStatus } from "~/components/atoms/SelectStatus";
import ChatSearchCriteria from "~CJS/block/ChatSearchCriteria";
import { ButtonNavi } from "~CJS/block/ButtonNavi";

import { MessageLeft, MessageRight } from "~CJS/atoms/Message";

import { initialize, GetChatMessage } from "~stores/TALENTBANK/Chat";

import { useDispatch, useSelector } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            paddingLeft: "0",
            paddingRight: "0",
        },
        paper: {
            // width: "90vw",
            // width: "100vw",
            // height: "80vh",
            width: "500px",
            height: "800px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
            boxShadow: "none",

            paddingTop: "0.2rem",
            // backgroundColor: "black",
            paddingLeft: "0.2rem",
            paddingRight: "0.2rem",
            paddingBottom: "0.6rem",
            border: "solid black",

            // marginRight: "auto",
            // marginLeft: "auto",
        },
        paper2: {
            // width: "80vw",
            width: "500px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
        },
        container: {
            width: "100vw",
            // height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        messagesBody: {
            // width: "calc( 100% - 20px )",
            margin: 10,
            overflowY: "scroll",
            // height: "calc( 100% - 80px )",
            boxShadow: "none",
            overflowWrap: "break-word",
            // minHeight: "70vh",
        },
        comment: {
            margin: "1rem !important",
            border: "solid 1px",
            padding: "0px",
            borderRadius: "0px",
            padding: "1rem",
            borderRadius: "0px !important",
        },
    })
);
const ChatSerch = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const company_id = query.get("company_id");
    const talent_id = query.get("talent_id");
    const top_back = query.get("top_back");

    const ChatState = useSelector((state) => state.Chat);

    // const InformationState = useSelector((state) => state.Information);
    // const company_id = InformationState.sponsor.company_id;

    // const AuditionSeetState = useSelector((state) => state.AuditionSeetList);

    useEffect(() => {
        if (talent_id && company_id) {
            const params = {
                company_id: company_id,
                talent_id: talent_id,
            };
            dispatch(GetChatMessage(params)).then((data) => {});
        }
    }, []);

    const StertSerch = (params) => {
        dispatch(GetChatMessage(params)).then((data) => {
            //     if (data.payload.result == "1") {
            //         dispatch(GetAuditionSeet({ offer_id: offer_id }));
            //     } else {
            //         alert(data.payload.error_message);
            //     }
        });
    };

    const OnBack = () => {
        if (top_back) {
            history.push("/AdminHome");
            return;
        }
        history.goBack();
    };

    return (
        <React.Fragment>
            <Container className={classes.root}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Grid item>
                        <ButtonNavi
                            // left_link={`/SponsorSearch`}
                            left_label="戻る"
                            left_props={{ onClick: OnBack }}
                            right_label="メッセージリスト"
                            right_link={`/Talent/MessageList`}
                        />

                        <Typography variant="h1">オファートーク管理</Typography>

                        <Paper className={classes.comment}>
                            <Typography variant="h5">検索方法</Typography>
                            <Typography variant="p">
                                企業IDとタレントIDを入力し検索ボタンをクリックすると、該当のオファートークが参照できます。
                            </Typography>
                        </Paper>
                        <ChatSearchCriteria
                            StertSerch={StertSerch}
                            company_id={company_id}
                            talent_id={talent_id}
                        />
                    </Grid>
                    <Grid item>
                        <Paper className={classes.paper}>
                            <div
                                style={{
                                    display: "block",
                                    background: "black",
                                    height: "3rem",
                                    width: "170px",
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    borderRadius: "0rem 0rem 1rem 1rem",
                                }}
                            ></div>
                            <Paper
                                id="style-1"
                                className={classes.messagesBody}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="space-evenly"
                                    // alignItems="center"
                                    style={{
                                        marginTop: "2rem",
                                        marginBottom: "6rem",
                                    }}
                                >
                                    {ChatState?.message_list.map(
                                        (value, index) => (
                                            <React.Fragment key={index}>
                                                {/* {value} */}

                                                {value.created_cd.match(
                                                    /^\C_?\d{8}$/g
                                                ) ? (
                                                    <MessageRight
                                                        {...value}
                                                        user_type={{
                                                            type: "talent",
                                                        }}
                                                        admin
                                                    />
                                                ) : (
                                                    <MessageLeft
                                                        {...value}
                                                        user_type={{
                                                            type: "company",
                                                        }}
                                                    />
                                                )}

                                                {/* <AuditionSeetCard
                                {...value}
                                company_id={"company_id"}
                                onAdoption={onAdoption}
                                onRepudiation={onRepudiation}
                            /> */}
                                            </React.Fragment>
                                        )
                                    )}
                                </Grid>
                            </Paper>{" "}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default ChatSerch;
