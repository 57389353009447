import React, { useEffect } from "react";
import ImagePanel from "../modules/ImagePanel";
import { makeStyles } from "@material-ui/core/styles";
import { ninki_data, slide_data, ichiosi_data } from "../../const/top_images";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Slide } from "react-slideshow-image";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { set_value } from "../../stores/Information";
import { GetTopdata } from "../../stores/Top";
import GoSerchPageButton from "../modules/GoSerchPageButton";
import { set_alert } from "../../stores/ErrorAlert";
import { useHistory, useLocation } from "react-router";
import AvatarSlider from "../modules/AvatarSlider";

import IngenuityTitle from "../utils/IngenuityTitle";

import PopupNotification from "../modules/PopupNotification";
import GeneralMascotDraw from "../modules/GeneralMascotDraw";
import { useInView } from "react-intersection-observer";

import ShopOverviewTop from "../modules/ShopOverviewTop.js";

import { Swiper, SwiperSlide } from "swiper/react";
import {
    Navigation,
    Autoplay,
    Pagination,
    Scrollbar,
    A11y,
    EffectCoverflow,
    FreeMode,
} from "swiper";

const Top = () => {
    const informationState = useSelector((state) => state.Information);
    const topState = useSelector((state) => state.Top);
    const history = useHistory();

    const dispatch = useDispatch();

    const { ref, inView, entry } = useInView({
        rootMargin: "-50px",
        threshold: 0,
        triggerOnce: true,
    });

    useEffect(() => {
        dispatch(set_value(["showFirstNavi", false]));
        dispatch(set_value(["showDrawer", true]));
        dispatch(set_value(["showBottomNavigation", true]));
        dispatch(GetTopdata());
    }, []);

    useEffect(() => {
        dispatch(set_alert(topState.error_message));
    }, [topState.error_message]);

    const handleRefresh = () => {
        dispatch(GetTopdata());
    };

    const JudgmentLink = (props) => {
        const { children, link } = props;
        const regexp = /^\//;
        if (link && link.match(regexp)) {
            return (
                <>
                    <Link to={link}>{children}</Link>
                </>
            );
        } else {
            return (
                <>
                    <a href={link} target="_blank" rel="noreferrer">
                        {children}
                    </a>
                </>
            );
        }
        return children;
    };

    const title_image_style = {
        marginRight: "0.5rem",
        marginLeft: "0.5rem",
    };

    return (
        <React.Fragment>
            {informationState.user.user_login ||
            informationState.shop.shop_login ||
            !informationState.showFirstNavi ? (
                <Container
                    fixed
                    style={{
                        maxWidth: "500px",
                        // margin: "0px",
                        padding: "1px",
                        // marginRight: "auto",
                        // marginLeft: "auto",
                        overflow: "hidden",
                    }}
                >
                    <PopupNotification />
                    <GeneralMascotDraw />

                    <AvatarSlider
                        talent_list={topState.talent_list}
                        handleRefresh={handleRefresh}
                    />

                    <div>
                        {topState.slide_data.length != 0 && (
                            <>
                                <Slide
                                    arrows={false}
                                    indicators
                                    transitionDuration={500}
                                >
                                    {topState.slide_data.map((item, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                width: "100%",
                                                // border: "solid 1px gray",
                                                // borderRadius:
                                                //     "0rem 0rem 1rem 1rem",
                                            }}
                                        >
                                            <JudgmentLink link={item.link_url}>
                                                <img
                                                    style={{
                                                        objectFit: "contain",
                                                        width: "100%",
                                                        maxHeight: "140px",
                                                        objectFit: "cover",
                                                    }}
                                                    src={item.image_path}
                                                    // src={
                                                    //     "/images/top/500x140.png"
                                                    // }
                                                    alt={item.image_comment}
                                                    onError={(e) => {
                                                        e.target.onerror =
                                                            "null";
                                                        e.target.src =
                                                            "/images/no_img.png";
                                                    }}
                                                />
                                            </JudgmentLink>

                                            {/* <Grid
                                        style={{
                                            // background: "pink",
                                            fontSize: "min(3rem, 5vw)",
                                            textAlign: "center",
                                            // color: "#fff",
                                            borderRadius: "0rem 0rem 1rem 1rem",
                                        }}
                                    >
                                        {item.image_comment}
                                 
                                    </Grid> */}
                                        </div>
                                    ))}
                                </Slide>
                            </>
                        )}
                    </div>
                    {/* <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <GoSerchPageButton />
                    </Grid> */}

                    {topState.news_list?.length != 0 ? (
                        <>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{
                                    backgroundColor: "#FADFE4",
                                    marginTop: "1rem",
                                }}
                            >
                                <IngenuityTitle
                                    title={
                                        <>
                                            <img
                                                src="/images/top/ol.png"
                                                height="50px"
                                                style={title_image_style}
                                            />
                                            フィフティーカードニュース!
                                        </>
                                    }
                                    recommend
                                />
                                {/* <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="flex-start"
                                style={{
                                    background: "pink",
                                    padding: "1rem",
                                    borderRadius: "1.5rem",
                                    fontSize: "1rem",
                                }}
                            >
                                {topState.news_list.map((news, index) => (
                                    <React.Fragment key={index}>
                                        <Grid
                                            onClick={() => {
                                                history.push(news.link);
                                            }}
                                        >
                                            ・{news.news}
                                        </Grid>
                                    </React.Fragment>
                                ))}
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    // onClick={() => console.log("mottomiru")}
                                    onClick={() => {
                                        history.push("/BlogList");
                                    }}
                                >
                                    もっとみる
                                </Grid>
                            </Grid> */}
                            </Grid>

                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="flex-start"
                                style={{
                                    background: "#FBE4F4",
                                    padding: "1rem",
                                    borderRadius: "1.5rem",
                                    fontSize: "1rem",
                                    marginTop: "0.5rem",
                                    marginBottom: "0.5rem",
                                }}
                            >
                                {topState.news_list.map((news, index) => (
                                    <React.Fragment key={index}>
                                        <Grid
                                            onClick={() => {
                                                history.push(news.link);
                                            }}
                                        >
                                            ・{news.news}
                                        </Grid>
                                    </React.Fragment>
                                ))}
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    style={{ color: "#008fde" }}
                                    onClick={() => {
                                        history.push("/BlogList");
                                    }}
                                >
                                    もっとみる
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        ""
                    )}

                    {topState.recommend_list.length != 0 ? (
                        <div>
                            {/* <h2>今週のイチオシ！</h2> */}
                            {/* <img
                                src="/storage/top/2371.png"
                                width="100%"
                                height="120"
                                style={{
                                    objectFit: "cover",
                                    marginBottom: "-17px",
                                    marginTop: "-1rem",
                                }}
                            /> */}

                            {/* <img
                                src="/storage/top/2371title01.png"
                                width="100%"
                                style={{
                                    objectFit: "cover",
                                    marginBottom: "-1px",
                                }}
                            /> */}
                            {/* <div
                                style={{
                                    background: "#7AA737",
                                    padding: "1rem",
                                }}
                            > */}
                            {/* <IngenuityTitle
                                title="今週のイチオシ！"
                                recommend
                            />
                            <ImagePanel
                                list={topState.recommend_list}
                                name="ichiosi_list"
                            /> */}
                            {/* </div> */}
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{
                                    backgroundColor: "#FADFE4",
                                    marginTop: "0.5rem",
                                }}
                            >
                                <IngenuityTitle
                                    title={
                                        <>
                                            <img
                                                src="/images/top/hiyoko.png"
                                                width="40px"
                                                style={title_image_style}
                                            />
                                            {`おすすめ店舗`}
                                        </>
                                    }
                                    recommend
                                    list={"/RecommendShoplist"}
                                />
                            </Grid>
                            <Swiper
                                slidesPerView={"auto"}
                                //spaceBetween={3}
                                modules={[FreeMode, Pagination]}
                                className="mySwiper"
                                rewind={true}
                            >
                                {topState.recommend_list.map((value, index) => (
                                    <SwiperSlide
                                        key={index}
                                        style={{
                                            width: "fit-content",
                                            marginRight: "auto",
                                            marginLeft: "auto",
                                        }}
                                    >
                                        {" "}
                                        {/* <Box style={{ width: "250px" }}> */}
                                        <Box style={{ width: "188px" }}>
                                            <ShopOverviewTop
                                                shop_data={value}
                                            />
                                        </Box>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    ) : (
                        ""
                    )}

                    {topState.use_history_list.length != 0 ? (
                        <div>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{
                                    backgroundColor: "#FADFE4",
                                    marginTop: "0.5rem",
                                }}
                            >
                                <IngenuityTitle
                                    title={
                                        <>
                                            <img
                                                src="/images/top/kurosu-tu.png"
                                                height="50px"
                                                style={title_image_style}
                                            />
                                            {`利用したお店`}
                                        </>
                                    }
                                    recommend
                                    list={"/UseHistoryShoplist"}
                                />
                            </Grid>
                            <Swiper
                                slidesPerView={"auto"}
                                //spaceBetween={3}
                                modules={[FreeMode, Pagination]}
                                className="mySwiper"
                                rewind={true}
                            >
                                {topState.use_history_list.map(
                                    (value, index) => (
                                        <SwiperSlide
                                            key={index}
                                            style={{
                                                width: "fit-content",
                                                marginRight: "auto",
                                                marginLeft: "auto",
                                            }}
                                        >
                                            {" "}
                                            {/* <Box style={{ width: "250px" }}> */}
                                            <Box style={{ width: "188px" }}>
                                                <ShopOverviewTop
                                                    shop_data={value}
                                                />
                                            </Box>
                                        </SwiperSlide>
                                    )
                                )}
                            </Swiper>
                        </div>
                    ) : (
                        ""
                    )}

                    {topState.new_shop_list.length != 0 ? (
                        <div>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{
                                    backgroundColor: "#FADFE4",
                                    marginTop: "0.5rem",
                                }}
                            >
                                <IngenuityTitle
                                    title={
                                        <>
                                            <img
                                                src="/images/top/ol.png"
                                                // width="20px"
                                                height="50px"
                                                style={title_image_style}
                                            />
                                            新着店舗ご紹介!
                                        </>
                                    }
                                    recommend
                                    list={"/NewShoplist"}
                                />
                            </Grid>
                            <Swiper
                                slidesPerView={"auto"}
                                // freeMode={true}
                                //spaceBetween={3}
                                modules={[FreeMode, Pagination]}
                                className="mySwiper"
                                rewind={true}
                                // onReachBeginning={OnReachEnd}
                            >
                                {topState.new_shop_list.map((value, index) => (
                                    <SwiperSlide
                                        key={index}
                                        style={{
                                            width: "fit-content",
                                            marginRight: "auto",
                                            marginLeft: "auto",
                                            // width: "10rem",
                                        }}
                                    >
                                        {" "}
                                        {/* <Box style={{ width: "250px" }}> */}
                                        <Box style={{ width: "188px" }}>
                                            <ShopOverviewTop
                                                // Onfavorite={Onfavorite}
                                                shop_data={value}
                                            />
                                        </Box>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            {/* <ImagePanel
                                list={topState.new_shop_list}
                                name="new_shop_list"
                            /> */}
                            {/* </div> */}
                        </div>
                    ) : (
                        ""
                    )}

                    {topState.near_shop_list.length != 0 ? (
                        <div>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{
                                    backgroundColor: "#FADFE4",
                                    marginTop: "0.5rem",
                                }}
                            >
                                <IngenuityTitle
                                    title={
                                        <>
                                            <img
                                                src="/images/top/usagi.png"
                                                height="50px"
                                                style={title_image_style}
                                            />
                                            {`${topState.prefectures}で人気のお店!`}
                                        </>
                                    }
                                    recommend
                                    list={"/NearShoplist"}
                                />
                            </Grid>
                            <Swiper
                                slidesPerView={"auto"}
                                // freeMode={true}
                                //spaceBetween={3}
                                modules={[FreeMode, Pagination]}
                                className="mySwiper"
                                rewind={true}
                                // onReachBeginning={OnReachEnd}
                            >
                                {topState.near_shop_list.map((value, index) => (
                                    <SwiperSlide
                                        key={index}
                                        style={{
                                            width: "fit-content",
                                            marginRight: "auto",
                                            marginLeft: "auto",
                                            // width: "10rem",
                                        }}
                                    >
                                        {" "}
                                        {/* <Box style={{ width: "250px" }}> */}
                                        <Box style={{ width: "188px" }}>
                                            <ShopOverviewTop
                                                // Onfavorite={Onfavorite}
                                                shop_data={value}
                                            />
                                        </Box>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            {/* <ImagePanel
                                list={topState.new_shop_list}
                                name="new_shop_list"
                            /> */}
                            {/* </div> */}
                        </div>
                    ) : (
                        ""
                    )}

                    <div>
                        {topState.popular_list.length != 0 ? (
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{
                                    backgroundColor: "#FADFE4",
                                    marginTop: "0.5rem",
                                }}
                            >
                                {/* <img
                                    src="/storage/top/2375.png"
                                    width="100%"
                                    height="130"
                                    style={{
                                        objectFit: "cover",
                                        marginBottom: "-17px",
                                    }}
                                /> */}
                                {/* <div
                                    style={{
                                        background: "#A7DAF4",
                                        padding: "1rem",
                                    }}
                                > */}
                                <IngenuityTitle
                                    title={
                                        <>
                                            <img
                                                src="/images/top/haisu-tu.png"
                                                height="50px"
                                                style={title_image_style}
                                            />
                                            ランキング５０!
                                        </>
                                    }
                                />
                                <ImagePanel
                                    list={topState.popular_list}
                                    name="ninki_list"
                                    // lanking={true}
                                />
                                {/* </div> */}
                            </Grid>
                        ) : (
                            ""
                        )}
                    </div>
                </Container>
            ) : (
                <Redirect to="/FirstNavi" />
            )}
        </React.Fragment>
    );
};

export default Top;
