// Flutterにトークン情報を保存する
const SetUserTokenFlutter = (api_key, type) => {
    // console.log("SetUserTokenFlutter!");
    const userToken = api_key;
    // console.log(userToken);
    if (typeof flutter === "undefined") {
        // console.log("Webでログイン");
    } else {
        window.flutter_inappwebview.callHandler("setUserToken", type, userToken)
            .then((result) => {
                // console.log(result["result"]);
            }).catch((error) => {
                // console.log(JSON.stringify(error));
            }); 
    }
}

export default SetUserTokenFlutter;