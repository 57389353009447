import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";

// Stateの初期状態
const initialState = {
    error_message: "",
    tag_list: {},
    recommend_list: {},
};

export const GetTagList = createAsyncThunk(
    "Top/GetTagList",
    async (arg, thunkAPI) => {
        const url = "/api/tag_list";
        const response = await axios.get(url + "?" + new URLSearchParams(), {
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector(
                    'meta[name="csrf-token"]'
                ).content,
            },
        });
        return response.data;
    }
);

export const TagCreate = createAsyncThunk(
    "管理/タグ作成",
    async (arg, thunkAPI) => {
        const url = "/api/tag_create";
        const api_key = thunkAPI.getState().Information.admin.admin_token;

        const response = await axios.post(url, arg, {
            headers: {
                "API-KEY": api_key,
                "Content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector(
                    'meta[name="csrf-token"]'
                ).content,
            },
        });
        return response.data;
    }
);

export const TagUpdate = createAsyncThunk(
    "管理/タグ編集",
    async (arg, thunkAPI) => {
        const url = "/api/tag_update";
        const api_key = thunkAPI.getState().Information.admin.admin_token;

        const response = await axios.put(url, arg, {
            headers: {
                "API-KEY": api_key,
                "Content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector(
                    'meta[name="csrf-token"]'
                ).content,
            },
        });
        return response.data;
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "TagList",
    initialState,
    reducers: {
        TagListSet: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        [GetTagList.pending]: (state, action) => {},
        [GetTagList.fulfilled]: (state, action) => {
            state.tag_list = action.payload.tag_list;
            state.recommend_list = action.payload.recommend_list;

            if (action.payload.error_message) {
                state.error_message = [
                    "サーバーエラー",
                    action.payload.error_message,
                ];
            }
        },
        [GetTagList.rejected]: (state, action) => {
            state.error_message = [
                "ネットワークエラー",
                "タグ一覧の取得に失敗しました",
            ];
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { TagListSet } = slice.actions;
