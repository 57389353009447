import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    error_dialog: {
        "& .MuiDialog-paper": {
            padding: "15px",
            border: "2px solid orangered",
            display: "block",
        },
    },
}));

const ErrorDialog = (props) => {
    const { children, ...other } = props;
    const classes = useStyles();

    return (
        <Dialog {...other} className={classes.error_dialog}>
            {children}
        </Dialog>
    );
};

export default ErrorDialog;