import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import OneTimePassword from "../pages/OneTimePassword";
import {
    login_set_value,
    login_user_set_value,
    login_shop_set_value,
    login_admin_set_value,
    initialize,
} from "../../stores/Login";
import { set_value } from "../../stores/Information";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import HttpFunction from "../utils/HttpFunction";
import Dialog from "@material-ui/core/Dialog";
import Error500Handling from "../utils/Error500Handling";
import { useHistory } from "react-router";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { TextFieldValidation } from "../utils/TextFields";
import {
    ButtonContainedPrimary,
    ButtonOutlinedPrimary,
    ButtonTextPrimary,
} from "../utils/Buttons";
import { makeStyles } from "@material-ui/core";
import { ArtTrack } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    stop_dialog: {
        "& .MuiDialog-paper": {
            padding: "15px",
        },
    },
    button_center: {
        display: "block",
        margin: "auto",
    },
}));

export const StopButton = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const thisState = useSelector((state) => state.Login);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleStop = () => {
        dispatch(initialize());
        if (thisState.login_mode !== "admin") {
            dispatch(set_value(["showDrawer", true]));
            dispatch(set_value(["showBottomNavigation", true]));
        }
        history.goBack();
    };

    return (
        <>
            <IconButton onClick={handleOpen} style={{ padding: "8px 0 24px" }}>
                <NavigateBeforeIcon />
                <span style={{ fontSize: "70%" }}>戻る</span>
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                className={classes.stop_dialog}
            >
                <p>ログインを中止します。よろしいですか？</p>
                <Grid container>
                    <Grid item xs={6}>
                        <ButtonContainedPrimary
                            className={classes.button_center}
                            onClick={handleClose}
                        >
                            キャンセル
                        </ButtonContainedPrimary>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonOutlinedPrimary
                            className={classes.button_center}
                            onClick={handleStop}
                        >
                            OK
                        </ButtonOutlinedPrimary>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
};

export const MailInput = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.Login);
    const [email, setEmail] = React.useState("");
    const [disable, setDisable] = React.useState(false);
    const [open500, setOpen500] = React.useState(false);

    const handleBlur = (e) => {
        setEmail(e.target.value);
    };

    const SendEmail = (e) => {
        e.preventDefault();
        const elements = e.target?.elements;

        let url = "";
        switch (thisState.login_mode) {
            case "user":
                url = "/api/mail_login";
                break;
            case "shop":
                url = "/api/mail_login_shop";
                break;
            case "admin":
                url = "/api/mail_login_admin";
                break;
            default:
                break;
        }

        setDisable(true);
        HttpFunction("POST", url, { email_address: elements?.email?.value }, "")
            .then((data) => {
                dispatch(
                    login_set_value(["error_message", data.error_message])
                );
                if (data.result === "OK") {
                    dispatch(login_set_value(["email_flag", false]));
                    dispatch(login_set_value(["onetime_flag", true]));
                    switch (thisState.login_mode) {
                        case "user":
                            dispatch(
                                login_user_set_value([
                                    "email_address",
                                    elements?.email?.value,
                                ])
                            );
                            break;
                        case "shop":
                            dispatch(
                                login_shop_set_value([
                                    "shop_email_address",
                                    elements?.email?.value,
                                ])
                            );
                            break;
                        case "admin":
                            dispatch(
                                login_admin_set_value([
                                    "email_address",
                                    elements?.email?.value,
                                ])
                            );
                            break;
                        default:
                            break;
                    }
                } else if (data.result === "NG") {
                    setDisable(false);
                } else if (data === 500) {
                    setOpen500(true);
                }
            })
            .catch((err) => {
                // console.log(err);
                setOpen500(true);
            });
    };

    const handleClose500 = () => {
        setOpen500(false);
        setDisable(false);
    };

    return (
        <>
            {thisState.error_message && (
                <Alert severity="error">
                    <AlertTitle>エラー</AlertTitle>
                    {thisState.error_message}
                </Alert>
            )}

            <form onSubmit={SendEmail}>
                <TextFieldValidation
                    label="メールアドレス"
                    discription="登録しているメールアドレスを入力し、送信ボタンを押してください。ワンタイムパスワードが届きます。"
                    id="email"
                    defaultValue={email}
                    placeholder="name@example.com"
                    type="email"
                    onBlur={handleBlur}
                    inputProps={{ maxLength: 255 }}
                    autoFocus
                />
                <div className="text-right">
                    <ButtonContainedPrimary
                        // onClick={SendEmail}
                        disabled={disable}
                        type="submit"
                    >
                        送信
                    </ButtonContainedPrimary>
                </div>
            </form>
            <Error500Handling open={open500} onClose={handleClose500} />
        </>
    );
};

export const OneTimeInput = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.Login);
    const [onetime, setOnetime] = React.useState("");
    const [open500, setOpen500] = React.useState(false);
    const [disable, setDisable] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [sendEmailCount, setCounter] = React.useState(0);
    const [reSendEmailDisabled, setReSendEmailDisabled] = React.useState(false);

    let send_onetime_url, send_mail_url, mail;
    if (thisState.login_mode === "user") {
        send_onetime_url = "/api/user_onetime_check_login";
        send_mail_url = "/api/mail_login";
        mail = thisState.user.email_address;
    } else if (thisState.login_mode === "shop") {
        send_onetime_url = "/api/user_onetime_check_login_shop";
        send_mail_url = "/api/mail_login_shop";
        mail = thisState.shop.shop_email_address;
    } else if (thisState.login_mode === "admin") {
        send_onetime_url = "/api/user_onetime_check_login_admin";
        send_mail_url = "/api/mail_login_admin";
        mail = thisState.admin.email_address;
    }

    const handleBlur = (e) => {
        setOnetime(e.target.value);
    };

    const SendOneTime = (e) => {
        e.preventDefault();
        const elements = e.target?.elements;
        setDisable(true);
        const params = {
            email_address: mail,
            one_time: elements?.one_time?.value,
        };

        HttpFunction("POST", send_onetime_url, params, "")
            .then((data) => {
                if (data.result === "OK") {
                    dispatch(login_set_value(["onetime_flag", false]));
                    dispatch(login_set_value(["password_flag", true]));
                } else if (data.result === "NG") {
                    setDisable(false);
                    dispatch(
                        login_set_value(["login_failed", data.login_failed])
                    );
                    setError(true);
                } else if (data === 500) {
                    setOpen500(true);
                }
            })
            .catch((err) => {
                // console.log(err);
                setOpen500(true);
            });
    };

    const SendEmail = () => {
        setError(false);
        if (sendEmailCount < 3) {
            HttpFunction("POST", send_mail_url, { email_address: mail }, "")
                .then((data) => {
                    if (data === 500) {
                        setOpen500(true);
                    }
                })
                .catch((err) => {
                    // console.log(err);
                    setOpen500(true);
                });
            setCounter(sendEmailCount + 1);
        }
    };

    const handleClose500 = () => {
        setOpen500(false);
        setDisable(false);
    };

    useEffect(() => {
        // console.log("sendEmailCount:"+sendEmailCount);
        if (sendEmailCount === 0) {
            // // console.log("初期値");
        } else if (sendEmailCount < 3) {
            setReSendEmailDisabled(true);
            setTimeout(function () {
                setReSendEmailDisabled(false);
            }, 30000);
        } else {
            setReSendEmailDisabled(true);
        }
    }, [sendEmailCount]);

    return (
        <>
            {error && (
                <Alert severity="error">
                    <AlertTitle>エラー</AlertTitle>
                    {1 <= thisState.login_failed &&
                    thisState.login_failed <= 4 ? (
                        <>
                            正しいワンタイムパスワードを入力してください。（5回失敗するとワンタイムパスワードが無効化されます。）
                            <br />
                            {`認証失敗回数：${thisState.login_failed}回`}
                        </>
                    ) : (
                        "ワンタイムパスワードが無効化されています。新しいワンタイムパスワードを発行するには、「メールの再送信」をクリックしてください。"
                    )}
                </Alert>
            )}
            {3 <= sendEmailCount && (
                <Alert severity="info">
                    <AlertTitle>メッセージ</AlertTitle>
                    再送上限に達しました。これ以上の再送信はできません。
                    <br />
                    再度ワンタイムパスワードを発行する場合はお手数ですが最初からやり直してください。
                </Alert>
            )}
            <form onSubmit={SendOneTime}>
                <OneTimePassword
                    onBlur={handleBlur}
                    name="one_time"
                    autoFocus
                />
                <ButtonContainedPrimary
                    disabled={disable}
                    type="submit"
                    style={{ display: "block", marginLeft: "auto" }}
                >
                    送信
                </ButtonContainedPrimary>
            </form>

            <ButtonTextPrimary
                onClick={SendEmail}
                style={{ display: "block", marginLeft: "auto" }}
                disabled={reSendEmailDisabled}
            >
                メールの再送信
            </ButtonTextPrimary>
            <Error500Handling open={open500} onClose={handleClose500} />
        </>
    );
};
