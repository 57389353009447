import React from "react";

import { Grid, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import HttpFunction from "../utils/HttpFunction";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core";
import {
    ButtonContainedPrimary,
    ButtonOutlinedPrimary,
    ButtonTextPrimary,
} from "../utils/Buttons";

const useStyles = makeStyles((theme) => ({
    button: {
        display: "block",
        margin: "auto",
    },
    stop_dialog: {
        "& .MuiDialog-paper": {
            padding: "15px",
        },
    },
}));

const QRReading = () => {
    const informationState = useSelector((state) => state.Information);
    const [open, setOpen] = React.useState(false);
    const [message, messageSet] = React.useState("");
    const classes = useStyles();
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    function qrReader() {
        if (typeof flutter !== "undefined") {
            window.flutter_inappwebview
                .callHandler("QRcodeRead")
                .then((result) => {
                    const qr = JSON.stringify(result);
                    // console.log(qr);
                    if (qr === "null") {
                        // console.log("QR読み取りキャンセル");
                    } else {
                        const params = {
                            qr_key: qr,
                        };
                        const api_key = informationState.shop.shop_token;
                        HttpFunction("POST", "/api/visit", params, api_key)
                            .then((data) => {
                                // console.log("result:" + data);
                                for (let key in data) {
                                    // console.log("key:" + key + " value:" + data[key]);
                                }
                                // console.log("status:" + data.status);
                                if (data.status == 0) {
                                    // console.log("読み取り成功");
                                    messageSet("読み取り完了しました。");
                                    handleOpen();
                                } else {
                                    // console.log("error_message:" + data.error_message);
                                    messageSet(data.error_message);
                                    handleOpen();
                                }
                            })
                            .catch((err) => {
                                // console.log(err);
                                messageSet("通信に失敗しました。管理者に問い合わせてください。");
                                handleOpen();
                            });
                    }
                })
                .catch((err) => {
                    // console.log(JSON.stringify(err));
                    messageSet("カメラの起動に失敗しました。");
                    handleOpen();
                });
        } else {
            messageSet("フィフティーカードアプリを利用してください。");
            handleOpen();
        }
    }
    return (
        <React.Fragment>
            <h1>QRコード読取</h1>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "70vh" }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "70%", fontSize: "8vw" }}
                    onClick={() => qrReader()}
                >
                    {/* <Button variant="contained" color="primary" onClick={() => QRcodeRead.postMessage("QRRead!")}> */}
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        className="blink"
                    >
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <img
                                src="/images/Common/img_flow_03.png"
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <div >qrを読み取る</div>
                    </Grid>
                </Button>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ margin: "5vw" }}
                >
                    <p>
                        ※カメラが起動しない場合は、お使いのスマホのこのアプリがカメラを使用する権限をご確認ください。
                    </p>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                className={classes.stop_dialog}
            >
                <Grid
                    container
                    style={{ textAlign: "center" }}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <p>{message}</p>
                    <Grid item xs={6}>
                        <ButtonOutlinedPrimary onClick={handleClose}>
                            OK
                        </ButtonOutlinedPrimary>
                    </Grid>
                </Grid>
            </Dialog>
        </React.Fragment>
    );
};

export default QRReading;
