import React, { useState, useEffect } from "react";

import ShopOverview from "../modules/ShopOverview";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { UseHistoryShoplist_set, GetUseHistoryShoplist } from "../../stores/UseHistoryShoplist";

const UseHistoryShoplist = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const UseHistoryShoplistState = useSelector((state) => state.UseHistoryShoplist);
    const params = {};

    useEffect(() => {
        dispatch(GetUseHistoryShoplist(params))
            .then((res) => {
                const new_shop_list_data = res.payload.data.map(
                    (shop, index) => {
                        const new_shop = {
                            ...shop,
                        };
                        return new_shop;
                    }
                );
                dispatch(
                    UseHistoryShoplist_set(["use_history_shop_list", new_shop_list_data])
                );
            })
            .catch((err) => {
                // console.log(err);
            });
    }, []);

    const Onfavorite = (shop_id) => {
        const new_shop_list_data = UseHistoryShoplistState.use_history_shop_list.map(
            (shop, index) => {
                if (shop.shop_id == shop_id) {
                    const new_shop = {
                        ...shop,
                        favorite: shop.favorite == 1 ? 0 : 1,
                    };
                    return new_shop;
                } else {
                    return shop;
                }
            }
        );

        dispatch(UseHistoryShoplist_set(["use_history_shop_list", new_shop_list_data]));
    };

    return (
        <React.Fragment>
            <ButtonNavi
                left_label="戻る"
                left_props={{
                    onClick: () => history.goBack(),
                }}
            />
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                    maxWidth: "600px",
                    margin: "auto",
                    overflow: "hidden",
                }}
            >
                <h1>利用したお店</h1>

                <React.Fragment>
                    {UseHistoryShoplistState.use_history_shop_list ? (
                        <React.Fragment>
                            {UseHistoryShoplistState.use_history_shop_list.map(
                                (shop, index) => (
                                    <React.Fragment key={index}>
                                        <ShopOverview
                                            Onfavorite={Onfavorite}
                                            shop_data={shop}
                                        />
                                    </React.Fragment>
                                )
                            )}
                        </React.Fragment>
                    ) : (
                        ""
                    )}
                </React.Fragment>
            </Grid>
        </React.Fragment>
    );
};

export default UseHistoryShoplist;
