import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Stateの初期状態
const initialState = {
    message_list: [],
    company_id: "",
    chat_list: [],
    chat_list_info: {
        page_count: 1,
        page: 1,
        total: 0,
    },
    message_list_info: {
        page_count: 1,
        page: 1,
        total: 0,
    },
};

export const GetChatMessage = createAsyncThunk(
    "TALENT/Chat/GetChatMessage",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const params = arg;
        let url = "/api/chat_message";

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "ontent-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const GetChatList = createAsyncThunk(
    "TALENT/Chat/GetChatList",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const params = arg;
        let url = "/api/chat_list";

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "ontent-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);
// 全メッセージ検索
export const GetMessageList = createAsyncThunk(
    "TALENT/Chat/GetMessageList",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const params = arg;
        let url = "/api/message_list";

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "ontent-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);
// Sliceを生成する
const slice = createSlice({
    name: "Chat",
    initialState,
    reducers: {
        initialize: () => initialState,
        chat_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        [GetChatMessage.pending]: (state, action) => {},
        [GetChatMessage.fulfilled]: (state, action) => {
            const payload = action.payload;
            const message_list = payload.message_list;
            if (message_list) {
                state.message_list = message_list;
            }
        },
        [GetChatMessage.rejected]: (state, action) => {},
        [GetChatList.fulfilled]: (state, action) => {
            const payload = action.payload;
            const chat_list = payload.chat_list;
            if (chat_list) {
                state.chat_list = chat_list;
            }
            const chat_list_info = payload.chat_list_info;
            if (chat_list_info) {
                state.chat_list_info = chat_list_info;
            }
            state.company_id = action.meta?.arg?.company_id;
        },
        [GetMessageList.fulfilled]: (state, action) => {
            const payload = action.payload;
            const message_list = payload.message_list;
            if (message_list) {
                state.message_list = message_list;
            }
            const message_list_info = payload.message_list_info;
            if (message_list_info) {
                state.message_list_info = message_list_info;
            }
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { initialize, chat_set } = slice.actions;
//
// message
// timestamp
// photoURL
// displayName
// avatarDisp
//
//
//
//
