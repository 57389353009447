import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import {
    initialize,
    talent_set,
    GetDetail,
    TalentFix,
} from "~stores/TALENTBANK/Talent";

import TalentProfileInput from "~/block/TalentProfileInput";

import OptionInput from "~CJS/block/OptionInput";
import { LinkButton } from "~CJS/atoms/LinkButton";
import { ButtonNavi } from "~CJS/block/ButtonNavi";

const TalentProfileFix = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.Talent);

    const path = history.location.pathname.split("/");
    const talent_id = path[2];

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elements = e.target?.elements;

        let input_option = [];

        const getValue = (name) => {
            let value = "";
            if (elements[name]?.value) {
                value = elements[name]?.value;
            }

            return value;
        };

        const talent_info = {
            talent_id: talent_id,
            fifty_id: talent_id?.match(/R_/) ? "" : getValue("fifty_id"),
            email_address: getValue("email_address"),
            password: getValue("password"),
            height: getValue("height"),
            image: getValue("image"),
            image_big: getValue("image_big"),
            age: getValue("age"),
            sns_link: getValue("sns_link"),
            talent_name: getValue("talent_name"),
            talent_name_kana: getValue("talent_name_kana"),
            talent_pr: getValue("talent_pr"),
            skill: getValue("skill"),
            sex: getValue("sex"),
            prefectures: getValue("prefectures"),
            city: getValue("city"),
            address_1: getValue("address_1"),
            address_2: getValue("address_2"),
            tell: getValue("tell"),
            achievement_count: getValue("achievement_count"),
            review_average: getValue("review_average"),
        };

        thisState.talent_detail.input_option.map((value, index) => {
            input_option.push({
                input_id: value.input_id,
                value: elements[value.input_id]?.value,
                type: value.type,
            });
        });

        const params = {
            talent_info: talent_info,
            input_option: input_option,
            category: getValue("category"),
        };

        dispatch(TalentFix(params)).then((action) => {
            if (action.payload.result == "1") {
                history.push(`/TalentDetail/${talent_id}`);
                alert("更新しました。");
            } else {
                action.payload.error_message &&
                    alert(action.payload.error_message);
            }
        });
    };
    // const HandleChange = (e) => {
    //     // dispatch(
    //     //     profile_fix_set([e.currentTarget.name, e.currentTarget.value])
    //     // );
    // };

    useEffect(() => {
        dispatch(initialize());
        dispatch(GetDetail(talent_id));
    }, []);

    const talent_name = "";
    const affiliation = "";
    const disabled = true;

    return (
        <React.Fragment>
            <Container>
                <ButtonNavi
                    left_label="戻る"
                    left_link={`/TalentDetail/${talent_id}`}
                />
                <Typography variant="h1">タレント編集</Typography>

                <form onSubmit={HandleSubmit}>
                    <TalentProfileInput {...thisState.talent_detail} />
                    <OptionInput
                        inputs={thisState.talent_detail?.input_option}
                    />
                    <LinkButton
                        to={`/TalentDetail/${talent_id}`}
                        label="キャンセル"
                        color="secondary"
                        variant="outlined"
                        fullWidth
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        fullWidth
                    >
                        変更
                    </Button>
                </form>
            </Container>
        </React.Fragment>
    );
};

export default TalentProfileFix;
