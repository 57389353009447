import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
// Stateの初期状態
const initialState = {
    offer_list: [
        // {
        //     talent_id: "",
        //     name: "",
        //     image: "",
        //     new_flag: false,
        //     excellent_flag: false,
        // },
    ],
    offer_serch: {
        page: 0,
        page_count: 0,
        total: 0,
    },
    criteria: {
        no_entry_close_flag: true,
    },
    offer_detail: {
        offer_id: "",
        company_id: "",
        offer_title: "",
        prefectures: "",
        city: "",
        address_1: "",
        address_2: "",
        guarantee: "",
        recruitment_count: "",
        audition_start: "",
        audition_close: "",
        job_start: "",
        job_end: "",
        job_content: "",
        job_detail: "",
        job_place: "",
        qualification: "",
        image_big: "",
        image: "",
        status: "",
    },
    // 公開中詳細データ
    public_data: {},
    // 申請中詳細データ
    application_data: {},
};

export const GetOfferList = createAsyncThunk(
    "TALENT/GetOfferList",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/offer_list";
        const params = arg;

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const GetDetail = createAsyncThunk(
    "TALENT/Offer/GetDetail",
    async (arg, thunkAPI) => {
        let url = "/api/offer_detail";
        const api_key = thunkAPI.getState().Information.admin.admin_token;

        const params = {
            ...arg, //必須
        };

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const OfferCreate = createAsyncThunk(
    "TALENT/Offer/OfferCreate",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        let url = "/api/offer_create";

        const params = {
            ...arg, //必須
        };

        try {
            const response = await axios.post(url, params, {
                headers: {
                    "API-KEY": api_key,
                    "content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const OfferFixApi = createAsyncThunk(
    "TALENT/OfferFixApi",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        let url = "/api/offer_fix";

        const params = {
            ...arg, //必須
        };
        try {
            const response = await axios.put(url, params, {
                headers: {
                    "API-KEY": api_key,
                    "content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const OfferDecision = createAsyncThunk(
    "TALENT/OfferDecision",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        let url = "/api/offer_decision";

        const params = {
            ...arg, //必須
        };
        try {
            const response = await axios.put(url, params, {
                headers: {
                    "API-KEY": api_key,
                    "content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "Offer",
    initialState,
    reducers: {
        initialize: (state, action) => {
            if (action.payload) {
                state[action.payload] = initialState[action.payload];
            } else {
                initialState;
            }
        },
        offer_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        // お仕事検索
        [GetOfferList.fulfilled]: (state, action) => {
            const resdata = action.payload;

            state.offer_list = resdata.offer_list;

            state.offer_serch = resdata.offer_info;

            state.criteria = action.meta.arg;
        },
        // お仕事詳細取得
        [GetDetail.fulfilled]: (state, action) => {
            const resdata = action.payload;

            if (resdata.application_data) {
                state.application_data = resdata.application_data;
            }
            if (resdata.public_data) {
                state.public_data = resdata.public_data;
            }

            state.offer_detail = resdata.offer_info;
        },
        // お仕事作成
        [OfferCreate.fulfilled]: (state, action) => {
            const resdata = action.payload;
        },
        // お仕事編集
        [OfferFixApi.fulfilled]: (state, action) => {
            const resdata = action.payload;
        },
        // お仕事否認･承認
        [OfferDecision.fulfilled]: (state, action) => {
            const resdata = action.payload;
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { initialize, offer_set } = slice.actions;
//
// message
// timestamp
// photoURL
// displayName
// avatarDisp
//
//
//
//
