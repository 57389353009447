import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";


import { GetEnquete, EnqueteSet, PostEnquete } from "../../stores/Enquete";

import TextField from "@material-ui/core/TextField";

const WithdrawalEnquete = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const EnqueteState = useSelector((state) => state.Enquete);
    const InformationState = useSelector((state) => state.Information);



    useEffect(() => {
        const params = {
            type: '201'
        };
        dispatch(GetEnquete(params));
    }, []);


    const OnWithdrawal = (e) => {

        e.preventDefault();
        const elem = e.target.elements;


        const list = EnqueteState.enquete_list.map((val, i) => {

            return {
                type: '201',
                enquete_id: val.enquete_id,
                answer: elem['input_' + i].value,
            }

        });


        dispatch(PostEnquete(
            {
                list: list,
                user_id: InformationState.user.user_id,
            }
        )).then((val) => {


            history.push("/Withdrawal");

        });


    };


    return (
        <React.Fragment>
            <Container fixed>
                <form onSubmit={OnWithdrawal}>



                    <Grid>
                        <h2> アンケートに答える</h2>
                    </Grid>
                    {/* 全１０問（全てご入力ください） */}
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {EnqueteState.enquete_list.map((val, i) => (
                            <React.Fragment key={i}>
                                <Grid style={{ margin: "0.7rem", width: "300px" }}>
                                    <div style={{ marginBottom: "5px" }}>Q{i}</div>
                                    <TextField
                                        name={'input_' + i}
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label={val.question}
                                        required={val.required ? true : false}


                                    />
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button
                            style={{ margin: "1rem" }}
                            variant="contained"
                            color="primary"
                            type='submit'
                        // onClick={OnWithdrawal}
                        >
                            退会
                        </Button>
                    </Grid>
                </form>
            </Container>
        </React.Fragment>
    );
};

export default WithdrawalEnquete;
