import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetShopData } from "./ShopInformation";
import HttpFunction from "../components/utils/HttpFunction";

// Stateの初期状態
const initialState = {
    tag_id_1: "---未選択---",
    tag_id_2: "---未選択---",
    tag_id_3: "---未選択---",
    tag_id_4: "---未選択---",
    tag_id_5: "---未選択---",
    reservation: "",
    approval_flag: 0,
    error_message: "",
    remand_dialog: false,
    shop_prefectures: "北海道",
    wifi: 0,
    latitude: "0",
    longitude: "0",
    rerender_flag: false,
    modal_progress: false,
    disable_button_fix: false,
    disable_button_apply: false,
    disable_button_remand: false,
    menu_data_list: [],
    tweet_list: [],
};

const AutoLink = (str) => {
    if (str.match(/<a(?: .+?)?>.*?<\/a>/g)) {
        return str;
    }
    var regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
    var regexp_makeLink = function (all, url, h, href) {
        return '<a href="h' + href + '">' + url + "</a>";
    };

    return str.replace(regexp_url, regexp_makeLink);
};
//現在の公開データの取得
export const GetShopInformation = createAsyncThunk(
    "ShopStoreApplication/GetShopInformation",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.shop.shop_token;

        const SetErrer = (message) => {
            dispatch(release_info_set(["error_message", message]));
        };

        HttpFunction("GET", "/api/shop_detail", "", api_key)
            .then((data) => {
                if (data.error_message) {
                    SetErrer(["サーバーエラー", data.error_message]);
                    return;
                }

                for (let [key, value] of Object.entries(data)) {
                    dispatch(release_info_set([key, value]));
                }

                dispatch(release_info_set(["holiday", data["shop_holiday"]]));
                dispatch(release_info_set(["url", data["shop_url"]]));
                dispatch(release_info_set(["sns", data["shop_sns"]]));
                dispatch(release_info_set(["modal_progress", false]));
            })
            .catch((err) => {
                // console.log("err");
                // console.log(err);
                dispatch(release_info_set(["modal_progress", false]));
                dispatch(
                    release_info_set([
                        "error_message",
                        ["サーバーエラー", "公開データの取得に失敗しました"],
                    ])
                );
            });
    }
);

export const GetShopInformationApplication = createAsyncThunk(
    "ShopStoreApplication/GetShopInformationApplication",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const apply_id = arg.apply_id;

        const informationState = thunkAPI.getState().Information;
        const thisState = thunkAPI.getState().ShopStoreApplication;

        const api_key = informationState.shop.shop_token;

        if (!apply_id) {
            // console.log("申請IDがありません");
            dispatch(
                release_info_set([
                    "error_message",
                    ["エラー", "申請IDがありません"],
                ])
            );
        }
        const params = {
            apply_id: apply_id,
            approval_flag: "0",
        };

        const url = "/api/shop_apply";

        const response = await axios.get(
            url + "?" + new URLSearchParams(params),
            {
                headers: {
                    "API-KEY": api_key,
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            }
        );
        return response.data;
    }
);

//申請の新規登録
export const NewShopInformationApplication = createAsyncThunk(
    "ShopStoreApplication/NewShopInformationApplication",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;

        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.shop.shop_token;
        const thisState = thunkAPI.getState().ShopStoreApplication;

        const params = {
            shop_name: thisState.shop_name,
            shop_explain: thisState.shop_explain,
            store_pr: thisState.store_pr,
            shop_image_big: thisState.shop_image_big,
            shop_image_small: thisState.shop_image_small,
            shop_menu: thisState.shop_menu,
            shop_prefectures: thisState.shop_prefectures,
            shop_city: thisState.shop_city,
            shop_address1: thisState.shop_address1,
            shop_address2: thisState.shop_address2,
            shop_staff: thisState.shop_staff,
            holiday: thisState.holiday,
            business_hours: thisState.business_hours,
            access: thisState.access,
            reservation: thisState.reservation,
            shop_phone_number: thisState.shop_phone_number,
            url: thisState.url,
            sns: thisState.sns,
            tag_id_1:
                thisState.tag_id_1 == "---未選択---"
                    ? null
                    : thisState.tag_id_1,
            tag_id_2:
                thisState.tag_id_2 == "---未選択---"
                    ? null
                    : thisState.tag_id_2,
            tag_id_3:
                thisState.tag_id_3 == "---未選択---"
                    ? null
                    : thisState.tag_id_3,
            tag_id_4:
                thisState.tag_id_4 == "---未選択---"
                    ? null
                    : thisState.tag_id_4,
            tag_id_5:
                thisState.tag_id_5 == "---未選択---"
                    ? null
                    : thisState.tag_id_5,
            wifi: thisState.wifi ? thisState.wifi : "0",
            latitude: thisState.latitude,
            longitude: thisState.longitude,
            sunday_opening_time: thisState?.sunday_opening_time,
            sunday_closing_time: thisState?.sunday_closing_time,
            monday_opening_time: thisState?.monday_opening_time,
            monday_closing_time: thisState?.monday_closing_time,
            tuesday_opening_time: thisState?.tuesday_opening_time,
            tuesday_closing_time: thisState?.tuesday_closing_time,
            wednesday_opening_time: thisState?.wednesday_opening_time,
            wednesday_closing_time: thisState?.wednesday_closing_time,
            thursday_opening_time: thisState?.thursday_opening_time,
            thursday_closing_time: thisState?.thursday_closing_time,
            friday_opening_time: thisState?.friday_opening_time,
            friday_closing_time: thisState?.friday_closing_time,
            saturday_opening_time: thisState?.saturday_opening_time,
            saturday_closing_time: thisState?.saturday_closing_time,
            closest_traffic_1: thisState?.closest_traffic_1,
            closest_station_1: thisState?.closest_station_1,
            closest_means_1: thisState?.closest_means_1,
            closest_station_minute_1: thisState?.closest_station_minute_1,
            closest_traffic_2: thisState?.closest_traffic_2,
            closest_station_2: thisState?.closest_station_2,
            closest_means_2: thisState?.closest_means_2,
            closest_station_minute_2: thisState?.closest_station_minute_2,
            closest_traffic_3: thisState?.closest_traffic_3,
            closest_station_3: thisState?.closest_station_3,
            closest_means_3: thisState?.closest_means_3,
            closest_station_minute_3: thisState?.closest_station_minute_3,
            target: thisState?.target,
            shop_image_2: thisState?.shop_image_2,
            shop_image_3: thisState?.shop_image_3,
            shop_image_4: thisState?.shop_image_4,
            shop_image_5: thisState?.shop_image_5,
            remarks: thisState?.remarks,
            menu_data_list: thisState?.menu_data_list,
        };

        HttpFunction("POST", "/api/create_apply", params, api_key)
            .then((data) => {
                if (data.result == "OK") {
                    dispatch(release_info_set(["modal_progress", false]));
                    dispatch(release_info_set(["apply_id", data.apply_id]));

                    dispatch(
                        release_info_set([
                            "error_message",
                            ["メッセージ", "申請が完了しました。", "info"],
                        ])
                    );
                    return;
                }
                if (data.error_message) {
                    dispatch(release_info_set(["modal_progress", false]));
                    dispatch(
                        release_info_set([
                            "error_message",
                            ["サーバーエラー", data.error_message],
                        ])
                    );
                } else {
                    dispatch(release_info_set(["modal_progress", false]));
                    dispatch(
                        release_info_set([
                            "error_message",
                            ["サーバーエラー", "申請に失敗しました。"],
                        ])
                    );
                }
            })
            .catch((err) => {
                // console.log(err);
                dispatch(
                    release_info_set([
                        "error_message",
                        ["サーバーエラー", "申請の新規登録に失敗しました"],
                    ])
                );
            });
    }
);
//申請の更新
export const FixShopInformationApplication = createAsyncThunk(
    "ShopStoreApplication/PostShopInformationApplication",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;

        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.shop.shop_token;
        const thisState = thunkAPI.getState().ShopStoreApplication;

        const SetErrer = (message) => {
            dispatch(release_info_set(["error_message", message]));
        };

        const params = {
            updated_at: thisState.updated_at,
            apply_id: thisState.apply_id,
            shop_name: thisState.shop_name,
            shop_explain: thisState.shop_explain,
            store_pr: thisState.store_pr,
            shop_image_big: thisState.shop_image_big,
            shop_image_small: thisState.shop_image_small,
            shop_menu: thisState.shop_menu,
            shop_staff: thisState.shop_staff,
            shop_prefectures: thisState.shop_prefectures,
            shop_city: thisState.shop_city,
            business_hours: thisState.business_hours,
            shop_address1: thisState.shop_address1,
            shop_address2: thisState.shop_address2,
            access: thisState.access,
            shop_phone_number: thisState.shop_phone_number,
            reservation: thisState.reservation,
            url: thisState.url,
            sns: thisState.sns,
            tag_id_1:
                thisState.tag_id_1 == "---未選択---"
                    ? null
                    : thisState.tag_id_1,
            tag_id_2:
                thisState.tag_id_2 == "---未選択---"
                    ? null
                    : thisState.tag_id_2,
            tag_id_3:
                thisState.tag_id_3 == "---未選択---"
                    ? null
                    : thisState.tag_id_3,
            tag_id_4:
                thisState.tag_id_4 == "---未選択---"
                    ? null
                    : thisState.tag_id_4,
            tag_id_5:
                thisState.tag_id_5 == "---未選択---"
                    ? null
                    : thisState.tag_id_5,
            holiday: thisState.holiday,
            wifi: thisState.wifi ? thisState.wifi : "0",
            latitude: thisState.latitude,
            longitude: thisState.longitude,
            sunday_opening_time: thisState?.sunday_opening_time,
            sunday_closing_time: thisState?.sunday_closing_time,
            monday_opening_time: thisState?.monday_opening_time,
            monday_closing_time: thisState?.monday_closing_time,
            tuesday_opening_time: thisState?.tuesday_opening_time,
            tuesday_closing_time: thisState?.tuesday_closing_time,
            wednesday_opening_time: thisState?.wednesday_opening_time,
            wednesday_closing_time: thisState?.wednesday_closing_time,
            thursday_opening_time: thisState?.thursday_opening_time,
            thursday_closing_time: thisState?.thursday_closing_time,
            friday_opening_time: thisState?.friday_opening_time,
            friday_closing_time: thisState?.friday_closing_time,
            saturday_opening_time: thisState?.saturday_opening_time,
            saturday_closing_time: thisState?.saturday_closing_time,
            closest_traffic_1: thisState?.closest_traffic_1,
            closest_station_1: thisState?.closest_station_1,
            closest_means_1: thisState?.closest_means_1,
            closest_station_minute_1: thisState?.closest_station_minute_1,
            closest_traffic_2: thisState?.closest_traffic_2,
            closest_station_2: thisState?.closest_station_2,
            closest_means_2: thisState?.closest_means_2,
            closest_station_minute_2: thisState?.closest_station_minute_2,
            closest_traffic_3: thisState?.closest_traffic_3,
            closest_station_3: thisState?.closest_station_3,
            closest_means_3: thisState?.closest_means_3,
            closest_station_minute_3: thisState?.closest_station_minute_3,
            target: thisState?.target,
            shop_image_2: thisState?.shop_image_2,
            shop_image_3: thisState?.shop_image_3,
            shop_image_4: thisState?.shop_image_4,
            shop_image_5: thisState?.shop_image_5,
            remarks: thisState?.remarks,
            menu_data_list: thisState?.menu_data_list,
        };

        HttpFunction("PUT", "/api/update_apply", params, api_key)
            .then((data) => {
                if (data.error_message) {
                    dispatch(release_info_set(["modal_progress", false]));
                    SetErrer(["サーバーエラー", data.error_message]);
                    return;
                }
                if (data.result != "OK") {
                    dispatch(release_info_set(["modal_progress", false]));
                    SetErrer(["サーバーエラー", data.error_message]);
                    return;
                }
                dispatch(release_info_set(["modal_progress", false]));
                SetErrer(["メッセージ", "申請を更新しました", "info"]);
            })
            .catch((err) => {
                dispatch(release_info_set(["modal_progress", false]));
                // console.log(err);
                SetErrer(["サーバーエラー", "申請の更新に失敗しました"]);
            });
    }
);

//申請データの取得管理者用
export const AdminGetShopInformationApplication = createAsyncThunk(
    "ShopStoreApplication/AdminGetShopInformationApplication",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;

        const informationState = thunkAPI.getState().Information;
        const thisState = thunkAPI.getState().ShopStoreApplication;

        const api_key = informationState.admin.admin_token;

        const SetErrer = (message) => {
            dispatch(release_info_set(["error_message", message]));
        };

        const params = {
            apply_id:
                thisState.apply_id == "undefined" ||
                thisState.apply_id == "null"
                    ? ""
                    : thisState.apply_id,
            approval_flag: "0",
            shop_id: thisState.shop_id,
        };

        // 申請ID、承認フラグ
        HttpFunction("GET", "/api/shop_apply_admin", params, api_key)
            .then((data) => {
                const show_data = data.show_data;

                // // console.log(show_data);

                if (!show_data.shop_id) {
                    // console.log("公開データがありません");
                }

                for (let [key, value] of Object.entries(show_data)) {
                    // // console.log(`key : ${key} , value : ${value}`)
                    if (key != "apply_id") {
                        dispatch(release_info_set([key, value]));
                    }
                }

                //タグが空ならば未選択と表示する。
                for (let i = 1; i < 6; i++) {
                    if (show_data["tag_id_" + i] == null) {
                        dispatch(
                            release_info_set(["tag_id_" + i, "---未選択---"])
                        );
                    }
                }

                if ("shop_holiday" in show_data)
                    dispatch(
                        release_info_set(["holiday", show_data["shop_holiday"]])
                    );

                if ("shop_url" in show_data)
                    dispatch(release_info_set(["url", show_data["shop_url"]]));

                if ("shop_sns" in show_data)
                    dispatch(release_info_set(["sns", show_data["shop_sns"]]));

                if (data.shop_url) {
                    const url = show_data.shop_url;
                    dispatch(release_info_set(["shop_url", AutoLink(url)]));
                }
                if (data.shop_sns) {
                    const sns = show_data.shop_sns;
                    dispatch(release_info_set(["shop_sns", AutoLink(sns)]));
                }
                dispatch(release_info_set(["modal_progress", false]));
            })
            .catch((err) => {
                // console.log(err);
                dispatch(release_info_set(["modal_progress", false]));
                SetErrer(["サーバーエラー", "申請データの取得に失敗しました"]);
            });
    }
);
//申請の承認
export const ApprovalShopInformationApplication = createAsyncThunk(
    "ShopStoreApplication/ApprovalShopInformationApplication",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;

        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.admin.admin_token;
        const thisState = thunkAPI.getState().ShopStoreApplication;

        const SetErrer = (message) => {
            dispatch(release_info_set(["error_message", message]));
        };
        const params = {
            shop_id: thisState.shop_id,
            apply_id: thisState.apply_id,
            updated_at: thisState.updated_at,
            information_updated_at: thisState.information_updated_at,
        };
        const url = "/api/approval_apply_admin";
        const response = await axios.put(url, params, {
            headers: {
                "API-KEY": api_key,
                "Content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector(
                    'meta[name="csrf-token"]'
                ).content,
            },
        });
        return response.data;
    }
);

//申請の否認
export const RemandShopInformationApplication = createAsyncThunk(
    "ShopStoreApplication/RemandShopInformationApplication",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;

        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.admin.admin_token;

        const thisState = thunkAPI.getState().ShopStoreApplication;
        const params = {
            apply_id: thisState.apply_id,
            updated_at: thisState.updated_at,
            denial_reason: thisState.denial_reason,
            approval_flag: "2",
            created_cd: thisState.created_cd,
            shop_id: thisState.shop_id,
        };

        const SetErrer = (message) => {
            dispatch(release_info_set(["error_message", message]));
        };

        HttpFunction("PUT", "/api/update_apply_admin", params, api_key)
            .then((data) => {
                if (data.result != "OK") {
                    dispatch(release_info_set(["modal_progress", false]));
                    SetErrer(["サーバーエラー", data.error_message]);
                    return;
                }
                dispatch(AdminGetShopInformationApplication());
                dispatch(release_info_set(["modal_progress", false]));
                SetErrer(["メッセージ", "申請を否認しました。", "info"]);
            })
            .catch((err) => {
                // console.log(err);
                dispatch(release_info_set(["modal_progress", false]));
                SetErrer(["サーバーエラー", "申請の否認に失敗しました"]);
            });
    }
);

//申請の新規作成 管理
export const AdminNewShopInformationApplication = createAsyncThunk(
    "ShopStoreApplication/AdminNewShopInformationApplication",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;

        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.admin.admin_token;
        const thisState = thunkAPI.getState().ShopStoreApplication;
        const SetErrer = (message) => {
            dispatch(release_info_set(["error_message", message]));
        };

        const params = {
            shop_id: thisState.shop_id,
            shop_name: thisState.shop_name,
            shop_explain: thisState.shop_explain,
            store_pr: thisState.store_pr,
            shop_image_big: thisState.shop_image_big,
            shop_image_small: thisState.shop_image_small,
            shop_menu: thisState.shop_menu,
            shop_staff: thisState.shop_staff,
            shop_prefectures: thisState.shop_prefectures,
            business_hours: thisState.business_hours,
            shop_city: thisState.shop_city,
            shop_address1: thisState.shop_address1,
            shop_address2: thisState.shop_address2,
            access: thisState.access,
            shop_phone_number: thisState.shop_phone_number,
            reservation: thisState.reservation,
            url: thisState.url,
            sns: thisState.sns,
            tag_id_1:
                thisState.tag_id_1 == "---未選択---"
                    ? null
                    : thisState.tag_id_1,
            tag_id_2:
                thisState.tag_id_2 == "---未選択---"
                    ? null
                    : thisState.tag_id_2,
            tag_id_3:
                thisState.tag_id_3 == "---未選択---"
                    ? null
                    : thisState.tag_id_3,
            tag_id_4:
                thisState.tag_id_4 == "---未選択---"
                    ? null
                    : thisState.tag_id_4,
            tag_id_5:
                thisState.tag_id_5 == "---未選択---"
                    ? null
                    : thisState.tag_id_5,
            holiday: thisState.holiday,
            wifi: thisState.wifi ? thisState.wifi : "0",
            latitude: thisState.latitude,
            longitude: thisState.longitude,
            sunday_opening_time: thisState?.sunday_opening_time,
            sunday_closing_time: thisState?.sunday_closing_time,
            monday_opening_time: thisState?.monday_opening_time,
            monday_closing_time: thisState?.monday_closing_time,
            tuesday_opening_time: thisState?.tuesday_opening_time,
            tuesday_closing_time: thisState?.tuesday_closing_time,
            wednesday_opening_time: thisState?.wednesday_opening_time,
            wednesday_closing_time: thisState?.wednesday_closing_time,
            thursday_opening_time: thisState?.thursday_opening_time,
            thursday_closing_time: thisState?.thursday_closing_time,
            friday_opening_time: thisState?.friday_opening_time,
            friday_closing_time: thisState?.friday_closing_time,
            saturday_opening_time: thisState?.saturday_opening_time,
            saturday_closing_time: thisState?.saturday_closing_time,
            closest_traffic_1: thisState?.closest_traffic_1,
            closest_station_1: thisState?.closest_station_1,
            closest_means_1: thisState?.closest_means_1,
            closest_station_minute_1: thisState?.closest_station_minute_1,
            closest_traffic_2: thisState?.closest_traffic_2,
            closest_station_2: thisState?.closest_station_2,
            closest_means_2: thisState?.closest_means_2,
            closest_station_minute_2: thisState?.closest_station_minute_2,
            closest_traffic_3: thisState?.closest_traffic_3,
            closest_station_3: thisState?.closest_station_3,
            closest_means_3: thisState?.closest_means_3,
            closest_station_minute_3: thisState?.closest_station_minute_3,
            target: thisState?.target,
            shop_image_2: thisState?.shop_image_2,
            shop_image_3: thisState?.shop_image_3,
            shop_image_4: thisState?.shop_image_4,
            shop_image_5: thisState?.shop_image_5,
            remarks: thisState?.remarks,
            menu_data_list: thisState?.menu_data_list,
        };

        HttpFunction("POST", "/api/create_apply_admin", params, api_key)
            .then((data) => {
                if (data.result != "OK") {
                    dispatch(release_info_set(["modal_progress", false]));
                    SetErrer(["サーバーエラー", data.error_message]);
                    return;
                }
                dispatch(release_info_set(["apply_id", data.apply_id]));
                dispatch(release_info_set(["modal_progress", false]));
                SetErrer(["メッセージ", "申請を作成しました", "info"]);
            })

            .catch((err) => {
                // console.log(err);
                dispatch(release_info_set(["modal_progress", false]));
                SetErrer(["サーバーエラー", "申請の作成に失敗しました"]);
            });
    }
);
//申請の更新 管理
export const AdminFixShopInformationApplication = createAsyncThunk(
    "ShopStoreApplication/AdminFixShopInformationApplication",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;

        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.admin.admin_token;
        const thisState = thunkAPI.getState().ShopStoreApplication;
        const SetErrer = (message) => {
            dispatch(release_info_set(["error_message", message]));
        };

        const params = {
            shop_id: thisState.shop_id,
            updated_at: thisState.updated_at,
            apply_id: thisState.apply_id,
            shop_name: thisState.shop_name,
            shop_explain: thisState.shop_explain,
            store_pr: thisState.store_pr,
            shop_image_big: thisState.shop_image_big,
            shop_image_small: thisState.shop_image_small,
            shop_menu: thisState.shop_menu,
            shop_staff: thisState.shop_staff,
            shop_prefectures: thisState.shop_prefectures,
            shop_city: thisState.shop_city,
            shop_address1: thisState.shop_address1,
            shop_address2: thisState.shop_address2,
            business_hours: thisState.business_hours,
            access: thisState.access,
            shop_phone_number: thisState.shop_phone_number,
            reservation: thisState.reservation,
            url: thisState.url,
            sns: thisState.sns,
            tag_id_1:
                thisState.tag_id_1 == "---未選択---"
                    ? null
                    : thisState.tag_id_1,
            tag_id_2:
                thisState.tag_id_2 == "---未選択---"
                    ? null
                    : thisState.tag_id_2,
            tag_id_3:
                thisState.tag_id_3 == "---未選択---"
                    ? null
                    : thisState.tag_id_3,
            tag_id_4:
                thisState.tag_id_4 == "---未選択---"
                    ? null
                    : thisState.tag_id_4,
            tag_id_5:
                thisState.tag_id_5 == "---未選択---"
                    ? null
                    : thisState.tag_id_5,
            holiday: thisState.holiday,
            wifi: thisState.wifi ? thisState.wifi : "0",
            latitude: thisState.latitude,
            longitude: thisState.longitude,
            sunday_opening_time: thisState?.sunday_opening_time,
            sunday_closing_time: thisState?.sunday_closing_time,
            monday_opening_time: thisState?.monday_opening_time,
            monday_closing_time: thisState?.monday_closing_time,
            tuesday_opening_time: thisState?.tuesday_opening_time,
            tuesday_closing_time: thisState?.tuesday_closing_time,
            wednesday_opening_time: thisState?.wednesday_opening_time,
            wednesday_closing_time: thisState?.wednesday_closing_time,
            thursday_opening_time: thisState?.thursday_opening_time,
            thursday_closing_time: thisState?.thursday_closing_time,
            friday_opening_time: thisState?.friday_opening_time,
            friday_closing_time: thisState?.friday_closing_time,
            saturday_opening_time: thisState?.saturday_opening_time,
            saturday_closing_time: thisState?.saturday_closing_time,
            closest_traffic_1: thisState?.closest_traffic_1,
            closest_station_1: thisState?.closest_station_1,
            closest_means_1: thisState?.closest_means_1,
            closest_station_minute_1: thisState?.closest_station_minute_1,
            closest_traffic_2: thisState?.closest_traffic_2,
            closest_station_2: thisState?.closest_station_2,
            closest_means_2: thisState?.closest_means_2,
            closest_station_minute_2: thisState?.closest_station_minute_2,
            closest_traffic_3: thisState?.closest_traffic_3,
            closest_station_3: thisState?.closest_station_3,
            closest_means_3: thisState?.closest_means_3,
            closest_station_minute_3: thisState?.closest_station_minute_3,
            target: thisState?.target,
            shop_image_2: thisState?.shop_image_2,
            shop_image_3: thisState?.shop_image_3,
            shop_image_4: thisState?.shop_image_4,
            shop_image_5: thisState?.shop_image_5,
            remarks: thisState?.remarks,
            menu_data_list: thisState?.menu_data_list,
        };

        HttpFunction("PUT", "/api/update_apply_admin", params, api_key)
            .then((data) => {
                if (data.result != "OK") {
                    dispatch(release_info_set(["modal_progress", false]));
                    SetErrer(["サーバーエラー", data.error_message]);
                    return;
                }
                dispatch(release_info_set(["modal_progress", false]));
                SetErrer(["メッセージ", "申請の更新しました", "info"]);
            })

            .catch((err) => {
                // console.log(err);
                dispatch(release_info_set(["modal_progress", false]));
                SetErrer(["サーバーエラー", "申請の更新に失敗しました"]);
            });
    }
);

const numStringCheck = (value) => {
    switch (typeof value) {
        case "number":
        case "bigint":
            return value || 0;
        case "boolean":
            return value || false;
        case "string":
        case "object":
        default:
            return value || "";
    }
};

// Sliceを生成する
const slice = createSlice({
    name: "ShopStoreApplication",
    initialState,
    reducers: {
        setInitializeState: (state, action) => {
            state = initialState;
            return state;
        },
        release_info_set: (state, action) => {
            const name = action.payload[0];
            const value = numStringCheck(action.payload[1]);
            const type = action.payload[2];

            if (type) {
                state[type][name] = value;
            } else {
                state[name] = value;
            }
        },
    },
    extraReducers: {
        // 【加盟店】公開情報取得
        [GetShopInformation.pending]: (state, action) => {
            state.modal_progress = true;
        },
        [GetShopInformation.fulfilled]: (state, action) => {},
        [GetShopInformation.rejected]: (state, action) => {
            state.modal_progress = false;
            state.error_message = [
                "ネットワークエラー",
                "公開データの取得に失敗しました",
            ];
        },
        // 【加盟店】申請情報取得
        [GetShopInformationApplication.pending]: (state, action) => {
            state.modal_progress = true;
        },
        [GetShopInformationApplication.fulfilled]: (state, action) => {
            const data = action.payload;

            for (let [key, value] of Object.entries(data)) {
                state[key] = value;
            }

            //タグが空ならば未選択と表示する。
            for (let i = 1; i < 6; i++) {
                if (data["tag_id_" + i] == null) {
                    state["tag_id_" + i] = "---未選択---";
                }
            }

            if (data.shop_url) {
                const url = data.shop_url;
                state.shop_url = AutoLink(url);
            }
            if (data.shop_sns) {
                const sns = data.shop_sns;
                state.shop_sns = AutoLink(sns);
            }
            state.modal_progress = false;

            state.rerender_flag = state.rerender_flag ? false : true;
        },
        [GetShopInformationApplication.rejected]: (state, action) => {
            state.error_message = [
                "ネットワークエラー",
                "更新データの取得に失敗しました",
            ];
        },
        // 【加盟店】申請新規
        [NewShopInformationApplication.pending]: (state, action) => {
            state.modal_progress = true;
        },
        [FixShopInformationApplication.fulfilled]: (state, action) => {},
        [NewShopInformationApplication.rejected]: (state, action) => {
            state.error_message = [
                "ネットワークエラー",
                "申請の新規登録に失敗しました",
            ];
        },
        // 【加盟店】申請編集
        [FixShopInformationApplication.pending]: (state, action) => {
            state.modal_progress = true;
        },
        [FixShopInformationApplication.fulfilled]: (state, action) => {},
        [FixShopInformationApplication.rejected]: (state, action) => {
            state.error_message = [
                "ネットワークエラー",
                "申請の更新に失敗しました",
            ];
        },
        // 【管理】申請情報取得
        [AdminGetShopInformationApplication.pending]: (state, action) => {
            state.modal_progress = true;
        },
        [AdminGetShopInformationApplication.fulfilled]: (state, action) => {},
        [AdminGetShopInformationApplication.rejected]: (state, action) => {
            state.modal_progress = false;
            state.error_message = ["エラー", "データの取得に失敗しました"];
        },
        // 【管理】承認
        [ApprovalShopInformationApplication.pending]: (state, action) => {
            state.modal_progress = true;
        },
        [ApprovalShopInformationApplication.fulfilled]: (state, action) => {
            const data = action.payload;
            state.modal_progress = false;
            state.apply_id = null;

            if (data.error_message) {
                state.error_message = ["エラー", data.error_message];
            } else {
                state.error_message = [
                    "メッセージ",
                    "申請を承認しました。",
                    "info",
                ];
                state.disable_button_fix = true;
                state.disable_button_apply = true;
                state.disable_button_remand = true;
            }
        },
        [ApprovalShopInformationApplication.rejected]: (state, action) => {
            state.modal_progress = false;
            state.error_message = [
                "ネットワークエラー",
                "申請の承認に失敗しました",
            ];
        },
        // 【管理】否認
        [RemandShopInformationApplication.pending]: (state, action) => {
            state.modal_progress = true;
        },
        [RemandShopInformationApplication.fulfilled]: (state, action) => {},
        [RemandShopInformationApplication.rejected]: (state, action) => {
            state.modal_progress = false;
            state.error_message = [
                "ネットワークエラー",
                "申請の否認に失敗しました",
            ];
        },
        // 【管理】申請新規
        [AdminNewShopInformationApplication.pending]: (state, action) => {
            state.modal_progress = true;
        },
        [AdminNewShopInformationApplication.fulfilled]: (state, action) => {},
        [AdminNewShopInformationApplication.rejected]: (state, action) => {
            state.modal_progress = false;
            state.error_message = ["ネットワークエラー", ""];
        },
        // 【管理】申請更新
        [AdminFixShopInformationApplication.pending]: (state, action) => {
            state.modal_progress = true;
        },
        [AdminFixShopInformationApplication.fulfilled]: (state, action) => {},
        [AdminFixShopInformationApplication.rejected]: (state, action) => {
            state.modal_progress = false;
            state.error_message = ["ネットワークエラー", ""];
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { release_info_set, setInitializeState } = slice.actions;
