import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

const WithdrawalConfirmation = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const OnMemberInformation = () => {
        history.push("/MemberInformation");
        // istory.push("/Withdrawal");
    };

    const OnWithdrawalEnquete = () => {
        history.push("/WithdrawalEnquete");
        // istory.push("/Withdrawal");
    };
    return (
        <React.Fragment>
            <Container fixed>
                <Grid>
                    <h2> 本当に退会しますか？</h2>
                    <Grid>退会をした場合</Grid>
                    <Grid>
                        ・タレントパーク登録者はタレントパークへもログインができなくなります。
                    </Grid>
                    <Grid>・再登録してもパカッティーはなくなります</Grid>
                    <Grid>
                        ・今まで利用していた店舗様で半額で利用できなくなります。
                    </Grid>
                    <Grid>・イベントなどの待遇も受けれなくなります</Grid>
                </Grid>
                <h2> ご了承の上退会ご希望の方</h2>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    style={{ marginBottom: "0.5rem", padding: "0.1rem" }}
                >
                    退会の前にアンケートにご協力をお願い致します。
                </Grid>

                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Button
                        style={{ margin: "1rem" }}
                        variant="contained"
                        color="primary"
                        onClick={OnWithdrawalEnquete}
                    >
                        アンケートに答える
                    </Button>
                    <Button
                        style={{ margin: "1rem" }}
                        variant="contained"
                        color="primary"
                        onClick={OnMemberInformation}
                    >
                        退会をやめる
                    </Button>
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default WithdrawalConfirmation;
