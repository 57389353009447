import React from "react";
import image from "/images/flutter/fifty_card.png";

import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";

import "react-slideshow-image/dist/styles.css";
import { withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
    },
    itemBar: {
        backgroundColor: "transparent",
        textAlign: "center",
        fontSize: "0.2rem",
    },
}));

export default function ImagePanel(props) {
    const classes = useStyles();
    const history = useHistory();

    const OnLink = (shop_id) => {
        history.push("/ShopInformation/" + shop_id);
    };

    if (!props.list) {
        // console.log("listパラメーターがありません");
    }
    if (!props.name) {
        // console.log("nameパラメーターがありません");
    }
    const list = props.list;
    const name = props.name;

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
        >
            <ImageList rowHeight={100} cols={3} gap={5}>
                {list.map((item, index) => (
                    <ImageListItem
                        key={name + index}
                        cols={item.cols || 1}
                        onClick={() => OnLink(item.shop_id)}
                        style={
                            {
                                // border: "solid 0.1rem",
                                // background: "white",
                            }
                        }
                    >
                        <img
                            src={item.shop_image_small}
                            alt={item.title}
                            onError={(e) => {
                                e.target.onerror = "null";
                                e.target.src = "/images/no_img.png";
                            }}
                        />

                        {props.lanking && LankImg(index) ? (
                            <ImageListItemBar
                                style={{
                                    marginTop: "1px",
                                    marginLeft: "-10px",
                                    background: "rgba(0,0,0,0)",
                                }}
                                position="top"
                                actionIcon={
                                    <img
                                        width="70"
                                        height="70"
                                        src={LankImg(index)}
                                    />
                                }
                                actionPosition="left"
                            />
                        ) : (
                            ""
                        )}
                        {/* 
                        <ImageListItemBar
                            position="top"
                            title={item.shop_name}
                            subtitle={item.shop_name}
                            className={classes.itemBar}
                        /> */}
                    </ImageListItem>
                ))}
            </ImageList>
        </Grid>
    );
}

const LankImg = (index) => {
    switch (index) {
        case 0:
            return "/storage/top/top_f1.png";
        case 1:
            return "/storage/top/top_f2.png";
        case 2:
            return "/storage/top/top_f3.png";
        default:
            break;
    }
};
