import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import { set_value, PostReview } from "../../stores/U_couponUseHistory";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import Rating from "@material-ui/lab/Rating";
import TextField from "@material-ui/core/TextField";
import TokenErrorDialog from "../utils/TokenErrorDialog";
import Error500Handling from "../utils/Error500Handling";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDialog-paper": {
            padding: "15px",
            width: "80%",
        },
    },
    container: {
        textAlign: "center",
        padding: "10px",
    },
    title: {
        fontSize: "1.5rem",
        marginBottom: "5px",
    },
    shop_name: {
        marginBottom: "10px",
    },
    success: {
        color: theme.palette.primary.main,
        fontSize: "0.85rem",
        margin: 0,
    },
    choice: {
        margin: "5px auto",
        width: "130px",
    },
}));

const ReviewDialog = () => {
    const thisState = useSelector((state) => state.U_couponUseHistory);
    const choices = ["最高！", "かなり良い", "良い", "ちょっと良い", "普通"];
    const [selected, setSelected] = React.useState(5 - thisState.review);
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleClose = () => {
        dispatch(set_value(["open_review_dialog", false]));
        setSelected(5);
        dispatch(set_value(["show_success", false]));
    };
    const handleSelect = (index) => {
        console.log("█████████████████████████████████████");
        console.log(index);
        setSelected(index);
        dispatch(set_value(["review", index]));
        // dispatch(set_value(["review", 5 - index]));
        dispatch(set_value(["show_success", false]));
    };

    const handlePostReview = () => {
        const params = {
            review: thisState.review,
            review_text: thisState.review_text,
            history_id: thisState.reviewed_history_id,
        };

        dispatch(PostReview(params));
    };

    const handleClickOK = () => {
        dispatch(set_value(["open_token_error_dialog", false]));
    };
    const handleCloseError = () => {
        dispatch(set_value(["open_error_dialog", false]));
    };

    useEffect(() => {
        setSelected(5 - thisState.review);
    });

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="review"
                open={thisState.open_review_dialog}
                className={classes.root}
            >
                <Grid container className={classes.container}>
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            レビュー投稿
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.shop_name}>
                        <Typography>
                            店舗名：{thisState.reviewed_shop}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <p className={classes.success}>
                            {thisState.show_success ? "\\投稿完了/" : ""}
                        </p>
                    </Grid>
                </Grid>
                {/* {choices.map((choice, index) => (
                    <Button
                        key={index}
                        variant={selected === index ? "contained" : "outlined"}
                        color="primary"
                        onClick={() => handleSelect(index)}
                        className={classes.choice}
                    >
                        {choice}
                    </Button>
                ))} */}

                <Grid
                    container
                    className={classes.container}
                    style={{
                        marginRight: "auto",
                        marginLeft: "auto",
                        display: "block",
                    }}
                >
                    タップして評価：
                    <Rating
                        value={thisState.review}
                        onChange={(event, newValue) => {
                            handleSelect(newValue);
                        }}
                    />
                </Grid>
                <TextField
                    label=""
                    type="text"
                    multiline
                    minRows={4}
                    variant="outlined"
                    value={thisState.review_text}
                    // className={classes.textField}

                    InputLabelProps={{
                        shrink: true,
                    }}
                    name="start_date"
                    onChange={(e) => {
                        dispatch(set_value(["review_text", e.target.value]));
                    }}
                />
                <Grid container className={classes.container}>
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleClose}
                        >
                            キャンセル
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePostReview}
                            disabled={selected === 5}
                        >
                            投稿
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
            <TokenErrorDialog
                open={thisState.open_token_error_dialog}
                onClickOK={handleClickOK}
            />
            <Error500Handling
                open={thisState.open_error_dialog}
                network={thisState.network_error}
                onClose={handleCloseError}
            />
        </>
    );
};

export default ReviewDialog;
