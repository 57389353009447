import React, { useEffect } from "react";
import HistoryTable from "../modules/HistoryTable";
import { initialize_table } from "../../stores/HistoryTable";
import { set_value, FetchHistory } from "../../stores/S_couponUseHistory";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Error500Handling from "../utils/Error500Handling";
import TokenErrorDialog from "../utils/TokenErrorDialog";

//列定義
const columns = [
    {
        field: "used_at",
        headerName: "日時",
        width: 125,
        sortable: false,
        valueFormatter: (params) => {
            const formattedDate = params.value
                .substr(2, 14)
                .replace(/-/g, "/")
                .replace("T", " ");
            return formattedDate;
        },
        headerAlign: "center",
    },
    {
        field: "nickname",
        headerName: "来店者",
        flex: 1,
        minWidth: 150,
        headerAlign: "center",
        sortable: false,
    },
];

const S_couponUseHistory = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.S_couponUseHistory);
    const tableState = useSelector((state) => state.HistoryTable);

    const GetHistory = (page) => {
        const params = { page: page };

        dispatch(FetchHistory(params));
    };

    const handleCloseError = () => {
        dispatch(set_value(["open_error_dialog", false]));
    };
    const handleClickOK = () => {
        dispatch(set_value(["open_token_error_dialog", false]));
    };

    useEffect(() => {
        dispatch(initialize_table());
    }, []);

    useEffect(() => {
        GetHistory(tableState.page);
    }, [tableState.page]);

    return (
        <>
            <h1>来店履歴</h1>
            <HistoryTable
                rows={thisState.shop_history}
                columns={columns}
                recordsPerPageOptions={[tableState.per_page]}
                disableColumnMenu={true}
            />
            <Error500Handling open={thisState.open_error_dialog} GET={true} onClose={handleCloseError} />
            <TokenErrorDialog open={thisState.open_token_error_dialog} onClickOK={handleClickOK} />
        </>
    );
};

export default S_couponUseHistory;
