import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { ValidationText } from "~CJS/atoms/TextFields";
import SelectPrefectures from "~CJS/atoms/SelectPrefectures";
import SelectSort from "~CJS/atoms/SelectSort";
import SelectStatus from "~CJS/atoms/SelectStatus";
import { SwitchInput } from "~CJS/atoms/SwitchInput";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapForm: {
      display: "flex",
      justifyContent: "center",
      width: "95%",
      margin: `${theme.spacing(0)} auto`,
      flexWrap: "wrap",
      padding: "1rem",
      border: "solid 1px"
    },
    wrapText: {
      width: "100%"
    },
    button: {
      //margin: theme.spacing(1),
    }
  })
);

const OfferSearchCriteria = (props) => {
  const { StertSerch, count, children, criteriaState, ...other } = props;
  const classes = useStyles();
  const submit_ref = React.useRef(null);

  const order_list = [
    {
      label: "人気順",
      value: "1",
      column: "audition_count",
      order: "desc"
    },
    { label: "登録順", value: "2", column: "created_at", order: "desc" },
    { label: "更新順", value: "3", column: "updated_at", order: "desc" },
    { label: "名前順", value: "4", column: "offer_title", order: "desc" },
    {
      label: "締め切り近い順",
      value: "5",
      column: "entry_close",
      order: "asc"
    },
    {
      label: "応募数が少ない順",
      value: "6",
      column: "audition_count",

      order: "asc"
    }
  ];

  const status_list = [
    {
      label: "申請中",
      value: "0"
    },
    {
      label: "公開",
      value: "1"
    },
    {
      label: "否認",
      value: "2"
    },
    {
      label: "非公開",
      value: "3"
    },
    {
      label: "全て",
      value: ""
    }
  ];

  const [genre, set_genre] = React.useState("");
  const [sort, set_sort] = React.useState(1);
  const [tag_select_list, set_tag_select] = React.useState([]);
  const [status, set_status] = React.useState("0");
  const [page, set_page] = React.useState(1);
  const [limit, set_limit] = React.useState(12);
  const [before_criteria, set_before_criteria] = React.useState("");

  const [first_time, set_first_time] = React.useState(true);
  // const [no_entry_close_flag, set_no_entry_close_flag] = React.useState(true);

  useEffect(() => {
    submit_ref.current.click();
  }, [page]);

  useEffect(() => {
    set_status(criteriaState.status);
  }, [criteriaState.status]);

  useEffect(() => {
    set_limit(criteriaState.limit);
  }, [criteriaState.limit]);
  useEffect(() => {
    if (criteriaState.order) {
      const order_json = JSON.parse(criteriaState.order);
      const order_value = order_json.value ? Number(order_json.value) - 1 : 0;
      set_sort(order_value);
    }
  }, [criteriaState.order]);

  const TagClick = (category_name) => {
    if (tag_select_list?.includes(category_name)) {
      // 選択タグリストから削除
      set_tag_select(tag_select_list.filter((item) => item !== category_name));
    } else {
      set_tag_select([...tag_select_list, category_name]);
    }
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    const elem = e.target.elements;
    const city = elem?.city?.value;
    const company_name = elem?.company_name?.value;
    const prefectures = elem?.prefectures?.value;
    const offer_id = elem?.offer_id?.value;
    const no_entry_close_flag = elem?.no_entry_close_flag?.value;

    const no_page_criteria = {
      prefectures: prefectures && prefectures != "未選択" ? prefectures : "",
      city: city && city != "未選択" ? city : "",
      genre: genre,
      order: JSON.stringify(order_list[sort]),
      tag_select_list: tag_select_list,
      limit: limit,
      status: status,
      company_name: company_name,
      offer_id: offer_id,
      no_entry_close_flag: no_entry_close_flag
    };

    let criteria = { ...no_page_criteria };
    if (Object.is(JSON.stringify(no_page_criteria), JSON.stringify(before_criteria))) {
      set_before_criteria(no_page_criteria);
      criteria["page"] = page;
    } else {
      set_before_criteria(no_page_criteria);
      set_page(1);
      criteria["page"] = "1";
    }

    if (first_time == true && criteriaState.page) {
      StertSerch && StertSerch(criteriaState);
      set_first_time(false);
      return;
    }
    set_first_time(false);

    StertSerch && StertSerch(criteria);
  };
  const handleChangePage = (event, new_page) => {
    set_page(new_page);
  };

  return (
    <React.Fragment>
      <form onSubmit={HandleSubmit} className={classes.wrapForm}>
        <SelectPrefectures value={criteriaState.prefectures} city={{ cityValue: criteriaState.city }} />
        <SelectStatus
          status_list={status_list}
          value={status}
          onChange={(e) => set_status(e.currentTarget.value)}
          fullWidth
          style={{ width: "100%", marginBottom: "1rem" }}
          InputLabelProps={{
            shrink: true
          }}
        />

        <SwitchInput label="募集中のみ" name="no_entry_close_flag" value={criteriaState.no_entry_close_flag} />

        <SelectSort order_list={order_list} value={sort} onChange={(e) => set_sort(e.currentTarget.value)} />
        <ValidationText
          name="company_name"
          variant="outlined"
          inputProps={{
            maxLength: 254
          }}
          value={criteriaState.company_name}
          // value={}
          // onChange={}
          label="企業名"
        ></ValidationText>
        <ValidationText
          name="offer_id"
          variant="outlined"
          inputProps={{
            maxLength: 11
          }}
          value={criteriaState.offer_id}
          label="オファーID"
        ></ValidationText>

        <Button variant="contained" color="primary" type="submit" fullWidth ref={submit_ref}>
          検索
        </Button>
      </form>
      {children}
      {count > 1 && (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Pagination count={count} variant="outlined" page={page} color="secondary" onChange={handleChangePage} />
        </Grid>
      )}
    </React.Fragment>
  );
};

export default OfferSearchCriteria;
