import React, { useState, useEffect } from "react";


import ButtonGeneral from "../utils/ButtonGeneral";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";


const AdminFixHome = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.Entry);




    useEffect(() => {
        // dispatch(set_entry_value(["registering_user", true]));
    }, []);

    return (
        <React.Fragment>

        </React.Fragment>
    );
};

export default AdminFixHome;
