import React from "react";
import Form from "react-bootstrap/Form";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogTitle } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
    dialog: {
        minHeight: "10rem",
        minWidth: "80vw",
        borderRadius: "2rem",
    },
    title: {
        fontSize: "1.2rem",
    },
    icon: {
        position: "absolute",
        right: "0",
        top: "0",
    },
    img_dialog: {
        padding: "8px,0px",
    },
    img: {
        width: "100%",
    },
});

const PopupDialog = (props) => {
    const classes = useStyles();
    return (
        <Dialog
            onClose={props.onClose}
            open={props.open}
            className={classes.dialog}
            fullWidth
            TransitionComponent={props.TransitionComponent}
        >
            {props && props.notification_title ? (
                <DialogTitle disableTypography className={classes.title}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {props.notification_title}
                        {/* <IconButton
                            aria-label="close"
                            className={classes.icon}
                            onClick={props.onClose}
                        >
                            <CloseIcon />
                        </IconButton> */}
                    </Grid>
                </DialogTitle>
            ) : (
                ""
            )}

            <IconButton
                aria-label="close"
                className={classes.icon}
                onClick={props.onClose}
            >
                <CloseIcon />
            </IconButton>
            {/* <DialogContent className={classes.img_dialog}> */}
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <img
                    className={classes.img}
                    src={props.image_path ? props.image_path : "null"}
                    onError={(e) => {
                        e.target.onerror = "null";
                        e.target.src = "/images/no_img.png";
                    }}
                />
            </Grid>
            {/* </DialogContent> */}

            <DialogContent dividers>
                <div
                    className="html-preview"
                    dangerouslySetInnerHTML={{
                        __html: props.notification_detail.replace(
                            /\n/g,
                            "<br>"
                        ),
                    }}
                ></div>
            </DialogContent>
            {props.link_url ? (
                    <Button href={props.link_url} onClick={props.onClose} target="_blank" rel="noreferrer" color="primary">確認する</Button>
                ) : (
                    <Button onClick={props.onClose} target="_blank" rel="noreferrer" color="primary">閉じる</Button>
            )}
        </Dialog>
    );
};
export default PopupDialog;
