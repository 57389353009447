import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Stateの初期状態
const initialState = {
    document_list: [],
    document_kubun: 2,
    title1: "",
    title2: "",
    text: "",
    control_kubun: 1,
};

export const GetDocumentAll = createAsyncThunk(
    "管理/ドキュメント一覧取得",
    async (arg, thunkAPI) => {
        const informationState = thunkAPI.getState().Information;
        const dispatch = thunkAPI.dispatch;
        const api_key = informationState.admin.admin_token;

        const params = arg;
        const url = "/api/document_all";

        const response = await axios.get(
            url + "?" + new URLSearchParams(params),
            {
                headers: {
                    "API-KEY": api_key,
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            }
        );

        return response.data;
    }
);
export const CreateDocument = createAsyncThunk(
    "管理/ドキュメント作成",
    async (arg, thunkAPI) => {
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.admin.admin_token;

        const params = arg;
        const url = "/api/create_document";

        const response = await axios.post(url, params, {
            headers: {
                "API-KEY": api_key,
                "Content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector(
                    'meta[name="csrf-token"]'
                ).content,
            },
        });

        return response.data;
    }
);

export const UpdateDocument = createAsyncThunk(
    "管理/ドキュメント修正",
    async (arg, thunkAPI) => {
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.admin.admin_token;

        const params = arg;
        const url = "/api/update_document";

        const response = await axios.put(url, params, {
            headers: {
                "API-KEY": api_key,
                "Content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector(
                    'meta[name="csrf-token"]'
                ).content,
            },
        });

        return response.data;
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "AdminDocumentSetting",
    initialState,
    reducers: {
        initialize: () => initialState,
        set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];

            if (type) {
                state[type][name] = value;
            } else {
                state[name] = value;
            }
        },
    },
    extraReducers: {
        [GetDocumentAll.pending]: (state, action) => {},
        [GetDocumentAll.fulfilled]: (state, action) => {
            const payload = action.payload;
            state.document_list = action.payload;
        },
        [GetDocumentAll.rejected]: (state, action) => {},
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_value, initialize } = slice.actions;
