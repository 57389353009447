import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const FlutterInappwebview = function (arg) {
    const AsyncFunc = createAsyncThunk(arg.type_name, async (arg, thunkAPI) => {
        const { params, handlerName } = arg;

        return await window.flutter_inappwebview.callHandler(
            handlerName,
            params
        );
    });
    return AsyncFunc(arg);
};
