import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { set_value, FetchPolicy } from "../../stores/Policy";
import Error500Handling from "../utils/Error500Handling";

const Policy = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.Policy);

    const GetPolicy = () => {
        dispatch(FetchPolicy());
    };

    const handleClose = () => {
        dispatch(set_value(["open_error_dialog", false]));
    };

    useEffect(() => {
        GetPolicy();
    }, []);

    return (
        <>
            <div className="policy">
                {thisState.policy_body.title1 !== null ? (
                    <h1>{thisState.policy_body.title1}</h1>
                ) : (
                    ""
                )}
                {thisState.policy_body.title2 !== null ? (
                    <h2>{thisState.policy_body.title2}</h2>
                ) : (
                    ""
                )}
                {thisState.policy_body.title3 !== null ? (
                    <h3>{thisState.policy_body.title3}</h3>
                ) : (
                    ""
                )}

                <div
                    className="html-preview"
                    dangerouslySetInnerHTML={{
                        __html: thisState.policy_body.text
                            ? thisState.policy_body.text
                            : "",
                    }}
                ></div>
            </div>
            <Error500Handling
                open={thisState.open_error_dialog}
                GET={true}
                onClose={handleClose}
            />
        </>
    );
};

export default Policy;
