import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import { Grid } from "@material-ui/core/";

const ModalProgress = (props) => {
    return (
        <>
            <Modal open={props.open}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: "100%" }}
                >
                    <CircularProgress size="5rem" />
                </Grid>
            </Modal>
        </>
    );
};

export default ModalProgress;
