import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { useDispatch, useSelector } from "react-redux";

import { BackButton } from "~CJS/atoms/BackButton";
import { LinkButton } from "~CJS/atoms/LinkButton";

import AuditionSheetInput from "~CJS/block/AuditionSheetInput";

import {
    initialize,
    AuditionSeetCreateApi,
} from "~stores/TALENTBANK/AuditionSeet";

const AuditionSeetCreate = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const path = history.location.pathname.split("/");
    const offer_id = path[2];

    // useEffect(() => {
    //     dispatch(initialize());
    //     dispatch(GetDetail({ offer_id: offer_id }));
    // }, []);

    // const OfferDetailState = useSelector((state) => state.Offer.offer_detail);

    const handleSubmit = (e) => {
        e.preventDefault();

        const elements = e.target?.elements;

        const getValue = (name) => {
            let value = "";
            if (elements[name]?.value) {
                value = elements[name]?.value;
            }

            return value;
        };

        const audition_info = {
            offer_id: offer_id,
            talent_id: getValue("talent_id"),
            pr_message: getValue("pr_message"),
        };

        const params = {
            audition_info: audition_info,
            input_option: [],
        };

        dispatch(AuditionSeetCreateApi(params)).then((data) => {
            history.push(`/AuditionList/${offer_id}`);

            // dispatch(GetDetail({ offer_id: offer_id }));
        });

        // history.push(`/AuditionList/${offer_id}`);
    };
    const data = {};

    return (
        <React.Fragment>
            <BackButton history={history} />
            <Typography variant="h1">オーディションシート作成</Typography>

            <Container>
                <form onSubmit={handleSubmit}>
                    <AuditionSheetInput data={data} />
                    <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        type="submit"
                        style={{ width: "96%", margin: "2%", height: "4rem" }}
                    >
                        作成
                    </Button>
                </form>
            </Container>
        </React.Fragment>
    );
};

export default AuditionSeetCreate;
