import React, { useEffect } from "react";
import { MailInput, OneTimeInput } from "../modules/Login";
import { login_set_value, initialize } from "../../stores/Login";
import { set_value } from "../../stores/Information";
import { admin_info_set } from "../../stores/AdminInfoChange";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import { ButtonTextPrimary, ButtonContainedPrimary } from "../utils/Buttons";
import { TextFieldValidation } from "../utils/TextFields";
import HttpFunction from "../utils/HttpFunction";
import Error500Handling from "../utils/Error500Handling";

const PasswordInput = () => {
    const thisState = useSelector((state) => state.Login);
    const dispatch = useDispatch();
    const history = useHistory();
    const [password, setPassword] = React.useState("");
    const [disable, setDisable] = React.useState(false);
    const [open500, setOpen500] = React.useState(false);

    const tryLogin = (e) => {
        e.preventDefault();
        setDisable(true);

        const params = {
            email_address: thisState.admin.email_address,
            password: password,
        };

        HttpFunction("POST", "/api/password_login_admin", params, "")
            .then((data) => {
                // console.log(data);
                dispatch(
                    login_set_value(["error_message", data.error_message])
                );
                if (data.result === "OK") {
                    dispatch(
                        set_value(["admin_token", data.admin_token, "admin"])
                    );
                    dispatch(set_value(["admin_login", true, "admin"]));
                    dispatch(set_value(["showDrawer", true]));

                    if (data.admin_token) {
                        localStorage.setItem(
                            "localStorage_login_token",
                            data.admin_token
                        );
                        localStorage.setItem(
                            "localStorage_login_type",
                            "admin"
                        );
                    } else {
                        localStorage.removeItem("localStorage_login_type");
                        localStorage.removeItem("localStorage_login_token");
                    }
                    dispatch(initialize());
                    history.push("/AdminHome");
                } else if (data.result === "NG") {
                    setDisable(false);
                } else if (data === 500) {
                    setOpen500(true);
                }
            })
            .catch((err) => {
                // console.log(err);
                setOpen500(true);
            });
    };

    const handleClose500 = () => {
        setOpen500(false);
        setDisable(false);
    };

    const handleForgetPass = () => {
        dispatch(
            admin_info_set(["email_address", thisState.admin.email_address])
        );
        dispatch(admin_info_set(["forget_password", true]));
        dispatch(admin_info_set(["situation", "password"]));
        dispatch(admin_info_set(["fix_type", "email"]));
        history.push("/ImportantChangeAdmin");
    };

    return (
        <>
            {thisState.error_message && (
                <Alert severity="error">
                    <AlertTitle>エラー</AlertTitle>
                    {thisState.error_message}
                </Alert>
            )}
            <form onSubmit={tryLogin}>
                <TextFieldValidation
                    label="パスワード"
                    id="password"
                    type="password"
                    defaultValue={password}
                    onChange={(e) => setPassword(e.target.value)}
                    inputProps={{ maxLength: 255 }}
                    autoFocus
                />
                <div className="text-right">
                    <ButtonContainedPrimary
                        disabled={disable}
                        // onClick={tryLogin}
                        type="submit"
                    >
                        ログイン
                    </ButtonContainedPrimary>
                </div>
            </form>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <ButtonTextPrimary onClick={handleForgetPass}>
                    パスワードをお忘れの方
                </ButtonTextPrimary>
            </Grid>
            <Error500Handling open={open500} onClose={handleClose500} />
        </>
    );
};

const LoginMembers = () => {
    const thisState = useSelector((state) => state.Login);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!thisState.back_flag) dispatch(initialize());
        dispatch(login_set_value(["error_message", ""]));
        dispatch(login_set_value(["back_flag", false]));
        dispatch(login_set_value(["login_mode", "admin"]));
        dispatch(set_value(["showDrawer", false]));
        dispatch(set_value(["showBottomNavigation", false]));
    }, []);

    return (
        <>
            <div
                style={{
                    marginRight: "auto",
                    maxWidth: "700px",
                    marginLeft: "auto",
                }}
            >
                <h2>管理者ログイン</h2>
                {thisState.email_flag && <MailInput />}
                {thisState.onetime_flag && <OneTimeInput />}
                {thisState.password_flag && <PasswordInput />}
            </div>
        </>
    );
};

export default LoginMembers;
