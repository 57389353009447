import React from "react";
import Form from "react-bootstrap/Form";
import TextField from "@material-ui/core/TextField";
const SelectSex = (props) => {
    return (
        <>
            <TextField
                style={{ width: "100%" }}
                select
                name="sex"
                label="性別"
                fullWidth
                variant="outlined"
                SelectProps={{
                    native: true,
                }}
                {...props}
            >
                <option value={2}>女性</option>
                <option value={1}>男性</option>
                <option value={3}>その他</option>
            </TextField>
        </>
    );
};

export default SelectSex;
