const ShopStoreApplicationHoursCheck = (releaseInfoState) => {
    const hours_list = {
        sunday_opening_time: "日曜日の営業開始時間",
        monday_opening_time: "月曜日の営業開始時間",
        tuesday_opening_time: "火曜日の営業開始時間",
        wednesday_opening_time: "水曜日の営業開始時間",
        thursday_opening_time: "木曜日の営業開始時間",
        friday_opening_time: "金曜日の営業開始時間",
        saturday_opening_time: "土曜日の営業開始時間",
        sunday_closing_time: "日曜日の営業終了時間",
        monday_closing_time: "月曜日の営業終了時間",
        tuesday_closing_time: "火曜日の営業終了時間",
        wednesday_closing_time: "水曜日の営業終了時間",
        thursday_closing_time: "木曜日の営業終了時間",
        friday_closing_time: "金曜日の営業終了時間",
        saturday_closing_time: "土曜日の営業終了時間",
    };

    const error_hours_list = Object.keys(hours_list).reduce((accumulator ,current_value) => {
        let check = releaseInfoState[current_value];
        if(check && 
            check.length > 5) check = check.substr(0,check.length - 3);
        if(check){
            if(check == "--:--")
            {
                releaseInfoState[current_value] = "";
            }
            else if(!(check).match(/^\d{2}[:][0-5][0-9]$/))
            {
                accumulator.push(" " + hours_list[current_value] + " ");
            }
        }
        return accumulator;
      },[])

    if (error_hours_list.length > 0) {
        return ["エラー", error_hours_list + "を「00:00」の形式または「--:--」に設定してください", "warning"];
    }

    return null; //エラー無し
}

export default ShopStoreApplicationHoursCheck;