import React, { useEffect } from "react";
import HistoryTable from "../modules/HistoryTable";
import { initialize_table } from "../../stores/HistoryTable";
import { set_value, FetchHistory, GetPointSum } from "../../stores/U_pointHistory";
import PointHistoryDialog from "../modules/PointHistoryDialog";
import { useDispatch, useSelector } from "react-redux";
import TokenErrorDialog from "../utils/TokenErrorDialog";
import Error500Handling from "../utils/Error500Handling";

//列定義
const columns = [
    {
        field: "created_at",
        headerName: "日時",
        headerAlign: "center",
        width: 125,
        sortable: false,
        valueFormatter: (params) => {
            const formattedDate = params.value
                .substr(2, 14)
                .replace(/-/g, "/")
                .replace("T", " ");
            return formattedDate;
        },
    },
    {
        field: "point",
        headerName: "パカッティー",
        type: "number",
        headerAlign: "center",
        width: 109,
        sortable: false,
        valueFormatter: (params) => `${params.value} P`,
    },
    {
        field: "reason",
        headerName: "イベント",
        headerAlign: "center",
        flex: 1,
        minWidth: 150,
    },
];

const U_pointHistory = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.U_pointHistory);
    const informationState = useSelector((state) => state.Information);
    const tableState = useSelector((state) => state.HistoryTable);

    const GetHistory = (page) => {
        const params = { page: page };

        dispatch(FetchHistory(params));
        dispatch(GetPointSum());
    };

    const handleCellClick = (params) => {
        const formattedDate = params
            .getValue(params.id, "created_at")
            .substr(2, 14)
            .replace(/-/g, "/")
            .replace("T", " ");
        dispatch(set_value(["selected_date", formattedDate]));
        dispatch(
            set_value(["selected_point", params.getValue(params.id, "point")])
        );
        dispatch(
            set_value(["selected_reason", params.getValue(params.id, "reason")])
        );
        dispatch(set_value(["open_detail_dialog", true]));
    };

    const handleCloseError = () => {
        dispatch(set_value(["open_error_dialog", false]));
    };
    const handleClickOK = () => {
        dispatch(set_value(["open_token_error_dialog", false]));
    };

    useEffect(() => {
        dispatch(initialize_table());
    }, []);

    useEffect(() => {
        GetHistory(tableState.page);
    }, [tableState.page]);

    return (
        <>
            <h1>パカッティー履歴</h1>
            <div style={{ fontSize: "1rem", marginLeft: "15px" }}>
                現在のパカッティー：
                <span style={{ fontSize: "150%", fontWeight: "bold" }}>
                    {informationState.user.point_sum}
                </span>{" P"}
            </div>
            <HistoryTable
                rows={thisState.point_history}
                columns={columns}
                recordsPerPageOptions={[tableState.per_page]}
                onCellClick={handleCellClick}
                needsToolbar={true}
            />
            <PointHistoryDialog />
            <TokenErrorDialog open={thisState.open_token_error_dialog} onClickOK={handleClickOK} />
            <Error500Handling open={thisState.open_error_dialog} GET={true} network={thisState.network_error} onClose={handleCloseError} />
        </>
    );
};

export default U_pointHistory;
