

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/functions/AsyncFunction";

const initialState = {
    blog_list: [],
    currentPage: 1,
    total: 0,
};

export const GetBlogList = (arg) => {
    return AsyncFunction({
        type_name: "BlogSetting/ブログ一覧取得",
        method: "get",
        url: "/api/GetBlogList",
        params: arg,
        api_key: "admin",
    });
};
export const PostBlog = (arg) => {
    return AsyncFunction({
        type_name: "BlogSetting/ブログ投稿",
        method: "post",
        url: "/api/PostBlog",
        params: arg,
        api_key: "admin",
    });
};
export const DeleteBlog = (arg) => {
    return AsyncFunction({
        type_name: "BlogSetting/ブログ削除",
        method: "delete",
        url: "/api/DeleteBlog",
        params: arg,
        api_key: "admin",
    });
};

const slice = createSlice({
    name: "BlogSetting",
    initialState,
    reducers: {
        BlogSettingSet: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) =>
                action.type.match("BlogSetting") &&
                action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;
                if (payload?.blog_list) {
                    state.blog_list = payload?.blog_list;
                }
                if (payload?.currentPage) {
                    state.currentPage = payload?.currentPage;
                }
                if (payload?.total) {
                    state.total = payload?.total;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { BlogSettingSet } = slice.actions;
