import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router";

import { BackButton } from "~CJS/atoms/BackButton";

import { LinkButton } from "~CJS/atoms/LinkButton";

import CompanyCard from "~CJS/block/CompanyCard";
import CompanySearchCriteria from "~CJS/block/CompanySearchCriteria";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { initialize, GetCompanyList } from "~stores/TALENTBANK/Company";

const SponsorSearch = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const CompanyState = useSelector((state) => state.Company);
    const company_list = CompanyState.company_list;
    useEffect(() => {
        // dispatch(GetCompanyList(params)).then((action) => {
        //     //     if (action.payload.result == "1") {
        //     //         history.push(
        //     //             `/OfferDetail/${action.payload?.offer_info?.offer_id}`
        //     //         );
        //     //         alert("お仕事を作成しました。");
        //     //     }
        // });
        // dispatch(set_entry_value(["registering_user", true]));
    }, []);

    const link_detail = (company_id) => {
        history.push(`/CompanyFix/${company_id}`);
    };

    const link_talk_list = (company_id) => {
        history.push(`/ChatList/${company_id}`);
    };

    const StertSerch = (params) => {
        dispatch(GetCompanyList(params)).then((action) => {
            //     if (action.payload.result == "1") {
            //         history.push(
            //             `/OfferDetail/${action.payload?.offer_info?.offer_id}`
            //         );
            //         alert("お仕事を作成しました。");
            //     }
        });
    };

    return (
        <React.Fragment>
            <Container>
                <ButtonNavi
                    left_label="Home画面"
                    left_link="/AdminHome"
                    right_label="企業作成"
                    right_link="/CompanyCreate"
                />
                <Typography variant="h1"> 企業検索</Typography>
                <Grid item style={{ margin: "10px", fontSize: "x-large" }}>
                    総企業数
                    <div
                        style={{
                            display: "inline-block",
                            fontSize: "xx-large",
                            fontWeight: "bold",
                            marginLeft: "0.5rem",
                        }}
                    >
                        {CompanyState?.company_serch?.total
                            ? CompanyState.company_serch.total
                            : 0}
                    </div>
                    社
                </Grid>
                <CompanySearchCriteria
                    StertSerch={StertSerch}
                    count={CompanyState.company_serch.page_count}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        // alignItems="center"
                    >
                        {CompanyState.company_list.map((item, index) => (
                            <React.Fragment key={index}>
                                <CompanyCard
                                    data={item}
                                    link_detail={link_detail}
                                    link_talk_list={link_talk_list}
                                    admin
                                />
                            </React.Fragment>
                        ))}
                    </Grid>
                </CompanySearchCriteria>
            </Container>
        </React.Fragment>
    );
};

export default SponsorSearch;
