import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { LinkButton } from "~CJS/atoms/LinkButton";
import StatusCard from "~CJS/block/StatusCard";
import { makeStyles } from "@material-ui/core/styles";
import { getStringFromDate } from "~CJS/functions/Time";

const LINES_TO_SHOW = 3;
const useStyles = makeStyles({
  multiLineEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": LINES_TO_SHOW,
    "-webkit-box-orient": "vertical",
  },
  card: {
    border: "solid 1px",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, .5)",
    position: "relative",
    backgroundColor: "#fff",
    maxWidth: "400px",
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: "0.5rem",
    marginTop: "0.5rem",
  },
  time_limit_card: {
    backgroundColor: "gray",
  },
  disabledImage: {
    "-webkit-filter": "grayscale(1)",
    filter: "gray",
    filter: "grayscale(1)",
    cursor: "default",
  },
});

const OfferCard = (props) => {
  const {
    offer_id,
    offer_title,
    created_at,
    prefectures,
    city,
    job_start_time,
    job_end_time,
    entry_close,
    job_date,
    job_detail,
    guarantee,
    audition_count,
    image_small,
    status,
  } = props.data;
  const classes = useStyles();

  let nextDayOfJob = new Date(entry_close);
  // nextDayOfJob.setDate(nextDayOfJob.getDate() + 1);
  const now = new Date();

  // newの判定 1ヶ月以上経過しているかどうか
  const created_date = Date.parse(created_at);
  const elapsed = now - created_date;
  const new_flag = elapsed < 2678400000 ? true : false;
  const time_limit_flag = Date.parse(entry_close.replaceAll("-", "/")) - now < 0 ? true : false;
  const arr_date = entry_close ? entry_close.split(" ")[0].split("-") : ["", "", ""];
  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        className={`cards ${classes.card} ${time_limit_flag && classes.time_limit_card}`}
      >
        <Grid container direction="column">
          <StatusCard status={status} id={offer_id} />
        </Grid>

        <Grid
          style={{
            width: "inherit",
          }}
        >
          <Grid
            style={{
              padding: "0.8rem",
              width: "100%",
              width: "inherit",
            }}
          >
            {new_flag && !time_limit_flag ? (
              <p
                style={{
                  backgroundImage: "URL(/images/talentBank/test/new.png)",
                  left: "auto",
                  right: "0",
                  backgroundSize: "55px 55px",
                  height: "55px",
                  width: "55px",
                  position: "absolute",
                  marginTop: "-0.8rem",
                }}
              ></p>
            ) : (
              ""
            )}
            {time_limit_flag ? (
              <p
                style={{
                  backgroundImage: "URL(/images/closed.png)",
                  left: "auto",
                  right: "0",
                  backgroundSize: "140px 140px",
                  height: "140px",
                  width: "140px",
                  position: "absolute",
                  marginTop: "-0.8rem",
                }}
              ></p>
            ) : (
              ""
            )}
            <Grid container style={{ fontSize: "1.5rem", width: "100%" }}>
              <Typography noWrap style={{ fontSize: "1.5rem", width: "100%" }}>
                {offer_title}
              </Typography>
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
              <img
                src={image_small ? image_small : "null"}
                style={{ width: "150px", height: "150px" }}
                className={`${time_limit_flag && classes.disabledImage}`}
                onError={(e) => {
                  e.target.onerror = "null";
                  e.target.src = "/images/no_image_150px_150px.png";
                }}
              />

              <Grid item xs>
                【​場所】
                <Typography noWrap align="right">
                  {prefectures && " " + prefectures}
                  {city && city != "未選択" && " " + city}
                </Typography>
                【ギャランティ】
                <Typography noWrap align="right">
                  {guarantee}
                </Typography>
                【応募締め切り】
                <Typography noWrap align="right">
                  {arr_date[0] + "年" + arr_date[1] + "月" + arr_date[2] + "日"}
                </Typography>
              </Grid>
            </Grid>
            【現在の応募数】
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
              {audition_count}人
            </Grid>
            {job_detail && (
              <Grid>
                【仕事内容】
                <Typography className={classes.multiLineEllipsis}>{job_detail}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" style={{ marginTop: "auto" }}>
          <LinkButton
            style={{
              color: "#fff",
              backgroundColor: "#eb6100",
              marginBottom: "0px",
              width: "100%",
            }}
            to={`/OfferDetail/${offer_id}`}
            label="詳細を確認"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default OfferCard;
