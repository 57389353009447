import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    TextFieldNormal,
    TextFieldReadOnly,
    TextFieldValidation,
    errorJudge,
} from "../utils/TextFields";
import {
    ButtonContainedPrimary,
    ButtonTextPrimary,
    ButtonOutlinedPrimary,
} from "../utils/Buttons";
import { admin_info_set, initialize, SendMail, SendOnetime, SendPass } from "../../stores/AdminInfoChange";
import { set_value } from "../../stores/Information";
import OneTimePassword from "./OneTimePassword";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import AdminError500Handling from "../utils/AdminError500Handling";
import Button from "@material-ui/core/Button";
import TokenErrorDialog from "../utils/TokenErrorDialog";
import { login_set_value } from "../../stores/Login";

const useStyles = makeStyles((theme) => ({
    button: {
        display: "block",
        margin: "auto",
    },
    stop_dialog: {
        "& .MuiDialog-paper": {
            padding: "15px",
        },
    },
    error_dialog: {
        "& .MuiDialog-paper": {
            padding: "15px",
            border: "2px solid orangered",
            display: "block",
        },
    },
}));

const StopButton = (props) => {
    const {disabled} = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const thisState = useSelector((state) => state.AdminInfoChange);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleStop = () => {
        dispatch(set_value(["showDrawer", true]));
        dispatch(initialize());
        if (thisState.forget_password) dispatch(login_set_value(["back_flag", true]));
        history.goBack();
    };

    return (
        <>
            <IconButton disabled={disabled} onClick={handleOpen} style={{ padding: "16px 0 24px" }}>
                <NavigateBeforeIcon />
                <span style={{ fontSize: "70%" }}>戻る</span>
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                className={classes.stop_dialog}
            >
                <p>変更手続きを中止します。よろしいですか？</p>
                <Grid container style={{ textAlign: "center" }}>
                    <Grid item xs={6}>
                        <ButtonContainedPrimary onClick={handleClose}>
                            キャンセル
                        </ButtonContainedPrimary>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonOutlinedPrimary onClick={handleStop}>
                            OK
                        </ButtonOutlinedPrimary>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
};

const ImportantChangeAdmin = () => {
    const admin_info_state = useSelector((state) => state.AdminInfoChange);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [sendEmailCount, setCounter] = React.useState(0);
    const [reSendEmailDisabled, setReSendEmailDisabled] = React.useState(false);

    useEffect(() => {
        // console.log("sendEmailCount:"+sendEmailCount);
        if(sendEmailCount === 0) {
            // // console.log("初期値");
        } else if (sendEmailCount < 3) {
            setReSendEmailDisabled(true);
            setTimeout(function () {
                setReSendEmailDisabled(false);
            },30000);
        } else {
            setReSendEmailDisabled(true);
        }
    },[sendEmailCount]);

    const handleBlur = (e) => {
        dispatch(admin_info_set([e.target.name, e.target.value]));
    };
    const handleCloseError = () => {
        dispatch(admin_info_set(["open_error_dialog", false]));
    };
    const handleClickOK = () => {
        dispatch(initialize());
    };

    // メアド送信ページの変数・関数
    const handleSend = () => {
        dispatch(admin_info_set(["disabled_mail_button", true]));
        dispatch(admin_info_set(["onetime_error", false]));

        const email =
            admin_info_state.situation === "email_address"
                ? admin_info_state.new_email_address
                : admin_info_state.email_address;
        const params = admin_info_state.forget_password
            ? {email_address: email}
            : {email_address_new: email};
        if(sendEmailCount < 3){
            dispatch(SendMail(params));
            if(admin_info_state.fix_type === "onetime"){
                setCounter(sendEmailCount + 1);
            }
        }
    };

    // ワンタイムパスページの変数・関数
    const handleConfirm = () => {
        dispatch(admin_info_set(["disabled_onetime_button", true]));

        const email =
            admin_info_state.situation === "email_address"
                ? admin_info_state.new_email_address
                : admin_info_state.email_address;
        const params = admin_info_state.forget_password
            ? {
                email_address: email,
                one_time: admin_info_state.onetime,
            } : {
                email_address_new: email,
                one_time: admin_info_state.onetime,
            };

        dispatch(SendOnetime(params));
    };

    // パスワードページの変数・関数
    const [error, setError] = React.useState({
        password: null,
        password2: null,
    });
    const [showPass, setShowPass] = React.useState(false);

    const handleChangePassword = (compare) => (e) => {
        const diff = e.target.value !== admin_info_state[compare];
        setError({ ...error, ["password2"]: diff });
    };
    const handleChangeShowPass = () => {
        setShowPass(!showPass);
    };
    const trueError = (name) => {
        setError({ ...error, [name]: true });
    };
    const falseError = (name) => {
        setError({ ...error, [name]: false });
    };

    const handleChange = () => {
        dispatch(admin_info_set(["disabled_pass_button", true]));

        const email =
            admin_info_state.situation === "email_address"
                ? admin_info_state.new_email_address
                : admin_info_state.email_address;
        const params = admin_info_state.forget_password
            ? {
                email_address: email,
                password: admin_info_state.password,
                updated_at: admin_info_state.updated_at,
            } : {
                email_address_new: email,
                password: admin_info_state.password,
                updated_at: admin_info_state.updated_at,
            };

        dispatch(SendPass(params));
    };

    const handleBackToStart = () => {
        dispatch(admin_info_set(["open_old_error", false]));
        setError({ password: null, password2: null });
        dispatch(admin_info_set(["new_email_address", ""]));
        dispatch(admin_info_set(["password", ""]));
        dispatch(admin_info_set(["password2", ""]));
        dispatch(admin_info_set(["fix_type", "email"]));
    };

    // 完了ページの関数
    const handleLogin = () => {
        dispatch(initialize());
        dispatch(set_value(["admin_login", true, "admin"]));
        dispatch(set_value(["showDrawer", true]));
        history.push("/AdminHome");
    };

    return (
        <>
            <div style={{ padding: "15px 0 0 15px" }}>
                <StopButton disabled={admin_info_state.fix_type === "completed"} />
            </div>
            <div style={{ padding: "0 30px 0" }}>
                {admin_info_state.fix_type === "email" && (
                    <>
                        {admin_info_state.situation === "password" && (
                            <>
                                <h2>パスワード変更</h2>
                                <p>
                                    下記のメールアドレスに、本人確認の為のワンタイムパスワードを送信いたします。認証後、新たなパスワードを設定いただき、パスワード変更が完了となります。
                                </p>
                                <TextFieldReadOnly
                                    id="email"
                                    label="メールアドレス"
                                    value={admin_info_state.email_address}
                                />
                            </>
                        )}
                        {admin_info_state.situation === "email_address" && (
                            <>
                                <h2>メールアドレス変更</h2>
                                {admin_info_state.error_message && (
                                    <Alert severity="error">
                                        <AlertTitle>エラー</AlertTitle>
                                        {admin_info_state.error_message}
                                    </Alert>
                                )}
                                <p>
                                    新しいメールアドレスを入力してください。そのアドレスに、本人確認の為のワンタイムパスワードを送信いたします。認証後、再度パスワードをご設定いただくとメールアドレス変更が完了となります。
                                </p>
                                <TextFieldValidation
                                    id="email"
                                    name="new_email_address"
                                    label="メールアドレス"
                                    type="email"
                                    onBlur={handleBlur}
                                    inputProps={{ maxLength: 255 }}
                                    autoFocus
                                />
                            </>
                        )}
                        <ButtonContainedPrimary
                            onClick={handleSend}
                            disabled={admin_info_state.disabled_mail_button}
                            className={classes.button}
                        >
                            送信
                        </ButtonContainedPrimary>
                    </>
                )}
                {admin_info_state.fix_type === "onetime" && (
                    <>
                        <h2>アドレス認証</h2>
                        {admin_info_state.onetime_error && (
                            <Alert severity="error">
                                <AlertTitle>エラー</AlertTitle>
                                {1 <= admin_info_state.login_failed &&
                                admin_info_state.login_failed <= 4 ? (
                                    <>
                                        正しいワンタイムパスワードを入力してください。（5回失敗するとワンタイムパスワードが無効化されます。）
                                        <br />
                                        {`認証失敗回数：${admin_info_state.login_failed}回`}
                                    </>
                                ) : (
                                    "ワンタイムパスワードが無効化されています。新しいワンタイムパスワードを発行するには、「メールの再送信」をクリックしてください。"
                                )}
                            </Alert>
                        )}
                        {3 <= sendEmailCount && (
                            <Alert severity="info">
                                <AlertTitle>メッセージ</AlertTitle>
                                再送上限に達しました。これ以上の再送信はできません。
                                <br/>
                                再度ワンタイムパスワードを発行する場合はお手数ですが最初からやり直してください。
                            </Alert>
                        )}
                        <OneTimePassword name="onetime" onBlur={handleBlur} helperText="メールアドレスに送信された4桁のパスワードをご確認ください。" autoFocus/>
                        <ButtonContainedPrimary
                            onClick={handleConfirm}
                            disabled={admin_info_state.disabled_onetime_button}
                            className={classes.button}
                        >
                            送信
                        </ButtonContainedPrimary>
                        <ButtonTextPrimary
                            onClick={handleSend}
                            className={classes.button}
                            style={{textTransform: "none"}}
                            disabled={reSendEmailDisabled}
                        >
                            {admin_info_state.situation === "email_address"
                                ? `メールが届かない場合：「${admin_info_state.new_email_address}」へ再送信`
                                : "メールが届かない場合：再送信"}
                        </ButtonTextPrimary>
                    </>
                )}
                {admin_info_state.fix_type === "new_password" && (
                    <>
                        <h2>パスワード変更</h2>
                        {admin_info_state.situation === "email_address" && (
                            <p>
                                変更を希望しない場合は、現在ご使用中のパスワードをご入力ください。
                            </p>
                        )}
                        <TextFieldValidation
                            label="パスワード"
                            discription="半角英数字7文字以上で設定してください。アルファベットは大文字と小文字を区別します。"
                            id="password"
                            name="password"
                            inputProps={{
                                minLength: 7,
                                pattern: "^[a-zA-Z0-9]*$",
                                maxLength: 255,
                            }}
                            type={showPass ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleChangeShowPass}
                                        >
                                            {showPass ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={handleChangePassword("password2")}
                            onBlur={handleBlur}
                            trueError={() => trueError("password")}
                            falseError={() => falseError("password")}
                            autoFocus
                        />
                        <TextFieldNormal
                            id="password2"
                            name="password2"
                            label="パスワード（確認）*"
                            type={showPass ? "text" : "password"}
                            error={error.password2}
                            helperText={
                                error.password2 &&
                                "上で入力したパスワードと一致させてください。"
                            }
                            inputProps={{ maxLength: 255 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleChangeShowPass}
                                        >
                                            {showPass ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={handleChangePassword("password")}
                            onBlur={handleBlur}
                        />
                        <ButtonContainedPrimary
                            disabled={errorJudge(error) || admin_info_state.disabled_pass_button}
                            onClick={handleChange}
                            className={classes.button}
                        >
                            変更
                        </ButtonContainedPrimary>
                        <Dialog
                            open={admin_info_state.open_old_error}
                            className={classes.error_dialog}
                        >
                            <p>
                                表示されているデータが古いため更新できませんでした。最初からやり直してください。
                            </p>
                            <Button
                                variant="contained"
                                className={classes.button}
                                onClick={handleBackToStart}
                            >
                                OK
                            </Button>
                        </Dialog>
                    </>
                )}
                {admin_info_state.fix_type === "completed" && (
                    <>
                        <h2>変更完了</h2>
                        <ButtonContainedPrimary
                            onClick={handleLogin}
                            className={classes.button}
                        >
                            確認
                        </ButtonContainedPrimary>
                    </>
                )}
            </div>
            <AdminError500Handling
                open={admin_info_state.open_error_dialog}
                onClose={handleCloseError}
            />
            <TokenErrorDialog open={admin_info_state.open_token_error_dialog} onClickOK={handleClickOK} />
        </>
    );
};

export default ImportantChangeAdmin;
