import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import QRCode from "qrcode.react";
import Countdown from "react-countdown";
import CachedIcon from "@material-ui/icons/Cached";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { set_value } from "../../stores/QRCodeDisplay";
import { UserInfoGet } from "../../stores/Information";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import HttpFunction from "../utils/HttpFunction";

// カード画像フィフティーカード
import fifty_card_white from "/images/card/fifty_card_white.png";
import fifty_card_blue from "/images/card/fifty_card_blue.png";
import fifty_card_green from "/images/card/fifty_card_green.png";
import fifty_card_pink from "/images/card/fifty_card_pink.png";
import fifty_card_yellow from "/images/card/fifty_card_yellow.png";

const fifty_cards = [
    fifty_card_white,
    fifty_card_pink,
    fifty_card_blue,
    fifty_card_green,
    fifty_card_yellow,
    fifty_card_white,
];

//フィフティーカードを表示
export const FiftyCardDisplay = (props) => {
    const { rank_type = 0, ...other } = props;

    return (
        <React.Fragment>
            <Grid container justifyContent="center" alignItems="center">
                <img className="fifty-card" src={fifty_cards[rank_type]} />
            </Grid>
        </React.Fragment>
    );
};

//会員ランクを表示
export const FiftyRankDisplay = (props) => {
    const {
        rank_color_cd = "white",
        rank_color_name = "ホワイト",
        ...other
    } = props;

    return (
        <React.Fragment>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: "1rem" }}
            >
                <Chip
                    label={`${rank_color_name}会員`}
                    style={{
                        marginTop: "1rem",
                        fontSize: "1.5rem",
                        padding: "1rem",
                        backgroundColor: `#${rank_color_cd}`,
                        border: "0.5px solid #dcdcdc",
                    }}
                />
            </Grid>
        </React.Fragment>
    );
};

//ポイントを表示
export const FiftyPintDisplay = (props) => {
    const { point_sum = "0", ...other } = props;

    return (
        <React.Fragment>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                    width: "90%",
                    border: "solid 0.2rem pink",
                    marginTop: "1rem",
                    padding: "0.5rem",
                    fontSize: "1.3rem",
                    color: "pink",
                    fontWeight: "bold",
                    marginRight: "auto",
                    marginLeft: "auto",
                }}
            >
                {point_sum}パカッティー
            </Grid>
        </React.Fragment>
    );
};
