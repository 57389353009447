import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Switch from "@material-ui/core/Switch";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import ImageInput from "../utils/ImageInput";
import { TextFieldValidOptional } from "../utils/TextFields";
import { set_admin_mascot } from "../../stores/AdminMascot";

const Center = (props) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ margin: "1rem 5%", width: "90%" }}
        >
            {props.children}
        </Grid>
    );
};
const Left = (props) => {
    return (
        <div
            style={{
                textAlign: "left",
                marginLeft: "0",
                marginRight: "auto",
            }}
        >
            {props.children}
        </div>
    );
};

const PreviewImage = (props) => {
    return (
        <Center>
            <img
                style={{ width: "100%" }}
                src={props.image_path ? props.image_path : "null"}
                onError={(e) => {
                    e.target.onerror = "null";
                    e.target.src = "/images/no_img.png";
                }}
            />
        </Center>
    );
};

const MascotInfo = (props) => {
    const { readOnly, onBlur, trueError, falseError, image, toggleRef } = props;
    const thisState = useSelector((state) => state.AdminMascot);
    const dispatch = useDispatch();
    const activeFlag = thisState.active_flag === "0";
    const [active, setActive] = useState(activeFlag);

    const SetImage = (arg) => {
        if (onBlur !== undefined) {
            // onBlurが指定されているとき
            const images = {
                mascot_image_small: arg.image,
                mascot_image_big: arg.image_big,
            };
            onBlur(images);
        } else {
            dispatch(set_admin_mascot(["mascot_image_small", arg.image]));
            dispatch(set_admin_mascot(["mascot_image_big", arg.image_big]));
        }
    };

    const handleBlur = (e) => {
        if (onBlur !== undefined) {
            // onBlurが指定されているとき
            onBlur({ [e.target.id]: e.target.value });
        } else {
            dispatch(set_admin_mascot([e.target.id, e.target.value]));
        }
    };

    const handleChange = (e) => {
        setActive(e.target.checked);
        toggleRef.current.checked = e.target.checked;
    };

    useEffect(() => {
        if (toggleRef?.current) {
            toggleRef.current.checked = thisState.active_flag === "0";
        }
    }, []);

    return (
        <>
            <Grid container style={{ justifyContent: "center" }}>
                <Grid item xs={4}>
                    <Center>
                        <PreviewImage
                            image_path={
                                image
                                    ? image
                                    : thisState.mascot_image_big
                                    ? thisState.mascot_image_big
                                    : "/images/no_img.png"
                            }
                        />
                    </Center>
                    {readOnly ? (
                        ""
                    ) : (
                        <ImageInput
                            comment={false}
                            onClick={SetImage}
                            width={200}
                            height={200}
                            image_big={true}
                            widthBig={500}
                            heightBig={500}
                        />
                    )}
                </Grid>
            </Grid>

            <Center>
                <Left>名前</Left>
                <TextFieldValidOptional
                    id="mascot_name"
                    required
                    defaultValue={thisState.mascot_name}
                    inputProps={{ maxLength: 255 }}
                    InputProps={{ readOnly: readOnly }}
                    onBlur={handleBlur}
                    trueError={() => trueError("mascot_name")}
                    falseError={() => falseError("mascot_name")}
                />
            </Center>
            <Center>
                <Left>パカッティー数</Left>
                <TextFieldValidOptional
                    id="mascot_point"
                    required
                    defaultValue={thisState.mascot_point}
                    inputProps={{ maxLength: 8, pattern: "^[-]?[0-9]+$" }}
                    InputProps={{ readOnly: readOnly }}
                    onBlur={handleBlur}
                    trueError={() => trueError("mascot_point")}
                    falseError={() => falseError("mascot_point")}
                />
            </Center>
            <Center>
                <Left>アクセスボーナスのタイトル</Left>
                <TextFieldValidOptional
                    id="draw_title"
                    required
                    defaultValue={thisState.draw_title}
                    inputProps={{ maxLength: 255 }}
                    InputProps={{ readOnly: readOnly }}
                    onBlur={handleBlur}
                    trueError={() => trueError("draw_title")}
                    falseError={() => falseError("draw_title")}
                />
            </Center>
            <Center>
                <Left>アクセスボーナスの文章</Left>
                <TextareaAutosize
                    style={{
                        fontSize: "1rem",
                        borderRadius: "1px",
                        boxSizing: "content-box",
                        whidth: "100%",
                        border: "solid 1px gray",
                        padding: "0.5rem",
                    }}
                    minRows={3}
                    id="draw_detail"
                    className="FlexTextarea"
                    as="textarea"
                    defaultValue={thisState.draw_detail}
                    maxLength={10000}
                    readOnly={readOnly}
                    onBlur={handleBlur}
                />
            </Center>
            <Center>
                <Left>アクセスボーナスにリンク付けするURL (1つまで)</Left>
                <TextareaAutosize
                    style={{
                        fontSize: "1rem",
                        borderRadius: "1px",
                        boxSizing: "content-box",
                        whidth: "100%",
                        border: "solid 1px gray",
                        padding: "0.5rem",
                    }}
                    minRows={3}
                    id="mascot_url"
                    className="FlexTextarea"
                    as="textarea"
                    defaultValue={thisState.mascot_url}
                    maxLength={10000}
                    readOnly={readOnly}
                    onBlur={handleBlur}
                />
            </Center>
            <Center>
                <Left>備考・メモ</Left>
                <TextareaAutosize
                    style={{
                        fontSize: "1rem",
                        borderRadius: "1px",
                        boxSizing: "content-box",
                        whidth: "100%",
                        border: "solid 1px gray",
                        padding: "0.5rem",
                    }}
                    minRows={3}
                    id="memo"
                    className="FlexTextarea"
                    as="textarea"
                    defaultValue={thisState.memo}
                    maxLength={10000}
                    readOnly={readOnly}
                    onBlur={handleBlur}
                />
            </Center>
            <Center>
                <Left>
                    ステータス：
                    {readOnly ? (
                        <Chip
                            label={
                                thisState.active_flag === "0"
                                    ? "公開中"
                                    : "非公開"
                            }
                            color={
                                thisState.active_flag === "0"
                                    ? "primary"
                                    : "default"
                            }
                        />
                    ) : (
                        <>
                            非公開
                            <Switch
                                ref={toggleRef}
                                checked={active}
                                onChange={handleChange}
                            />
                            公開
                        </>
                    )}
                </Left>
            </Center>
        </>
    );
};

const fakeFunction = (arg) => {};

// propsが渡されなかった時のデフォルト値
MascotInfo.defaultProps = {
    trueError: fakeFunction,
    falseError: fakeFunction,
};

export default MascotInfo;
