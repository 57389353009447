import React from "react";
import HistoryTable from "../modules/HistoryTable";
import Button from '@material-ui/core/Button';


//仮データ
const rows = [
    {id: 1, shop: 'GOLD HAIR SALON', dateTime: "07月07日07時07分", genre: "美容院", user: "のび太", dayOfWeek: "水"},
    {id: 2, shop: 'マリンリゾート　MARINEQ', dateTime: "07月07日07時07分", genre: "飲食院", user: "どらえもん", dayOfWeek: "水"},
    {id: 3, shop: 'HANAYA OSAKA', dateTime: "07月07日07時07分", genre: "花", user: "スネ夫", dayOfWeek: "水"},
    {id: 4, shop: '蓮鍼灸院', dateTime: "07月07日07時07分", genre: "美容鍼", user: "ジャイアン", dayOfWeek: "水"},
    {id: 5, shop: 'oRest Gym®', dateTime: "07月07日07時07分", genre: "ヨガ・ジム", user: "しずか", dayOfWeek: "水"},
    {id: 6, shop: 'beauty up', dateTime: "08月04日11時03分", genre: "ネイルサロン", user: "のび太", dayOfWeek: "水"},
    {id: 7, shop: 'GOLD HAIR SALON', dateTime: "08月05日11時30分", genre: "美容院", user: "どらえもん", dayOfWeek: "木"},
    {id: 8, shop: '有印良品', dateTime: "08月05日15時00分", genre: "エコ雑貨", user: "スネ夫", dayOfWeek: "木"},
    {id: 9, shop: 'oRest Gym®', dateTime: "08月07日10時30分", genre: "ヨガ・ジム", user: "ジャイアン", dayOfWeek: "土"},
]

//列定義
const columns = [
    {field: 'shop', headerName: '加盟店名', flex: 1, minWidth: 280},
    {field: 'dateTime', headerName: '時間', flex: 0.5, minWidth: 150},
    {field: 'genre', headerName: 'ジャンル', flex: 0.5, minWidth: 150, hide: true},
    {field: 'user', headerName: '利用者名', flex: 0.5, minWidth: 150, hide: true},
    {field: 'dayOfWeek', headerName: '曜日', flex: 0.4, minWidth: 120, hide: true},
    {
        //ボタンの列。便宜上idをfieldとしている
        field: 'id', headerName: '詳細', width: 150, sortable: false, filterable: false, selectable: false,
        renderCell: (params) => (
            <Button variant="contained" color="secondary" href="https://www.sanrio.co.jp/" >詳細</Button>
        ),
    },
];

const AdminCouponUseHistory = () => {    
    return (
        <React.Fragment>
            
            <HistoryTable
                rows={rows}
                columns={columns}
                recordsPerPage={5}
                recordsPerPageOptions={[5, 10]}
            />
        </React.Fragment>
    );
};

export default AdminCouponUseHistory;