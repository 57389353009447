import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import user from "./user";

// create year_list
const date = new Date();
const thisYear = date.getFullYear();
const defaultYearMonth = (thisYear - 35).toString() + "-06";

// const dt = new Date();
// const year = dt.getFullYear(); //年
// const month = ("0" + (dt.getMonth() + 1)).slice(-2); //月
// const day = ("0" + dt.getDate()).slice(-2);
// const Hours = ("0" + dt.getHours()).slice(-2);
// const Minutes = ("0" + dt.getMinutes()).slice(-2);
// const Seconds = ("0" + dt.getSeconds()).slice(-2);
// const now = `test${month}${day}${Hours}${Minutes}${Seconds}@digitalsolutions.co.jp`;

// Stateの初期状態
const initialState = {
    entry_mode: "",
    user: {
        user_id: "",
        token: "",
        activeStep: 0,
        email_flag: true,
        onetime_flag: false,
        policy_flag: false,
        input_flag: false,
        check_flag: false,
        subscription_flag: false,
        fin_flag: false,
        info: {
            nickname: "",
            birth_year_month: "",
            sex: "2",
            prefecture: "",
            password: "",
            password2: "",
            invitation_code: "",
            what_look: "",
        },
        error: {
            nickname: null,
            password: null,
            password2: null,
        },
    },
    shop: {
        email_flag: true,
        onetime_flag: false,
        policy_flag: false,
        input_flag: false,
        check_flag: false,
        fin_flag: false,
        info: {
            shop_id: "",
            shop_password: "",
            name: "",
            password: "",
            password2: "",
            invitation_code: "",
        },
        error: {
            shop_id: null,
            shop_password: null,
            name: null,
            password: null,
            password2: null,
        },
    },
    email: "",
    // email: now,
    error_message: "",
};

export const CreateUserID = createAsyncThunk(
    "Entry/CreateUserID",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const thisState = thunkAPI.getState().Entry;
        const params = {
            email_address: thisState.email,
        };
        const url = "/api/create_user_id";
        try {
            const response = await axios.post(url, params, {
                headers: {
                    // "API-KEY": api_key,
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const PayingUpdate = createAsyncThunk(
    "Entry/PayingUpdate",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const thisState = thunkAPI.getState().Entry;
        const params = {
            user_id: thisState.user.user_id,
        };

        const url = "/api/paying_update";
        try {
            const response = await axios.put(url, params, {
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const CreateGeneralUser = createAsyncThunk(
    "Entry/CreateGeneralUser",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const thisState = thunkAPI.getState().Entry;
        const info = thisState.user.info;
        const params = {
            user_id: thisState.user.user_id,
            password: info.password,
            prefectures: info.prefecture,
            sex: info.sex,
            raw_year_month: info.birth_year_month.replace("-", "/"),
            nickname: info.nickname,
            invitation_cd: info.invitation_code,
            what_look: info.what_look,
        };

        const url = "/api/create_general_user";
        try {
            const response = await axios.post(url, params, {
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "Entry",
    initialState,
    reducers: {
        set_entry_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];

            if (type) {
                state[type][name] = value;
            } else {
                state[name] = value;
            }
        },
        set_entry_error: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];
            if (type) {
                state[type][name] = value;
            } else {
                state[name] = value;
            }
        },
        initialize: () => initialState,
    },
    extraReducers: {
        [CreateUserID.pending]: (state, action) => {},
        [CreateUserID.fulfilled]: (state, action) => {
            const result_data = action.payload;
            if (result_data.status == "0") {
                state.user.user_id = result_data.user_id;
                //     switch (result_data.paying) {
                //         case 0:
                //             console.debug("課金処理前");
                //             break;
                //         case 1:
                //             console.debug("課金処理済みです");
                //             // state.user.subscription_flag = false;
                //             // state.user.input_flag = true;
                //             break;
                //         default:
                //             break;
            }
            // } else {
            //     console.debug(result_data.error_message);
            //     state.error_message = result_data.error_message;
            // }
        },
        [CreateUserID.rejected]: (state, action) => {
            console.debug("ユーザー登録に失敗しました");
            // state.error_message = ["ユーザー登録に失敗しました"];
        },
        [PayingUpdate.pending]: (state, action) => {
            console.debug("PayingUpdateAPI通信開始");
        },
        [PayingUpdate.fulfilled]: (state, action) => {
            const result_data = action.payload;
            if (result_data.status == "0") {
                state.user.subscription_flag = false;
                state.user.input_flag = true;
            }
        },
        [PayingUpdate.rejected]: (state, action) => {
            state.error_message = [
                "課金情報の登録に失敗しました。再度購入または復元をお試しください。また複数回発生する場合はお手数ですが最初からやり直してください。",
            ];
            // state.user.subscription_flag = false;
            // state.user.input_flag = true;
        },
        [CreateGeneralUser.pending]: (state, action) => {
            console.debug("CreateGeneralUserAPI通信開始");
        },
        [CreateGeneralUser.fulfilled]: (state, action) => {
            console.debug("CreateGeneralUserAPI通信完了");

            console.debug("action.payload");
            console.debug(action.payload);
            const result_data = action.payload;

            if (result_data.result === "OK") {
                console.debug("会員登録が成功しました。");

                if (result_data.error_message) {
                    onsole.debug(error_message);
                }

                state.user.token = result_data.user_token;
                state.user.input_flag = false;
                state.user.check_flag = false;
                state.successOpen = true;
                state.user.fin_flag = true;
            } else {
                console.debug("会員登録が失敗しました。");
            }
        },
        [CreateGeneralUser.rejected]: (state, action) => {
            console.debug(
                "会員登録に失敗しました。再度購入または復元をお試しください。また複数回発生する場合はお手数ですが最初からやり直してください。"
            );
            state.error_message = [
                "会員登録に失敗しました。再度購入または復元をお試しください。また複数回発生する場合はお手数ですが最初からやり直してください。",
            ];
            // state.user.successOpen = true;
            // state.user.input_flag = false;
            // state.user.check_flag = false;
            // state.user.fin_flag = true;
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_entry_value, set_entry_error, initialize } = slice.actions;
