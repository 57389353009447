import React from "react";
import { Grid, Chip, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { Swiper, SwiperSlide } from "swiper/react";
import {
    Navigation,
    Autoplay,
    Pagination,
    Scrollbar,
    A11y,
    EffectCoverflow,
    FreeMode,
} from "swiper";

const HalfPriceMenu = (props) => {
    // const classes = useStyles();

    const { menu_data, index, onDelete, onEdit } = props;

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                    // maxWidth: "300px",
                    maxWidth: "450px",
                    marginRight: "auto",
                    marginLeft: "auto",
                    border: "solid 1px #a3a3a3",
                    borderRadius: "0.2rem",
                    padding: "0.5rem",
                    marginBottom: "0.3rem",
                    marginTop: "0.2rem",
                }}
            >
                <Grid item xs={4}>
                    <img
                        src={
                            menu_data?.image_path
                                ? menu_data?.image_path
                                : menu_data?.menu_image_path
                                ? menu_data?.menu_image_path
                                : "null"
                        }
                        onError={(e) => {
                            e.target.onerror = "null";
                            e.target.src = "/images/no_image_02.png";
                        }}
                        style={{ width: "100%", maxWidth: "550px" }}
                    />
                    <br />
                    {menu_data?.treatment_time && (
                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "0.5rem",
                                paddingTop: "2px",
                            }}
                        >
                            施術時間{menu_data?.treatment_time}分
                        </div>
                    )}
                </Grid>
                <Grid item xs={8} style={{ padding: "0.3rem" }}>
                    {menu_data?.edit_kubun == "1" && (
                        <>
                            <div
                                style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "bold",
                                }}
                            >
                                {menu_data?.menu_name}
                            </div>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid
                                    item
                                    xs={5}
                                    style={{
                                        textAlign: "center",
                                        fontSize: "1rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {Number(
                                        menu_data?.original_price
                                    ).toLocaleString()}
                                    円
                                </Grid>
                                <Grid item xs={2}>
                                    <div style={{ fontSize: "0.6rem" }}> ⇨</div>
                                </Grid>
                                <Grid item xs={5}>
                                    <div
                                        style={{
                                            color: "#f84361",
                                            textAlign: "center",
                                            fontSize: "1rem",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {Number(
                                            menu_data?.half_price
                                        ).toLocaleString()}
                                        円
                                    </div>
                                </Grid>
                            </Grid>

                            {menu_data?.menu_detail}
                        </>
                    )}

                    {menu_data?.edit_kubun == "2" && (
                        <>
                            <div
                                style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "bold",
                                }}
                            >
                                {menu_data?.menu_name}
                            </div>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                style={{
                                    textAlign: "center",
                                    fontSize: "0.9rem",
                                    fontWeight: "bold",
                                    color: "#f84361",
                                }}
                            >
                                {menu_data?.free_detail}
                            </Grid>

                            {menu_data?.menu_detail}
                        </>
                    )}

                    {onEdit && (
                        <div style={{ textAlign: "end" }}>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "#99FFFF" }}
                                onClick={() => onEdit(index)}
                            >
                                編集
                            </Button>
                        </div>
                    )}

                    {onDelete && (
                        <div style={{ textAlign: "end" }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => onDelete(index)}
                            >
                                削除
                            </Button>
                        </div>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default HalfPriceMenu;
