import React from "react";
import Button from "@material-ui/core/Button";
import MemberListTable from "../modules/MemberListTable";
import MemberListSort from "../modules/MemberListSort";
import { useHistory } from "react-router-dom";

// 加盟店編集

const AdminEntryShopList = () => {
    const history = useHistory();
    const shop_id = "S0000074";
    const apply_id = "R0000114";

    const onNewUserButton = () => {
        history.push(`/AdminStoreApplication/${shop_id}/undefined`);
    };
    const onFixUserButton = () => {
        history.push(`/AdminStoreApplication/${shop_id}/${apply_id}`);
    };
    const onSnapUserButton = () => {
        history.push(`/AdminSnap/${shop_id}`);
    };

    return (
        <React.Fragment>
            <div className="text-right">
                <Button
                    className="new-user-button"
                    onClick={() => onNewUserButton()}
                >
                    新規追加
                </Button>
                <Button
                    className="new-user-button"
                    onClick={() => onFixUserButton()}
                >
                    申請確認
                </Button>
                <Button
                    className="new-user-button"
                    onClick={() => onSnapUserButton()}
                >
                    スナップ申請確認
                </Button>
            </div>
        </React.Fragment>
    );
};

export default AdminEntryShopList;
