import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles({
    dialog: {
        "& *": {
            fontFamily: "'Kaisei Decol', serif",
        },
    },
    title: {
        fontSize: "1.5rem",
        color: "salmon",
    },
    img_container: {
        flexWrap: "nowrap",
    },
    img_frame: {
        width: "55px",
    },
    img: {
        flex: "1",
        minWidth: "100%",
    },
    point_container: {
        display: "flex",
        justifyContent: "center",
    },
    point_text: {
        fontSize: "1.5rem",
        padding: "0 1rem",
        margin: "2rem 0 1rem",
        border: "2px dashed #fff",
    },
    point_color_plus: {
        backgroundColor: "#ffd5a9",
        boxShadow: "0 0 0 8px #ffd5a9",
    },
    point_color_minus: {
        backgroundColor: "#bcebde",
        boxShadow: "0 0 0 8px #bcebde",
    },
    point_number: {
        fontSize: "2rem",
    },
    dialog_content: {
        borderTop: "none",
        fontSize: "1rem",
        display: "contents",
        textAlign: 'center'
    },
    button_text: {
        fontWeight: "bold",
    },
});

const MascotDrawDialog = (props) => {
    const {
        open,
        title,
        image,
        detail,
        url,
        point,
        onClose,
        transition,
        style,
    } = props;
    const classes = useStyles();

    return (
        <Dialog
            onClose={onClose}
            open={open}
            className={`${classes.dialog} ${style}`}
            fullWidth
            TransitionComponent={transition}
        >
            {/* くじタイトル */}
            <DialogTitle disableTypography className={classes.title}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {title}
                </Grid>
            </DialogTitle>

            {/* マスコット画像 */}
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.img_container}
            >
                {/* <img
                    src="/images/flower_frame.png"
                    className={classes.img_frame}
                    onError={(e) => {
                        e.target.onerror = "null";
                        e.target.src = "";
                    }}
                /> */}
                <img
                    className={classes.img}
                    src={image ? image : "null"}
                    onError={(e) => {
                        e.target.onerror = "null";
                        e.target.src = "/images/no_img.png";
                    }}
                />
                {/* <img
                    src="/images/flower_frame.png"
                    className={classes.img_frame}
                    onError={(e) => {
                        e.target.onerror = "null";
                        e.target.src = "";
                    }}
                /> */}
            </Grid>

            {/* ポイント増減 */}
            {point >= 0 && (
                <div className={classes.point_container}>
                    <span
                        className={`${classes.point_text} ${classes.point_color_plus}`}
                    >
                        <span className={classes.point_number}>{point}</span>
                        パカッティーGET✨
                    </span>
                </div>
            )}
            {point < 0 && (
                <Grid container justifyContent="center">
                    <span
                        className={`${classes.point_text} ${classes.point_color_minus}`}
                    >
                        <span className={classes.point_number}>{-point}</span>
                        パカッティーLOSE💦
                    </span>
                </Grid>
            )}

            {/* くじ詳細文 */}
            {detail && (
                <DialogContent dividers className={classes.dialog_content}>
                    <div
                        className="html-preview"
                        dangerouslySetInnerHTML={{
                            __html: detail.replace(/\n/g, "<br>"),
                        }}
                    ></div>
                </DialogContent>
            )}

            {/* ボタン */}

            <DialogActions>
                {url && (
                    <Button
                        href={url}
                        onClick={onClose}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.button_text}
                        color="primary"
                    >
                        ログインボーナス情報
                    </Button>
                )}
                <Button
                    onClick={onClose}
                    className={classes.button_text}
                    color="primary"
                >
                    閉じる
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default MascotDrawDialog;
