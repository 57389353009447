import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    button: {
        // marginRight: theme.spacing(2),
        // padding: "0.8rem 3rem",
        // fontSize: "1.2rem",
        // borderRadius: "1rem",
        // backgroundColor: "black",
        width: "100%",
        // borderRadius: "50%",
        border: "double 0.5rem",
        zIndex: "10",
    },
}));

const GoSerchPageButton = (props) => {
    const history = useHistory();
    const classes = useStyles();

    const OnButton = (e) => {
        history.push("ShopList");
    };

    return (
        <>
            {/* <img
                src="https://illustimage.com/photo/dl/2421.png?20161219"
                // width="50%"
                // height="140"
                style={{
                    objectFit: "cover",
                    marginRight: "20%",
                    marginBottom: "-1.8rem",
                    marginLeft: "auto",
                    height: "85px",
                    // minWidth:"50%"
                    

                }}
            /> */}
            <Button
                width="50%"
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={OnButton}
                {...props}
            >
                {/* <div id="box"></div> */}

                <div style={{ fontSize: "1rem", marginLeft: "1rem" }}>
                    検索画面へ
                    <SearchIcon />
                </div>
            </Button>
        </>
    );
};

export default GoSerchPageButton;
