import React from "react";
import Form from "react-bootstrap/Form";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { withStyles, makeStyles } from "@material-ui/core/styles";
const marks = [
    {
        value: 500,
        label: "500円",
    },
    {
        value: 20000,
        label: "上限なし",
    },
];

const CustomSlider = withStyles({
    valueLabel: {
        left: "calc(-50% + 12px)",
        top: -22,
        "& *": {
            background: "transparent",
            color: "#000",
            width: "5rem",
        },
    },
})(Slider);

const SelectPriceRange = (props) => {
    return (
        <>
            {/* <Typography id="price-slider-title" gutterBottom>
                半額メニュー金額
            </Typography> */}
            <CustomSlider
                style={{
                    width: "80%",
                    marginRight: "auto",
                    marginLeft: "auto",
                }}
                defaultValue={10000}
                aria-labelledby="price-slider"
                valueLabelDisplay="auto"
                step={500}
                min={500}
                max={20000}
                marks={marks}
                {...props}
            />
        </>
    );
};

export default SelectPriceRange;
