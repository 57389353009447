import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(2),
        background: "#b0dcfa",
        "&:hover": {
            background: "#b0dcfa",
        },

    },
}));

const ButtonGeneral = (props) => {
    const classes = useStyles();
    return (
        <Button
            variant="contained"
            color="primary"
            className={classes.button}
            {...props}
        />
    );
};

export default ButtonGeneral;
