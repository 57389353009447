import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";

// Stateの初期状態
const initialState = {
    history_data: [],
    history_options: {
        current_page: 1,
        last_page: 1,
    },
    point_history_data: [],
    point_history_options: {
        current_page: 1,
        last_page: 1,
    },
};

export const GetUserHistory = createAsyncThunk(
    "ADMIN/一般ユーザー履歴取得",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/admin_history";

        const params = arg;

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const GetUserPointHistory = createAsyncThunk(
    "ADMIN/一般ユーザーポイント履歴取得",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/admin_point_history";

        const params = arg;

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "AdminGeneralUserDetail",
    initialState,
    reducers: {
        setUserDetail: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
        initialize: () => initialState,
    },

    extraReducers: {
        [GetUserHistory.pending]: (state, action) => {},
        [GetUserHistory.fulfilled]: (state, action) => {
            const payload = action.payload;
            state.history_data = payload.data;
            state.history_options.current_page = payload.current_page;
            state.history_options.last_page = payload.last_page;
        },
        [GetUserHistory.rejected]: (state, action) => {},
        [GetUserPointHistory.pending]: (state, action) => {},
        [GetUserPointHistory.fulfilled]: (state, action) => {
            const payload = action.payload;
            state.point_history_data = payload.data;

            state.point_history_options.current_page = payload.current_page;
            state.point_history_options.last_page = payload.last_page;
        },
        [GetUserPointHistory.rejected]: (state, action) => {},
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { setUserDetail, initialize } = slice.actions;
