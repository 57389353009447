import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const GetUserTokenFlutter = createAsyncThunk(
    "Flutter/GetUserTokenFlutter",
    async (arg, thunkAPI) => {
        try {
            // フラッターにトークンがあるか確認
            const result = await window.flutter_inappwebview.callHandler(
                "getUserToken"
            );
            // if (result["result"]) {
                // const api_key = ["userToken"];
                // console.log("api_key:" + api_key);
            // }
            return result;
        } catch (error) {
            return null;
        }
    }
);
// // // Flutter側空トークン情報を取得する
// export default async function GetUserTokenFlutter() {
//     try {
//         // フラッターにトークンがあるか確認
//         const result = await window.flutter_inappwebview.callHandler(
//             "getUserToken"
//         );
//         if (result["result"]) {
//             const api_key = result["userToken"];
//             console.log("api_key:" + api_key);
//         }
//         return result;
//     } catch (error) {
//         console.log("GetUserTokenFlutter Error!!:" + JSON.stringify(error));
//         return null;
//     }
// }
