
import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Dialog from "@material-ui/core/Dialog";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { set_value , logout } from "../../stores/Information";
import OneTimePassword from "../pages/OneTimePassword";
import HttpFunction from "../utils/HttpFunction";
import {set_withdrawal_value , initialize} from "../../stores/Withdrawal";
import {
    ButtonContainedPrimary,
    ButtonOutlinedPrimary,
    ButtonTextPrimary,
} from "../utils/Buttons";
import Error500Handling from "../utils/Error500Handling";
import TokenErrorDialog from "../utils/TokenErrorDialog";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import TextField from "@material-ui/core/TextField";
import { set_alert } from "../../stores/ErrorAlert";

const Withdrawal = () => {
    const informationState = useSelector((state) => state.Information);
    const thisState = useSelector((state) => state.Withdrawal);
    const history = useHistory();
    const dispatch = useDispatch();
    const [openFailedLogin, setOpenFailedLogin] = React.useState(false);
    const [open500, setOpen500] = React.useState(false);
    const [disable, setDisable] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [faildOnetime, setFaildOnetime] = React.useState(0);
    const handleClose500 = () => {
        setOpen500(false);
        setDisable(false);
    };
    const [sendEmailCount, setCounter] = React.useState(0);
    const [reSendEmailDisabled, setReSendEmailDisabled] = React.useState(false);
    
    useEffect(() => {
        // console.log("useEffect");
        dispatch(initialize());
        if(informationState.user.user_login === true) {
            dispatch(set_withdrawal_value(["type", "user"]));
            dispatch(set_withdrawal_value(["email", informationState.user.email_address]));
            dispatch(set_withdrawal_value(["api_key", informationState.user.token]));
        } else if (informationState.shop.shop_login) {
            dispatch(set_withdrawal_value(["type", "shop"]));
            dispatch(set_withdrawal_value(["email", informationState.shop.shop_email_address]));
            dispatch(set_withdrawal_value(["api_key", informationState.shop.shop_token]));
        } else {
            // console.log("ログイン情報無し！");
            setOpenFailedLogin(true);
        }
    },[])
    
    useEffect(() => {
        // console.log("sendEmailCount:"+sendEmailCount);
        if(sendEmailCount === 0) {
            // // console.log("初期値");
        } else if (sendEmailCount < 3) {
            setReSendEmailDisabled(true);
            setTimeout(function () {
                setReSendEmailDisabled(false);
            },30000);
        } else {
            setReSendEmailDisabled(true);
        }
    },[sendEmailCount]);

    const setEmail = () => {
        if(thisState.type === "user") {
            sendEmail("/api/general_send_onetime_delete");
        } else if (thisState.type === "shop") {
            sendEmail("/api/shop_send_onetime_delete");
        } else {
            setOpenFailedLogin(true);
        }
    }

    const sendEmail = (api) => {
        if(sendEmailCount < 3){
            // console.log("api:"+api);
            const email = thisState.email;
            let api_key = "";
            if (thisState.api_key) {
                // console.log("thisState.api_key:" + thisState.api_key);
                api_key = thisState.api_key;
            }
            // console.log("api_key:"+ api_key);
            // console.log("email:"+ email);
            HttpFunction("POST", api, { email_address_new: email }, api_key)
                .then((data) => {
                    // console.log(data);
                    if(data.error_message && data.error_message.startsWith("ログイン情報の取得ができませんでした。")) {
                        dispatch(initialize()); 
                        setOpenFailedLogin(true);
                    } else if (data === 500) {
                        // console.log("500エラー");
                        setOpen500(true);
                    } else if(data.result === "OK") {
                        // console.log("OK");
                        dispatch(set_withdrawal_value(["withdrawal_flag", false]));
                        dispatch(set_withdrawal_value(["onetime_flag", true]));
                        dispatch(set_value(["showDrawer", false]));
                        dispatch(set_value(["showBottomNavigation", false]));
                        setError(false);
                    } else { 
                        setOpen500(true);    
                    }
                }).catch((err) => {
                    // console.log("ワンタイムパスワードの送信に失敗しました。");
                    // console.log("err:" + err);
                    // if(err == 'TypeError: Failed to fetch') {
                    //     // console.log("ネットワークエラー");
                    //     dispatch(set_withdrawal_value(["error_message", "ネットワークエラーが発生しました"]));
                    // } else {
                    //     // console.log("ネットワークエラーでない");
                    //     dispatch(set_withdrawal_value(["error_message", "サーバーエラーが発生しました"]));
                    // } 
                    setOpen500(true);
                });
            if(thisState.onetime_flag){
                setCounter(sendEmailCount + 1);
            }
        } 
    };

    const WithdrawalInfo = () => {
        
        return (
            <React.Fragment>
                <Container fixed>
                    {informationState.user.user_login ? (
                        <Grid>
                            <Grid>
                                <h2>退会について</h2>
                                <Grid>
                                    サブスクリプションの解約はGoogleまたはAppleの公式サイトより解約手順に従って、本アプリの退会とは別に行ってください。
                                </Grid>
                                <Grid>
                                    退会ボタンより退会しても自動的にサブスクリプションの解約が行われることはありません。
                                </Grid>
                            </Grid>
                            <Grid>
                                <h2>サブスク解約手順</h2>
                                <Grid>GoogleまたはAppleの公式サイトより解約手順をご確認ください。</Grid>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{
                                        marginTop: "0.5rem",
                                        paddingTop: "0.5rem"
                                    }}
                                    >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        href={"https://support.google.com/googleplay/answer/7018481?hl=ja&co=GENIE.Platform%3DAndroid#zippy=%2Cgoogle-play-%E3%82%A2%E3%83%97%E3%83%AA%E3%81%A7%E5%AE%9A%E6%9C%9F%E8%B3%BC%E5%85%A5%E3%82%92%E8%A7%A3%E7%B4%84%E3%81%99%E3%82%8B"}
                                        style={{
                                            textTransform: 'none',
                                            margin: "0.2rem",
                                        }}
                                        >
                                        Google Playの解約手順はこちら
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        href={"https://support.apple.com/ja-jp/HT202039"}
                                        style={{
                                            textTransform: 'none',
                                            margin: "0.2rem",
                                        }}
                                        >
                                        Appleの解約手順はこちら
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    ):(
                        ""
                    )}
                    <Grid>
                        <h2>退会</h2>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                                marginTop: "0.5rem",
                                paddingTop: "0.5rem"
                            }}
                            >
                            <Grid>
                            退会される方は下の「退会」ボタンを押してください。登録されているメールにワンタイムパスワードを送信します。
                            </Grid>
                            <Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={setEmail}
                                    >
                                    退会
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </React.Fragment>
        );

    }

    const DeleteOneTimePasswordInput = () => {
        const useStyles = makeStyles((theme) => ({
            button: {
                display: "block",
                margin: "auto",
            },
            stop_dialog: {
                "& .MuiDialog-paper": {
                    padding: "15px",
                },
            },
            error_dialog: {
                "& .MuiDialog-paper": {
                    padding: "15px",
                    border: "2px solid orangered",
                    display: "block",
                },
            },
        }));
        const [onetime, setOnetime] = React.useState("");
        
        const handleBlur = (e) => {
            setOnetime(e.target.value);
        };

        const StopButton = () => {
            const dispatch = useDispatch();
            const history = useHistory();
            const classes = useStyles();
            const [open, setOpen] = React.useState(false);
        
            const handleOpen = () => {
                setOpen(true);
            };
            const handleClose = () => {
                setOpen(false);
            };
            const handleStop = () => {
                dispatch(set_value(["showDrawer", true]));
                dispatch(set_value(["showBottomNavigation", true]));
                dispatch(initialize());
                history.goBack();
            };
        
            return (
                <>
                    <IconButton onClick={handleOpen} style={{ padding: "8px 0 24px" }}>
                        <NavigateBeforeIcon />
                        <span style={{ fontSize: "70%" }}>戻る</span>
                    </IconButton>
                    <Dialog open={open} onClose={handleClose} className={classes.stop_dialog}>
                        <p>退会を中止します。よろしいですか？</p>
                        <Grid container style={{textAlign: "center"}}>
                            <Grid item xs={6}>
                                <ButtonContainedPrimary
                                    onClick={handleClose}
                                >
                                    キャンセル
                                </ButtonContainedPrimary>
                            </Grid>
                            <Grid item xs={6}>
                                <ButtonOutlinedPrimary
                                    onClick={handleStop}
                                >
                                    OK
                                </ButtonOutlinedPrimary>
                            </Grid>
                        </Grid>
                    </Dialog>
                </>
            );
        };

        const setSendOneTime = () => {
            // console.log("setSendOneTime");
            setDisable(true);
            if(thisState.type === "user") {
                sendOneTime("/api/general_user_onetime_check_delete");
            } else if(thisState.type === "shop") {
                sendOneTime("/api/shop_user_onetime_check_delete");
            } else {
                // console.log("ログイン無し");
                setOpenFailedLogin(true);
            }  
        };

        const sendOneTime = (api) => {
            // console.log("ontime:"+ onetime);
            let api_key;
            if(!thisState.email){
                dispatch(initialize()); 
                setOpenFailedLogin(true);
                return
            }
            const params = {
                email_address: thisState.email,
                one_time: onetime,
            };
            if (thisState.api_key) {
                // console.log("thisState.api_key:" + thisState.api_key);
                api_key = thisState.api_key;
            }
            // console.log(api);
            // console.log("apikey:" + api_key);
            // console.log("email:"+thisState.email);
            // console.log("one_time:"+onetime);
            HttpFunction("DELETE", api, params, api_key)
                .then((data) => {
                    // console.log(data);
                    for(let key in data) {
                        // console.log("key:" + key);
                    }
                    if(data.error_message && data.error_message.startsWith("ログイン情報の取得ができませんでした。")) {
                        // console.log("error_message in");
                        dispatch(initialize()); 
                        setOpenFailedLogin(true);
                    } else if (data.result === "OK") {
                        // console.log("data.result OK");
                        dispatch(set_withdrawal_value(["onetime_flag", false]));
                        dispatch(set_withdrawal_value(["withdrawal_complete_flag", true]));
                    } else if (data.result === "NG") {
                        // console.log("error_message NG");
                        // console.log("data.error_failed:"+data.login_failed);
                        setDisable(false);
                        setFaildOnetime(data.login_failed);
                        setError(true);
                    } else {
                        setDisable(false);
                        setOpen500(true);
                    }
                })
                .catch((err) => {
                    setDisable(false);
                    // console.log("err:" + err);
                    setOpen500(true);
                });
        };
    

        return (
            <>
                <StopButton />
                {error && (
                    <Alert severity="error">
                        <AlertTitle>エラー</AlertTitle>
                        {1 <= faildOnetime &&
                        faildOnetime <= 4 ? (
                            <>
                                正しいワンタイムパスワードを入力してください。（5回失敗するとワンタイムパスワードが無効化されます。）
                                <br />
                                {`認証失敗回数${faildOnetime}回`}
                            </>
                        ) : (
                            "ワンタイムパスワードが無効化されています。新しいワンタイムパスワードを発行するには、「メールの再送信」をクリックしてください。"
                        )}
                    </Alert>
                )}
                {3 <= sendEmailCount && (
                    <Alert severity="info">
                        <AlertTitle>メッセージ</AlertTitle>
                        再送上限に達しました。これ以上の再送信はできません。
                        <br/>
                        再度ワンタイムパスワードを発行する場合はお手数ですが最初からやり直してください。
                    </Alert>
                )}
                <Grid>
                    <p>ワンタイムパスワードを送信で退会が完了します。</p>
                    {informationState.user.user_login ? (
                        <p>退会するとパカッティーは破棄されますのでご注意ください。</p>
                    ): (
                        ""
                    )}
                </Grid>
                <OneTimePassword onBlur={handleBlur} />
                <ButtonContainedPrimary
                    disabled={disable}
                    onClick={setSendOneTime}
                    style={{ display: "block", marginLeft: "auto" }}
                >
                    送信
                </ButtonContainedPrimary>
                <ButtonTextPrimary
                    onClick={setEmail}
                    style={{ display: "block", marginLeft: "auto" }}
                    disabled={reSendEmailDisabled}
                >
                    メールの再送信
                </ButtonTextPrimary>
            </>
        );
    };

    const WithdrawalComplete = () => {
        useEffect(() => {
            if(typeof flutter !== "undefined" && thisState.type !== null) {
                window.flutter_inappwebview.callHandler("removeUserToken",thisState.type);
            }
            switch (thisState.type) {
                case "user":
                    dispatch(logout(["user"]));
                    break;
                case "shop":
                    dispatch(logout(["shop"]));
                    break;
            }
            setTimeout(() => history.push("/FirstNavi"), 2000);            
        },[])
        return(
            <React.Fragment>
                <Container>
                    <h1>退会完了</h1>
                    <Grid>
                        ログイン画面に移動します。
                    </Grid>
                </Container>
            </React.Fragment>
        );
    }

    return(
        <div className="m-3">
            {thisState.error_message && (
                    <Alert severity="error">
                        <AlertTitle>エラー</AlertTitle>
                        {thisState.error_message}
                    </Alert>
            )}
            {thisState.withdrawal_flag ? <WithdrawalInfo /> : ""}
            {thisState.onetime_flag ? <DeleteOneTimePasswordInput /> : ""}
            {thisState.withdrawal_complete_flag ? <WithdrawalComplete /> : ""}
            <TokenErrorDialog open={openFailedLogin} />
            <Error500Handling open={open500} onClose={handleClose500} />
        </div>
    );
};

export default Withdrawal;
