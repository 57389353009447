import React, { useEffect } from "react";

import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {
    DataGrid,
    GridToolbarContainer,
    useGridSlotComponentProps,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarColumnsButton,
} from "@material-ui/data-grid";
import jaJPDataGrid from "../../modules/JaJPDataGrid";

import { GetMessageList } from "../../../stores/TALENTBANK/Chat";

import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import {
    format,
    formatDistance,
    formatRelative,
    subDays,
    parse,
    differenceInYears,
} from "date-fns";
import ja from "date-fns/locale/ja";

const MessageList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const path = history.location.pathname.split("/");
    const company_id = path[2];
    const ChatState = useSelector((state) => state.Chat);
    const message_list = ChatState.message_list;
    const message_list_info = ChatState.message_list_info;

    const [selected_row, setSelectedRow] = React.useState(false);

    const [month, setMonth] = React.useState(false);
    const [year, setYear] = React.useState(false);

    const HandleMonthChange = (e) => {
        // setSelectedRow(e.target.value);
        if (!e.target.value) return;
        const Array = e.target.value.split("-");

        setYear(Array[0]);
        setMonth(Array[1]);
    };

    // const history_list = AdminShopUsageHistoryState.history_list;
    // const [shop_id, set_shop_id] = React.useState("S0000000");

    const columns = [
        {
            field: "talent_id",
            headerName: "タレントID",
            width: 180,
            hide: true,
        },
        //     { field: "user_id", headerName: "user_id", width: 130 },
        {
            field: "talent_name",
            headerName: "タレント名",
            width: 130,
            hide: false,
            valueGetter: (params) => params.row?.talent_info_data?.talent_name,
        },
        {
            field: "company_name",
            headerName: "企業名",
            width: 130,
            hide: false,
            valueGetter: (params) =>
                params.row?.company_info_data?.company_name,
        },
        {
            field: "message",
            headerName: "メッセージ",
            flex: 1,
            valueGetter: (params) =>
                params.row?.message ? params.row?.message : "画像を送信",
            // renderCell: (params) => (
            //     <>
            //         {params.row?.message} <br /> aaaaaaaaaaaaa
            //     </>
            // ),
        },

        // {
        //     field: "詳細",
        //     headerName: "",
        //     width: 100,
        //     editable: false,
        //     renderCell: (params) => (
        //         <Button
        //             variant="contained"
        //             color="secondary"
        //             // onClick={() => DetailClick(params.row)}
        //         >
        //             詳細
        //         </Button>
        //     ),
        // },
    ];

    const HandleCellClick = (e) => {
        setSelectedRow(e.row);
    };

    useEffect(() => {
        const page =
            ChatState.company_id == company_id
                ? Number(message_list_info.page)
                : 1;

        const params = {
            page: page,
            month: month,
            year: year,
        };
        dispatch(GetMessageList(params));
    }, []);

    const onPageChange = (page) => {
        const params = {
            page: Number(page) + 1,
            month: month,
            year: year,
        };
        dispatch(GetMessageList(params));
    };

    const onSerch = () => {
        const params = {
            page: 1,
            month: month,
            year: year,
        };
        dispatch(GetMessageList(params));
    };
    return (
        <React.Fragment>
            <Container>
                <ButtonNavi
                    left_label="戻る"
                    left_link={`/Talent/ChatSerch?top_back=true`}
                />
                <h1>メッセージリスト</h1>

                <DetailDialog
                    handleClose={() => setSelectedRow(false)}
                    open={selected_row ? true : false}
                    selected_row={selected_row}
                />
                <TextField
                    value={`${year}-${month}`}
                    type="month"
                    onChange={HandleMonthChange}
                    variant="outlined"
                />
                <Button variant="contained" onClick={onSerch}>
                    検索
                </Button>

                <div style={{ height: 640, width: "100%" }}>
                    <DataGrid
                        getRowId={(row) => row.id}
                        rows={message_list}
                        columns={columns}
                        pageSize={10}
                        // rowsPerPageOptions={[5]}
                        // checkboxSelection
                        disableSelectionOnClick
                        onPageChange={onPageChange}
                        paginationMode="server"
                        page={Number(message_list_info.page) - 1}
                        rowCount={message_list_info.total}
                        localeText={jaJPDataGrid}
                        onCellClick={(event) => HandleCellClick(event)}
                        // AdminShopUsageHistoryState.lastPage

                        //
                    />
                </div>
            </Container>
        </React.Fragment>
    );
};

const FormatTime = (time) => {
    if (!time) return;
    let result = time.replace(".000000Z", "");
    result = result.replace("T", " ");
    result = result.slice(0, -3);
    return result;
};

const DetailDialog = (props) => {
    const { handleClose, open, selected_row, ...other } = props;
    return (
        <>
            <Dialog
                onClose={handleClose}
                open={open}
                // style={{ background: "#fff" }}
            >
                <DialogTitle style={{ background: "#fff" }}>
                    メッセージ詳細
                </DialogTitle>
                <DialogContent style={{ background: "#fff" }}>
                    <Grid container>
                        <Grid
                            item
                            xs={6}
                            style={{
                                borderBottom: "solid 1px #adadad",
                                marginBottom: "0.2rem",
                            }}
                        >
                            企業名
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                borderBottom: "solid 1px #adadad",
                                marginBottom: "0.2rem",
                            }}
                        >
                            {selected_row.company_info_data?.company_name}
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                borderBottom: "solid 1px #adadad",
                                marginBottom: "0.2rem",
                            }}
                        >
                            タレント名
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                borderBottom: "solid 1px #adadad",
                                marginBottom: "0.2rem",
                            }}
                        >
                            {selected_row.talent_info_data?.talent_name}
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                borderBottom: "solid 1px #adadad",
                                marginBottom: "0.2rem",
                            }}
                        >
                            タレントID
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                borderBottom: "solid 1px #adadad",
                                marginBottom: "0.2rem",
                            }}
                        >
                            {selected_row.talent_id}
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                borderBottom: "solid 1px #adadad",
                                marginBottom: "0.2rem",
                            }}
                        >
                            送信時間
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                borderBottom: "solid 1px #adadad",
                                marginBottom: "0.2rem",
                            }}
                        >
                            {FormatTime(selected_row.created_at)}
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                borderBottom: "solid 1px #adadad",
                                marginBottom: "0.2rem",
                            }}
                        >
                            送信者
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                borderBottom: "solid 1px #adadad",
                                marginBottom: "0.2rem",
                            }}
                        >
                            {selected_row?.created_cd?.indexOf("T") !== -1
                                ? selected_row.talent_info_data?.talent_name
                                : selected_row.company_info_data?.company_name}
                        </Grid>
                    </Grid>
                    メッセージ
                    {selected_row.message ? (
                        <Grid
                            container
                            style={{ padding: "0.2rem", border: "solid 1px" }}
                        >
                            <Grid item xs={12}>
                                {selected_row.message
                                    .split("\n")
                                    .map((t, i) => (
                                        <p key={i}>{t}</p>
                                    ))}
                            </Grid>{" "}
                        </Grid>
                    ) : (
                        <img src={selected_row.image} width="100%"></img>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};
export default MessageList;
