import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import ButtonGeneral from "../utils/ButtonGeneral";
import { Button } from "@material-ui/core";
import { ButtonNavi } from "~CJS/block/ButtonNavi";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import InputImagePreview from "~CJS/atoms/InputImagePreview";

import {
    initialize,
    set_value,
    GetSlideData,
    HomeSlideSetting,
} from "~stores/AdminHomeSetting";

const AdminHomeSetting = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.AdminHomeSetting);

    useEffect(() => {
        // dispatch(set_entry_value(["registering_user", true]));
        dispatch(GetSlideData());
    }, []);

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;

        let slide_data = thisState.slide_data.map((value, index) => {
            let newData = { ...value };
            const image_data = elem["slide_data_" + index + "_big"].value;
            newData.image_path = image_data;
            const url = elem[`slide_url_${index}`].value;
            newData.link_url = url;
            return newData;
        });
        dispatch(set_value(["slide_data", slide_data]));
        dispatch(HomeSlideSetting({ slide_data: slide_data }));
    };

    return (
        <React.Fragment>
            <Container>
                <ButtonNavi left_label="Home画面" left_link="/AdminHome" />
                <Typography variant="h1">ホーム画面設定</Typography>
                <form onSubmit={HandleSubmit}>
                    {thisState.slide_data.map((value, index) => (
                        <div key={index} style={{marginBottom: "100px"}}>
                            <p style={{paddingLeft: "80px", fontSize: "1.2rem"}}>（{index + 1}）</p>
                            <InputImagePreview
                                key={`${Math.floor(
                                    Math.random() * 1000
                                )}-image`}
                                comment={false}
                                height={200}
                                option={{ height: 560, width: 2000 }}
                                name={"slide_data_" + index}
                                image_big={
                                    thisState.slide_data[index].image_path
                                }
                                image={thisState.slide_data[index].image_path}
                            />
                            <div style={{display: "flex", padding: "0 80px", alignItems: "center"}}>
                                <span style={{marginRight: "10px"}}>リンク先のURL： </span>
                                <TextField
                                    key={`${Math.floor(
                                        Math.random() * 1000
                                    )}-url`}
                                    name={`slide_url_${index}`}
                                    defaultValue={thisState.slide_data[index].link_url}
                                    variant="outlined"
                                    size="small"
                                    style={{flex: "1"}}
                                />
                            </div>
                        </div>
                    ))}
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                    >
                        設定
                    </Button>
                </form>
            </Container>
        </React.Fragment>
    );
};

export default AdminHomeSetting;
