import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Stateの初期状態
const initialState = {
    shop_data: {
        shop_id: "",
        shop_name: "",
        shop_explain: "",
        store_pr: "",
        access: "",
        shop_prefectures: "",
        shop_city: "",
        shop_address1: "",
        shop_address2: "",
        business_hours: "",
        holiday: "",
        shop_url: "",
        shop_sns: "",
        tag_id_1: "",
        tag_id_2: "",
        tag_id_3: "",
        tag_id_4: "",
        tag_id_5: "",
        tag_id_6: "",
        shop_image_big: "",
        shop_image_small: "",
        shop_phone_number: "",
        shop_staff: "",
        shop_menu: "",
        reservation: "",
        menu_data_list: [],
        review_list: [],
    },
};

export const GetShopData = createAsyncThunk(
    "ShopInformation/GetShopData",
    async (arg, thunkAPI) => {
        const csrf_token = document.head.querySelector(
            'meta[name="csrf-token"]'
        ).content;

        const params = {
            shop_id: arg,
        };

        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.user.token;

        const query_params = new URLSearchParams(params);
        const res = await fetch("/api/shop_information" + "?" + query_params, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": csrf_token,
                "API-KEY": api_key ? api_key : "",
            },
        });

        const data = await res.json();
        if (!data.data) {
            thunkAPI.dispatch(set_value(["modal_message", "no_data_err"]));
            return 0;
        } else {
            return data.data;
        }
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "ShopInformation",
    initialState,
    reducers: {
        shop_info_set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        [GetShopData.pending]: (state, action) => {},
        [GetShopData.fulfilled]: (state, action) => {
            state.shop_data = action.payload;
        },
        [GetShopData.rejected]: (state, action) => {
            // console.log("ネットワークエラー");
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { shop_info_set_value } = slice.actions;
