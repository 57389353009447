import React, { useState, useEffect } from "react";

import ShopOverview from "../modules/ShopOverview.js";
import { shop_data } from "../../const/shop_data";
import { useSelector } from "react-redux";
import { Container } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import HttpFunction from "../utils/HttpFunction";
import { FavoriteShop_set, GetFavoriteShop } from "../../stores/FavoriteShop";

import { useDispatch } from "react-redux";

const FavoriteShop = () => {
    const dispatch = useDispatch();
    const FavoriteShopState = useSelector((state) => state.FavoriteShop);
    const f_list = FavoriteShopState.favorite_list;
    const InformationState = useSelector((state) => state.Information);
    const api_key = InformationState.user.token;
    const params = {};

    useEffect(() => {
        if (!api_key) return;

        dispatch(GetFavoriteShop(params))
            .then((res) => {
                const new_shop_list_data = res.payload.data.map(
                    (shop, index) => {
                        const new_shop = {
                            ...shop,
                            favorite: (shop.favorite = 1),
                        };
                        return new_shop;
                    }
                );
                dispatch(
                    FavoriteShop_set(["favorite_list", new_shop_list_data])
                );
                // dispatch(FavoriteShop_set(["favorite_list", data.data]));
            })
            .catch((err) => {
                // console.log("お気に入り一覧の読み込みに失敗しました。");
                // console.log(err);
            });
    }, []);

    const Onfavorite = (shop_id) => {
        const new_shop_list_data = f_list.map((shop, index) => {
            if (shop.shop_id == shop_id) {
                const new_shop = {
                    ...shop,
                    favorite: shop.favorite != 1 ? 1 : 0,
                };
                return new_shop;
            } else {
                return shop;
            }
        });

        dispatch(FavoriteShop_set(["favorite_list", new_shop_list_data]));
    };

    return (
        <div
            style={{
                display: "flex",
                maxWidth: "500px",
                margin: "0px",
                padding: "0px",
                marginRight: "auto",
                marginLeft: "auto",
            }}
        >
            <div style={{ width: "96%" }}>
                {FavoriteShopState.favorite_list.length != 0 ? (
                    <React.Fragment>
                        {FavoriteShopState.favorite_list.map((value, index) => (
                            <React.Fragment key={index}>
                                <ShopOverview
                                    Onfavorite={Onfavorite}
                                    shop_data={value}
                                />
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ) : (
                    <div
                        style={{
                            width: "96%",
                            marginRight: "auto",
                            marginLeft: "auto",
                            textAlign: "center",
                            paddingTop: "40vw",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                    >
                        お気に入り店舗がありません
                    </div>
                )}
            </div>
        </div>
    );
};

export default FavoriteShop;
