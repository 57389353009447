import React, { useState, useEffect } from "react";

import ButtonGeneral from "../utils/ButtonGeneral";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { set_value } from "../../stores/AdminStoreApplication";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";

import ShopApplicationPreview from "../modules/ShopApplicationPreview";
import { GetShopData } from "../../stores/ShopInformation";
import {
    release_info_set,
    GetShopInformation,
    GetShopInformationApplication,
    setInitializeState,
} from "../../stores/ShopStoreApplication";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ModalProgress from "../utils/ModalProgress";
import { set_alert } from "../../stores/ErrorAlert";
import * as info from "../../stores/Information";

import TextHorizon from "../utils/TextHorizon";
import TweetList from "../modules/TweetList";
import { DeleteTweet } from "../../stores/Tweet";
import { ApprovalFlag, PublicStatusFlag } from "../utils/FlagToViewName";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const ShopStoreApplication = () => {
    const releaseInfoState = useSelector((state) => state.ShopStoreApplication);
    const information_state = useSelector((state) => state.Information);
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(setInitializeState());
        dispatch(GetShopInformation());
        dispatch(info.set_value(["showDrawer", true]));
    }, []);

    useEffect(() => {
        dispatch(set_alert(releaseInfoState.error_message));
    }, [releaseInfoState.error_message]);

    const OnApplication = () => {
        // console.log("変更申請");
        //申請処理
    };

    const LinkFix = () => {
        // console.log("申請変更");
        history.push("/ShopStoreApplicationFix/" + releaseInfoState.apply_id);
    };

    const LinkNew = () => {
        history.push("/ShopStoreApplicationNew");
        // console.log("申請新規");
    };
    const LinkSnapPosting = () => {
        history.push("/ShopSnapPosting");
    };
    const onTweetDelete = (tweet_data) => {
        const result = confirm("この投稿を本当に削除しますか?");
        if (!result) return;
        dispatch(DeleteTweet({ tweet_id: tweet_data?.id })).then((data) => {
            dispatch(GetShopInformation());
        });
    };

    return (
        <React.Fragment>
            {/* <ModalProgress open={releaseInfoState.modal_progress} /> */}
            <div
                style={{ margin: "2%", width: "96%" }}
                className={classes.root}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            history.push("/tweet");
                        }}
                    >
                        一言
                    </Button>
                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            history.push(
                                `/BlogList/?shop_id=${releaseInfoState?.shop_id}`
                            );
                        }}
                    >
                        ブログ
                    </Button> */}
                </Grid>
                <h1>店舗公開情報</h1>
                <TextHorizon
                    name="公開ステータス"
                    value={PublicStatusFlag(releaseInfoState.public_status)}
                />
                <TextHorizon
                    name="申請ステータス"
                    value={
                        releaseInfoState.apply_id
                            ? "申請済" +
                              "(" +
                              ApprovalFlag(releaseInfoState.approval_flag) +
                              ")"
                            : "未申請"
                    }
                />
                {releaseInfoState.approval_flag == "2" &&
                releaseInfoState.denial_reason ? (
                    <div
                        style={{
                            border: "solid 2px red",
                            marginLeft: "-1%",
                            paddingLeft: "1%",
                            marginRight: "-1%",
                            paddingRight: "1%",
                        }}
                    >
                        <TextHorizon
                            style={{ border: "solid red" }}
                            name="否認理由"
                            value={releaseInfoState.denial_reason}
                        />
                    </div>
                ) : (
                    ""
                )}
                <TextHorizon name="店舗名" value={releaseInfoState.shop_name} />

                <TweetList
                    tweet_list={releaseInfoState.tweet_list}
                    onDelete={onTweetDelete}
                />
                <ShopApplicationPreview ApplicationData={releaseInfoState} />
            </div>

            <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                style={{
                    margin: "2%",
                    width: "96%",
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    disabled={releaseInfoState.apply_id ? true : false}
                    onClick={LinkNew}
                >
                    申請
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    disabled={releaseInfoState.apply_id ? false : true}
                    onClick={LinkFix}
                >
                    申請編集
                </Button>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
            >
                <ButtonGeneral
                    variant="contained"
                    color="primary"
                    onClick={LinkSnapPosting}
                    style={{
                        margin: "5%",
                        fontSize: "1rem",
                        width: "90%",
                    }}
                >
                    スナップ投稿
                </ButtonGeneral>
            </Grid>
        </React.Fragment>
    );
};

export default ShopStoreApplication;
