import React from "react";

import Form from "react-bootstrap/Form";
import Button from "@material-ui/core/Button";
const FixMember = () => {
    return (
        <React.Fragment>
            
            <Form className="entryform">
                {/* aaaa */}
                <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>メールアドレス</Form.Label>
                    <Form.Control type="email" placeholder="name@example.com" />
                </Form.Group>

                <Form.Group>
                    <Form.Label>パスワード</Form.Label>
                    <Form.Control type="password" placeholder="Password" />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Nickname</Form.Label>
                    <Form.Control type="text" placeholder="Normal text" />
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>sexuality</Form.Label>
                    <Form.Control as="select">
                        <option>unselected</option>
                        <option>male</option>
                        <option>female</option>
                        <option>other</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>備考</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                </Form.Group>

                <div className="text-right">
                    <Button className="new-user-button">新規追加</Button>
                </div>
            </Form>
        </React.Fragment>
    );
};

export default FixMember;
