import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { useDispatch, useSelector } from "react-redux";

import { BackButton } from "~CJS/atoms/BackButton";
import { LinkButton } from "~CJS/atoms/LinkButton";

import OfferDetailInput from "~CJS/block/OfferDetailInput";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { initialize, OfferFixApi, GetDetail } from "~stores/TALENTBANK/Offer";

const OfferFix = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const search = useLocation().search;
    const path = history.location.pathname.split("/");
    const offer_id = path[2];
    const query = new URLSearchParams(search);
    const status = query.get("status");

    useEffect(() => {
        const params = { offer_id: offer_id, status: status };
        dispatch(GetDetail(params));
    }, []);

    const OfferDetailState = useSelector((state) => state.Offer.offer_detail);

    const handleSubmit = (e) => {
        e.preventDefault();

        const elements = e.target?.elements;

        const getValue = (name) => {
            let value = "";
            if (elements[name]?.value) {
                value = elements[name]?.value;
            }

            return value;
        };

        const offer_info = {
            offer_id: offer_id,
            company_id: getValue("company_id"),
            offer_title: getValue("offer_title"),
            prefectures: getValue("prefectures"),
            city: getValue("city"),
            guarantee: getValue("guarantee"),
            recruitment_count: getValue("recruitment_count"),
            job_start_time: getValue("job_start_time"),
            job_end_time: getValue("job_end_time"),
            job_start: getValue("job_start"),
            job_end: getValue("job_end"),
            job_date: getValue("job_date"),
            job_detail: getValue("job_detail"),
            image_big: getValue("image_big"),
            image: getValue("image"),
            status: getValue("status"),
            competing_flag: getValue("competing_flag"),
            entry_close: getValue("entry_close"),
        };

        const params = {
            offer_info: offer_info,
            input_option: [],
            category: getValue("category"),
        };

        dispatch(OfferFixApi(params)).then((data) => {
            history.push(`/OfferDetail/${offer_id}`);
        });
    };

    return (
        <React.Fragment>
            {/* <BackButton history={history} /> */}

            <Container>
                <ButtonNavi
                    left_label="お仕事詳細"
                    left_link={`/OfferDetail/${offer_id}?status=${status}`}
                />
                <Typography variant="h1">お仕事編集</Typography>
                <form onSubmit={handleSubmit}>
                    <OfferDetailInput data={OfferDetailState} />
                    <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        type="submit"
                        style={{ width: "96%", margin: "2%", height: "4rem" }}
                    >
                        更新
                    </Button>
                </form>
            </Container>
        </React.Fragment>
    );
};

export default OfferFix;
