import React, { useEffect } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Container from "@material-ui/core/Container";
import { makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { LinkButton } from "~CJS/atoms/LinkButton";
import { SwitchInput } from "~CJS/atoms/SwitchInput";
import Pagination from "@material-ui/lab/Pagination";
import {
    GetTalentRequestList,
    ReleaseTalentRequest,
    ApprovalTalentRequest,
    DeleteTalentRequest,
    talent_requests_set,
} from "~stores/TALENTBANK/TalentRequest";
// import Error500Handling from "../utils/Error500Handling";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import { ButtonNavi } from "~CJS/block/ButtonNavi";

import { format, formatDistance, formatRelative, subDays } from "date-fns";
import { ja } from "date-fns/locale";

const TalentRequestList = () => {
    const dispatch = useDispatch();
    const NewsState = useSelector((state) => state.News);

    const TalentRequestState = useSelector((state) => state.TalentRequest);
    const talent_request_list = TalentRequestState.talent_request_list;

    const [active_flag, set_active_flag] = React.useState(["active_flag", 1]);
    const [delete_flag, set_delete_flag] = React.useState(["delete_flag", 0]);

    useEffect(() => {
        dispatch(GetTalentRequestList(TalentRequestState.serch_info));
    }, []);

    // 承認
    const HandleApproval = (talent_id) => {
        const result = confirm("承認しますか?");
        if (!result) return;

        const params = { talent_id: talent_id, status: 1, page: 1 };
        dispatch(ApprovalTalentRequest(params));
    };

    // 否認
    const HandleDenial = (talent_id) => {
        // const result = confirm("否認しますか?");
        // if (!result) return;

        const reason = prompt("否認理由を入力してください。\n (空白可)", "");
        if (reason === null) {
            return;
        }

        const params = { talent_id: talent_id, status: 2, reason: reason };
        dispatch(ApprovalTalentRequest(params));
    };
    // 削除
    const HandleDelete = (talent_id) => {
        const result = confirm("削除しますか?");
        if (!result) return;

        const params = { talent_id: talent_id, delete_flag: 1 };
        dispatch(DeleteTalentRequest(params));
    };
    const HandleRestoration = (talent_id) => {
        const result = confirm("復元しますか?");
        if (!result) return;

        const params = { talent_id: talent_id, delete_flag: 0 };
        dispatch(DeleteTalentRequest(params));
    };

    const HandleRelease = (talent_id) => {
        const result = confirm("公開しますか?");
        if (!result) return;

        const params = { talent_id: talent_id, status: 1 };
        dispatch(ReleaseTalentRequest(params));
    };
    // 検索
    const handleSubmit = (e) => {
        e.preventDefault();
        const elements = e.target?.elements;

        const getValue = (name) => {
            let value = "";
            if (elements[name]?.value) {
                value = elements[name]?.value;
            }

            return value;
        };

        set_active_flag(getValue("active_flag") == "true" ? 0 : 1);
        set_delete_flag(getValue("delete_flag") == "true" ? 1 : 0);

        const params = {
            ...TalentRequestState.serch_info,
            active_flag: getValue("active_flag") == "true" ? 0 : 1,
            delete_flag: getValue("delete_flag") == "true" ? 1 : 0,
            page: 1,
        };

        dispatch(talent_requests_set(["serch_info", params]));
        dispatch(GetTalentRequestList(params));
    };

    const HeadGlid = (props) => {
        return (
            <Grid
                item
                xs={6}
                style={{
                    background: "#feae53",
                    padding: "1rem",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    border: "solid 1px",
                    textAlign: "left",
                    margin: "0px",
                }}
                {...props}
            ></Grid>
        );
    };
    const BodyGlid = (props) => {
        return (
            <Grid
                item
                style={{
                    border: "1px solid",
                    textAlign: "center",
                    margin: "0px",
                    padding: "0.5rem",
                }}
                {...props}
            ></Grid>
        );
    };
    const handleChangePage = (e, page) => {
        dispatch(
            GetTalentRequestList({
                ...TalentRequestState.serch_info,
                page: page,
            })
        );
    };
    const news_list = NewsState.news_list;

    return (
        <Container>
            <ButtonNavi left_label="Home画面" left_link="/AdminHome" />

            <Typography variant="h1">タレント承認</Typography>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <img
                    src="/images/talentBank/管理画面/タレント承認説明ズ.png"
                    width="100%"
                    style={{ maxWidth: "1000px" }}
                />
            </Grid>
            <form onSubmit={handleSubmit}>
                <SwitchInput label="承認済" name="active_flag" />
                <SwitchInput label="削除済" name="delete_flag" />
                <Button variant="contained" type="submit">
                    検索
                </Button>
            </form>
            <Grid container style={{ minWidth: "1200px" }}>
                <HeadGlid xs={2}>申請日</HeadGlid>
                <HeadGlid xs={1}>活動名</HeadGlid>
                <HeadGlid xs>メールアドレス</HeadGlid>
                <HeadGlid xs={1}>FiftyID</HeadGlid>
                <HeadGlid xs={1}>詳細</HeadGlid>
                <HeadGlid xs={1}>削除</HeadGlid>
                <HeadGlid xs={1}>否認</HeadGlid>
                <HeadGlid xs={1}>承認</HeadGlid>
                <HeadGlid xs={1}></HeadGlid>
                {talent_request_list && (
                    <>
                        {talent_request_list.map((value, index) => (
                            <React.Fragment key={index}>
                                <Grid container>
                                    <BodyGlid xs={2}>
                                        {format(
                                            new Date(value?.created_at),
                                            "MMM do EEEE Ho",
                                            { locale: ja }
                                        )}
                                        {/* {value.created_at} */}
                                    </BodyGlid>
                                    <BodyGlid xs={1}>
                                        {value?.talent_name}
                                    </BodyGlid>
                                    <BodyGlid xs>
                                        {value?.email_address}
                                    </BodyGlid>
                                    <BodyGlid xs={1}>
                                        {value?.user_id
                                            ? value?.user_id
                                            : "未登録"}
                                    </BodyGlid>
                                    {/* {serch_option.document_kubun != "1" && (
                                        <BodyGlid xs>{index}</BodyGlid>
                                    )} */}
                                    <BodyGlid xs={1}>
                                        <LinkButton
                                            variant="outlined"
                                            to={`/TalentDetail/${value?.talent_id}`}
                                        >
                                            詳細
                                        </LinkButton>
                                    </BodyGlid>
                                    <BodyGlid xs={1}>
                                        {value.delete_flag == "0" ? (
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() =>
                                                    HandleDelete(
                                                        value?.talent_id
                                                    )
                                                }
                                            >
                                                削除
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() =>
                                                    HandleRestoration(
                                                        value?.talent_id
                                                    )
                                                }
                                            >
                                                復元
                                            </Button>
                                        )}
                                    </BodyGlid>{" "}
                                    <BodyGlid xs={1}>
                                        {value?.active_flag == "1" ? (
                                            <>
                                                {
                                                    // value.user_id &&
                                                    value?.status != "2" ? (
                                                        <Button
                                                            variant="contained"
                                                            onClick={() =>
                                                                HandleDenial(
                                                                    value?.talent_id
                                                                )
                                                            }
                                                        >
                                                            否認
                                                        </Button>
                                                    ) : (
                                                        ""
                                                    )
                                                }
                                            </>
                                        ) : (
                                            "承認済"
                                        )}
                                    </BodyGlid>
                                    <BodyGlid xs={1}>
                                        {value?.active_flag == "1" ? (
                                            <>
                                                {
                                                    // value.user_id &&
                                                    value?.status != "1" ? (
                                                        <Button
                                                            variant="contained"
                                                            onClick={() =>
                                                                HandleApproval(
                                                                    value?.talent_id
                                                                )
                                                            }
                                                        >
                                                            承認
                                                        </Button>
                                                    ) : (
                                                        ""
                                                    )
                                                }
                                            </>
                                        ) : (
                                            "承認済"
                                        )}
                                    </BodyGlid>
                                    <BodyGlid xs={1}>
                                        <ApprovalButton
                                            request_data={value}
                                            HandleRelease={HandleRelease}
                                        />
                                    </BodyGlid>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </>
                )}

                {TalentRequestState.serch_info?.page_count > 1 && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Pagination
                            shape="rounded"
                            count={TalentRequestState.serch_info?.page_count}
                            variant="outlined"
                            page={TalentRequestState.serch_info.page}
                            color="secondary"
                            onChange={handleChangePage}
                        />
                    </Grid>
                )}
            </Grid>
            {/* <div style={{ padding: "5px" }}>
                <CategoriesAccordion categories={thisState.qas} />
            </div>
            <Typography style={{ padding: "20px" }}>
                FAQでは解決しないご質問や、ご意見・ご要望等ございましたら、下記のお問い合わせ先までお願いいたします。
            </Typography> */}
        </Container>
    );
};

const ApprovalButton = (props) => {
    const { request_data, HandleRelease } = props;

    const HandleDenialMessage = () => {
        alert(
            `否認理由\n${request_data.reason ? request_data.reason : 未記入}`
        );
    };
    switch (true) {
        case request_data?.active_flag == "0":
            return "公開済";

        case request_data?.status == "2":
            return (
                <Button variant="text" onClick={HandleDenialMessage}>
                    否認
                </Button>
            );

        case request_data?.status == "1":
            return (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => HandleRelease(request_data?.talent_id)}
                >
                    公開
                </Button>
            );
        default:
            return "";
    }
};
export default TalentRequestList;
