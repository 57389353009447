import React from "react";
import TextField from "@material-ui/core/TextField";
const SelectTime = (props) => {
    const { view, value, ChangeTime, val_name, label, ...other } = props;

    if (view) {
        return value;
    }

    //時のコンボボックス選択
    const ChangeHours = (e, name, value) => {
        let time = createTime(e.target.value, value ? value.substr(3, 2) : "--");
        ChangeTime(time, name);
    };

    //分のコンボボックス選択
    const ChangeMinutes = (e, name, value) => {
        let time = createTime(value ? value.substr(0, 2) : "--", e.target.value);
        ChangeTime(time, name);
    };

    //HH:mmまたは空白に変換
    const createTime = (hour, minute) => {
        let time = `${hour}:${minute}`;
        return time != "--:--" ? time : "";
    };

    return (
        <>
            <label style={{ display: "table-header-group" }}>
                {label}
            </label>
            <TextField
                style={{ width: "64px" }}
                select
                name="hour"
                fullWidth
                variant="outlined"
                value={value ? value.substr(0, 2) : "--"}
                SelectProps={{
                    native: true,
                }}
                onChange={(e) =>
                    ChangeHours(
                        e,
                        val_name,
                        value
                    )
                }
                size="small"
                {...other}
            >
                <option value={"--"}>--</option>
                <option value={"00"}>00</option>
                <option value={"01"}>01</option>
                <option value={"02"}>02</option>
                <option value={"03"}>03</option>
                <option value={"04"}>04</option>
                <option value={"05"}>05</option>
                <option value={"06"}>06</option>
                <option value={"07"}>07</option>
                <option value={"08"}>08</option>
                <option value={"09"}>09</option>
                <option value={"10"}>10</option>
                <option value={"11"}>11</option>
                <option value={"12"}>12</option>
                <option value={"13"}>13</option>
                <option value={"14"}>14</option>
                <option value={"15"}>15</option>
                <option value={"16"}>16</option>
                <option value={"17"}>17</option>
                <option value={"18"}>18</option>
                <option value={"19"}>19</option>
                <option value={"20"}>20</option>
                <option value={"21"}>21</option>
                <option value={"22"}>22</option>
                <option value={"23"}>23</option>
                <option value={"24"}>24</option>
                <option value={"25"}>25</option>
                <option value={"26"}>26</option>
                <option value={"27"}>27</option>
                <option value={"28"}>28</option>
                <option value={"29"}>29</option>
                <option value={"30"}>30</option>
            </TextField>
            <label style={{ margin: "10px 5px" }}>
                {":"}
            </label>
            <TextField
                style={{ width: "64px" }}
                select
                name="minute"
                fullWidth
                variant="outlined"
                value={value ? value.substr(3, 2) : "--"}
                SelectProps={{
                    native: true,
                }}
                onChange={(e) =>
                    ChangeMinutes(
                        e,
                        val_name,
                        value
                    )
                }
                size="small"
                {...other}
            >
                <option value={"--"}>--</option>
                {[...Array(12)].map((val, i) => (
                    <React.Fragment key={i}>
                        {i * 5 < 10 ?
                            <option value={'0' + (i * 5)}>{'0' + (i * 5)}</option> :
                            <option value={i * 5}>{i * 5}</option>
                        }
                    </React.Fragment>
                ))}




                {/* <option value={"--"}>--</option>
                <option value={"00"}>00</option>
                <option value={"10"}>10</option>
                <option value={"20"}>20</option>
                <option value={"30"}>30</option>
                <option value={"40"}>40</option>
                <option value={"50"}>50</option> */}
            </TextField>
        </>
    );
};

export default SelectTime;
