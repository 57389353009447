import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import MascotDrawDialog from "./MascotDrawDialog";
import Slide from "@material-ui/core/Slide";
import { set_general_mascot, GetMascotDraw, ReadMascotDraw } from "../../stores/GeneralMascotDraw";

const GeneralMascotDraw = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.GeneralMascotDraw);
    const hasShownPopup = useSelector((state) => state.NotificationList.popup_show);
    const userToken = useSelector((state) => state.Information.user.token);

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const handleClose = () => {
        dispatch(set_general_mascot(["show_draw", false]));
    };

    // 初回レンダリング
    useEffect(() => {
        if (!userToken) return;

        var now = new Date();
        var today = `${now.getMonth()}${now.getDate()}`;

        // アプリ起動後初めて、もしくはアプリ使用中に日付をまたいでから初めてHOMEを開いたとき
        if (today !== thisState.last_date) {
            dispatch(GetMascotDraw());
            dispatch(set_general_mascot(["last_date", today]));
        } 
    }, []);
    // ログインボーナス表示時
    useEffect(() => {
        if (thisState.show_draw && hasShownPopup) {
            var date = new Date();
            const strNow = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
            const params = {
                read_at: strNow,
                mascot_id: thisState.mascot_data.mascot_id,
                mascot_name: thisState.mascot_data.mascot_name,
                mascot_point: thisState.mascot_data.mascot_point,
            };
            dispatch(ReadMascotDraw(params));
        }
    }, [thisState.show_draw, hasShownPopup]);

    return (
        <MascotDrawDialog
            open={thisState.show_draw && hasShownPopup}
            title={thisState.mascot_data.draw_title}
            image={thisState.mascot_data.mascot_image_big}
            detail={thisState.mascot_data.draw_detail}
            url={thisState.mascot_data.mascot_url}
            point={thisState.mascot_data.mascot_point}
            onClose={handleClose}
            TransitionComponent={Transition}
        />
    );
};

export default GeneralMascotDraw;
