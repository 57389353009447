import React from "react";
import Button from "@material-ui/core/Button";
import { Grid, Container } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";

import Facebook from "/images/logo/Facebookロゴ.png";
import Instagram from "/images/logo/Instagramロゴ.png";
import Twitter from "/images/logo/Twitterロゴ.png";
import Web from "/images/logo/Webロゴ.png";
import Youtube from "/images/logo/Youtubeロゴ.png";
import TikTok from "/images/logo/Tiktokロゴ.png";
import fiftyCardSNS from "/images/logo/fiftyCardSNSロゴ.png";
import Talentbank from "/images/logo/Talentbankロゴ.png";
import LINE from "/images/logo/LINEロゴ.png";
import Xlogo from "/images/logo/Xロゴ.jpg";

const OfficialSNS = () => {
    return (
        <React.Fragment>
            <Grid item>
                <h1>公式SNS</h1>
            </Grid>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Container>
                    <LinkBottun
                        link="https://instagram.com/50fiftycard_official"
                        image={Instagram}
                        label="Instagram"
                    />

                    <LinkBottun
                        link="https://www.facebook.com/profile.php?id=100071202338904"
                        image={Facebook}
                        label="Facebook"
                    />

                    <LinkBottun
                        link="https://twitter.com/fifty_staff/"
                        image={Xlogo}
                        label="X"
                    />
                    <LinkBottun
                        link="https://youtube.com/@50off95?si=EnSIkaIECMiOmarE"
                        image={Youtube}
                        label="Youtube"
                    />
                    <LinkBottun
                        link="https://www.tiktok.com/@fifty_card"
                        image={TikTok}
                        label="TikTok"
                    />
                    {/* <LinkBottun
                        link="https://lin.ee/bMRyhQh"
                        image={LINE}
                        label="LINE"
                    /> */}
                    <LinkBottun
                        link="https://fifty-card.net/"
                        image={fiftyCardSNS}
                        label="加盟店募集中"
                    />
                    <LinkBottun
                        link="https://talent-park.net/front"
                        image={Talentbank}
                        label="タレント登録"
                    />
                </Container>
            </Grid>
        </React.Fragment>
    );
};

const LinkBottun = (props) => {
    const { link, image, label } = props;
    return (
        <Button
            style={{ width: "100%", marginTop: "1rem", padding: "1rem" }}
            variant="outlined"
            color="primary"
            href={link}
            startIcon={<img style={{ width: "30%" }} src={image}></img>}
        >
            <Grid style={{ width: "50%" }} item>
                <Typography>{label}</Typography>
            </Grid>
        </Button>
    );
};

export default OfficialSNS;
