import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {
    ButtonContainedPrimary,
    ButtonOutlinedPrimary,
} from "../utils/Buttons";
import HistoryTable from "../modules/HistoryTable";
import { useDispatch, useSelector } from "react-redux";
import {
    set_shoplist_value,
    initialize,
    GetShopList,
} from "../../stores/AdminShopList";
import { set_table_value, initialize_table } from "../../stores/HistoryTable";
import ModalGeneral from "../utils/ModalGeneral";
import { TextFieldNormal } from "../utils/TextFields";
import SelectPrefectures from "../utils/SelectPrefectures";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import ShopAdditionDialog from "../modules/ShopAdditionDialog";
import { useHistory } from "react-router";
import TokenErrorDialog from "../utils/TokenErrorDialog";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { ButtonNavi } from "~CJS/block/ButtonNavi";

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        paddingLeft: "40px",
        paddingRight: "40px",
    },
    modalTitle: {
        fontSize: "2rem",
        color: theme.palette.primary.main,
        textAlign: "center",
    },
    prefectures: {
        "& .MuiFormLabel-root.Mui-disabled": {
            color: "inherit",
        },
    },
    stateLabel: {
        fontSize: "16px",
        marginBottom: 0,
    },
    condition: {
        marginBottom: "40px",
    },
    searchButton: {
        display: "block",
        margin: "auto",
    },
}));

const SearchModal = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.AdminShopList);
    const classes = useStyles();
    const [condition, setCondition] = React.useState({
        freeword: thisState.freeword,
        prefectures: thisState.prefectures,
        checkedAllPref: thisState.checkedAllPref,
        apply_status: thisState.apply_status,
        detail_on: thisState.detail_on,
        detail_off: thisState.detail_off,
        stop_release: thisState.stop_release,
    });

    const handleChangeValue = (e) => {
        setCondition({ ...condition, [e.target.name]: e.target.value });
    };
    const handleChangeChecked = (e) => {
        setCondition({ ...condition, [e.target.name]: e.target.checked });
    };
    const handleClickSearch = () => {
        dispatch(set_shoplist_value(["freeword", condition.freeword]));
        dispatch(set_shoplist_value(["prefectures", condition.prefectures]));
        dispatch(
            set_shoplist_value(["checkedAllPref", condition.checkedAllPref])
        );
        dispatch(set_shoplist_value(["apply_status", condition.apply_status]));
        dispatch(set_shoplist_value(["detail_on", condition.detail_on]));
        dispatch(set_shoplist_value(["detail_off", condition.detail_off]));
        dispatch(set_shoplist_value(["stop_release", condition.stop_release]));
        dispatch(set_table_value(["page", 1]));
        dispatch(
            set_shoplist_value(["search_triger", thisState.search_triger + 1])
        );
        dispatch(set_shoplist_value(["open_search_dialog", false]));
    };
    const handleCloseSearch = () => {
        dispatch(set_shoplist_value(["open_search_dialog", false]));
    };

    return (
        <ModalGeneral open onClose={handleCloseSearch}>
            <Container className={classes.modalContainer}>
                <p className={classes.modalTitle}>条件設定</p>
                <TextFieldNormal
                    name="freeword"
                    label="ワード検索"
                    placeholder="加盟店ID or 加盟店名（部分一致可）"
                    defaultValue={condition.freeword}
                    className={classes.condition}
                    onBlur={handleChangeValue}
                    inputProps={{ maxLength: 255 }}
                />
                <div className={classes.condition}>
                    <SelectPrefectures
                        name="prefectures"
                        disabled={condition.checkedAllPref}
                        className={classes.prefectures}
                        value={condition.prefectures}
                        onChange={handleChangeValue}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="checkedAllPref"
                                checked={condition.checkedAllPref}
                                onChange={handleChangeChecked}
                            />
                        }
                        label="すべて"
                    />
                </div>
                <div className={classes.condition}>
                    <p className={classes.stateLabel}>申請状況</p>
                    <RadioGroup
                        aria-label="申請状況"
                        name="apply_status"
                        value={condition.apply_status}
                        onChange={handleChangeValue}
                    >
                        <Grid container>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="すべて"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="基本情報/スナップ申請中"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    value="3"
                                    control={<Radio />}
                                    label="基本情報差戻し中"
                                />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </div>
                <div className={classes.condition}>
                    <p className={classes.stateLabel}>公開状況</p>
                    <FormGroup aria-label="公開状況">
                        <Grid container>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={condition.detail_on}
                                            onChange={handleChangeChecked}
                                            name="detail_on"
                                        />
                                    }
                                    label="公開中"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={condition.detail_off}
                                            onChange={handleChangeChecked}
                                            name="detail_off"
                                        />
                                    }
                                    label="未公開"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={condition.stop_release}
                                            onChange={handleChangeChecked}
                                            name="stop_release"
                                        />
                                    }
                                    label="利用停止"
                                />
                            </Grid>
                        </Grid>
                    </FormGroup>
                </div>
                <ButtonContainedPrimary
                    className={classes.searchButton}
                    onClick={handleClickSearch}
                >
                    検索
                </ButtonContainedPrimary>
            </Container>
        </ModalGeneral>
    );
};

const AdminShopList = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.AdminShopList);
    const tableState = useSelector((state) => state.HistoryTable);
    const history = useHistory();

    // ---------一覧表内のボタン用の関数-------------
    const handleToShopEditing = (shop_id) => {
        history.push(`/AdminShopEditing/${shop_id}`);
    };
    const handleToApplicationDetail = (params) => {
        const shop_id = params.id;
        let apply_id = params.row.apply_id;
        if (!apply_id) apply_id = "undefined";
        history.push(`/AdminStoreApplication/${shop_id}/${apply_id}`);
    };
    const handleToSnapDetail = (shop_id) => {
        history.push(`/AdminSnap/${shop_id}`);
    };
    // ---------------------------------------------

    const columns = [
        {
            field: "shop_id",
            headerName: "加盟店ID",
            width: 90,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "shop_name",
            headerName: "加盟店名",
            flex: 1,
            minWidth: 120,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "recommend_flag",
            headerName: "おすすめ",
            valueFormatter: (params) => (params.value === "1" ? "○" : "-"),
            align: "center",
            width: 90,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "special_flag",
            headerName: "優良店舗",
            valueFormatter: (params) => (params.value === "1" ? "○" : "-"),
            align: "center",
            width: 90,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "status",
            headerName: "公開状況",
            valueFormatter: (params) => {
                switch (params.value) {
                    case 0:
                        return "未公開";
                    case 1:
                        return "公開中";
                    case 2:
                        return "利用停止";
                    default:
                        return "その他";
                }
            },
            align: "center",
            width: 90,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "edit",
            headerName: "編集",
            renderCell: (params) => (
                <ButtonContainedPrimary
                    onClick={() => handleToShopEditing(params.id)}
                >
                    編集
                </ButtonContainedPrimary>
            ),
            align: "center",
            width: 80,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "approval_flag",
            headerName: "基本情報",
            renderCell: (params) => {
                if (params.value === "0") {
                    return (
                        <ButtonContainedPrimary
                            disabled={params.row.status === 2}
                            onClick={() => handleToApplicationDetail(params)}
                        >
                            申請中
                        </ButtonContainedPrimary>
                    );
                } else if (params.value === "2") {
                    return (
                        <ButtonOutlinedPrimary
                            disabled={params.row.status === 2}
                            onClick={() => handleToApplicationDetail(params)}
                        >
                            差戻し中
                        </ButtonOutlinedPrimary>
                    );
                } else {
                    return (
                        <ButtonOutlinedPrimary
                            disabled={params.row.status === 2}
                            onClick={() => handleToApplicationDetail(params)}
                        >
                            新規申請
                        </ButtonOutlinedPrimary>
                    );
                }
            },
            align: "center",
            width: 90,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "snap_count",
            headerName: "スナップ",
            renderCell: (params) =>
                params.value > 0 ? (
                    <ButtonContainedPrimary
                        disabled={params.row.status === 2}
                        onClick={() => handleToSnapDetail(params.id)}
                    >
                        申請確認
                    </ButtonContainedPrimary>
                ) : (
                    //"-"
                    <ButtonOutlinedPrimary
                        disabled={params.row.status === 2}
                        onClick={() => handleToSnapDetail(params.id)}
                    >
                        新規申請
                    </ButtonOutlinedPrimary>
                ),
            align: "center",
            width: 90,
            headerAlign: "center",
            sortable: false,
        },
    ];

    const handleOpenSearch = () => {
        dispatch(set_shoplist_value(["open_search_dialog", true]));
    };

    const getShopList = (page) => {
        const send_pref = thisState.checkedAllPref ? "" : thisState.prefectures;
        const params = {
            word: thisState.freeword,
            prefectures: send_pref,
            status: thisState.apply_status,
            detail_off: Number(thisState.detail_off),
            detail_on: Number(thisState.detail_on),
            active_flag: Number(thisState.stop_release),
            page: page,
        };
        // console.log(params);

        dispatch(GetShopList(params));
    };

    const handleClickOK = () => {
        dispatch(set_shoplist_value(["open_token_error_dialog", false]));
    };

    useEffect(() => {
        if (history.location.search === "?mode=visit") {
            dispatch(initialize());
            dispatch(initialize_table());
        }
    }, []);

    useEffect(() => {
        getShopList(tableState.page);
    }, [tableState.page, thisState.search_triger]);

    return (
        <Container>
            <ButtonNavi left_label="Home画面" left_link="/AdminHome" />
            <h1>加盟店一覧</h1>
            {thisState.show_alert && (
                <Alert severity="error">
                    <AlertTitle>{thisState.error_title}</AlertTitle>
                    {thisState.error_message}
                </Alert>
            )}
            <Grid container>
                <Grid item style={{ margin: "10px" }}>
                    <ButtonContainedPrimary onClick={handleOpenSearch}>
                        検索条件変更
                    </ButtonContainedPrimary>
                </Grid>
                <Grid item style={{ margin: "10px" }}>
                    <ButtonOutlinedPrimary
                        onClick={() =>
                            dispatch(
                                set_shoplist_value([
                                    "open_addition_dialog",
                                    true,
                                ])
                            )
                        }
                    >
                        加盟店新規追加
                    </ButtonOutlinedPrimary>
                </Grid>
                <Grid item style={{ margin: "10px", fontSize: "x-large" }}>
                    総店舗数
                    <div
                        style={{
                            display: "inline-block",
                            fontSize: "xx-large",
                            fontWeight: "bold",
                            marginLeft: "0.5rem",
                        }}
                    >
                        {thisState.total_rows}
                    </div>
                    件
                </Grid>
            </Grid>
            {thisState.open_search_dialog && <SearchModal />}
            <HistoryTable
                rows={thisState.shop_list}
                columns={columns}
                recordsPerPageOptions={[tableState.per_page]}
                getRowId={(row) => row.shop_id}
                needsToolbar={false}
                disableColumnMenu={true}
            />
            <ShopAdditionDialog />
            <TokenErrorDialog
                open={thisState.open_token_error_dialog}
                admin={true}
                onClickOK={handleClickOK}
            />
        </Container>
    );
};

export default AdminShopList;
