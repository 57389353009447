import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import HttpFunction from "../utils/HttpFunction";
import { set_alert } from "../../stores/ErrorAlert";
import { notice_set_value, GetNotices } from "../../stores/NotificationList";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { Container } from "@material-ui/core";
import NotificationInformation from "./NotificationInformation";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";

import EmailIcon from "@material-ui/icons/Email";
import DraftsIcon from "@material-ui/icons/Drafts";

import { Fragment } from "react";
const useStyles = makeStyles({
    root: {
        background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
        border: 0,
        borderRadius: 3,
        boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        color: "white",
        height: 48,
        padding: "0 30px",
    },
    ListItemText: {
        marginLeft: "1rem",
    },
    ListItemNoRead: {},
    ListItemRead: {
        background: "silver",
    },
});

const NotificationList = () => {
    const dispatch = useDispatch();
    const noticeState = useSelector((state) => state.NotificationList);
    const noticeDataState = noticeState.notification_list_data;
    const notification_list_data = noticeState.notification_list_data;
    const InformationState = useSelector((state) => state.Information);
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        dispatch(GetNotices());
    }, []);

    useEffect(() => {
        dispatch(set_alert(noticeState.error_message));
    }, [noticeState.error_message]);

    const ReadStyle = (index) => {
        return noticeDataState[index].read_flag == "1"
            ? classes.ListItemRead
            : classes.ListItemNoRead;
    };

    const LinkOpen = (notification_id) => {
        Read(notification_id);
        history.push(`/NotificationInformation/${notification_id}`);
    };

    const Read = (notification_id) => {
        const params = {
            notification_id: notification_id,
        };

        let api_key = "";
        let url = "";

        if (InformationState.user.user_login) {
            api_key = InformationState.user.token;
            url = "/api/notice_read";
        }
        if (InformationState.shop.shop_login) {
            api_key = InformationState.shop.shop_token;
            url = "/api/shop_notice_read";
        }

        HttpFunction("POST", url, params, api_key)
            .then((data) => {
                dispatch(GetNotices());
            })
            .catch((err) => {
                // console.log(err);
            });
    };
    const ReadAll = () => {
        notification_list_data.map((notification_data, index) => {
            Read(notification_list_data[index].notification_id);
        });
    };
    const Icon = (index) => {
        return (
            <Fragment>
                {notification_list_data[index].read_flag == "1" ? (
                    <DraftsIcon color="primary" />
                ) : (
                    <EmailIcon />
                )}
            </Fragment>
        );
    };

    return (
        <Container className={classes.paper}>
            {notification_list_data.map((notification_data, index) => (
                <Fragment key={`notification_id-${notification_data.id}`}>
                    <ListItem className={ReadStyle(index)}>
                        <Avatar
                            onClick={() =>
                                Read(notification_data.notification_id)
                            }
                        >
                            {Icon(index)}
                        </Avatar>

                        <ListItemText
                            className={classes.ListItemText}
                            primary={notification_data.notification_title}
                            onClick={() =>
                                LinkOpen(notification_data.notification_id)
                            }
                            secondary={notification_data.release_start_date}
                        />
                    </ListItem>

                    <Divider variant="inset" />
                </Fragment>
            ))}

            <div className="text-right">
                <Button onClick={(e) => ReadAll()}>すべて既読にする</Button>
            </div>
        </Container>
    );
};

export default NotificationList;
