import React from "react";

import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router";

import Typography from "@material-ui/core/Typography";
const IngenuityTitle = (props) => {
    const { title, lanking, recommend, list, ...others } = props;
    const history = useHistory();

    let icon = "";
    if (recommend) icon = "/storage/top/2462.png";
    if (lanking) icon = "/storage/top/2121.png";
    lanking;
    return (
        <>
            <div
                style={{
                    fontSize: "1.8rem",
                    width: "100%",
                    display: "flex",
                    padding: "0.1rem",
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                    //borderBottom: "solid 2px pink",
                }}
                className={"ingenuity"}
            >
                {/* <i className="far fa-lightbulb fuwafuwa">
                    {icon ? <img src={icon} height="50" width="50" /> : ""}
                </i> */}

                {/* <Typography variant="h3" style={{ fontWeight: "bold" }}> */}
                <Typography
                    variant="h3"
                    style={{
                        fontWeight: "bold",
                        width: "100%",
                        textAlign: "left",
                    }}
                >
                    {title}
                </Typography>
                {list ? (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        style={{
                            fontSize: "initial",
                            width: "28%",
                            color: "#f06292",
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                        }}
                        onClick={() => {
                            history.push(list);
                        }}
                    >
                        もっとみる
                    </Grid>
                ) : (
                    ""
                )}
            </div>
        </>
    );
};

export default IngenuityTitle;
