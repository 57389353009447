import React, { useRef, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const SelectRawYearMonth = (props) => {
    const { noInput, ...other } = props;
    const el = useRef(null);

    const onClick = () => {
        el.current.value = "";
    };

    return (
        <>
            <Grid container>
                <Grid item xs>
                    <TextField
                        type="month"
                        InputLabelProps={{ shrink: true }}
                        label="生年月"
                        fullWidth
                        inputRef={el}
                        {...other}
                    />
                </Grid>
                {noInput ? (
                    ""
                ) : (
                    <Grid item>
                        <Button onClick={onClick}>リセット</Button>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default SelectRawYearMonth;
