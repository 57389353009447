import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";
import { UserInfoGet } from "./Information";

// Stateの初期状態
const initialState = {
    check_list: [],
    item_list: "",
    open_modal: false,
    error_message: "",
    page: 1,
    last_page: 1,
    image_path: "",
    product_name: "",
    product_comment: "",
    necessary_point: 50,
    release_start_date: "2021-10-01 10:00:00",
    release_end_date: "9999-12-31 23:59:59",
    modal_progress: false,
};

export const GetGiftList = createAsyncThunk(
    "GeneralRedeemingPointList/GetGiftList",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;
        const redeemingPointState =
            thunkAPI.getState().GeneralRedeemingPointList;
        const api_key = informationState.user.token;
        const params = {
            page: redeemingPointState.page,
        };
        const url = "/api/point_items";

        const response = await axios.get(
            url + "?" + new URLSearchParams(params),
            {
                headers: {
                    "API-KEY": api_key,
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            }
        );
        return response.data;
    }
);

export const PointExchange = createAsyncThunk(
    "GeneralRedeemingPointList/PointExchange",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;

        const api_key = informationState.user.token;

        if (!api_key) return;

        const params = {
            product_id: arg.product_id,
            exchange_count: 1,
        };

        const SetErrer = (message) => {
            dispatch(exchange_set(["error_message", message]));
        };

        HttpFunction("POST", "/api/point_redeeming", params, api_key)
            .then((data) => {
                if (data.error_message) {
                    dispatch(exchange_set(["open_modal", false]));

                    SetErrer(["サーバーエラー", data.error_message]);

                    return;
                }
                if (data.status == 0) {
                    dispatch(exchange_set(["open_modal", false]));
                    dispatch(UserInfoGet(api_key));
                    SetErrer([
                        "メッセージ",
                        "交換処理が完了しました。",
                        "info",
                    ]);
                    //アプリでの処理完了後、商品交換申し込みフォームを開く
                    window.open(`https://forms.gle/RnPjir7krGHvnDn29`, '_blank');
                }
            })
            .catch((err) => {
                // console.log("サーバーエラー");
                // console.log(err);
                dispatch(
                    exchange_set(["error_message", ["サーバーエラー", err]])
                );
            });
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "GeneralRedeemingPointList",
    initialState,
    reducers: {
        exchange_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        [GetGiftList.pending]: (state, action) => {
            // // console.log(" 処理開始");
            state.modal_progress = true;
        },
        [GetGiftList.fulfilled]: (state, action) => {
            // console.log(action.payload);
            state.item_list = action.payload.data;
            state.last_page = action.payload.last_page;
            state.modal_progress = false;
        },
        [GetGiftList.rejected]: (state, action) => {
            // console.log(action);

            // console.log(action.payload);
            state.modal_progress = false;
            state.error_message = [
                "ネットワークエラー",
                "交換品の取得に失敗しました",
            ];
        },
        [PointExchange.rejected]: (state, action) => {
            state.open_modal = false;
            state.error_message = ["ネットワークエラー", "交換に失敗しました"];
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { exchange_set } = slice.actions;
