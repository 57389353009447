import React, { useState, useEffect } from "react";
import Image from "react-bootstrap/Image";
import { set_alert } from "../../stores/ErrorAlert";
import { Grid, Button, TextField } from "@material-ui/core/";
import ItemCard from "../modules/ItemCard";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import ModalGeneral from "../utils/ModalGeneral";
import Pagination from "@material-ui/lab/Pagination";
import { shallowEqual } from "react-redux";
import ModalProgress from "../utils/ModalProgress";

import {
    exchange_set,
    GetGiftList,
    CreateGift,
    DeleteGift,
    initialize,
    FixGift,
} from "../../stores/AdminRedeemingPointList";
import InputImagePreview from "../utils/InputImagePreview";
import { TextFieldValidation } from "../utils/TextFields";
import { ButtonNavi, Fullwidth } from "~CJS/block/ButtonNavi";

// 加盟店編集

const AdminRedeemingPointList = () => {
    const onNewItemButton = () => {
        OpenModal();
    };

    const dispatch = useDispatch();
    const informationState = useSelector((state) => state.Information);
    const exchange_state = useSelector(
        (state) => state.AdminRedeemingPointList,
        shallowEqual
    );
    const open_modal = useSelector(
        (state) => state.AdminRedeemingPointList.open_modal
    );

    useEffect(() => {
        dispatch(initialize()), dispatch(GetGiftList());
    }, []);

    useEffect(() => {
        dispatch(set_alert(exchange_state.error_message));
    }, [exchange_state.error_message]);

    const item_list = exchange_state.item_list;

    const OpenModal = () => {
        dispatch(exchange_set(["image_path", ""]));
        dispatch(exchange_set(["product_name", ""]));
        dispatch(exchange_set(["product_comment", ""]));
        dispatch(exchange_set(["necessary_point", 0]));
        dispatch(exchange_set(["fix_flag", false]));
        dispatch(exchange_set(["open_modal", true]));
    };
    const CloseModal = () => {
        dispatch(exchange_set(["open_modal", false]));
    };
    const deleteItem = (data) => {
        // console.log("del");
        const result = confirm("交換商品を削除しますか?");
        if (!result) return;
        dispatch(DeleteGift(data));
    };

    const fixItem = (data) => {
        dispatch(exchange_set(["product_id", data?.product_id]));
        dispatch(exchange_set(["image_path", data?.image_path]));
        dispatch(exchange_set(["product_name", data?.product_name]));
        dispatch(exchange_set(["product_comment", data?.product_comment]));
        dispatch(exchange_set(["necessary_point", data?.necessary_point]));
        dispatch(exchange_set(["updated_at", data?.updated_at]));
        dispatch(exchange_set(["fix_flag", true]));
        dispatch(exchange_set(["open_modal", true]));
    };

    const SetImage = (arg) => {
        const image = arg.image;
        // console.log(image);

        dispatch(exchange_set(["image_path", image]));
    };

    const Create = () => {
        if (exchange_state.fix_flag) {
            dispatch(FixGift());
        } else {
            dispatch(CreateGift());
        }
    };

    const HandleSet = (e) => {
        dispatch(exchange_set([e.currentTarget.id, e.currentTarget.value]));
    };

    const PageChange = (e, value) => {
        dispatch(exchange_set(["page", value]));
        dispatch(GetGiftList());
    };

    return (
        <React.Fragment>
            <ModalProgress open={exchange_state.modal_progress} />
            <ModalGeneral
                open={open_modal}
                className={"test-modal"}
                onClose={CloseModal}
            >
                <MainImage image_path={exchange_state.image_path} />

                <InputImagePreview
                    comment={false}
                    onClick={SetImage}
                    height={200}
                    image_big={exchange_state.image_path}
                    image={exchange_state.image_path}
                />

                <InputText
                    exchange_state={exchange_state}
                    HandleSet={HandleSet}
                    Create={Create}
                />
            </ModalGeneral>
            <ButtonNavi left_label="Home画面" left_link="/AdminHome" />

            <h1>交換商品設定</h1>
            <Button variant="contained" onClick={() => onNewItemButton()}>
                新規追加
            </Button>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                現在のリスト
            </Grid>

            <Grid container>
                {item_list ? (
                    item_list.map((data, index) => (
                        <Grid
                            item
                            xs={6}
                            style={{
                                padding: "0.5rem",
                            }}
                            key={index}
                        >
                            <Grid>
                                <ItemCard
                                    data={data}
                                    index={index}
                                    OnExchange={() => deleteItem(data)}
                                    ButtonName="削除"
                                    OnFix={() => fixItem(data)}
                                    FixButtonName="編集"
                                />
                            </Grid>
                        </Grid>
                    ))
                ) : (
                    <>交換できる景品がありません</>
                )}
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Pagination
                    count={exchange_state.last_page}
                    variant="outlined"
                    color="primary"
                    page={exchange_state.page}
                    onChange={PageChange}
                />
            </Grid>
        </React.Fragment>
    );
};

const MainImage = (props) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: "200px" }}
        >
            <Image
                src={props.image_path ? props.image_path : "null"}
                onError={(e) => {
                    e.target.onerror = "null";
                    e.target.src = "/images/no_img.png";
                }}
            />
        </Grid>
    );
};

const InputText = (props) => {
    const exchange_state = props.exchange_state;
    const HandleSet = props.HandleSet;
    const Create = props.Create;

    return (
        <Grid style={{ margin: "1rem" }}>
            <TextFieldValidation
                label="品名"
                id="product_name"
                onChange={HandleSet}
                value={exchange_state.product_name}
                onBlur={HandleSet}
                inputProps={{ maxLength: 255 }}
            />

            <TextFieldValidation
                label="必要パカッティー"
                discription="半角数字で入力してください。"
                id="necessary_point"
                onChange={HandleSet}
                value={exchange_state.necessary_point}
                inputProps={{ maxLength: 8, pattern: "^[+]?[0-9]+$" }}
            />

            <div style={{ fontSize: "1rem" }}>商品詳細 *</div>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <TextareaAutosize
                    style={{
                        fontSize: "1rem",
                        borderRadius: "1px",
                        boxSizing: "content-box",
                        whidth: "100%",
                        border: "solid 1px gray",
                        padding: "0.5rem",
                    }}
                    minRows={3}
                    id="product_comment"
                    className="FlexTextarea"
                    as="textarea"
                    value={exchange_state.product_comment}
                    name="product_comment"
                    onChange={HandleSet}
                    maxLength={10000}
                />
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ marginBottom: "1rem", marginTop: "1rem" }}
            >
                <Button
                    color="primary"
                    variant="contained"
                    style={{ width: "50%" }}
                    onClick={() => Create()}
                >
                    決定
                </Button>
            </Grid>
        </Grid>
    );
};

export default AdminRedeemingPointList;
