import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core";
import LINEAt from "../block/LINEAt";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDialog-paper": {
            padding: "15px",
            border: "2px solid orangered",
        },
    },
    title: {
        fontWeight: "bolder",
        marginRight: "5px",
    },
    p_email: {
        margin: 0,
        fontWeight: "bolder",
    },
}));

// Error"500"とあるが、作った経緯でこの名称になっただけで
// 実際はサーバーエラー・ネットワークエラー全体に対して表示している
const Error500Handling = (props) => {
    const { open, GET, network, ...others } = props;
    const classes = useStyles();
    const message = GET
        ? "一度他のページに移動してから、再度このページを開いてみてください。"
        : "時間をおいて、もう一度やり直してください。";

    return (
        <Dialog open={open} className={classes.root} {...others}>
            <p>
                <span className={classes.title}>エラー</span>
                <br />
                {message}
                <br />
                エラーが繰り返される場合は、下記までお問い合わせをお願いいたします。
            </p>
            <p>
                HYBRID BANK Co.Ltd
                <br />
                フィフティーカードアプリ担当
            </p>
            <LINEAt
                link="https://lin.ee/bMRyhQh"
                image="/images/Common/FCラインアット一般用.jpg"
            />
            {/* <p className={classes.p_email}>mail: info@hybridbank.net</p> */}
        </Dialog>
    );
};

export default Error500Handling;
