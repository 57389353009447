import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {
    notice_set_value,
    GetNoticeDetail,
} from "../../stores/NotificationList";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import HttpFunction from "../utils/HttpFunction";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({});

const NotificationInformation = (props) => {
    const dispatch = useDispatch();
    const noticeState = useSelector((state) => state.NotificationList);
    const classes = useStyles();
    const item = props.item;
    const history = useHistory();
    const path = history.location.pathname.split("/");
    const notification_id = path[2];

    const InformationState = useSelector((state) => state.Information);

    useEffect(() => {
        dispatch(GetNoticeDetail({ notification_id: notification_id }));
    }, []);

    const notification_list_data = noticeState.notification_list_data;
    const LinkNotificationList = (e) => {
        history.push("/NotificationList");
    };

    // const thisState = useSelector((state) => state.NotificationList);

    return (
        <Container>
            <ChevronLeftIcon onClick={(e) => LinkNotificationList()} />

            <h2>
                {noticeState.notification_detail &&
                noticeState.notification_detail.notification_title
                    ? noticeState.notification_detail.notification_title
                    : "タイトル未設定"}
            </h2>
            <div
                className="html-preview"
                style={{ width: "100%", textAlign: "right" }}
            >
                {noticeState.notification_detail &&
                noticeState.notification_detail.release_start_date
                    ? noticeState.notification_detail.release_start_date
                    : ""}
            </div>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                {noticeState.notification_detail &&
                noticeState.notification_detail.image_path ? (
                    <>
                        <img
                            width="100%"
                            className={classes.img}
                            src={noticeState.notification_detail.image_path}
                            onError={(e) => {
                                e.target.onerror = "null";
                                e.target.src = "/images/no_img.png";
                            }}
                        />
                    </>
                ) : (
                    ""
                )}
            </Grid>
            <div
                className="html-preview"
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{
                    __html:
                        noticeState.notification_detail &&
                        noticeState.notification_detail.notification_detail
                            ? noticeState.notification_detail.notification_detail.replace(
                                  /\n/g,
                                  "<br>"
                              )
                            : "通知詳細はありません",
                }}
            ></div>
        </Container>
    );
};

export default NotificationInformation;
