import React, { useState, useEffect } from "react";

import Pagination from "@material-ui/lab/Pagination";
// import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import Container from "@material-ui/core/Container";
// import { FormControl } from "@material-ui/core";

import FreeWordInput from "~CJS/atoms/FreeWordInput";
import SelectPrefectures from "~CJS/atoms/SelectPrefectures";
import SelectCity from "~CJS/atoms/SelectCity";
import SelectSex from "~CJS/atoms/SelectSex";
// import SelectGenre from "~/atoms/SelectGenre";
// import TagList from "~CJS/atoms/TagList";
import TagsDialog from "~CJS/block/TagsDialog";
import SelectSort from "~CJS/atoms/SelectSort";
// import SelectCategory from "~/atoms/SelectCategory";
import SelectAge from "~CJS/atoms/SelectAge";
import SelectHeight from "~CJS/atoms/SelectHeight";
import SelectLimit from "~CJS/atoms/SelectLimit";
import { SwitchInput } from "~CJS/atoms/SwitchInput";
import {
    SelectRange,
    AdoptSumRange,
    EvaluationRange,
} from "~CJS/atoms/SelectRange";
import { InputMaxLength } from "~CJS/atoms/TextFields";
import CategoryInput from "~/block/CategoryInput";

const useStyles = makeStyles((theme) =>
    createStyles({
        wrapForm: {
            display: "flex",
            justifyContent: "center",
            width: "95%",
            margin: `${theme.spacing(0)} auto`,
            flexWrap: "wrap",
            padding: "1rem",
            border: "solid 1px",
        },
        wrapText: {
            width: "100%",
        },
        button: {
            //margin: theme.spacing(1),
        },
    })
);

const TalentSearchCriteria = (props) => {
    const { StertSerch, count, children, ...other } = props;
    const classes = useStyles();
    const submit_ref = React.useRef(null);
    const [tag_list, set_tag] = React.useState([
        { category_name: "クリエーター", category_id: "0" },
        { category_name: "パーツ", category_id: "1" },
        { category_name: "マケ女子", category_id: "2" },
        { category_name: "ヨガモデル", category_id: "3" },
        { category_name: "クリニックモデル", category_id: "4" },
    ]);

    const order_list = [
        { label: "登録順", value: "1", column: "created_at", order: "desc" },
        {
            label: "実績順",
            value: "2",
            column: "achievement_count",
            order: "desc",
        },
        { label: "更新順", value: "3", column: "updated_at", order: "desc" },
        { label: "名前順", value: "4", column: "talent_name", order: "desc" },
        {
            label: "評価の高い順",
            value: "5",
            column: "review_average",
            order: "desc",
        },
        {
            label: "評価の低い順",
            value: "5",
            column: "review_average",
            order: "asc",
        },
        // { label: "実績順", value: "6", column: "achievement_count", order: "desc" },
    ];

    const [genre, set_genre] = React.useState("");
    const [sort, set_sort] = React.useState(0);
    const [tag_select_list, set_tag_select] = React.useState([]);
    const [page, set_page] = React.useState(1);
    const [before_criteria, set_before_criteria] = React.useState("");
    const [limit, set_limit] = React.useState(10);
    const [open, set_open] = React.useState(true);

    const TagClick = (category_name) => {
        if (tag_select_list?.includes(category_name)) {
            // 選択タグリストから削除
            set_tag_select(
                tag_select_list.filter((item) => item !== category_name)
            );
        } else {
            set_tag_select([...tag_select_list, category_name]);
        }
    };

    const handleOpen = () => {
        set_open(!open);
    };
    //最初のレンダリングで実行しない;
    // const [count_render, setCountRender] = React.useState(0);
    useEffect(() => {
        // setCountRender(count_render + 1);
        // if (count_render < 1) return;
        submit_ref.current.click();
    }, [page]);

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;
        const keyword = elem.keyword.value;
        const age = elem.age.value;
        const sex = elem.sex.value;
        const height = elem.height.value;
        const evaluation_range = elem.evaluation_range.value;
        const adoptsum_range = elem.adoptsum_range.value;
        const active_flag = elem.active_flag.value;
        const delete_flag = elem.delete_flag.value;
        const date_range = elem.date_range.value;
        const city = elem?.city?.value;
        const category = elem?.category?.value;
        const prefectures = elem?.prefectures?.value;
        const age_private_flag = elem?.age_private_flag?.value;

        const no_page_criteria = {
            keyword: keyword,
            prefectures: prefectures,
            city: city && city != "未選択" ? city : "",
            genre: genre,
            order: JSON.stringify(order_list[sort]),
            // tag_select_list: tag_select_list,
            age_private_flag: age_private_flag,
            tag_select_list: category,
            age: age,
            sex: sex,
            height: height,
            evaluation_range: evaluation_range,
            adoptsum_range: adoptsum_range,
            active_flag: active_flag,
            delete_flag: delete_flag,
            date_range: date_range,
            limit: limit,
        };

        let criteria = { ...no_page_criteria };
        if (
            Object.is(
                JSON.stringify(no_page_criteria),
                JSON.stringify(before_criteria)
            )
        ) {
            set_before_criteria(no_page_criteria);
            criteria["page"] = page;
        } else {
            set_before_criteria(no_page_criteria);
            set_page(1);
            criteria["page"] = "1";
        }

        StertSerch && StertSerch(criteria);
    };

    const handleChangePage = (event, new_page) => {
        set_page(new_page);
    };

    return (
        <React.Fragment>
            <Button onClick={handleOpen}>
                検索条件を{open ? "隠す" : "表示"}
            </Button>
            <form
                name={"serchform"}
                onSubmit={HandleSubmit}
                className={classes.wrapForm}
                style={{ display: open ? "" : "none" }}
            >
                <InputMaxLength
                    style={{ width: "100%", marginBottom: "1rem" }}
                    name="keyword"
                    label="タレント名"
                    variant="outlined"
                    inputProps={{ maxLength: 100 }}
                />
                <SelectPrefectures />
                {/* <SelectCity prefectures={prefectures} /> */}
                <SelectSex />

                <SelectAge />

                <SelectHeight />
                {/* <SelectGenre
                    name="genre"
                    onChange={(e) => set_genre(e.currentTarget.value)}
                /> */}

                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                >
                    <SwitchInput
                        label="年齢非公開を含む"
                        name="age_private_flag"
                        value={true}
                    />
                    <SwitchInput label="削除のみ" name="delete_flag" />
                    <SwitchInput label="無効のみ" name="active_flag" />
                </Grid>
                {/* <SwitchInput label="" name="excellent_flag" /> */}

                <AdoptSumRange />
                <EvaluationRange />
                <SelectRange />
                <CategoryInput />
                {/* <TagsDialog
                    TagClick={TagClick}
                    tag_list={tag_list}
                    tag_select_list={tag_select_list}
                    title="カテゴリ"
                />

                <FreeWordInput
                    tags={tag_select_list}
                    SetTags={set_tag_select}
                /> */}
                {/* <SelectCategory /> */}

                <SelectSort
                    order_list={order_list}
                    value={sort}
                    onChange={(e) => set_sort(e.currentTarget.value)}
                />
                <SelectLimit
                    value={limit}
                    onChange={(e) => set_limit(e.currentTarget.value)}
                />

                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    ref={submit_ref}
                >
                    検索
                </Button>
            </form>
            {children}
            {count > 1 && (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Pagination
                        count={count}
                        variant="outlined"
                        page={page}
                        color="secondary"
                        onChange={handleChangePage}
                    />
                </Grid>
            )}
        </React.Fragment>
    );
};

export default TalentSearchCriteria;
