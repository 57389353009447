import React, { useState, useEffect } from "react";

import ButtonGeneral from "../utils/ButtonGeneral";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import StoreApplicationEdit from "../modules/StoreApplicationEdit";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Container from "@material-ui/core/Container";
import {
    release_info_set,
    GetShopInformationApplication,
    FixShopInformationApplication,
} from "../../stores/ShopStoreApplication";
import ShopStoreApplicationNullCheck from "../utils/ShopStoreApplicationNullCheck";
import ShopStoreApplicationHoursCheck from "../utils/ShopStoreApplicationHoursCheck";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const ShopStoreApplicationFix = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const thisState = useSelector((state) => state.Entry);
    const classes = useStyles();
    const path = history.location.pathname.split("/");
    const apply_id = path[2];
    const releaseInfoState = useSelector((state) => state.ShopStoreApplication);
    const SetErrer = (message) => {
        dispatch(release_info_set(["error_message", message]));
    };

    //申請処理
    const OnApplication = () => {
        const input_list = {
            shop_name: "店舗名",
            shop_explain: "店舗説明",
            store_pr: "サブタイトル",
            shop_menu: "fiftyメニュー",
            shop_city: "市区町村",
            shop_address1: "町名・番地",
            access: "アクセス",
            business_hours: "営業時間",
            holiday: "定休日",
            shop_phone_number: "電話番号",
            shop_staff: "担当者",
        };

        const null_list = ShopStoreApplicationNullCheck(
            input_list,
            releaseInfoState
        );
        if (null_list.length > 0) {
            SetErrer(["エラー", null_list + "は必須項目です", "warning"]);
            return;
        }

        if (!releaseInfoState.sns && !releaseInfoState.url) {
            SetErrer(["エラー", "SNSもしくはURLの入力は必須です", "warning"]);
            return;
        }

        //営業時間チェック
        const hours_check = ShopStoreApplicationHoursCheck(releaseInfoState);
        if(hours_check != null)
        {
            SetErrer(hours_check);
            return;
        }

        // console.log("変更申請");
        //申請処理
        dispatch(FixShopInformationApplication());
    };
    const Back = () => {
        history.push("/ShopStoreApplication");
    };
    useEffect(() => {
        dispatch(GetShopInformationApplication({ apply_id: apply_id }));
    }, []);

    return (
        <Container style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <ChevronLeftIcon onClick={Back} />
            <div style={{ margin: "0.5rem" }} className={classes.root}>
                <h1>店舗申請編集</h1>

                {releaseInfoState.rerender_flag ? (
                    <div>
                        {" "}
                        <div>
                            <StoreApplicationEdit
                                releaseInfoState={releaseInfoState}
                            />
                        </div>
                    </div>
                ) : (
                    <div>
                        <StoreApplicationEdit
                            releaseInfoState={releaseInfoState}
                        />
                    </div>
                )}

                <ButtonGeneral onClick={OnApplication}>申請変更</ButtonGeneral>
            </div>
        </Container>
    );
};

export default ShopStoreApplicationFix;
