import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { set_shoplist_value, CreateShop } from "../../stores/AdminShopList";
import ModalGeneral from "../utils/ModalGeneral";
import Container from "@material-ui/core/Container";
import { TextFieldNormal } from "../utils/TextFields";
import { ButtonContainedPrimary } from "../utils/Buttons";
import { makeStyles } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import TokenErrorDialog from "../utils/TokenErrorDialog";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        paddingLeft: "40px",
        paddingRight: "40px",
    },
    modalTitle: {
        fontSize: "2rem",
        color: theme.palette.primary.main,
        textAlign: "center",
    },
    centerButton: {
        display: "block",
        margin: "auto",
    },
    marker: {
        background: "linear-gradient(transparent 45%, #e9809d7a 0%)",
    },
    centerBlock: {
        textAlign: "center",
    },
    centerText: {
        display: "inline-block",
        textAlign: "left",
    },
}));

const ShopAdditionDialog = () => {
    const shoplistState = useSelector((state) => state.AdminShopList);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [newShop, setNewShop] = React.useState("");
    const [error, setError] = React.useState(false);
    const history = useHistory();

    const handleClose = () => {
        dispatch(set_shoplist_value(["open_addition_dialog", false]));
        dispatch(set_shoplist_value(["before_register", true]));
        dispatch(set_shoplist_value(["show_alert", false]));
    };
    const handleBlur = (e) => {
        if (e.target.value) setError(false);
        setNewShop(e.target.value);
    };
    const handleRegister = () => {
        dispatch(set_shoplist_value(["show_alert", false]));

        if (!newShop) {
            setError(true);
            return;
        }

        const params = { shop_name: newShop };

        dispatch(CreateShop(params));
    };

    const handleClickOK = () => {
        dispatch(set_shoplist_value(["open_token_error_dialog", false]));
    };

    const CreateStoreInfo = () => {
        history.push(
            `/AdminStoreApplication/${shoplistState.new_shop_id}/undefined`
        );
        handleClose();
    };

    return (
        <>
            <ModalGeneral
                open={shoplistState.open_addition_dialog}
                onClose={handleClose}
            >
                <Container className={classes.modalContainer}>
                    {shoplistState.before_register ? (
                        <>
                            <p className={classes.modalTitle}>加盟店登録</p>
                            <TextFieldNormal
                                label="店名"
                                discription="※最大120字です。"
                                error={error}
                                helperText={error && "入力してください。"}
                                inputProps={{ maxLength: 120 }}
                                onBlur={handleBlur}
                            />
                            <ButtonContainedPrimary
                                className={classes.centerButton}
                                onClick={handleRegister}
                            >
                                登録
                            </ButtonContainedPrimary>
                            {shoplistState.show_alert && (
                                <Alert severity="error">
                                    <AlertTitle>
                                        {shoplistState.error_title}
                                    </AlertTitle>
                                    {shoplistState.error_message}
                                </Alert>
                            )}
                        </>
                    ) : (
                        <>
                            <p className={classes.modalTitle}>加盟店登録確認</p>
                            <div
                                className={classes.centerBlock}
                                style={{ padding: "30px" }}
                            >
                                <p
                                    className={classes.centerText}
                                    style={{
                                        fontSize: "large",
                                    }}
                                >
                                    以下の内容で登録が完了しました。
                                    <br />
                                    <span
                                        className={classes.marker}
                                        style={{ fontWeight: "bold" }}
                                    >
                                        加盟店IDと加盟店パスワードを、店舗担当者にメールでご連絡ください。
                                    </span>
                                </p>
                            </div>
                            <ErrorIcon
                                color="primary"
                                fontSize="large"
                                className={classes.centerButton}
                            />
                            <div
                                className={classes.centerBlock}
                                style={{ padding: "10px" }}
                            >
                                <p>
                                    文字の伝え間違い（大文字の「I（アイ）」と小文字の「l（エル）」など）を防ぐため、
                                    <br />
                                    必ず下記のIDとパスワードを
                                    <span className={classes.marker}>
                                        コピー＆ペースト
                                    </span>
                                    してください。
                                </p>
                            </div>
                            <div
                                className={classes.centerBlock}
                                style={{ margin: "10px" }}
                            >
                                <p
                                    className={classes.centerText}
                                    style={{
                                        fontSize: "xx-large",
                                        border: "3px solid #e980937a",
                                        padding: "25px",
                                    }}
                                >
                                    加盟店ID：
                                    <span style={{ fontWeight: "bold" }}>
                                        {shoplistState.new_shop_id}
                                    </span>
                                    <br />
                                    加盟店パスワード：
                                    <span style={{ fontWeight: "bold" }}>
                                        {shoplistState.new_shop_password}
                                    </span>
                                </p>
                                <p className={classes.centerBlock}>
                                    ※加盟店一覧画面からも確認できます。
                                </p>
                            </div>

                            <ButtonContainedPrimary
                                onClick={CreateStoreInfo}
                                style={{
                                    width: "80%",
                                    padding: "1.5rem",
                                    fontSize: "2rem",
                                    fontWeight: "bold",
                                    marginTop: "1rem",
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    display: "block",
                                }}
                            >
                                新規申請
                            </ButtonContainedPrimary>
                        </>
                    )}
                </Container>
            </ModalGeneral>
            <TokenErrorDialog
                open={shoplistState.open_token_error_dialog}
                admin={true}
                onClickOK={handleClickOK}
            />
        </>
    );
};

export default ShopAdditionDialog;
