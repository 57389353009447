import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";
import { set_table_value } from "./HistoryTable";

// Stateの初期状態
const initialState = {
    notice_list: [],
    open_token_error_dialog: false,
    show_alert: false,
};

export const GetNoticeList = createAsyncThunk(
    "AdminNoticeList/GetNoticeList",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        let api_key = thunkAPI.getState().Information.admin.admin_token;

        if (!api_key) api_key = "";
        HttpFunction("GET", "/api/admin_notices", params, api_key)
            .then((data) => {
                // console.log(data);
                if (data.error_message && data.error_message.startsWith("ログイン情報の取得ができませんでした")) {
                    dispatch(set_noticelist_value(["open_token_error_dialog", true]));
                } else if (data) {
                    dispatch(set_noticelist_value(["show_alert", false]));
                    dispatch(set_noticelist_value(["notice_list", data.data]));
                    dispatch(set_table_value(["total_rows", data.total]));
                    dispatch(set_table_value(["per_page", data.per_page]));
                } 
            })
            .catch((err) => {
                // console.log(err);
                dispatch(set_noticelist_value(["error_title", "エラー"]));
                dispatch(set_noticelist_value(["error_message", "一度前のページに戻り、もう一度この画面を開いてみてください。"]));
                dispatch(set_noticelist_value(["show_alert", true]));
            });
    }
);

const isRejectedAction = (action) => {
    return action.type.startsWith("AdminNoticeList") && action.type.endsWith("rejected");
};

// Sliceを生成する
const slice = createSlice({
    name: "AdminNoticeList",
    initialState,
    reducers: {
        set_noticelist_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(isRejectedAction, (state, action) => {
            state["error_title"] = "エラー";
            state["error_message"] = "時間をおいて、もう一度やり直してください。";
            state["show_alert"] = true;
        });
    },
});

export default slice.reducer;
export const {set_noticelist_value} = slice.actions;