import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";

// Stateの初期状態
const initialState = {
    shop_name: "",
    shop_id: "",
    shop_password: "",
    recommend_flag: "0",
    special_flag: "0",
    active_flag: "0",
    updated_at: "",
    status_undetermined: false,
    open_token_error_dialog: false,
    show_alert: false,
    shop_user_list: [],
};

export const GetInfo = createAsyncThunk(
    "AdminShopEditing/GetInfo",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        let api_key = thunkAPI.getState().Information.admin.admin_token;

        if (!api_key) api_key = "";
        HttpFunction("GET", "/api/edit_shop", params, api_key)
            .then((data) => {
                // console.log(data);
                if (
                    data.error_message &&
                    data.error_message.startsWith(
                        "ログイン情報の取得ができませんでした"
                    )
                ) {
                    dispatch(
                        set_shopedit_value(["open_token_error_dialog", true])
                    );
                } else if (data) {
                    dispatch(set_shopedit_value(["show_alert", false]));
                    dispatch(
                        set_shopedit_value([
                            "shop_name",
                            data.shop_data.shop_name,
                        ])
                    );
                    dispatch(
                        set_shopedit_value(["shop_id", data.shop_data.shop_id])
                    );
                    dispatch(
                        set_shopedit_value([
                            "shop_password",
                            data.show_password,
                        ])
                    );
                    dispatch(
                        set_shopedit_value([
                            "recommend_flag",
                            data.shop_data.recommend_flag,
                        ])
                    );
                    dispatch(
                        set_shopedit_value([
                            "special_flag",
                            data.shop_data.special_flag,
                        ])
                    );
                    dispatch(
                        set_shopedit_value([
                            "active_flag",
                            data.shop_data.active_flag,
                        ])
                    );
                    dispatch(
                        set_shopedit_value([
                            "updated_at",
                            data.shop_data.updated_at,
                        ])
                    );
                    dispatch(
                        set_shopedit_value([
                            "shop_user_list",
                            data.shop_user_list,
                        ])
                    );
                }
            })
            .catch((err) => {
                // console.log(err);
                dispatch(set_shopedit_value(["error_title", "エラー"]));
                dispatch(
                    set_shopedit_value([
                        "error_message",
                        "一度前のページに戻り、もう一度この画面を開いてみてください。",
                    ])
                );
                dispatch(set_shopedit_value(["show_alert", true]));
            });
    }
);

export const ChangePass = createAsyncThunk(
    "AdminShopEditing/ChangePass",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        let api_key = thunkAPI.getState().Information.admin.admin_token;

        if (!api_key) api_key = "";
        HttpFunction("PUT", "/api/edit_password_shop", params, api_key)
            .then((data) => {
                // console.log(data);
                if (data.result === "OK") {
                    dispatch(set_shopedit_value(["show_alert", false]));
                    dispatch(
                        set_shopedit_value(["shop_password", data.password])
                    );
                    dispatch(
                        set_shopedit_value(["updated_at", data.updated_at])
                    );
                } else if (
                    data.error_message &&
                    data.error_message.startsWith(
                        "ログイン情報の取得ができませんでした"
                    )
                ) {
                    dispatch(
                        set_shopedit_value(["open_token_error_dialog", true])
                    );
                } else if (data.error_message) {
                    dispatch(set_shopedit_value(["error_title", "エラー"]));
                    dispatch(
                        set_shopedit_value([
                            "error_message",
                            "表示されているデータが古いため更新できませんでした。一度前のページに戻り、もう一度この画面を開いてみてください。",
                        ])
                    );
                    dispatch(set_shopedit_value(["show_alert", true]));
                }
            })
            .catch((err) => {
                // console.log(err);
                dispatch(set_shopedit_value(["error_title", "エラー"]));
                dispatch(
                    set_shopedit_value([
                        "error_message",
                        "時間をおいて、もう一度やり直してください。",
                    ])
                );
                dispatch(set_shopedit_value(["show_alert", true]));
            });
    }
);

export const ChangeStatus = createAsyncThunk(
    "AdminShopEditing/ChangeStatus",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        let api_key = thunkAPI.getState().Information.admin.admin_token;

        if (!api_key) api_key = "";
        HttpFunction("POST", "/api/edit_update_shop", params, api_key)
            .then((data) => {
                // console.log(data);
                if (data.result === "OK") {
                    dispatch(set_shopedit_value(["show_alert", false]));
                    dispatch(
                        set_shopedit_value(["status_undetermined", false])
                    );
                    dispatch(
                        set_shopedit_value(["updated_at", data.updated_at])
                    );
                } else if (
                    data.error_message &&
                    data.error_message.startsWith(
                        "ログイン情報の取得ができませんでした"
                    )
                ) {
                    dispatch(
                        set_shopedit_value(["open_token_error_dialog", true])
                    );
                } else if (data.error_message) {
                    dispatch(set_shopedit_value(["error_title", "エラー"]));
                    dispatch(
                        set_shopedit_value([
                            "error_message",
                            "表示されているデータが古いため更新できませんでした。一度前のページに戻り、もう一度この画面を開いてみてください。",
                        ])
                    );
                    dispatch(set_shopedit_value(["show_alert", true]));
                }
            })
            .catch((err) => {
                // console.log(err);
                dispatch(set_shopedit_value(["error_title", "エラー"]));
                dispatch(
                    set_shopedit_value([
                        "error_message",
                        "時間をおいて、もう一度やり直してください。",
                    ])
                );
                dispatch(set_shopedit_value(["show_alert", true]));
            });
    }
);

export const DeleteShopUser = createAsyncThunk(
    "AdminShopEditing/DeleteShopUser",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        let api_key = thunkAPI.getState().Information.admin.admin_token;

        if (!api_key) api_key = "";
        HttpFunction("DELETE", "/api/delete_shopuser", params, api_key).then(
            (data) => {
                console.log("█████████data████████████████████████████");
                console.log(data);
                dispatch(
                    set_shopedit_value(["shop_user_list", data.shop_user_list])
                );
            }
        );
        // .catch((err) => {
        //     // console.log(err);
        //     dispatch(set_shopedit_value(["error_title", "エラー"]));
        //     dispatch(
        //         set_shopedit_value([
        //             "error_message",
        //             "時間をおいて、もう一度やり直してください。",
        //         ])
        //     );
        //     dispatch(set_shopedit_value(["show_alert", true]));
        // });
    }
);

const isRejectedAction = (action) => {
    return (
        action.type.startsWith("AdminShopEditing") &&
        action.type.endsWith("rejected")
    );
};

// Sliceを生成する
const slice = createSlice({
    name: "AdminShopEditing",
    initialState,
    reducers: {
        set_shopedit_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
        initialize: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addMatcher(isRejectedAction, (state, action) => {
            state["error_title"] = "エラー";
            state["error_message"] =
                "時間をおいて、もう一度やり直してください。";
            state["show_alert"] = true;
        });
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_shopedit_value, initialize } = slice.actions;
