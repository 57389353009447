import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { set_value } from "../../stores/Information";
import { GetShopData } from "../../stores/ShopInformation";
import { UserInfoGet } from "../../stores/Information";
import { Container, makeStyles, Grid } from "@material-ui/core";
import {
    ButtonTextPrimary,
    ButtonContainedPrimary,
    ButtonOutlinedPrimary,
} from "../utils/Buttons";
import HttpFunction from "../utils/HttpFunction";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Error500Handling from "../utils/Error500Handling";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { LinkPanel } from "~CJS/atoms/LinkButton";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import StreetviewIcon from "@material-ui/icons/Streetview";
import { List, ListItem } from "@material-ui/core";
import {
    FiftyCardDisplay,
    FiftyRankDisplay,
    FiftyPintDisplay,
} from "~/modules/FiftyCardDisplay";

const useStyles = makeStyles({
    topButton: {
        textAlign: "right",
        height: "10vh",
        "& .MuiButton-root": {
            margin: "15px 10px",
        },
    },
    logoContainer: {
        height: "60vh",
        display: "flex",
        "& > img": {
            width: "200px",
            margin: "auto",
        },
    },
    buttonContainer: {
        textAlign: "center",
        "& .MuiButton-root": {
            display: "block",
            width: "176px",
            margin: "15px auto",
        },
    },
    stop_dialog: {
        "& .MuiDialog-paper": {
            padding: "15px",
        },
    },
    container: {
        textAlign: "center",
        padding: "10px",
    },
    shop_name: {
        marginBottom: "10px",
    },
});

const MenuUse = () => {
    const history = useHistory();
    const url = new URL(window.location.href);
    // パス設定
    const path = history.location.pathname;
    // ショップID設定
    const shop_id = url.searchParams.get("id");

    // ショップのState設定
    const thisState = useSelector((state) => state.ShopInformation);
    // ユーザーのState設定
    const InformationState = useSelector((state) => state.Information);
    // dispatch設定
    const dispatch = useDispatch();
    // エラーフラグ
    const [hasError, setHasError] = React.useState(false);
    // エラーパターン
    const [errorCase, setErrorCase] = React.useState("");
    // エラーメッセージ
    const [error_message, setErrorMessage] = React.useState("");
    // メッセージ設定
    const [messagetitle, setMessageTitle] = React.useState(null);
    const [message, setMessage] = React.useState(null);
    const [usedYmd, setUsedYmd] = React.useState("");
    const [usedTime, setUsedTime] = React.useState("");
    const [use_created_at, setUse_created_at] = React.useState(false);
    const [modalData, setModalData] = React.useState({
        status: "status",
        title: "",
        message: "",
        usedTime: "",
        message2: "",
    });

    // ダイアログ制御
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const [open500, setOpen500] = React.useState(false);

    const menuUseConfirmation = () => {
        setHasError(false);
        const api_key = InformationState.user.token;
        const params = {
            user_id: InformationState.user.user_id,
            shop_id: shop_id,
        };
        // メニュー利用確認
        // 1．フィフティーカードの課金ユーザーであること
        // 2．当日の来店店舗の利用があるか
        // 3. 当日の来店ポイントの付与が実施されているか確認
        // 未実施の場合は来店ポイントを付与
        HttpFunction("POST", "/api/shop_used", params, api_key)
            .then((data) => {
                switch (data.status) {
                    case "0":
                        setModalData({
                            status: data.status,
                            title: "Thank you！",
                            message: (
                                <>
                                    ご来店
                                    <br />
                                    ありがとうございます
                                </>
                            ),
                            usedTime: data.time,
                            message2: "",
                        });
                        setMessageTitle("Thank you！");
                        setMessage("来店パカッティーを付与しました。");

                        setUsedTime(data.time);
                        break;
                    // 同店で使用済
                    case "1":
                        setModalData({
                            status: data.status,
                            title: "Thank you！",
                            message: (
                                <>
                                    ご来店
                                    <br />
                                    ありがとうございます
                                </>
                            ),
                            usedTime: data.time,
                            message2: "本日分の来店パカッティーは付与されてます。",
                        });
                        break;
                    case "3":
                        // ポイント取得済
                        setModalData({
                            status: data.status,
                            title: "Sorry...",
                            message: (
                                <>
                                    また明日
                                    <br />
                                    使ってね!
                                </>
                            ),
                            usedTime: data.user_use_created_at,
                            message2: "",
                        });
                        break;

                    default:
                        setMessageTitle("エラー");
                        setMessage(data.error_message);
                        setUsedYmd("");
                        setUsedTime("");
                        setErrorCase("");
                        setHasError(true);
                        setErrorMessage(data.error_message);
                        break;
                }
                setOpen(true);
            })
            .catch((err) => {
                setMessageTitle("エラー");
                setMessage("エラーが発生しました。");
                setUsedYmd("");
                setUsedTime("");
                setErrorMessage("時間をおいて、もう一度やり直してください。");
                setErrorCase("");
                setHasError(true);
                setOpen500(true);
            });
    };

    // ダイアログ閉じる処理
    const dialogHandle = () => {
        setOpen(false);
        history.push(`/QRCodeDisplay`);
    };
    const handleClose500 = () => {
        setOpen500(false);
    };

    useEffect(() => {
        const api_key = InformationState.user.token;
        dispatch(UserInfoGet(api_key));

        if (shop_id) {
            dispatch(GetShopData(shop_id));
        } else {
            console.log("shopid none");
        }
    }, []);

    useEffect(() => {
        if (!shop_id) {
            setHasError(true);
            setErrorCase("1"); //shop_idなし
            setErrorMessage(
                "店舗IDが見つかりません。再度QRコードを読み取ってください。"
            );
        } else if (thisState.shop_data.shop_name) {
            // 店舗情報取得完了
            // console.log(thisState.shop_data.shop_name);
            setHasError(false);
            setErrorCase(""); //errorCase空に設定
            setErrorMessage(""); //エラーメッセージ空に設定
        } else {
            setHasError(true);
            setErrorCase("2"); //店舗情報取得失
            setErrorMessage(
                "店舗情報の取得に失敗しました。再度QRコードを読み取ってください。"
            );
        }
    }, [thisState.shop_data]);

    return (
        <React.Fragment>
            <Container>
                {hasError == true ? (
                    <Alert severity="error">
                        <AlertTitle>エラー</AlertTitle>
                        {error_message}
                    </Alert>
                ) : (
                    ""
                )}
                <Grid>
                    {/* <h1>店舗利用確認</h1>
                    <Description
                        errorCase={errorCase}
                        shop_data={thisState.shop_data}
                    /> */}

                    <Grid>
                        <Typography
                            noWrap
                            style={{ fontSize: "1.5rem", marginBotoom: "0" }}
                            variant="h1"
                            align="left"
                        >
                            {InformationState.user.nickname}
                        </Typography>

                        <FiftyCardDisplay
                            rank_type={InformationState.user.rank_type}
                        />
                        {/* <FiftyRankDisplay
                            rank_color_cd={InformationState.user.rank_color_cd}
                            rank_color_name={
                                InformationState.user.rank_color_name
                            }
                        /> */}
                        <FiftyPintDisplay
                            point_sum={InformationState.user.point_sum}
                        />
                        <Typography style={{ fontSize: "1.5rem" }} align="left">
                            注意事項
                        </Typography>
                        <List>
                            <ListItem>来店時に必ず提⽰して下さい。</ListItem>
                            <ListItem>
                                同じ店舗では 1⽇1 回の使⽤と なります。
                            </ListItem>
                        </List>
                        <Typography
                            style={{ fontSize: "1rem", color: "RED" }}
                            align="left"
                        >
                            ※ここから下はスタッフに操作し てもらって下さい。
                        </Typography>

                        <Grid
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "20px",
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={menuUseConfirmation}
                                style={{
                                    width: "90%",
                                    margin: "1%",
                                    height: "4rem",
                                }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ fontSize: "1.4rem" }}
                                >
                                    {"スタッフがタップ"}
                                    {/* {
                                        <HowToRegIcon
                                            style={{ fontSize: "80px" }}
                                        />
                                    } */}
                                </Grid>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                open={open}
                onClose={dialogHandle}
                className={classes.stop_dialog}
            >
                <DialogTitle disableTypography>
                    <Typography
                        variant="h1"
                        style={{
                            fontSize: "2rem",
                            color: modalData.status == "3" ? "RED" : "black",
                            margin: "0px",
                            padding: "0px",
                        }}
                        align="center"
                    >
                        {modalData.title}
                    </Typography>
                </DialogTitle>
                <Grid
                    container
                    style={{ textAligin: "center" }}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid container className={classes.container}>
                        <Grid item xs={12} className={classes.shop_name}>
                            <Typography
                                style={{
                                    fontSize: "1.5rem",
                                }}
                                align="center"
                            >
                                {modalData.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.shop_name}>
                            {modalData.usedTime && (
                                <Typography align="center">
                                    {modalData.usedTime}利用
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                align="center"
                                style={{
                                    fontSize: "0.5rem",
                                }}
                            >
                                {modalData.message2}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <img
                            src="/images/Common/キャラ入りロゴ.png"
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <ButtonOutlinedPrimary fullWidth onClick={dialogHandle}>
                            確認
                        </ButtonOutlinedPrimary>
                    </Grid>
                </Grid>
            </Dialog>
            <Error500Handling open={open500} onClose={handleClose500} />
        </React.Fragment>
    );
};

const Description = (props) => {
    const { errorCase, shop_data } = props;
    switch (errorCase) {
        case "1":
            return (
                <Grid>
                    <h2>店舗情報取得に失敗</h2>
                    <ul>
                        <li>
                            ネットワークをご確認の上再度QRコードを読み取りをお試しください
                        </li>
                        <li>
                            何度も取得に失敗する場合は店舗またはフィフティーカード管理者へお問い合わせください。
                        </li>
                    </ul>
                </Grid>
            );

        case "2":
            return (
                <Grid>
                    <h2>店舗情報取得に失敗</h2>
                    <ul>
                        <li>
                            ご確認の上再度QRコードを読み取りをお試しください。
                        </li>
                        <li>
                            何度も取得に失敗する場合は店舗またはフィフティーカード管理者へお問い合わせください。
                        </li>
                    </ul>
                </Grid>
            );

        default:
            return (
                <Grid>
                    <b>{shop_data.shop_name}</b>
                    でフィフティーメニューをご利用になる前に「利用前にご確認ください」を必ずご確認の上で「承認する」ボタンをタップしてください。
                    <h2>利用前にご確認ください</h2>
                    <ul>
                        <li>
                            フィフティーメニューは1店舗につき、1日1回までご利用いただけます。
                        </li>
                        <li>
                            「承認する」ボタンを店員がタップし後に表示される画面をに見せる必要があります。見せなかった場合はメニューの利用ができない場合があります。
                        </li>
                        <li>
                            来店パカッティーは1日1回（1日に何店舗利用されても1回）のみ来店パカッティーは付与されます。
                        </li>
                    </ul>
                </Grid>
            );
    }
};
export default MenuUse;
