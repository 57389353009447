import React from "react";
import Form from "react-bootstrap/Form";
import TextField from "@material-ui/core/TextField";
const SelectTarget = (props) => {
    const { view, value, ...other } = props;

    if (view) {
        switch (value) {
            case "1":
                return "男女共通";
            case "2":
                return "女性限定";
            case "3":
                return "男性限定";
            case "4":
                return "知り合いのみ男性可能";
            case "5":
                return "知り合いのみ女性可能";
            default:
                return "男女共通";
        }
    }
    return (
        <>
            <TextField
                style={{ width: "100%" }}
                select
                name="target"
                label="対象"
                fullWidth
                variant="outlined"
                value={value}
                SelectProps={{
                    native: true,
                }}
                {...other}
            >
                <option value={1}>男女共通</option>
                <option value={2}>女性限定</option>
                <option value={3}>男性限定</option>
                <option value={4}>知り合いのみ男性可能</option>
                <option value={5}>知り合いのみ女性可能</option>
            </TextField>
        </>
    );
};

export default SelectTarget;
