import { createSlice } from "@reduxjs/toolkit";

const shop_comment = 
`大阪を中心にキッチンカーを運営しています。<br>
お客様の笑顔とキッチンカー仲間の笑顔が生きがいです!<br>
イベントやデリバリーのご依頼あればお気軽にお問合せください。<br>
亀のマークが目印のキッチンカーです。<br>
名物は溶岩焼きバーガモチコチキン。`;


const shop_menu = 
`<h4>健康モチコチキン</h4>
S 300円→150円<br>
M 500円→250円<br>
L 1000円→500円`;

// Stateの初期状態
const initialState = {
    shop_name: "test",
    shop_comment: shop_comment,
    shop_menu:shop_menu,
};
// Sliceを生成する
const slice = createSlice({
    name: "AdminStoreApplication",
    initialState,
    reducers: {
        set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
        setName: (state, action) => {
            return Object.assign({}, state, { name: action.payload });
        },
        clearName: (state) => {
            return Object.assign({}, state, { name: "" });
        },
        // etc...
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { setName, set_value } = slice.actions;
