import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Input, Button, Grid, Container, TextField } from "@material-ui/core/";
import ImageResizer from "../utils/ImageResizer";
import { HideFooter, ShowFooter } from "../modules/BottomNavigation";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    input_file: {
        opacity: "0",
        appearance: "none",
        position: "absolute",
    },
}));

// comment true,false コメント入力するか
const ImageInput = (props) => {
    const classes = useStyles();
    const initialState = { image: false, comment: "", image_big: false };

    const [image, setImage] = useState(initialState.image);
    const [image_big, setImageBig] = useState(initialState.image_big);
    const [comment, setComment] = useState(initialState.comment);

    const SetImages = async (e) => {
        const file = e.target.files[0];
        try {
            const url = await ImageResizer(file, props);
            setImage(url);

            if (props && props.image_big) {
                const option = { height: props.heightBig, width: props.widthBig };
                const big_url = await ImageResizer(file, option);
                setImageBig(big_url);
            }
            return image;
        } catch (err) {
            // console.log(err);
        }
    };

    const DelImages = () => {
        setImage(false);
        setComment("");
    };
    const SetComment = (e) => {
        setComment(e.target.value);
    };

    const Decision = () => {
        props.onClick({
            comment: comment,
            image: image,
            image_big: image_big,
        });
        DelImages();
    };

    const FileInputButton = () => {
        return (
            <React.Fragment>
                <Button
                    key="fileinput"
                    component="label"
                    variant="contained"
                    style={{ width: "90%", margin: "5%" }}
                    color="primary"
                >
                    ファイルを選択する
                    <Input
                        className={classes.input_file}
                        type="file"
                        inputProps={{
                            accept: ".jpg,.gif,.png,image/gif,image/jpeg,image/png",
                        }}
                        name="file_input"
                        onChange={SetImages}
                        color="primary"
                    ></Input>
                </Button>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {image ? (
                <Container
                    style={{
                        width: "96%",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",

                        borderRadius: "1rem",
                        boxShadow: "0 2px 5px 0 rgba(0, 0, 0, .5)",
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button
                            key="fileinput"
                            component="label"
                            style={{ width: "90%", margin: "5%" }}
                        >
                            <img
                                style={{ height: "100%", maxWidth: "100%" }}
                                src={image}
                            />
                            <Input
                                className={classes.input_file}
                                type="file"
                                name="file_input"
                                onChange={SetImages}
                                color="primary"
                            ></Input>
                        </Button>
                    </Grid>
                    {props.comment && (
                        <TextField
                            type="text"
                            value={comment}
                            name="text_input"
                            style={{
                                width: "100%",
                                marginTop: "1rem",
                                marginBottom: "1rem",
                            }}
                            inputProps={{ maxLength: 255 }}
                            onFocus={HideFooter}
                            onBlur={ShowFooter}
                            onChange={SetComment}
                            variant="outlined"
                            label="画像説明"
                            multiline
                        ></TextField>
                    )}

                    <Button
                        id="cansel"
                        component="label"
                        variant="outlined"
                        style={{ width: "50%" }}
                        onClick={DelImages}
                    >
                        キャンセル
                    </Button>
                    <Button
                        component="label"
                        variant="contained"
                        style={{ width: "50%" }}
                        onClick={Decision}
                        color="primary"
                    >
                        決定
                    </Button>
                </Container>
            ) : (
                <FileInputButton />
            )}
        </React.Fragment>
    );
};

export default ImageInput;
