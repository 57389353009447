import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { GetTagList, TagListSet } from "../../stores/TagList";

const useStyles = makeStyles((theme) => ({
    root: {
        height: 300,
        flexGrow: 1,
        minWidth: 300,
    },
    modal: {
        display: "flex",
        padding: theme.spacing(1),
        alignItems: "center",
        justifyContent: "center",
    },
}));
const box_style = {
    borderRadius: "2rem",
    position: "absolute",
    top: "50%",
    left: "50%",
    height: "80%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    overflowY: "scroll",
    padding: "0.5rem",
    boxShadow: "0 0 10px 5px burlywood",
};
const TagList = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.Shoplist);
    const tagListState = useSelector((state) => state.TagList);

    useEffect(() => {
        dispatch(GetTagList());
    }, []);

    return (
        <React.Fragment>
            <Modal
                className={classes.modal}
                open={props.open}
                onClose={props.TagListClick}
            >
                <Box sx={{ ...box_style }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                    >
                        <Button onClick={props.TagListClick}>
                            閉じる
                            <CloseIcon />
                        </Button>
                    </Grid>
                    <h1>タグ一覧</h1>
                    <Grid>
                        {Object.keys(tagListState.tag_list).map(
                            (key, outindex) => (
                                <div key={outindex + "_tagout"}>
                                    <h2>{key}</h2>
                                    {tagListState.tag_list[key].map(
                                        (value, index) => (
                                            <Chip
                                                key={index + "_taglist"}
                                                label={value.tag_name}
                                                name={value.tag_id}
                                                onClick={() =>
                                                    props.TagClick(
                                                        value.tag_name
                                                    )
                                                }
                                                color={
                                                    thisState.tag_list[
                                                        value.tag_name
                                                    ]
                                                        ? "primary"
                                                        : "default"
                                                }
                                            />
                                        )
                                    )}
                                </div>
                            )
                        )}
                    </Grid>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default TagList;
