import React, { useState, useEffect } from "react";

import ShopOverview from "../modules/ShopOverview";
import ShoplistSort from "../modules/ShoplistSort";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
import PinDropIcon from "@material-ui/icons/PinDrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { shoplist_set } from "../../stores/Shoplist";
import { shop_data } from "../../const/shop_data";
import { useHistory } from "react-router";

const Shoplist = () => {
    const thisState = useSelector((state) => state.Shoplist);
    const dispatch = useDispatch();
    const information_state = useSelector((state) => state.Information);
    const api_key = information_state.user.token;
    const history = useHistory();

    const [view_sort, setViewSort] = React.useState(true);

    const SerchStert = (arg) => {
        const page = arg && arg.page ? arg.page : 1;

        let page_num = 1;
        if (page) {
            page_num = page;
        }

        dispatch(shoplist_set(["serch_start", true]));

        // if (!thisState.serch_text.trim()) {
        //     const error_message = "検索条件を設定してください";
        //     dispatch(shoplist_set(["error_message", error_message]));
        //     dispatch(shoplist_set(["serch_start", false]));
        //     window.scroll({ top: 0, behavior: "smooth" });
        //     return;
        // } else {
        dispatch(shoplist_set(["error_message", false]));
        // }

        let csrf_token = document.head.querySelector(
            'meta[name="csrf-token"]'
        ).content;

        const genre_filter_val = Object.keys(thisState.genre_filter)
            .map((val, i) => {
                if (thisState.genre_filter[val]) {
                    return val;
                }

                return null;
            })
            .filter((v) => v);

        async function GetShopList() {
            // // console.log({
            //     エリア: thisState.serch_area,
            //     検索ワード: thisState.serch_text,
            //     オーダー: thisState.order,
            //     ソート: thisState.sort,
            //     リミット: thisState.limit,
            //     ページ: page_num,
            // });

            const query_params =
                "search_data" +
                "[" +
                "area" +
                "]" +
                "=" +
                encodeURIComponent(thisState.serch_area) +
                "&" +
                "search_data" +
                "[" +
                "word" +
                "]" +
                "=" +
                encodeURIComponent(thisState.serch_text) +
                "&" +
                "search_data" +
                "[" +
                "serch_city" +
                "]" +
                "=" +
                encodeURIComponent(thisState.serch_city) +
                "&" +
                "search_data" +
                "[" +
                "serch_station_name" +
                "]" +
                "=" +
                encodeURIComponent(thisState.serch_station_name) +
                "&" +
                "search_data" +
                "[" +
                "price_range" +
                "]" +
                "=" +
                encodeURIComponent(thisState.price_range) +
                "&" +
                "order" +
                "=" +
                encodeURIComponent(thisState.order) +
                "&" +
                "sort" +
                "=" +
                encodeURIComponent(thisState.sort) +
                "&" +
                "genre_filter" +
                "=" +
                encodeURIComponent(genre_filter_val) +
                "&" +
                "limit" +
                "=" +
                encodeURIComponent(thisState.limit) +
                "&" +
                "page" +
                "=" +
                page_num;

            const res = await fetch("/api/shop_search" + "?" + query_params, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": csrf_token,
                    "API-KEY": api_key == undefined ? "" : api_key,
                },
            });
            const data = await res.json();

            return data;
        }

        GetShopList()
            .then((data) => {
                dispatch(
                    shoplist_set(["current_page", data.data?.current_page || 1])
                );
                dispatch(
                    shoplist_set(["shop_list_data", data.data?.data || ""])
                );
                dispatch(
                    shoplist_set(["total_page", data.data?.last_page || ""])
                );
                dispatch(shoplist_set(["total_count", data.data?.total || 0]));
                setViewSort(false);
            })
            .catch((err) => {
                // console.log(err);
            });

        dispatch(shoplist_set(["serch_start", false]));
        dispatch(shoplist_set(["serch_flag", true]));
    };

    const send_data = thisState.serch_text;

    const ChangePages = (event, value) => {
        SerchStert({ page: value });
        document.getElementById("shop_total_count").scrollIntoView(true);
        window.scrollBy(0, -56);
    };

    const Onfavorite = (shop_id) => {
        const new_shop_list_data = thisState.shop_list_data.map(
            (shop, index) => {
                if (shop.shop_id == shop_id) {
                    const new_shop = {
                        ...shop,
                        favorite: shop.favorite == 1 ? 0 : 1,
                    };
                    return new_shop;
                } else {
                    return shop;
                }
            }
        );

        dispatch(shoplist_set(["shop_list_data", new_shop_list_data]));
    };

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                    maxWidth: "600px",
                    margin: "auto",
                    overflow: "hidden",
                }}
            >
                <h1>店舗検索</h1>
                {view_sort ? (
                    <ShoplistSort SerchStert={SerchStert} />
                ) : (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Button
                            variant="contained"
                            onClick={() => setViewSort(true)}
                        >
                            戻る
                        </Button>
                    </Grid>
                )}

                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-end"
                >
                    {thisState.shop_list_data?.length ? (
                        <Box component="span" m={1}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => history.push("/Map")}
                            >
                                <PinDropIcon />
                                MAPで表示
                            </Button>
                        </Box>
                    ) : (
                        ""
                    )}
                </Grid>
                {thisState.serch_flag ? (
                    thisState.serch_start ? (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CircularProgress color="secondary" />
                        </Grid>
                    ) : (
                        <React.Fragment>
                            {thisState.shop_list_data ? (
                                <React.Fragment>
                                    <div id="shop_total_count">
                                        {thisState.total_count}件該当しました。
                                    </div>
                                    {thisState.shop_list_data.map(
                                        (shop, index) => (
                                            <React.Fragment key={index}>
                                                <ShopOverview
                                                    Onfavorite={Onfavorite}
                                                    shop_data={shop}
                                                />
                                            </React.Fragment>
                                        )
                                    )}

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{
                                            marginTop: "1rem",
                                        }}
                                    >
                                        <Pagination
                                            count={Number(thisState.total_page)}
                                            variant="outlined"
                                            page={thisState.current_page}
                                            color="secondary"
                                            onChange={ChangePages}
                                        />
                                    </Grid>
                                </React.Fragment>
                            ) : (
                                <div id="shop_total_count">
                                    検索条件に一致するデータが存在しません。
                                </div>
                            )}
                        </React.Fragment>
                    )
                ) : (
                    ""
                )}
            </Grid>
        </React.Fragment>
    );
};

export default Shoplist;
