import React from "react";
import { Slide } from "react-slideshow-image";

const ShopImageSlide = (props) => {
    const { image_list } = props;

    const filterd_image_list = image_list.filter((v) => !!v);
    return (
        <>
            <div
                style={{
                    width: "100%",
                    display: "block",
                }}
            >
                {filterd_image_list.length != 0 && (
                    <>
                        <Slide
                            arrows={false}
                            indicators
                            transitionDuration={500}
                        >
                            {filterd_image_list.map((item, index) => (
                                <React.Fragment key={index}>
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "block",
                                            // border: "solid 1px gray",
                                            // borderRadius:
                                            //     "0rem 0rem 1rem 1rem",
                                            textAlign: "center",
                                        }}
                                    >
                                        {/* <JudgmentLink link={item.link_url}> */}
                                        <img
                                            style={{
                                                objectFit: "contain",
                                                //width: "100%",
                                                // height: "100%",
                                                //maxHeight: "250px",
                                                objectFit: "cover",
                                                width: "auto",
                                                height: "250px",
                                            }}
                                            src={item}
                                            // src={"/images/top/500x140.png"}
                                            // alt={item.image_comment}
                                            onError={(e) => {
                                                e.target.onerror = "null";
                                                e.target.src =
                                                    "/images/no_img.png";
                                            }}
                                        />
                                        {/* </JudgmentLink> */}
                                    </div>
                                </React.Fragment>
                            ))}
                        </Slide>
                    </>
                )}
            </div>
        </>
    );
};

export default ShopImageSlide;
