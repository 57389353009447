import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Stateの初期状態
const initialState = {
    user: {
        email_address: "",
    },
    shop: {
        shop_email_address: "",
    },
    admin: {
        email_address: "",
    },
    login_mode: "", // user/shop/admin
    email_flag: true,
    onetime_flag: false,
    password_flag: false,
    error_message: "",
};

export const NoOntimeShopLogin = createAsyncThunk(
    "ログイン/ワンタイムなし店舗ログイン",
    async (arg, thunkAPI) => {
        const params = arg;
        const url = "/api/no_onetime_shop_login";

        const response = await axios.post(url, params, {
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector(
                    'meta[name="csrf-token"]'
                ).content,
            },
        });

        return response.data;
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "Login",
    initialState,
    reducers: {
        login_set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
        login_user_set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];

            state[name] = value;
            let new_type = state.user;
            new_type[name] = value;
            state.user = new_type;
        },
        login_shop_set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state.shop[name] = value;
        },
        login_admin_set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state.admin[name] = value;
        },
        initialize: () => initialState,
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {
    login_set_value,
    login_user_set_value,
    login_shop_set_value,
    login_admin_set_value,
    initialize,
} = slice.actions;
