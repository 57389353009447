import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";

import { ButtonNavi } from "~CJS/block/ButtonNavi";
import {
    initialize,
    GetAdminUserList,
    CreateAdminUser,
    DeleteAdminUser,
} from "~stores/AdminUser";

import { useDispatch, useSelector } from "react-redux";

const AdminUserList = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.AdminUser);
    useEffect(() => {
        dispatch(GetAdminUserList());
    }, []);

    const handleDelete = (user_id) => {
        dispatch(DeleteAdminUser({ admin_id: user_id })).then((data) => {
            dispatch(GetAdminUserList());
        });
    };

    const HandleSubmit = (e) => {
        e.preventDefault();

        const elem = e.target?.elements;

        const params = {
            email: elem.email_address.value,
            nickname: elem.nickname.value,
            password: elem.password.value,
        };

        dispatch(CreateAdminUser(params)).then((data) => {
            if (data.payload.result == 1) {
                alert("ユーザーを作成しました。");
                dispatch(GetAdminUserList());
            } else if (data?.payload?.errer_message) {
                alert(data.payload.errer_message);
            } else {
                alert("ユーザーに失敗しました。");
            }
        });
    };
    return (
        <React.Fragment>
            <Container>
                <ButtonNavi left_label="戻る" left_link={`/AdminHome`} />
                <Grid
                    container
                    style={{
                        marginBottom: "2rem",
                        border: "solid 0.1rem pink",
                        padding: "1rem",
                  
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            メールアドレス
                        </Grid>
                        <Grid item xs={3}>
                            名前
                        </Grid>
                        <Grid item xs={3}>
                            パスワード
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                    <form onSubmit={HandleSubmit} style={{ width: "inherit" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <TextField
                                    name="email_address"
                                    fullWidth
                                    label=""
                                    size="small"
                                    // inputProps={{ maxLength: 4, pattern: "^[a-zA-Z0-9_]+$" }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    name="nickname"
                                    fullWidth
                                    label=""
                                    size="small"
                                    // inputProps={{ maxLength: 4, pattern: "^[a-zA-Z0-9_]+$" }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    name="password"
                                    label=""
                                    fullWidth
                                    size="small"
                                    type="password"
                                    // inputProps={{ maxLength: 4, pattern: "^[a-zA-Z0-9_]+$" }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{
                                        marginRight: "auto",
                                        display: "block",
                                        marginLeft: "auto",
                                    }}
                                    type="submit"
                                >
                                    作成
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={2}>
                            ID
                        </Grid>
                        <Grid item xs={3}>
                            名前
                        </Grid>
                        <Grid item xs={5}>
                            メールアドレス
                        </Grid>
                    </Grid>
                    {thisState.admin_user_list.map((value, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Grid container spacing={3}>
                                    <Grid item xs={2}>
                                        {value.user_id}
                                    </Grid>
                                    <Grid item xs={3}>
                                        {value.nickname}
                                    </Grid>
                                    <Grid item xs={5}>
                                        {value.email_address}
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() =>
                                                handleDelete(value.user_id)
                                            }
                                        >
                                            削除
                                        </Button>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        );
                    })}
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default AdminUserList;
