import React from "react";
import TagList from "../modules/TagList";
import { Button, Chip, TextField, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { set_alert } from "../../stores/ErrorAlert";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}));

const ErrorAlert = (props) => {
    const thisState = useSelector((state) => state.ErrorAlert);
    const dispatch = useDispatch();
    const classes = useStyles();
    const severity = thisState.severity ? thisState.severity : "error";

    return (
        <>
            {thisState.title ? (
                <div className={classes.root}>
                    <Alert
                        severity={severity}
                        onClose={() => {
                            dispatch(set_alert(["", ""]));
                        }}
                    >
                        <AlertTitle>{thisState.title}</AlertTitle>
                        {thisState.message}
                    </Alert>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default ErrorAlert;
