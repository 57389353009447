import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";
import { set_table_value } from "./HistoryTable";

// Stateの初期状態
const initialState = {
    member_list: [],
    // ---検索条件---
    user_id: "",
    email_address: "",
    prefectures: "北海道",
    all_prefectures: true,
    user_rank_type: "1",
    all_ranks: true,
    user_type: "1",
    order: "created_at",
    sort: "desc",
    talent_flag: false,
    // -----------
    open_search_dialog: false,
    search_triger: 0,
    open_token_error_dialog: false,
    show_alert: false,
    // ---会員編集-----
    open_editing_dialog: false,
    edited_user_id: "",
    edited_nickname: "",
    edited_email: "",
    edited_rank: "",
    edited_point: "",
    edited_active_flag: 0,
    isMinusSign: false,
    adjusted_point: "",
    success: false,
    // ---会員数--------------
    total_user: 0,
    // -----------------
};

export const GetMemberList = createAsyncThunk(
    "AdminMemberList/GetMemberList",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const thisState = thunkAPI.getState().AdminMemberList;
        const url =
            thisState.user_type === "1"
                ? "/api/show_general_users"
                : "/api/show_delete_users";
        let api_key = thunkAPI.getState().Information.admin.admin_token;

        if (!api_key) api_key = "";
        HttpFunction("GET", url, params, api_key)
            .then((data) => {
                // console.dir(data.data);
                if (
                    data.error_message &&
                    data.error_message.startsWith(
                        "ログイン情報の取得ができませんでした"
                    )
                ) {
                    dispatch(
                        set_memberlist_value(["open_token_error_dialog", true])
                    );
                } else if (data) {
                    dispatch(set_memberlist_value(["total_user", data.total]));

                    dispatch(set_memberlist_value(["show_alert", false]));
                    dispatch(set_memberlist_value(["member_list", data.data]));
                    dispatch(set_table_value(["total_rows", data.total]));
                    dispatch(set_table_value(["per_page", data.per_page]));
                }
            })
            .catch((err) => {
                // console.log(err);
                dispatch(set_memberlist_value(["error_title", "エラー"]));
                dispatch(
                    set_memberlist_value([
                        "error_message",
                        "一度前のページに戻りもう一度この画面を開くか、検索条件設定ダイアログの検索ボタンをもう一度押してみてください。",
                    ])
                );
                dispatch(set_memberlist_value(["show_alert", true]));
            });
    }
);

export const changePoint = createAsyncThunk(
    "AdminMemberList/changePoint",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const thisState = thunkAPI.getState().AdminMemberList;
        let api_key = thunkAPI.getState().Information.admin.admin_token;

        if (!api_key) api_key = "";
        HttpFunction("POST", "/api/point_adjust", params, api_key)
            .then((data) => {
                // console.log(data);
                if (data.status === 0) {
                    dispatch(set_memberlist_value(["show_alert", false]));
                    dispatch(set_memberlist_value(["success", true]));
                    dispatch(
                        set_memberlist_value([
                            "edited_point",
                            thisState.edited_point + params.point,
                        ])
                    );
                    dispatch(
                        set_memberlist_value([
                            "search_triger",
                            thisState.search_triger + 1,
                        ])
                    );
                } else if (
                    data.error_message &&
                    data.error_message.startsWith(
                        "ログイン情報の取得ができませんでした"
                    )
                ) {
                    dispatch(
                        set_memberlist_value(["open_token_error_dialog", true])
                    );
                } else if (data.error_message) {
                    dispatch(set_memberlist_value(["error_title", "エラー"]));
                    dispatch(
                        set_memberlist_value([
                            "error_message",
                            data.error_message,
                        ])
                    );
                    dispatch(set_memberlist_value(["show_alert", true]));
                }
            })
            .catch((err) => {
                // console.log(err);
                dispatch(set_memberlist_value(["error_title", "エラー"]));
                dispatch(
                    set_memberlist_value([
                        "error_message",
                        "時間をおいて、もう一度やり直してください。",
                    ])
                );
                dispatch(set_memberlist_value(["show_alert", true]));
            });
    }
);

export const ChangeActive = createAsyncThunk(
    "管理/会員詳細/無効化",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/change_active";

        try {
            const response = await axios.post(url, arg, {
                headers: {
                    "API-KEY": api_key,
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const ChangeEmail = createAsyncThunk(
    "管理/一般会員のメールアドレス変更",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/admin_change_email";

        try {
            const response = await axios.post(url, arg, {
                headers: {
                    "API-KEY": api_key,
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

const isRejectedAction = (action) => {
    return (
        action.type.startsWith("AdminMemberList") &&
        action.type.endsWith("rejected")
    );
};

// Sliceを生成する
const slice = createSlice({
    name: "AdminMemberList",
    initialState,
    reducers: {
        set_memberlist_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];
            if (type) {
                state[type][name] = value;
            } else {
                state[name] = value;
            }
        },
        initialize: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addMatcher(isRejectedAction, (state, action) => {
            state["error_title"] = "エラー";
            state["error_message"] =
                "時間をおいて、もう一度やり直してください。";
            state["show_alert"] = true;
        });
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_memberlist_value, initialize } = slice.actions;
