import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";

// Stateの初期状態
const initialState = {
    error_message: "",
    fifty_girl_list: [],
    fixed_list: [],
    popular_list: [],
    recommend_list: [],
    slide_data: [],
    talent_list: [],
    new_shop_list: [],
    near_shop_list: [],
    prefectures: "",
    news_list: [],
    use_history_list: [],
};

export const GetTopdata = createAsyncThunk(
    "Top/GetTopdata",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;

        let api_key = informationState.user.token;
        if (!api_key) api_key = "";

        const params = { ...arg };
        const url = "/api/general_home";

        const response = await axios.get(
            url + "?" + new URLSearchParams(params),
            {
                headers: {
                    "API-KEY": api_key,
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            }
        );
        return response.data;
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "Top",
    initialState,
    reducers: {
        TopSet: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        [GetTopdata.pending]: (state, action) => {},
        [GetTopdata.fulfilled]: (state, action) => {
            const data = action.payload.data;
            if (action.payload.error_message) {
                state.error_message = [
                    "サーバーエラー",
                    action.payload.error_message,
                ];
            }
            if (!data) {
                // console.log("dataがありません");
                return;
            }
            if (data && data.recommend) {
                state.recommend_list = data.recommend;
            } else {
                // console.log("おすすめリストがありません");
            }
            if (data && data.fifty_girl) {
                state.fifty_girl_list = data.fifty_girl;
            } else {
                // console.log("フィフティーガールがありません");
            }
            if (data && data.fixed) {
                state.fixed_list = data.fixed;
            } else {
                // console.log("スライダー通知がありません");
            }
            if (data && data.popular) {
                state.popular_list = data.popular;
            } else {
                // console.log("人気店舗リストがありません");
            }
            if (data && data.new_shop_list) {
                state.new_shop_list = data.new_shop_list;
            }
            if (data && data.near_shop_list) {
                state.near_shop_list = data.near_shop_list;
            }
            // if (data && data.fixed && data.fifty_girl) {
            //     state.slide_data = data.fixed.concat(data.fifty_girl);
            // } else {
            //     // console.log("スライダーリストを作成できませんでした。");
            // }
            state.slide_data = data.fixed;
            if (data && data.talent_list) {
                state.talent_list = data.talent_list;
            } else {
                // console.log("talent_listがありません");
            }

            if (data && data.prefectures) {
                state.prefectures = data.prefectures;
            }
            if (data && data.news_list) {
                state.news_list = data.news_list;
            }
            if (data && data.use_history_list) {
                state.use_history_list = data.use_history_list;
            }
        },
        [GetTopdata.rejected]: (state, action) => {
            state.error_message = [
                "ネットワークエラー",
                "TOP画面の取得に失敗しました",
            ];
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { TopSet } = slice.actions;
