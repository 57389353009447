import React, { useState, useEffect } from "react";

import ButtonGeneral from "../utils/ButtonGeneral";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import StoreApplicationEdit from "../modules/StoreApplicationEdit";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import {
    AdminNewShopInformationApplication,
    release_info_set,
} from "../../stores/ShopStoreApplication";
import ShopStoreApplicationNullCheck from "../utils/ShopStoreApplicationNullCheck";
import ShopStoreApplicationHoursCheck from "../utils/ShopStoreApplicationHoursCheck";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const AdminStoreApplicationNew = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const thisState = useSelector((state) => state.Entry);
    const classes = useStyles();
    const releaseInfoState = useSelector((state) => state.ShopStoreApplication);
    const SetErrer = (message) => {
        dispatch(release_info_set(["error_message", message]));
    };

    const path = history.location.pathname.split("/");
    const shop_id = path[2];

    useEffect(() => {
        dispatch(release_info_set(["shop_id", shop_id]));
        dispatch(release_info_set(["remarks", "正確な営業日、営業時間などはお店へ直接ご確認ください。"])); //備考初期値
    }, []);

    const OnApplication = () => {
        const input_list = {
            shop_name: "店舗名",
            shop_explain: "店舗説明",
            store_pr: "サブタイトル",
            shop_menu: "fiftyメニュー",
            shop_city: "市区町村",
            shop_address1: "町名・番地",
            access: "アクセス",
            business_hours: "営業時間",
            holiday: "定休日",
            shop_phone_number: "電話番号",
            shop_staff: "担当者",
            latitude: "緯度",
            longitude: "経度",
        };

        const null_list = ShopStoreApplicationNullCheck(input_list, releaseInfoState);
        if (null_list.length > 0) {
            SetErrer(["エラー", null_list + "は必須項目です", "warning"]);
            return;
        }

        if ( !releaseInfoState.sns  && !releaseInfoState.url ){
            SetErrer(["エラー", "SNSもしくはURLの入力は必須です", "warning"]);
            return;
        }

        //営業時間チェック
        const hours_check = ShopStoreApplicationHoursCheck(releaseInfoState);
        if(hours_check != null)
        {
            SetErrer(hours_check);
            return;
        }

        // console.log("変更申請");
        //申請処理

        dispatch(AdminNewShopInformationApplication());
    };
    const Back = () => {
        if (releaseInfoState.apply_id) {
            history.push(
                "/AdminStoreApplication/" +
                    releaseInfoState.shop_id +
                    "/" +
                    releaseInfoState.apply_id
            );
        } else {
            history.goBack();
        }
    };

    return (
        <React.Fragment>
            <IconButton onClick={Back} style={{ padding: "8px 0 24px" }}>
                <ChevronLeftIcon />
                <span style={{ fontSize: "70%" }}>戻る</span>
            </IconButton>
            <div style={{ margin: "0.5rem" }} className={classes.root}>
                <h1>新規申請</h1>
                <StoreApplicationEdit
                    admin
                    releaseInfoState={releaseInfoState}
                />
                <ButtonGeneral onClick={OnApplication}>申請</ButtonGeneral>
            </div>
        </React.Fragment>
    );
};

export default AdminStoreApplicationNew;
