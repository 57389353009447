export const GetNow = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = ("0" + (now.getMonth() + 1)).slice(-2);
    const date = ("0" + now.getDate()).slice(-2);
    const hour = ("0" + now.getHours()).slice(-2);
    const min = ("0" + now.getMinutes()).slice(-2);
    const sec = ("0" + now.getSeconds()).slice(-2);

    return {
        now: now,
        year: year,
        month: month,
        date: date,
        hour: hour,
        min: min,
        sec: sec,
    };
};
