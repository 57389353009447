import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/functions/AsyncFunction";

const initialState = {
    point_application_count: 0, //未出荷数
};

export const GetAdminHome = (arg) => {
    return AsyncFunction({
        type_name: "AdminHome/管理者ホーム情報の取得",
        method: "get",
        url: "/api/GetAdminHome",
        params: arg,
        api_key: "admin",
    });
};
const slice = createSlice({
    name: "AdminHome",
    initialState,
    reducers: {
        AdminHomeSet: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) =>
                action.type.match("AdminHome/") &&
                action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;
                if (
                    payload?.point_application_count === 0 ||
                    payload?.point_application_count
                ) {
                    state.point_application_count =
                        payload?.point_application_count;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { AdminHomeSet } = slice.actions;
