import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";
import { set_table_value } from "./HistoryTable";
import { set_value as set_information_value } from "./Information";

// Stateの初期状態
const initialState = {
    point_history: [],
    point_sum: 0,
    open_detail_dialog: false,
    open_token_error_dialog: false,
    open_error_dialog: false,
    network_error: false,
};

export const FetchHistory = createAsyncThunk(
    "U_pointHistory/FetchHistory",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        let api_key = thunkAPI.getState().Information.user.token;

        if (!api_key) api_key = "";
        HttpFunction("GET", "/api/point_history", params, api_key)
            .then((data) => {
                // console.log("u_point: " + data.data);
                if (data.error_message && data.error_message.startsWith("ログイン情報の取得ができませんでした")) {
                    dispatch(set_value(["open_token_error_dialog", true]));
                } else if (data) {
                    dispatch(set_value(["point_history", data.data]));
                    dispatch(set_table_value(["total_rows", data.total]));
                    dispatch(set_table_value(["per_page", data.per_page]));
                }  
            })
            .catch((err) => {
                // console.log(err);
                dispatch(set_value(["open_error_dialog", true]));
            });
    }
);

export const GetPointSum = createAsyncThunk(
    "U_pointHistory/GetPointSum",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        let api_key = thunkAPI.getState().Information.user.token;

        if (!api_key) api_key = "";
        HttpFunction("GET", "/api/general_user_information", {}, api_key)
            .then((data) => {
                if (data.error_message && data.error_message.startsWith("ログイン情報の取得ができませんでした")) {
                    dispatch(set_value(["open_token_error_dialog", true]));
                } else if (data) {
                    dispatch(set_information_value(["point_sum", data.point_sum, "user"]));
                }
            })
            .catch((err) => {
                // console.log(err);
                dispatch(set_value(["open_error_dialog", true]));
            });
    }
);

const isRejectedAction = (action) => {
    return action.type.startsWith("U_ponitHistory") && action.type.endsWith("rejected");
};

// Sliceを生成する
const slice = createSlice({
    name: "U_pointHistory",
    initialState,
    reducers: {
        set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(isRejectedAction, (state, action) => {
            state["network_error"] = true;
            state["open_error_dialog"] = true;
        });
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_value } = slice.actions;