import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import InputImagePreview from "../utils/InputImagePreview";
import { release_info_set } from "../../stores/ShopStoreApplication";
import { Grid, Chip, Button } from "@material-ui/core";
import { GetShopData } from "../../stores/ShopInformation";
import Divider from "@material-ui/core/Divider";
import SelectTags from "../utils/SelectTags";
import SelectTarget from "../utils/SelectTarget";
import HalfPriceMenu from "../utils/HalfPriceMenu";
import TextField from "@material-ui/core/TextField";
import SelectPrefectures from "../utils/SelectPrefectures";
import ShopApplicationPreview from "./ShopApplicationPreview";
import SelectTime from "../utils/SelectTime";
import { makeStyles } from "@material-ui/core/styles";
import { HideFooter, ShowFooter } from "../modules/BottomNavigation";
import ReactImageBase64 from "react-image-base64";
import { InputAdornment } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import ClearIcon from "@material-ui/icons/Clear";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    input: {
        "&:invalid": {
            border: "red solid 2px",
        },
    },
    image: {
        width: "100%",
        maxWidth: "550px",
        borderRadius: "0.5rem",
        padding: "1px",
    },
}));

const input_list = {
    shop_city: "市区町村",
    shop_address1: "町名・番地",
    shop_address2: "建物名・部屋番号",
    access: "アクセス",
    business_hours: "営業時間",
    holiday: "定休日",
    url: "URL",
    sns: "SNS",
    reservation: "予約方法",
    shop_phone_number: "電話番号",
    shop_staff: "担当者",
};
const length_message_list = {
    shop_city: "255文字以内",
    shop_address1: "255文字以内",
    shop_address2: "255文字以内",
    access: "255文字以内",
    business_hours: "255文字以内",
    holiday: "255文字以内",
    url: "255文字以内",
    reservation: "255文字以内",
    sns: "255文字以内",
    shop_phone_number: "15文字以内",
    shop_staff: "32文字以内",
};
const input_pattern = {
    shop_city: { maxLength: 255 },
    shop_address1: { maxLength: 255 },
    shop_address2: { maxLength: 255 },
    access: { maxLength: 255 },
    business_hours: { maxLength: 255 },
    holiday: { maxLength: 255 },
    url: { maxLength: 255 },
    reservation: { maxLength: 255 },
    sns: { maxLength: 255 },
    shop_phone_number: { maxLength: 15 },
    shop_staff: { maxLength: 32 },
};

export default function ShopApplication(props) {
    const thisState = useSelector((state) => state.ShopApplication);
    const releaseInfoState = props.releaseInfoState;
    const dispatch = useDispatch();
    const classes = useStyles();

    const SetErrer = (message) => {
        dispatch(release_info_set(["error_message", message]));
    };

    const ChangeBusinessHours = (e, name) => {
        dispatch(release_info_set([name, e.target.value]));
    };
    const ResetBusinessHours = (day_list, name) => {
        day_list.map((val, index) => {
            dispatch(release_info_set([`${val.name}_opening_time`, ""]));
            dispatch(release_info_set([`${val.name}_closing_time`, ""]));
        });
    };
    const ChangeTime = (value, name) => {
        dispatch(release_info_set([name, value]));
    };

    const SetInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (name == "sns") {
            dispatch(release_info_set(["sns", AutoLink(value)]));
            return;
        }

        if (name == "url") {
            dispatch(release_info_set(["url", AutoLink(value)]));
            return;
        }

        if (name == "url") {
            dispatch(release_info_set(["url", AutoLink(value)]));
            return;
        }

        // console.log(name);
        dispatch(release_info_set([name, value]));
    };
    const SetImage = (arg) => {
        // console.log(arg);
        const image = arg.image;

        dispatch(release_info_set(["shop_image_small", image]));

        const image_big = arg.image_big;
        dispatch(release_info_set(["shop_image_big", image_big]));
    };
    const tag_delete = (e) => {
        dispatch(release_info_set([e.target.name, "---未選択---"]));
    };

    const SetTags = (e) => {
        const tags_list = new Array(5)
            .fill(null)
            .map((_, i) => releaseInfoState["tag_id_" + i]);
        // console.log(tags_list);
        if (tags_list.filter((x) => x === e.target.value).length > 0) {
            SetErrer(["入力エラー", "重複するタグは登録できません", "warning"]);

            return;
        }

        // // console.log(tags_list.filter((x) => x === e.target.value).length);

        dispatch(release_info_set([e.target.name, e.target.value]));
    };

    //urlのみの場合aタグをつける
    const AutoLink = (str) => {
        if (str.match(/<a(?: .+?)?>.*?<\/a>/g)) {
            return str;
        }
        var regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
        var regexp_makeLink = function (all, url, h, href) {
            return '<a href="h' + href + '">' + url + "</a>";
        };

        return str.replace(regexp_url, regexp_makeLink);
    };

    const HandleImageClick = (data, name) => {
        dispatch(release_info_set([name, data.fileData]));

        if (name == "shop_image_small") {
            dispatch(release_info_set(["shop_image_big", data.ofileData]));
        }
    };

    const image_delete = (name) => {
        const result = confirm("削除しますか?");
        if (!result) return;

        dispatch(release_info_set([name, ""]));

        if (name == "shop_image_small") {
            dispatch(release_info_set(["shop_image_big", ""]));
        }
    };

    const AddMenu = (new_menu_data) => {
        // half_price: "",
        // menu_detail: "",
        // menu_name: "",
        // original_price: "",
        // treatment_time: "",

        const menu_data_list = releaseInfoState?.menu_data_list
            ? releaseInfoState?.menu_data_list
            : [];
        dispatch(
            release_info_set([
                "menu_data_list",
                [...menu_data_list, new_menu_data],
            ])
        );
    };

    //メニューを変更押下時 変更を反映
    const EditMenu = (edit_menu_data, index) => {
        const menu_data_list = [...releaseInfoState?.menu_data_list];

        const edit_data = { ...edit_menu_data, edit_mode: false };

        menu_data_list.splice(index, 1, edit_data);

        dispatch(release_info_set(["menu_data_list", menu_data_list]));
    };
    //編集押下時 編集領域を表示/非表示
    const HalfPriceEdit = (index) => {
        const menu_data_list = [...releaseInfoState?.menu_data_list];

        let mode = menu_data_list[index]["edit_mode"] ? false : true;

        const edit_data = { ...menu_data_list[index], edit_mode: mode };

        menu_data_list.splice(index, 1, edit_data);

        dispatch(release_info_set(["menu_data_list", menu_data_list]));
    };

    const HalfPriceDelete = (index) => {
        const menu_data_list = [...releaseInfoState?.menu_data_list];
        menu_data_list.splice(index, 1);
        dispatch(release_info_set(["menu_data_list", menu_data_list]));
    };
    return (
        <>
            {props.preview ? (
                <React.Fragment>
                    <ShopApplicationPreview
                        ApplicationData={releaseInfoState}
                    />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <h2>店舗画像</h2>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            maxWidth: "800px",
                            marginRight: "auto",
                            marginLeft: "auto",
                        }}
                    >
                        <Grid item xs={6}>
                            <ImageInput
                                image={releaseInfoState.shop_image_small}
                                HandleImageClick={HandleImageClick}
                                classes={classes}
                                name="shop_image_small"
                                image_delete={image_delete}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item xs={6}>
                                    <ImageInput
                                        image={releaseInfoState?.shop_image_2}
                                        HandleImageClick={HandleImageClick}
                                        classes={classes}
                                        name="shop_image_2"
                                        image_delete={image_delete}
                                    />

                                    <ImageInput
                                        image={releaseInfoState?.shop_image_3}
                                        HandleImageClick={HandleImageClick}
                                        classes={classes}
                                        name="shop_image_3"
                                        image_delete={image_delete}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ImageInput
                                        image={releaseInfoState?.shop_image_4}
                                        HandleImageClick={HandleImageClick}
                                        classes={classes}
                                        name="shop_image_4"
                                        image_delete={image_delete}
                                    />
                                    <ImageInput
                                        image={releaseInfoState?.shop_image_5}
                                        HandleImageClick={HandleImageClick}
                                        classes={classes}
                                        name="shop_image_5"
                                        image_delete={image_delete}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* <InputImagePreview
                        comment={false}
                        onClick={SetImage}
                        height={200}
                        image_big
                    /> */}

                    <h2>店舗名</h2>
                    <TextareaAutosize
                        maxLength={255}
                        maxRows="1"
                        className="FlexTextarea"
                        as="textarea"
                        defaultValue={releaseInfoState.shop_name}
                        name="shop_name"
                        onFocus={HideFooter}
                        onBlur={ShowFooter}
                        onChange={SetInput}
                        style={{ fontSize: "1.2rem" }}
                    />
                    <h2>タグ</h2>
                    <SelectTags
                        view_name={"タグ1"}
                        name={"tag_id_1"}
                        value={releaseInfoState.tag_id_1}
                        onChange={SetTags}
                        delete={tag_delete}
                    />
                    <SelectTags
                        view_name={"タグ2"}
                        name={"tag_id_2"}
                        value={releaseInfoState.tag_id_2}
                        onChange={SetTags}
                        delete={tag_delete}
                    />
                    <SelectTags
                        view_name={"タグ3"}
                        name={"tag_id_3"}
                        value={releaseInfoState.tag_id_3}
                        onChange={SetTags}
                        delete={tag_delete}
                    />
                    <SelectTags
                        view_name={"タグ4"}
                        name={"tag_id_4"}
                        value={releaseInfoState.tag_id_4}
                        onChange={SetTags}
                        delete={tag_delete}
                    />
                    <SelectTags
                        view_name={"タグ5"}
                        name={"tag_id_5"}
                        value={releaseInfoState.tag_id_5}
                        onChange={SetTags}
                        delete={tag_delete}
                    />

                    <h2>サブタイトル</h2>
                    <TextareaAutosize
                        minRows={3}
                        maxLength={255}
                        className="FlexTextarea"
                        as="textarea"
                        value={releaseInfoState.store_pr}
                        name="store_pr"
                        onFocus={HideFooter}
                        onBlur={ShowFooter}
                        onChange={SetInput}
                        style={{ height: "150px" }}
                    />
                    <h2>店舗説明</h2>
                    <TextareaAutosize
                        minRows={3}
                        maxLength={10000}
                        className="FlexTextarea"
                        as="textarea"
                        value={releaseInfoState.shop_explain}
                        name="shop_explain"
                        onFocus={HideFooter}
                        onBlur={ShowFooter}
                        onChange={SetInput}
                        style={{ height: "150px" }}
                    />

                    <h2>fiftyメニュー</h2>
                    <TextareaAutosize
                        minRows={3}
                        maxLength={10000}
                        maxRows="100"
                        className="FlexTextarea"
                        as="textarea"
                        value={releaseInfoState.shop_menu}
                        name="shop_menu"
                        onFocus={HideFooter}
                        onBlur={ShowFooter}
                        onChange={SetInput}
                        style={{ height: "150px" }}
                    />

                    <h2>詳細</h2>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item xs>
                            <b>ターゲット</b>
                        </Grid>
                        <Grid item xs>
                            <SelectTarget
                                label=""
                                onChange={SetInput}
                                value={releaseInfoState.target}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item xs>
                            <b>都道府県</b>
                        </Grid>
                        <Grid item xs>
                            <SelectPrefectures
                                size="small"
                                variant="outlined"
                                name="shop_prefectures"
                                onChange={SetInput}
                                value={releaseInfoState.shop_prefectures}
                                label=""
                            />
                        </Grid>
                    </Grid>

                    <Divider
                        style={{
                            marginTop: "0.5rem",
                            marginBottom: "0.5rem",
                        }}
                    />

                    <React.Fragment>
                        {Object.keys(input_list).map((key, index) => (
                            <React.Fragment key={key}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Grid item xs={3}>
                                        <b>{input_list[key]}</b>
                                        <div style={{ fontSize: "0.2rem" }}>
                                            {length_message_list[key]}
                                        </div>
                                    </Grid>
                                    <Grid item xs>
                                        <TextField
                                            className="FlexTextarea"
                                            multiline
                                            as="textarea"
                                            variant="outlined"
                                            inputProps={{
                                                resize: "both",
                                                ...input_pattern[key],
                                            }}
                                            value={releaseInfoState[key]}
                                            style={{ fontSize: "16px" }}
                                            name={key}
                                            onFocus={HideFooter}
                                            onBlur={ShowFooter}
                                            onChange={SetInput}
                                        />
                                    </Grid>
                                </Grid>
                                <Divider
                                    style={{
                                        marginTop: "0.5rem",
                                        marginBottom: "0.5rem",
                                    }}
                                />
                            </React.Fragment>
                        ))}

                        <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                            spacing={1}
                        >
                            <Grid item xs>
                                <b>無料wifi</b>
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    select
                                    name="wifi"
                                    defaultValue={releaseInfoState.wifi}
                                    onChange={SetInput}
                                    SelectProps={{
                                        native: true,
                                    }}
                                >
                                    <option value={0}>✕</option>
                                    <option value={1}>○</option>
                                </TextField>
                            </Grid>
                        </Grid>
                        <Divider
                            style={{
                                marginTop: "0.5rem",
                                marginBottom: "0.5rem",
                            }}
                        />

                        {props.admin ? (
                            <>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Grid item xs>
                                        <b>緯度</b>
                                    </Grid>
                                    <Grid item xs>
                                        <TextField
                                            onFocus={HideFooter}
                                            onBlur={ShowFooter}
                                            onChange={SetInput}
                                            type="text"
                                            inputProps={{
                                                className: classes.input,
                                                maxLength: 11,
                                                pattern:
                                                    "[-+]?[0-9]{1,3}[.]?[0-9]{1,6}",
                                            }}
                                            size="small"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                            name="latitude"
                                            value={releaseInfoState.latitude}
                                        ></TextField>
                                    </Grid>
                                </Grid>
                                <Divider
                                    style={{
                                        marginTop: "0.5rem",
                                        marginBottom: "0.5rem",
                                    }}
                                />

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Grid item xs>
                                        <b>経度</b>
                                    </Grid>
                                    <Grid item xs>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                            name="longitude"
                                            value={releaseInfoState.longitude}
                                            onFocus={HideFooter}
                                            onBlur={ShowFooter}
                                            onChange={SetInput}
                                            inputProps={{
                                                className: classes.input,
                                                maxLength: 11,
                                                pattern:
                                                    "[-+]?[0-9]{1,3}[.]?[0-9]{1,6}",
                                            }}
                                        ></TextField>
                                    </Grid>
                                </Grid>
                                <Divider
                                    style={{
                                        marginTop: "0.5rem",
                                        marginBottom: "0.5rem",
                                    }}
                                />
                            </>
                        ) : (
                            ""
                        )}
                        <BusinessHoursInput
                            releaseInfoState={releaseInfoState}
                            ChangeBusinessHours={ChangeBusinessHours}
                            ResetBusinessHours={ResetBusinessHours}
                            ChangeTime={ChangeTime}
                        />
                        <ClosestStationInput
                            releaseInfoState={releaseInfoState}
                            ChangeBusinessHours={ChangeBusinessHours}
                        />
                    </React.Fragment>
                </React.Fragment>
            )}
            {/* <h2>半額メニュー</h2> */}
            {props.preview ? (
                <></>
            ) : (
                <React.Fragment>
                    {releaseInfoState?.menu_data_list.length != 0
                        ? releaseInfoState?.menu_data_list.map(
                              (menu_data, index) => (
                                  <React.Fragment key={index}>
                                      {/* {index} */}
                                      <HalfPriceMenu
                                          index={index}
                                          menu_data={menu_data}
                                          onDelete={HalfPriceDelete}
                                          onEdit={HalfPriceEdit}
                                      />
                                      <HalfPriceMenuInput
                                          AddMenu={EditMenu}
                                          editMode={true}
                                          edit_menu_data={menu_data}
                                          index={index}
                                      />
                                  </React.Fragment>
                              )
                          )
                        : "※半額メニューがありません。作成してください。"}
                    <HalfPriceMenuInput
                        AddMenu={AddMenu}
                        editMode={false}
                        index={-1}
                    />
                </React.Fragment>
            )}
            {props.preview ? (
                <></>
            ) : (
                <>
                    <Divider
                        style={{
                            marginTop: "0.5rem",
                            marginBottom: "0.5rem",
                        }}
                    />
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item xs={3}>
                            <b>{"備考"}</b>
                            <div style={{ fontSize: "0.2rem" }}>
                                {"255文字以内"}
                            </div>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                className="FlexTextarea"
                                multiline
                                as="textarea"
                                variant="outlined"
                                inputProps={{
                                    resize: "both",
                                    maxLength: 255,
                                }}
                                value={releaseInfoState.remarks}
                                style={{ fontSize: "16px" }}
                                name="remarks"
                                onFocus={HideFooter}
                                onBlur={ShowFooter}
                                onChange={SetInput}
                            />
                        </Grid>
                    </Grid>
                    <Divider
                        style={{
                            marginTop: "0.5rem",
                            marginBottom: "0.5rem",
                        }}
                    />
                </>
            )}
        </>
    );
}

const ClosestStationInput = (props) => {
    const { releaseInfoState, ChangeBusinessHours } = props;

    return (
        <>
            {[1, 2, 3].map((val, index) => (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: "0.5rem" }}
                >
                    {/* <Grid item style={{ width: "5rem" }}> */}
                    <Grid item style={{ width: "3.1rem", fontSize: "95%" }}>
                        最寄り{index + 1}
                    </Grid>
                    <Grid item xs>
                        <TextField
                            size="small"
                            variant="outlined"
                            style={{ width: "4.7rem" }}
                            name={`closest_traffic_${index + 1}`}
                            label="交通"
                            placeholder="銀座線"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={
                                releaseInfoState &&
                                releaseInfoState[`closest_traffic_${index + 1}`]
                                    ? releaseInfoState[
                                          `closest_traffic_${index + 1}`
                                      ]
                                    : ""
                            }
                            onChange={(e) =>
                                ChangeBusinessHours(
                                    e,
                                    `closest_traffic_${index + 1}`
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            size="small"
                            variant="outlined"
                            //style={{ width: "8rem" }}
                            style={{ width: "5.8rem" }}
                            name={`closest_station_${index + 1}`}
                            label="最寄り"
                            placeholder="表参道駅"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={
                                releaseInfoState &&
                                releaseInfoState[`closest_station_${index + 1}`]
                                    ? releaseInfoState[
                                          `closest_station_${index + 1}`
                                      ]
                                    : ""
                            }
                            onChange={(e) =>
                                ChangeBusinessHours(
                                    e,
                                    `closest_station_${index + 1}`
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            size="small"
                            variant="outlined"
                            style={{ width: "5.8rem" }}
                            name={`closest_means_${index + 1}`}
                            label="手段"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder="徒歩"
                            value={
                                releaseInfoState &&
                                releaseInfoState[`closest_means_${index + 1}`]
                                    ? releaseInfoState[
                                          `closest_means_${index + 1}`
                                      ]
                                    : ""
                            }
                            onChange={(e) =>
                                ChangeBusinessHours(
                                    e,
                                    `closest_means_${index + 1}`
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            size="small"
                            variant="outlined"
                            //style={{ width: "8rem" }}
                            style={{ width: "3rem" }}
                            name={`closest_station_minute_${index + 1}`}
                            label="分数"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder="10"
                            value={
                                releaseInfoState &&
                                releaseInfoState[
                                    `closest_station_minute_${index + 1}`
                                ]
                                    ? releaseInfoState[
                                          `closest_station_minute_${index + 1}`
                                      ]
                                    : ""
                            }
                            onChange={(e) =>
                                ChangeBusinessHours(
                                    e,
                                    `closest_station_minute_${index + 1}`
                                )
                            }
                        />
                    </Grid>
                </Grid>
            ))}
        </>
    );
};

let add_count = -1;

const HalfPriceMenuInput = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { AddMenu, editMode, edit_menu_data, index } = props;

    let display = "";
    if (editMode && !edit_menu_data.edit_mode) {
        display = "none";
    }

    const initial = {
        half_price: editMode ? edit_menu_data.half_price : "",
        menu_detail: editMode ? edit_menu_data.menu_detail : "",
        menu_name: editMode ? edit_menu_data.menu_name : "",
        original_price: editMode ? edit_menu_data.original_price : "",
        treatment_time: editMode ? edit_menu_data.treatment_time : "",
        image_path: editMode ? edit_menu_data.image_path : "",
        edit_kubun: editMode ? edit_menu_data.edit_kubun : "1",
        free_detail: editMode ? edit_menu_data.free_detail : "",
        id: editMode ? edit_menu_data.id : add_count,
    };

    const [menu_data, setMenuData] = React.useState(initial);

    if (editMode && edit_menu_data.id !== menu_data.id) {
        //削除によって参照先がずれた場合は初期化
        setMenuData(initial);
    }

    const HandleChange = (e) => {
        setMenuData({ ...menu_data, [e.target.name]: e.target.value });
    };

    const HandleImageClick = (data, name) => {
        setMenuData({ ...menu_data, [name]: data.fileData });
    };

    // const AddMenu = (data) => {
    //     console.log(data);
    // };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            style={{
                maxWidth: "800px",
                marginRight: "auto",
                marginLeft: "auto",
                border: "solid 1px ",
                borderRadius: "1rem",
                padding: "0.5rem",
                display: display,
                marginBottom: editMode ? "3rem" : "",
            }}
        >
            <Grid item xs={4} style={{ padding: "0.2rem" }}>
                <ImageInput
                    image={menu_data.image_path}
                    HandleImageClick={HandleImageClick}
                    classes={classes}
                    name="image_path"
                    id={menu_data.id}
                />

                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    // style={{ width: "8rem" }}
                    label=""
                    placeholder="施術"
                    name="treatment_time"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">分</InputAdornment>
                        ),
                    }}
                    value={menu_data?.treatment_time}
                    onChange={HandleChange}
                />
            </Grid>
            {/* <Grid item xs="8"> */}
            <Grid
                xs={8}
                container
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                style={{ paddingTop: "0.4rem" }}
            >
                <RadioGroup
                    aria-label=""
                    name="edit_kubun"
                    value={menu_data?.edit_kubun}
                    onChange={HandleChange}
                    style={{ flexDirection: "initial" }}
                >
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        style={{ marginBottom: "0.5rem" }}
                        label=""
                        placeholder="メニュー名"
                        name="menu_name"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        // disabled={menu_data?.edit_kubun == "2"}
                        onChange={HandleChange}
                        value={menu_data?.menu_name}
                    />

                    <Grid item>
                        <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="メニュー価格設定"
                        />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <TextField
                            size="small"
                            variant="outlined"
                            style={{ width: "6rem" }}
                            label=""
                            placeholder="価格"
                            name="original_price"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        円
                                    </InputAdornment>
                                ),
                            }}
                            disabled={menu_data?.edit_kubun == "2"}
                            onChange={HandleChange}
                            value={menu_data?.original_price}
                        />
                        <div style={{ fontSize: "2rem" }}> ⇨</div>
                        <TextField
                            size="small"
                            variant="outlined"
                            style={{ width: "6rem" }}
                            label=""
                            placeholder="半額"
                            name="half_price"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        円
                                    </InputAdornment>
                                ),
                            }}
                            disabled={menu_data?.edit_kubun == "2"}
                            onChange={HandleChange}
                            value={menu_data?.half_price}
                        />
                    </Grid>

                    <Grid item>
                        <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="価格を手入力する"
                        />
                    </Grid>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        style={{ marginTop: "0.5rem" }}
                        label=""
                        placeholder="フリー入力"
                        name="free_detail"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={menu_data?.edit_kubun != "2"}
                        onChange={HandleChange}
                        value={menu_data?.free_detail}
                    />

                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        style={{ marginTop: "0.5rem" }}
                        label=""
                        placeholder="説明"
                        // multiline
                        // row={2}
                        name="menu_detail"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        // disabled={menu_data?.edit_kubun == "2"}
                        onChange={HandleChange}
                        value={menu_data?.menu_detail}
                    />
                </RadioGroup>
            </Grid>
            {/* </Grid> */}
            <Button
                fullWidth
                variant="contained"
                style={{
                    marginTop: "0.5rem",
                    backgroundColor: editMode ? "#99FFFF" : "",
                }}
                onClick={() => {
                    if (menu_data.edit_kubun == "1") {
                        if (
                            !menu_data.half_price ||
                            !menu_data.original_price
                        ) {
                            alert("金額は必須項目です");
                            return;
                        }
                        if (
                            isNaN(menu_data.half_price) ||
                            isNaN(menu_data.original_price)
                        ) {
                            alert("数値以外が入力されています。");
                            return;
                        }

                        if (
                            Number(menu_data.half_price) * 2 >
                            Number(menu_data.original_price)
                        ) {
                            alert("半額以下になっていません。");
                            return;
                        }
                        if (1000 > Number(menu_data.original_price)) {
                            alert(
                                "1000円以上のメニューを半額にする必要があります。"
                            );
                            return;
                        }

                        if (!menu_data.menu_name) {
                            alert("メニュー名は必須です。");
                            return;
                        }
                    } else if (menu_data.edit_kubun == "2") {
                        if (!menu_data.free_detail) {
                            alert("フリー入力は必須です。");
                            return;
                        }
                    } else {
                        alert("メニュー設定 または手入力 を選択してください。");
                        return;
                    }

                    // setMenuData(initial);
                    // AddMenu(menu_data);
                    if (!editMode) add_count = add_count - 1;
                    setMenuData(
                        editMode
                            ? menu_data
                            : {
                                  ...initial,
                                  id: editMode ? menu_data.id : add_count,
                              }
                    );
                    AddMenu(menu_data, index);
                }}
            >
                {editMode ? "メニューを変更" : "メニューを追加"}
            </Button>
        </Grid>
    );
};

const ImageInput = (props) => {
    const { image, HandleImageClick, classes, name, id, image_delete } = props;

    const index = isNaN(id) ? "" : id;

    return (
        <Grid
            direction="column"
            justifyContent="center"
            alignItems="center"
            container
        >
            <ImageListItem
                key={index}
                style={{
                    borderRadius: "0.5rem",
                    textAlign: "center",
                    listStyle: "none",
                }}
            >
                <label
                    htmlFor={`image_upload_${name}${index}`}
                    style={{ marginBottom: "0" }}
                >
                    <img
                        src={image ? image : "null"}
                        className={classes.image}
                        style={{ transform: "initial", position: "initial" }}
                        onError={(e) => {
                            e.target.onerror = "null";
                            e.target.src = "/images/upload.png";
                        }}
                    />
                </label>
                {image_delete ? (
                    <ImageListItemBar
                        className={classes.ImageListItemBar}
                        position={"top"}
                        actionIcon={
                            <ClearIcon
                                color="secondary"
                                onClick={() => image_delete(name)}
                            />
                        }
                    />
                ) : (
                    ""
                )}
            </ImageListItem>
            {/* <Input type="file" id="image_upload" name="image" onChange={HandleChange} hidden /> */}
            {/* <img src={images?.fileData} /> */}
            <div style={{ display: "none" }}>
                <ReactImageBase64
                    maxFileSize={10485760}
                    thumbnail_size={500}
                    drop={false}
                    hidden={true}
                    // capture="environment"
                    multiple={true}
                    id={`image_upload_${name}${index}`}
                    name={name}
                    handleChange={(data) => HandleImageClick(data, name)}
                />
            </div>
        </Grid>
    );
};

const BusinessHoursInput = (props) => {
    const {
        releaseInfoState,
        ChangeBusinessHours,
        ResetBusinessHours,
        ChangeTime,
    } = props;

    const list = [
        { label: "日曜日", name: "sunday" },
        { label: "月曜日", name: "monday" },
        { label: "火曜日", name: "tuesday" },
        { label: "水曜日", name: "wednesday" },
        { label: "木曜日", name: "thursday" },
        { label: "金曜日", name: "friday" },
        { label: "土曜日", name: "saturday" },
    ];

    return (
        <>
            営業時間
            {list.map((val, index) => (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: "0.5rem" }}
                >
                    {/* <Grid item style={{ width: "4rem" }}> */}
                    <Grid item style={{ width: "3rem" }}>
                        {val.label}
                    </Grid>
                    <Grid item xs>
                        {/* <TextField
                            type="time"
                            size="small"
                            variant="outlined"
                            style={{ width: "8rem" }}
                            name="longitude"
                            label="営業開始時間"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={
                                releaseInfoState &&
                                releaseInfoState[`${val.name}_opening_time`]
                                    ? releaseInfoState[
                                          `${val.name}_opening_time`
                                      ]
                                    : ""
                            }
                            onChange={(e) =>
                                ChangeBusinessHours(
                                    e,
                                    `${val.name}_opening_time`
                                )
                            }
                        /> */}
                        <SelectTime
                            label="営業開始時間"
                            value={
                                releaseInfoState &&
                                releaseInfoState[`${val.name}_opening_time`]
                                    ? releaseInfoState[
                                          `${val.name}_opening_time`
                                      ]
                                    : ""
                            }
                            ChangeTime={ChangeTime}
                            val_name={`${val.name}_opening_time`}
                        />
                    </Grid>
                    <Grid item xs>
                        {/* <TextField
                            type="time"
                            size="small"
                            variant="outlined"
                            style={{ width: "8rem" }}
                            label="営業終了時間"
                            name="longitude"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={
                                releaseInfoState &&
                                releaseInfoState[`${val.name}_closing_time`]
                                    ? releaseInfoState[
                                          `${val.name}_closing_time`
                                      ]
                                    : ""
                            }
                            onChange={(e) =>
                                ChangeBusinessHours(
                                    e,
                                    `${val.name}_closing_time`
                                )
                            }
                        /> */}
                        <SelectTime
                            label="営業終了時間"
                            value={
                                releaseInfoState &&
                                releaseInfoState[`${val.name}_closing_time`]
                                    ? releaseInfoState[
                                          `${val.name}_closing_time`
                                      ]
                                    : ""
                            }
                            ChangeTime={ChangeTime}
                            val_name={`${val.name}_closing_time`}
                        />
                    </Grid>
                </Grid>
            ))}
            <Button onClick={() => ResetBusinessHours(list, "")}>
                営業日リセット
            </Button>
        </>
    );
};
