import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Button } from "@material-ui/core";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import Rating from "@material-ui/lab/Rating";
import FavoriteIcon from "@material-ui/icons/Favorite";

import Checkbox from "@material-ui/core/Checkbox";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";

import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FavoriteShop_set } from "../../stores/FavoriteShop";

import HalfPriceMenu from "../utils/HalfPriceMenu";

import HttpFunction from "../utils/HttpFunction";

import { withStyles } from "@material-ui/core/styles";
const StyledRating = withStyles({
    iconFilled: {
        color: "#ff6d75",
    },
    iconHover: {
        color: "#ff3d47",
    },
})(Rating);

const ShopOverview = (props) => {
    const FavoriteShopState = useSelector((state) => state.FavoriteShop);
    const informationState = useSelector((state) => state.Information);
    const api_key = informationState.user.token;
    const dispatch = useDispatch();
    const this_shop_data = props.shop_data;
    const shop_id = this_shop_data.shop_id;
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedF: true,
        checkedG: true,
    });
    const f_list = FavoriteShopState.favorite_list;

    const in_f_list = f_list.find((v) => v.shop_id == shop_id);

    const flag = this_shop_data.favorite == "0" ? false : true;

    const OnFavorite = (e) => {
        if (!api_key) return;

        const params = {
            shop_id: shop_id,
        };

        const type = flag ? "delete_favorite" : "add_favorite";

        HttpFunction("POST", "/api/" + type, params, api_key)
            .then((data) => {
                props.Onfavorite(shop_id);
            })
            .catch((err) => {
                // console.log(err);
            });
    };
    return (
        <div
            className="shop-overview"
            key={this_shop_data.shop_id}
            style={{
                width: "96%",
                marginRight: "2%",
                marginLeft: "2%",
                marginBottom: "2px",
                ...props?.style,
            }}
        >
            {/* <Card style={{ padding: "1rem" }}> */}
            <Card style={{ padding: "1rem", minHeight: "120px", fontSize: "90%" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    {informationState.user.user_login && props.Onfavorite ? (
                        <Checkbox
                            checked={flag}
                            icon={<FavoriteBorder />}
                            checkedIcon={<Favorite />}
                            name={this_shop_data.shop_id}
                            className="favorite-icon"
                            onChange={OnFavorite}
                        />
                    ) : (
                        ""
                    )}

                    <div>
                        評価{" : "}
                        {this_shop_data.review ? this_shop_data.review : "0"}
                        {/* <StyledRating
                            name="read-only"
                            value={this_shop_data.review}
                            readOnly
                            icon={<FavoriteIcon fontSize="inherit" />}
                        /> */}
                    </div>
                </Grid>

                <Container className="shop-overview-container">
                    <Row>
                        <Col>
                            {/* <Link
                                to={
                                    "/ShopInformation/" + this_shop_data.shop_id
                                }
                            >
                                <h4>{this_shop_data.shop_name}</h4>
                            </Link> */}

                            {/* <div
                                className={"shop-honbun" + " " + "html-preview"}
                                dangerouslySetInnerHTML={{
                                    __html: this_shop_data.store_pr
                                        ? this_shop_data.store_pr.replace(
                                              /\n/g,
                                              "<br>"
                                          )
                                        : "",
                                }}
                            ></div> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3} style={{ paddingRight: 0, paddingLeft: 0 }}>
                            <Link
                                to={
                                    "/ShopInformation/" + this_shop_data.shop_id
                                }
                            >
                                <Image
                                    src={this_shop_data.shop_image_small}
                                    rounded
                                    className="img-responsive"
                                    onError={(e) => {
                                        e.target.onerror = "null";
                                        e.target.src = "/images/no_img.png";
                                    }}
                                    style={{
                                        width: "220px",
                                        maxHeight: "150px",
                                        //height: "100%",
                                        objectFit: "cover",
                                        //aspectRatio: "5 / 3",
                                    }}
                                />
                            </Link>
                        </Col>
                        <Col>
                            <Link
                                to={
                                    "/ShopInformation/" + this_shop_data.shop_id
                                }
                            >
                                <h4 style={{ color: "hotpink" }}>
                                    {this_shop_data.shop_name}
                                </h4>
                            </Link>
                            <div
                                className={"shop-honbun" + " " + "html-preview"}
                                dangerouslySetInnerHTML={{
                                    __html: this_shop_data.store_pr
                                        ? this_shop_data.store_pr.replace(
                                              /\n/g,
                                              "<br>"
                                          )
                                        : "",
                                }}
                            ></div>
                            {(this_shop_data.closest_station_1 || this_shop_data.closest_traffic_1) && (
                                <>
                                    📍
                                    {this_shop_data.closest_traffic_1}{" "}
                                    {this_shop_data.closest_station_1}{" "}
                                    {this_shop_data.closest_means_1}
                                    {this_shop_data.closest_station_minute_1}分
                                    <br />
                                </>
                            )}
                            {(this_shop_data.closest_station_2 || this_shop_data.closest_traffic_2) && (
                                <>
                                    📍
                                    {this_shop_data.closest_traffic_2}{" "}
                                    {this_shop_data.closest_station_2}{" "}
                                    {this_shop_data.closest_means_2}
                                    {this_shop_data.closest_station_minute_2}分
                                    <br />
                                </>
                            )}
                            {(this_shop_data.closest_station_3 || this_shop_data.closest_traffic_3) && (
                                <>
                                    📍
                                    {this_shop_data.closest_traffic_3}{" "}
                                    {this_shop_data.closest_station_3}{" "}
                                    {this_shop_data.closest_means_3}
                                    {this_shop_data.closest_station_minute_3}分
                                </>
                            )}

                            {/* <Tags this_shop_data={this_shop_data} /> */}
                        </Col>
                    </Row>

                    {(this_shop_data.menu_name || this_shop_data.free_detail) ? (
                        <>
                            <HalfPriceMenu menu_data={this_shop_data} />
                        </>
                    ) : (
                        ""
                    )}

                    {this_shop_data.tweet ? (
                        <>
                            スタッフの一言
                            <Grid
                                style={{
                                    background: "#d7d7d7",
                                    borderRadius: "1rem",
                                    padding: "1rem",
                                }}
                            >
                                {this_shop_data.tweet}
                            </Grid>
                        </>
                    ) : (
                        ""
                    )}
                </Container>
            </Card>
        </div>
    );
};

const Tags = (props) => {
    const this_shop_data = props.this_shop_data;
    const tag_id_names = [
        "tag_id_1",
        "tag_id_2",
        "tag_id_3",
        "tag_id_4",
        "tag_id_5",
    ];
    return (
        <React.Fragment>
            <Row>
                <div className="tag-list">
                    {tag_id_names.map((tag_name, index) => (
                        <React.Fragment key={index}>
                            {this_shop_data[tag_name] ? (
                                <Chip
                                    as="input"
                                    type="button"
                                    variant="outlined"
                                    key={this_shop_data[tag_name]}
                                    label={this_shop_data[tag_name]}
                                />
                            ) : (
                                ""
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </Row>
        </React.Fragment>
    );
};

export default ShopOverview;
