import React, { useEffect } from "react";
import HistoryTable from "./HistoryTable";
import { initialize_table } from "../../stores/HistoryTable";
import { set_value, FetchHistory } from "../../stores/S_couponUseHistory";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Error500Handling from "../utils/Error500Handling";
import TokenErrorDialog from "../utils/TokenErrorDialog";
import Grid from "@material-ui/core/Grid";
import { ButtonContainedPrimary } from "../utils/Buttons";

import Pagination from "@material-ui/lab/Pagination";
import {
    GetUserHistory,
    setUserDetail,
    GetUserPointHistory,
    initialize,
} from "../../stores/AdminGeneralUserDetail";

const AdminGeneralUserDetail = (props) => {
    const { general_user_id } = props;

    useEffect(() => {
        dispatch(initialize());
    }, []);

    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.AdminGeneralUserDetail);

    const handleGetHistory = () => {
        dispatch(GetUserHistory({ general_user_id: general_user_id }));
    };
    const handleGetPointHistory = () => {
        dispatch(GetUserPointHistory({ general_user_id: general_user_id }));
    };

    const hangeChangPageHistory = (event, new_page) => {
        dispatch(
            GetUserHistory({ general_user_id: general_user_id, page: new_page })
        );
    };
    const hangeChangPagePointHistory = (event, new_page) => {
        dispatch(
            GetUserPointHistory({
                general_user_id: general_user_id,
                page: new_page,
            })
        );
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Grid container>
                        <ButtonContainedPrimary
                            onClick={handleGetHistory}
                            style={{ margin: "1rem" }}
                        >
                            利用履歴表示
                        </ButtonContainedPrimary>
                    </Grid>

                    {thisState?.history_data?.length ? (
                        <Grid container>
                            <Grid
                                item
                                xs={6}
                                style={{
                                    background: "pink",
                                    padding: "1rem",
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    border: "solid 1px",
                                    textAlign: "center",
                                    margin: "0px",
                                }}
                            >
                                店舗名
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                style={{
                                    background: "pink",
                                    padding: "1rem",
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    border: "solid 1px",
                                    textAlign: "left",
                                    margin: "0px",
                                }}
                            >
                                使用日時
                            </Grid>
                            {thisState.history_data.map((val, index) => (
                                <>
                                    <Grid
                                        item
                                        xs={6}
                                        style={{
                                            border: "1px solid",
                                            textAlign: "center",
                                            margin: "0px",
                                            padding: "0.5rem",
                                        }}
                                    >
                                        {val.shop_name}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        style={{
                                            border: "1px solid",
                                            textAlign: "center",
                                            margin: "0px",
                                            padding: "0.5rem",
                                        }}
                                    >
                                        {val.used_at}
                                    </Grid>
                                </>
                            ))}

                            <Pagination
                                style={{ margin: "1rem auto" }}
                                count={thisState.history_options.last_page}
                                variant="outlined"
                                color="primary"
                                page={thisState.history_options.current_page}
                                onChange={hangeChangPageHistory}
                            />
                        </Grid>
                    ) : (
                        ""
                    )}
                </Grid>
                <Grid item xs={6}>
                    <ButtonContainedPrimary
                        style={{ margin: "1rem" }}
                        onClick={handleGetPointHistory}
                    >
                        パカッティー履歴表示
                    </ButtonContainedPrimary>

                    {thisState?.point_history_data?.length ? (
                        <Grid container>
                            <Grid
                                item
                                xs={2}
                                style={{
                                    background: "pink",
                                    padding: "1rem",
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    border: "solid 1px",
                                    textAlign: "center",
                                    margin: "0px",
                                }}
                            >
                                パカッティー
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                style={{
                                    background: "pink",
                                    padding: "1rem",
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    border: "solid 1px",
                                    textAlign: "center",
                                    margin: "0px",
                                }}
                            >
                                理由
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                style={{
                                    background: "pink",
                                    padding: "1rem",
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    border: "solid 1px",
                                    textAlign: "left",
                                    margin: "0px",
                                }}
                            >
                                日時
                            </Grid>
                            {thisState.point_history_data.map((val, index) => (
                                <>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            border: "1px solid",
                                            textAlign: "center",
                                            margin: "0px",
                                            padding: "0.5rem",
                                        }}
                                    >
                                        {val.point}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        style={{
                                            border: "1px solid",
                                            textAlign: "center",
                                            margin: "0px",
                                            padding: "0.5rem",
                                        }}
                                    >
                                        {val.reason}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        style={{
                                            border: "1px solid",
                                            textAlign: "center",
                                            margin: "0px",
                                            padding: "0.5rem",
                                        }}
                                    >
                                        {val.created_at}
                                    </Grid>
                                </>
                            ))}
                            <Pagination
                                style={{ margin: "1rem auto" }}
                                count={
                                    thisState.point_history_options.last_page
                                }
                                variant="outlined"
                                color="primary"
                                page={
                                    thisState.point_history_options.current_page
                                }
                                onChange={hangeChangPagePointHistory}
                            />
                        </Grid>
                    ) : (
                        ""
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default AdminGeneralUserDetail;
