import React from "react";

// 加盟店編集

const ErrorPage = () => {
    return (
        <React.Fragment>
            <div>
                <h2>404</h2>
                <p>Sorry, the page you're looking con not found.</p>
            </div>
        </React.Fragment>
    );
};

export default ErrorPage;
