import React from "react";
import { Grid } from "@material-ui/core";

const TextHorizon = (props) => {
    const name = props.name;
    const value = props.value;
    return (
        <Grid
            container
            direction="row"
            alignItems="flex-start"
            spacing={1}
            style={{
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
            }}
        >
            <Grid
                item
                xs={3}
                style={{
                    color: "#ff3399",
                }}
            >
                <b
                    style={{
                        color: "#ff3399",
                    }}
                >
                    {" "}
                    {name}
                </b>
            </Grid>
            <Grid item xs={9}>
                <div
                    style={{ wordWrap: "break-word" }}
                    className="html-preview"
                    dangerouslySetInnerHTML={{
                        __html: value ? value.replace(/\n/g, "<br>") : "",
                    }}
                ></div>
            </Grid>
        </Grid>
    );
};

export default TextHorizon;
