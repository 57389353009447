import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";
import { set_table_value } from "./HistoryTable";

const dt = new Date();
const year = dt.getFullYear(); //年
const month = ("0" + (dt.getMonth() + 1)).slice(-2); //月
const day = ("0" + dt.getDate()).slice(-2);
const start_date = year - 1 + "-" + month + "-" + day;
const end_date = year + "-" + month + "-" + day;

// Stateの初期状態
const initialState = {
    modal_show: false,
    message_show: false,
    start_date: start_date,
    end_date: end_date,
    history_list: [],
    error_message: "",
    // ---レビューダイアログ---
    open_review_dialog: false,
    review: null,
    reviewed_shop: "",
    reviewed_history_id: "",
    show_success: false,
    // ------------------------
    search_triger: 0,
    open_error_dialog: false,
    network_error: false,
    open_token_error_dialog: false,
};

export const FetchHistory = createAsyncThunk(
    "U_couponUseHistory/FetchHistory",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        let api_key = thunkAPI.getState().Information.user.token;

        if (!api_key) api_key = "";
        HttpFunction("GET", "/api/history", params, api_key)
            .then((data) => {
                // console.log("u_coupon: ");
                // console.dir(data);
                if (
                    data.error_message &&
                    data.error_message.startsWith(
                        "ログイン情報の取得ができませんでした"
                    )
                ) {
                    dispatch(set_value(["open_token_error_dialog", true]));
                } else if (data) {
                    dispatch(set_value(["history_list", data.data]));
                    dispatch(set_table_value(["total_rows", data.total]));
                    dispatch(set_table_value(["per_page", data.per_page]));
                }
            })
            .catch((err) => {
                // console.log(err);
                dispatch(set_value(["open_error_dialog", true]));
            });
    }
);

export const PostReview = createAsyncThunk(
    "U_couponUseHistory/PostReview",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const thisState = thunkAPI.getState().U_couponUseHistory;
        let api_key = thunkAPI.getState().Information.user.token;

        if (!api_key) api_key = "";
        HttpFunction("POST","/api/review", params, api_key)
            .then((data) => {
                if (!data.error_message) {
                    dispatch(set_value(["show_success", true]));
                    const next_history_list = [];
                    thisState.history_list.forEach(history => {
                        if (history.history_id === thisState.reviewed_history_id) {
                            const newHistory = {
                                id: history.id,
                                shop_id: history.shop_id,
                                shop_name: history.shop_name,
                                used_at: history.used_at,
                                user_id: history.user_id,
                                created_at: history.created_at,
                                review: thisState.review,
                                history_id: history.history_id,
                            };
                            next_history_list.push(newHistory);
                        } else {
                            next_history_list.push(history);
                        }
                    });
                    dispatch(set_value(["history_list", next_history_list]));
                } else if (data.error_message.startsWith("ログイン情報の取得ができませんでした")) {
                    dispatch(set_value(["open_token_error_dialog", true]));
                } else {
                    dispatch(set_value(["error_message", data.error_message]));
                    dispatch(set_value(["message_show", true]));
                }
            })
            .catch((err) => {
                // console.log(err);
                dispatch(set_value(["network_error", false]));
                dispatch(set_value(["open_error_dialog", true]));
            });
    }
);

const isRejectedAction = (action) => {
    return action.type.startsWith("U_couponUseHistory") && action.type.endsWith("rejected");
};

// Sliceを生成する
const slice = createSlice({
    name: "U_couponUseHistory",
    initialState,
    reducers: {
        set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
        crear_serch: (state) => {
            state["start_date"] = start_date;
            state["end_date"] = end_date;
            state["genre"] = "";
            state["shop_name"] = "";
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(isRejectedAction, (state, action) => {
            state["network_error"] = true;
            state["open_error_dialog"] = true;
        });
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_value, crear_serch } = slice.actions;
