import React, { useState, useEffect } from "react";

import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { set_value } from "../../stores/Information";
import { shopuser_info_set } from "../../stores/ShopUserInfoChange";
import { useHistory } from "react-router";
import HttpFunction from "../utils/HttpFunction";
import TokenErrorDialog from "../utils/TokenErrorDialog";

const ShopUserInformation = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const informationState= useSelector((state) => state.Information);
    const [openFailedLogin, setOpenFailedLogin] = React.useState(false);

    useEffect(() => {
        const api_key = informationState.shop.shop_token;
        // console.log("apikey:" + api_key);
        if(!api_key){
            setOpenFailedLogin(true);
        }
        HttpFunction("GET", "/api/shop_user_information", "", api_key)
            .then((data) => {
                // console.log(data);
                if(data.error_message && data.error_message.startsWith("ログイン情報の取得ができませんでした")) {
                    setOpenFailedLogin(true);
                } else {
                    dispatch(set_value(["shop_id", data.shop_id, "shop"]));
                    dispatch(set_value(["shop_user_id", data.user_id, "shop"]));
                    dispatch(set_value(["shop_user_name", data.nickname, "shop"]));
                    dispatch(set_value(["shop_email_address", data.email_address, "shop"]));
                    dispatch(set_value(["shop_updated_at", data.updated_at, "shop"]));
                    dispatch(set_value(["shop_token", api_key, "shop"]));
                }
            })
            .catch((err) => {
                // console.log(err);
                // console.log("データ取得に失敗");
                setOpenFailedLogin(true);
            });
    }, []);

    const OnFixImportant = (e) => {
        // console.log(e.currentTarget.name);
        if(e.currentTarget.name === "nickname") {
            // console.log("名字（役職）変更");
            history.push("/ShopUserInfoChange");
        } else{
            // console.log("メールまたはパスワード変更");
            dispatch(shopuser_info_set(["email_address", informationState.shop.shop_email_address]));
            dispatch(shopuser_info_set(["fix_type", "email"]));
            dispatch(shopuser_info_set(["situation", e.currentTarget.name]));
            dispatch(set_value(["showDrawer", false]));
            dispatch(set_value(["showBottomNavigation", false]));
            history.push("/ImportantChangeShopUser");
        }
    };

    const OnWithdrawal = () => {
        history.push("/Withdrawal");
    };

    const InformationItem = (props) => {
        return (
            <Container>
                <div>{props.title}</div>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <div>{props.value}</div>
                    {props.fix ? (
                        <Button
                            name={props.name}
                            variant="contained"
                            color="primary"
                            onClick={OnFixImportant}
                        >
                            編集
                        </Button>
                    ) : (
                        ""
                    )}
                </Grid>

                <Divider
                    style={{ marginTop: "0.3em", marginBottom: "0.5rem" }}
                />
            </Container>
        );
    };

    return (
        <React.Fragment>
            <Container fixed>
                <h2>プロフィール</h2>
                <InformationItem
                    title="ID"
                    name="user_id"
                    value={informationState.shop.shop_user_id}
                />
                <InformationItem
                    title="名字（役職）"
                    name="nickname"
                    value={informationState.shop.shop_user_name}
                    fix={true}
                />
                <InformationItem
                    title="メールアドレス"
                    name="email_address"
                    value={informationState.shop.shop_email_address}
                    fix={true}
                />
                <InformationItem
                    title="パスワード"
                    value={"***********"}
                    name="password"
                    fix={true}
                />
                {/* <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        marginTop: "0.5rem",
                        borderTop: "2px solid gray",
                        paddingTop: "0.5rem"
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={OnWithdrawal}
                    >
                        退会について
                    </Button>
                </Grid> */}
            </Container>
            <TokenErrorDialog open={openFailedLogin} />
        </React.Fragment>
    );
};

export default ShopUserInformation;
