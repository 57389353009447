import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Tooltip from "@material-ui/core/Tooltip";
import CopyToClipBoard from "react-copy-to-clipboard";

const TextBoxWithCopyButton = (props) => {
    const input = props.input;
    const onChange = props.onChange;
    const openTip = props.openTip;
    const handleClickButton = props.handleClickButton;
    const handleCloseTip = props.handleCloseTip;

    return (
        <FormControl variant="outlined">
            <OutlinedInput
                type="text"
                value={input}
                onChange={onChange}
                endAdornment={
                    <InputAdornment position="end">
                        <Tooltip
                            arrow
                            open={openTip}
                            onClose={handleCloseTip}
                            disableHoverListener
                            placement="top"
                            title="Copied!"
                        >
                            <CopyToClipBoard text={input}>
                                <IconButton
                                    disabled={input === ""}
                                    onClick={handleClickButton}
                                >
                                    <AssignmentIcon />
                                </IconButton>
                            </CopyToClipBoard>
                        </Tooltip>
                    </InputAdornment>
                }
                disabled
            />
        </FormControl>
    );
};

export default TextBoxWithCopyButton;
