import React, { useEffect } from "react";
// import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { LinkButton } from "~CJS/atoms/LinkButton";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import Paper from "@material-ui/core/Paper";
import {
    initialize,
    VoteSet,
    vote_set,
    GetVoteList,
    SetPoll,
    ShowVoteList,
    VoteDelete,
} from "~stores/TALENTBANK/Vote";
import { ValidationText } from "~CJS/atoms/TextFields";
import Pagination from "@material-ui/lab/Pagination";

import CommonDialog from "~CJS/atoms/CommonDialog";

import { useDispatch, useSelector } from "react-redux";
import { GetNow } from "~/utils/GetNow";
const now_year_month = GetNow().year + "-" + GetNow().month;

import { withStyles } from "@material-ui/core/styles";

const DarkerDisabledTextField = withStyles({
    root: {
        marginRight: 8,
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.6)", // (default alpha is 0.38)
        },
    },
})(TextField);

const VoteList = () => {
    const dispatch = useDispatch();
    const voteState = useSelector((state) => state.Vote);
    const [modal, setmodal] = React.useState(false);
    const [limit, setlimit] = React.useState(10);

    useEffect(() => {
        dispatch(initialize());
        dispatch(ShowVoteList());
    }, []);

    // useEffect(() => {
    //     dispatch(
    //         GetVoteList({
    //             year_month: voteState.vote_serch.year_month.replace("-", ""),
    //         })
    //     );
    // }, [voteState.vote_serch]);

    const HandleVoteSet = (talent_id, vote_id) => {
        dispatch(VoteSet({ vote_id: vote_id, talent_id: talent_id })).then(
            (data) => {
                dispatch(
                    GetVoteList({
                        vote_id: vote_id,
                    })
                );
            }
        );
    };

    const ChangePoll = (talent_id, poll) => {
        dispatch(
            vote_set([
                "new_poll",
                {
                    ...voteState.new_poll,
                    [talent_id]: poll,
                },
            ])
        );
    };
    const HandleSetPoll = (talent_id, vote_id) => {
        if (!voteState.new_poll[talent_id]) {
            alert("変更されていません");
            return;
        }
        if (!isFinite(voteState.new_poll[talent_id])) {
            alert("数字以外が入力されています");
            return;
        }
        dispatch(
            SetPoll({
                talent_id: talent_id,
                poll: voteState.new_poll[talent_id],
                vote_id: vote_id,
            })
        ).then((data) => {
            dispatch(vote_set(["new_poll", {}]));
            dispatch(
                GetVoteList({
                    vote_id: vote_id,
                    limit: limit,
                })
            );
        });
    };

    const handleChangePage = (event, new_page) => {
        dispatch(ShowVoteList({ page: new_page }));
    };

    const HandleDeleteVote = (vote_id) => {
        const result = window.confirm(
            `｢${vote_id}｣を削除します。\nよろしいですか?`
        );

        if (!result) return;

        dispatch(
            VoteDelete({
                vote_id: vote_id,
            })
        ).then((data) => {
            dispatch(ShowVoteList());
        });
    };

    const HandleSerchVote = (vote_id) => {
        dispatch(
            GetVoteList({
                vote_id: vote_id,
                limit: limit,
            })
        ).then((data) => {
            setmodal(true);
        });
    };

    // const ChangeYearMonth = (e) => {
    //     dispatch(
    //         vote_set([
    //             "vote_serch",
    //             {
    //                 ...voteState.vote_serch,
    //                 year_month: e.target.value,
    //             },
    //         ])
    //     );
    // };
    const HandlelimitChange = (e) => {
        setlimit(e.target.value);
        dispatch(
            GetVoteList({
                vote_id: voteState.selected_vote_id,
                limit: e.target.value,
            })
        ).then((data) => {
            // setmodal(true);
        });
    };

    const DateForm = (props) => {
        return (
            <DarkerDisabledTextField
                label={props.label}
                // variant="outlined"
                type="date"
                size="small"
                value={props.value}
                disabled
            ></DarkerDisabledTextField>
        );
    };

    return (
        <Container>
            <ButtonNavi
                left_label="Home画面"
                left_link="/AdminHome"
                right_label="投票を作成"
                right_link="/VoteCreate"
            />
            <Typography variant="h1">{"投票管理"}</Typography>

            {voteState.vote_list.map((value, index) => (
                <Grid
                    key={index}
                    container
                    style={{
                        maxWidth: "800px",
                        border: "solid 1px",
                        margin: "1rem",
                        maxWidth: "800px",
                        marginRight: "auto",
                        marginLeft: "auto",
                        padding: "0.5rem",
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {value?.image && (
                            <img
                                src={value.image}
                                onError={(e) => {
                                    e.target.onerror = "null";
                                    e.target.src =
                                        "/images/talentBank/NO_IMAGE/no_image_500×140.png";
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                <Typography variant="h5">
                                    {value.vote_title}
                                </Typography>
                            </Grid>

                            <Grid item>投票ID {value.vote_id}</Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                        >
                            <Grid item xs={12} sm={6}>
                                公開開始日
                                <DateForm
                                    value={value.vote_view_start}
                                    name=""
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                公開終了日
                                <DateForm
                                    value={value.vote_view_close}
                                    name=""
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                投票開始日
                                <DateForm value={value.vote_start} name="" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                投票終了日
                                <DateForm value={value.vote_close} name="" />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            投票詳細 {value.vote_detail}
                        </Grid>
                        <Grid item xs={12}>
                            メモ {value.memo}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                    >
                        <Grid item>
                            <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                onClick={() => HandleDeleteVote(value.vote_id)}
                            >
                                削除
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={() => HandleSerchVote(value.vote_id)}
                            >
                                ランキングを表示
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            ))}

            {voteState.vote_info.page_count > 1 && (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Pagination
                        count={voteState.vote_info.page_count}
                        variant="outlined"
                        page={voteState.vote_info.page}
                        color="secondary"
                        onChange={handleChangePage}
                    />
                </Grid>
            )}

            {/* <TextField
                className="month-input"
                // fullWidth
                name="month"
                type="month"
                label="期間"
                variant="outlined"
                value={voteState.vote_serch.year_month}
                onChange={ChangeYearMonth}
            ></TextField> */}

            <CommonDialog open={modal} onClose={() => setmodal(false)}>
                <>
                    {!voteState.vote_talent_list.length ? (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                                minHeight: "5rem",
                                margin: "1rem",
                            }}
                        >
                            <Grid item>投票がありません</Grid>
                        </Grid>
                    ) : (
                        <>
                            <TextField
                                label="表示人数"
                                variant="outlined"
                                select
                                // size="small"
                                value={limit}
                                onChange={HandlelimitChange}
                                style={{
                                    width: "8rem",
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </TextField>
                        </>
                    )}
                    {voteState.vote_talent_list.map((value, index) => (
                        <Grid
                            key={index}
                            container
                            // spacing={2}
                            style={{
                                maxWidth: "800px",
                                border: "solid 1px",
                                margin: "1rem",
                                maxWidth: "800px",
                                marginRight: "auto",
                                marginLeft: "auto",
                                padding: "0.5rem",
                            }}
                        >
                            <Grid item>
                                <Grid
                                    item
                                    style={{
                                        fontSize: "2rem",
                                    }}
                                >
                                    {index + 1}位
                                </Grid>
                                <Grid
                                    item
                                    style={{
                                        fontSize: "2rem",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                        width: "200px",
                                        textAlign: "center",
                                        display: "contents",
                                    }}
                                >
                                    <ValidationText
                                        className="month-input"
                                        fullWidth={false}
                                        key={value.talent_id}
                                        name="poll"
                                        type="text"
                                        variant="outlined"
                                        value={
                                            voteState.new_poll[value.talent_id]
                                                ? voteState.new_poll[
                                                      value.talent_id
                                                  ]
                                                : value.poll
                                        }
                                        onChange={(e) =>
                                            ChangePoll(
                                                value.talent_id,
                                                e.target.value
                                            )
                                        }
                                        inputProps={{
                                            maxLength: 10,
                                            pattern: "^[0-9]*$",
                                        }}
                                    ></ValidationText>

                                    <div
                                        style={{
                                            display: "inline-block",
                                            fontSize: "1rem",
                                            marginTop: "2rem",
                                        }}
                                    >
                                        票
                                    </div>
                                </Grid>
                                <Button
                                    variant="contained"
                                    color="default"
                                    name="HandleSetPoll"
                                    style={{
                                        display: "flex",
                                        width: "220px",
                                        marginTop: "0.3rem",
                                    }}
                                    onClick={() =>
                                        HandleSetPoll(
                                            value.talent_id,
                                            value.vote_id
                                        )
                                    }
                                >
                                    編集する
                                </Button>
                            </Grid>
                            <Grid item>
                                <img
                                    src={
                                        voteState?.talent_list[index]?.image_big
                                    }
                                    style={{
                                        height: "150px",
                                        width: "150px",
                                    }}
                                />
                            </Grid>

                            <Grid item xs>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-end"
                                    alignItems="stretch"
                                    style={{
                                        justifyContent: "flex-end",
                                        minWidth: "150px",
                                    }}
                                >
                                    <Grid
                                        item
                                        style={{
                                            fontSize: "1.5rem",
                                        }}
                                    >
                                        {
                                            voteState?.talent_list[index]
                                                ?.talent_name
                                        }
                                    </Grid>
                                    <Grid item>{value.talent_id}</Grid>
                                    <Grid
                                        item
                                        style={{
                                            marginTop: "0.5rem",
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="default"
                                            fullWidth
                                            name="HandleVoteSet"
                                            disabled={
                                                voteState.vote_serch
                                                    .year_month !=
                                                now_year_month
                                            }
                                            onClick={() =>
                                                HandleVoteSet(
                                                    value.talent_id,
                                                    value.vote_id
                                                )
                                            }
                                        >
                                            投票する
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        style={{
                                            marginTop: "0.3rem",
                                        }}
                                    >
                                        <LinkButton
                                            label="詳細を確認"
                                            to={`/TalentDetail/${value.talent_id}`}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </>
            </CommonDialog>
        </Container>
    );
};

export default VoteList;
