import React, { useEffect } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Container from "@material-ui/core/Container";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { set_value, FetchFAQ } from "../../stores/FAQ";
import Error500Handling from "../utils/Error500Handling";
import Grid from "@material-ui/core/Grid";
import LINEAt from "../block/LINEAt";

const FAQ = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.FAQ);

    const GetFAQ = () => {
        dispatch(FetchFAQ());
    };

    const handleCloseError = () => {
        dispatch(set_value(["open_error_dialog", false]));
    };

    const useStylesAccordion = makeStyles((theme) => ({
        root: {
            "&.MuiPaper-root.MuiPaper-elevation1": {
                width: "100%",
                borderRadius: "unset",
                boxShadow: "none",
            },
        },
    }));

    const QAsAccordion = ({ qasForCategory }) => {
        const classes = useStylesAccordion();

        return qasForCategory.map((qa) => (
            <Accordion key={qa.id} className={classes.root}>
                <AccordionSummary
                    style={{ paddingLeft: "10px" }}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <span
                        style={{
                            color: "mediumturquoise",
                            fontWeight: "bold",
                            fontSize: "180%",
                            margin: "0 15px auto 0",
                            whiteSpace : "nowrap",
                        }}
                    >
                        Q.
                    </span>
                    <span style={{ margin: "auto 0" }}>{qa.question}</span>
                </AccordionSummary>
                <AccordionDetails style={{ paddingLeft: "10px" }}>
                    <span
                        style={{
                            color: "lightsalmon",
                            fontWeight: "bold",
                            fontSize: "180%",
                            margin: "0 15px auto 0",
                            whiteSpace : "nowrap",
                        }}
                    >
                        A.
                    </span>
                    <span style={{ margin: "auto 0", whiteSpace: "pre-line" }}>
                        {qa.answer}
                    </span>
                </AccordionDetails>
            </Accordion>
        ));
    };

    const CategoriesAccordion = ({ categories: qas }) => {
        const classes = useStylesAccordion();

        return qas.map((categoryArray) => (
            <Accordion
                key={categoryArray[0].question_category}
                className={classes.root}
            >
                <AccordionSummary
                    style={{ backgroundColor: "mistyrose" }}
                    expandIcon={<ExpandMoreIcon />}
                >
                    {categoryArray[0].question_category}
                </AccordionSummary>
                <AccordionDetails style={{ display: "block", padding: 0 }}>
                    <QAsAccordion qasForCategory={categoryArray} />
                </AccordionDetails>
            </Accordion>
        ));
    };

    useEffect(() => {
        GetFAQ();
    }, []);

    return (
        <Container>
            <h1>よくある質問</h1>
            <div style={{ padding: "5px" }}>
                <CategoriesAccordion categories={thisState.qas} />
            </div>
            <Typography style={{ padding: "20px" }}>
                よくある質問では解決しないご質問や、ご意見・ご要望等ございましたら、下記のお問い合わせ先までお願いいたします。
            </Typography>
            <Typography style={{ padding: "20px" }}>
                HYBRID BANK Co.Ltd
                <br />
                フィフティーカードアプリ担当
                <br />
                <br />
            </Typography>

            <LINEAt
                link="https://lin.ee/bMRyhQh"
                image="/images/Common/FCラインアット一般用.jpg"
            />
            <Error500Handling
                open={thisState.open_error_dialog}
                GET={true}
                onClose={handleCloseError}
            />
        </Container>
    );
};

export default FAQ;
