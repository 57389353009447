import { createSlice } from "@reduxjs/toolkit";

// Stateの初期状態
const initialState = {
    guide_data: [
        {
            document_kubun: "",
            id: "",
            title1: "",
            title2: "",
            title3: "",
            text: "",
        },
    ],
    guide_detail: {
        id: {
            text: "",
        },
    },
    guide_data_list: {},
};
// Sliceを生成する
const slice = createSlice({
    name: "Guide",
    initialState,
    reducers: {
        set_guide: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];

            if (type) {
                state[name] = value;
                let new_type = state[type];
                new_type[name] = value;
                state[type] = new_type;
            } else {
                state[name] = value;
            }
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_guide } = slice.actions;
