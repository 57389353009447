import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";
import { set_value } from "./Information";

// Stateの初期状態
const initialState = {
    fix_type: null, 
    /*
        email:パスワード変更時のメアド送信
        onetime: ワンタイムパス送信
        new_password: パスワード変更
        completed: メアド・パスワード変更完了
    */
    situation: "",
    /*
        email_address: メアド変更
        password: パスワード変更
    */
    forget_password: false,
    shop_user_name: "",
    onetime:"",
    password: "",
    password2: "",
    email_address: "",
    new_email_address: "",
    shop_id: "",
    updated_at: "",
    error_message: "",
    open_token_error_dialog: false,
    open_error_dialog: false,
    open_old_error: false,
    onetime_error: false,
    disabled_mail_button: false,
    disabled_onetime_button: false,
    disabled_pass_button: false,
};

export const SendMail = createAsyncThunk(
    "ShopUserInfoChange/SendMail",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const thisState = thunkAPI.getState().ShopUserInfoChange;
        const url = thisState.forget_password
            ? "/api/send_forget_password_mail_shop"
            : "/api/shop_send_onetime_mail_edit";
        let api_key = thunkAPI.getState().Information.shop.shop_token;
        

        if (!api_key) api_key = "";
        HttpFunction("POST", url, params, api_key)
            .then((data) => {
                // console.log(data);
                dispatch(
                    shopuser_info_set(["error_message", data.error_message])
                );
                if (data.result === "OK") {
                    dispatch(
                        shopuser_info_set(["updated_at", data.updated_at])
                    );
                    dispatch(shopuser_info_set(["fix_type", "onetime"]));
                } else if (data === 500) {
                    dispatch(shopuser_info_set(["open_error_dialog", true]));
                } else if (data.error_message && data.error_message.startsWith("ログイン情報の取得ができませんでした")) {
                    dispatch(shopuser_info_set(["open_token_error_dialog", true]));
                }
                dispatch(shopuser_info_set(["disabled_mail_button", false]));
            })
            .catch((err) => {
                // console.log(err);
                dispatch(shopuser_info_set(["open_error_dialog", true]));
                dispatch(shopuser_info_set(["disabled_mail_button", false]));
            });
    }
);

export const SendOnetime = createAsyncThunk(
    "ShopUserInfoChange/SendOnetime",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const thisState = thunkAPI.getState().ShopUserInfoChange;
        const url = thisState.forget_password
            ? "/api/user_onetime_check_password_shop"
            : "/api/shop_user_onetime_check_mail_password";
        let api_key = thunkAPI.getState().Information.shop.shop_token;
        
        if (!api_key) api_key = "";
        HttpFunction("POST", url, params, api_key)
            .then((data) => {
                // console.log(data);
                if (data.result === "OK") {
                    dispatch(shopuser_info_set(["onetime_error", false]));
                    dispatch(shopuser_info_set(["fix_type", "new_password"]));
                } else if (data.result === "NG") {
                    dispatch(
                        shopuser_info_set(["login_failed", data.login_failed])
                    );
                    dispatch(shopuser_info_set(["onetime_error", true]));
                } else if (data === 500) {
                    dispatch(shopuser_info_set(["open_error_dialog", true]));
                } else if (data.error_message && data.error_message.startsWith("ログイン情報の取得ができませんでした")) {
                    dispatch(shopuser_info_set(["open_token_error_dialog", true]));
                }
                dispatch(shopuser_info_set(["disabled_onetime_button", false]));
            })
            .catch((err) => {
                // console.log(err);
                dispatch(shopuser_info_set(["open_error_dialog", true]));
                dispatch(shopuser_info_set(["disabled_onetime_button", false]));
            });
    }
);

export const SendPass = createAsyncThunk(
    "ShopUserInfoChange/SendPass",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const thisState = thunkAPI.getState().ShopUserInfoChange;
        const url = thisState.forget_password
            ? "/api/update_password_shop"
            : "/api/shop_update_mail_password";
        let api_key = thunkAPI.getState().Information.shop.shop_token;

        if (!api_key) api_key = "";
        HttpFunction("POST", url, params, api_key)
            .then((data) => {
                // console.log(data);
                if (data.result === "OK") {
                    dispatch(
                        set_value([
                            "shop_email_address",
                            data.email_address,
                            "shop",
                        ])
                    );
                    dispatch(
                        set_value(["shop_token", data.shop_token, "shop"])
                    );
                    dispatch(shopuser_info_set(["fix_type", "completed"]));
                } else if (data.error_message && data.error_message.startsWith("ログイン情報の取得ができませんでした")) {
                    dispatch(shopuser_info_set(["open_token_error_dialog", true]));
                } else if (data.error_message) {
                    dispatch(shopuser_info_set(["open_old_error", true]));
                } else if (data === 500) {
                    dispatch(shopuser_info_set(["open_error_dialog", true]));
                }
                dispatch(shopuser_info_set(["disabled_pass_button", false]));
            })
            .catch((err) => {
                // console.log(err);
                dispatch(shopuser_info_set(["open_error_dialog", true]));
                dispatch(shopuser_info_set(["disabled_pass_button", false]));

            });
    }
);

const isRejectedAction = (action) => {
    return action.type.startsWith("ShopUserInfoChange") && action.type.endsWith("rejected");
};
const isRejectedSendMail = (action) => {
    return action.type === "ShopUserInfoChange/SendMail/rejected";
};
const isRejectedSendOnetime = (action) => {
    return action.type === "ShopUserInfoChange/SendOnetime/rejected";
};
const isRejectedSendPass = (action) => {
    return action.type === "ShopUserInfoChange/SendPass/rejected";
};

// Sliceを生成する
const slice = createSlice({
    name: "ShopUserInfoChange",
    initialState,
    reducers: {
        shopuser_info_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
        initialize: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addMatcher(isRejectedSendMail, (state, action) => {
            state["disabled_mail_button"] = false;
        });
        builder.addMatcher(isRejectedSendOnetime, (state, action) => {
            state["disabled_onetime_button"] = false;
        });
        builder.addMatcher(isRejectedSendPass, (state, action) => {
            state["disabled_pass_button"] = false;
        });
        builder.addMatcher(isRejectedAction, (state, action) => {
            state["open_error_dialog"] = true;
        });
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { shopuser_info_set, initialize } = slice.actions;
