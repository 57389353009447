import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Grid from "@material-ui/core/Grid";
import {
    ButtonContainedPrimary,
    ButtonOutlinedPrimary,
} from "../utils/Buttons";
import HistoryTable from "../modules/HistoryTable";
import TokenErrorDialog from "../utils/TokenErrorDialog";
import {
    set_noticelist_value,
    GetNoticeList,
} from "../../stores/AdminNoticeList";
import {
    set_Initial,
} from "../../stores/AdminNotice";
import { initialize_table } from "../../stores/HistoryTable";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ButtonNavi } from "~CJS/block/ButtonNavi";

const formatPeriod = (params) => {
    const start = params.row.release_start_date.substr(2, 8).replace(/-/g, "/");
    const end = params.row.release_end_date.startsWith("9999")
        ? ""
        : params.row.release_end_date.substr(2, 8).replace(/-/g, "/");

    return `${start}~${end}`;
};

const AdminNoticeList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.AdminNoticeList);
    const tableState = useSelector((state) => state.HistoryTable);

    const columns = [
        {
            field: "release_period",
            headerName: "公開期間",
            valueGetter: formatPeriod,
            width: 150,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "control_kubun",
            headerName: "対象",
            valueFormatter: (params) => {
                switch (params.value) {
                    case "2":
                        return "fifty会員";
                    case "3":
                        return "加盟店会員";
                    case "5":
                        return "どちらとも";
                    default:
                        return "その他";
                }
            },
            width: 100,
            align: "center",
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "notification_title",
            headerName: "タイトル",
            flex: 1,
            minWidth: 120,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "notification_detail",
            headerName: "内容",
            flex: 1,
            minWidth: 120,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "notification_category",
            headerName: "タイプ",
            valueFormatter: (params) => {
                switch (params.value) {
                    case "1":
                        return "一覧";
                    case "2":
                        return "POP";
                    case "3":
                        return "既読制御POP";
                    default:
                        return "その他";
                }
            },
            width: 108,
            align: "center",
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "edit",
            headerName: "編集",
            renderCell: (params) => (
                <ButtonContainedPrimary onClick={() => handleToEditing(params)}>
                    編集
                </ButtonContainedPrimary>
            ),
            width: 80,
            align: "center",
            headerAlign: "center",
            sortable: false,
        },
    ];

    const handleToEditing = (params) => {
        dispatch(set_Initial());
        history.push(`/AdminNotice/${params.row.notification_id}`);
    };

    const createNewNotice = () => {
        dispatch(set_Initial());
        history.push("/AdminNotice/undefined");
    };

    const getNoticeList = (page) => {
        const params = { page: page };

        dispatch(GetNoticeList(params));
    };

    const handleClickOK = () => {
        dispatch(set_noticelist_value(["open_token_error_dialog", false]));
    };

    useEffect(() => {
        if (history.location.search === "?mode=visit") {
            dispatch(initialize_table());
        }
    }, []);

    useEffect(() => {
        getNoticeList(tableState.page);
    }, [tableState.page]);

    return (
        <Container>
              <ButtonNavi left_label="Home画面" left_link="/AdminHome" />
            <h1>通知設定</h1>
            {thisState.show_alert && (
                <Alert severity="error">
                    <AlertTitle>{thisState.error_title}</AlertTitle>
                    {thisState.error_message}
                </Alert>
            )}
            <Grid container>
                <Grid item style={{ margin: "10px" }}>
                    <ButtonOutlinedPrimary onClick={createNewNotice}>
                        新規作成
                    </ButtonOutlinedPrimary>
                </Grid>
            </Grid>
            <HistoryTable
                rows={thisState.notice_list}
                columns={columns}
                recordsPerPageOptions={[tableState.per_page]}
                getRowId={(row) => row.notification_id}
                needsToolbar={false}
                disableColumnMenu={true}
            />
            <TokenErrorDialog
                open={thisState.open_token_error_dialog}
                admin={true}
                onClickOK={handleClickOK}
            />
        </Container>
    );
};

export default AdminNoticeList;
