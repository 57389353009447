import { Http } from "@material-ui/icons";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { useCookies } from "react-cookie";
import HttpFunction from "../components/utils/HttpFunction";
// Stateの初期状態
const initialState = {
    user: {
        email_address: "",
        nickname: "",
        user_id: "",
        password: "",
        token: process.env.MIX_USER_TOKEN ? process.env.MIX_USER_TOKEN : "",
        user_login: process.env.MIX_USER_TOKEN ? true : false,
        prefectures: "",
        raw_year_month: "",
        sex: "",
        invitation_cd: "",
        point_sum: 0,
        rank_type: "5",
        rank_color_name: "ホワイト",
        rank_color_cd: "ffffff",
        error_message: "",
    },
    shop: {
        shop_email_address: "",
        shop_user_name: "",
        shop_user_id: "",
        shop_password: "",
        shop_id: "",
        shop_position: "",
        shop_token: process.env.MIX_SHOP_TOKEN
            ? process.env.MIX_SHOP_TOKEN
            : "",
        shop_login: process.env.MIX_SHOP_TOKEN ? true : false,
    },
    admin: {
        admin_email_address: "",
        admin_nickname: "",
        admin_password: "",
        admin_id: "",
        admin_token: process.env.MIX_ADMIN_TOKEN
            ? process.env.MIX_ADMIN_TOKEN
            : "",
        admin_login: process.env.MIX_ADMIN_TOKEN ? true : false,
    },
    count: 0,
    showFirstNavi: true,
    showDrawer: true,
    showBottomNavigation: true,
};

//localStorageに保存したログイントークンを取得
export function getLocalStorage(id) {
    const value = localStorage.getItem(id);
    if (value !== null) {
        return value;
    }
    return "";
}
//localStorageにログイントークンを保存
export function saveLocalStorage(state, id) {
    if (state) {
        localStorage.setItem(id, state);
    }
}
function resolveAfter2Seconds() {
    return new Promise((resolve) => {
        const login_token = getLocalStorage("localStorage_login_token");
        const type = getLocalStorage("localStorage_login_type");
        resolve({
            type: type,
            login_token: login_token,
        });
    });
}

export const GetLoginToken = createAsyncThunk(
    "共通/ログイントークンの取得",
    async (arg, thunkAPI) => {
        try {
            const response = await resolveAfter2Seconds();

            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const UserInfoGet = createAsyncThunk(
    "Information/UserInfoGet",
    async (arg, thunkAPI) => {
        const api_key = arg;
        try {
            const data = await HttpFunction(
                "GET",
                "/api/general_user_information",
                "",
                api_key
            );
            for (let key in data) {
            }
            let stateActions = null;

            if (data.length !== 0) {
                if (data.error_message) {
                    stateActions = {
                        error_message: data.error_message,
                    };
                } else {
                    stateActions = {
                        user_id: data.user_id,
                        email_address: data.email_address,
                        sex: data.sex,
                        raw_year_month: data.raw_year_month,
                        prefectures: data.prefectures,
                        nickname: data.nickname,
                        paying_expiration_date: data.paying_expiration_date,
                        invitation_cd: data.invitation_cd,
                        point_sum: data.point_sum,
                        updated_at: data.updated_at,
                        rank_type: data.user_rank_type,
                        rank_color_name: data.user_rank_color_name,
                        rank_color_cd: data.user_rank_color_cd,
                        token: api_key,
                    };
                }
            }

            return { data: stateActions };
        } catch (err) {
            // console.log("err:" + err);
            const error = {
                error_message: err.toString(),
            };
            return { data: error };
        }
    }
);

export const UserInfoUpdate = createAsyncThunk(
    "Information/UserInfoUpdate",
    async (arg, thunkAPI) => {
        const api_key = arg.api_key;
        const params = arg.params;
        // APIエラーを起こす時は以下を使う

        try {
            const data = await HttpFunction(
                "PUT",
                "/api/general_user_information",
                params,
                api_key
            );
            for (let key in data) {
                // console.log("data:" + key + " value:" + data[key]);
            }
            let stateActions = null;
            if (data.error_message) {
                stateActions = {
                    error_message: data.error_message,
                };
            } else {
            }

            return { data: stateActions };
        } catch (err) {
            const error = {
                error_message: err.toString(),
            };
            return { data: error };
        }
    }
);

export const ShopUserInfoGet = createAsyncThunk(
    "Information/ShopUserInfoGet",
    async (arg, thunkAPI) => {
        // console.log("==========infomation--ShopUserInfoGet==========");
        const dispatch = thunkAPI.dispatch;
        const api_key = arg;
        // console.log("api_key:" + api_key);
        try {
            const data = await HttpFunction(
                "GET",
                "/api/shop_user_information",
                "",
                api_key
            );
            let stateActions = null;
            if (data.error_message) {
                stateActions = {
                    error_message: data.error_message,
                };
            } else {
                stateActions = {
                    shop_token: api_key,
                    shop_id: data.shop_id,
                    shop_user_id: data.user_id,
                    shop_user_name: data.nickname,
                    shop_email_address: data.email_ad1dress,
                    shop_updated_at: data.updated_at,
                    shop_login: true,
                };
            }
            return { data: stateActions };
        } catch (err) {
            // console.log("err:" + err);
            const error = {
                error_message: err.toString(),
            };
            return { data: error };
        }
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "Information",
    initialState,
    reducers: {
        set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];

            if (type) {
                state[name] = value;
                let new_type = state[type];
                new_type[name] = value;
                state[type] = new_type;
            } else {
                state[name] = value;
            }
        },
        logout: (state, action) => {
            const name = action.payload[0];
            state[name] = initialState[name];
        },
        admin_logout: (state, action) => {
            localStorage.removeItem("localStorage_login_type");
            localStorage.removeItem("localStorage_login_token");
            state.admin.admin_login = false;
            state.admin.admin_token = "";
        },
    },
    extraReducers: {
        [UserInfoGet.fulfilled]: (state, action) => {
            for (let key in action.payload.data) {
                state.user[key] = action.payload.data[key];
            }
        },
        [UserInfoGet.rejected]: (state, action) => {
            // console.log("rejected:ネットワークエラー");
            state.user.error_message = "ネットワークエラー";
            // console.log("state.user.error_message:" + state.user.error_message);
        },
        [UserInfoUpdate.fulfilled]: (state, action) => {
            // console.log("処理完了");
            for (let actions in action) {
                // console.log("actions:" + actions + " value:" + action[actions]);
            }
            if (action.payload.data) {
                for (let key in action.payload.data) {
                    state.user[key] = action.payload.data[key];
                }
            }
        },
        [UserInfoUpdate.rejected]: (state, action) => {
            // console.log("rejected:ネットワークエラー");
            state.user.error_message = "ネットワークエラー";
            // console.log("state.user.error_message:" + state.user.error_message);
        },
        [ShopUserInfoGet.fulfilled]: (state, action) => {
            // console.log("処理完了");
            // console.log(action.payload.data);
            for (let key in action.payload.data) {
                state.shop[key] = action.payload.data[key];
            }
        },
        [ShopUserInfoGet.rejected]: (state, action) => {
            // console.log("rejected:ネットワークエラー");
            state.shop.error_message = "ネットワークエラー";
            // console.log("state.shop.error_message:" + state.shop.error_message);
        },
        [GetLoginToken.fulfilled]: (state, action) => {
            const payload = action.payload;

            if (payload.login_token) {
                const type = payload.type;

                switch (type) {
                    case "admin":
                        state.admin.admin_token = payload.login_token;
                        state.admin.admin_login = true;
                        break;

                    default:
                        break;
                }
            }
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_value, logout, admin_logout } = slice.actions;
