const ShopStoreApplicationNullCheck = (input_list, releaseInfoState) => {
    const null_list = Object.keys(input_list).reduce((accumulator ,current_value) => {
        if(!releaseInfoState[current_value]){
            accumulator.push(" " + input_list[current_value] + " ");
        }
        return accumulator;
      },[])  
    return null_list;
}

export default ShopStoreApplicationNullCheck;