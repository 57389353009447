import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
// 加盟店編集
import { Input, Button, Grid, Container, TextField } from "@material-ui/core/";

import {
    set_snap,
    DeleteSnap,
    AdminGetSnap,
    ApprovalSnap,
    DenialSnap,
    AdminUploadSnap,
} from "../../stores/ShopSnapPosting";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useSelector, useDispatch } from "react-redux";
import { set_alert } from "../../stores/ErrorAlert";
import { useHistory } from "react-router-dom";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import ModalGeneral from "../utils/ModalGeneral";
import InputImagePreview from "../utils/InputImagePreview";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";

import ClearIcon from "@material-ui/icons/Clear";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    input_file: {
        opacity: "0",
        appearance: "none",
        position: "absolute",
    },

    sub_title_button: {
        fintSize: "1vw",
        width: "30px",
    },
    ImageListItemBar: {
        height: "1.5rem",
    },
}));

const AdminSnap = () => {
    const snapState = useSelector((state) => state.ShopSnapPosting);
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const path = history.location.pathname.split("/");
    const shop_id = path[2];
    const OnDelete = (index) => {
        dispatch(DeleteSnap(snapState.image_list[index]));
    };

    const OpenInputDenialMessage = () => {
        const flg = snapState.open_InputDenial ? false : true;
        dispatch(set_snap(["open_InputDenial", flg]));
    };
    const CloseInputDenialMessage = () => {
        dispatch(set_snap(["open_InputDenial", false]));
    };

    const OnApproval = (index) => {
        dispatch(ApprovalSnap(snapState.image_list[index]));
        CloseModal();
    };
    const OnDenial = (index) => {
        dispatch(
            DenialSnap({
                ...snapState.image_list[index],
                denial_reason: snapState.denial_reason,
            })
        );

        CloseModal();
        CloseInputDenialMessage();
    };

    const Back = () => {
        history.push("/AdminShopList");
    };

    useEffect(() => {
        dispatch(AdminGetSnap({ shop_id: shop_id }));
    }, []);

    useEffect(() => {
        dispatch(set_alert(snapState.error_message));
    }, [snapState.error_message]);

    const OpenModal = (index) => {
        dispatch(set_snap(["open_modal_index", index]));
    };
    const CloseModal = () => {
        dispatch(set_snap(["open_modal_index", "false"]));
    };

    const AddSnap = (arg) => {
        const image = arg.image;
        const image_comment = arg.comment;

        dispatch(AdminUploadSnap({ image: image, image_comment: image_comment, shop_id: shop_id }));

        dispatch(set_snap(["image_comment", ""]));
    };

    const index = snapState.open_modal_index;

    return (
        <React.Fragment>
            <ChevronLeftIcon onClick={Back} />
            <div>
                <h2>スナップ投稿</h2>
            </div>

            <InputImagePreview comment={true} onClick={AddSnap} />

            <Modal open={snapState.progress}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: "100%" }}
                >
                    <CircularProgress size="5rem" />
                </Grid>
            </Modal>

            <div>
                <h2>スナップ申請一覧</h2>
            </div>

            <ModalGeneral
                open={snapState.open_modal_index === "false" ? false : true}
                onClose={CloseModal}
                style={{ height: "100%" }}
            >
                {snapState.open_modal_index === "false" ? (
                    ""
                ) : (
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ height: "100%" }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                        >
                            <img
                                style={{
                                    width: "100%",
                                    maxWidth: "400px",
                                    //maxHeight: "300px",
                                    maxHeight: "400px",
                                }}
                                src={snapState.image_list[index].image_path}
                                alt={snapState.image_list[index].image_comment}
                                onError={(e) => {
                                    e.target.onerror = "null";
                                    e.target.src = "/images/no_img.png";
                                }}
                            />
                        </Grid>
                        {snapState.image_list[index].image_comment}

                        <React.Fragment>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="flex-end"
                            >
                                <Button
                                    component="label"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => OnApproval(index)}
                                >
                                    承認
                                </Button>
                                <Button
                                    component="label"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => OpenInputDenialMessage()}
                                >
                                    否認
                                </Button>
                            </Grid>
                        </React.Fragment>

                        <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="flex-end"
                        >
                            {snapState.open_InputDenial ? (
                                <InputDenialMessage
                                    index={index}
                                    snapState={snapState}
                                    set_snap={set_snap}
                                    dispatch={dispatch}
                                    OnDenial={OnDenial}
                                />
                            ) : (
                                <div style={{ height: "7rem" }}></div>
                            )}
                        </Grid>
                    </Grid>
                )}
            </ModalGeneral>

            <Container>
                <ImageList
                    rowHeight={120}
                    gap={20}
                    style={{
                        padding: "0.5rem",
                    }}
                >
                    {snapState.image_list.map((item, index) => (
                        <ImageListItem
                            key={index}
                            style={{
                                boxShadow: "0px 0px 0.2rem 0px rgba(0,0,0,0.6)",
                                borderRadius: "0.5rem",
                                textAlign: "center",
                            }}
                        >
                            <img
                                src={item.image_path}
                                alt={item.image_comment}
                                onClick={() => OpenModal(index)}
                                onError={(e) => {
                                    e.target.onerror = "null";
                                    e.target.src = "/images/no_img.png";
                                }}
                                style={{width: "auto", height: "100%"}}
                            />
                            <ImageListItemBar
                                className={classes.ImageListItemBar}
                                position={"top"}
                                title={item.image_comment}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Container>
        </React.Fragment>
    );
};

const InputDenialMessage = (props) => {
    const snapState = props.snapState;
    const index = props.index;
    const set_snap = props.set_snap;
    const dispatch = props.dispatch;
    const OnDenial = props.OnDenial;

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                style={{ margin: "1rem 2rem 2rem 2rem", height: "4rem" }}
            >
                否認理由
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Grid container item xs={9}>
                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            size="small"
                            defaultValue={snapState.denial_reason}
                            onBlur={(e) =>
                                dispatch(
                                    set_snap(["denial_reason", e.target.value])
                                )
                            }
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                    <Grid
                        container
                        item
                        xs={3}
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Button
                            component="label"
                            variant="contained"
                            color="secondary"
                            onClick={() => OnDenial(index)}
                        >
                            否認
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default AdminSnap;
