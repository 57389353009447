
import React from "react";
import ReactDOM from "react-dom";

import { createStore } from "redux";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";

import App from "./App";
import store from "../stores/";
import { BrowserRouter } from "react-router-dom";

// ⑤
ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <CookiesProvider>
                <App />
            </CookiesProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById("app")
);
