import React, { useEffect } from "react";
import {
    set_entry_value,
    initialize,
    CreateUserID,
    PayingUpdate,
    CreateGeneralUser,
} from "../../stores/Entry";
import {
    set_value,
    UserInfoGet,
    UserInfoUpdate,
} from "../../stores/Information";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SelectPrefectures from "../utils/SelectPrefectures";
import SelectSex from "../utils/SelectSex";
import SelectRawYearMonth from "../utils/SelectRawYearMonth";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {
    StopButton,
    MailInput,
    OneTimeInput,
    PolicyAgreement,
    EntryComplete,
} from "../modules/Entry";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Grid from "@material-ui/core/Grid";
import HttpFunction from "../utils/HttpFunction";
import Error500Handling from "../utils/Error500Handling";
import { makeStyles } from "@material-ui/core";
import {
    TextFieldNormal,
    TextFieldReadOnly,
    TextFieldValidation,
    errorJudge,
} from "../utils/TextFields";
import { ButtonContainedPrimary, ButtonTextPrimary } from "../utils/Buttons";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { FlutterInappwebview } from "../utils/FlutterInappwebview";
import { progress_set } from "../../stores/TALENTBANK/Progress";

const EntryMembers = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.Entry);
    const ProgressState = useSelector((state) => state.Progress);

    const useStyles = makeStyles((theme) => ({
        stepper: {
            padding: "24px 5px",
        },
        step: {
            padding: 0,
        },
        button: {
            display: "block",
            margin: "auto",
        },
        stop_dialog: {
            "& .MuiDialog-paper": {
                padding: "15px",
            },
        },
    }));
    const noflutter_flag = typeof flutter === "undefined" ? true : false;

    // const noflutter_flag = false;
    // dispatch(set_entry_value(["email_flag", false, "user"]));
    // dispatch(set_entry_value(["subscription_flag", true, "user"]));
    // dispatch(set_entry_value(["email", "TEST00000132@digitalsolutions.co.jp"]));
    // const dev_flag = true;
    const dev_flag = false;
    const [devConsole, setDevConsole] = React.useState([]);
    const consoleSet = (arg) => {
        setDevConsole([...devConsole, arg]);
    };

    // ステッパー
    const EntryMemberStepper = () => {
        const classes = useStyles();

        useEffect(() => {
            if (thisState.user.email_flag || thisState.user.onetime_flag) {
                dispatch(set_entry_value(["activeStep", 0, "user"]));
                return;
            }

            if (thisState.user.policy_flag) {
                dispatch(set_entry_value(["activeStep", 1, "user"]));
                return;
            }
            if (thisState.user.subscription_flag) {
                dispatch(set_entry_value(["activeStep", 2, "user"]));
                return;
            }

            if (thisState.user.fin_flag) {
                dispatch(set_entry_value(["activeStep", 4, "user"]));
                return;
            }

            if (thisState.user.input_flag || thisState.user.check_flag) {
                dispatch(set_entry_value(["activeStep", 3, "user"]));
                return;
            }
        });

        return (
            <Stepper
                activeStep={thisState.user.activeStep}
                alternativeLabel
                className={classes.stepper}
            >
                <Step key="confirm-email" className={classes.step}>
                    <StepLabel>
                        アドレス
                        <br />
                        認証
                    </StepLabel>
                </Step>
                <Step key="agree-policy" className={classes.step}>
                    <StepLabel>利用規約</StepLabel>
                </Step>
                <Step key="subscription" className={classes.step}>
                    <StepLabel>
                        サブスク
                        <br />
                        購入
                    </StepLabel>
                </Step>
                <Step key="fill-userinfo" className={classes.step}>
                    <StepLabel>情報入力</StepLabel>
                </Step>
                <Step key="completed" className={classes.step}>
                    <StepLabel>登録完了</StepLabel>
                </Step>
            </Stepper>
        );
    };

    // 会員情報入力画面
    const InfoInput = () => {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);
        const [info, setInfo] = React.useState({
            nickname: "",
            birth_year_month: "",
            sex: "2",
            prefecture: "",
            password: "",
            password2: "",
            invitation_code: "",
        });
        const [error, setError] = React.useState({
            nickname: null,
            birth_year_month: null,
            password: null,
            password2: null,
        });
        const [showPass, setShowPass] = React.useState(false);

        const handleSetInfo = (e) => {
            setInfo({ ...info, [e.target.id]: e.target.value });
            // if (e.target.id === "birth_year_month") {
            //     const empty = e.target.value === "" ? true : false;
            //     setError({ ...error, birth_year_month: empty });
            // }
        };
        const handleChangePassword = (compare) => (e) => {
            const diff = e.target.value !== info[compare];
            setError({ ...error, ["password2"]: diff });
        };
        const handleChangeShowPass = (e) => {
            setShowPass(!showPass);
        };
        const handleGoCheck = () => {
            dispatch(set_entry_value(["info", info, "user"]));
            dispatch(set_entry_value(["error", error, "user"]));
            dispatch(set_entry_value(["input_flag", false, "user"]));
            dispatch(set_entry_value(["check_flag", true, "user"]));
        };
        const trueError = (name) => {
            setError({ ...error, [name]: true });
        };
        const falseError = (name) => {
            setError({ ...error, [name]: false });
        };

        useEffect(() => {
            setInfo(thisState.user.info);
            setError(thisState.user.error);
        }, [thisState.user.input_flag]);

        return (
            <div>
                <TextFieldValidation
                    label="ニックネーム"
                    id="nickname"
                    defaultValue={thisState.user.info.nickname}
                    onBlur={handleSetInfo}
                    trueError={() => trueError("nickname")}
                    falseError={() => falseError("nickname")}
                    inputProps={{ maxLength: 255 }}
                />
                <SelectRawYearMonth
                    id="birth_year_month"
                    onChange={handleSetInfo}
                    defaultValue={thisState.user.info.birth_year_month}
                    error={error.birth_year_month}
                    helperText={error.birth_year_month && "選択してください。"}
                    style={{ marginBottom: "25px" }}
                    variant="outlined"
                    size="small"
                />
                <SelectSex
                    id="sex"
                    onChange={handleSetInfo}
                    defaultValue={thisState.user.info.sex}
                    style={{ marginBottom: "25px" }}
                    variant="outlined"
                    size="small"
                />
                <SelectPrefectures
                    id="prefecture"
                    onChange={handleSetInfo}
                    defaultValue={thisState.user.info.prefecture}
                    helperText="店舗検索の際、検索条件の初期値に設定されます。"
                    style={{ marginBottom: "25px" }}
                    variant="outlined"
                    size="small"
                    required
                />
                <TextFieldReadOnly
                    id="email"
                    label="メールアドレス"
                    value={thisState.email}
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 255 }}
                />
                <TextFieldValidation
                    label="パスワード"
                    discription="半角英数字7文字以上で設定してください。アルファベットは大文字と小文字を区別します。"
                    id="password"
                    defaultValue={thisState.user.info.password}
                    inputProps={{
                        minLength: 7,
                        maxLength: 255,
                        pattern: "^[a-zA-Z0-9]*$",
                    }}
                    type={showPass ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleChangeShowPass}
                                >
                                    {showPass ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={handleChangePassword("password2")}
                    onBlur={handleSetInfo}
                    trueError={() => trueError("password")}
                    falseError={() => falseError("password")}
                />
                <TextFieldNormal
                    id="password2"
                    label="パスワード（確認）*"
                    type={showPass ? "text" : "password"}
                    defaultValue={thisState.user.info.password2}
                    error={error.password2}
                    helperText={
                        error.password2 &&
                        "上で入力したパスワードと一致させてください。"
                    }
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleChangeShowPass}
                                >
                                    {showPass ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    inputProps={{
                        minLength: 7,
                        maxLength: 255,
                        pattern: "^[a-zA-Z0-9]*$",
                    }}
                    onChange={handleChangePassword("password")}
                    onBlur={handleSetInfo}
                />

                <TextFieldNormal
                    id="what_look"
                    label="何を見て知りましたか？"
                    defaultValue={thisState.user.info.what_look}
                    onBlur={handleSetInfo}
                    // inputProps={{ maxLength: 7 }}
                />
                <TextFieldNormal
                    id="invitation_code"
                    label="招待コード"
                    defaultValue={thisState.user.info.invitation_code}
                    onBlur={handleSetInfo}
                    inputProps={{ maxLength: 7 }}
                />

                <div className="text-right">
                    <ButtonContainedPrimary
                        disabled={errorJudge(error)}
                        onClick={handleGoCheck}
                    >
                        確認へ進む
                    </ButtonContainedPrimary>
                </div>
            </div>
        );
    };

    // 入力情報確認画面
    const InfoCheck = () => {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);
        let sex;
        switch (thisState.user.info.sex) {
            case "1":
                sex = "男性";
                break;
            case "2":
                sex = "女性";
                break;
            case "3":
                sex = "その他";
                break;
        }
        const [open500, setOpen500] = React.useState(false);

        const handleBack = () => {
            dispatch(set_entry_value(["check_flag", false, "user"]));
            dispatch(set_entry_value(["input_flag", true, "user"]));
        };

        const handleRegister = () => {
            dispatch(CreateGeneralUser());

            // const params = {
            //     nickname: thisState.user.info.nickname,
            //     raw_year_month: thisState.user.info.birth_year_month.replace(
            //         "-",
            //         "/"
            //     ),
            //     sex: thisState.user.info.sex,
            //     prefectures: thisState.user.info.prefecture,
            //     email_address: thisState.email,
            //     password: thisState.user.info.password,
            // };
            // HttpFunction("POST", "/api/create_user", params, "")
            //     .then((data) => {
            //         if (data.result === "OK") {
            //             dispatch(
            //                 set_entry_value(["user_id", data.user_id, "user"])
            //             );
            //             dispatch(
            //                 set_entry_value(["check_flag", false, "user"])
            //             );
            //             dispatch(
            //                 set_entry_value(["subscription_flag", true, "user"])
            //             );
            //         } else if (data.error_message) {
            //             dispatch(
            //                 set_entry_value([
            //                     "error_message",
            //                     data.error_message,
            //                 ])
            //             );
            //         } else {
            //             setOpen500(true);
            //         }
            //     })
            //     .catch((err) => {
            //         // console.log("err: " + err);
            //         setOpen500(true);
            //     });
        };

        const handleClose500 = () => {
            setOpen500(false);
        };

        return (
            <>
                {thisState.error_message && (
                    <Alert severity="error">
                        <AlertTitle>エラー</AlertTitle>
                        {thisState.error_message}
                    </Alert>
                )}
                <TextFieldReadOnly
                    id="nickname-check"
                    label="ニックネーム"
                    value={thisState.user.info.nickname}
                />
                <SelectRawYearMonth
                    value={thisState.user.info.birth_year_month}
                    InputProps={{ readOnly: true }}
                    noInput
                />
                <TextFieldReadOnly id="sex-check" label="性別" value={sex} />
                <TextFieldReadOnly
                    id="prefecture-check"
                    label="都道府県"
                    value={thisState.user.info.prefecture}
                />
                <TextFieldReadOnly
                    id="email-check"
                    label="メールアドレス"
                    value={thisState.email}
                />
                <TextFieldReadOnly
                    id="password-check"
                    label="パスワード"
                    value={thisState.user.info.password}
                    type="password"
                />

                <TextFieldReadOnly
                    id="what-look"
                    label="何を見て知りましたか？"
                    value={thisState.user.info.what_look}
                />
                <TextFieldReadOnly
                    id="invitation-code-check"
                    label="招待コード"
                    value={thisState.user.info.invitation_code}
                />
                <Grid container style={{ textAlign: "center" }}>
                    <Grid item xs={6}>
                        <Button variant="contained" onClick={handleBack}>
                            修正する
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonContainedPrimary onClick={handleRegister}>
                            登録する
                        </ButtonContainedPrimary>
                    </Grid>
                </Grid>
                <Error500Handling open={open500} onClose={handleClose500} />
            </>
        );
    };

    const [successOpen, setSuccessOpen] = React.useState(false);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [token, setUserToken] = React.useState("");

    const Subscription = () => {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        const classes = useStyles();

        useEffect(() => {
            if (!thisState.successOpen) return;
        }, [thisState.successOpen]);

        useEffect(() => {
            if (thisState.error_message == "") return;
            setMessage(thisState.error_message);
            setErrorOpen(true);
        }, [thisState.error_message]);

        const handleClose = () => {
            dispatch(set_entry_value(["token", token, "user"]));
            dispatch(set_entry_value(["subscription_flag", false, "user"]));
            dispatch(set_entry_value(["fin_flag", true, "user"]));
            setSuccessOpen(false);
        };

        const errorHandleClose = () => {
            setErrorOpen(false);
            dispatch(set_entry_value(["error_message", ""]));
        };

        const loadingErrorToggle = (message) => {
            setMessage(message);
            setErrorOpen(true);
        };

        // サブスク購入ボタン処理
        const AppPurchase = () => {
            if (noflutter_flag) {
                loadingErrorToggle(
                    "サブスクリプション購入はアプリからの登録のみです。フィフティーカードアプリをご利用ください。"
                );
            } else {
                dispatch(CreateUserID()).then((result_data) => {
                    consoleSet({ CreateUserID: result_data });
                    if (result_data.payload.paying == 1) {
                        dispatch(
                            set_entry_value([
                                "subscription_flag",
                                false,
                                "user",
                            ])
                        );

                        dispatch(set_entry_value(["input_flag", true, "user"]));
                    } else if (result_data.payload.status == 2) {
                        loadingErrorToggle(result_data.payload.error_message);
                        return;
                    }

                    dispatch(
                        FlutterInappwebview({
                            type_name: "Flutter/課金処理",
                            handlerName: "AppPurchase",
                            params: result_data.payload.user_id,
                        })
                    )
                        .then((res) => {
                            consoleSet({ 課金処理res: res });
                            const result = res.payload;

                            // window.flutter_inappwebview
                            //     .callHandler("AppPurchase", result_data.payload.user_id)
                            //     .then((result) => {
                            //         consoleSet({ 課金処理result: result });
                            if (result["result"] === "success") {
                                dispatch(PayingUpdate())
                                    .then(() => {
                                        loadingErrorToggle(
                                            "処理が完了しました。"
                                        );
                                        dispatch(
                                            set_entry_value([
                                                "subscription_flag",
                                                false,
                                                "user",
                                            ])
                                        );
                                        dispatch(
                                            set_entry_value([
                                                "input_flag",
                                                true,
                                                "user",
                                            ])
                                        );
                                    })
                                    .catch((error) => {
                                        loadingErrorToggle(
                                            "処理が失敗しました。お手数ですが、再度お試しください。"
                                        );
                                    });
                            } else {
                                loadingErrorToggle(result["message"]);
                            }
                        })
                        .catch((error) => {
                            loadingErrorToggle(
                                "処理中にエラーが発生しました。お手数ですが再度、お試しください。"
                            );
                        });
                });
            }
        };

        // 復元ボタン処理
        const AppRestore = () => {
            if (noflutter_flag) {
                setMessage(
                    "サブスクリプション登録はアプリからの登録のみです。フィフティーカードアプリをご利用ください。"
                );
                setErrorOpen(true);
            } else {
                dispatch(CreateUserID())
                    .then((result_data) => {
                        if (
                            result_data.payload.paying != 1 &&
                            !result_data.payload.user_id
                        ) {
                            loadingErrorToggle(
                                "復元するデータが見つかりませんでした。"
                            );
                            return;
                        }
                        consoleSet({ CreateUserID: result_data });

                        dispatch(
                            FlutterInappwebview({
                                type_name: "Flutter/リストア処理",
                                handlerName: "SubscriptionRestore",
                                params: result_data.payload.user_id,
                            })
                        )
                            .then((res) => {
                                consoleSet({ リストア処理res: res });
                                const result = res.payload;
                                if (result["result"] === "success") {
                                    dispatch(
                                        set_entry_value([
                                            "subscription_flag",
                                            false,
                                            "user",
                                        ])
                                    );
                                    dispatch(
                                        set_entry_value([
                                            "input_flag",
                                            true,
                                            "user",
                                        ])
                                    );
                                } else {
                                    if (result["message"]) {
                                        loadingErrorToggle(result["message"]);
                                    } else {
                                        loadingErrorToggle(
                                            "処理中にエラーが発生しました。お手数ですが再度お試しください。"
                                        );
                                    }
                                }
                            })
                            .catch((error) => {
                                // console.log(JSON.stringify("error" + error));
                                loadingErrorToggle(
                                    "処理中にエラーが発生しました。お手数ですが、再度お試しください。"
                                );

                                // dispatch(progress_set[("Progress", false)]);
                            });
                    })
                    .catch((error) => {
                        // console.log(JSON.stringify("error" + error));
                        loadingErrorToggle(
                            "エラーが発生しました。お手数ですが再度おためしください"
                        );
                    });
            }
        };

        return (
            <>
                <Grid>
                    <h1>サブスク購入</h1>

                    <List>
                        <ListItem>
                            <ListItemText
                                primary={
                                    "フィフティー会員は、掲載されているすべてのお店で（１店舗につき１日１メニューのご利用であれば）何度でも使える月額制の会員です。"
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={
                                    "フィフティー会員になるには、サブスクリプションの購入が必要となります。"
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={
                                    "サブスクリプションを解約されると退会となりますのでご注意ください。"
                                }
                            />
                        </ListItem>
                    </List>
                    <ButtonContainedPrimary
                        onClick={AppPurchase}
                        disabled={ProgressState.Progress}
                        style={{ display: "block", margin: "auto" }}
                    >
                        サブスク購入
                        <br />
                        {"500円/月"}
                    </ButtonContainedPrimary>
                    <p style={{ marginTop: "5px" }}>
                        ※会員の本登録が完了するまでは本アプリにはログインできません。
                    </p>
                    <Grid
                        style={{
                            height: "30px",
                        }}
                    ></Grid>
                    <Grid>
                        <h2>復元される前に</h2>
                    </Grid>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary={
                                    "すでにフィフティーカード本会員に登録している方が、同端末または端末に紐づく異なるアカウントで、復元操作を行うと異なるアカウントへ課金情報のみが引き継がれます。"
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={
                                    "課金情報が引き継がれると既存のアカウントではログインができなくなり、パカッティーや利用履歴も同タイミングで消失しますのでご注意ください。"
                                }
                            />
                        </ListItem>
                    </List>
                    <ButtonTextPrimary
                        style={{ display: "block", margin: "auto" }}
                        onClick={AppRestore}
                        disabled={ProgressState.Progress}
                    >
                        復元する
                    </ButtonTextPrimary>
                </Grid>
                <Dialog
                    open={successOpen}
                    onClose={handleClose}
                    className={classes.stop_dialog}
                >
                    <p>{message}</p>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{ textAlign: "center" }}
                    >
                        <ButtonContainedPrimary onClick={handleClose}>
                            確認
                        </ButtonContainedPrimary>
                    </Grid>
                </Dialog>
                <Dialog
                    open={errorOpen}
                    onClose={errorHandleClose}
                    className={classes.stop_dialog}
                >
                    {/* <p>エラーが発生しました。</p> */}
                    <p>{message}</p>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{ textAlign: "center" }}
                    >
                        <ButtonContainedPrimary onClick={errorHandleClose}>
                            確認
                        </ButtonContainedPrimary>
                    </Grid>
                </Dialog>
            </>
        );
    };

    useEffect(() => {
        dispatch(initialize());
        dispatch(set_entry_value(["entry_mode", "user"]));
        dispatch(set_value(["showDrawer", false]));
        dispatch(set_value(["showBottomNavigation", false]));
    }, []);

    return (
        <div className="m-3">
            <StopButton disabled={thisState.user.fin_flag} />
            <EntryMemberStepper />
            {dev_flag && (
                <>
                    <Button onClick={() => setDevConsole([])}>crear</Button>
                    <div
                        style={{
                            width: "100%",
                            minHeight: "5rem",
                            background: "black",
                            color: "#fff",
                        }}
                    >
                        {devConsole.map((value, i) => (
                            <div key={i}>
                                {i}
                                {
                                    "=========================================================== "
                                }
                                <pre
                                    style={{
                                        color: "#fff",
                                    }}
                                >
                                    {JSON.stringify(value, null, " ")}
                                </pre>
                            </div>
                        ))}
                    </div>
                    <Button onClick={() => setDevConsole([])}>crear</Button>
                </>
            )}
            {thisState.user.email_flag ? <MailInput /> : ""}
            {thisState.user.onetime_flag ? <OneTimeInput /> : ""}
            {thisState.user.policy_flag ? <PolicyAgreement /> : ""}
            {thisState.user.input_flag ? <InfoInput /> : ""}
            {thisState.user.check_flag ? <InfoCheck /> : ""}
            {thisState.user.subscription_flag ? <Subscription /> : ""}
            {thisState.user.fin_flag ? <EntryComplete /> : ""}
        </div>
    );
};

export default EntryMembers;
