import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

// それぞれ slice.reducer を default export している前提
import userReducer from "./user";
import AdminStoreApplication from "./AdminStoreApplication";
import EntryShop from "./EntryShop";
import Login from "./Login";
import Information from "./Information";
import AdminPointSetting from "./AdminPointSetting";
import Entry from "./Entry";
import U_couponUseHistory from "./U_couponUseHistory";
import NotificationList from "./NotificationList";
import Shoplist from "./Shoplist";
import FAQ from "./FAQ";
import FavoriteShop from "./FavoriteShop";
import ShopInformation from "./ShopInformation";
import QRCodeDisplay from "./QRCodeDisplay";
import GeneralFriendInvitation from "./GeneralFriendInvitation";
import ShopApplication from "./ShopApplication";
import GeneralRedeemingPointList from "./GeneralRedeemingPointList";
import UserInfoChange from "./UserInfoChange";
import HistoryTable from "./HistoryTable";
import U_pointHistory from "./U_pointHistory";
import S_couponUseHistory from "./S_couponUseHistory";
import Policy from "./Policy";
import Guide from "./Guide";
import ShopSnapPosting from "./ShopSnapPosting";
import ShopStoreApplication from "./ShopStoreApplication";
import ShopUserInfoChange from "./ShopUserInfoChange";
import ErrorAlert from "./ErrorAlert";
import Withdrawal from "./Withdrawal";
import AdminShopList from "./AdminShopList";
import AdminMemberList from "./AdminMemberList";
import AdminNotice from "./AdminNotice";
import AdminInfoChange from "./AdminInfoChange";
import Top from "./Top";
import TagList from "./TagList";
import AdminNoticeList from "./AdminNoticeList";
import AdminShopEditing from "./AdminShopEditing";
import AdminRedeemingPointList from "./AdminRedeemingPointList";
import AdminHomeSetting from "./AdminHomeSetting";
import AdminMascot from "./AdminMascot";
import GeneralMascotDraw from "./GeneralMascotDraw";
import AdminUser from "./AdminUser";
import AdminShopQRCreate from "./AdminShopQRCreate";
import AdminGeneralUserDetail from "./AdminGeneralUserDetail";
import ErrorHandler from "./ErrorHandler";
import AdminDocumentSetting from "./AdminDocumentSetting";
import AdminQASetting from "./AdminQASetting";
import AdminShopUsageHistory from "./AdminShopUsageHistory";
import AdminNewsSetting from "./AdminNewsSetting";
import AdminBlogSetting from "./AdminBlogSetting";
import AdminPointApplicationList from "./AdminPointApplicationList";
import AdminEnqueteSetting from "./AdminEnqueteSetting";
import Blog from "./Blog";
import Tweet from "./Tweet";
import NewShoplist from "./NewShoplist";
import NearShoplist from "./NearShoplist";
import RecommendShoplist from "./RecommendShoplist";
import UseHistoryShoplist from "./UseHistoryShoplist";
import AdminHome from "./AdminHome";
import Enquete from "./Enquete";
// タレントバンク

import Talent from "~stores/TALENTBANK/Talent";
import Category from "~stores/TALENTBANK/Category";
import Offer from "~stores/TALENTBANK/Offer";
import Progress from "~stores/TALENTBANK/Progress";
import AuditionSeet from "~stores/TALENTBANK/AuditionSeet";
import Company from "~stores/TALENTBANK/Company";
import Vote from "~stores/TALENTBANK/Vote";
import Chat from "~stores/TALENTBANK/Chat";
import News from "~stores/TALENTBANK/News";
import TalentRequest from "~stores/TALENTBANK/TalentRequest";
import HomeSetting from "./TALENTBANK/HomeSetting";

const reducer = combineReducers({
    Information: Information,
    user: userReducer,
    AdminStoreApplication: AdminStoreApplication,
    EntryShop: EntryShop,
    Login: Login,
    AdminPointSetting: AdminPointSetting,
    Entry: Entry,
    U_couponUseHistory: U_couponUseHistory,
    NotificationList: NotificationList,
    Shoplist: Shoplist,
    FAQ: FAQ,
    FavoriteShop: FavoriteShop,
    ShopInformation: ShopInformation,
    QRCodeDisplay: QRCodeDisplay,
    GeneralFriendInvitation: GeneralFriendInvitation,
    ShopApplication: ShopApplication,
    GeneralRedeemingPointList: GeneralRedeemingPointList,
    UserInfoChange: UserInfoChange,
    HistoryTable: HistoryTable,
    U_pointHistory: U_pointHistory,
    S_couponUseHistory: S_couponUseHistory,
    Policy: Policy,
    Guide: Guide,
    ShopSnapPosting: ShopSnapPosting,
    ShopStoreApplication: ShopStoreApplication,
    ShopUserInfoChange: ShopUserInfoChange,
    ErrorAlert: ErrorAlert,
    Withdrawal: Withdrawal,
    AdminShopList: AdminShopList,
    AdminMemberList: AdminMemberList,
    AdminNotice: AdminNotice,
    AdminInfoChange: AdminInfoChange,
    Top: Top,
    TagList: TagList,
    AdminNoticeList: AdminNoticeList,
    AdminShopEditing: AdminShopEditing,
    AdminRedeemingPointList: AdminRedeemingPointList,
    AdminHomeSetting: AdminHomeSetting,
    AdminMascot: AdminMascot,
    GeneralMascotDraw: GeneralMascotDraw,
    AdminUser: AdminUser,
    AdminShopQRCreate: AdminShopQRCreate,
    AdminGeneralUserDetail: AdminGeneralUserDetail,
    AdminDocumentSetting: AdminDocumentSetting,
    AdminQASetting: AdminQASetting,
    AdminShopUsageHistory: AdminShopUsageHistory,
    AdminNewsSetting: AdminNewsSetting,
    AdminBlogSetting: AdminBlogSetting,
    AdminPointApplicationList: AdminPointApplicationList,
    AdminEnqueteSetting: AdminEnqueteSetting,
    Blog: Blog,
    Tweet: Tweet,
    NewShoplist: NewShoplist,
    NearShoplist: NearShoplist,
    RecommendShoplist: RecommendShoplist,
    UseHistoryShoplist: UseHistoryShoplist,
    AdminHome: AdminHome,
    Enquete: Enquete,
    //共通
    ErrorHandler: ErrorHandler,
    // タレントバンク管理
    Talent: Talent,
    Category: Category,
    Offer: Offer,
    Progress: Progress,
    AuditionSeet: AuditionSeet,
    Company: Company,
    Vote: Vote,
    Chat: Chat,
    HomeSetting: HomeSetting,
    News: News,
    TalentRequest: TalentRequest,
});

const store = configureStore({ reducer });

export default store;
