import React, { useRef } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {},
    label: {},
    discription: {},
}));

export const InputText = (props) => {
    const {
        label,
        discription,
        id,
        onBlur,
        trueError,
        defaultValue,
        falseError,
        ...other
    } = props;
    // trueError/falseErrorは、validationエラーがtrue(エラー有)/false(エラー無)のときに実行される関数
    const ref = useRef(null);
    const classes = useStyles();
    const [error, setError] = React.useState(false);

    const handleBlur = (e) => {
        if (onBlur !== undefined) onBlur(e);

        // console.log(ref.current.validationMessage);
        if (ref.current) {
            if (!ref.current.validity.valid) {
                setError(true);
                if (trueError !== undefined) trueError();
            } else {
                setError(false);
                if (falseError !== undefined) falseError();
            }
        }
    };

    return (
        <React.Fragment>
            {label && <p className={classes.label}>{label + " *"}</p>}
            {discription && (
                <p className={classes.discription}>{discription}</p>
            )}
            {defaultValue && (
                <TextField
                    id={id}
                    inputRef={ref}
                    helperText={
                        ref?.current?.validationMessage
                            ? ref.current.validationMessage
                            : ""
                    }
                    error={error}
                    fullWidth
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    defaultValue={defaultValue}
                    {...other}
                />
            )}
        </React.Fragment>
    );
};
