import { createSlice } from "@reduxjs/toolkit";

// Stateの初期状態
const initialState = {
    title: "",
    message: "",
};

// Sliceを生成する
const slice = createSlice({
    name: "ErrorAlert",
    initialState,
    reducers: {
        set_alert: (state, action) => {
            const title = action.payload[0];
            const message = action.payload[1];
            const severity = action.payload[2];
            state.severity = severity;
            state.title = title;
            state.message = message;
            window.scrollTo(0, 0);
        },
        set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_alert } = slice.actions;
