import React from "react";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";



function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
}
const rows = [
    createData("nobita", "のび太", "無効", "10"),
    createData("suneo", "スネ夫", "", "10"),
    createData("zyaian", "ジャイアン", "無効", "11"),
    createData("sizuka", "しずか", "", "9"),
    createData("doraemon", "どらえもん", "", "0"),
];

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 12,
        backgroundColor: "pink",
        color: "white",
    },
    body: {
        fontSize: 12,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const MemberListTable = () => {
    const history = useHistory();

    const onFixMember = () => {
        history.push("/FixMember");
    };

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table
                    className="MemberListTable"
                    aria-label="customized table"
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">ID</StyledTableCell>
                            <StyledTableCell align="left">
                                表示名
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                有効
                            </StyledTableCell>
                            <StyledTableCell align="right"></StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row">
                                    {row.name}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {row.calories}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {row.fat}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Button
                                        size="small"
                                        className="table-fix-button"
                                        onClick={(e) => onFixMember()}
                                    >
                                        編集
                                    </Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
};

export default MemberListTable;
