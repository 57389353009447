import React from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    PanelButton: {
        width: "100%",
        maxWidth: "300px",
        margin: "1%",
        marginRight: "auto",
        marginLeft: "auto",
        display: "block",
        height: "auto",
    },
}));

export const SimpleButton = (props) => {
    const { to, label } = props;

    return (
        <React.Fragment>
            <Button variant="contained" color="primary" {...props}>
                {label}
            </Button>
        </React.Fragment>
    );
};

export const PanelButton = (props) => {
    const { to, label, icon, className, ...other } = props;
    const classes = useStyles();

    return (
        <>
            <SimpleButton
                label={
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        style={{ fontSize: "1.4rem" }}
                    >
                        {props.label}
                        {icon}
                    </Grid>
                }
                to={to}
                className={`${classes.PanelButton} ${className}`}
                {...other}
            />
        </>
    );
};
