import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles({
  card: {
    border: "solid 1px",
    // borderRadius: "0.5rem",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, .5)",
    position: "relative",
    backgroundColor: "#fff",
    width: "96%",
    padding: "0.5rem",
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: "0.5rem",
    marginTop: "0.5rem",
    fontSize: "1.2rem"
  },
  zero: {
    backgroundColor: "#ffccff"
  },
  one: {
    backgroundColor: "#afeeee"
  },
  two: {
    backgroundColor: "#ffffcc"
  },
  tree: {
    backgroundColor: "#ececec"
  },
  default: {
    backgroundColor: "#FFF"
  }
});
const OfferStatus = (status) => {
  switch (status) {
    case "0":
      return "申請中";
      break;
    case "1":
      return "公開";
      break;
    case "2":
      return "否認";
      break;
    case "3":
      return "非公開";
      break;
    default:
      return "未申請";
      break;
  }
};
const AuditionSheetStatus = (status) => {
  switch (status) {
    case "0":
      return "申請中";

    case "1":
      return "採用";

    case "2":
      return "否認";

    case "3":
      return "辞退";

    default:
      return "未申請";
      break;
  }
};

const StatusCard = (props) => {
  const { status, reason, offer, audition_sheet, id, ...other } = props;
  const classes = useStyles();

  let status_info = "";
  switch (true) {
    case offer:
      status_info = OfferStatus(status);

      break;
    case audition_sheet:
      status_info = AuditionSheetStatus(status);

      break;
    default:
      status_info = OfferStatus(status);
      break;
  }

  const selectClass = (status) => {
    switch (status) {
      case "0":
        return `cards ${classes.card} ${classes.zero}`;

      case "1":
        return `cards ${classes.card}  ${classes.one}`;

      case "2":
        return `cards ${classes.card}  ${classes.two}`;

      case "3":
        return `cards ${classes.card}  ${classes.tree}`;

      default:
        return `cards ${classes.card} ${classes.default}`;
    }
  };

  return (
    <React.Fragment>
      <Grid container direction="row" justifyContent="space-evenly">
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          // alignItems="flex-start"

          className={selectClass(status)}
        >
          <Grid container className={classes.root}>
            <Grid>
              ステータス:{"  "}
              {status_info}
            </Grid>
            <Grid
              style={{
                marginRight: "0",
                marginLeft: "auto",
                fontSize: "0.5rem",
                marginTop: "auto"
              }}
            >
              {id && id}
            </Grid>
          </Grid>

          <Grid container>{reason && <>理由: {reason}</>}</Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default StatusCard;
