import { createSlice } from "@reduxjs/toolkit";

// Stateの初期状態
const initialState = {
    day: Date.now() + 3000,
    qr_count_key: 0,
    qr_value: "QR_ERROR",
};
// Sliceを生成する
const slice = createSlice({
    name: "QRCodeDisplay",
    initialState,
    reducers: {
        set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_value } = slice.actions;
