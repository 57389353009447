const HttpFunction = (method, url, params, api_key) => {
    const csrf_token = document.head.querySelector(
        'meta[name="csrf-token"]'
    ).content;
    async function POST() {
        const res = await fetch(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": csrf_token,
                "API-KEY": api_key,
            },
            body: JSON.stringify(params),
        });
        // console.log("status:"+res.status);
        const data = await res.json();
        return data;
    }

    async function GET() {
        const query_params = new URLSearchParams(params);
        const res = await fetch(url + "?" + query_params, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": csrf_token,
                "API-KEY": api_key,
            },
        });
        const data = await res.json();
        return data;
    }

    if (method == "GET") {
        return GET();
    } else {
        return POST();
    }
};

export default HttpFunction;
