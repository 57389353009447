import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";

export default function PreviewSwitching(props) {
    return (
        <AppBar
            position="static"
            style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
        >
            <Tabs
                value={props.value}
                onChange={props.onChange}
                variant="fullWidth"
            >
                <Tab label="編集"></Tab>
                <Tab label="プレビュー"></Tab>
            </Tabs>
        </AppBar>
    );
}
