import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";

const TweetList = (props) => {
    const { tweet_list = [], onDelete } = props;
    return (
        <React.Fragment>
            {tweet_list.length ? (
                <div
                    style={{
                        background: "pink",
                        borderRadius: "1rem",
                        marginBottom: "0.5rem",
                    }}
                >
                    <div style={{ marginLeft: "1rem" }}> スタッフの一言</div>
                    <List>
                        {tweet_list.map((tweet_data, index) => (
                            <React.Fragment key={index}>
                                <ListItem
                                    alignItems="flex-start"
                                    style={{
                                        paddingTop: "0",
                                        display: "block",
                                        paddingBottom: "0px",
                                    }}
                                >
                                    <ListItemText>
                                        <Typography
                                            style={{
                                                padding: "0.5rem",
                                                border: "solid 1px gray",
                                                borderRadius: "1rem",
                                                fontSize: "small",
                                                justifyContent: "space-between",
                                                display: "flex",
                                            }}
                                        >
                                            {tweet_data.tweet}
                                            {onDelete && (
                                                <DeleteIcon
                                                    onClick={() =>
                                                        onDelete(tweet_data)
                                                    }
                                                />
                                            )}
                                        </Typography>
                                    </ListItemText>
                                    <div
                                        style={{
                                            //fontSize: "0.2rem",
                                            fontSize: "small",
                                            marginRight: "0",
                                            marginLeft: "auto",
                                            width: "fit-content",
                                        }}
                                    >
                                        {tweet_data.created_at}
                                    </div>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                </div>
            ) : (
                ""
            )}
        </React.Fragment>
    );
};

export default TweetList;
