import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { DataGrid } from "@material-ui/data-grid";
import { GetShopList } from "~stores/Shoplist";

import ShopOverview from "../modules/ShopOverview";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import * as L from "leaflet";

const Map = () => {
    const dispatch = useDispatch();
    const ShoplistState = useSelector((state) => state.Shoplist);
    const position_list = ShoplistState.shop_list_data;

    const [position, setPosition] = React.useState(
        position_list?.length
            ? [position_list[0]?.latitude, position_list[0]?.longitude]
            : [34.7024, 135.4959]
    );
    const [shop_data, setShopData] = React.useState(false);
    // const map = useMapEvents({
    //     click(e) {
    //         console.log(e.latlng);
    //     },
    // });

    // function test2(position) {
    //     const latitude = position.coords.latitude;
    //     const longitude = position.coords.longitude;

    //     console.log("█████████████████████████████████████");
    //     console.log(latitude);
    //     console.log(longitude);
    //     // if (!latitude || !longitude) return;
    //     setPosition([latitude, longitude]);
    // }

    // console.log(position);
    // useEffect(() => {
    //     navigator.geolocation.getCurrentPosition(test2);
    // }, []);

    useEffect(() => {
        dispatch(GetShopList()); //常にすべて表示
    }, []);

    const LeafIcon = L.Icon.extend({
        options: {}
      });

    const markerIcon = new LeafIcon({
        iconUrl:
          "/images/Common/onepiece001.png"
    });
    const [icon, setIcon] = useState(markerIcon);

    const HandleAllGet = () => {
        dispatch(GetShopList());
    };

    return (
        <React.Fragment>
            <ButtonNavi
                left_label="戻る"
                left_link={`/ShopList`}
                // right_label="すべて表示"
                // right_props={{ onClick: HandleAllGet, color: "primary" }}
            />
            <div>
                <MapContainer
                    center={position}
                    // zoom={10}
                    zoom={17}
                    scrollWheelZoom={true}
                    style={{ height: "70vh", width: "100vw" }}
                    // style={{ height: "10rem", width: "10rem" }}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    {position_list.map((val, index) => (
                        <React.Fragment key={index}>
                            <Marker
                                position={[val.latitude, val.longitude]}
                                eventHandlers={{
                                    click: (e) => {
                                        setShopData(val);
                                    },
                                }}
                                icon={icon}
                            >
                                <Popup>
                                    <Typography variant="h6">
                                        {val.shop_name}
                                    </Typography>
                                    <br />
                                    {val.shop_explain}
                                </Popup>
                            </Marker>
                        </React.Fragment>
                    ))}
                    {/* <Marker position={[35.505, 139.09]}>
                        <Popup>
                            A pretty CSS3 popup. <br /> Easily customizable.
                        </Popup>
                    </Marker> */}
                </MapContainer>
                {shop_data ? (
                    <ShopOverview
                        style={{
                            marginTop: "-10vh",
                            zIndex: "1000",
                            position: "absolute",
                        }}
                        shop_data={shop_data}
                    />
                ) : (
                    ""
                )}
            </div>
        </React.Fragment>
    );
};

export default Map;
