import React from "react";
import Button from "@material-ui/core/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";

import FormGroup from "@material-ui/core/FormGroup";

import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const MemberListSort = () => {
    const [value, setValue] = React.useState([20, 37]);
    return (
        <React.Fragment>
            <FormControl>
                <div></div>
            </FormControl>

            <Container className="m-2">
                <FormGroup row>
                    <FormControlLabel
                        control={<Switch />}
                        label="有効なユーザーのみ"
                    />
                </FormGroup>
                <TextField id="standard-basic" label="検索したいユーザーID" />

                <div className="text-right">
                    <Button className="serch-button">検索</Button>
                </div>
            </Container>
        </React.Fragment>
    );
};

export default MemberListSort;
