AdminShopUsageHistory;
import React, { useEffect } from "react";

import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
    DataGrid,
    GridToolbarContainer,
    useGridSlotComponentProps,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarColumnsButton,
} from "@material-ui/data-grid";
import jaJPDataGrid from "../modules/JaJPDataGrid";

import {
    admin_shop_usage_history_set,
    GetShopHistory,
    PutShopHistory,
} from "../../stores/AdminShopUsageHistory";

import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import {
    format,
    formatDistance,
    formatRelative,
    subDays,
    parse,
    differenceInYears,
} from "date-fns";
import ja from "date-fns/locale/ja";

import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const AdminShopUsageHistory = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const AdminShopUsageHistoryState = useSelector(
        (state) => state.AdminShopUsageHistory
    );
    const history_list = AdminShopUsageHistoryState.history_list;
    const [shop_id, set_shop_id] = React.useState("");
    const [month, setMonth] = React.useState("");
    const [select_history, setSelectHistory] = React.useState(false);
    const [review_flag, setReviewFlag] = React.useState("");

    const old = (raw_year_month) => {
        const test = parse(raw_year_month, "yyyy/MM", new Date());
        return differenceInYears(new Date(), test);
    };

    const columns = [
        { field: "created_at", headerName: "利用時間", width: 180 },
        { field: "user_id", headerName: "user_id", width: 130 },
        { field: "shop_id", headerName: "shop_id", width: 130 },
        {
            field: "history_id",
            headerName: "history_id",
            width: 130,
            hide: true,
        },
        {
            field: "nickname",
            headerName: "ニックネーム",
            flex: 1,
            editable: true,
        },
        {
            field: "review_flag",
            headerName: "レビュー",
            width: 150,
            // editable: true,
            hide: true,
            valueGetter: (params) =>
                params.row?.review_flag ? "レビュー済" : "",
        },
        {
            field: "review",
            headerName: "評価",
            width: 80,
        },
        {
            field: "review_text",
            headerName: "コメント",
            flex: 1,
        },

        {
            field: "sex",
            headerName: "性別",
            width: 130,
            editable: true,
            valueGetter: (params) =>
                params.row?.sex == 1
                    ? "男"
                    : params.row?.sex == 2
                    ? "女"
                    : "その他",
        },
        // {
        //     field: "raw_year_month",
        //     headerName: "年齢",
        //     width: 150,
        //     editable: true,
        //     valueGetter: (params) => old(params.row?.raw_year_month),
        // },
        // {
        //     field: "prefectures",
        //     headerName: "都道府県",
        //     width: 150,
        //     editable: true,
        // },
        {
            field: "active_flag",
            headerName: "表示フラグ",
            width: 150,
            valueGetter: (params) =>
                params.row?.active_flag == "0" ? "表示" : "非表示",
        },
        {
            field: "",
            headerName: "",
            width: 100,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    onClick={() => setSelectHistory(params.row)}
                >
                    詳細
                </Button>
            ),
        },
    ];

    useEffect(() => {
        // dispatch(initialize());
        const params = {
            shop_id: shop_id,
            page: 1,
        };
        dispatch(GetShopHistory(params));
    }, []);

    const HandleSerch = () => {
        const params = {
            shop_id: shop_id,
            month: month,
            page: 1,
            review_flag: review_flag,
        };
        dispatch(GetShopHistory(params));
    };

    const onPageChange = (page_num) => {
        const params = {
            shop_id: shop_id,
            page: page_num + 1,
            month: month,
            review_flag: review_flag,
        };
        dispatch(GetShopHistory(params));
    };

    const HandleChengeHistory = (history_id, update_data) => {
        const params = {
            shop_id: shop_id,
            month: month,
            page: AdminShopUsageHistoryState.currentPage,
            review_flag: review_flag,
            history_id: history_id,

            update_data: update_data,
        };
        dispatch(PutShopHistory(params)).then(() => {
            setSelectHistory(false);
        });
    };

    return (
        <React.Fragment>
            <Dialog
                open={select_history ? true : false}
                onClose={() => setSelectHistory(false)}
            >
                <DialogTitle>詳細</DialogTitle>

                <DialogContent
                    style={{ minWidth: "30rem", minHeight: "20rem" }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            店舗ID
                        </Grid>
                        <Grid item xs={6}>
                            {select_history.shop_id}
                        </Grid>

                        <Grid item xs={6}>
                            評価
                        </Grid>
                        <Grid item xs={6}>
                            <Rating value={select_history.review} readOnly />
                        </Grid>

                        <Grid item xs={6}>
                            ニックネーム
                        </Grid>
                        <Grid item xs={6}>
                            {" "}
                            {select_history.nickname
                                ? select_history.nickname
                                : "名無し"}
                        </Grid>

                        <Grid item xs={6}>
                            都道府県
                        </Grid>
                        <Grid item xs={6}>
                            {" "}
                            {select_history.prefectures}
                        </Grid>

                        <Grid item xs={6}>
                            性別
                        </Grid>
                        <Grid item xs={6}>
                            {select_history.sex == 1
                                ? "男"
                                : select_history.sex == 2
                                ? "女"
                                : "その他"}
                        </Grid>

                        <Grid item xs={6}>
                            状況
                        </Grid>
                        <Grid item xs={6}>
                            {select_history.active_flag == "0"
                                ? "表示"
                                : "非表示"}
                        </Grid>

                        <Grid item xs={6}>
                            テキスト
                        </Grid>
                        <Grid item xs={6}>
                            <Box> {select_history.review_text}</Box>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    {select_history.active_flag == "0" ? (
                        <Button
                            color="primary"
                            onClick={() =>
                                HandleChengeHistory(select_history.history_id, {
                                    active_flag: 1,
                                })
                            }
                        >
                            このレビューを非表示にする
                        </Button>
                    ) : (
                        <Button
                            color="primary"
                            onClick={() =>
                                HandleChengeHistory(select_history.history_id, {
                                    active_flag: 0,
                                })
                            }
                        >
                            このレビューを再表示する
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Container>
                <ButtonNavi left_label="戻る" left_link={`/AdminHome`} />
                <h1>店舗利用履歴</h1>

                <TextField
                    label="店舗ID"
                    value={shop_id}
                    placeholder="S0000000"
                    onChange={(e) => set_shop_id(e.target.value)}
                />
                <TextField
                    label="利用月"
                    value={month}
                    type="month"
                    onChange={(e) => setMonth(e.target.value)}
                    style={{ marginRight: "2rem" }}
                />

                <FormControlLabel
                    control={
                        <Switch
                            // size="small"
                            checked={review_flag}
                            onChange={(e) =>
                                setReviewFlag(e.target.checked ? 1 : "")
                            }
                        />
                    }
                    label="レビュー済みのみ"
                />

                <Button
                    variant="contained"
                    onClick={HandleSerch}
                    color="primary"
                >
                    検索
                </Button>
                {/* 
            {history_list.map((history, index) => (
                <>{index}</>
            ))} */}

                <div style={{ height: 20, width: "100%" }}>
                    {AdminShopUsageHistoryState.total}件見つかりました。
                </div>
                <div style={{ height: 640, width: "100%" }}>
                    <DataGrid
                        getRowId={(row) => row.history_id}
                        rows={history_list}
                        columns={columns}
                        pageSize={10}
                        // rowsPerPageOptions={[5]}
                        // checkboxSelection
                        disableSelectionOnClick
                        onPageChange={onPageChange}
                        paginationMode="server"
                        page={AdminShopUsageHistoryState.currentPage}
                        rowCount={AdminShopUsageHistoryState.total}
                        localeText={jaJPDataGrid}
                        // AdminShopUsageHistoryState.lastPage

                        //
                    />
                </div>
            </Container>
        </React.Fragment>
    );
};

export default AdminShopUsageHistory;
