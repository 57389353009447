import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import { useHistory, useLocation } from "react-router";

import { ButtonNavi, Fullwidth } from "~CJS/block/ButtonNavi";

import { LinkButton } from "~CJS/atoms/LinkButton";
import { BackButton } from "~CJS/atoms/BackButton";
import Button from "@material-ui/core/Button";
import TalentProfileInput from "~/block/TalentProfileInput";
import OptionInput from "~CJS/block/OptionInput";

import {
    initialize,
    TalentDelete,
    GetDetail,
    TalentActive,
} from "~stores/TALENTBANK/Talent";

import Typography from "@material-ui/core/Typography";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    overrides: {
        MuiInputBase: {
            root: {
                "&.Mui-disabled": {
                    color: "black",
                    // opacity: 1,
                },
            },
        },
        MuiFormLabel: {
            root: {
                "&.Mui-disabled": {
                    // color: "black",
                    // opacity: 1,
                },
            },
        },
        MuiInputLabel: {
            outlined: {
                "&$shrink": {
                    color: "black",
                },
            },
        },
        MuiTypography: {
            h1: {
                textAlign: "center",
                fontSize: "2.5rem",
                margin: "1rem 0rem",
                width: "100%",
            },
            h2: {
                fontSize: "2.0rem",
            },
        },
    },
});

const TalentDetail = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const path = history.location.pathname.split("/");
    const talent_id = path[2];
    const thisState = useSelector((state) => state.Talent);
    const search = useLocation().search;
    const query = new URLSearchParams(search);

    const request_flag = talent_id?.slice(0, 1) == "R" ? true : false;

    useEffect(() => {
        dispatch(initialize());
        dispatch(GetDetail(talent_id));
    }, []);
    const handleDelete = () => {
        dispatch(TalentDelete({ talent_id: talent_id })).then((action) => {
            if (action.payload.result == "1") {
                history.push(`/TalentSearch`);
                alert("タレントを削除しました。");
            }
        });
    };
    const handleActive = () => {
        dispatch(
            TalentActive({
                talent_id: talent_id,
                active_flag:
                    thisState.talent_detail?.active_flag == "1" ? "0" : "1",
            })
        ).then((action) => {
            if (action.payload.result == "1") {
                if (action.meta.arg.active_flag == "1") {
                    alert("タレントを再表示しました。");
                } else {
                    alert("タレントを非表示にしました。");
                }
                history.push(`/TalentSearch`);
            }
        });
    };

    return (
        <React.Fragment>
            <Container>
                {/* <BackButton history={history} /> */}

                {request_flag ? (
                    <ButtonNavi
                        left_label="戻る"
                        left_link="/TalentRequestList"
                    />
                ) : (
                    <ButtonNavi
                        left_label="タレント検索"
                        left_link="/TalentSearch"
                    />
                )}
                <Typography variant="h1">タレント詳細</Typography>

                <form>
                    {/* <FormControl noValidate autoComplete="off" fullWidth> */}
                    <TalentProfileInput disabled {...thisState.talent_detail} />
                    <OptionInput
                        disabled
                        inputs={thisState.talent_detail?.input_option}
                    />
                </form>
                <Fullwidth
                    label="編集"
                    link={`/TalentDetailFix/${talent_id}`}
                />
                {request_flag ? (
                    ""
                ) : (
                    <ButtonNavi
                        left_label={
                            thisState.talent_detail?.active_flag == "1"
                                ? "非表示"
                                : "再表示"
                        }
                        left_props={{ onClick: handleActive }}
                        right_label={
                            thisState.talent_detail?.delete_flag == "1"
                                ? ""
                                : "削除"
                        }
                        right_props={{ onClick: handleDelete }}
                    />
                )}
            </Container>
        </React.Fragment>
    );
};

export default TalentDetail;
