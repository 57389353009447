import React from "react";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import LINEAt from "../block/LINEAt";
import Container from "@material-ui/core/Container";

const NewShopApplication = () => {
    return (
        <Container>
            <div style={{ marginTop: "80px", textAlign: "center" }}>
                <Typography color="primary" style={{ fontSize: "200%" }}>
                    加盟店登録
                </Typography>
            </div>
            <div
                style={{
                    marginTop: "15px",
                    textAlign: "center",
                    marginBottom: "1rem",
                }}
            >
                今すぐ加盟店申請をしよう！
                <br />
                加盟店登録は下記のLINE@にメッセージを送信してください!
            </div>
            <LINEAt
                link="https://lin.ee/eDft3Hv"
                image="/images/Common/FCラインアット店舗用.png"
            />
            {/* <div style={{ textAlign: "center" }}>
                <Button
                    href="https://fifty-card.net/shopentry/"
                    variant="contained"
                    color="primary"
                    style={{
                        margin: "50px auto",
                        padding: "8px 40px",
                    }}
                >
                    申請はこちら
                </Button>
            </div> */}
        </Container>
    );
};

export default NewShopApplication;
