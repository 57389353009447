import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { useSelector, useDispatch } from "react-redux";
import { Tweet_set, PostTweet } from "~stores/Tweet";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Typography, Grid } from "@material-ui/core";
const Tweet = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.Tweet);

    useEffect(() => {
        // dispatch(set_entry_value(["registering_user", true]));
    }, []);

    const onPost = () => {
        if (!thisState.tweet) return;
        dispatch(PostTweet({ tweet: thisState.tweet })).then((data) => {
            if (data?.payload?.result == "OK") {
                dispatch(Tweet_set(["tweet", ""]));
                alert("投稿しました。");
                history.back();
            }
        });
    };

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                style={{ marginBottom: "0.5rem", padding: "0.1rem" }}
            >
                <ChevronLeftIcon
                    onClick={() => history.back()}
                    style={{ fontSize: "2rem" }}
                />
                <Button variant="contained" color="primary" onClick={onPost}>
                    投稿
                </Button>
            </Grid>
            <div style={{ display: "flex" }}>
                <TextField
                    autoFocus
                    value={thisState.tweet}
                    style={{ margin: "0.2rem" }}
                    fullWidth
                    multiline
                    minRows={5}
                    variant="outlined"
                    onChange={(e) => {
                        const line_num_array = e.target.value.match(/\n/g);
                        if (line_num_array?.length >= 4) return;
                        if (e.target.value.length > 100) return;
                        dispatch(Tweet_set(["tweet", e.target.value]));
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default Tweet;
