import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { ButtonTextPrimary } from "../utils/Buttons";
import TokenErrorDialog from "../utils/TokenErrorDialog";
import Container from "@material-ui/core/Container";

import { LinkPanelBig } from "~CJS/atoms/LinkButton";
import Typography from "@material-ui/core/Typography";
// 管理用
import BuildSharpIcon from "@material-ui/icons/BuildSharp";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import BusinessRoundedIcon from "@material-ui/icons/BusinessRounded";
import LocalOfferRoundedIcon from "@material-ui/icons/LocalOfferRounded";
import AddAlertRoundedIcon from "@material-ui/icons/AddAlertRounded";
import StoreMallDirectoryRoundedIcon from "@material-ui/icons/StoreMallDirectoryRounded";
import PeopleRoundedIcon from "@material-ui/icons/PeopleRounded";
import HomeIcon from "@material-ui/icons/Home";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import HowToVoteIcon from "@material-ui/icons/HowToVote";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ForumIcon from "@material-ui/icons/Forum";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import LabelIcon from "@material-ui/icons/Label";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import SelectAllIcon from "@material-ui/icons/SelectAll";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import HelpIcon from "@material-ui/icons/Help";
import DescriptionIcon from "@material-ui/icons/Description";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import HistoryIcon from "@material-ui/icons/History";
import BookIcon from "@material-ui/icons/Book";
import CreateIcon from "@material-ui/icons/Create";

import Badge from "@material-ui/core/Badge";
import { admin_logout } from "~stores/Information";
import { GetAdminHome } from "~stores/AdminHome";

const useStyles = makeStyles((theme) => ({
    root: {
        "& button": {
            display: "block",
            margin: "auto",
        },
        maxWidth: "none",
    },
    LinkPanelBig: {
        background: "orange",
        "&:hover": {
            background: "#b0dcfa",
        },
    },
    badge: {
        transform: "scale(2) translate(20%, -10%)",
        // anchorOriginTopRightRectangle
    },
}));

// const AdminPageLink = (props) => {
//     const { link } = props;
//     const history = useHistory();
//     const handleClick = () => {
//         history.push(link);
//     };

//     return (
//         <ButtonTextPrimary onClick={handleClick}>
//             {props.children}
//         </ButtonTextPrimary>
//     );
// };

const AdminHome = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const admin_token = useSelector(
        (state) => state.Information.admin.admin_token
    );
    const AdminHomeState = useSelector((state) => state.AdminHome);

    const [open, setOpen] = useState(false);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (!admin_token) setOpen(true);

        dispatch(GetAdminHome());
    }, []);

    const Increment = () => {
        setCount(count + 1);
    };
    const onLogout = () => {
        dispatch(admin_logout());
    };
    return (
        <Container className={classes.root}>
            {/* <h1 style={{ textAlign: "center" }}>管理者HOME</h1> */}
            <Typography variant="h1" onClick={Increment}>
                FIFTY CARD
            </Typography>

            <LinkPanelBig
                label="会員一覧"
                to="/AdminMemberList"
                icon={<PeopleRoundedIcon style={{ fontSize: "80px" }} />}
            />
            <LinkPanelBig
                label={
                    <>
                        加盟店一覧
                        <p style={{ fontSize: "1rem" }}>
                            {"(新規登録・申請確認もこちら)"}
                        </p>
                    </>
                }
                to="/AdminShopList?mode=visit"
                icon={
                    <StoreMallDirectoryRoundedIcon
                        style={{ fontSize: "80px" }}
                    />
                }
            />
            <LinkPanelBig
                label="加盟店QRコード"
                to="/AdminShopQRCreate"
                icon={<SelectAllIcon style={{ fontSize: "80px" }} />}
            />

            <LinkPanelBig
                label="通知設定"
                to="/AdminNoticeList?mode=visit"
                icon={<AddAlertRoundedIcon style={{ fontSize: "80px" }} />}
            />

            <LinkPanelBig
                label="パカッティー設定"
                to="/AdminPointSetting"
                icon={<BuildSharpIcon style={{ fontSize: "80px" }} />}
            />
            <LinkPanelBig
                label="交換商品設定"
                to="/AdminRedeemingPointList"
                icon={<ShoppingCartIcon style={{ fontSize: "80px" }} />}
            />

            <Badge
                badgeContent={AdminHomeState.point_application_count}
                color="secondary"
                style={{
                    marginLeft: "0.5rem",
                    marginRight: "0.8rem",
                    // transform: "scale(2) translate(0%, 0%)",
                }}
                classes={{ badge: classes.badge }}
            >
                <LinkPanelBig
                    label="パカッティー引換リクエスト"
                    to="/AdminPointApplicationList"
                    icon={<ShoppingCartIcon style={{ fontSize: "80px" }} />}
                />
            </Badge>

            <LinkPanelBig
                label="ホーム画面設定"
                to="/AdminHomeSetting"
                icon={<HomeIcon style={{ fontSize: "80px" }} />}
            />
            <LinkPanelBig
                label="ログインボーナス設定"
                to="/AdminMascotList"
                icon={
                    <SentimentSatisfiedAltIcon style={{ fontSize: "80px" }} />
                }
            />
            <LinkPanelBig
                label="管理者設定"
                to="/AdminUserList"
                icon={<PersonAddIcon style={{ fontSize: "80px" }} />}
            />

            <LinkPanelBig
                label="ドキュメント管理"
                to="/AdminDocumentSetting"
                icon={<DescriptionIcon style={{ fontSize: "80px" }} />}
                // disabled={count > 4 ? false : true}
            />
            <LinkPanelBig
                label="Q&A管理"
                to="/AdminQASetting"
                icon={<HelpIcon style={{ fontSize: "80px" }} />}
                // disabled={count > 4 ? false : true}
            />
            <LinkPanelBig
                label="タグ管理"
                to="/AdminTagList"
                icon={<LabelIcon style={{ fontSize: "80px" }} />}
                // disabled={count > 4 ? false : true}
            />

            <LinkPanelBig
                label="店舗利用履歴"
                to="/AdminShopUsageHistory"
                icon={<HistoryIcon style={{ fontSize: "80px" }} />}
                // disabled={count > 4 ? false : true}
            />
            <LinkPanelBig
                label="ニュース管理"
                to="/AdminNewsSetting"
                icon={<FiberNewIcon style={{ fontSize: "80px" }} />}
            />
            <LinkPanelBig
                label="ブログ管理"
                to="/AdminBlogSetting"
                icon={<BookIcon style={{ fontSize: "80px" }} />}
            />
            <LinkPanelBig
                label="アンケート管理"
                to="/AdminEnqueteSetting"
                icon={<CreateIcon style={{ fontSize: "80px" }} />}
            />

            <Typography variant="h1">TALENT BANK</Typography>

            <LinkPanelBig
                label="タレント検索"
                to="/TalentSearch"
                icon={<SearchRoundedIcon style={{ fontSize: "80px" }} />}
                className={classes.LinkPanelBig}
            />
            <LinkPanelBig
                label="タレント承認"
                to="/TalentRequestList"
                icon={<GroupAddIcon style={{ fontSize: "80px" }} />}
                className={classes.LinkPanelBig}
            />

            <LinkPanelBig
                label="お仕事検索"
                to="/OfferSearch"
                icon={<LocalOfferRoundedIcon style={{ fontSize: "80px" }} />}
                className={classes.LinkPanelBig}
            />

            <LinkPanelBig
                label="企業検索"
                to="/SponsorSearch"
                icon={<BusinessRoundedIcon style={{ fontSize: "80px" }} />}
                className={classes.LinkPanelBig}
            />
            <LinkPanelBig
                label="投票管理"
                to="/VoteList"
                icon={<HowToVoteIcon style={{ fontSize: "80px" }} />}
                className={classes.LinkPanelBig}
            />
            <LinkPanelBig
                label="ジャンル管理"
                to="/Talent/CategoryList"
                icon={<LabelIcon style={{ fontSize: "80px" }} />}
                className={classes.LinkPanelBig}
                // disabled
            />
            <LinkPanelBig
                label="オファートーク管理"
                to="/Talent/ChatSerch"
                icon={<ForumIcon style={{ fontSize: "80px" }} />}
                className={classes.LinkPanelBig}
                // disabled
            />
            <LinkPanelBig
                label="ホーム画面設定"
                to="/Talent/HomeSetting"
                icon={<HomeIcon style={{ fontSize: "80px" }} />}
                className={classes.LinkPanelBig}
            />
            <LinkPanelBig
                label="ニュース設定"
                to="/Talent/NewsSetting"
                icon={<FiberNewIcon style={{ fontSize: "80px" }} />}
                className={classes.LinkPanelBig}
            />
            <LinkPanelBig
                label="ログアウト"
                to="/LoginAdmin"
                onClick={onLogout}
                icon={<HomeIcon style={{ fontSize: "80px" }} />}
                className={classes.LinkPanelBig}
            />

            {/* <LinkPanelBig
                label="入力項目設定"
                to="/AdminHomeSetting"
                icon={<BorderColorIcon style={{ fontSize: "80px" }} />}
                className={classes.LinkPanelBig}
                disabled
            /> */}
            {/* <LinkPanelBig
                label="お気に入り登録情報"
                to="/Talent/FavoriteList"
                icon={<FavoriteBorderIcon style={{ fontSize: "80px" }} />}
                className={classes.LinkPanelBig}
                disabled
            /> */}

            <TokenErrorDialog open={open} admin={true} />
        </Container>
    );
};

export default AdminHome;
