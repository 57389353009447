import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";

// Stateの初期状態
const initialState = {
    image_list: [],
    open_modal_index: "false",
    error_message: "",
    progress: false,
};

export const GetReleaseSnap = createAsyncThunk(
    "ShopSnapPosting/GetReleaseSnap",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.shop.shop_token;
        const shop_id = arg.shop_id;
        const SetErrer = (message) => {
            dispatch(set_snap(["error_message", message]));
        };
        const params = {
            shop_id: arg.shop_id,
        };
        HttpFunction("GET", "/api/shop_image", params, api_key)
            .then((data) => {
                if (data.error_message) {
                    SetErrer(["サーバーエラー", data.error_message]);

                    return;
                }
                dispatch(set_snap(["image_list", data.image]));
                dispatch(set_snap(["progress", false]));
            })
            .catch((err) => {
                SetErrer(["サーバーエラー", "公開スナップ取得に失敗しました"]);
                dispatch(set_snap(["progress", false]));
                // console.log(err);
            });
    }
);

export const GetSnap = createAsyncThunk(
    "ShopSnapPosting/GetSnap",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.shop.shop_token;

        const params = {};
        HttpFunction("GET", "/api/image_show", params, api_key)
            .then((data) => {
                if (data.error_message) {
                    dispatch(
                        set_snap([
                            "error_message",
                            ["サーバーエラー", data.error_message],
                        ])
                    );
                    return;
                }
                dispatch(set_snap(["image_list", data.data]));
                dispatch(set_snap(["progress", false]));
            })
            .catch((err) => {
                dispatch(
                    set_snap([
                        "error_message",
                        ["サーバーエラー", "スナップ取得に失敗しました"],
                    ])
                );
                dispatch(set_snap(["progress", false]));
                // console.log(err);
            });
    }
);

export const UploadSnap = createAsyncThunk(
    "ShopSnapPosting/UploadSnap",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.shop.shop_token;
        const params = {
            image: arg.image,
            image_kubun: "10",
            image_comment: arg.image_comment,
        };

        HttpFunction("POST", "/api/image_upload", params, api_key)
            .then((data) => {
                if (data.error_message) {
                    dispatch(
                        set_snap([
                            "error_message",
                            ["サーバーエラー", data.error_message],
                        ])
                    );
                    dispatch(set_snap(["progress", false]));
                    return;
                }
                dispatch(GetSnap());
            })
            .catch((err) => {
                dispatch(
                    set_snap([
                        "error_message",
                        ["サーバーエラー", "スナップ登録に失敗しました"],
                    ])
                );
                // console.log(err);
                dispatch(set_snap(["progress", false]));
            });
    }
);

export const DeleteSnap = createAsyncThunk(
    "ShopSnapPosting/DeleteSnap",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.shop.shop_token;
        const params = {
            image_id: arg.image_id,
        };

        HttpFunction("DELETE", "/api/image_delete", params, api_key)
            .then((data) => {
                if (data.error_message) {
                    dispatch(
                        set_snap([
                            "error_message",
                            ["サーバーエラー", data.error_message],
                        ])
                    );
                    dispatch(set_snap(["progress", false]));
                    return;
                }
                dispatch(GetSnap());
            })
            .catch((err) => {
                dispatch(
                    set_snap([
                        "error_message",
                        ["サーバーエラー", "スナップ削除に失敗しました"],
                    ])
                );
                dispatch(set_snap(["progress", false]));
                // console.log(err);
            });
    }
);

export const AdminGetSnap = createAsyncThunk(
    "ShopSnapPosting/AdminGetSnap",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.admin.admin_token;
        const params = {
            shop_id: arg.shop_id,
        };

        HttpFunction("GET", "/api/image_show_admin", params, api_key)
            .then((data) => {
                if (data.error_message) {
                    dispatch(
                        set_snap([
                            "error_message",
                            ["サーバーエラー", data.error_message],
                        ])
                    );
                    dispatch(set_snap(["progress", false]));

                    return;
                }
                dispatch(set_snap(["image_list", data.data]));

                dispatch(set_snap(["progress", false]));
            })
            .catch((err) => {
                dispatch(
                    set_snap([
                        "error_message",
                        ["サーバーエラー", "スナップ取得に失敗しました"],
                    ])
                );
                // console.log(err);
                dispatch(set_snap(["progress", false]));
            });
    }
);

export const ApprovalSnap = createAsyncThunk(
    "ShopSnapPosting/ApprovalSnap",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.admin.admin_token;
        const params = {
            image_id: arg.image_id,
            shop_id: arg.shop_id,
        };

        HttpFunction("PUT", "/api/image_approval", params, api_key)
            .then((data) => {
                if (data.error_message) {
                    dispatch(
                        set_snap([
                            "error_message",
                            ["サーバーエラー", data.error_message],
                        ])
                    );

                    return;
                }
                dispatch(AdminGetSnap({ shop_id: arg.shop_id }));
            })
            .catch((err) => {
                dispatch(
                    set_snap([
                        "error_message",
                        ["サーバーエラー", "スナップ承認に失敗しました"],
                    ])
                );
                // console.log(err);
            });
    }
);
export const DenialSnap = createAsyncThunk(
    "ShopSnapPosting/DenialSnap",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.admin.admin_token;
        const params = {
            image_id: arg.image_id,
            shop_id: arg.shop_id,
            denial_reason: arg.denial_reason,
        };

        HttpFunction("PUT", "/api/image_denial", params, api_key)
            .then((data) => {
                if (data.error_message) {
                    dispatch(
                        set_snap([
                            "error_message",
                            ["サーバーエラー", data.error_message],
                        ])
                    );

                    return;
                }
                dispatch(AdminGetSnap({ shop_id: arg.shop_id }));
            })
            .catch((err) => {
                dispatch(
                    set_snap([
                        "error_message",
                        ["サーバーエラー", "スナップ否認に失敗しました"],
                    ])
                );
                // console.log(err);
            });
    }
);

export const AdminUploadSnap = createAsyncThunk(
    "ShopSnapPosting/AdminUploadSnap",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.admin.admin_token;
        const params = {
            image: arg.image,
            image_kubun: "10",
            image_comment: arg.image_comment,
            shop_id: arg.shop_id,
        };

        HttpFunction("POST", "/api/image_upload_admin", params, api_key)
            .then((data) => {
                if (data.error_message) {
                    dispatch(
                        set_snap([
                            "error_message",
                            ["サーバーエラー", data.error_message],
                        ])
                    );
                    dispatch(set_snap(["progress", false]));
                    return;
                }
                dispatch(AdminGetSnap({ shop_id: arg.shop_id }));
            })
            .catch((err) => {
                dispatch(
                    set_snap([
                        "error_message",
                        ["サーバーエラー", "スナップ登録に失敗しました"],
                    ])
                );
                // console.log(err);
                dispatch(set_snap(["progress", false]));
            });
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "ShopSnapPosting",
    initialState,
    reducers: {
        set_snap: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];

            if (type) {
                state[name] = value;
                let new_type = state[type];
                new_type[name] = value;
                state[type] = new_type;
            } else {
                state[name] = value;
            }
        },
        del_snap: (state, action) => {
            const index = action.payload;

            let new_image_list = [...state.image_list];

            new_image_list.splice(index, 1);

            state.image_list = new_image_list;
        },
    },
    extraReducers: {
        [GetReleaseSnap.rejected]: (state, action) => {
            state.error_message = [
                "ネットワークエラー",
                "スナップ画像の取得に失敗しました",
            ];
        },
        [GetSnap.pending]: (state, action) => {},
        [GetSnap.fulfilled]: (state, action) => {},
        [GetSnap.rejected]: (state, action) => {
            state.error_message = [
                "ネットワークエラー",
                "スナップ画像の取得に失敗しました",
            ];
        },
        [UploadSnap.pending]: (state, action) => {
            state.progress = true;
        },
        [UploadSnap.fulfilled]: (state, action) => {},
        [UploadSnap.rejected]: (state, action) => {
            state.progress = false;
            state.error_message = [
                "ネットワークエラー",
                "スナップ画像の登録に失敗しました",
            ];
        },
        [DeleteSnap.pending]: (state, action) => {
            state.progress = true;
        },
        [DeleteSnap.rejected]: (state, action) => {
            state.progress = false;
            state.error_message = [
                "ネットワークエラー",
                "スナップ画像の削除に失敗しました",
            ];
        },

        [AdminGetSnap.rejected]: (state, action) => {
            state.error_message = [
                "ネットワークエラー",
                "スナップ画像の取得に失敗しました",
            ];
            dispatch(set_snap(["progress", false]));
        },
        [ApprovalSnap.rejected]: (state, action) => {
            state.error_message = [
                "ネットワークエラー",
                "スナップ画像の承認に失敗しました",
            ];
        },
        [DenialSnap.rejected]: (state, action) => {
            state.error_message = [
                "ネットワークエラー",
                "スナップ画像の否認に失敗しました",
            ];
        },
        [AdminUploadSnap.pending]: (state, action) => {
            state.progress = true;
        },
        [AdminUploadSnap.fulfilled]: (state, action) => {},
        [AdminUploadSnap.rejected]: (state, action) => {
            state.progress = false;
            state.error_message = [
                "ネットワークエラー",
                "スナップ画像の登録に失敗しました",
            ];
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_snap, del_snap } = slice.actions;
