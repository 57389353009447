import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";

// Stateの初期状態
const initialState = {
    policy_body: {},
    open_error_dialog: false,
};

export const FetchPolicy = createAsyncThunk(
    "Policy/FetchPolicy",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;
        const entryState = thunkAPI.getState().Entry;

        let url;
        if (
            informationState.shop.shop_login ||
            entryState.entry_mode === "shop"
        ) {
            url = "/api/terms_shop";
        } else if (
            informationState.user.user_login ||
            entryState.entry_mode === "user"
        ) {
            url = "/api/terms_general";
        } else {
            url = "/api/terms_nologin";
        }

        HttpFunction("GET", url, {}, "")
            .then((data) => {
                // console.log(data);
                if (data) dispatch(set_value(["policy_body", data]));
            })
            .catch((err) => {
                // console.log(err);
                dispatch(set_value(["open_error_dialog", true]));
            });
    }
);

const isRejectedAction = (action) => {
    return action.type.startsWith("Policy") && action.type.endsWith("rejected");
};

// Sliceを生成する
const slice = createSlice({
    name: "Policy",
    initialState,
    reducers: {
        set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(isRejectedAction, (state, action) => {
            state["open_error_dialog"] = true;
        });
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_value } = slice.actions;
