import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    tweet: "",
};
export const PostTweet = (arg) => {
    return AsyncFunction({
        type_name: "Tweet/tweet投稿",
        method: "post",
        url: "/api/post_tweet",
        params: arg,
        api_key: "shop",
    });
};
export const DeleteTweet = (arg) => {
    return AsyncFunction({
        type_name: "Tweet/tweet削除",
        method: "delete",
        url: "/api/delete_tweet",
        params: arg,
        api_key: "shop",
    });
};

const slice = createSlice({
    name: "Tweet",
    initialState,
    reducers: {
        Tweet_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) =>
                action.type.match("Tweet") &&
                action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;
                // if (payload?.menu_list) {
                //     state.menu_list = payload?.menu_list;
                // }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { Tweet_set } = slice.actions;
