import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import fiftyLogo from "/images/Common/fiftyLogo.png";
import fiftyLogoBlue from "/images/Common/fiftyLogoBlue.png";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { set_value } from "../../stores/Information";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    topButton: {
        textAlign: "right",
        height: "10vh",
        "& .MuiButton-root": {
            margin: "15px 10px",
        },
    },
    logoContainer: {
        height: "60vh",
        display: "flex",
        "& > img": {
            width: "200px",
            margin: "auto",
        },
    },
    buttonContainer: {
        textAlign: "center",
        "& .MuiButton-root": {
            display: "block",
            width: "176px",
            margin: "15px auto",
        },
    },
});

const FirstNavi = () => {
    const classes = useStyles();
    const [forMember, setForMember] = React.useState(true);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(set_value(["showDrawer", false]));
        dispatch(set_value(["showBottomNavigation", false]));
    }, []);

    const handleChangeTarget = () => {
        setForMember(!forMember);
    };

    const handleChangePage = (url) => {
        dispatch(set_value(["showDrawer", true]));
        dispatch(set_value(["showBottomNavigation", true]));
        history.push(url);
    };

    return (
        <React.Fragment>
            <div className={classes.topButton}>
                <a href="https://fifty-card.net/privacy" target="_blank">
                    プライバシーポリシー
                </a>
                <Button
                    onClick={handleChangeTarget}
                    color="primary"
                    variant="contained"
                >
                    {forMember ? "加盟店の方はこちら" : "戻る"}
                </Button>
            </div>

            {forMember ? (
                <div className={classes.logoContainer}>
                    <img src={fiftyLogo} />
                </div>
            ) : (
                <>
                    <div className={classes.logoContainer}>
                        <img src={fiftyLogoBlue} />
                    </div>
                </>
            )}
            {forMember ? (
                <div className={classes.buttonContainer}>
                    <Button
                        onClick={() => handleChangePage("/EntryMembers")}
                        variant="contained"
                        color="primary"
                    >
                        新規会員登録
                    </Button>
                    <Button
                        onClick={() => handleChangePage("/LoginMembers")}
                        variant="outlined"
                        color="primary"
                    >
                        ログイン
                    </Button>
                    <Button
                        onClick={() => handleChangePage("/")}
                        color="primary"
                    >
                        ログインせずにはじめる
                    </Button>
                </div>
            ) : (
                <div className={classes.buttonContainer}>
                    <Button
                        onClick={() => handleChangePage("/Entryshop")}
                        variant="contained"
                        color="primary"
                        style={{ background: "rgb(143, 208, 255)" }}
                    >
                        新規加盟店会員登録
                    </Button>
                    <Button
                        onClick={() => handleChangePage("/LoginShop")}
                        variant="outlined"
                        color="primary"
                        style={{
                            border: "solid 1px rgb(143, 208, 255)",
                            color: "rgb(143, 208, 255)",
                        }}
                    >
                        ログイン
                    </Button>
                </div>
            )}
        </React.Fragment>
    );
};

export default FirstNavi;
