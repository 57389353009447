import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Stateの初期状態
const initialState = {
    mascot_data: "",
    show_draw: false,
    last_date: "",
};

// ログインボーナス取得
export const GetMascotDraw = createAsyncThunk(
    "GeneralMascotDraw/GetMascotDraw",
    async(params, thunkAPI) => {
        let api_key = thunkAPI.getState().Information.user.token;

        if (!api_key) api_key = "";
        try {
            const res = await axios.get(
                "/api/mascot_draw",
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );
            return res.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// ログインボーナス既読日時更新、ポイント付与
export const ReadMascotDraw = createAsyncThunk(
    "GeneralMascotDraw/ReadMascotDraw",
    async(params, thunkAPI) => {
        let api_key = thunkAPI.getState().Information.user.token;

        if (!api_key) api_key = "";
        try {
            const res = await axios.post(
                "/api/mascot_read_point",
                params,
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );
            return res.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "GeneralMascotDraw",
    initialState,
    reducers: {
        set_general_mascot: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        [GetMascotDraw.fulfilled]: (state, action) => {
            if (action.payload.result === "OK") {
                state.mascot_data = action.payload.data;
                state.show_draw = true;
            } else {
                state.show_draw = false;
            }
        },
        [GetMascotDraw.rejected]: (state, action) => {
            console.dir(action);
            state.show_draw = false;
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_general_mascot } = slice.actions;
