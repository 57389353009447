export const ApprovalFlag = (approval_flag) => {
    switch (approval_flag) {
        case "0":
            return "未承認";
            break;
        case "1":
            return "承認";
            break;
        case "2":
            return "否認";
            break;

        default:
            return "未申請";
            break;
    }
};

export const PublicStatusFlag = (approval_flag) => {
    switch (approval_flag) {
        case 0:
            return "未公開";
            break;
        case 1:
            return "公開中";
            break;
        case 2:
            return "利用停止";
            break;
        default:
            return "判別不能";
            break;
    }
};
