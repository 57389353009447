import { createSlice } from "@reduxjs/toolkit";

// Stateの初期状態
const initialState = {
    shop_tell: "",
    shop_menu: "shop_menu",
    shop_name: "shop_name",
    shop_comment: "shop_comment",
};
// Sliceを生成する
const slice = createSlice({
    name: "EntryShop",
    initialState,
    reducers: {
        set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_value } = slice.actions;
