import { createAsyncThunk } from "@reduxjs/toolkit";
/**
 * type_name
 * method
 * url
 * params
 * api_key
 */
export const AsyncFunction = function (arg) {
    const AsyncFunc = createAsyncThunk(arg.type_name, async (arg, thunkAPI) => {
        try {
            const { method, url, params, api_key } = arg;
            const informationState = thunkAPI.getState().Information;

            let key = api_key;
            switch (api_key) {
                case "admin":
                    key = informationState.admin?.admin_token;
                    break;
                case "user":
                    key = informationState.user?.token;
                    break;
                case "shop":
                    key = informationState.shop?.shop_token;
                    break;
                default:
                    break;
            }

            const headers = {
                headers: {
                    "API-KEY": key,
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            };

            const encodedurl = url + "?" + new URLSearchParams(params);

            let response;
            switch (method) {
                case "get" || "GET":
                    response = await axios.get(encodedurl, headers);
                    break;
                case "post" || "POST":
                    response = await axios.post(url, params, headers);
                    break;
                case "put" || "PUT":
                    response = await axios.put(url, params, headers);
                    break;
                case "delete" || "DELETE":
                    response = await axios.delete(encodedurl, headers);
                    break;
                default:
                    response = await axios.get(encodedurl, headers);
                    break;
            }

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    });
    return AsyncFunc(arg);
};
