import React, { useState, useEffect } from "react";

import ButtonGeneral from "../utils/ButtonGeneral";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import StoreApplicationEdit from "../modules/StoreApplicationEdit";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {
    release_info_set,
    NewShopInformationApplication,
} from "../../stores/ShopStoreApplication";
import ShopStoreApplicationNullCheck from "../utils/ShopStoreApplicationNullCheck";
import ShopStoreApplicationHoursCheck from "../utils/ShopStoreApplicationHoursCheck";
import ModalProgress from "../utils/ModalProgress";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const ShopStoreApplicationNew = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const thisState = useSelector((state) => state.Entry);
    const classes = useStyles();
    const releaseInfoState = useSelector((state) => state.ShopStoreApplication);
    const SetErrer = (message) => {
        dispatch(release_info_set(["error_message", message]));
    };

    useEffect(() => {
        dispatch(release_info_set(["remarks", "正確な営業日、営業時間などはお店へ直接ご確認ください。"])); //備考初期値
    }, []);

    const OnApplication = () => {
        //必須リスト
        const input_list = {
            shop_name: "店舗名",
            shop_explain: "店舗説明",
            store_pr: "サブタイトル",
            shop_menu: "fiftyメニュー",
            shop_city: "市区町村",
            shop_address1: "町名・番地",
            access: "アクセス",
            business_hours: "営業時間",
            holiday: "定休日",
            shop_phone_number: "電話番号",
            shop_staff: "担当者",
        };

        const null_list = ShopStoreApplicationNullCheck(input_list, releaseInfoState);
        if (null_list.length > 0) {
            SetErrer(["エラー", null_list + "は必須項目です", "warning"]);
            return;
        }

        if ( !releaseInfoState.sns  && !releaseInfoState.url ){
            SetErrer(["エラー", "SNSもしくはURLの入力は必須です", "warning"]);
            return;
        }

        //営業時間チェック
        const hours_check = ShopStoreApplicationHoursCheck(releaseInfoState);
        if(hours_check != null)
        {
            SetErrer(hours_check);
            return;
        }

        dispatch(NewShopInformationApplication());
    };
    const Back = () => {
        history.push("/ShopStoreApplication");
    };

    return (
        <React.Fragment>
            <ChevronLeftIcon onClick={Back} />
            <ModalProgress open={releaseInfoState.modal_progress} />
            <div style={{ margin: "0.5rem" }} className={classes.root}>
                <h1>新規申請</h1>
                <StoreApplicationEdit releaseInfoState={releaseInfoState} />
                <ButtonGeneral onClick={OnApplication}>申請</ButtonGeneral>
            </div>
        </React.Fragment>
    );
};

export default ShopStoreApplicationNew;
