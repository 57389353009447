import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { useDispatch, useSelector } from "react-redux";
// Stateの初期状態
const initialState = {
    audition_seet_list: [],
    offer_info: {
        recruitment_count: 0,
        audition_start: "",
        audition_close: "",
        audition_count: 0,
    },
    serch_info: {
        page_count: "",
        page: "",
        total: "",
        achievement_count_current: "",
    },
};

export const GetAuditionSeet = createAsyncThunk(
    "TALENT/AuditionSeet/GetAuditionSeet",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/audition_seet";

        const params = arg;

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const AuditionSeetCreateApi = createAsyncThunk(
    "TALENT/AuditionSeet/AuditionSeetCreate",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        let url = "/api/audition_seet_create";

        const params = {
            ...arg, //必須
        };

        try {
            const response = await axios.post(url, params, {
                headers: {
                    "API-KEY": api_key,
                    "content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const SetAuditionSeetStatus = createAsyncThunk(
    "TALENT/AuditionSeet/audition_seet_status",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        let url = "/api/audition_seet_status";

        const params = {
            ...arg, //必須
        };

        try {
            const response = await axios.put(url, params, {
                headers: {
                    "API-KEY": api_key,
                    "content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "AuditionSeet",
    initialState,
    reducers: {
        initialize: (state, action) => {
            if (action.payload) {
                state[action.payload] = initialState[action.payload];
            } else {
                initialState;
            }
        },
        audition_seet_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },

    extraReducers: {
        [GetAuditionSeet.fulfilled]: (state, action) => {
            const resdata = action.payload;

            if (resdata.result == "1") {
                state.offer_info = resdata.offer_info;
                state.serch_info = resdata.serch_info;
                state.audition_seet_list = resdata.audition_seet_list;
            }
        },
        [GetAuditionSeet.rejected]: (state, action) => {},

        [AuditionSeetCreateApi.fulfilled]: (state, action) => {
            const resdata = action.payload;

            if (resdata.result == "1") {
                state.audition_seet_list = resdata.audition_seet_list;
                // alert("作成応募が完了しました。");
            }
        },
        [AuditionSeetCreateApi.rejected]: (state, action) => {},
        [SetAuditionSeetStatus.fulfilled]: (state, action) => {
            const resdata = action.payload;

            if (resdata.result == "1") {
                // state.audition_seet_list = resdata.audition_seet_list;
                // alert("作成応募が完了しました。");
            } else {
            }
        },
    },
});

export default slice.reducer;
export const { initialize, audition_seet_set } = slice.actions;
