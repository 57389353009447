import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { Grid } from "@material-ui/core/";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";

import { Swiper, SwiperSlide } from "swiper/react";
import {
    Navigation,
    Autoplay,
    Pagination,
    Scrollbar,
    A11y,
    EffectCoverflow,
    FreeMode,
} from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-coverflow";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "-5px",
        display: "flex",
        "& > *": {
            // margin: theme.spacing(1),
        },
    },
    itemlist: {
        overflowX: "auto",
        whiteSpace: "nowrap",
        "*::-webkit-overflow-scrolling": "touch",
    },
    IconButton: {
        margin: "0",
        padding: "5px",
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        border: "solid 2px pink",
        display: "inline-block",
    },
}));

// const top_talent_list = [
//     { image: "/images/talentBank/test/R/R001.png", url: "/" },
//     { image: "/images/talentBank/test/R/R002.png", url: "/" },
//     { image: "/images/talentBank/test/R/R003.png", url: "/" },
//     { image: "/images/talentBank/test/R/R004.png", url: "/" },
//     { image: "/images/talentBank/test/R/R005.png", url: "/" },
//     { image: "/images/talentBank/test/R/R006.png", url: "/" },
//     { image: "/images/talentBank/test/R/R007.png", url: "/" },
//     { image: "/images/talentBank/test/R/R008.png", url: "/" },
//     { image: "/images/talentBank/test/R/R009.png", url: "/" },
//     { image: "/images/talentBank/test/R/R0010.png", url: "/" },
// ];
const AvatarSlider = (props) => {
    const history = useHistory();
    const classes = useStyles();
    // const [endflag, setflag_end] = React.useState(false);
    const HandleClick = (url) => {
        // history.push(url);
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
        // window.location.href = url;
    };

    const talent_list = props.talent_list;

    const talent_bank_url = process.env.MIX_TALENT_BANK_URL
        ? process.env.MIX_TALENT_BANK_URL
        : "";
    const handleScroll = (e) => {
        const clientWidth = e.target.clientWidth; //表示されている横幅
        const scrollWidth = e.target.scrollWidth; //要素全体の横幅
        const sLeft = e.target.scrollLeft; // 要素のスクロール値

        if (scrollWidth - (clientWidth + sLeft) == 0) {
            props.handleRefresh();
            e.target.scrollTo(0, 0);
        }
    };

    const OnReachEnd = () => {
        props.handleRefresh();
    };
    // const OnSliderMove = (e) => {
    //     if (endflag) {
    //         setflag_end(false);
    //         props.handleRefresh();
    //     }
    // };
    return (
        <React.Fragment>
            <Grid
                style={{
                    display: "block",
                    maxWidth: "800px",
                }}
            >
                {talent_list.length >= 1 && (
                    <Swiper
                        slidesPerView={"auto"}
                        // freeMode={true}
                        spaceBetween={10}
                        modules={[FreeMode, Pagination]}
                        className="mySwiper"
                        rewind={true}
                        onReachBeginning={OnReachEnd}
                    >
                        {talent_list.map((item, index) => (
                            <SwiperSlide
                                key={index}
                                style={{
                                    width: "fit-content",
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    // width: "10rem",
                                }}
                            >
                                <a
                                    href={
                                        talent_bank_url +
                                        "common/TalentDetail/" +
                                        item.talent_id
                                    }
                                >
                                    <Avatar
                                        alt="Remy Sharp"
                                        src={item.image_small}
                                        className={classes.large}
                                        style={{
                                            width: "60px",
                                            height: "60px",
                                        }}
                                    />
                                </a>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
            </Grid>

            {/* <div className={classes.root}>
                <div className={classes.itemlist} onScroll={handleScroll}>
                    {talent_list.map((value, index) => (
                        <IconButton
                            key={index}
                            className={classes.IconButton}
                            onClick={() =>
                                HandleClick(
                                    talent_bank_url +
                                        "common/TalentDetail/" +
                                        value.talent_id
                                )
                            }
                        >
                            <Avatar
                                alt="Remy Sharp"
                                src={value.image_small}
                                className={classes.large}
                            />
                        </IconButton>
                    ))}
                </div>
            </div> */}
        </React.Fragment>
    );
};
export default AvatarSlider;
