import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";
import { set_table_value } from "./HistoryTable";

// Stateの初期状態
const initialState = {
    shop_history: [],
    open_error_dialog: false,
    open_token_error_dialog: false,
};

export const FetchHistory = createAsyncThunk(
    "S_couponUseHistory/FetchHistory",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        let api_key = thunkAPI.getState().Information.shop.shop_token;

        if (!api_key) api_key = "";
        HttpFunction("GET", "/api/shop_history", params, api_key)
            .then((data) => {
                // console.log(data);
                if (data.error_message && data.error_message.startsWith("ログイン情報の取得ができませんでした")) {
                    dispatch(set_value(["open_token_error_dialog", true]));
                } else if (data) {
                    dispatch(set_value(["shop_history", data.data]));
                    dispatch(set_table_value(["total_rows", data.total]));
                    dispatch(set_table_value(["per_page", data.per_page]));
                }
            })
            .catch((err) => {
                // console.log(err);
                dispatch(set_value(["open_error_dialog", true]));
            });
    }
);

const isRejectedAction = (action) => {
    return action.type.startsWith("S_couponUseHistory") && action.type.endsWith("rejected");
};

// Sliceを生成する
const slice = createSlice({
    name: "S_couponUseHistory",
    initialState,
    reducers: {
        set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(isRejectedAction, (state, action) => {
            state["open_error_dialog"] = true;
        });
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_value } = slice.actions;