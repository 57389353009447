import React from "react";
import Button from "@material-ui/core/Button";

export const ButtonContainedPrimary = (props) => {
    return <Button variant="contained" color="primary" {...props}/>;
};

export const ButtonOutlinedPrimary = (props) => {
    return <Button variant="outlined" color="primary" {...props}/>;
};

export const ButtonTextPrimary = (props) => {
    return <Button variant="text" color="primary" {...props}/>;
}