import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
// Stateの初期状態
const initialState = {
    company_list: [],
    company_serch: {
        page: 0,
        page_count: 0,
        total: 0,
    },
    company_detail: {},
};

export const GetCompanyList = createAsyncThunk(
    "TALENT/Company/GetCompanyList",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/company_list";
        const params = arg;

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const GetCompanyDetail = createAsyncThunk(
    "TALENT/Company/GetCompanyDetail",
    async (arg, thunkAPI) => {
        const url = "/api/company_detail";
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const params = arg;

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const CompanyCreateApi = createAsyncThunk(
    "TALENT/Company/CompanyCreateApi",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        let url = "/api/company_create";

        const params = {
            ...arg, //必須
        };

        try {
            const response = await axios.post(url, params, {
                headers: {
                    "API-KEY": api_key,
                    "content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const CompanyFixApi = createAsyncThunk(
    "TALENT/Company/CompanyFixApi",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        let url = "/api/company_fix";

        const params = arg;

        try {
            const response = await axios.put(url, params, {
                headers: {
                    "API-KEY": api_key,
                    "content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "Company",
    initialState,
    reducers: {
        initialize: (state, action) => {
            if (action.payload) {
                state[action.payload] = initialState[action.payload];
            } else {
                initialState;
            }
        },
        company_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        // 企業検索

        [GetCompanyList.fulfilled]: (state, action) => {
            const resdata = action.payload;

            state.company_list = resdata.company_list;
            state.company_serch.page_count = resdata?.serch_info?.page_count;
            state.company_serch.page = resdata?.serch_info?.page;
            state.company_serch.total = resdata?.serch_info?.total;
        },
        // 企業詳細取得
        [GetCompanyDetail.fulfilled]: (state, action) => {
            const resdata = action.payload;

            state.company_detail = resdata.company_info;
        },
        // 企業作成
        [CompanyCreateApi.fulfilled]: (state, action) => {
            const resdata = action.payload;
        },
        // 企業編集
        [CompanyFixApi.fulfilled]: (state, action) => {
            const resdata = action.payload;
        },

        // [CompanyDecision.fulfilled]: (state, action) => {
        //     const resdata = action.payload;
        // },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { initialize, company_set } = slice.actions;
//
// message
// timestamp
// photoURL
// displayName
// avatarDisp
//
//
//
//
