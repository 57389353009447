import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router";

import Pagination from "@material-ui/lab/Pagination";

import { BackButton } from "~CJS/atoms/BackButton";
import { LinkButton } from "~CJS/atoms/LinkButton";
import OfferSearchCriteria from "~CJS/block/OfferSearchCriteria";
import OfferCardList from "~CJS/block/OfferCardList";

import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { initialize, GetOfferList } from "~stores/TALENTBANK/Offer";

const OfferSearch = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const OfferState = useSelector((state) => state.Offer);

    const StertSerch = (criteria) => {
        dispatch(GetOfferList(criteria));
    };

    return (
        <React.Fragment>
            {/* <BackButton history={history} /> */}
            <Container>
                <ButtonNavi
                    left_label="Home画面"
                    left_link="/AdminHome"
                    right_label="お仕事作成"
                    right_link="/OfferCreate"
                />

                <Typography variant="h1">お仕事検索</Typography>

                <OfferSearchCriteria
                    StertSerch={StertSerch}
                    count={OfferState.offer_serch.page_count}
                    criteriaState={OfferState.criteria}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        // alignItems="center"
                    >
                        <OfferCardList offerlist={OfferState.offer_list} />
                    </Grid>
                </OfferSearchCriteria>
            </Container>
        </React.Fragment>
    );
};

export default OfferSearch;
