import React, { useRef } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: "24px",
    },
    label: {
        fontSize: "14.4px",
        margin: "0 0 5px",
    },
    discription: {
        fontSize: "13px",
        margin: "0 0 5px",
        padding: "0 15px 0",
    },
}));

export const TextFieldNormal = (props) => {
    const {label, discription, id, ...other} = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {label && <p className={classes.label}>{label}</p>}
            {discription && <p className={classes.discription}>{discription}</p>}
            <TextField
                id={id}
                fullWidth
                variant="outlined"
                size="small"
                {...other}
            />
        </div>
    );
};

export const TextFieldReadOnly = (props) => {
    const {id, label, discription, value, ...other} = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {label && <p className={classes.label}>{label}</p>}
            {discription && <p className={classes.discription}>{discription}</p>}
            <TextField
                id={id}
                value={value}
                InputProps={{readOnly: true}}
                fullWidth
                {...other}
            />
        </div>  
    );
};

export const TextFieldValidation = (props) => {
    const { label, discription, id, onBlur, trueError, falseError, ...other} = props;
    // trueError/falseErrorは、validationエラーがtrue(エラー有)/false(エラー無)のときに実行される関数
    const ref = useRef(null);
    const classes = useStyles();
    const [error, setError] = React.useState(false);

    const handleBlur = (e) => {
        if (onBlur !== undefined) onBlur(e);
        if (ref.current) {
            if(!ref.current.validity.valid) {
                setError(true);
                if (trueError !== undefined) trueError();
            } else {
                setError(false);
                if (falseError !== undefined) falseError();
            }
        }
    };

    return (
        <div className={classes.root}>
            {label && <p className={classes.label}>{label + " *"}</p>}
            {discription && <p className={classes.discription}>{discription}</p>}
            <TextField
                id={id}
                inputRef={ref}
                helperText={ref?.current?.validationMessage ? ref.current.validationMessage : " "}
                error={error}
                required
                fullWidth
                variant="outlined"
                size="small"
                onBlur={handleBlur}
                {...other}
            />
        </div>
    );
};


// TextFieldValidationの、requiredが無い版
// (label, discriptionも無い)
export const TextFieldValidOptional = (props) => {
    const { id, onBlur, trueError, falseError, ...other} = props;
    // trueError/falseErrorは、validationエラーがtrue(エラー有)/false(エラー無)のときに実行される関数
    const ref = useRef(null);
    const [error, setError] = React.useState(false);

    const handleBlur = (e) => {
        if (onBlur !== undefined) onBlur(e);
        if (ref.current) {
            if(!ref.current.validity.valid) {
                setError(true);
                if (trueError !== undefined) trueError();
            } else {
                setError(false);
                if (falseError !== undefined) falseError();
            }
        }
    };

    return (
        <TextField
            id={id}
            inputRef={ref}
            helperText={ref?.current?.validationMessage ? ref.current.validationMessage : " "}
            error={error}
            fullWidth
            variant="outlined"
            size="small"
            onBlur={handleBlur}
            {...other}
        />
    );
};

export const errorJudge = (error_list) => {
    for (const key in error_list) {
        if (error_list[key]) {
            return true;
        }
        if(error_list[key] === null) {
            return true;
        }
    }
    return false;
};