import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import { useHistory } from "react-router";

import { BackButton } from "~CJS/atoms/BackButton";
import Button from "@material-ui/core/Button";
import TalentProfileInput from "~/block/TalentProfileInput";
import OptionInput from "~CJS/block/OptionInput";
import Typography from "@material-ui/core/Typography";
import { ButtonNavi, Fullwidth } from "~CJS/block/ButtonNavi";

import {
    TalentCreate,
    GetDetail,
    initialize,
    talent_set,
} from "~stores/TALENTBANK/Talent";

const TalentNew = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.Talent);

    useEffect(() => {
        dispatch(initialize());
        dispatch(GetDetail());
        // dispatch(set_entry_value(["registering_user", true]));
    }, []);

    const HandleSubmit = (e) => {
        e.preventDefault();

        const elements = e.target?.elements;

        let input_option = [];
        let category = {};

        const getValue = (name) => {
            let value = "";
            if (elements[name]?.value) {
                value = elements[name]?.value;
            }

            return value;
        };
        const talent_info = {
            email_address: getValue("email_address"),
            password: getValue("password"),
            height: getValue("height"),
            image: getValue("image"),
            image_big: getValue("image_big"),
            age: getValue("age"),
            sns_link: getValue("sns_link"),
            talent_name: getValue("talent_name"),
            talent_name_kana: getValue("talent_name_kana"),
            talent_pr: getValue("talent_pr"),
            skill: getValue("skill"),
            sex: getValue("sex"),
            city: getValue("city"),
            prefectures: getValue("prefectures"),
            address_1: getValue("address_1"),
            address_2: getValue("address_2"),
            tell: getValue("tell"),
            achievement_count: getValue("achievement_count"),
            review_average: getValue("review_average"),
        };
        thisState.talent_detail.input_option.map((value, index) => {
            input_option.push({
                input_id: value.input_id,
                value: elements[value.input_id]?.value,
                type: value.type,
            });
        });
        dispatch(talent_set(["talent_info", talent_info]));
        const params = {
            talent_info: talent_info,
            input_option: input_option,
            category: getValue("category"),
        };
        dispatch(TalentCreate(params)).then((action) => {
            if (action.payload.result == "1") {
                history.push(
                    `/TalentDetail/${action.payload?.talent_info?.talent_id}`
                );
                alert("タレントを作成しました。");
            } else {
                // action.payload.error_message &&
                //     alert(action.payload.error_message);
            }
        });
    };

    return (
        <React.Fragment>
            <Container>
                <ButtonNavi
                    left_label="タレント検索"
                    left_link="/TalentSearch"
                />

                <Typography variant="h1">タレント作成</Typography>

                <form onSubmit={HandleSubmit}>
                    <TalentProfileInput create {...thisState.talent_detail} />
                    <OptionInput
                        inputs={thisState.talent_detail?.input_option}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        fullWidth
                    >
                        作成
                    </Button>
                </form>
            </Container>
        </React.Fragment>
    );
};

export default TalentNew;
