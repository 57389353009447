import React, { useEffect } from "react";

// ダイアログ用
import Button from "@material-ui/core/Button";
import shopRecruitingImg from "/images/Common/shop_recruiting.png";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core";
import { notice_set_value, GetPopup } from "../../stores/NotificationList";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import PopupDialog from "./PopupDialog";

import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";

const PopupNotification = (props) => {
    const dispatch = useDispatch();
    const noticeState = useSelector((state) => state.NotificationList);
    const popup = noticeState.notification_popup;

    // ダイアログ用----------------
    const [open, setOpen] = React.useState(true);

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    const handleClose = () => {
        setOpen(false);
        dispatch(notice_set_value(["popup_show", true]));
    };
    useEffect(() => {
        dispatch(GetPopup());
    }, []);

    // ---------------------------
    return (
        <React.Fragment>
            {noticeState.popup_show == false &&
            popup &&
            popup.notification_detail ? (
                <>
                    <PopupDialog
                        notification_detail={popup.notification_detail}
                        image_path={popup.image_path}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Transition}
                        link_url={popup.button_url}
                    />
                </>
            ) : (
                ""
            )}
        </React.Fragment>
    );
};

export default PopupNotification;
