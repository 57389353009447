const IsEmpty = (value) => {
    switch (typeof value) {
        case 'string' :
            return value == '' ? true : false;
        case 'object' :
            return Object.keys(value).length ? false : true;
        case 'boolean':
            return false;
        case 'integer':
            return false;
        case 'number':
            return value ? true : false;
        default:
            // console.log((typeof value))
            return false;
    }
}

export default IsEmpty