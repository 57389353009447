import React, { useEffect } from "react";
import fifty_card_white from "/images/card/fifty_card_white.png";
import fifty_card_blue from "/images/card/fifty_card_blue.png";
import fifty_card_green from "/images/card/fifty_card_green.png";
import fifty_card_pink from "/images/card/fifty_card_pink.png";
import fifty_card_yellow from "/images/card/fifty_card_yellow.png";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import QRCode from "qrcode.react";
import Countdown from "react-countdown";
import CachedIcon from "@material-ui/icons/Cached";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { set_value } from "../../stores/QRCodeDisplay";
import { UserInfoGet } from "../../stores/Information";
import Chip from "@material-ui/core/Chip";
import HttpFunction from "../utils/HttpFunction";

import { FormControlLabel, makeStyles, Switch } from "@material-ui/core";

import { useZxing } from "react-zxing";
import { useState } from "react";

const fifty_cards = [
    fifty_card_pink,
    fifty_card_blue,
    fifty_card_green,
    fifty_card_yellow,
    fifty_card_white,
];

const useStyles = makeStyles((theme) => ({
    qr_grid: {
        borderRadius: "1rem",
        padding: "1rem",
        margin: "2%",
        width: "95%",
    },
    qr_grid_top: {
        minHeight: "3rem",
    },
}));

const QRCodeDisplay = () => {
    const classes = useStyles();
    const history = useHistory();
    const thisState = useSelector((state) => state.QRCodeDisplay);
    const InformationState = useSelector((state) => state.Information);
    const dispatch = useDispatch();

    const GetQRcode = () => {
        const api_key = InformationState.user.token;
        const params = "";
        HttpFunction("GET", "/api/qr_create", params, api_key)
            .then((data) => {
                if (data.error_message) {
                    // console.log(data.error_message);
                    return;
                }
                // // console.log(data.qr_key);
                // // console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");
                // // console.log(new Date());
                // // console.log(Date.now());
                // // console.log(data.qr_time_expiration);
                // // console.log(Date.parse(data.qr_time_expiration));
                // // console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");

                dispatch(set_value(["day", Date.parse(data.expired)]));
                dispatch(
                    set_value(["qr_count_key", thisState.qr_count_key + 1])
                );
                dispatch(set_value(["qr_value", data.qr_key]));
            })
            .catch((err) => {
                dispatch(set_value(["qr_value", "QR_ERROR"]));
                // console.log("err:" + err);
            });
    };

    const CountdownRenderer = ({ hours, minutes, seconds, completed }) => {
        const QRUpdate = () => {
            GetQRcode();
        };

        if (completed) {
            // Render a completed state
            return (
                <>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        className={classes.qr_grid_top}
                    >
                        <div>
                            QRコードの
                            <br />
                            有効期限が切れました
                            <br />
                            更新してください。
                        </div>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={QRUpdate}
                        >
                            更新
                            <CachedIcon />
                        </Button>
                    </Grid>
                </>
            );
        } else {
            // Render a countdown
            return (
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className={classes.qr_grid_top}
                >
                    <span>
                        有効期限{minutes}分{seconds}秒
                    </span>
                    <Button
                        variant="contained"
                        color="default"
                        onClick={QRUpdate}
                    >
                        更新
                        <CachedIcon />
                    </Button>
                </Grid>
            );
        }
    };
    const OnGeneralRedeemingPoint = () => {
        history.push("/GeneralRedeemingPointList");
    };
    const OnEntry = () => {
        history.push("/EntryMembers");
    };

    useEffect(() => {
        dispatch(UserInfoGet(InformationState.user.token));
        GetQRcode();
        // setInitUrl("");
    }, []);

    // QRコードリーダー機能（店舗QR読み取り機能）
    // ボタンでカメラ起動
    // const [ initUrl, setInitUrl] = useState("");
    // const [ result, setResult ] = useState('');
    // const [ stepCount , setStepCount ] = useState(0);

    // const handleClickStepCount = (event) => {
    //     setStepCount(stepCount + 1);
    // }
    
    // カメラで値読み取り
    // useEffect(() => {
    //     // 読み取り完了時に値が店舗IDが読めた場合はQRReadingへ値を持って遷移 
    //     // →resultにMenuUseがある場合は遷移処理
    //     console.log("result");
    //     console.log(result);
    //     if (result.includes("MenuUse?id")) {
    //         const setInitUrl = result.substring(result.indexOf('MenuUse')-1).replace(/"/g,"");
    //         // console.log("setInitUrl");
    //         // console.log(setInitUrl);
    //         // 遷移するための処理へ移動（URL付）
    //         qrPointGetPage(setInitUrl);
    //     } else if(!result) {
    //         // 初期値のときは何もしない
    //     } else {
    //         // 読み取り完了時に値が店舗IDが読みない場合はエラーメッセージ表示
    //         alert("店舗QRコードを再度読み直してください。");
    //         setResult("");
    //     }
    // }, [result]);

    // QRコードリーダー機能（店舗QR読み取り機能）
    function qrReader() {
        if (typeof flutter !== "undefined") {
            window.flutter_inappwebview
                .callHandler("QRcodeRead")
                // .then((result) => {
                //     const qr = JSON.stringify(result);
                //     // setResult(qr);
                //     if (qr.includes("MenuUse?id")) {
                //         // setInitUrl(qr.substring(qr.indexOf('MenuUse')-1).replace(/"/g,""));
                //         // console.log("setInitUrl");
                //         // console.log(setInitUrl);
                //         // 遷移するための処理へ移動（URL付）
                //         history.push(qr.substring(qr.indexOf('MenuUse')-1).replace(/"/g,""));
                //         // if(initUrl) {
                //         //     const PageChange = confirm('QRコードを読み取りました。画面遷移しますか？');
                //         //     setInitUrl("");
                //         //     if(PageChange) {
                //         //         history.push(setInitUrl);
                //         //     } else {
                //         //         // キャンセルの場合はなにもしない
                //         //     }   
                //         // } else {
                //         //     setInitUrl("");
                //         //     alert("店舗QRコードを再度読み直してください。エラーコード:1");
                //         // }
                //     } else {
                //         // 読み取り完了時に値が店舗IDが読みない場合はエラーメッセージ表示
                //         alert("店舗QRコードを再度読み直してください。");
                //     }
                // })
                // .catch((err) => {
                //     console.log(JSON.stringify(err));
                //     alert("カメラの起動に失敗しました。");
                // });
        } else {
            alert("フィフティーカードアプリを利用してください。");
        }
    }

    // const qrPointGetPage = (url) => {
    //     if(url) {
    //         // const PageChange = confirm('QRコードを読み取りました。画面遷移しますか？');
    //         if(confirm('QRコードを読み取りました。画面遷移しますか？')) {
    //             history.push(url);
    //         } else {
    //             // キャンセルの場合はなにもしない
    //             alert("画面遷移をキャンセルしました。");
    //         }   
    //     }
    // }

    return (
        <React.Fragment>
            <div
                className="flip-boards"
                style={{
                    maxWidth: "500px",
                    margin: "0px",
                    padding: "0px",
                    marginRight: "auto",
                    marginLeft: "auto",
                }}
            >
                <div className="board left">
                    {InformationState.user.user_login ? (
                        <>
                            　　
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                            >
                                <img
                                    className="fifty-card"
                                    src={
                                        fifty_cards[
                                            InformationState.user.rank_type - 1
                                        ]
                                    }
                                    // onClick={handleClickStepCount}
                                />
                            </Grid>
                            {/* カメラ起動処理 */}
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="flex-start"
                                style={{
                                    margin: "10px 0px 20px 0px"
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={qrReader}
                                >
                                    カメラを起動する
                                </Button>
                            </Grid>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Chip
                                    label={`${InformationState.user.rank_color_name}会員`}
                                    style={{
                                        fontSize: "1.5rem",
                                        padding: "1rem",
                                        backgroundColor: `#${InformationState.user.rank_color_cd}`,
                                        border: "0.5px solid #dcdcdc",
                                    }}
                                />
                                現在の保有パカッティー
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ fontSize: "2rem" }}
                                >
                                    {InformationState.user.point_sum}P
                                </Grid>
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: `#${InformationState.user.rank_color_cd}`,
                                        border: "0.5px solid #dcdcdc",
                                    }}
                                    onClick={OnGeneralRedeemingPoint}
                                >
                                    商品と交換する
                                </Button>
                            </Grid>
                        </>
                    ) : (
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <div
                                style={{
                                    fontSize: "2rem",
                                }}
                            >
                                会員証
                            </div>
                            <div
                                style={{
                                    fontSize: "1.5rem",
                                    border: "solid 1px ",
                                    marginTop: "2rem",
                                    marginBottom: "2rem",
                                }}
                            >
                                この機能を利用するには <br />
                                会員登録が必要です
                            </div>

                            <Button
                                variant="contained"
                                style={{
                                    fontSize: "1.5rem",
                                    border: "solid 1px ",
                                    paddingLeft: "2rem",
                                    paddingRight: "2rem",
                                }}
                                onClick={OnEntry}
                            >
                                登録
                            </Button>
                        </Grid>
                    )}
                </div>
            </div>
            <div style={{textAlign:"center"}}>
                <img src="/images/Common/IMG_2179.jpg" width="80%" />
            </div>
        </React.Fragment>
    );
};

export default QRCodeDisplay;
