import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import {
    initialize,
    GetCategoryList,
    DeleteCategory,
    CreateCategory,
} from "~stores/TALENTBANK/Category";

import {
    GetTagList,
    TagListSet,
    TagUpdate,
    TagCreate,
} from "../../stores/TagList";

import { ButtonNavi } from "~CJS/block/ButtonNavi";

import TagList from "~CJS/atoms/TagList";

const SelectGenre = (props) => {
    return (
        <>
            <TextField
                style={{ width: "100%", marginBottom: "1rem" }}
                select
                name="genre_id"
                label="ジャンル"
                fullWidth
                variant="outlined"
                SelectProps={{
                    native: true,
                }}
                {...props}
            >
                <option value={"0000001"}>飲食</option>
                <option value={"0000002"}>美容</option>
                <option value={"0000003"}>その他</option>
            </TextField>
        </>
    );
};

const AdminTagList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.Category);
    const tagListState = useSelector((state) => state.TagList);

    useEffect(() => {
        dispatch(initialize());
        dispatch(GetTagList());

        // dispatch(GetCategoryList({ item_type: 1 }));
    }, []);
    // const TagClick = (category_name, category_id) => {
    //     const result = window.confirm(
    //         `｢${category_name}｣を削除します。\nよろしいですか?`
    //     );

    //     if (!result) return;

    //     dispatch(
    //         DeleteCategory({ item_type: 1, category_id: category_id })
    //     ).then((data) => {
    //         dispatch(GetCategoryList({ item_type: 1 }));
    //     });
    // };
    const CreateClick = (e) => {
        e.preventDefault();
        const elem = e.target;
        if (!e.target.elements) return;
        if (!elem.genre_id.value) return;
        if (!elem.tag_name.value) return;

        dispatch(
            TagCreate({
                genre_id: elem.genre_id.value,
                tag_name: elem.tag_name.value,
            })
        ).then((data) => {
            dispatch(GetTagList());
        });
    };

    const HandleDelete = (data) => {
        const result = window.confirm(
            `｢${data.tag_name}｣を削除します。\nよろしいですか?`
        );
        if (!result) return;
        dispatch(
            TagUpdate({
                tag_id: data.tag_id,
                data: { delete_flag: "1" },
            })
        ).then((data) => {
            dispatch(GetTagList());
        });
    };

    const TagClick = (data) => {
        if (data.recommend_flag == "1") {
            const result = window.confirm(
                `｢${data.tag_name}｣のおすすめを解除します。\nよろしいですか?`
            );

            if (!result) return;
            dispatch(
                TagUpdate({
                    tag_id: data.tag_id,
                    data: { recommend_flag: "0" },
                })
            ).then((data) => {
                dispatch(GetTagList());
            });
        } else {
            const result = window.confirm(
                `｢${data.tag_name}｣をおすすめに登録します。\nよろしいですか?`
            );
            if (!result) return;
            dispatch(
                TagUpdate({
                    tag_id: data.tag_id,
                    data: { recommend_flag: "1" },
                })
            ).then((data) => {
                dispatch(GetTagList());
            });
        }
    };
    return (
        <React.Fragment>
            <Container>
                <ButtonNavi left_label="戻る" left_link={`/AdminHome`} />
                <Typography variant="h1">タグ管理</Typography>

                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        padding: "1rem",
                        border: "solid 2px pink",
                    }}
                >
                    <Typography variant="h6">登録中タグ一覧</Typography>

                    <Grid>
                        {Object.keys(tagListState.tag_list).map(
                            (key, outindex) => (
                                <div key={outindex + "_tagout"}>
                                    <h2>{key}</h2>
                                    {tagListState.tag_list[key].map(
                                        (value, index) => (
                                            <Chip
                                                key={index + "_taglist"}
                                                label={value.tag_name}
                                                name={value.tag_id}
                                                style={{ margin: "0.2rem" }}
                                                color={
                                                    value.recommend_flag == "1"
                                                        ? "primary"
                                                        : "default"
                                                }
                                                onClick={() => TagClick(value)}
                                                onDelete={() =>
                                                    HandleDelete(value)
                                                }
                                            />
                                        )
                                    )}
                                </div>
                            )
                        )}
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        padding: "1rem",
                        border: "2px solid pink",
                        marginTop: "1rem",
                        boxShadow: "rgb(0 0 0 / 50%) 0px 10px 8px 0px",
                        borderRadius: "5rem",
                    }}
                >
                    <Typography variant="h6">タグの追加</Typography>
                    <form onSubmit={CreateClick}>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                style={{
                                    width: "10rem",
                                }}
                            >
                                <SelectGenre />
                            </Grid>
                            <Grid
                                item
                                style={{
                                    width: "30rem",
                                }}
                            >
                                <TextField
                                    fullWidth
                                    name="tag_name"
                                    variant="outlined"
                                    label="タグ名"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                ></TextField>
                            </Grid>
                        </Grid>
                        <Grid item style={{ margin: "0.5rem" }}>
                            <Button
                                fullWidth
                                type="submit"
                                color="primary"
                                variant="contained"
                            >
                                追加
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default AdminTagList;
