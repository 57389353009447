import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import {
    initialize,
    GetCategoryList,
    DeleteCategory,
    CreateCategory,
} from "~stores/TALENTBANK/Category";

import TalentProfileInput from "~/block/TalentProfileInput";

import OptionInput from "~CJS/block/OptionInput";
import { LinkButton } from "~CJS/atoms/LinkButton";
import { ButtonNavi } from "~CJS/block/ButtonNavi";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TagList from "~CJS/atoms/TagList";

const CategoryList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.Category);

    useEffect(() => {
        dispatch(initialize());
        dispatch(GetCategoryList({ item_type: 1 }));
    }, []);
    const TagClick = (category_name, category_id) => {
        const result = window.confirm(
            `｢${category_name}｣を削除します。\nよろしいですか?`
        );

        if (!result) return;

        dispatch(
            DeleteCategory({ item_type: 1, category_id: category_id })
        ).then((data) => {
            dispatch(GetCategoryList({ item_type: 1 }));
        });
    };
    const CreateClick = (e) => {
        e.preventDefault();
        const elem = e.target.elements;
        if (!e.target.elements) return;
        if (!elem.category_name.value) return;
        dispatch(
            CreateCategory({
                item_type: 1,
                category_name: elem.category_name.value,
            })
        ).then((data) => {
            dispatch(GetCategoryList({ item_type: 1 }));
        });
    };
    return (
        <React.Fragment>
            <Container>
                <ButtonNavi left_label="戻る" left_link={`/AdminHome`} />
                <Typography variant="h1">ジャンル管理</Typography>

                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        padding: "1rem",
                        border: "solid 2px pink",
                    }}
                >
                    <Typography variant="h6">登録中ジャンル一覧</Typography>
                    <TagList
                        TagClick={TagClick}
                        onDelete={TagClick}
                        tag_list={thisState.category_list}
                        tag_select_list={[]}
                    />
                </Grid>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        padding: "1rem",
                        border: "solid 2px pink",
                        marginTop: "1rem",
                    }}
                >
                    <Typography variant="h6">ジャンル追加</Typography>
                    <form onSubmit={CreateClick}>
                        <Grid
                            item
                            style={{
                                width: "30rem",
                            }}
                        >
                            <TextField
                                fullWidth
                                name="category_name"
                                variant="outlined"
                                label="ジャンル名"
                            ></TextField>
                        </Grid>
                        <Grid item style={{ margin: "0.5rem" }}>
                            <Button
                                fullWidth
                                type="submit"
                                color="primary"
                                variant="contained"
                            >
                                追加
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default CategoryList;
