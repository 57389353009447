import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { DataGrid } from "@material-ui/data-grid";
import { GetBlogList, PostBlog, DeleteBlog } from "~stores/AdminBlogSetting";
import { GetBlogGeneral, PostBlogShop, DeleteBlogShop } from "~stores/Blog";
import {
    FormatTime,
    ParseTime,
    FormatOpeningHours,
} from "~/functions/DateTimeFunctions";

import ReactImageBase64 from "react-image-base64";

const Blog = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const search = useLocation().search;
    const BlogState = useSelector((state) => state.Blog);

    const query = new URLSearchParams(search);

    const shop_id = query.get("shop_id");
    const blog_id = query.get("blog_id");

    useEffect(() => {
        dispatch(GetBlogGeneral({ blog_id: blog_id }));
    }, []);
    // const [add_flag, set_add_flag] = useState(false);
    // const [shop_id, set_shop_id] = useState("");
    // const [blog_image, set_blog_image] = useState("");

    return (
        <React.Fragment>
            <ButtonNavi
                left_label="戻る"
                left_props={{ onClick: () => history.goBack() }}
                // left_link={`/BlogList/?shop_id=${shop_id}`}
            />
            {/* <h1>ブログ</h1> */}
            {/* {BlogState?.blog_data?.id} */}
            <Grid
                container
                direction="column"
                justifyContent="space-around"
                alignItems="flex-start"
                style={{ padding: "1rem" }}
            >
                <Typography variant="h6">
                    {BlogState?.blog_data?.title}
                </Typography>

                <Typography variant="caption">
                    投稿日:{" "}
                    {FormatTime(
                        ParseTime(BlogState?.blog_data?.updated_at),
                        "jp_date_time"
                    )}
                </Typography>
                <Typography variant="caption">
                    投稿者:{" "}
                    {BlogState?.blog_data?.nickname}
                </Typography>
            </Grid>
            
            <Grid
                container
                direction="column"
                justifyContent="space-around"
                alignItems="flex-start"
                style={{ padding: "1rem" }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <img src={BlogState?.blog_data?.image_path} width="80%" />
                </Grid>

                <Typography variant="body1">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: BlogState?.blog_data?.text ?
                                BlogState.blog_data.text.replace(
                                    /\n/g,
                                    "<br>"
                                )
                                : "",
                        }}
                    ></div>
                </Typography>
            </Grid>
        </React.Fragment>
    );
};

const ImageInput = (props) => {
    const { image, HandleImageClick, name } = props;

    return (
        <Grid
            direction="column"
            justifyContent="center"
            alignItems="center"
            container
        >
            <label
                htmlFor={`image_upload_${name}`}
                style={{ marginBottom: "0" }}
            >
                <img
                    src={image ? image : "null"}
                    height="300px"
                    style={{ margin: "1rem" }}
                    onError={(e) => {
                        e.target.onerror = "null";
                        e.target.src = "/images/upload.png";
                    }}
                />
            </label>
            {/* <Input type="file" id="image_upload" name="image" onChange={HandleChange} hidden /> */}
            {/* <img src={images?.fileData} /> */}
            <div style={{ display: "none" }}>
                <ReactImageBase64
                    maxFileSize={10485760}
                    thumbnail_size={500}
                    drop={false}
                    hidden={true}
                    // capture="environment"
                    multiple={true}
                    id={`image_upload_${name}`}
                    name={name}
                    handleChange={(data) => HandleImageClick(data, name)}
                />
            </div>
        </Grid>
    );
};

export default Blog;
