import React, { useEffect } from "react";

import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import GradeIcon from "@material-ui/icons/Grade";
import { useHistory } from "react-router-dom";
import { set_value } from "../../stores/Information";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { notice_set_value, GetNotices } from "../../stores/NotificationList";
import HttpFunction from "../utils/HttpFunction";
import { withStyles } from "@material-ui/styles";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    toolbar: {
        minHeight: "35px",
    },
    appBar: {
        // height: "36px",
        // minHeight: "36px",
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        minHeight: "auto",
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        // padding: "0px",
        marginRight: "1px",
    },
    bellButton: {
        marginLeft: "auto",
        marginRight: "0px",
        paddingRight: "0px",
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
        minHeight: "28px",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

const PinkAppBar = withStyles({
    root: {
        backgroundColor: "pink",
    },
})(AppBar);

export default function DrawerLeft() {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const informationState = useSelector((state) => state.Information);

    const notification_list_data = useSelector(
        (state) => state.NotificationList.notification_list_data
    );
    const read_count = useSelector(
        (state) => state.NotificationList.read_count
    );

    const GetNotification = () => {
        dispatch(GetNotices());
    };

    // URLのパスが変更されるたびに通知を確認する。
    const path = history.location.pathname;
    useEffect(() => {
        if (path == "/") {
            GetNotification();
            // console.log(path);
        }
    }, [path]);

    const notification_read_count = () => {
        if (!notification_list_data) return;
        const read_list = notification_list_data.map((obj) => obj.read_flag);
        let count = 0;
        for (let i = 0; i < read_list.length; ++i) {
            if (read_list[i] !== "1") count++;
        }
        return count;
    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const LinkClose = (e) => {
        setOpen(false);

        const pattern = /^https?:\/\//i;
        if (pattern.test(e)) {
            location.href = e;
        }

        history.push(e);
    };

    // ハンバーガーメニューの表示内容一覧/////////////////////////
    // 未ログイン(一般/加盟店)
    const nonLoginMenuList = [
        "新規会員登録・ログイン",
        "使い方",
        "よくある質問・お問合せ",
        "利用規約",
        "公式SNS",
        "加盟店募集中！",
    ];
    const nonLoginMenuLinkList = [
        "/FirstNavi",
        "/Guide",
        "/FAQ",
        "/Policy",
        "/OfficialSNS",
        "/NewShopApplication",
    ];

    // 一般ログイン済み
    const userMenuLink = [
        "マイページ",
        // "利用履歴",
        // "ポイント交換",
        // "ポイント履歴",
        "利用方法",
        "よくある質問",
        "利用規約",
        "公式SNS",
        "加盟店募集中！",
        "タレント登録",
        "ログアウト",
    ];
    const userMenuLinkList = [
        "/MemberInformation",
        // "/U_couponUseHistory",
        // "/GeneralRedeemingPointList",
        // "/U_pointHistory",
        "/Guide",
        "/FAQ",
        "/Policy",
        "/OfficialSNS",
        "/NewShopApplication",
        "https://talent-park.net",
        "/Logout/user",
    ];

    // 加盟店ログイン済み
    const shopMenuLink = [
        "プロフィール編集",
        "来店履歴",
        "利用規約",
        "使い方",
        "よくある質問・お問合せ",
        "店舗公開情報",
        "ログアウト",
    ];
    const shopMenuLinkList = [
        "/ShopUserInformation",
        "/S_couponUseHistory",
        "/Policy",
        "/Guide",
        "/FAQ",
        "/ShopStoreApplication",
        "/Logout/shop",
    ];

    // 管理者ログイン済み
    const adminMenuLink = [
        "管理者HOME",
        "会員一覧",
        "加盟店一覧",
        "通知設定",
        "パカッティー設定",
    ];
    const adminMenuLinkList = [
        "/AdminHome",
        "/AdminMemberList",
        "/AdminShopList?mode=visit",
        "/AdminNoticeList?mode=visit",
        "/AdminPointSetting",
    ];

    // テスト用
    const testList = [
        "ログイン",
        "使い方",
        "よくある質問・お問合せ",
        "利用規約",
        "公式SNS",
        "加盟店募集中！",
        "プロフィール編集",
        "利用履歴",
        "パカッティー交換",
        "パカッティー履歴",
        "一般会員ログアウト",
        "店舗プロフィール編集",
        "来店履歴",
        "店舗公開情報",
        "加盟店ログアウト",
        "会員一覧",
        "加盟店一覧",
        "通知設定",
    ];
    const testLinkList = [
        "/FirstNavi",
        "/Guide",
        "/FAQ",
        "/Policy",
        "/OfficialSNS",
        "/NewShopApplication",
        "/MemberInformation",
        "/U_couponUseHistory",
        "/GeneralRedeemingPointList",
        "/U_pointHistory",
        "/Logout/user",
        "/ShopUserInformation",
        "/S_couponUseHistory",
        "/ShopStoreApplication",
        "/Logout/shop",
        "/AdminMemberList",
        "/AdminShopList",
        "/AdminNoticeList",
    ];

    const lists = (list, link_list) => {
        return (
            <List>
                {list.map((text, index) => (
                    <ListItem
                        button
                        key={text}
                        onClick={() => LinkClose(link_list[index])}
                    >
                        <ListItemIcon>
                            {index % 2 === 0 ? <GradeIcon /> : <GradeIcon />}
                        </ListItemIcon>

                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
        );
    };
    const countup = () => {
        const count = informationState.count + 1;
        dispatch(set_value(["count", count]));
    };
    return (
        <div className={classes.root}>
            <CssBaseline />
            <PinkAppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(
                            classes.menuButton,
                            open && classes.hide
                        )}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        style={{ marginLeft: "auto" }}
                        noWrap
                    >
                        フィフティーカード
                    </Typography>
                    {informationState.user.user_login ||
                    informationState.shop.shop_login ? (
                        <MenuItem className={classes.bellButton}>
                            <IconButton
                                aria-label="show 11 new notifications"
                                color="inherit"
                                onClick={() => LinkClose("/NotificationList")}
                                className={classes.menuButton}
                            >
                                <Badge
                                    color="secondary"
                                    badgeContent={notification_read_count()}
                                >
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </MenuItem>
                    ) : (
                        ""
                    )}
                </Toolbar>
            </PinkAppBar>

            <Drawer
                className={classes.drawer}
                // variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
                onClose={handleDrawerClose}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </div>

                {
                    // 権限によるハンバーガーメニューの表示制御//////////////////
                    (() => {
                        // テスト用
                        if (
                            informationState.user.user_login &&
                            informationState.shop.shop_login &&
                            informationState.admin.admin_login
                        ) {
                            return lists(testList, testLinkList);
                        } else if (
                            // 一般ログイン済み
                            informationState.user.user_login
                        ) {
                            return lists(userMenuLink, userMenuLinkList);
                        } else if (
                            // 加盟店ログイン済み
                            informationState.shop.shop_login
                        ) {
                            return lists(shopMenuLink, shopMenuLinkList);
                        } else if (
                            // 管理者ログイン済み
                            informationState.admin.admin_login
                        ) {
                            return lists(adminMenuLink, adminMenuLinkList);
                        } else {
                            // 未ログイン(一般/加盟店)
                            return lists(
                                nonLoginMenuList,
                                nonLoginMenuLinkList
                            );
                        }

                        <Divider />;
                    })()
                }

                <div
                    className="show-management"
                    onClick={(e) => countup()}
                ></div>
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />
            </main>
        </div>
    );
}
