import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { Button, Grid, Container } from "@material-ui/core/";

import ModalGeneral from "../utils/ModalGeneral";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import ClearIcon from "@material-ui/icons/Clear";
import { set_alert } from "../../stores/ErrorAlert";

import { GetReleaseSnap } from "../../stores/ShopSnapPosting";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },

    sub_title_button: {
        fintSize: "1vw",
        width: "30px",
    },
    ImageListItemBar: {
        height: "1.5rem",
    },
}));

const SnapList = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const snapState = useSelector((state) => state.ShopSnapPosting);

    const initialState = { show: false };

    const [modal, showModal] = useState(initialState.show);

    const snap_list = snapState.image_list;

    useEffect(() => {
        dispatch(GetReleaseSnap({ shop_id: props.shop_id }));
    }, []);

    useEffect(() => {
        dispatch(set_alert(snapState.error_message));
    }, [snapState.error_message]);

    const OpenModal = (index) => {
        showModal(index);
    };
    const CloseModal = () => {
        showModal(false);
    };

    const ShowModal = () => {
        const index = modal;

        return (
            <ModalGeneral
                open={modal === false ? false : true}
                onClose={CloseModal}
            >
                {modal === false ? (
                    ""
                ) : (
                    <>
                        <Container>
                            <div style={{textAlign: "center"}}>
                                <img
                                    style={{ width: "100%" }}
                                    src={snap_list[index].image_path}
                                    alt={snap_list[index].image_comment}
                                    onError={(e) => {
                                        e.target.onerror = "null";
                                        e.target.src = "/images/no_img.png";
                                    }}
                                />
                            </div>

                            <div>
                                {snap_list[index].image_comment}
                            </div>
                        </Container>
                    </>
                )}
            </ModalGeneral>
        );
    };

    return (
        <React.Fragment>
            <ShowModal />
            <ImageList
                rowHeight={120}
                cols={3}
                gap={2}
                // style={{
                //     padding: "0.2rem",
                // }}
            >
                {snap_list &&
                    snap_list.map((item, index) => (
                        <ImageListItem
                            key={index}
                            style={{
                                boxShadow: "0px 0px 0.2rem 0px rgba(0,0,0,0.6)",
                                // borderRadius: "0.5rem",
                                textAlign: "center",
                            }}
                        >
                            <img
                                src={item.image_path}
                                alt={item.image_comment}
                                onClick={() => OpenModal(index)}
                                onError={(e) => {
                                    e.target.onerror = "null";
                                    e.target.src = "/images/no_img.png";
                                }}
                                style={{width: "auto", height: "100%"}}
                            />
                            {/* <ImageListItemBar
                                    className={classes.ImageListItemBar}
                                    position={"top"}
                                    title={item.image_comment}
                                    actionIcon={
                                        props.OnDelete ? (
                                            <ClearIcon
                                                color="secondary"
                                                onClick={() =>
                                                    props.OnDelete(index)
                                                }
                                            />
                                        ) : (
                                            ""
                                        )
                                    }
                                /> */}
                        </ImageListItem>
                    ))}
            </ImageList>
        </React.Fragment>
    );
};

export default SnapList;
