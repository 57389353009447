

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/functions/AsyncFunction";

const initialState = {
    enquete_list: [],
};

export const GetEnquete = (arg) => {
    return AsyncFunction({
        type_name: "Enquete/アンケート情報の取得",
        method: "get",
        url: "/api/GetEnquete",
        params: arg,
        api_key: '',
    });
};

export const PostEnquete = (arg) => {
    return AsyncFunction({
        type_name: "Enquete/アンケート投稿",
        method: "post",
        url: "/api/PostEnquete",
        params: arg,
        api_key: 'user',
    });
};
const slice = createSlice({
    name: "Enquete",
    initialState,
    reducers: {
        EnqueteSet: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) =>
                action.type.match("Enquete/") &&
                action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;
                if (payload?.enquete_list) {
                    state.enquete_list = payload?.enquete_list;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { EnqueteSet } = slice.actions;
