import React, { useEffect } from "react";

import { useHistory, useParams } from "react-router";
import { useDispatch } from "react-redux";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { logout , set_value } from "../../stores/Information";

const Logout = () => {
    const { type } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    // console.log("type:"+ type);

    useEffect(() => {
        dispatch(set_value(["showDrawer", false]));
        dispatch(set_value(["showBottomNavigation", false]));
        if(typeof flutter !== "undefined" && type !== null) {
            window.flutter_inappwebview.callHandler("removeUserToken",type);
        }
        switch (type) {
            case "user":
                dispatch(logout(["user"]));
            case "shop":
                dispatch(logout(["shop"]));
                break;
            case "management":
                dispatch(logout(["admin"]));
                break;
        }
        setTimeout(() => history.push("/FirstNavi"), 2000);
    });

    return (
        <React.Fragment>
                <Container>
                    <h1>ログアウト完了</h1>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        ログイン画面に移動します。
                    </Grid>
                </Container>
            </React.Fragment>
    );
};

export default Logout;
