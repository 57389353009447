import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Stateの初期状態
const initialState = {
    nearshop_list: [],
    prefectures: "",
};

export const GetNearShoplist= createAsyncThunk(
    "地域別店舗の取得",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.user.token;

        const params = arg;
        let url = "/api/near_shop_list";

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "ontent-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);
// Sliceを生成する
const slice = createSlice({
    name: "NearShoplist",
    initialState,
    reducers: {
        NearShoplist_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { NearShoplist_set } = slice.actions;
