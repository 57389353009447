import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
    FormatTime,
    ParseTime,
    FormatOpeningHours,
} from "~/functions/DateTimeFunctions";
import { DataGrid } from "@material-ui/data-grid";
import { GetEnqueteList } from "~stores/AdminEnqueteSetting";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const LabelFromType = (type) => {
    switch (type) {
        case "101":
            return "登録時アンケート";
        case "201":
            return "退会時アンケート";
        default:
            return "不明";
    }
};

const AdminEnqueteSetting = () => {
    const dispatch = useDispatch();
    const AEState = useSelector((state) => state.AdminEnqueteSetting);
    const [search_type, set_search_type] = useState("");
    const [search_user_id, set_search_user_id] = useState("");
    const [page, set_page] = useState(1);

    const [selected, set_selected] = useState(false);

    const [enquete_id, set_enquete_id] = useState('');

    useEffect(() => {

        dispatch(GetEnqueteList({ status: search_type }));
    }, []);

    const HandleDelete = (row) => {
        const params = {
            page: page.currentPage,
            status: search_type,
            id: row.id,
        };
        dispatch(DeletePointApplication(params));
    };

    const columns = [
        { field: "id", headerName: "ID", width: 120, hide: true },
        {
            field: "type",
            headerName: "回答場所",
            width: 200,
            valueGetter: (params) => {
                return LabelFromType(params.row.type);
            },
        },
        {
            field: "question",
            headerName: "質問",
            flex: true,
        },
        {
            field: "answer",
            headerName: "回答",
            flex: true,
        },
        {
            field: "user_id",
            headerName: "ユーザーID",
            width: 150,
        },

        {
            field: "created_at",
            headerName: "回答日",
            width: 150,
            valueGetter: (params) => params.row.created_at,

            // FormatTime(ParseTime(params.row.created_at), "jp_date_time"),
        },
        {
            field: "detail",
            headerName: "詳細",
            width: 100,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    // color="secondary"
                    onClick={() => {
                        set_selected(params.row);
                    }}
                >
                    詳細
                </Button>
            ),
        },
    ];

    const onPageChange = (page) => {
        const params = {
            page: page + 1,
            search_type: search_type,
            search_user_id: search_user_id,
        };
        set_page(page + 1);
        dispatch(GetEnqueteList(params));

        // dispatch(GetPointApplicationList(params));
    };

    return (
        <React.Fragment>
            <ButtonNavi left_label="Home画面" left_link="/AdminHome" />
            <h1>アンケート一覧</h1>

            <Grid container style={{ padding: "1rem" }}>
                <Grid style={{ padding: "0rem 2rem" }}>
                    <TextField
                        select
                        label="回答場所"
                        value={search_type}
                        SelectProps={{ native: true }}
                        onChange={(e) => {
                            set_search_type(e.target.value)
                            const params = {
                                search_type: e.target.value,
                                search_user_id: search_user_id,
                                enquete_id: enquete_id,
                            };
                            dispatch(GetEnqueteList(params));



                            // const params = {
                            //     search_type: e.target.value,
                            // };
                            // dispatch(GetEnqueteList(params));
                        }}
                    >
                        <option value="">すべて</option>
                        <option value="101">登録時アンケート</option>
                        <option value="201">退会時アンケート</option>
                        {/* <option value="9">発送済</option> */}
                    </TextField>
                </Grid>


                <Grid style={{ padding: "0rem 2rem" }}>
                    <TextField
                        select
                        label="質問"
                        value={enquete_id}
                        SelectProps={{ native: true }}
                        onChange={(e) => {
                            set_enquete_id(e.target.value);
                            // const params = {
                            //     search_type: e.target.value,
                            // };
                            // dispatch(GetEnqueteList(params));
                        }}
                    >


                        <option value="">すべて</option>
                        {AEState?.enquete_master.map((val, i) => <>
                            <option key={val.enquete_id} value={val.enquete_id}>{val.question}</option>
                        </>)}



                        {/* <option value="9">発送済</option> */}
                    </TextField>
                </Grid>


                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ padding: "2rem 0rem" }}>

                    <Grid style={{ padding: "0rem 2rem" }}>
                        <TextField
                            value={search_user_id}
                            label="ユーザーID"
                            onChange={(e) => {
                                set_search_user_id(e.target.value);
                            }}
                        ></TextField>
                    </Grid>

                    <Grid style={{ padding: "0rem 2rem" }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size='large'
                            onClick={() => {
                                const params = {
                                    search_type: search_type,
                                    search_user_id: search_user_id,
                                    enquete_id: enquete_id,
                                };
                                dispatch(GetEnqueteList(params));
                            }}
                        >
                            検索
                        </Button>
                    </Grid>
                </Grid>

            </Grid>
            <Container>
                <div style={{ height: 800, width: "100%" }}>
                    <DataGrid
                        // autoHeight
                        rowHeight={50}
                        rows={AEState?.enquete_list}
                        columns={columns}
                        pageSize={20}
                        rowsPerPageOptions={[10]}
                        // checkboxSelection
                        disableSelectionOnClick
                        onPageChange={onPageChange}
                        paginationMode="server"

                        // page={AEState.currentPage - 1}
                        rowCount={AEState.total}
                    />
                </div>
            </Container>

            <Dialog
                open={selected ? true : false}
                onClose={() => set_selected(false)}
            >
                <DialogTitle style={{ background: "#fff" }}>詳細</DialogTitle>
                <DialogContent style={{ background: "#fff" }}>
                    <DialogContentText>
                        {LabelFromType(selected.type)}
                    </DialogContentText>
                    <DialogContentText>{selected.question}</DialogContentText>
                    <DialogContentText>{selected.answer}</DialogContentText>
                </DialogContent>
                {/* <DialogActions style={{ background: "#fff" }}>
                    <Button onClick={handleClose} color="primary">
                        確認
                    </Button>
                </DialogActions> */}
            </Dialog>
        </React.Fragment >
    );
};

export default AdminEnqueteSetting;
