import React, { useEffect } from "react";

import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {
    GetDocumentAll,
    set_value,
    initialize,
    CreateDocument,
    UpdateDocument,
} from "../../stores/AdminDocumentSetting";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import ModalGeneral from "../utils/ModalGeneral";
import CodeEditor from "@uiw/react-textarea-code-editor";

const SelectDocumentKubun = (props) => {
    return (
        <>
            <TextField
                style={{ width: "100%", marginBottom: "1rem" }}
                select
                name="document_kubun"
                label="ドキュメント区分"
                fullWidth
                variant="outlined"
                SelectProps={{
                    native: true,
                }}
                {...props}
            >
                <option value={1}>規約</option>
                <option value={2}>使い方</option>
                {/* <option value={3}>Q&A</option> */}
            </TextField>
        </>
    );
};
const SelectControlKubun = (props) => {
    return (
        <>
            <TextField
                style={{ width: "100%", marginBottom: "1rem" }}
                select
                name="control_kubun"
                label="表示対象"
                fullWidth
                variant="outlined"
                SelectProps={{
                    native: true,
                }}
                {...props}
            >
                <option value={0}>全て</option>
                <option value={1}>非ログイン</option>
                <option value={2}>一般会員</option>
                <option value={3}>店舗会員</option>
                <option value={4}>管理者</option>
            </TextField>
        </>
    );
};

const DocumentSetting = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const DocumentState = useSelector((state) => state.AdminDocumentSetting);
    const [modal, set_modal] = React.useState(false);
    const [fix_flag, set_fix_flag] = React.useState(false);

    const [serch_option, set_serch_option] = React.useState({
        document_kubun: 2,
    });

    useEffect(() => {
        dispatch(initialize());
        dispatch(
            GetDocumentAll({ document_kubun: serch_option.document_kubun })
        );
    }, []);

    const ShowDocumentKubun = (document_kubun) => {
        switch (document_kubun) {
            case "1":
                return "規約";
            case "2":
                return "使い方";
            case "3":
                return "Q&A";

            default:
                return "";
        }
    };

    const ShowControlKubun = (control_kubun) => {
        switch (control_kubun) {
            case "0":
                return "全て";
            case "1":
                return "非ログイン";
            case "2":
                return "一般会員";
            case "3":
                return "店舗会員";
            case "4":
                return "管理者";

            default:
                return "";
        }
    };

    const OpenDetail = (value) => {
        set_modal(value);
        set_fix_flag(false);
    };

    const handleChange = (e) => {
        dispatch(set_value([e.target.name, e.target.value]));
    };

    const kubunChange = (e) => {
        set_serch_option({ document_kubun: e.target.value });
        dispatch(GetDocumentAll({ document_kubun: e.target.value }));
    };

    const handleDelete = (id) => {
        dispatch(
            UpdateDocument({
                id: id,
                data: { delete_flag: 1 },
            })
        ).then((data) => {
            dispatch(
                GetDocumentAll({ document_kubun: serch_option.document_kubun })
            );
            set_modal(false);
            set_fix_flag(false);
        });
    };
    const HndleUpdate = (e) => {
        e.preventDefault();

        const elements = e.target?.elements;

        const getValue = (name) => {
            let value = "";
            if (elements[name]?.value) {
                value = elements[name]?.value;
            }

            return value;
        };

        dispatch(
            UpdateDocument({
                id: modal.id,
                data: { text: getValue("text") },
            })
        ).then((data) => {
            dispatch(
                GetDocumentAll({ document_kubun: serch_option.document_kubun })
            );
            set_modal(false);
            set_fix_flag(false);
        });
    };

    const onCreate = () => {
        if (!DocumentState.title1) {
            alert("タイトルは必須項目です");
            return;
        }
        if (!DocumentState.text) {
            alert("本文は必須項目です");
            return;
        }
        dispatch(
            CreateDocument({
                document_kubun: DocumentState.document_kubun,
                title1: DocumentState.title1,
                title2: DocumentState.title2,
                text: DocumentState.text,
                control_kubun: DocumentState.control_kubun,
            })
        ).then((data) =>
            dispatch(
                GetDocumentAll({ document_kubun: serch_option.document_kubun })
            )
        );
    };
    const onFix = () => {
        set_fix_flag(!fix_flag);
    };

    const HeadGlid = (props) => {
        return (
            <Grid
                item
                xs={6}
                style={{
                    background: "pink",
                    padding: "1rem",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    border: "solid 1px",
                    textAlign: "left",
                    margin: "0px",
                }}
                {...props}
            ></Grid>
        );
    };
    const BodyGlid = (props) => {
        return (
            <Grid
                item
                style={{
                    border: "1px solid",
                    textAlign: "center",
                    margin: "0px",
                    padding: "0.5rem",
                }}
                {...props}
            ></Grid>
        );
    };

    return (
        <React.Fragment>
            <Container>
                <ButtonNavi left_label="戻る" left_link={`/AdminHome`} />
                <h1>ドキュメント管理</h1>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        border: "solid 1px",
                        padding: "2rem",
                        marginBottom: "2rem",
                    }}
                >
                    新規作成
                    <SelectDocumentKubun
                        value={DocumentState.document_kubun}
                        onChange={handleChange}
                    />
                    <SelectControlKubun
                        value={DocumentState.control_kubun}
                        onChange={handleChange}
                    />
                    <TextField
                        style={{ marginBottom: "1rem" }}
                        fullWidth
                        label="タイトル"
                        name="title1"
                        variant="outlined"
                        defaultValue={DocumentState.title1}
                        onBlur={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    ></TextField>
                    <TextField
                        style={{
                            marginBottom: "1rem",
                        }}
                        fullWidth
                        label="項目"
                        name="title2"
                        variant="outlined"
                        defaultValue={DocumentState.title2}
                        onBlur={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    ></TextField>
                    <TextField
                        style={{ marginBottom: "1rem" }}
                        fullWidth
                        multiline
                        label="本文"
                        name="text"
                        defaultValue={DocumentState.text}
                        onBlur={handleChange}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            style: { alignItems: "unset", minHeight: "10rem" },
                        }}
                    ></TextField>
                    <Button variant="outlined" onClick={onCreate}>
                        作成
                    </Button>
                </Grid>

                <SelectDocumentKubun
                    onChange={kubunChange}
                    value={serch_option.document_kubun}
                />
                <Grid container>
                    <HeadGlid xs={1}>区分</HeadGlid>
                    <HeadGlid xs={1}>対象</HeadGlid>
                    <HeadGlid xs>タイトル</HeadGlid>

                    {serch_option.document_kubun != "1" && (
                        <HeadGlid xs>項目</HeadGlid>
                    )}
                    <HeadGlid xs={1}>詳細</HeadGlid>
                    {DocumentState?.document_list && (
                        <>
                            {DocumentState.document_list.map((value, index) => (
                                <React.Fragment key={index}>
                                    <Grid container>
                                        <BodyGlid xs={1}>
                                            {ShowDocumentKubun(
                                                value.document_kubun
                                            )}
                                        </BodyGlid>
                                        <BodyGlid xs={1}>
                                            {ShowControlKubun(
                                                value.control_kubun
                                            )}
                                        </BodyGlid>

                                        <BodyGlid xs>{value.title1}</BodyGlid>

                                        {serch_option.document_kubun != "1" && (
                                            <BodyGlid xs>
                                                {value.title2}
                                            </BodyGlid>
                                        )}
                                        <BodyGlid xs={1}>
                                            <Button
                                                variant="outlined"
                                                onClick={() =>
                                                    OpenDetail(value)
                                                }
                                            >
                                                詳細
                                            </Button>
                                        </BodyGlid>
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </>
                    )}
                </Grid>
            </Container>

            <ModalGeneral
                onClose={() => set_modal(false)}
                open={modal ? true : false}
            >
                <Grid
                    container
                    style={{ padding: "1rem", margin: "1rem", width: "auto" }}
                >
                    <div style={{ flexGrow: 1 }}></div>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => handleDelete(modal.id)}
                    >
                        削除
                    </Button>
                </Grid>
                <form onSubmit={HndleUpdate}>
                    <Grid
                        container
                        style={{
                            padding: "1rem",
                            margin: "1rem",
                            width: "auto",
                        }}
                    >
                        <Grid container>
                            <Grid item xs={3}>
                                タイトル
                            </Grid>
                            <Grid item xs={9}>
                                {modal.title1}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={3}>
                                項目
                            </Grid>
                            <Grid item xs={9}>
                                {modal.title2}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={3}>
                                大項目
                            </Grid>
                            <Grid item xs={9}>
                                {modal.title3}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={3}>
                                区分
                            </Grid>
                            <Grid item xs={9}>
                                {ShowDocumentKubun(modal.document_kubun)}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={3}>
                                表示対象
                            </Grid>
                            <Grid item xs={9}>
                                {" "}
                                {ShowControlKubun(modal.control_kubun)}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container style={{ margin: "1rem" }}>
                        {fix_flag ? (
                            <>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={onFix}
                                    style={{ margin: "1rem" }}
                                >
                                    キャンセル
                                </Button>
                                <Button
                                    variant="outlined"
                                    type="submit"
                                    style={{ margin: "1rem" }}
                                >
                                    更新
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    variant="outlined"
                                    onClick={onFix}
                                    style={{ margin: "1rem" }}
                                >
                                    編集
                                </Button>
                            </>
                        )}
                    </Grid>
                    <Grid
                        container
                        style={{
                            margin: "1rem",
                            border: "solid 1px #c2c2c2",
                            width: "auto",
                        }}
                    >
                        {fix_flag ? (
                            <>
                                <InputCode modal={modal} />
                            </>
                        ) : (
                            <>
                                {modal && modal.text && (
                                    <>
                                        <div
                                            style={{
                                                padding: "1rem",
                                            }}
                                            className="html-preview"
                                            dangerouslySetInnerHTML={{
                                                __html: modal.text,
                                            }}
                                        ></div>
                                    </>
                                )}
                            </>
                        )}
                    </Grid>
                </form>
            </ModalGeneral>
        </React.Fragment>
    );
};

const InputCode = (props) => {
    const { modal } = props;

    const [code, setCode] = React.useState(modal.text);
    const [preview_flag, set_preview_flag] = React.useState(false);
    const onPreview = () => {
        set_preview_flag(!preview_flag);
    };

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                style={{ background: preview_flag ? "pink" : "#ff8f8f" }}
            >
                <Button onClick={onPreview}>
                    {" "}
                    {preview_flag ? "コード" : "プレビュー"}
                </Button>
            </Grid>
            {preview_flag ? (
                <div
                    style={{
                        padding: "1rem",
                    }}
                    className="html-preview"
                    dangerouslySetInnerHTML={{
                        __html: code,
                    }}
                ></div>
            ) : (
                <div>
                    <div className="w-tc-editor-var"> </div>
                    <CodeEditor
                        value={code}
                        language="html"
                        name="text"
                        placeholder="Please enter JS code."
                        onChange={(evn) => setCode(evn.target.value)}
                        padding={15}
                        style={{
                            fontSize: 20,
                            backgroundColor: "#f5f5f5",
                            fontFamily:
                                "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace !important",
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default DocumentSetting;
