import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { useDispatch, useSelector } from "react-redux";
// Stateの初期状態
const initialState = {
    enquete_list: [],
    enquete_master: [],
};

export const GetEnqueteList = createAsyncThunk(
    "ADMIN/GetEnqueteList",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/enquete_list";

        const params = arg;

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "AdminEnquete",
    initialState,
    reducers: {
        initialize: (state, action) => {
            if (action.payload) {
                state[action.payload] = initialState[action.payload];
            } else {
                initialState;
            }
        },
        admin_enquete_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        [GetEnqueteList.fulfilled]: (state, action) => {
            const resdata = action.payload;

            if (resdata.result == "OK") {
                state.enquete_list = resdata.enquete_list;
                state.enquete_master = resdata.enquete_master;
                state.total = resdata.total;
            }
        },
        [GetEnqueteList.rejected]: (state, action) => { },
    },
});

export default slice.reducer;
export const { initialize, admin_enquete_set } = slice.actions;
