import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";
import { set_table_value } from "./HistoryTable";

// Stateの初期状態
const initialState = {
    shop_list: [],
    // ---検索条件-----
    freeword: "",
    prefectures: "北海道",
    checkedAllPref: true,
    apply_status: "2",
    detail_on: true,
    detail_off: true,
    stop_release: false,
    // ----------------
    open_search_dialog: false,
    search_triger: 0,
    open_token_error_dialog: false,
    show_alert: false,
    // ----加盟店新規追加------
    open_addition_dialog: false,
    before_register: true,
    new_shop_id: "",
    new_shop_password: "",
    //----------------------
    total_rows: 0,
};

export const GetShopList = createAsyncThunk(
    "AdminShopList/GetShopList",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        let api_key = thunkAPI.getState().Information.admin.admin_token;

        if (!api_key) api_key = "";
        HttpFunction("GET", "/api/shop_list", params, api_key)
            .then((data) => {
                // console.log("shoplist: " + data.data);
                if (
                    data.error_message &&
                    data.error_message.startsWith(
                        "ログイン情報の取得ができませんでした"
                    )
                ) {
                    dispatch(
                        set_shoplist_value(["open_token_error_dialog", true])
                    );
                } else if (data) {
                    dispatch(set_shoplist_value(["show_alert", false]));
                    dispatch(set_shoplist_value(["shop_list", data.data]));
                    dispatch(set_shoplist_value(["total_rows", data.total]));
                    dispatch(set_table_value(["total_rows", data.total]));
                    dispatch(set_table_value(["per_page", data.per_page]));
                }
            })
            .catch((err) => {
                // console.log(err);
                dispatch(set_shoplist_value(["error_title", "エラー"]));
                dispatch(
                    set_shoplist_value([
                        "error_message",
                        "一度前のページに戻りもう一度この画面を開くか、検索条件設定ダイアログの検索ボタンをもう一度押してみてください。",
                    ])
                );
                dispatch(set_shoplist_value(["show_alert", true]));
            });
    }
);

export const CreateShop = createAsyncThunk(
    "AdminShopList/CreateShop",
    async (params, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const thisState = thunkAPI.getState().AdminShopList;
        let api_key = thunkAPI.getState().Information.admin.admin_token;

        if (!api_key) api_key = "";
        HttpFunction("POST", "/api/create_shop", params, api_key)
            .then((data) => {
                // console.log(data);
                if (data.result === "OK") {
                    dispatch(set_shoplist_value(["show_alert", false]));
                    dispatch(set_shoplist_value(["new_shop_id", data.shop_id]));
                    dispatch(
                        set_shoplist_value(["new_shop_password", data.password])
                    );
                    dispatch(set_shoplist_value(["before_register", false]));
                    dispatch(set_table_value(["page", 1]));
                    dispatch(
                        set_shoplist_value([
                            "search_triger",
                            thisState.search_triger + 1,
                        ])
                    );
                } else if (
                    data.error_message &&
                    data.error_message.startsWith(
                        "ログイン情報の取得ができませんでした"
                    )
                ) {
                    dispatch(
                        set_shoplist_value(["open_token_error_dialog", true])
                    );
                }
            })
            .catch((err) => {
                // console.log(err);
                dispatch(set_shoplist_value(["error_title", "エラー"]));
                dispatch(
                    set_shoplist_value([
                        "error_message",
                        "時間をおいて、もう一度やり直してください。",
                    ])
                );
                dispatch(set_shoplist_value(["show_alert", true]));
            });
    }
);

const isRejectedAction = (action) => {
    return (
        action.type.startsWith("AdminShopList") &&
        action.type.endsWith("rejected")
    );
};

// Sliceを生成する
const slice = createSlice({
    name: "AdminShopList",
    initialState,
    reducers: {
        set_shoplist_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];
            if (type) {
                state[type][name] = value;
            } else {
                state[name] = value;
            }
        },
        initialize: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addMatcher(isRejectedAction, (state, action) => {
            state["error_title"] = "エラー";
            state["error_message"] =
                "時間をおいて、もう一度やり直してください。";
            state["show_alert"] = true;
        });
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_shoplist_value, initialize } = slice.actions;
