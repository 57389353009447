import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

const ShopOverviewTop = (props) => {
    const this_shop_data = props.shop_data;

    return (
        <div
            className="shop-overview"
            key={this_shop_data.shop_id}
            style={{
                width: "96%",
                marginRight: "2%",
                marginLeft: "1%",
                marginTop: "0.3rem",
                border: "solid 1px #a3a3a3",
                ...props?.style,
            }}
        >
            <Card
                style={{
                    padding: "0.3rem",
                    minHeight: "280px",
                    fontSize: "90%",
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <div
                        style={{
                            minHeight: "3.5rem",
                            display: "flex",
                        }}
                    >
                        <div
                            style={{
                                paddingRight: 0,
                                paddingLeft: 0,
                                maxWidth: "60%",
                                display: "inline-block",
                            }}
                        >
                            <lavel
                                style={{
                                    backgroundColor: "#f06292",
                                    color: "white",
                                    padding: "0.3rem",
                                    paddingTop: "1rem",
                                    height: "3rem",
                                    display: "table-cell",
                                    fontSize: "0.5rem",
                                    marginRight: "0.5rem",
                                }}
                            >
                                {this_shop_data.shop_prefectures
                                    ? this_shop_data.shop_prefectures
                                    : ""}
                            </lavel>
                        </div>
                        <div
                            style={{
                                paddingRight: 0,
                                paddingLeft: 0,
                                maxWidth: "64%",
                                display: "inline-block",
                                marginLeft: "0.5rem",
                            }}
                        >
                            <Link
                                to={
                                    "/ShopInformation/" + this_shop_data.shop_id
                                }
                            >
                                <h4
                                    style={{
                                        color: "hotpink",
                                        overflow: "hidden",
                                        display: "-webkit-box",
                                        webkitBoxOrient: "vertical",
                                        webkitLineClamp: "2",
                                    }}
                                >
                                    {this_shop_data.shop_name}
                                </h4>
                            </Link>
                        </div>
                    </div>
                </Grid>

                <div
                    className="shop-overview-container"
                    style={{ paddingLeft: "15px" }}
                >
                    <Row>
                        <Col style={{ paddingLeft: 0 }}>
                            <Link
                                to={
                                    "/ShopInformation/" + this_shop_data.shop_id
                                }
                            >
                                <div style={{ textAlign: "-webkit-center" }}>
                                    <Image
                                        src={this_shop_data.shop_image_small}
                                        rounded
                                        className="img-responsive"
                                        onError={(e) => {
                                            e.target.onerror = "null";
                                            e.target.src = "/images/no_img.png";
                                        }}
                                        style={{
                                            width: "auto",
                                            height: "120px",
                                            objectFit: "cover",
                                        }}
                                    />
                                </div>
                            </Link>
                        </Col>
                    </Row>
                </div>
                <Container
                    className="shop-overview-container"
                    style={{ minHeight: "4.7rem" }}
                >
                    {this_shop_data.menu_name || this_shop_data.free_detail ? (
                        <>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                style={{
                                    maxWidth: "450px",
                                    marginRight: "auto",
                                    marginLeft: "-1.0rem",
                                    border: "solid 1px #a3a3a3",
                                    borderRadius: "0.2rem",
                                    padding: "0.5rem",
                                    marginTop: "0.2rem",
                                    display: "block",
                                }}
                            >
                                <Grid style={{ padding: "0.3rem" }}>
                                    {this_shop_data.edit_kubun == "1" ? (
                                        <>
                                            <div
                                                style={{
                                                    fontSize: "0.9rem",
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                    overflow: "hidden",
                                                    display: "-webkit-box",
                                                    webkitBoxOrient: "vertical",
                                                    webkitLineClamp: "1",
                                                    maxWidth: "170px",
                                                }}
                                            >
                                                {this_shop_data.menu_name}
                                            </div>

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                style={{
                                                    display: "grid",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    style={{
                                                        fontSize: "1rem",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {Number(
                                                        this_shop_data?.original_price
                                                    ).toLocaleString() + "円"}
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div
                                                        style={{
                                                            fontSize: "2rem",
                                                            width: "200px",
                                                            margin: '-0.8rem'
                                                        }}
                                                    >
                                                        ⇩
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div
                                                        style={{
                                                            color: "#f84361",
                                                            fontSize: "1rem",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {Number(
                                                            this_shop_data?.half_price
                                                        ).toLocaleString() +
                                                            "円"}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    {this_shop_data.edit_kubun == "2" ? (
                                        <>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <div
                                                    style={{
                                                        fontSize: "0.9rem",
                                                        fontWeight: "bold",
                                                        textAlign: "center",
                                                        overflow: "hidden",
                                                        display: "-webkit-box",
                                                        webkitBoxOrient: "vertical",
                                                        webkitLineClamp: "1",
                                                        maxWidth: "170px",
                                                    }}
                                                >
                                                    {this_shop_data?.menu_name ? this_shop_data.menu_name : ''}
                                                </div>

                                                <Grid
                                                    item
                                                    style={{
                                                        fontSize: "0.9rem",
                                                        fontWeight: "bold",
                                                        overflow: "hidden",
                                                        display: "-webkit-box",
                                                        webkitBoxOrient:
                                                            "vertical",
                                                        webkitLineClamp: "2",
                                                    }}
                                                >
                                                    {this_shop_data?.free_detail}
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        ""
                    )}
                </Container>
            </Card>
        </div>
    );
};

export default ShopOverviewTop;
