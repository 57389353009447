import { createSlice } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    email: "",
    select_genre: "飲食",
    taglist: false,
    serch_text: "",
    serch_area: "",
    shop_list_data: [],
    tag_list: "",
    error_message: false,
    page: "1",
    current_page: 1,
    total_count: "0",
    total_page: "",
    limit: "10",
    order: "i.recommend_flag",
    sort: "desc", //asc or desc
    area_search_flug: true,
    serch_city: "",
    serch_station_name: "",
    price_range: "20000",
    genre_filter: { 飲食: false, 美容: false, その他: false },
};

export const GetShopList = (arg) => {
    return AsyncFunction({
        type_name: "Shoplist/店舗位置情報一覧取得",
        method: "get",
        url: "/api/GetShopList",
        params: arg,
        api_key: "admin",
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "Shoplist",
    initialState,
    reducers: {
        shoplist_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
        tag_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];

            state.tag_list = {
                ...state.tag_list,
                [name]: value,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) =>
                action.type.match("Shoplist") &&
                action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;

                if (payload?.shop_list_data) {
                    state.shop_list_data = payload?.shop_list_data;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { shoplist_set, tag_set } = slice.actions;
