import React from "react";
import { useEffect, useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { createStore } from "redux";
import { Provider } from "react-redux";

import store from "../stores";
import { connect } from "react-redux";
import SlideRoutes from "react-slide-routes";
import { Route, useLocation, Switch } from "react-router-dom";
import GlobalNav from "./modules/GlobalNav";
import Footer from "./modules/Footer";
import Drawer from "./modules/Drawer";
import BottomNavigation from "./modules/BottomNavigation";
import Top from "./pages/Top";
import LoginMembers from "./pages/LoginMembers";
import LoginShop from "./pages/LoginShop";
import EntryShop from "./pages/EntryShop";
import Shoplist from "./pages/Shoplist";
import EntryMembers from "./pages/EntryMembers";
import ShopInformation from "./pages/ShopInformation";
import U_couponUseHistory from "./pages/U_couponUseHistory";
import S_couponUseHistory from "./pages/S_couponUseHistory";
import GeneralRedeemingPointList from "./pages/GeneralRedeemingPointList";
import GeneralFriendInvitation from "./pages/GeneralFriendInvitation";
import U_pointHistory from "./pages/U_pointHistory";
import FixMember from "./pages/FixMember";

import FavoriteShop from "./pages/FavoriteShop";
import LoginAdmin from "./pages/LoginAdmin";
import Policy from "./pages/Policy";
import QRCodeDisplay from "./pages/QRCodeDisplay";
import MemberInformation from "./pages/MemberInformation";
import Guide from "./pages/Guide";
import FAQ from "./pages/FAQ";
import Logout from "./pages/Logout";
import Withdrawal from "./pages/Withdrawal";
import WithdrawalConfirmation from "./pages/WithdrawalConfirmation";
import WithdrawalEnquete from "./pages/WithdrawalEnquete";

import NotificationList from "./pages/NotificationList";
import NotificationInformation from "./pages/NotificationInformation";
import QRReading from "./pages/QRReading";
import FirstNavi from "./pages/FirstNavi";
import NewShopApplication from "./pages/NewShopApplication";
import ErrorPage from "./pages/ErrorPage";
import OfficialSNS from "./pages/OfficialSNS";
import ShopSnapPosting from "./pages/ShopSnapPosting";
import ShopUserInformation from "./pages/ShopUserInformation";
import ShopStoreApplication from "./pages/ShopStoreApplication";
import ShopUserInfoChange from "./pages/ShopUserInfoChange";
import ShopStoreApplicationFix from "./pages/ShopStoreApplicationFix";
import ShopStoreApplicationNew from "./pages/ShopStoreApplicationNew";

import ImportantChangeMember from "./pages/ImportantChangeMember";
import ImportantChangeShopUser from "./pages/ImportantChangeShop";
import BlogList from "./pages/BlogList";
import Blog from "./pages/Blog";
import Tweet from "./pages/Tweet";
import Map from "./pages/Map";
import NewShoplist from "./pages/NewShoplist";
import NearShoplist from "./pages/NearShoplist";
import RecommendShoplist from "./pages/RecommendShoplist";
import UseHistoryShoplist from "./pages/UseHistoryShoplist";

import { set_alert } from "../stores/ErrorAlert";
import ErrorAlert from "./modules/ErrorAlert";
import UserInfoChange from "./pages/UserInfoChange";
import MenuUse from "./pages/MenuUse";

import { pink, red } from "@material-ui/core/colors";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import HttpFunction from "./utils/HttpFunction";
import {
    set_value,
    UserInfoGet,
    ShopUserInfoGet,
    GetLoginToken,
} from "./../stores/Information";
import { useHistory } from "react-router";
import { shoplist_set } from "./../stores/Shoplist";

import ModalProgress from "~CJS/atoms/ModalProgress";
import AdminRoute from "~/route/AdminRoute";
import TalentBankRoute from "~/route/TalentBankRoute";

import ErrerModal from "~/block/ErrerModal";
import { GetUserTokenFlutter } from "./utils/GetUserTokenFlultter";
import { AppSiteLink } from "./utils/AppSiteLink";
import { progress_set } from "./../stores/TALENTBANK/Progress";
import { AsyncFunction } from "~/functions/AsyncFunction";

//TALENTBANK
// import TalentSearch from "~/pages/TALENTBANK/TalentSearch";
// import SponsorSearch from "~/pages/TALENTBANK/SponsorSearch";
// import TalentCreate from "~/pages/TALENTBANK/TalentCreate";
// import TalentDetail from "~/pages/TALENTBANK/TalentDetail";
// import TalentDetailFix from "~/pages/TALENTBANK/TalentDetailFix";
// import OfferSearch from "~/pages/TALENTBANK/OfferSearch";
// import OfferCreate from "~/pages/TALENTBANK/OfferCreate";
// import OfferFix from "~/pages/TALENTBANK/OfferFix";
// import OfferDetail from "~/pages/TALENTBANK/OfferDetail";

import { AppStyle } from "~/AppStyle";
const theme = createTheme({ ...AppStyle });

const App = (state) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const informationState = useSelector((state) => state.Information);
    const { pathname } = useLocation();
    const history = useHistory();
    const ProgressState = useSelector((state) => state.Progress);
    const [initialize, set_initialize] = React.useState(false);

    if (process.env.MIX_APP_ENV === "local") {
        console.log("ローカル環境");
    } else {
        useEffect(() => {
            if (
                !pathname.includes("/disabled_navigation") &&
                !pathname.includes("/Withdrawal") &&
                !pathname.includes("/Logout")
            ) {
                if (pathname.includes("MenuUse")) {
                    const setInitUrl = location.pathname + location.search;
                    if (informationState.user.user_login) {
                        history.push(setInitUrl);
                    } else {
                        dispatch(set_value(["initUrl", setInitUrl]));
                    }
                }
                if (informationState.user.user_login) {
                    dispatch(set_value(["showBottomNavigation", true]));
                    if (informationState.initUrl) {
                        const initURL = informationState.initUrl;
                        dispatch(set_value(["initUrl", null]));
                        if (typeof flutter === "undefined") {
                            history.push("/");
                            if (process.env.MIX_MENU_USE_WEB === "view") {
                                history.push(initURL);
                            } else {
                                AppSiteLink();
                            }
                        } else {
                            history.push(initURL);
                        }
                    } else {
                        if (pathname.includes("MenuUse")) {
                        } else {
                            history.push("/");
                        }
                    }
                } else if (informationState.shop.shop_login) {
                    dispatch(set_value(["showBottomNavigation", true]));
                    history.push("/ShopStoreApplication");
                } else if (pathname.includes("Admin")) {
                    history.push("/LoginAdmin");
                } else {
                    // console.log("login data false!");
                    history.push("/FirstNavi");
                }
            } else {
                // console.log("disabled_navigation , Withdrawal or  Logout ");
            }
        }, [
            informationState.user.user_login,
            informationState.shop.shop_login,
        ]);
    }
    const getUserInfo = (result) => {
        const api_key = result["userToken"];

        if (result["type"] === "user") {
            // 一般会員の場合
            // 課金状態確認処理
            // HttpFunction("POST", "/api/login_pay_check", "", api_key);

            dispatch(
                AsyncFunction({
                    url: "/api/login_pay_check",
                    method: "post",
                    params: "",
                    api_key: api_key,
                    type_name: "ログイン課金チェック",
                })
            )
                .then((res) => {
                    const data = res.payload;
                    if (!data.error_message) {
                        dispatch(set_value(["token", data.token, "user"]));
                        dispatch(
                            set_value([
                                "invitation_cd",
                                data.invitation_cd,
                                "user",
                            ])
                        );
                        dispatch(
                            set_value(["prefectures", data.prefectures, "user"])
                        );
                        dispatch(
                            shoplist_set(["serch_area", data.prefectures])
                        );
                        dispatch(set_value(["user_login", true, "user"]));
                    } else {
                        // console.log("error:" + data.error_message);
                    }
                })
                .catch((err) => {
                    // console.log(err);
                });
        } else if (result["type"] === "shop") {
            // 店舗会員の場合
            dispatch(ShopUserInfoGet(api_key))
                .then((data) => {
                    if (data.payload.data.error_message) {
                        // console.log("error:" + data.payload.data.error_message);
                    } else {
                        dispatch(set_value(["shop_login", true, "shop"]));
                    }
                })
                .catch((e) => {
                    // console.log(e);
                });
        } else {
            // typeエラー
        }
    };

    useEffect(() => {
        dispatch(GetUserTokenFlutter())
            .then((res) => {
                console.log("フラッターの処理");
                console.log(res);
                //
                const userInfo = res.payload;
                if (userInfo) {
                    getUserInfo(userInfo);
                } else {
                    console.log("Not Flutter And pathname has MenuUse");
                    if (
                        pathname.includes("MenuUse") &&
                        process.env.MIX_MENU_USE_WEB !== "view"
                    ) {
                        AppSiteLink();
                    }
                    // dispatch(GetLoginToken());
                }
            })
            .catch((e) => {
                console.log("has Error:" + e);
            });
    }, []);

    useEffect(() => {
        dispatch(set_alert(["", ""]));
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        if (initialize) return;
        dispatch(GetLoginToken()).then((data) => {
            set_initialize(true);
        });
    }, [pathname]);

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                {informationState.showDrawer ? <Drawer /> : ""}
                <ErrerModal />

                <ModalProgress open={ProgressState.Progress} />
                <ErrorAlert />
                <Switch>
                    <Route path="/" component={Top} exact />
                    <Route
                        path="/LoginMembers"
                        component={LoginMembers}
                        exact
                    />
                    <Route path="/LoginShop" component={LoginShop} exact />
                    <Route path="/EntryShop" component={EntryShop} exact />
                    <Route path="/Shoplist" component={Shoplist} exact />
                    <Route
                        path="/EntryMembers"
                        component={EntryMembers}
                        exact
                    />
                    <Route
                        path="/ShopInformation/:ShopID"
                        component={ShopInformation}
                        // exact
                    />
                    <Route
                        path="/U_couponUseHistory"
                        component={U_couponUseHistory}
                        exact
                    />
                    <Route
                        path="/S_couponUseHistory"
                        component={S_couponUseHistory}
                        exact
                    />
                    <Route
                        path="/GeneralRedeemingPointList"
                        component={GeneralRedeemingPointList}
                        exact
                    />
                    <Route
                        path="/U_pointHistory"
                        component={U_pointHistory}
                        exact
                    />
                    <Route
                        path="/FavoriteShop"
                        component={FavoriteShop}
                        exact
                    />

                    <Route path="/FixMember" component={FixMember} exact />
                    <Route path="/LoginAdmin" component={LoginAdmin} exact />
                    <Route path="/Policy" component={Policy} exact />
                    <Route
                        path="/QRCodeDisplay"
                        component={QRCodeDisplay}
                        exact
                    />
                    <Route
                        path="/MemberInformation"
                        component={MemberInformation}
                        exact
                    />
                    <Route path="/Guide" component={Guide} exact />
                    <Route path="/FAQ" component={FAQ} exact />
                    <Route path="/Withdrawal" component={Withdrawal} exact />

                    <Route
                        path="/WithdrawalConfirmation"
                        component={WithdrawalConfirmation}
                        exact
                    />

                    <Route
                        path="/WithdrawalEnquete"
                        component={WithdrawalEnquete}
                        exact
                    />

                    <Route path="/Logout/:type" component={Logout} exact />
                    <Route path="/QRReading" component={QRReading} exact />
                    <Route path="/FirstNavi" component={FirstNavi} exact />
                    <Route
                        path="/GeneralFriendInvitation"
                        component={GeneralFriendInvitation}
                        exact
                    />
                    <Route
                        path="/NewShopApplication"
                        component={NewShopApplication}
                        exact
                    />
                    <Route path="/OfficialSNS" component={OfficialSNS} exact />
                    <Route
                        path="/ShopUserInformation"
                        component={ShopUserInformation}
                        exact
                    />
                    <Route
                        path="/ShopUserInfoChange"
                        component={ShopUserInfoChange}
                        exact
                    />
                    <Route
                        path="/ImportantChangeMember"
                        component={ImportantChangeMember}
                        exact
                    />
                    <Route
                        path="/ImportantChangeShopUser"
                        component={ImportantChangeShopUser}
                        exact
                    />
                    <Route
                        path="/UserInfoChange"
                        component={UserInfoChange}
                        exact
                    />
                    <Route path="/Tweet" component={Tweet} exact />
                    <Route path="/Map" component={Map} exact />
                    {/* ============================管理=================================== */}
                    {/* <SlideRoutes
                        location={location}
                        duration={500}
                        effect="ease-in"
                    > */}

                    <Route
                        path="/ShopStoreApplication"
                        component={ShopStoreApplication}
                        exact
                    />
                    <Route
                        path="/ShopStoreApplicationFix/:ApplyID"
                        component={ShopStoreApplicationFix}
                        exact
                    />
                    <Route
                        path="/ShopStoreApplicationNew"
                        component={ShopStoreApplicationNew}
                        exact
                    />
                    <Route
                        path="/ShopSnapPosting"
                        component={ShopSnapPosting}
                        exact
                    />
                    <Route
                        path="/NotificationList"
                        component={NotificationList}
                        exact
                    />
                    <Route
                        path="/NotificationInformation/:notification_id"
                        component={NotificationInformation}
                        exact
                    />
                    <Route path="/BlogList" component={BlogList} exact />
                    <Route path="/Blog" component={Blog} exact />
                    <Route path="/NewShoplist" component={NewShoplist} exact />
                    <Route
                        path="/NearShoplist"
                        component={NearShoplist}
                        exact
                    />
                    <Route
                        path="/RecommendShoplist"
                        component={RecommendShoplist}
                        exact
                    />
                    <Route
                        path="/UseHistoryShoplist"
                        component={UseHistoryShoplist}
                        exact
                    />

                    <Route path="/MenuUse" component={MenuUse} />
                    {initialize ? (
                        <>
                            <Route>
                                <AdminRoute />
                            </Route>
                        </>
                    ) : (
                        ""
                    )}
                </Switch>

                <Footer />

                {informationState.showBottomNavigation ? (
                    <BottomNavigation />
                ) : (
                    ""
                )}
            </ThemeProvider>
        </React.Fragment>
    );
};

export default App;
