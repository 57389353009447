import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Stateの初期状態
const initialState = {
    shop_data: {
        shop_id: "",
        shop_name: "",
        shop_explain: "",
        store_pr: "",
        access: "",
        shop_prefectures: "",
        shop_city: "",
        shop_address1: "",
        shop_address2: "",
        business_hours: "",
        holiday: "",
        shop_url: "",
        shop_sns: "",
        tag_id_1: "",
        tag_id_2: "",
        tag_id_3: "",
        tag_id_4: "",
        tag_id_5: "",
        tag_id_6: "",
        shop_image_big: "",
        shop_image_small: "",
        shop_phone_number: "",
        shop_staff: "",
        shop_menu: "",
    },
};

// Sliceを生成する
const slice = createSlice({
    name: "ShopApplication",
    initialState,
    reducers: {
        shop_app_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { shop_app_set } = slice.actions;
