import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Stateの初期状態
const initialState = {
    error_message: "",
    // for AdminMascotList
    mascot_list: [],
    list_length: 0,
    page: 1,
    last_page: 1,
    back_flag: false,
    search_error: false,
    // for AdminMascotNew, AdminMascotDetail, AdminMascotEdit
    mascot_id: "",
    mascot_name: "",
    mascot_image_big: "",
    mascot_image_small: "",
    mascot_point: 0,
    draw_title: "",
    draw_detail: "",
    mascot_url: "",
    memo: "",
    release_start_date: "",
    release_end_date: "9999-12-31 23:59:59",
    control_kubun: "2",
    active_flag: "0",
    updated_at: "",
    open_success: false,
    open_failure: false,
};

// マスコット一覧取得
export const GetMascotList = createAsyncThunk(
    "AdminMascot/GetMascotList",
    async(params, thunkAPI) => {
        let api_key = thunkAPI.getState().Information.admin.admin_token;
        
        if (!api_key) api_key = "";
        
        try {
            const res = await axios.get(
                "/api/admin_mascot_list?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );
            return res.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// マスコット新規登録
export const CreateMascot = createAsyncThunk(
    "AdminMascot/CreateMascot",
    async(params, thunkAPI) => {
        let api_key = thunkAPI.getState().Information.admin.admin_token;
        
        if (!api_key) api_key = "";
        
        try {
            const res = await axios.post(
                "/api/admin_mascot_create",
                params,
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );
            return res.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// マスコット編集
export const UpdateMascot = createAsyncThunk(
    "AdminMascot/UpdateMascot",
    async(params, thunkAPI) => {
        let api_key = thunkAPI.getState().Information.admin.admin_token;
        
        if (!api_key) api_key = "";
        
        try {
            const res = await axios.put(
                "/api/admin_mascot_update",
                params,
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );
            return res.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// マスコット削除
export const DeleteMascot = createAsyncThunk(
    "AdminMascot/DeleteMascot",
    async(params, thunkAPI) => {
        let api_key = thunkAPI.getState().Information.admin.admin_token;
        
        if (!api_key) api_key = "";
        
        try {
            const res = await axios.delete(
                "/api/admin_mascot_delete?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );
            return res.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "AdminMascot",
    initialState,
    reducers: {
        set_admin_mascot: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
        mascot_input_initialize: (state) => {
            const initialInput = {
                mascot_name: "",
                mascot_image_big: "",
                mascot_image_small: "",
                mascot_point: 0,
                draw_title: "",
                draw_detail: "",
                mascot_url: "",
                memo: "",
                release_start_date: "",
                release_end_date: "9999-12-31 23:59:59",
                control_kubun: "2",
                active_flag: "0",
            };
            const newState = {...state, ...initialInput};
            return newState;
        },

    },
    extraReducers: {
        [GetMascotList.fulfilled]: (state, action) => {
            state.search_error = false;
            state.mascot_list = action.payload.data;
            state.last_page = action.payload.last_page;
            state.list_length = action.payload.data.length;
        },
        [GetMascotList.rejected]: (state) => {
            state.error_message = "マスコット一覧を取得できませんでした。時間をおいて再度お試しください。";
            state.search_error = true;
            state.mascot_list = [];
            state.last_page = 1;
        },
        [CreateMascot.fulfilled]: (state, action) => {
            if (action.payload.result === 'OK') {
                state.open_success = true; // 成功ダイアログ開く
            } else if (action.payload.error_message) {
                state.error_message = action.payload.error_message
                state.open_failure = true; // 失敗ダイアログ開く
            } else {
                state.error_message = "時間をおいてもう一度試してください。";
                state.open_failure = true;
            }
        },
        [CreateMascot.rejected]: (state) => {
            state.error_message = "時間をおいてもう一度試してください。";
            state.open_failure = true;
        },
        [UpdateMascot.fulfilled]: (state, action) => {
            if (action.payload.result === 'OK') {
                // 変更が確定したのでAPIリクエストのパラメータをステートに代入
                state.mascot_name = action.meta.arg.mascot_name;
                state.mascot_image_big = action.meta.arg.mascot_image_big;
                state.mascot_image_small = action.meta.arg.mascot_image_small;
                state.mascot_point = action.meta.arg.mascot_point;
                state.draw_title = action.meta.arg.draw_title;
                state.draw_detail = action.meta.arg.draw_detail;
                state.mascot_url = action.meta.arg.mascot_url;
                state.memo = action.meta.arg.memo;
                state.active_flag = action.meta.arg.active_flag;

                // 成功ダイアログ開く
                state.open_success = true;
            } else if (action.payload.error_message) {
                state.error_message = action.payload.error_message
                state.open_failure = true; // 失敗ダイアログ開く
            } else {
                state.error_message = "時間をおいてもう一度試してください。";
                state.open_failure = true;
            }
        },
        [UpdateMascot.rejected]: (state) => {
            state.error_message = "時間をおいてもう一度試してください。";
            state.open_failure = true;
        },
        [DeleteMascot.fulfilled]: (state, action) => {
            if (action.payload.result === 'OK') {
                state.open_success = true; // 成功ダイアログ開く
            } else if (action.payload.error_message) {
                state.error_message = action.payload.error_message
                state.open_failure = true; // 失敗ダイアログ開く
            } else {
                state.error_message = "時間をおいてもう一度試してください。";
                state.open_failure = true;
            }
        },
        [DeleteMascot.rejected]: (state, action) => {
            state.error_message = "時間をおいてもう一度試してください。";
            state.open_failure = true;
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_admin_mascot, mascot_input_initialize } = slice.actions;
