import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/functions/AsyncFunction";

const initialState = {
    blog_list: [],
    currentPage: 1,
    lastPage: 1,
    total: 0,
    blog_data: {},
};

export const GetBlogListGeneral = (arg) => {
    return AsyncFunction({
        type_name: "Blog/ブログ一覧取得",
        method: "get",
        url: "/api/GetBlogListGeneral",
        params: arg,
        api_key: "",
    });
};
export const GetBlogListShop = (arg) => {
    return AsyncFunction({
        type_name: "Blog/ブログ一覧取得",
        method: "get",
        url: "/api/GetBlogListShop",
        params: arg,
        api_key: "shop",
    });
};

export const GetBlogGeneral = (arg) => {
    return AsyncFunction({
        type_name: "Blog/ブログ取得",
        method: "get",
        url: "/api/GetBlogGeneral",
        params: arg,
        api_key: "",
    });
};
export const PostBlogShop = (arg) => {
    return AsyncFunction({
        type_name: "Blog/ブログ投稿",
        method: "post",
        url: "/api/PostBlogShop",
        params: arg,
        api_key: "shop",
    });
};
export const DeleteBlogShop = (arg) => {
    return AsyncFunction({
        type_name: "Blog/ブログ削除",
        method: "delete",
        url: "/api/DeleteBlogShop",
        params: arg,
        api_key: "shop",
    });
};

const slice = createSlice({
    name: "Blog",
    initialState,
    reducers: {
        BlogSet: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) =>
                action.type.match("Blog/") &&
                action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;
                if (payload?.blog_data) {
                    state.blog_data = payload?.blog_data;
                }

                if (payload?.blog_list) {
                    state.blog_list = payload?.blog_list;
                }
                if (payload?.currentPage) {
                    state.currentPage = payload?.currentPage;
                }
                if (payload?.lastPage) {
                    state.lastPage = payload?.lastPage;
                }
                if (payload?.total) {
                    state.total = payload?.total;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { BlogSet } = slice.actions;
