import React, { useEffect } from "react";

import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    Typography,
    Grid,
    TextField,
    TextareaAutosize,
    Switch,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { isBefore } from "date-fns";
import SelectReleaseTarget from "../utils/SelectReleaseTarget";
import SelectNoticeType from "../utils/SelectNoticeType";
import { TextFieldNormal } from "../utils/TextFields";
import {
    set_Notice,
    GetNotice,
    UpdateNotice,
    CreateNotice,
    set_Initial,
} from "../../stores/AdminNotice";
import InputImagePreview from "../utils/InputImagePreview";
import PopupNotification from "../modules/PopupNotification";
import PopupDialog from "../modules/PopupDialog";
import { set_alert } from "../../stores/ErrorAlert";
import IsEmpty from "../utils/IsEmpty";

const AdminNotice = () => {
    const NoticeState = useSelector((state) => state.AdminNotice);
    const dispatch = useDispatch();
    const history = useHistory();

    const path = history.location.pathname.split("/");
    const notification_id =
        NoticeState.notification_id === "undefined"
            ? path[2]
            : NoticeState.notification_id;

    useEffect(() => {
        dispatch(set_Initial());
        dispatch(set_Notice(["notification_id", notification_id]));
        // 新規登録か編集の切り分け
        if (notification_id == "undefined") {
            // 新規登録の場合
        } else {
            dispatch(set_Notice(["new_or_fix", "fix"]));
            dispatch(GetNotice({ notification_id: notification_id }));
            // 編集の場合
        }
    }, []);

    useEffect(() => {
        if (NoticeState.release_end_date.includes("9999-12-31")) {
            dispatch(set_Notice(["indefinitely", true]));
        }
    }, [NoticeState.release_end_date]);

    useEffect(() => {
        dispatch(set_alert(NoticeState.error_message));
    }, [NoticeState.error_message]);

    const HandleChange = (e) => {
        if (
            ["release_start_date", "release_end_date"].includes(
                e.currentTarget.name
            ) &&
            isBefore(new Date(e.currentTarget.value), new Date())
        )
            return;
        dispatch(set_Notice([e.currentTarget.name, e.currentTarget.value]));
    };

    const HandleToggle = (e) => {
        const new_value = NoticeState[e.currentTarget.name] ? false : true;
        dispatch(set_Notice([e.currentTarget.name, new_value]));
    };
    const HandleDelete = (e) => {
        const new_value = NoticeState.delete_flag == "1" ? "0" : "1";
        dispatch(set_Notice(["delete_flag", new_value]));
    };

    const SetPreviewFlag = () => {
        dispatch(set_Notice(["preview_flag", true]));
    };
    const UnSetPreviewFlag = () => {
        dispatch(set_Notice(["preview_flag", false]));
    };
    const SetImage = (arg) => {
        const image = arg.image_big;
        dispatch(set_Notice(["image", image]));
    };
    const SetNotice = () => {
        switch (NoticeState.new_or_fix) {
            case "new":
                dispatch(CreateNotice());
                break;
            case "fix":
                dispatch(UpdateNotice());
                break;
            default:
                dispatch(
                    set_Notice([
                        "error_message",
                        ["エラー", "通知設定で発生しました"],
                    ])
                );
                return;
        }
    };
    const Back = () => {
        dispatch(set_Initial());
        history.push("/AdminNoticeList");
    };

    const button_disable1 =
        NoticeState.release_start_date &&
        isBefore(new Date(NoticeState.release_start_date), new Date());
    const button_disable2 =
        IsEmpty(NoticeState.notification_title) ||
        IsEmpty(NoticeState.notification_detail);

    return (
        <React.Fragment>
            <ChevronLeftIcon onClick={Back} />
            <Center>
                <Center>
                    <Typography color="primary" style={{ fontSize: "200%" }}>
                        {NoticeState.new_or_fix == "new"
                            ? "通知登録"
                            : "通知編集"}
                    </Typography>
                </Center>

                <Center>
                    <Left>通知対象</Left>
                    <SelectReleaseTarget
                        value={NoticeState.control_kubun}
                        name="control_kubun"
                        onChange={HandleChange}
                    />
                </Center>

                <Center>
                    <Left>通知タイプ</Left>
                    <SelectNoticeType
                        value={NoticeState.notification_category}
                        onChange={HandleChange}
                    />
                </Center>

                {NoticeState.preview_flag == false ? (
                    <React.Fragment>
                        <Center>
                            <Left>通知タイトル</Left>
                            <TextField
                                fullWidth
                                type="text"
                                size="small"
                                name="notification_title"
                                variant="outlined"
                                onChange={HandleChange}
                                value={NoticeState.notification_title}
                                inputProps={{ maxLength: 255 }}
                            />
                        </Center>
                        <Center>
                            <Left>通知内容</Left>
                            <TextareaAutosize
                                minRows={3}
                                maxLength={10000}
                                name="notification_detail"
                                style={{
                                    width: "100%",
                                    padding: "0.5rem",
                                    fontSize: "1.2rem",
                                    borderRadius: "0.3rem",
                                }}
                                size="small"
                                defaultValue={NoticeState.notification_detail}
                                variant="outlined"
                                onBlur={HandleChange}
                            ></TextareaAutosize>
                        </Center>
                        {NoticeState.notification_category == 1 ? (
                            <>
                                {/* 通知タイプが通常通知の場合 */}
                                <Center>
                                    <Left>通知画像</Left>
                                    <PreviewImage
                                        image_path={
                                            NoticeState.image
                                                ? NoticeState.image
                                                : NoticeState.image_path
                                        }
                                    />
                                </Center>

                                <InputImagePreview
                                    comment={false}
                                    onClick={SetImage}
                                    height={200}
                                    image_big
                                />
                            </>
                        ) : (
                            <>
                                {/* 通知タイプがポップアップの場合 */}
                                <Center>
                                    <Left>通知画像</Left>
                                    <PreviewImage
                                        image_path={
                                            NoticeState.image
                                                ? NoticeState.image
                                                : NoticeState.image_path
                                        }
                                    />
                                </Center>

                                <InputImagePreview
                                    comment={false}
                                    onClick={SetImage}
                                    height={200}
                                    image_big
                                />
                                <Center>
                                    <Left>URL</Left>
                                    <TextField
                                        inputProps={{ maxLength: 255 }}
                                        fullWidth
                                        type="text"
                                        size="small"
                                        name="button_url"
                                        variant="outlined"
                                        onChange={HandleChange}
                                        value={NoticeState.button_url}
                                    />
                                </Center>
                            </>
                        )}
                    </React.Fragment>
                ) : (
                    <>
                        {/* 通知タイプが通常通知の場合 */}
                        {NoticeState.notification_category == 1 ? (
                            <Grid
                                container
                                style={{
                                    minHeight: "15rem",
                                    boxShadow:
                                        "0px 0px 0.2rem 0px rgba(0,0,0,0.6)",
                                    borderRadius: "0.5rem",
                                    width: "90%",
                                }}
                            >
                                {NoticeState.notification_title ? (
                                    <h2
                                        style={{
                                            width: "96%",
                                            height: "fit-content",
                                            margin: "0.2rem 2%",
                                        }}
                                    >
                                        {NoticeState.notification_title}
                                    </h2>
                                ) : (
                                    "※通知タイトルが未入力です"
                                )}
                                <Center>
                                    <Left>
                                        <PreviewHTML>
                                            {NoticeState.notification_detail}
                                        </PreviewHTML>
                                    </Left>
                                </Center>
                            </Grid>
                        ) : (
                            <>
                                {/* 通知タイプがポップアップの場合 */}

                                <PopupDialog
                                    notification_detail={
                                        NoticeState.notification_detail
                                    }
                                    image_path={
                                        NoticeState.image
                                            ? NoticeState.image
                                            : NoticeState.image_path
                                    }
                                    open={NoticeState.preview_flag}
                                    onClose={UnSetPreviewFlag}
                                    button_url={NoticeState.button_url}
                                    notification_title={
                                        NoticeState.notification_title
                                    }
                                />
                            </>
                        )}
                    </>
                )}

                <BigButton
                    onClick={UnSetPreviewFlag}
                    disabled={!NoticeState.preview_flag}
                >
                    編集
                </BigButton>
                <BigButton
                    onClick={SetPreviewFlag}
                    disabled={NoticeState.preview_flag}
                >
                    プレビュー
                </BigButton>

                <Center>
                    <Left>公開開始日</Left>
                    <TextField
                        fullWidth
                        type="date"
                        size="small"
                        name="release_start_date"
                        variant="outlined"
                        disabled={
                            NoticeState.release_start_date &&
                            isBefore(
                                new Date(NoticeState.release_start_date),
                                new Date()
                            )
                        }
                        onChange={HandleChange}
                        value={NoticeState.release_start_date.replace(
                            /\//g,
                            "-"
                        )}
                    />
                </Center>

                <Center>
                    <Left>公開終了日</Left>
                    <TextField
                        fullWidth
                        type="date"
                        size="small"
                        name="release_end_date"
                        variant="outlined"
                        disabled={
                            NoticeState.indefinitely ||
                            (NoticeState.release_end_date &&
                                isBefore(
                                    new Date(NoticeState.release_end_date),
                                    new Date()
                                ))
                        }
                        onChange={HandleChange}
                        value={NoticeState.release_end_date.replace(/\//g, "-")}
                    />
                </Center>

                <Center>
                    <Switch
                        checked={NoticeState.indefinitely}
                        onChange={HandleToggle}
                        name="indefinitely"
                        color="primary"
                    />
                    公開終了日を無期限にする
                </Center>

                {NoticeState.new_or_fix == "fix" && (
                    <Center>
                        <Switch
                            checked={
                                NoticeState.delete_flag == "1" ? true : false
                            }
                            onChange={HandleDelete}
                            name="delete_flag"
                            color="primary"
                        />
                        削除
                    </Center>
                )}
                {NoticeState.new_or_fix == "new" ? (
                    <BigButton
                        disabled={button_disable1 || button_disable2}
                        onClick={SetNotice}
                    >
                        確定
                    </BigButton>
                ) : (
                    <BigButton disabled={button_disable2} onClick={SetNotice}>
                        編集
                    </BigButton>
                )}
            </Center>
        </React.Fragment>
    );
};

const Center = (props) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ margin: "1rem 5%", width: "90%" }}
        >
            {props.children}
        </Grid>
    );
};
const Left = (props) => {
    return (
        <div
            style={{
                textAlign: "left",
                marginLeft: "0",
                marginRight: "auto",
            }}
        >
            {props.children}
        </div>
    );
};
const BigButton = (props) => {
    return (
        <Button
            style={{
                minWidth: "8rem",
                minHeight: "3rem",
                margin: "1rem 5%",
            }}
            variant="contained"
            color="primary"
            {...props}
        >
            {props.children}
        </Button>
    );
};

const PreviewHTML = (props) => {
    return (
        <div
            className="html-preview"
            dangerouslySetInnerHTML={{
                __html: props.children
                    ? props.children.replace(/\n/g, "<br>")
                    : "",
            }}
        ></div>
    );
};
const PreviewImage = (props) => {
    return (
        <Center>
            <img
                style={{ width: "100%" }}
                src={props.image_path ? props.image_path : "null"}
                onError={(e) => {
                    e.target.onerror = "null";
                    e.target.src = "/images/no_img.png";
                }}
            />
        </Center>
    );
};

export default AdminNotice;
