import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
// 加盟店編集
import { Input, Button, Grid, Container, TextField } from "@material-ui/core/";
import { useHistory } from "react-router";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import IconButton from "@material-ui/core/IconButton";

import {
    set_snap,
    del_snap,
    GetSnap,
    UploadSnap,
    DeleteSnap,
} from "../../stores/ShopSnapPosting";

import { useSelector, useDispatch } from "react-redux";
import { set_alert } from "../../stores/ErrorAlert";

import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import ModalGeneral from "../utils/ModalGeneral";
import InputImagePreview from "../utils/InputImagePreview";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";

import ClearIcon from "@material-ui/icons/Clear";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    input_file: {
        opacity: "0",
        appearance: "none",
        position: "absolute",
    },

    sub_title_button: {
        fintSize: "1vw",
        width: "30px",
    },
    ImageListItemBar: {
        height: "1.5rem",
    },
}));

const ShopSnapPosting = () => {
    const snapState = useSelector((state) => state.ShopSnapPosting);
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const OnDelete = (index) => {
        const result = confirm("削除しますか?");
        if (!result) return;

        dispatch(DeleteSnap(snapState.image_list[index]));
    };
    const InputChange = (e) => {
        dispatch(set_snap([e.target.name, e.target.value]));
    };

    useEffect(() => {
        dispatch(GetSnap());
    }, []);

    useEffect(() => {
        dispatch(set_alert(snapState.error_message));
    }, [snapState.error_message]);

    const AddSnap = (arg) => {
        const image = arg.image;
        const image_comment = arg.comment;

        dispatch(UploadSnap({ image: image, image_comment: image_comment }));

        dispatch(set_snap(["image_comment", ""]));
    };

    const OnSnapPost = (e) => {
        // console.log("OnSnapPost");
    };

    const RequestButton = () => {
        return (
            <React.Fragment>
                <Button
                    component="label"
                    variant="contained"
                    color="secondary"
                    onClick={OnSnapPost}
                >
                    登録申請
                </Button>
            </React.Fragment>
        );
    };

    const OpenModal = (index) => {
        dispatch(set_snap(["open_modal_index", index]));
    };
    const CloseModal = () => {
        dispatch(set_snap(["open_modal_index", "false"]));
    };
    const onBack = () => {
        history.goBack();
    };

    const ShowModal = () => {
        const index = snapState.open_modal_index;

        return (
            <ModalGeneral
                open={snapState.open_modal_index === "false" ? false : true}
                onClose={CloseModal}
            >
                {snapState.open_modal_index === "false" ? (
                    ""
                ) : (
                    <>
                        <Container>
                            <img
                                style={{ width: "100%" }}
                                src={snapState.image_list[index].image_path}
                                alt={snapState.image_list[index].image_comment}
                                onError={(e) => {
                                    e.target.onerror = "null";
                                    e.target.src = "/images/no_img.png";
                                }}
                            />

                            {snapState.image_list[index].image_comment}

                            {snapState.image_list[index].denial_reason ? (
                                <>
                                    <h2>否認理由</h2>
                                    {snapState.image_list[index].denial_reason}
                                </>
                            ) : (
                                ""
                            )}
                        </Container>
                    </>
                )}
            </ModalGeneral>
        );
    };

    return (
        <React.Fragment>
            <IconButton onClick={onBack} style={{ padding: "8px 0 24px" }}>
                <NavigateBeforeIcon />
                <span style={{ fontSize: "70%" }}>戻る</span>
            </IconButton>
            <div>
                <h2>スナップ投稿</h2>
            </div>

            <Container>
                <h6>スナップ投稿について</h6>
                <p style={{ fontSize: "0.5rem" }}>
                    ファイルを選択しスナップ画像の説明を入力してください。
                    登録申請ボタンをタップすることで登録申請を行うことができます。
                    申請情報の確認の後、公開させていただきます。
                </p>
            </Container>

            <InputImagePreview
                comment={true}
                onClick={AddSnap}
                height="1200"
                width="1200"
            />

            <Modal open={snapState.progress}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: "100%" }}
                >
                    <CircularProgress size="5rem" />
                </Grid>
            </Modal>

            <ShowModal />

            <Container>
                <h2>申請中スナップ</h2>
                <ImageList
                    rowHeight={120}
                    cols={3}
                    // gap={20}
                    style={{
                        padding: "0.1rem",
                    }}
                >
                    {snapState.image_list.map((item, index) =>
                        item.approval_flag == 0 ? (
                            <ImageListItem
                                key={index}
                                style={{
                                    boxShadow:
                                        "0px 0px 0.2rem 0px rgba(0,0,0,0.6)",
                                    borderRadius: "0.5rem",
                                    textAlign: "center",
                                }}
                            >
                                <img
                                    src={item.image_path}
                                    alt={item.image_comment}
                                    onClick={() => OpenModal(index)}
                                    onError={(e) => {
                                        e.target.onerror = "null";
                                        e.target.src = "/images/no_img.png";
                                    }}
                                    style={{ width: "auto", height: "100%" }}
                                />
                                <ImageListItemBar
                                    className={classes.ImageListItemBar}
                                    position={"top"}
                                    title={item.image_comment}
                                    actionIcon={
                                        <ClearIcon
                                            color="secondary"
                                            onClick={() => OnDelete(index)}
                                        />
                                    }
                                />
                            </ImageListItem>
                        ) : (
                            ""
                        )
                    )}
                </ImageList>

                <h2>否認スナップ</h2>
                <ImageList
                    rowHeight={120}
                    cols={3}
                    // gap={20}
                    style={{
                        padding: "0.1rem",
                    }}
                >
                    {snapState.image_list.map((item, index) =>
                        item.approval_flag == 2 ? (
                            <ImageListItem
                                key={index}
                                style={{
                                    boxShadow:
                                        "0px 0px 0.2rem 0px rgba(0,0,0,0.6)",
                                    borderRadius: "0.5rem",
                                    textAlign: "center",
                                }}
                            >
                                <img
                                    src={item.image_path}
                                    alt={item.image_comment}
                                    onClick={() => OpenModal(index)}
                                    onError={(e) => {
                                        e.target.onerror = "null";
                                        e.target.src = "/images/no_img.png";
                                    }}
                                    style={{ width: "auto", height: "100%" }}
                                />
                                <ImageListItemBar
                                    className={classes.ImageListItemBar}
                                    position={"top"}
                                    title={item.image_comment}
                                    actionIcon={
                                        <ClearIcon
                                            color="secondary"
                                            onClick={() => OnDelete(index)}
                                        />
                                    }
                                />
                            </ImageListItem>
                        ) : (
                            ""
                        )
                    )}
                </ImageList>
                <h2>公開中スナップ</h2>
                <ImageList
                    rowHeight={120}
                    cols={3}
                    // gap={20}
                    style={{
                        padding: "0.1rem",
                    }}
                >
                    {snapState.image_list.map((item, index) =>
                        item.approval_flag == 1 ? (
                            <ImageListItem
                                key={index}
                                style={{
                                    boxShadow:
                                        "0px 0px 0.2rem 0px rgba(0,0,0,0.6)",
                                    borderRadius: "0.5rem",
                                    textAlign: "center",
                                }}
                            >
                                <img
                                    src={item.image_path}
                                    alt={item.image_comment}
                                    onClick={() => OpenModal(index)}
                                    onError={(e) => {
                                        e.target.onerror = "null";
                                        e.target.src = "/images/no_img.png";
                                    }}
                                    style={{ width: "auto", height: "100%" }}
                                />
                                <ImageListItemBar
                                    className={classes.ImageListItemBar}
                                    position={"top"}
                                    title={item.image_comment}
                                    actionIcon={
                                        <ClearIcon
                                            color="secondary"
                                            onClick={() => OnDelete(index)}
                                        />
                                    }
                                />
                            </ImageListItem>
                        ) : (
                            ""
                        )
                    )}
                </ImageList>
            </Container>
        </React.Fragment>
    );
};

export default ShopSnapPosting;
