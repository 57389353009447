import React, { useEffect } from "react";
import SelectPrefectures from "../utils/SelectPrefectures";
import SelectRawYearMonth from "../utils/SelectRawYearMonth";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { user_info_set, NormalInfoChange } from "../../stores/UserInfoChange";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { TextFieldNormal } from "../utils/TextFields";
import { ButtonContainedPrimary } from "../utils/Buttons";
import { useHistory } from "react-router";
import ErrorDialog from "../utils/ErrorDialog";
import Button from "@material-ui/core/Button";
import TokenErrorDialog from "../utils/TokenErrorDialog";
import Error500Handling from "../utils/Error500Handling";
import { HideFooter, ShowFooter } from "../modules/BottomNavigation";

const useStyles = makeStyles({
    Input: {
        marginTop: "1rem",
    },
    button: {
        display: "block",
        margin: "auto",
    },
});

const UserInfoChange = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const informationState = useSelector((state) => state.Information);
    const user_info_state = useSelector((state) => state.UserInfoChange);
    const [nickError, setNickError] = React.useState(false);
// console.log(informationState)

    const OnSbubmit = () => {
        if (!user_info_state.nickname) {
            setNickError(true);
            return;
        }

        const params = {
            prefectures: user_info_state.prefectures,
            nickname: user_info_state.nickname,
            raw_year_month: user_info_state.raw_year_month,
            updated_at: user_info_state.updated_at,
        };
        
        dispatch(NormalInfoChange(params));
    };

    const OnBack = () => {
        history.push("/MemberInformation");
        dispatch(user_info_set(["open_old_error", false]));
    };

    const InputChange = (e) => {
        dispatch(user_info_set([e.currentTarget.name, e.currentTarget.value]));
        if (e.currentTarget.name === "nickname") {
            if (e.currentTarget.value) setNickError(false);
        }
        ShowFooter(); 
    };

    const handleCloseError = () => {
        dispatch(user_info_set(["open_error_dialog", false]));
    };
    const handleClickOK = () => {
        dispatch(user_info_set(["open_token_error_dialog", false]));
    };

    useEffect(() => {
        dispatch(user_info_set(["nickname", informationState.user.nickname]));
        dispatch(
            user_info_set(["prefectures", informationState.user.prefectures])
        );
        dispatch(user_info_set(["updated_at", informationState.user.updated_at]));
        dispatch(user_info_set(["raw_year_month", informationState?.user?.raw_year_month?.replace('-' ,'/')]));
    }, []);

    useEffect(() => {
        if (user_info_state.return_triger !== 0) {
            history.push("/MemberInformation");
            dispatch(user_info_set(["return_triger", 0]));
        }
    }, [user_info_state.return_triger]);

    return (
        <> 
            <Container>
                <h2>ユーザー情報の変更</h2>
                <TextFieldNormal
                    className={classes.Input}
                    label="ニックネーム"
                    name="nickname"
                    error={nickError}
                    helperText={nickError ? "入力してください" : ""}
                    onFocus={HideFooter}
                    onBlur={InputChange}
                    defaultValue={informationState.user.nickname}
                    inputProps={{ maxLength: 255 }}
                />
                <SelectRawYearMonth
                    name="raw_year_month"
                    onChange={InputChange}
                    defaultValue={informationState.user?.raw_year_month?.replace('/' ,'-')}
                    // error={error.birth_year_month}
                    // helperText={error.birth_year_month && "選択してください。"}
                    style={{ marginBottom: "25px" }}
                />
                <SelectPrefectures
                    className={classes.Input}
                    name="prefectures"
                    onChange={InputChange}
                    defaultValue={informationState.user.prefectures}
                />
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginTop: "1rem" }}
                >
                    <ButtonContainedPrimary
                        onClick={OnBack}
                    >
                        戻る
                    </ButtonContainedPrimary>
                    <ButtonContainedPrimary
                        onClick={OnSbubmit}
                    >
                        変更する
                    </ButtonContainedPrimary>
                </Grid>
            </Container>
            <ErrorDialog open={user_info_state.open_old_error} >
                <p>
                    表示されているデータが古いため更新できませんでした。ページを開き直してください。
                </p>
                <Button
                    variant="contained"
                    className={classes.button}
                    onClick={OnBack}
                >
                    OK
                </Button>
            </ErrorDialog>
            <TokenErrorDialog open={user_info_state.open_token_error_dialog} onClickOK={handleClickOK} />
            <Error500Handling open={user_info_state.open_error_dialog} network={user_info_state.network_error} onClose={handleCloseError} />
        </>
    );
};

export default UserInfoChange;
