import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MascotInfo from "../modules/MascotInfo";
import MascotDrawDialog from "../modules/MascotDrawDialog";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { DeleteMascot, set_admin_mascot } from "../../stores/AdminMascot";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            padding: "15px",
            width: "350px",
        },
    },
    preview: {
        "& .MuiDialog-container": {
            width: "400px",
            margin: "auto",
        },
    },
}));

const AdminMascotDetail = () => {
    const thisState = useSelector((state) => state.AdminMascot);
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [openPreview, setOpenPreview] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);

    // ログインボーナスプレビューボタン押下
    const handleClickPreview = () => {
        setOpenPreview(true);
    };

    // ログインボーナスプレビューダイアログを閉じる
    const handleClosePreview = () => {
        setOpenPreview(false);
    };

    // 編集ボタン押下
    const handleClickEdit = () => {
        history.push(`/AdminMascotEdit/${thisState.mascot_id}`);
    };

    // 削除ボタン押下
    const handleClickDelete = () => {
        setOpenConfirmation(true);
    };

    // 削除確認ダイアログのOKボタン押下
    const handleExecuteDelete = () => {
        const params = {
            mascot_id: thisState.mascot_id,
            delete_flag: "1",
            updated_at: thisState.updated_at,
        };
        dispatch(DeleteMascot(params));
        setOpenConfirmation(false);
    };

    // 削除確認ダイアログを閉じる
    const handleCloseConfirmationDialog = () => {
        setOpenConfirmation(false);
    };

    // 一覧へボタン押下
    const handleClickToList = () => {
        dispatch(set_admin_mascot(["back_flag", true]));
    };

    // 削除結果表示ダイアログを閉じる
    const handleCloseResultDialog = () => {
        if (thisState.open_success) {
            dispatch(set_admin_mascot(["open_success", false]));

            // 削除成功時は一覧に戻す
            if (thisState.list_length === 1) {
                // 現在のページのマスコット数が1の場合、削除すると現在のページに属するデータが存在しなくなるので、1ページ前に戻る
                dispatch(set_admin_mascot(["page", thisState.page - 1]));
            }
            dispatch(set_admin_mascot(["back_flag", true]));
            history.push("/AdminMascotList");
        } else {
            dispatch(set_admin_mascot(["open_failure", false]));
        }
    };

    return (
        <>
            <ButtonNavi
                left_label="一覧へ"
                left_link="/AdminMascotList"
                left_props={{
                    onClick: handleClickToList,
                }}
            />

            <h1>マスコット詳細</h1>

            <MascotInfo readOnly={true} />

            <Grid container justifyContent="space-evenly">
                <Grid item xs={2} style={{ textAlign: "center" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickPreview}
                        style={{width: "165px"}}
                    >
                        ボーナスプレビュー
                    </Button>
                </Grid>
                <Grid item xs={2} style={{ textAlign: "center" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickEdit}
                        style={{width: "165px"}}
                    >
                        編集
                    </Button>
                </Grid>
                <Grid item xs={2} style={{ textAlign: "center" }}>
                    <Button
                        variant="contained"
                        color="default"
                        onClick={handleClickDelete}
                        style={{width: "165px"}}
                    >
                        削除
                    </Button>
                </Grid>
            </Grid>

            {/* ログインボーナスプレビューダイアログ */}
            <MascotDrawDialog
                open={openPreview}
                title={thisState.draw_title}
                image={thisState.mascot_image_small}
                detail={thisState.draw_detail}
                url={thisState.mascot_url}
                point={thisState.mascot_point}
                onClose={handleClosePreview}
                style={classes.preview}
            />

            {/* 削除確認ダイアログ */}
            <Dialog
                open={openConfirmation}
                onClose={handleCloseConfirmationDialog}
                className={classes.dialog}
            >
                <p>このマスコットを削除します。</p>
                <Grid container style={{ textAlign: "center" }}>
                    <Grid item xs={6}>
                        <Button 
                            variant="contained"
                            color="default"
                            onClick={handleCloseConfirmationDialog}
                        >
                            キャンセル
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleExecuteDelete}
                        >
                            OK
                        </Button>
                    </Grid>
                </Grid>
                
            </Dialog>

            {/* 削除の結果表示ダイアログ */}
            <Dialog
                open={thisState.open_success || thisState.open_failure}
                onClose={handleCloseResultDialog}
                className={classes.dialog}
            >
                {thisState.open_success && <p>削除しました。</p>}
                {thisState.open_failure && (
                    <>
                        <p>削除できませんでした。</p>
                        <p>{thisState.error_message}</p>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default AdminMascotDetail;
