import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";

// import Pagination from "@material-ui/lab/Pagination";

import { BackButton } from "~CJS/atoms/BackButton";
import { LinkButton } from "~CJS/atoms/LinkButton";
import TalentSearchCriteria from "~/block/TalentSearchCriteria";
import TalentCard from "~CJS/block/TalentCard";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import {
    initialize,
    talent_set,
    GetTalentList,
} from "~stores/TALENTBANK/Talent";

const TalentSearch = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const submit_ref = React.useRef(null);

    const TalentState = useSelector((state) => state.Talent);

    const StertSerch = (criteria) => {
        dispatch(GetTalentList(criteria));
    };

    return (
        <React.Fragment>
            <Container>
                {/* <BackButton history={history} /> */}
                <ButtonNavi
                    left_label="Home画面"
                    left_link="/AdminHome"
                    right_label="タレント作成"
                    right_link="/TalentCreate"
                />
                <Typography variant="h1">タレント検索</Typography>

                <Grid item style={{ margin: "10px", fontSize: "x-large" }}>
                    総タレント数
                    <div
                        style={{
                            display: "inline-block",
                            fontSize: "xx-large",
                            fontWeight: "bold",
                            marginLeft: "0.5rem",
                        }}
                    >
                        {TalentState.talent_serch.total}
                    </div>
                    人
                </Grid>

                <TalentSearchCriteria
                    StertSerch={StertSerch}
                    count={TalentState.talent_serch.page_count}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        // alignItems="center"
                        style={{
                            maxWidth: "1000px",
                            marginInline: "auto",
                        }}
                    >
                        {TalentState.talent_list.map((item, index) => (
                            <React.Fragment key={index}>
                                <TalentCard 
                                    data={item}
                                    detailUrl={`/TalentDetail/${item.talent_id}`}
                                    admin
                                />
                            </React.Fragment>
                        ))}

                        {/* {TalentState.talent_serch.page_count > 1 && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Pagination
                                count={TalentState.talent_serch.page_count}
                                variant="outlined"
                                page={page}
                                color="secondary"
                                onChange={handleChangePage}
                            />
                        </Grid>
                    )} */}
                    </Grid>
                </TalentSearchCriteria>
            </Container>
        </React.Fragment>
    );
};

export default TalentSearch;
