import React, { useEffect } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Container from "@material-ui/core/Container";
import { makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { GetNewsList, DeleteNews, SetNews } from "~stores/TALENTBANK/News";
// import Error500Handling from "../utils/Error500Handling";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import { ButtonNavi } from "~CJS/block/ButtonNavi";

import { format, formatDistance, formatRelative, subDays } from "date-fns";
import { ja } from "date-fns/locale";

const NewsSetting = () => {
    const dispatch = useDispatch();
    const NewsState = useSelector((state) => state.News);

    useEffect(() => {
        dispatch(GetNewsList());
    }, []);

    const HeadGlid = (props) => {
        return (
            <Grid
                item
                xs={6}
                style={{
                    background: "#feae53",
                    padding: "1rem",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    border: "solid 1px",
                    textAlign: "left",
                    margin: "0px",
                }}
                {...props}
            ></Grid>
        );
    };
    const BodyGlid = (props) => {
        return (
            <Grid
                item
                style={{
                    border: "1px solid",
                    textAlign: "center",
                    margin: "0px",
                    padding: "0.5rem",
                }}
                {...props}
            ></Grid>
        );
    };

    const news_list = NewsState.news_list;

    const HandleCreate = (e) => {
        e.preventDefault();
        const elem = e.target.elements;
        const params = {
            content: elem?.content?.value,
            type: "1",
        };
        dispatch(SetNews(params)).then((data) => {
            document.create_news_form.reset();
        });
    };

    const HandleDelete = (id) => {
        const params = {
            id: id,
        };
        dispatch(DeleteNews(params)).then((data) => {});
    };

    return (
        <Container>
            <ButtonNavi left_label="Home画面" left_link="/AdminHome" />

            <Typography variant="h1">ニュース設定</Typography>

            <form
                onSubmit={HandleCreate}
                autoComplete="off"
                name="create_news_form"
                key="1"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        border: "solid 1px",
                        padding: "2rem",
                        marginBottom: "2rem",
                    }}
                >
                    <TextField
                        style={{ marginBottom: "1rem" }}
                        fullWidth
                        label="内容"
                        required
                        name="content"
                        variant="outlined"
                        // defaultValue={DocumentState.title1}
                        // onBlur={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    ></TextField>

                    <Button variant="outlined" type="submit">
                        投稿
                    </Button>
                </Grid>
            </form>
            <Grid container>
                <HeadGlid xs={2}>投稿日</HeadGlid>
                <HeadGlid xs>内容</HeadGlid>
                <HeadGlid xs={1}>削除</HeadGlid>
                {news_list && (
                    <>
                        {news_list.map((value, index) => (
                            <React.Fragment key={index}>
                                <Grid container>
                                    <BodyGlid xs={2}>
                                        {format(
                                            new Date(value?.created_at),
                                            "MMM do EEEE Ho",
                                            { locale: ja }
                                        )}
                                        {/* {value.created_at} */}
                                    </BodyGlid>
                                    <BodyGlid xs>{value.content}</BodyGlid>

                                    {/* {serch_option.document_kubun != "1" && (
                                        <BodyGlid xs>{index}</BodyGlid>
                                    )} */}
                                    <BodyGlid xs={1}>
                                        <Button
                                            variant="outlined"
                                            onClick={() =>
                                                HandleDelete(value.id)
                                            }
                                        >
                                            削除
                                        </Button>
                                    </BodyGlid>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </>
                )}
            </Grid>
            {/* <div style={{ padding: "5px" }}>
                <CategoriesAccordion categories={thisState.qas} />
            </div>
            <Typography style={{ padding: "20px" }}>
                FAQでは解決しないご質問や、ご意見・ご要望等ございましたら、下記のお問い合わせ先までお願いいたします。
            </Typography> */}
        </Container>
    );
};

export default NewsSetting;
