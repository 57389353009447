import React, { useEffect } from "react";
import HistoryTable from "../modules/HistoryTable";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { set_table_value, initialize_table } from "../../stores/HistoryTable";
import {
    set_value,
    crear_serch,
    FetchHistory,
} from "../../stores/U_couponUseHistory";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ReviewDialog from "../modules/ReviewDialog";
import TokenErrorDialog from "../utils/TokenErrorDialog";
import Error500Handling from "../utils/Error500Handling";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    root: {
        height: 300,
        flexGrow: 1,
        minWidth: 300,
        transform: "translateZ(0)",
        // The position fixed scoping doesn't work in IE 11.
        // Disable this demo to preserve the others.
        "@media all and (-ms-high-contrast: none)": {
            display: "none",
        },
    },
    modal: {
        display: "flex",
        padding: theme.spacing(1),
        alignItems: "center",
        justifyContent: "center",
    },
    message_modal: {
        display: "flex",
        padding: theme.spacing(1),
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #f06292",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    textField: {
        width: "100%",
        marginTop: "1rem",
    },
    buttonSubmit: {
        marginTop: "1rem",
        marginRight: "1rem",
    },
    buttonClear: {
        marginTop: "1rem",
    },
}));

const U_couponUseHistory = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.U_couponUseHistory);
    const tableState = useSelector((state) => state.HistoryTable);

    // -----------レビューボタン用の関数------------
    const handleOpenReview = (params) => {
        dispatch(
            set_value([
                "reviewed_shop",
                params.getValue(params.id, "shop_name"),
            ])
        );
        dispatch(
            set_value([
                "reviewed_history_id",
                params.getValue(params.id, "history_id"),
            ])
        );

        dispatch(
            set_value([
                "review_text",
                params.getValue(params.id, "review_text"),
            ])
        );
        dispatch(set_value(["review", params.getValue(params.id, "review")]));
        dispatch(set_value(["open_review_dialog", true]));
    };
    // ----------------------------------------------

    //列定義
    const columns = [
        {
            field: "used_at",
            headerName: "日時",
            width: 125,
            sortable: false,
            valueFormatter: (params) => {
                const formattedDate = params.value
                    .substr(2, 14)
                    .replace(/-/g, "/")
                    .replace("T", " ");
                return formattedDate;
            },
            headerAlign: "center",
        },
        {
            field: "shop_name",
            headerName: "加盟店名",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            sortable: false,
            renderCell: (params) => {
                return (
                    <Link to={`/ShopInformation/${params.row.shop_id}`}>
                        {params.value}
                    </Link>
                );
            },
        },
        {
            field: "review",
            headerName: "レビュー",
            width: 109,
            sortable: false,
            filterable: false,
            selectable: false,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleOpenReview(params)}
                    style={{ width: "104px" }}
                >
                    {params.getValue(params.id, "review") === null
                        ? "レビュー"
                        : "レビュー済"}
                </Button>
            ),
            headerAlign: "center",
            align: "center",
        },
    ];

    // ------------検索モーダル用の関数----------------
    const handleOpen = () => {
        dispatch(set_value(["modal_show", true]));
    };

    const handleClose = () => {
        dispatch(set_value(["modal_show", false]));
    };
    const messageClose = () => {
        dispatch(set_value(["message_show", false]));
    };

    const setInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const setInput = (start, end) => {
            const start_num = start.split("-").join("");
            const end_num = end.split("-").join("");

            if (end_num < start_num) {
                return true;
            }
        };

        switch (name) {
            case "start_date":
                if (setInput(value, thisState.end_date)) {
                    dispatch(
                        set_value([
                            "error_message",
                            "開始日が終了日より後ろです。",
                        ])
                    );
                    dispatch(set_value(["message_show", true]));
                    return;
                }
                break;
            case "end_date":
                if (setInput(thisState.start_date, value)) {
                    dispatch(
                        set_value([
                            "error_message",
                            "終了日が開始日より前です。",
                        ])
                    );
                    dispatch(set_value(["message_show", true]));
                    return;
                }
                break;
        }

        dispatch(set_value([name, value]));
    };

    const messageModal = () => {
        return (
            <Modal
                open={thisState.message_show}
                onClose={messageClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.message_modal}
            >
                <div className={classes.paper}>{thisState.error_message}</div>
            </Modal>
        );
    };

    const serchStart = () => {
        dispatch(set_value(["modal_show", false]));
        dispatch(set_table_value(["page", 1]));
        dispatch(set_value(["search_triger", thisState.search_triger + 1]));
    };

    const GetHistory = (page) => {
        const params = {
            date_from: `${thisState.start_date} 00:00:00`,
            date_to: `${thisState.end_date} 23:59:59`,
            page: page,
        };

        dispatch(FetchHistory(params));
    };

    const handleCloseError = () => {
        dispatch(set_value(["open_error_dialog", false]));
    };
    const handleClickOK = () => {
        dispatch(set_value(["open_token_error_dialog", false]));
    };

    useEffect(() => {
        dispatch(initialize_table());
        dispatch(set_value(["search_triger", 0]));
    }, []);

    useEffect(() => {
        GetHistory(tableState.page);
    }, [tableState.page, thisState.search_triger]);

    return (
        <>
            <h1>利用履歴</h1>
            <Button
                variant="contained"
                color="primary"
                onClick={handleOpen}
                style={{ margin: "0 0 10px 10px" }}
            >
                履歴の取得条件を変更
            </Button>
            <Modal
                open={thisState.modal_show}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >
                <div className={classes.paper}>
                    <h2>取得条件設定</h2>
                    <form className={classes.container} noValidate>
                        <TextField
                            label="開始日"
                            type="date"
                            value={thisState.start_date}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="start_date"
                            onChange={setInput}
                        />

                        <TextField
                            label="終了日"
                            type="date"
                            value={thisState.end_date}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="end_date"
                            onChange={setInput}
                        />
                        <div>
                            <Button
                                className={classes.buttonSubmit}
                                variant="contained"
                                color="primary"
                                onClick={serchStart}
                            >
                                履歴取得
                            </Button>
                            <Button
                                className={classes.buttonClear}
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    dispatch(crear_serch());
                                }}
                            >
                                クリア
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal>
            {messageModal()}

            <HistoryTable
                rows={thisState.history_list}
                columns={columns}
                recordsPerPageOptions={[tableState.per_page]}
                needsToolbar={true}
                disableColumnMenu={true}
            />
            <ReviewDialog />
            <TokenErrorDialog
                open={thisState.open_token_error_dialog}
                onClickOK={handleClickOK}
            />
            <Error500Handling
                open={thisState.open_error_dialog}
                GET={true}
                onClose={handleCloseError}
            />
        </>
    );
};

export default U_couponUseHistory;
