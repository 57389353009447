import React, { useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import { red } from "@material-ui/core/colors";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import ChatIcon from "@material-ui/icons/Chat";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { useDispatch, useSelector } from "react-redux";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import { LinkButton } from "~CJS/atoms/LinkButton";

const LINES_TO_SHOW = 3;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "500px",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, .5)",
    margin: "0.1rem",
    marginBottom: "0.2rem",
    marginTop: "0.2rem"
  },
  MuiCardHeader: {
    paddingBottom: "0rem",
    marginBottom: "0rem"
  },
  MuiCardContent: {
    paddingBottom: "0rem",
    marginBottom: "0rem"
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    paddingBottom: "0rem",
    marginBottom: "0rem"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: red[500]
  },
  Accordion: {
    padding: "0rem",
    margin: "0rem",
    boxShadow: "none",
    paddingBottom: "0rem",
    marginBottom: "0rem"
  },
  multiLineEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": LINES_TO_SHOW,
    "-webkit-box-orient": "vertical"
  }
}));

const AuditionSeetCard = (props) => {
  const { talent_id, company_id, offer_id, audition_pr, talent_name, image_small, onAdoption, onRepudiation, status } =
    props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <Card
        className={classes.root}
        style={{
          background: (status == "0" && "#fff") || (status == "1" && "#d1ffff") || (status == "2" && "#dcdcdc"),
          marginBottom: "0.5rem"
        }}
      >
        {/* <Grid
                    // xs="6"
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    style={{
                        // border: "solid 1px",
                        boxShadow: "0 2px 10px 0 rgba(0, 0, 0, .5)",
                        position: "relative",
                        backgroundColor: "#fff",
                        width: "150px",
                        margin: "0.1rem",
                        marginBottom: "0.2rem",
                        marginTop: "0.2rem",
                        width: "100%",
                        maxWidth: "500px",
                        display: "inline-block",
                    }}
                > */}

        <CardHeader
          className={classes.MuiCardHeader}
          avatar={
            <Avatar
              component={Link}
              to={`/TalentDetail/${talent_id}`}
              aria-label="recipe"
              src={image_small}
              className={classes.avatar}
            >
              R
            </Avatar>
          }
          // action={
          //     <LinkButton
          //         label={
          //             <>
          //                 トーク{" "}
          //                 <ChatIcon style={{ fontSize: "1.8rem" }} />
          //             </>
          //         }
          //         variant="outlined"
          //         to={`/chat/Chat?talent_id=${talent_id}&&company_id=${company_id}`}
          //     />
          // }
          title={
            <Link style={{ fontSize: "1.4rem", color: "black" }} to={`/TalentDetail/${talent_id}`}>
              {talent_name}
            </Link>
          }
          // subheader="採用回数 0 回"
        />
        <CardContent className={classes.MuiCardContent}>
          <Accordion className={classes.Accordion} square>
            <AccordionSummary className={classes.Accordion} style={{ display: "unset" }}>
              <Typography className={classes.multiLineEllipsis}>{audition_pr}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="textSecondary" component="p">
                {audition_pr}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </CardContent>
        <CardActions disableSpacing>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Grid item>
              <Button style={{ border: "none" }} onClick={() => onRepudiation(talent_id, offer_id)} color="secondary">
                不採用
              </Button>
              <Button
                onClick={() => onAdoption(talent_id, offer_id)}
                variant="contained"
                color="primary"
                style={{ border: "orange" }}
              >
                採用
              </Button>
            </Grid>
            <Grid item>
              {status == "0" && "未判定"}
              {status == "1" && "採用"}
              {status == "2" && "不採用"}
            </Grid>
          </Grid>
        </CardActions>

        {/* </Grid> */}
      </Card>
    </React.Fragment>
  );
};

export default AuditionSeetCard;
