import React, { useEffect } from "react";
import { StopButton, MailInput, OneTimeInput } from "../modules/Login";
import {
    login_set_value,
    initialize,
    NoOntimeShopLogin,
} from "../../stores/Login";
import { set_value } from "../../stores/Information";
import { shopuser_info_set } from "../../stores/ShopUserInfoChange";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import HttpFunction from "../utils/HttpFunction";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { TextFieldValidation } from "../utils/TextFields";
import Grid from "@material-ui/core/Grid";
import { ButtonContainedPrimary, ButtonTextPrimary } from "../utils/Buttons";
import Error500Handling from "../utils/Error500Handling";
import SetUserTokenFlutter from "../utils/SetUserTokenFlultter";
import Container from "@material-ui/core/Container";
const PasswordInput = () => {
    const thisState = useSelector((state) => state.Login);
    const dispatch = useDispatch();
    const history = useHistory();
    const [input, setInput] = React.useState({
        shop_id: "",
        password: "",
    });
    const [disable, setDisable] = React.useState(false);
    const [open500, setOpen500] = React.useState(false);

    const handleBlur = (e) => {
        setInput({ ...input, [e.target.id]: e.target.value });
    };

    const tryLogin = () => {
        setDisable(true);

        const params = {
            email_address: thisState.shop.shop_email_address,
            password: input.password,
            shop_id: input.shop_id,
        };

        HttpFunction("POST", "/api/password_login_shop", params, "")
            .then((data) => {
                // console.log(data);
                dispatch(
                    login_set_value(["error_message", data.error_message])
                );
                if (data.result === "OK") {
                    SetUserTokenFlutter(data.shop_token, "shop");
                    dispatch(
                        set_value(["shop_token", data.shop_token, "shop"])
                    );
                    dispatch(set_value(["shop_login", true, "shop"]));
                    dispatch(set_value(["showDrawer", true]));
                    dispatch(set_value(["showBottomNavigation", true]));
                    dispatch(initialize());
                    history.push("/ShopStoreApplication");
                } else if (data.result === "NG") {
                    setDisable(false);
                } else if (data === 500) {
                    setOpen500(true);
                }
            })
            .catch((err) => {
                // console.log(err);
                setOpen500(true);
            });
    };

    const handleClose500 = () => {
        setOpen500(false);
        setDisable(false);
    };

    const handleForgetPass = () => {
        dispatch(
            shopuser_info_set([
                "email_address",
                thisState.shop.shop_email_address,
            ])
        );
        dispatch(shopuser_info_set(["forget_password", true]));
        dispatch(shopuser_info_set(["situation", "password"]));
        dispatch(shopuser_info_set(["fix_type", "email"]));
        history.push("/ImportantChangeShopUser");
    };

    return (
        <>
            {thisState.error_message && (
                <Alert severity="error">
                    <AlertTitle>エラー</AlertTitle>
                    {thisState.error_message}
                </Alert>
            )}
            <TextFieldValidation
                label="加盟店ID"
                id="shop_id"
                defaultValue={input.shop_id}
                onBlur={handleBlur}
                inputProps={{ maxLength: 8 }}
                autoFocus
            />
            <TextFieldValidation
                label="パスワード"
                id="password"
                type="password"
                defaultValue={input.password}
                onBlur={handleBlur}
                inputProps={{ maxLength: 255 }}
            />
            <div className="text-right">
                <ButtonContainedPrimary disabled={disable} onClick={tryLogin}>
                    ログイン
                </ButtonContainedPrimary>
            </div>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <ButtonTextPrimary onClick={handleForgetPass}>
                    パスワードをお忘れの方
                </ButtonTextPrimary>
            </Grid>
            <Error500Handling open={open500} onClose={handleClose500} />
        </>
    );
};

// const LoginShop = () => {
//     const thisState = useSelector((state) => state.Login);
//     const dispatch = useDispatch();

//     useEffect(() => {
//         if(!thisState.back_flag) dispatch(initialize());
//         dispatch(login_set_value(["error_message", ""]));
//         dispatch(login_set_value(["back_flag", false]));
//         dispatch(login_set_value(["login_mode", "shop"]));
//         dispatch(set_value(["showDrawer", false]));
//         dispatch(set_value(["showBottomNavigation", false]));
//     }, []);

//     return (
//         <>
//             <div style={{padding: "15px 0 0 15px"}}>
//                 <StopButton />
//             </div>
//             <div style={{padding: "0 30px 0"}}>
//                 <h2>加盟店ログイン</h2>
//                 {thisState.email_flag && <MailInput />}
//                 {thisState.onetime_flag && <OneTimeInput />}
//                 {thisState.password_flag && <PasswordInput />}
//             </div>
//         </>
//     );
// };

const LoginShop = () => {
    const thisState = useSelector((state) => state.Login);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (!thisState.back_flag) dispatch(initialize());
        dispatch(login_set_value(["error_message", ""]));
        dispatch(login_set_value(["back_flag", false]));
        dispatch(login_set_value(["login_mode", "shop"]));
        dispatch(set_value(["showDrawer", false]));
        dispatch(set_value(["showBottomNavigation", false]));
    }, []);

    const handleForgetPass = () => {
        const email_address = window.prompt(
            "メールアドレスを入力してください",
            ""
        );
        const regexp =
            /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

        if (!regexp.test(email_address)) {
            alert("メールアドレスの形式が不正です。");
            return;
        }

        dispatch(shopuser_info_set(["email_address", email_address]));
        dispatch(shopuser_info_set(["forget_password", true]));
        dispatch(shopuser_info_set(["situation", "password"]));
        dispatch(shopuser_info_set(["fix_type", "email"]));
        history.push("/ImportantChangeShopUser");
    };

    const HndleLogin = (e) => {
        e.preventDefault();

        const elements = e.target?.elements;

        const getValue = (name) => {
            let value = "";
            if (elements[name]?.value) {
                value = elements[name]?.value;
            }

            return value;
        };

        dispatch(
            NoOntimeShopLogin({
                shop_id: getValue("shop_id"),
                email_address: getValue("email"),
                password: getValue("password"),
            })
        ).then((data) => {
            const payload = data.payload;
            const { result, error_message, shop_token } = payload;

            if (result == "OK") {
                SetUserTokenFlutter(data.shop_token, "shop");
                dispatch(set_value(["shop_token", shop_token, "shop"]));
                dispatch(set_value(["shop_login", true, "shop"]));
                dispatch(set_value(["showDrawer", true]));
                dispatch(set_value(["showBottomNavigation", true]));
                dispatch(initialize());
                history.push("/ShopStoreApplication");
            } else if (error_message) {
                alert(error_message);
            }
        });

        // console.log(err);
    };
    return (
        <Container>
            <div style={{ padding: "15px 0 0 15px" }}>
                <StopButton />
            </div>
            <Container style={{ padding: "0 30px 0", maxWidth: "600px" }}>
                <h2>加盟店ログイン</h2>
                <form onSubmit={HndleLogin}>
                    <TextFieldValidation
                        label="加盟店ID"
                        placeholder="S0000000"
                        name="shop_id"
                        inputProps={{ maxLength: 8 }}
                        autoFocus
                    />
                    <TextFieldValidation
                        label="メールアドレス"
                        name="email"
                        placeholder="name@exampsle.com"
                        type="email"
                        inputProps={{ maxLength: 255 }}
                    />
                    <TextFieldValidation
                        label="パスワード"
                        name="password"
                        type="password"
                        inputProps={{ maxLength: 255 }}
                    />
                    <div className="text-right">
                        <ButtonContainedPrimary type="submit">
                            ログイン
                        </ButtonContainedPrimary>
                    </div>
                </form>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <ButtonTextPrimary onClick={handleForgetPass}>
                        パスワードをお忘れの方
                    </ButtonTextPrimary>
                </Grid>
            </Container>
        </Container>
    );
};

export default LoginShop;
