import { createSlice } from "@reduxjs/toolkit";

// Stateの初期状態
const initialState = {
    type:"",
    email:"",
    api_key:"",
    withdrawal_flag: true,
    onetime_flag: false,
    withdrawal_complete_flag: false,
};

// Sliceを生成する
const slice = createSlice({
    name: "Withdrawal",
    initialState,
    reducers: {
        set_withdrawal_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
        initialize: () => initialState,
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_withdrawal_value, initialize } = slice.actions;
