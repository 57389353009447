import React, { useEffect } from "react";

import { InputMaxLength, InputMail } from "~CJS/atoms/TextFields";
import InputAge from "~CJS/atoms/InputAge";
import InputImagePreview from "~CJS/atoms/InputImagePreview";
import SelectSex from "~CJS/atoms/SelectSex";
import SelectPrefectures from "~CJS/atoms/SelectPrefectures";
import SelectCity from "~CJS/atoms/SelectCity";
import CategoryInput from "~/block/CategoryInput";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { setUseProxies } from "immer";

const TalentProfileInput = (props) => {
    const {
        disabled,
        talent_id,
        fifty_id,
        talent_name,
        talent_name_kana,
        age,
        image_big,
        image,
        sex,
        city,
        address_1,
        address_2,
        tell,
        achievement_count,
        review_average,
        sns_link,
        skill,
        talent_pr,
        height,
        category,
        email_address,
        password,
        create,
        image_small,
        ...other
    } = props;

    return (
        <React.Fragment>
            <InputImagePreview
                key={`${Math.floor(Math.random() * 1000)}-image`}
                comment={false}
                height={200}
                image_big={image_big}
                image={image ? image : image_small}
                disabled={disabled}
            />

            <Box
                style={{
                    // background: "lightyellow",
                    border: "solid 1px",
                    padding: "1rem",
                }}
            >
                <Box sx={{ mb: 1 }}>
                    <Typography>アカウント情報</Typography>
                </Box>
                <InputMaxLength
                    label="メールアドレス"
                    required
                    name="email_address"
                    inputProps={{ maxLength: 254 }}
                    disabled={!create}
                    value={email_address}
                />

                <InputMaxLength
                    key={`${Math.floor(Math.random() * 1000)}-talent_id`}
                    label="タレントID"
                    required
                    name="talent_id"
                    inputProps={{ maxLength: 254 }}
                    disabled={true}
                    value={talent_id}
                />

                <InputMaxLength
                    key={`${Math.floor(Math.random() * 1000)}-talent_id`}
                    label="fifty_id"
                    required={talent_id?.match(/R_/) ? false : true}
                    name="fifty_id"
                    inputProps={{ maxLength: 254 }}
                    disabled={disabled}
                    value={fifty_id}
                    style={{ display: talent_id?.match(/R_/) ? "none" : "" }}
                />
            </Box>
            <InputMaxLength
                label="活動名"
                name="talent_name"
                inputProps={{ maxLength: 32 }}
                required
                disabled={disabled}
                value={talent_name}
            />
            <InputMaxLength
                label="ふりがな"
                name="talent_name_kana"
                inputProps={{ maxLength: 255 }}
                required
                disabled={disabled}
                value={talent_name_kana}
            />

            <CategoryInput disabled={disabled} category_list={category} />

            {disabled && (
                <InputMaxLength
                    name="review_average"
                    label="評価平均"
                    inputProps={{ maxLength: 254 }}
                    disabled={disabled}
                    value={String(review_average ? review_average : "")}
                />
            )}
            <SelectSex disabled={disabled} required value={sex} />
            <InputAge
                type="date"
                name="age"
                label="生年月日"
                // required
                inputProps={{ maxLength: 254 }}
                disabled={disabled}
                value={age}
            />
            <InputMaxLength
                name="height"
                label="身長"
                required
                inputProps={{
                    maxLength: 254,
                    pattern: "^[0-9]+$",
                }}
                value={height}
                disabled={disabled}
            />
            <div>
                <SelectPrefectures
                    disabled={disabled}
                    value={props.prefectures}
                    prefectures={props.prefectures}
                    required
                    city={{ defaultValue: city }}
                />
            </div>
            <InputMaxLength
                name="address_1"
                label="市区町村以下住所"
                inputProps={{ maxLength: 254 }}
                disabled={disabled}
                value={address_1}
            />
            <InputMaxLength
                name="address_2"
                label="マンション・建物名・部屋番号"
                inputProps={{ maxLength: 254 }}
                disabled={disabled}
                value={address_2}
            />
            <InputMaxLength
                multiline
                name="skill"
                label="特技"
                inputProps={{ maxLength: 254 }}
                disabled={disabled}
                value={skill}
            />
            <InputMaxLength
                multiline
                name="talent_pr"
                label="自己PR"
                inputProps={{ maxLength: 254 }}
                disabled={disabled}
                value={talent_pr}
            />
            {disabled && (
                <InputMaxLength
                    name="achievement_count"
                    label="採用件数"
                    inputProps={{ maxLength: 254 }}
                    disabled={disabled}
                    value={String(achievement_count ? achievement_count : "")}
                />
            )}
        </React.Fragment>
    );
};

export default TalentProfileInput;
