import { Route, useLocation, Switch } from "react-router-dom";
import TalentSearch from "~/pages/TALENTBANK/TalentSearch";
import SponsorSearch from "~/pages/TALENTBANK/SponsorSearch";
import TalentCreate from "~/pages/TALENTBANK/TalentCreate";
import TalentDetail from "~/pages/TALENTBANK/TalentDetail";
import TalentDetailFix from "~/pages/TALENTBANK/TalentDetailFix";
import OfferSearch from "~/pages/TALENTBANK/OfferSearch";
import OfferCreate from "~/pages/TALENTBANK/OfferCreate";
import OfferFix from "~/pages/TALENTBANK/OfferFix";
import OfferDetail from "~/pages/TALENTBANK/OfferDetail";
import AuditionList from "~/pages/TALENTBANK/AuditionList";
import AuditionSeetCreate from "~/pages/TALENTBANK/AuditionSeetCreate";
import CompanyCreate from "~/pages/TALENTBANK/CompanyCreate";
import CompanyFix from "~/pages/TALENTBANK/CompanyFix";
import VoteList from "~/pages/TALENTBANK/VoteList";
import CategoryList from "~/pages/TALENTBANK/CategoryList";
import ChatSerch from "~/pages/TALENTBANK/ChatSerch";
import HomeSetting from "../pages/TALENTBANK/HomeSetting";
import VoteCreate from "../pages/TALENTBANK/VoteCreate";
import NewsSetting from "../pages/TALENTBANK/NewsSetting";
import TalentRequestList from "../pages/TALENTBANK/TalentRequestList";
import ChatList from "../pages/TALENTBANK/ChatList";
import MessageList from "../pages/TALENTBANK/MessageList";
import ErrorPage from "~/pages/ErrorPage";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { TalentStyle } from "~/AppStyle";
const theme = createTheme({ ...TalentStyle });

const TalentBankRoute = () => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <Switch>
                    <Route
                        path="/TalentSearch"
                        component={TalentSearch}
                        exact
                    />
                    <Route
                        path="/SponsorSearch"
                        component={SponsorSearch}
                        exact
                    />
                    <Route
                        path="/TalentCreate"
                        component={TalentCreate}
                        exact
                    />
                    <Route
                        path="/TalentDetail/:talent_id"
                        component={TalentDetail}
                        exact
                    />
                    <Route
                        path="/TalentDetailFix/:talent_id"
                        component={TalentDetailFix}
                        exact
                    />
                    <Route path="/OfferSearch" component={OfferSearch} exact />
                    <Route
                        path="/OfferFix/:offer_id"
                        component={OfferFix}
                        exact
                    />
                    <Route path="/OfferCreate" component={OfferCreate} exact />
                    <Route
                        path="/OfferDetail/:offer_id"
                        component={OfferDetail}
                        exact
                    />
                    <Route
                        path="/AuditionList/:offer_id"
                        component={AuditionList}
                        exact
                    />

                    <Route
                        path="/Talent/ChatSerch"
                        component={ChatSerch}
                        exact
                    />

                    <Route
                        path="/AuditionSeetCreate/:offer_id"
                        component={AuditionSeetCreate}
                        exact
                    />
                    <Route
                        path="/CompanyCreate"
                        component={CompanyCreate}
                        exact
                    />
                    <Route
                        path="/CompanyFix/:company_id"
                        component={CompanyFix}
                        exact
                    />
                    <Route path="/VoteList" component={VoteList} exact />
                    <Route path="/VoteCreate" component={VoteCreate} exact />
                    <Route
                        path="/Talent/CategoryList"
                        component={CategoryList}
                        exact
                    />
                    <Route
                        path="/Talent/NewsSetting"
                        component={NewsSetting}
                        exact
                    />
                    <Route
                        path="/Talent/HomeSetting"
                        component={HomeSetting}
                        exact
                    />
                    <Route
                        path="/TalentRequestList"
                        component={TalentRequestList}
                        exact
                    />
                    <Route path="/Talent/MessageList" component={MessageList} exact />
                    <Route
                        path="/ChatList/:company_id"
                        component={ChatList}
                        exact
                    />
                    <Route path="*">
                        <ErrorPage />
                    </Route>
                </Switch>
            </ThemeProvider>
        </>
    );
};

export default TalentBankRoute;
