import React from "react";
import {
    createTheme,
    makeStyles,
    ThemeProvider,
} from "@material-ui/core/styles";
import {
    DataGrid,
    GridToolbarContainer,
    useGridSlotComponentProps,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarColumnsButton,
} from "@material-ui/data-grid";
import jaJPDataGrid from "./JaJPDataGrid";
import jaJPCustom from "./JaJPCustom";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import { useDispatch, useSelector } from "react-redux";
import { set_table_value } from "../../stores/HistoryTable";

//ツールバーのオプションメニュー
function TableMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <IconButton
                aria-label="menu"
                aria-controls="menu-contents"
                aria-haspopup="true"
                onClick={handleClick}
                style={{ marginLeft: "auto" }}
            >
                <MoreVertIcon style={{ width: 50, color: "pink" }} />
            </IconButton>
            <Menu
                id="menu-contents"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <GridToolbarColumnsButton
                    onClick={handleClose}
                    style={{ display: "flex", color: "#f06292" }}
                />
                <GridToolbarDensitySelector
                    onClick={handleClose}
                    style={{ display: "flex", color: "#f06292" }}
                />
            </Menu>
        </React.Fragment>
    );
}

//ツールバー
function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton style={{ color: "#f06292" }} />
            <TableMenu />
        </GridToolbarContainer>
    );
}

//ページネーション
function CustomPagination() {
    const { options } = useGridSlotComponentProps();
    const thisState = useSelector((state) => state.HistoryTable);
    const dispatch = useDispatch();


    const handleChangePage = (event, newPage) => {
        dispatch(set_table_value(["page", newPage + 1]));
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(set_table_value(["per_page", parseInt(event.target.value, 10)]));
    };

    return (
        <Table>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={options.rowsPerPageOptions}
                        count={options.rowCount}
                        page={thisState.page - 1}
                        rowsPerPage={thisState.per_page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    );
}

//表本体
const useStyles1 = makeStyles({
    root: {
        "& .MuiDataGrid-columnHeaderWrapper": {
            backgroundColor: "#ea447b",
            color: "white",
        },
        "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
            {
                outline: "none",
            },
        "& .Mui-odd": {
            backgroundColor: "#EAEAEA",
        },
        "& .MuiDataGrid-cell--textRight": {
            paddingRight: "20px",
        },
    },
});

const theme = createTheme(
    {
        palette: {
            primary: {
                main: "#f06292",
                light: "#b0dcfa",
                dark: "#FFFFFF",
                contrastText: "#ffffff",
            },
        },
    },
    jaJPCustom
);

export default function HistoryTable(props) {
    const { rows, columns, recordsPerPageOptions, needsToolbar, ...other } = props;
    const thisState = useSelector((state) => state.HistoryTable);
    const classes = useStyles1();

    return (
        <div className={classes.root}>
            <ThemeProvider theme={theme}>
                <DataGrid
                    autoHeight
                    rows={rows}
                    columns={columns}
                    pageSize={thisState.per_page}
                    rowsPerPageOptions={recordsPerPageOptions}
                    page={thisState.page -1}
                    disableColumnFilter
                    components={
                        needsToolbar
                            ? {
                                  Pagination: CustomPagination,
                                  Toolbar: CustomToolbar,
                              }
                            : {
                                  Pagination: CustomPagination,
                              }
                    }
                    paginationMode="server"
                    rowCount={thisState.total_rows}
                    localeText={jaJPDataGrid}
                    disableSelectionOnClick
                    {...other}
                />
            </ThemeProvider>
        </div>
    );
}
