import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { LinkButton } from "~CJS/atoms/LinkButton";
import AuditionSeetCard from "~CJS/block/AuditionSeetCard";
import Pagination from "@material-ui/lab/Pagination";
import {
    initialize,
    GetAuditionSeet,
    SetAuditionSeetStatus,
} from "~stores/TALENTBANK/AuditionSeet";

import { useDispatch, useSelector } from "react-redux";

const AuditionSeetList = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const path = history.location.pathname.split("/");
    const offer_id = path[2];

    const auditionSeetState = useSelector((state) => state.AuditionSeet);
    // const InformationState = useSelector((state) => state.Information);
    // const company_id = InformationState.sponsor.company_id;

    // const AuditionSeetState = useSelector((state) => state.AuditionSeetList);

    useEffect(() => {
        dispatch(initialize());
        dispatch(GetAuditionSeet({ offer_id: offer_id }));
    }, []);

    const onAdoption = (talent_id, offer_id) => {
        const reason = window.prompt("採用理由を入力してください", "");
        if (reason === null) {
            return;
        }

        dispatch(
            SetAuditionSeetStatus({
                status: "1",
                talent_id: talent_id,
                offer_id: offer_id,
                reason: reason,
            })
        ).then((data) => {
            if (data.payload.result == "1") {
                dispatch(GetAuditionSeet({ offer_id: offer_id }));
            } else {
                alert(data.payload.error_message);
            }
        });
    };
    const onRepudiation = (talent_id, offer_id) => {
        const reason = window.prompt("不採用理由を入力してください", "");
        if (reason === null) {
            return;
        }

        dispatch(
            SetAuditionSeetStatus({
                status: "2",
                talent_id: talent_id,
                offer_id: offer_id,
                reason: reason,
            })
        ).then((data) => {
            if (data.payload.result == "1") {
                dispatch(GetAuditionSeet({ offer_id: offer_id }));
            } else {
                alert(data.payload.error_message);
            }
        });
    };

    // ページ移動
    const handleChangePage = (e, page) => {
        dispatch(GetAuditionSeet({ offer_id: offer_id, page: page }));
    };

    return (
        <React.Fragment>
            <Container>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        {/* <BackButton
                        history={history}
                        style={{
                            marginBottom: "1rem",
                            height: "3rem",
                            fontSize: "1.4rem",
                        }}
                    />{" "} */}

                        <LinkButton
                            variant="contained"
                            // color="secondary"
                            to={`/OfferDetail/${offer_id}?status=1`}
                            style={{
                                marginBottom: "1rem",
                                height: "3rem",
                                fontSize: "1.4rem",
                                marginLeft: "auto",
                                marginRight: "0",
                                display: "table",
                            }}
                            label="戻る"
                        ></LinkButton>
                    </Grid>
                    <Grid item>
                        <LinkButton
                            variant="contained"
                            color="secondary"
                            to={`/AuditionSeetCreate/${offer_id}`}
                            style={{
                                marginBottom: "1rem",
                                height: "3rem",
                                fontSize: "1.4rem",
                                marginLeft: "auto",
                                marginRight: "0",
                                display: "table",
                            }}
                            label="オーディション応募"
                        ></LinkButton>
                    </Grid>
                </Grid>
                <Typography variant="h1">応募一覧</Typography>

                <Typography>
                    募集人数
                    <b>{auditionSeetState.offer_info.recruitment_count}</b>人
                </Typography>
                <Typography>
                    応募人数<b>{auditionSeetState.offer_info.audition_count}</b>
                    人
                </Typography>
                <Typography>
                    現在の採用人数
                    <b>
                        {
                            auditionSeetState.serch_info
                                ?.achievement_count_current
                        }
                    </b>
                    人
                </Typography>

                <Grid
                    container
                    // direction="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                    style={{
                        padding: "1rem",
                    }}
                >
                    {/* 絞り込み条件 */}
                    {/* <br /> */}
                    {/* <SelectStatus onChange={HandleChange} value={AuditionSeetState.criteria.status} /> */}
                    {/* <Button fullWidth variant="contained" color="primary">
                    検索
                </Button> */}
                </Grid>
                <Grid
                    container
                    // direction="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                >
                    {auditionSeetState?.audition_seet_list &&
                        auditionSeetState.audition_seet_list.map(
                            (value, index) => (
                                <React.Fragment key={index}>
                                    <AuditionSeetCard
                                        {...value}
                                        company_id={"company_id"}
                                        onAdoption={onAdoption}
                                        onRepudiation={onRepudiation}
                                    />
                                </React.Fragment>
                            )
                        )}
                </Grid>

                {auditionSeetState.serch_info?.page_count > 1 && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Pagination
                            shape="rounded"
                            count={auditionSeetState.serch_info?.page_count}
                            variant="outlined"
                            page={auditionSeetState.page}
                            color="secondary"
                            onChange={handleChangePage}
                        />
                    </Grid>
                )}
            </Container>
        </React.Fragment>
    );
};

export default AuditionSeetList;
