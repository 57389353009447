import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import SelectPrefectures from "~CJS/atoms/SelectPrefectures";
import { ValidationText } from "~CJS/atoms/TextFields";
import { SwitchInput } from "~CJS/atoms/SwitchInput";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapForm: {
      display: "flex",
      justifyContent: "center",
      width: "95%",
      margin: `${theme.spacing(0)} auto`,
      flexWrap: "wrap",
      padding: "1rem",
      border: "solid 1px",
    },
    wrapText: {
      width: "100%",
    },
    button: {
      //margin: theme.spacing(1),
    },
  }),
);

const CompanySearchCriteria = (props) => {
  // children 検索表示対象
  // StertSerch 開始ボタンクリックイベント
  const { StertSerch, count, children, ...other } = props;
  const submit_ref = React.useRef(null);
  const classes = useStyles();

  const order_list = [
    {
      label: "人気順",
      value: "1",
      column: "audition_count",
      order: "desc",
    },
    { label: "登録順", value: "2", column: "created_at", order: "desc" },
    { label: "更新順", value: "3", column: "updated_at", order: "desc" },
    { label: "名前順", value: "4", column: "talent_name", order: "desc" },
    {
      label: "締め切り近い順",
      value: "5",
      column: "audition_close",
      order: "asc",
    },
    {
      label: "応募数が少ない順",
      value: "6",
      column: "audition_count",

      order: "asc",
    },
  ];

  const status_list = [
    {
      label: "申請中",
      value: "0",
    },
    {
      label: "公開",
      value: "1",
    },
    {
      label: "否認",
      value: "2",
    },
    {
      label: "非公開",
      value: "3",
    },
    {
      label: "全て",
      value: "",
    },
  ];

  const [genre, set_genre] = React.useState("");
  const [sort, set_sort] = React.useState(0);
  const [tag_select_list, set_tag_select] = React.useState([]);
  const [status, set_status] = React.useState("0");
  const [page, set_page] = React.useState(1);
  const [before_criteria, set_before_criteria] = React.useState("");
  const [limit, set_limit] = React.useState(12);

  const TagClick = (category_name) => {
    if (tag_select_list?.includes(category_name)) {
      // 選択タグリストから削除
      set_tag_select(tag_select_list.filter((item) => item !== category_name));
    } else {
      set_tag_select([...tag_select_list, category_name]);
    }
  };
  useEffect(() => {
    submit_ref.current.click();
  }, [page]);

  const HandleSubmit = (e) => {
    e.preventDefault();
    const elem = e.target.elements;
    const city = elem?.city?.value;
    const company_name = elem?.company_name?.value;
    const prefectures = elem?.prefectures?.value;
    const active_flag = elem?.active_flag?.value;
    const delete_flag = elem?.delete_flag?.value;

    const no_page_criteria = {
      prefectures: prefectures && prefectures != "未選択" ? prefectures : "",
      city: city && city != "未選択" ? city : "",
      genre: genre,
      order: JSON.stringify(order_list[sort]),
      tag_select_list: tag_select_list,
      limit: limit,
      company_name: company_name,
      status: status,
      active_flag: active_flag,
      delete_flag: delete_flag,
    };

    let criteria = { ...no_page_criteria };
    if (Object.is(JSON.stringify(no_page_criteria), JSON.stringify(before_criteria))) {
      set_before_criteria(no_page_criteria);
      criteria["page"] = page;
    } else {
      set_before_criteria(no_page_criteria);
      set_page(1);
      criteria["page"] = "1";
    }
    StertSerch && StertSerch(criteria);
  };
  const handleChangePage = (event, new_page) => {
    set_page(new_page);
  };

  return (
    <React.Fragment>
      <form onSubmit={HandleSubmit} className={classes.wrapForm}>
        <SelectPrefectures />

        <ValidationText
          name="company_name"
          variant="outlined"
          inputProps={{
            maxLength: 254,
          }}
          // value={}
          // onChange={}
          label="企業名"
          {...other}
        ></ValidationText>
        <SwitchInput label="無効のみ" name="active_flag" />
        <SwitchInput label="削除のみ" name="delete_flag" />

        <Button variant="contained" color="primary" type="submit" fullWidth ref={submit_ref}>
          検索
        </Button>
      </form>
      {children}
      {count > 1 && (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Pagination count={count} variant="outlined" page={page} color="secondary" onChange={handleChangePage} />
        </Grid>
      )}
    </React.Fragment>
  );
};

export default CompanySearchCriteria;
