import React from "react";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ValidationText } from "~CJS/atoms/TextFields";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapForm: {
      display: "flex",
      justifyContent: "center",
      width: "95%",
      margin: `${theme.spacing(0)} auto`,
      flexWrap: "wrap",
      padding: "1rem",
      border: "solid 1px",
    },
    wrapText: {
      width: "100%",
    },
    button: {
      //margin: theme.spacing(1),
    },
  })
);

const ChatSearchCriteria = (props) => {
  // children 検索表示対象
  // StertSerch 開始ボタンクリックイベント
  const { StertSerch, count, children, ...other } = props;
  const submit_ref = React.useRef(null);
  const classes = useStyles();

  const HandleSubmit = (e) => {
    e.preventDefault();
    const elem = e.target.elements;

    const talent_id = elem.talent_id.value;
    const company_id = elem.company_id.value;
    if (!company_id.match(/^\C_?\d{8}$/g)) {
      alert("会社IDの形式が違います");
      return;
    }
    if (!talent_id.match(/^\T_?\d{8}$/g)) {
      alert("タレントIDの形式が違います");
      return;
    }
    StertSerch({
      talent_id: talent_id,
      company_id: company_id,
    });
  };

  return (
    <React.Fragment>
      <form onSubmit={HandleSubmit} className={classes.wrapForm}>
        <ValidationText
          name="company_id"
          variant="outlined"
          inputProps={{
            maxLength: 11,
          }}
          fullWidth={false}
          defaultValue={props?.company_id ? props.company_id : "C_00000000"}
          // onChange={}
          label="企業ID"
        ></ValidationText>
        <ValidationText
          name="talent_id"
          variant="outlined"
          inputProps={{
            maxLength: 11,
          }}
          fullWidth={false}
          defaultValue={props?.talent_id ? props.talent_id : "T_00000000"}
          label="タレントID"
        ></ValidationText>
        <Button variant="contained" color="primary" type="submit" fullWidth ref={submit_ref}>
          検索
        </Button>
      </form>
      {children}
    </React.Fragment>
  );
};

export default ChatSearchCriteria;
