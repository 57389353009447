import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
// Stateの初期状態
const initialState = {
    talent_request_list: [],
    serch_info: {
        page_count: 1,
        page: 0,
    },
};

export const GetTalentRequestList = createAsyncThunk(
    "TALENT/GetTalentRequestList",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/talent_request_list";
        const params = arg;

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);
export const ReleaseTalentRequest = createAsyncThunk(
    "TALENT/ReleaseTalentRequest",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/release_talent_request";
        const params = arg;

        try {
            const response = await axios.post(url, params, {
                headers: {
                    "API-KEY": api_key,
                    "content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);
export const ApprovalTalentRequest = createAsyncThunk(
    "TALENT/ApprovalTalentRequest",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/approval_talent_request";
        const params = arg;

        try {
            const response = await axios.post(url, params, {
                headers: {
                    "API-KEY": api_key,
                    "content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const DeleteTalentRequest = createAsyncThunk(
    "TALENT/DeleteTalentRequest",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/delete_talent_request";
        const params = arg;

        try {
            const response = await axios.delete(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "TalentRequest",
    initialState,
    reducers: {
        initialize: (state, action) => {
            if (action.payload) {
                state[action.payload] = initialState[action.payload];
            } else {
                initialState;
            }
        },
        talent_requests_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        [GetTalentRequestList.fulfilled]: (state, action) => {
            const resdata = action.payload;
            if (resdata?.talent_request_list) {
                state.talent_request_list = resdata.talent_request_list;
            }
            if (resdata?.page_count) {
                state.serch_info.page_count = resdata.page_count;
            }

            if (resdata?.page) {
                state.serch_info.page = resdata.page;
            }
            if (resdata?.total) {
                state.total = resdata.total;
            }
        },
        [ReleaseTalentRequest.fulfilled]: (state, action) => {
            const resdata = action.payload;
            if (resdata?.talent_request_list) {
                state.talent_request_list = resdata.talent_request_list;
            }
        },
        [ApprovalTalentRequest.fulfilled]: (state, action) => {
            const resdata = action.payload;
            if (resdata?.talent_request_list) {
                state.talent_request_list = resdata.talent_request_list;
            }
        },
        [DeleteTalentRequest.fulfilled]: (state, action) => {
            const resdata = action.payload;
            if (resdata?.talent_request_list) {
                state.talent_request_list = resdata.talent_request_list;
            }
        },
        // [DeleteTalentRequest.fulfilled]: (state, action) => {
        //     const resdata = action.payload;
        //     state.news_list = resdata.news_list;
        // },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { initialize, talent_requests_set } = slice.actions;
//
// message
// timestamp
// photoURL
// displayName
// avatarDisp
//
//
//
//
