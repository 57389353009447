import React from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    root: {
        height: 300,
        flexGrow: 1,
        minWidth: 300,
    },
    modal: {
        display: "flex",
        padding: theme.spacing(1),
        alignItems: "center",
        justifyContent: "center",
    },
}));
const box_style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    height: "80%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    overflowY: "scroll",
    boxShadow: "0 5px 25px 0 rgba(0, 0, 0, .5)",
    borderRadius: " 1rem",
};
const ModalGeneral = (props) => {
    const classes = useStyles();
    const thisState = useSelector((state) => state.Shoplist);

    return (
        <React.Fragment>
            <Modal
                className={props.className}
                open={props.open}
                onClose={props.onClose}
            >
                <Box sx={{ ...box_style }} style={{ outline: "none" }}>
                    <Grid style={{ height: "5%" }}>
                        <Button onClick={props.onClose}>
                            <CloseIcon />
                            閉じる
                        </Button>
                    </Grid>
                    <Grid style={{ height: "95%" }}>{props.children}</Grid>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default ModalGeneral;
