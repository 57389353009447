import React from "react";
import TextField from "@material-ui/core/TextField";
const SelectLimit = (props) => {
    return (
        <>
            <TextField
                style={{ width: "100%", marginBottom: "1rem" }}
                select
                name="page_limit"
                label="1ページの表示数"
                fullWidth
                variant="outlined"
                SelectProps={{
                    native: true,
                }}
                {...props}
            >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
            </TextField>
        </>
    );
};

export default SelectLimit;
