import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";

const getFormatDate = (date) => {
    return `${date.getFullYear()}${("0" + (date.getMonth() + 1)).slice(-2)}${(
        "0" + date.getDate()
    ).slice(-2)}`;
};
// Stateの初期状態
const initialState = {
    error_message: "",
    before: {},
    modal_progress: false,
};

export const GetSetting = createAsyncThunk(
    "AdminPointSetting/GetSetting",
    async (arg, thunkAPI) => {
        const informationState = thunkAPI.getState().Information;
        const dispatch = thunkAPI.dispatch;
        const api_key = informationState.admin.admin_token;

        const params = "";
        const url = "/api/point_setting";

        const response = await axios.get(
            url + "?" + new URLSearchParams(params),
            {
                headers: {
                    "API-KEY": api_key,
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            }
        );

        return response.data;
    }
);

export const UpdateSetting = createAsyncThunk(
    "AdminPointSetting/UpdateSetting",
    async (arg, thunkAPI) => {
        const informationState = thunkAPI.getState().Information;
        const thisState = thunkAPI.getState().AdminPointSetting;

        const dispatch = thunkAPI.dispatch;
        const api_key = informationState.admin.admin_token;

        const SetErrer = (message) => {
            dispatch(set_value(["error_message", message]));
        };
        const params = {
            app_point: thisState.app_point,
            coupon_point: thisState.coupon_point,
            friend_introduction_point: thisState.friend_introduction_point,
            release_end_date: "9999-12-31 00:00:00",
            release_start_date: "2000-01-01 00:00:00",
            review_point: thisState.review_point,
            shop_introduction_point: thisState.shop_introduction_point,
            updated_at: thisState.updated_at,
            visit_point: thisState.visit_point,
        };
        HttpFunction("PUT", "/api/point_setting_update", params, api_key)
            .then((data) => {

                if (data.error_message) {
                    SetErrer(["サーバーエラー", data.error_message]);
                    return;
                }

                if (data.result == "OK") {
                    SetErrer([
                        "メッセージ",
                        "パカッティー設定を反映しました。",
                        "info",
                    ]);
                    dispatch(GetSetting());
                    return;
                }
            })
            .catch((err) => {
                SetErrer(["サーバーエラー", "設定情報の取得に失敗しました"]);
                // console.log(err);
            });
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "AdminPointSetting",
    initialState,
    reducers: {
        initialize: () => initialState,
        set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];

            if (type) {
                state[type][name] = value;
            } else {
                state[name] = value;
            }
        },
    },
    extraReducers: {
        [GetSetting.pending]: (state, action) => {
            state.modal_progress = true;
        },
        [GetSetting.fulfilled]: (state, action) => {
            if (action.payload.error_message) {
                state.error_message = [
                    "サーバーエラー",
                    action.payload.error_message,
                ];
                state.modal_progress = false;
                return;
            }
            for (let [key, value] of Object.entries(action.payload)) {
                state[key] = value;
                state.before[key] = value;
            }
            state.modal_progress = false;
        },
        [GetSetting.rejected]: (state, action) => {
            // console.log("エラー");
            state.error_message = [
                "サーバーエラー",
                "設定情報の取得に失敗しました",
            ];
            state.modal_progress = false;
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_value, initialize } = slice.actions;
