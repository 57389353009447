import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MascotInfo from "../modules/MascotInfo";
import MascotDrawDialog from "../modules/MascotDrawDialog";
import { ButtonContainedPrimary } from "../utils/Buttons";
import { errorJudge } from "../utils/TextFields";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { UpdateMascot, set_admin_mascot } from "../../stores/AdminMascot";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            padding: "15px",
            width: "350px",
        },
    },
    preview: {
        "& .MuiDialog-container": {
            width: "400px",
            margin: "auto",
        },
    },
}));

const AdminMascotEdit = () => {
    const thisState = useSelector((state) => state.AdminMascot);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    
    // 入力項目 (初期値は現在ステートに入っている値)
    const [input, setInput] = useState({
        mascot_name: thisState.mascot_name,
        mascot_image_big: thisState.mascot_image_big,
        mascot_image_small: thisState.mascot_image_small,
        mascot_point: thisState.mascot_point,
        draw_title: thisState.draw_title,
        draw_detail: thisState.draw_detail,
        mascot_url: thisState.mascot_url,
        memo: thisState.memo,
    });
    const toggleActive = useRef(null);
    // 文字数以外のバリデーションがある項目のエラーチェック用変数
    const [error, setError] = useState({
        mascot_name: false,
        mascot_point: false,
        draw_title: false,
    });

    const [openPreview, setOpenPreview] = useState(false);


    // updates: {setStateしたいkey: setStateしたいvalue}
    const handleBlur = (updates) => {
        setInput({ ...input, ...updates });
    };
    // バリデーションエラーがtrue/falseの際に、エラーチェック用変数の値を更新
    const trueError = (inputId) => {
        setError({ ...error, [inputId]: true });
    };
    const falseError = (inputId) => {
        setError({ ...error, [inputId]: false });
    };

    // ログインボーナスプレビューボタン押下
    const handleClickPreview = () => {
        setOpenPreview(true);
    };
    // ログインボーナスプレビューダイアログを閉じる
    const handleClosePreview = () => {
        setOpenPreview(false);
    };

    // 確定ボタン押下時
    const handleClickUpdate = () => {
        const active_flag = toggleActive.current.checked ? "0" : "1";
        const params = {
            mascot_id: thisState.mascot_id,
            mascot_name: input.mascot_name,
            mascot_image_big: input.mascot_image_big,
            mascot_image_small: input.mascot_image_small,
            mascot_point: input.mascot_point,
            draw_title: input.draw_title,
            draw_detail: input.draw_detail,
            mascot_url: input.mascot_url,
            memo: input.memo,
            active_flag: active_flag,
            updated_at: thisState.updated_at,
        };
        dispatch(UpdateMascot(params));
    };

    // 登録結果表示ダイアログを閉じる
    const handleCloseDialog = () => {
        if (thisState.open_success) {
            dispatch(set_admin_mascot(["open_success", false]));
            // 登録成功時は詳細に戻す
            history.push(`/AdminMascotDetail/${thisState.mascot_id}`);
        } else {
            dispatch(set_admin_mascot(["open_failure", false]));
        }
    };

    return (
        <>
            <ButtonNavi
                left_label="キャンセル"
                left_link={`/AdminMascotDetail/${thisState.mascot_id}`}
            />

            <h1>マスコット編集</h1>

            <MascotInfo
                onBlur={handleBlur}
                trueError={trueError}
                falseError={falseError}
                image={input.mascot_image_small}
                toggleRef={toggleActive}
            />

            <Grid container justifyContent="space-evenly">
                <Grid item xs={2} style={{textAlign: "center"}}>
                    <ButtonContainedPrimary
                        onClick={handleClickPreview}
                        style={{ width: "165px" }}
                    >
                        ボーナスプレビュー
                    </ButtonContainedPrimary>
                </Grid>
                <Grid item xs={2} style={{textAlign: "center"}}>
                    <ButtonContainedPrimary
                        disabled={errorJudge(error)}
                        onClick={handleClickUpdate}
                        style={{ width: "165px" }}
                    >
                        確定
                    </ButtonContainedPrimary>
                </Grid>
            </Grid>

            {/* ログインボーナスプレビューダイアログ */}
            <MascotDrawDialog
                open={openPreview}
                title={input.draw_title}
                image={input.mascot_image_small}
                detail={input.draw_detail}
                url={input.mascot_url}
                point={input.mascot_point}
                onClose={handleClosePreview}
                style={classes.preview}
            />
            
            {/* 編集の結果表示ダイアログ */}
            <Dialog
                open={thisState.open_success || thisState.open_failure}
                onClose={handleCloseDialog}
                className={classes.dialog}
            >
                {thisState.open_success && <p>変更完了</p>}
                {thisState.open_failure && (
                    <>
                        <p>変更できませんでした。</p>
                        <p>{thisState.error_message}</p>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default AdminMascotEdit;
