import React, { useEffect } from "react";
// import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { LinkButton } from "~CJS/atoms/LinkButton";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import Button from "@material-ui/core/Button";
import {
    initialize,
    VoteSet,
    vote_set,
    GetVoteList,
    SetPoll,
    CreateVate,
} from "~stores/TALENTBANK/Vote";
import { SelectDateRange } from "~CJS/atoms/SelectRange";
import InputImagePreview from "~CJS/atoms/InputImagePreview";

import { getDateObj } from "~/functions/Time";

import { ValidationText } from "~CJS/atoms/TextFields";

import { useDispatch, useSelector } from "react-redux";
import { GetNow } from "~/utils/GetNow";

const useStyles = makeStyles((theme) =>
    createStyles({
        wrapForm: {},
        rangeInput: {
            marginTop: "1rem",
        },
        textInput: {
            marginTop: "1rem",
        },
        buttonInput: {
            marginTop: "1rem",
        },
    })
);

const VoteCreate = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const voteState = useSelector((state) => state.Vote);

    // useEffect(() => {
    //     dispatch(initialize());
    //     GetVoteList({
    //         year_month: voteState.vote_serch.year_month.replace("-", ""),
    //     });
    // }, []);

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;
        const image_big = elem.image_big.value;
        const title = elem.title.value;
        const detail = elem.detail.value;
        const memo = elem.memo.value;
        const vote_close = elem.vote_close.value;
        const vote_start = elem.vote_start.value;
        const vote_view_close = elem.vote_view_close.value;
        const vote_view_start = elem.vote_view_start.value;

        if (vote_view_close < vote_close) {
            alert("応募終了日が公開終了日を過ぎています。");
            return;
        }
        if (vote_view_start > vote_start) {
            alert("応募開始日が公開終了日より前が設定されています。");
            return;
        }
        if (!title) {
            alert("投票タイトルが未入力です");
            return;
        }
        const params = {
            vote_title: title,
            image: image_big,
            vote_detail: detail,
            memo: memo,
            vote_close: vote_close,
            vote_start: vote_start,
            vote_view_close: vote_view_close,
            vote_view_start: vote_view_start,
        };

        dispatch(CreateVate(params)).then((data) => {
            switch (data.payload.result) {
                case "1":
                    alert("投票を作成しました。");
                    break;
                case "2":
                    break;

                default:
                    break;
            }

            // dispatch(GetCategoryList({ item_type: 1 }));
        });
    };

    const futureDate = (plus_date) => {
        const date = new Date();
        date.setDate(date.getDate() + plus_date);
        const future_date = getDateObj(date);
        return (
            future_date.year + "-" + future_date.month + "-" + future_date.day
        );
    };

    return (
        <Container>
            <ButtonNavi left_label="投票一覧" left_link="/VoteList" />
            <Typography variant="h1">{"投票作成"}</Typography>

            <form onSubmit={HandleSubmit} className={classes.wrapForm}>
                <InputImagePreview
                    comment={false}
                    height={200}
                    // image_big={image_big}
                    // image={image}
                    option={{ height: 140, width: 500 }}
                    error_image="/images/talentBank/NO_IMAGE/no_image_500×140.png"
                />

                <TextField
                    className={classes.textInput}
                    fullWidth
                    name="title"
                    type="test"
                    label="投票タイトル"
                    variant="outlined"
                    required
                ></TextField>

                <TextField
                    className={classes.textInput}
                    fullWidth
                    name="detail"
                    type="test"
                    multiline
                    label="投票詳細"
                    variant="outlined"
                    rows="3"
                ></TextField>

                <SelectDateRange
                    required
                    className={classes.rangeInput}
                    start_name="vote_view_start"
                    end_name="vote_view_close"
                    start_label="公開開始日"
                    end_label="公開終了日"
                    start={futureDate(1)}
                    end={futureDate(7)}
                />
                <SelectDateRange
                    required
                    className={classes.rangeInput}
                    start_name="vote_start"
                    end_name="vote_close"
                    start_label="応募開始日"
                    end_label="応募終了日"
                    start={futureDate(1)}
                    end={futureDate(7)}
                />

                <TextField
                    className={classes.textInput}
                    fullWidth
                    name="memo"
                    type="text"
                    multiline
                    label="メモ"
                    variant="outlined"
                    rows="3"
                ></TextField>

                <Button
                    className={classes.buttonInput}
                    variant="contained"
                    color="secondary"
                    type="submit"
                    fullWidth
                >
                    作成
                </Button>
            </form>
        </Container>
    );
};

export default VoteCreate;
