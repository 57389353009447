import React, { useEffect } from "react";

import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import {
    set_value,
    initialize,
    CreateQADetail,
    CreateQA,
    GetQAAll,
    UpdateQA,
} from "../../stores/AdminQASetting";

import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import ModalGeneral from "../utils/ModalGeneral";
import CodeEditor from "@uiw/react-textarea-code-editor";

const SelectQAList = (props) => {
    const { list = [], ...other } = props;

    return (
        <>
            <TextField
                style={{ width: "100%", marginBottom: "1rem" }}
                select
                name="classification_name"
                label="分類名"
                fullWidth
                variant="outlined"
                SelectProps={{
                    native: true,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                {...other}
            >
                <option value="">未選択</option>
                {list.map((value, index) => (
                    <React.Fragment key={index}>
                        <option value={value.qa_id}>
                            {value.classification_name}
                        </option>
                    </React.Fragment>
                ))}

                {/* <option value={3}>Q&A</option> */}
            </TextField>
        </>
    );
};
const SelectControlKubun = (props) => {
    return (
        <>
            <TextField
                style={{ width: "100%", marginBottom: "1rem" }}
                select
                name="control_kubun"
                label="表示対象"
                fullWidth
                variant="outlined"
                SelectProps={{
                    native: true,
                }}
                {...props}
            >
                <option value={0}>全て</option>
                <option value={1}>非ログイン</option>
                <option value={2}>一般会員</option>
                <option value={3}>店舗会員</option>
                <option value={4}>管理者</option>
            </TextField>
        </>
    );
};

const AdminQASetting = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const QAState = useSelector((state) => state.AdminQASetting);
    const [modal, set_modal] = React.useState(false);
    const [fix_flag, set_fix_flag] = React.useState(false);
    const [serch_option, set_serch_option] = React.useState({
        document_kubun: 2,
    });

    useEffect(() => {
        dispatch(initialize());
        dispatch(GetQAAll());
        // dispatch(
        //     GetQAAll({ document_kubun: serch_option.document_kubun })
        // );
    }, []);

    const ShowControlKubun = (control_kubun) => {
        switch (control_kubun) {
            case "0":
                return "全て";
            case "1":
                return "非ログイン";
            case "2":
                return "一般会員";
            case "3":
                return "店舗会員";
            case "4":
                return "管理者";

            default:
                return "";
        }
    };

    const OpenDetail = (value) => {
        set_modal(value);
    };

    const handleChange = (e) => {
        dispatch(set_value([e.target.name, e.target.value]));
    };

    const handleDelete = (id) => {
        dispatch(
            UpdateQA({
                id: id,
                data: { delete_flag: 1 },
            })
        ).then((data) => {
            dispatch(GetQAAll());
            set_modal(false);
            set_fix_flag(false);
        });
    };
    const HndleUpdate = (e) => {
        e.preventDefault();

        const elements = e.target?.elements;

        const getValue = (name) => {
            let value = "";
            if (elements[name]?.value) {
                value = elements[name]?.value;
            }

            return value;
        };

        dispatch(
            UpdateQA({
                id: modal.id,
                data: { answer: getValue("answer") },
            })
        ).then((data) => {
            dispatch(GetQAAll());
            set_modal(false);
            set_fix_flag(false);
        });
    };

    const onCreate = (e) => {
        e.preventDefault();
        const elements = e.target?.elements;

        const getValue = (name) => {
            let value = "";
            if (elements[name]?.value) {
                value = elements[name]?.value;
            }

            return value;
        };

        if (!getValue("classification_name")) {
            alert("分類名は必須項目です");
            return;
        }

        dispatch(
            CreateQA({
                classification_name: getValue("classification_name"),
                control_kubun: getValue("control_kubun"),
            })
        ).then((data) => {
            dispatch(GetQAAll());
            document.getElementById("create-qa-form").reset();
        });
    };

    const onCreateDetail = (e) => {
        e.preventDefault();

        const elements = e.target?.elements;

        const getValue = (name) => {
            let value = "";
            if (elements[name]?.value) {
                value = elements[name]?.value;
            }

            return value;
        };

        if (!getValue("classification_name")) {
            alert("分類名は必須項目です");
            return;
        }
        if (!getValue("question")) {
            alert("質問は必須項目です");
            return;
        }
        if (!getValue("answer")) {
            alert("回答は必須項目です");
            return;
        }

        dispatch(
            CreateQADetail({
                qa_id: getValue("classification_name"),
                answer: getValue("answer"),
                question: getValue("question"),
                control_kubun: getValue("control_kubun"),
            })
        ).then((data) => dispatch(GetQAAll()));
    };
    const onFix = () => {
        set_fix_flag(!fix_flag);
    };

    const GetKubun = (qa_id) => {
        if (!qa_id) return "";
        if (QAState.qa_list.length < 1) return "";
        const qa_list = QAState.qa_list;

        const qa_val = qa_list.filter(function (e) {
            return e.qa_id == qa_id;
        });
        return qa_val[0].classification_name;
    };

    const HeadGlid = (props) => {
        return (
            <Grid
                item
                xs={6}
                style={{
                    background: "pink",
                    padding: "1rem",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    border: "solid 1px",
                    textAlign: "left",
                    margin: "0px",
                }}
                {...props}
            ></Grid>
        );
    };
    const BodyGlid = (props) => {
        return (
            <Grid
                item
                style={{
                    border: "1px solid",
                    textAlign: "center",
                    margin: "0px",
                    padding: "0.5rem",
                }}
                {...props}
            ></Grid>
        );
    };

    return (
        <React.Fragment>
            <Container>
                <ButtonNavi left_label="戻る" left_link={`/AdminHome`} />
                <h1>Q&A管理</h1>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        border: "solid 1px",
                        padding: "2rem",
                        marginBottom: "2rem",
                    }}
                >
                    <Grid item xs={12}>
                        <b
                            style={{
                                fontSize: "1.5rem",
                            }}
                        >
                            分類作成
                        </b>
                    </Grid>
                    <Grid item xs={12}>
                        <form onSubmit={onCreate}>
                            <SelectControlKubun />
                            <TextField
                                style={{ marginBottom: "1rem" }}
                                fullWidth
                                label="分類名"
                                name="classification_name"
                                variant="outlined"
                                defaultValue={QAState.classification_name}
                                onBlur={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            ></TextField>
                            <Button variant="outlined" type="submit">
                                作成
                            </Button>
                        </form>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        border: "solid 1px",
                        padding: "2rem",
                        marginBottom: "2rem",
                    }}
                >
                    <Grid item xs={12}>
                        <b
                            style={{
                                fontSize: "1.5rem",
                            }}
                        >
                            Q&A新規作成
                        </b>
                    </Grid>
                    <Grid item xs={12}>
                        <form onSubmit={onCreateDetail} id="create-qa-form">
                            <SelectQAList list={QAState.qa_list} />
                            <SelectControlKubun />
                            <TextField
                                style={{ marginBottom: "1rem" }}
                                fullWidth
                                label="Question"
                                name="question"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            ></TextField>

                            <TextField
                                style={{ marginBottom: "1rem" }}
                                fullWidth
                                multiline
                                label="Answer"
                                name="answer"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    style: {
                                        alignItems: "unset",
                                        minHeight: "10rem",
                                    },
                                }}
                            ></TextField>
                            <Button variant="outlined" type="submit">
                                作成
                            </Button>
                        </form>
                    </Grid>
                </Grid>

                {/* <SelectclassificationName
                    onChange={kubunChange}
                    value={serch_option.document_kubun}
                /> */}
                <Grid container>
                    <HeadGlid xs={1}>対象</HeadGlid>
                    <HeadGlid xs>区分</HeadGlid>
                    <HeadGlid xs>タイトル</HeadGlid>

                    {serch_option.document_kubun != "1" && (
                        <HeadGlid xs>項目</HeadGlid>
                    )}
                    <HeadGlid xs={1}>詳細</HeadGlid>
                    {QAState?.qa_detail && (
                        <>
                            {QAState.qa_detail.map((value, index) => (
                                <React.Fragment key={index}>
                                    <Grid container>
                                        <BodyGlid xs={1}>
                                            {ShowControlKubun(
                                                value.control_kubun
                                            )}
                                        </BodyGlid>
                                        <BodyGlid xs>
                                            <>{GetKubun(value.qa_id)}</>
                                        </BodyGlid>

                                        <BodyGlid xs>{value.question}</BodyGlid>

                                        <BodyGlid xs>
                                            <div style={{ display: "grid" }}>
                                                <Typography align="left" noWrap>
                                                    {value.answer}
                                                </Typography>
                                            </div>
                                        </BodyGlid>

                                        <BodyGlid xs={1}>
                                            <Button
                                                variant="outlined"
                                                onClick={() =>
                                                    OpenDetail(value)
                                                }
                                            >
                                                詳細
                                            </Button>
                                        </BodyGlid>
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </>
                    )}
                </Grid>
            </Container>

            <ModalGeneral
                onClose={() => set_modal(false)}
                open={modal ? true : false}
            >
                <Grid
                    container
                    style={{ padding: "1rem", margin: "1rem", width: "auto" }}
                >
                    <div style={{ flexGrow: 1 }}></div>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => handleDelete(modal.id)}
                    >
                        削除
                    </Button>
                </Grid>
                <form onSubmit={HndleUpdate}>
                    <Grid
                        container
                        style={{
                            padding: "1rem",
                            margin: "1rem",
                            width: "auto",
                        }}
                    >
                        <Grid container>
                            <Grid item xs={3}>
                                タイトル
                            </Grid>
                            <Grid item xs={9}>
                                {GetKubun(modal.qa_id)}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={3}>
                                質問
                            </Grid>
                            <Grid item xs={9}>
                                {modal.question}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={3}>
                                表示対象
                            </Grid>
                            <Grid item xs={9}>
                                {ShowControlKubun(modal.control_kubun)}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container style={{ margin: "1rem" }}>
                        {fix_flag ? (
                            <>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={onFix}
                                    style={{ margin: "1rem" }}
                                >
                                    キャンセル
                                </Button>
                                <Button
                                    variant="outlined"
                                    type="submit"
                                    style={{ margin: "1rem" }}
                                >
                                    更新
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    variant="outlined"
                                    onClick={onFix}
                                    style={{ margin: "1rem" }}
                                >
                                    編集
                                </Button>
                            </>
                        )}
                    </Grid>
                    <Grid
                        container
                        style={{
                            margin: "1rem",
                            border: "solid 1px #c2c2c2",
                            width: "auto",
                            padding: "1rem",
                        }}
                    >
                        {fix_flag ? (
                            <>
                                <InputCode modal={modal} />
                            </>
                        ) : (
                            <>
                                {modal && modal.answer && (
                                    <>
                                        <div
                                            className="html-preview"
                                            dangerouslySetInnerHTML={{
                                                __html: modal.answer.replace(
                                                    /\n/g,
                                                    "<br>"
                                                ),
                                            }}
                                        ></div>
                                    </>
                                )}
                            </>
                        )}
                    </Grid>
                </form>
            </ModalGeneral>
        </React.Fragment>
    );
};

const InputCode = (props) => {
    const { modal } = props;

    const [code, setCode] = React.useState(modal.answer);

    return (
        <div
            style={{
                width: "100%",
            }}
        >
            <div className="w-tc-editor-var"></div>
            <CodeEditor
                value={code}
                language="html"
                name="answer"
                placeholder="入力してください。"
                onChange={(evn) => setCode(evn.target.value)}
                padding={15}
                style={{
                    minWidth: "500px",
                    fontSize: 20,
                    backgroundColor: "#f5f5f5",
                    fontFamily:
                        "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace !important",
                }}
            />
        </div>
    );
};

export default AdminQASetting;
