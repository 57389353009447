import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";
// Stateの初期状態
const initialState = {
    read_count: 0,
    notification_list_data: [
        {
            id: "",
            notification_id: "",
            notification_title: "通知はありません",
            release_start_date: "",
            read_flag: "1",
            button_url: "",
        },
    ],
    notification_detail: {
        message: `初期値`,
        notification_title: "",
        notification_detail: "",
    },
    notification_popup: "",
    popup_show: false,
    error_message: "",
};
export const GetNoticeDetail = createAsyncThunk(
    "NotificationList/GetNoticeDetail",
    async (arg, thunkAPI) => {
        const InformationState = thunkAPI.getState().Information;
        const params = {
            notification_id: arg.notification_id,
        };

        let api_key = "";
        let url = "";

        if (InformationState.user.user_login) {
            api_key = InformationState.user.token;
            url = "/api/notice_detail";
        }
        if (InformationState.shop.shop_login) {
            api_key = InformationState.shop.shop_token;
            url = "/api/shop_notice_detail";
        }

        const response = await axios.get(
            url + "?" + new URLSearchParams(params),
            {
                headers: {
                    "API-KEY": api_key,
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            }
        );
        return response.data;
    }
);

export const GetNotices = createAsyncThunk(
    "NotificationList/GetNotices",
    async (arg, thunkAPI) => {
        const informationState = thunkAPI.getState().Information;

        let api_key = null;
        let url = null;

        if (informationState.user.user_login) {
            api_key = informationState.user.token;
            url = "/api/notices";
        }
        if (informationState.shop.shop_login) {
            api_key = informationState.shop.shop_token;
            url = "/api/shop_notices";
        }

        if (!api_key) return;

        const params = "";

        const response = await axios.get(
            url + "?" + new URLSearchParams(params),
            {
                headers: {
                    "API-KEY": api_key,
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            }
        );
        return response.data;
    }
);

export const GetPopup = createAsyncThunk(
    "NotificationList/GetPopup",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;

        let api_key = null;
        let url = "/api/popup";

        if (informationState.user.user_login) {
            api_key = informationState.user.token;
        }
        if (informationState.shop.shop_login) {
            api_key = informationState.shop.shop_token;
        }

        const params = "";

        const response = await axios.get(
            url + "?" + new URLSearchParams(params),
            {
                headers: {
                    "API-KEY": api_key,
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            }
        );
        return response.data;
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "NotificationList",
    initialState,
    reducers: {
        notice_set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];

            if (type) {
                state[name] = value;
                let new_type = state[type];
                new_type[name] = value;
                state[type] = new_type;
            } else {
                state[name] = value;
            }

            state[name] = value;
        },
    },
    extraReducers: {
        [GetPopup.pending]: (state, action) => {},
        [GetPopup.fulfilled]: (state, action) => {
            const payload = action.payload;
            if(!payload) return ;
            if (payload?.error_message) 
                state.error_message = ["サーバーエラー", payload.error_message];
            // 表示するポップアップが無かった場合/エラーが返ってきた場合は、ポップアップ表示済みフラグをtrueにする
            if (!payload.notification_id) {
                state.popup_show = true;
            }

            state.notification_popup = payload;
        },

        [GetPopup.rejected]: (state, action) => {
            // console.log("ポップアップ通知の取得に失敗しました");
            // エラーが返ってきた場合は、ポップアップ表示済みフラグをtrueにする
            state.popup_show = true;
        },

        [GetNotices.pending]: (state, action) => {},
        [GetNotices.fulfilled]: (state, action) => {
            const payload = action.payload;
            if(!payload) return;
            if (payload?.error_message)
                state.error_message = ["サーバーエラー", payload.error_message];
            
            if (payload?.data)
                state.notification_list_data = payload?.data;
        },

        [GetNotices.rejected]: (state, action) => {
            // console.log("通知一覧の取得に失敗しました");
        },
        [GetNoticeDetail.pending]: (state, action) => {},
        [GetNoticeDetail.fulfilled]: (state, action) => {
            const notification_detail = action.payload.data;
            state.notification_detail = notification_detail;
        },
        [GetNoticeDetail.rejected]: (state, action) => {
            // console.log("通知詳細の取得に失敗しました");
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { notice_set_value } = slice.actions;
