import React, { useEffect } from "react";

import ModalGeneral from "../utils/ModalGeneral";
import HttpFunction from "../utils/HttpFunction";
import { set_alert } from "../../stores/ErrorAlert";
import ItemCard from "../modules/ItemCard";
import Paper from "@material-ui/core/Paper";

import {
    exchange_set,
    GetGiftList,
    PointExchange,
} from "../../stores/GeneralRedeemingPointList";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { UserInfoGet } from "../../stores/Information";
import {
    Typography,
    AppBar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    CssBaseline,
    Grid,
    Toolbar,
    Container,
    Divider,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ModalProgress from "../utils/ModalProgress";

const GeneralRedeemingPointList = () => {
    const dispatch = useDispatch();
    const exchange_state = useSelector(
        (state) => state.GeneralRedeemingPointList
    );
    const point = useSelector((state) => state.Information.user.point_sum);
    const informationState = useSelector((state) => state.Information);

    //ポイント表示
    const ShowPoint = () => {
        return (
            <Container>
                <Grid container>保有パカッティー {point}</Grid>
            </Container>
        );
    };

    useEffect(() => {
        dispatch(UserInfoGet(informationState.user.token));
        dispatch(GetGiftList());
    }, []);

    useEffect(() => {
        dispatch(set_alert(exchange_state.error_message));
    }, [exchange_state.error_message]);

    const OnExchangeDecision = (check_list) => {
        dispatch(PointExchange(check_list));
    };

    const CheckModal = () => {
        return (
            <Container>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: "200px" }}
                >
                    <img
                        src={
                            exchange_state.check_list.image_path
                                ? exchange_state.check_list.image_path
                                : "null"
                        }
                        onError={(e) => {
                            e.target.onerror = "null";
                            e.target.src = "/images/no_img.png";
                        }}
                    />
                </Grid>
                {/* <Grid container>交換品 </Grid> */}
                <h3>交換品 </h3>
                {"商品番号: " + exchange_state.check_list.product_id}
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ margin: "1rem 0rem" }}
                >
                    <b>{exchange_state.check_list.product_name}</b>
                </Grid>

            
                {/* <Accordion>
                    <AccordionSummary>商品詳細を見る</AccordionSummary>
                    <AccordionDetails> */}
                <div
                    className={"html-preview"}
                    dangerouslySetInnerHTML={{
                        __html: exchange_state.check_list.product_comment
                            ? exchange_state.check_list.product_comment.replace(
                                  /\n/g,
                                  "<br>"
                              )
                            : "",
                    }}
                ></div>
                {/* </AccordionDetails>
                </Accordion> */}
                <Paper
                    elevation={3}
                    style={{
                        marginTop: "2rem",
                    }}
                >
                    <Container>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid>保有パカッティー </Grid>
                            <Grid> {point}</Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid>必要パカッティー </Grid>
                            <Grid>
                                {" "}
                                {exchange_state.check_list.necessary_point}
                            </Grid>
                        </Grid>
                        <Grid
                            style={{
                                fontSize: "1rem",
                                borderTop: "solid 1px gray",
                            }}
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid>交換後パカッティー </Grid>
                            <Grid>
                                {point -
                                    exchange_state.check_list.necessary_point}
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
                <Grid
                    style={{
                        marginTop: "2rem",
                    }}
                    container
                >
                    交換しますか?{" "}
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                            OnExchangeDecision(exchange_state.check_list)
                        }
                    >
                        交換
                    </Button>
                </Grid>
            </Container>
        );
    };

    const OnExchange = (e) => {
        dispatch(exchange_set(["open_modal", true]));
        dispatch(
            exchange_set([
                "check_list",
                exchange_state.item_list[e.currentTarget.name],
            ])
        );
    };
    const CloseModal = () => {
        dispatch(exchange_set(["open_modal", false]));
    };

    return (
        <React.Fragment>
            <ModalProgress open={exchange_state.modal_progress} />
            <ModalGeneral
                open={exchange_state.open_modal}
                className={"test-modal"}
                onClose={CloseModal}
            >
                <CheckModal />
            </ModalGeneral>
            <ShowPoint />

            <ExchangeTarget OnExchange={OnExchange} />
        </React.Fragment>
    );
};

//景品一覧
const ExchangeTarget = (props) => {
    const exchange_state = useSelector(
        (state) => state.GeneralRedeemingPointList
    );

    const item_list = exchange_state.item_list;
    return (
        <Grid container>
            {item_list ? (
                item_list.map((data, index) => (
                    <Grid
                        item
                        xs={12}
                        style={{
                            padding: "0.5rem",
                        }}
                        key={index}
                    >
                        <ItemCard
                            data={data}
                            index={index}
                            OnExchange={props.OnExchange}
                            ButtonName="交換"
                        />
                    </Grid>
                ))
            ) : (
                <>交換できる景品がありません</>
            )}
        </Grid>
    );
};

export default GeneralRedeemingPointList;
