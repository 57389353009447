import { createSlice } from "@reduxjs/toolkit";

// Stateの初期状態
const initialState = {
    page: 1,
    per_page: 1,
    total_rows: 1,
};
// Sliceを生成する
const slice = createSlice({
    name: "HistoryTable",
    initialState,
    reducers: {
        set_table_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
        initialize_table: () => initialState,
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_table_value, initialize_table } = slice.actions;