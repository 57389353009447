import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { LinkButton } from "~CJS/atoms/LinkButton";
import Button from "@material-ui/core/Button";
import { PanelButton } from "~CJS/atoms/StyledButton";

import { useDispatch, useSelector } from "react-redux";

import { BackButton } from "~CJS/atoms/BackButton";
// import FixedBand from "~/atoms/FixedBand";

import OfferDetailInput from "~CJS/block/OfferDetailInput";
// import CompanyCard from "~CJS/block/CompanyCard";
import { initialize, OfferDecision, GetDetail } from "~stores/TALENTBANK/Offer";

const OfferDetail = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const offerState = useSelector((state) => state.Offer);
    const path = history.location.pathname.split("/");
    const offer_id = path[2];

    useEffect(() => {
        const params = { offer_id: offer_id };
        // dispatch(initialize());
        dispatch(GetDetail(params));
    }, []);

    const Judgement = (status, reason = "") => {
        console.log({ reason: reason });
        const params = {
            offer_id: offer_id,
            status: status,
            reason: reason,
            id: offerState.offer_detail.id,
        };
        dispatch(OfferDecision(params)).then((data) => {
            dispatch(GetDetail({ offer_id: offer_id }));
            history.push(`/OfferDetail/${offer_id}`);
        });
    };
    const handleApproval = () => {
        Judgement(1);
    };

    const handlePrivate = () => {
        Judgement(3);
    };

    const handleRepudiation = () => {
        const reason = window.prompt("否認理由を入力してください", "");
        if (reason === null) {
            return;
        }
        Judgement(2, reason);
    };

    return (
        <React.Fragment>
            <Container>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <LinkButton
                            variant="contained"
                            // color="secondary"
                            to={`/OfferSearch`}
                            style={{
                                marginBottom: "1rem",
                                height: "3rem",
                                fontSize: "1.4rem",
                                marginLeft: "auto",
                                marginRight: "0",
                                display: "table",
                            }}
                            label="戻る"
                        ></LinkButton>
                    </Grid>
                    <Grid item>
                        <LinkButton
                            variant="contained"
                            color="secondary"
                            to={`/AuditionList/${offer_id}`}
                            style={{
                                marginBottom: "1rem",
                                height: "3rem",
                                fontSize: "1.4rem",
                                marginLeft: "auto",
                                marginRight: "0",
                                display: "table",
                            }}
                            label="オーディションシート"
                        ></LinkButton>
                    </Grid>
                </Grid>
                <Typography variant="h1">お仕事詳細</Typography>
                <OfferDetailInput disabled data={offerState.offer_detail} />

                <Grid container style={{ width: "96%", margin: "2%" }}>
                    <LinkButton
                        variant="contained"
                        color="secondary"
                        to={`/OfferFix/${offer_id}`}
                        style={{
                            width: "100%",
                            marginBottom: "1rem",
                            height: "3rem",
                            fontSize: "1.4rem",
                        }}
                        label="編集"
                    ></LinkButton>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <PanelButton
                                label="公開"
                                onClick={handleApproval}
                                disabled={
                                    offerState.offer_detail?.status == "1"
                                }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <PanelButton
                                label="非公開"
                                onClick={handlePrivate}
                                disabled={
                                    offerState.offer_detail?.status != "1"
                                }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <PanelButton
                                label="否認"
                                onClick={handleRepudiation}
                                disabled={
                                    offerState.offer_detail?.status != "0"
                                }
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                        <PanelButton
                            label="公開情報を取得"
                            onClick={handlePublic}
                            disabled={offerState.offer_detail?.status != "0"}
                        />
                    </Grid> */}
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default OfferDetail;
