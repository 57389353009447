
const jaJPCustom = {
    props: {
      // MuiBreadcrumbs: {
      //   expandText: 'Show path',
      // },
      MuiTablePagination: {
        backIconButtonText: '前のページ',
        labelRowsPerPage: '1ページ:',
        labelDisplayedRows: ({ from, to, count }) =>
          `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`,
        nextIconButtonText: '次のページ',
      },
      MuiRating: {
        getLabelText: (value) => `${value} ${value !== 1 ? '出演者' : '星'}`,
        // emptyLabelText: 'Empty',
      },
      MuiAutocomplete: {
        clearText: 'クリア',
        closeText: '閉じる',
        loadingText: '積み込み…',
        noOptionsText: '結果がありません',
        openText: '開いた',
      },
      MuiAlert: {
        closeText: '閉じる',
      },
    },
};

export default jaJPCustom;