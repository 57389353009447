import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { set_value } from "../../stores/U_pointHistory";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDialog-paper": {
            width: "300px",
            padding: "15px",
        },
        "& .MuiTypography-root": {
            margin: "5px 0",
            "& > span": {
                fontWeight: "bolder",
            },
        },
    },
    title: {
        fontSize: "1.5rem",
        textAlign: "center",
        color: theme.palette.primary.main,
    },
}));

const PointHistoryDialog = () => {
    const thisState = useSelector((state) => state.U_pointHistory);
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleClose = () => {
        dispatch(set_value(["open_detail_dialog", false]));
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="point-history-detail"
            open={thisState.open_detail_dialog}
            className={classes.root}
        >
            <Typography className={classes.title}>詳細</Typography>
            <Typography>日時：{thisState.selected_date}</Typography>
            <Typography>パカッティー増減：<span>{thisState.selected_point + " P"}</span></Typography>
            <Typography>イベント：{thisState.selected_reason}</Typography>
        </Dialog>    
    );
};

export default PointHistoryDialog;