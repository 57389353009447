import React, { useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import QRCode from "qrcode.react";
import ReactToPrint from "react-to-print";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { ButtonContainedPrimary } from "../utils/Buttons";
import { CreateQR } from "../../stores/AdminShopQRCreate";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    qr_grid: {
        padding: "2rem",
    },
    qr_canvas: {
        padding: "10px",
        border: "solid 2px #cbc8c8",
        marginBottom: "10px",
    },
    qr_dummy: {
        width: "250px",
        height: "250px",
        border: "solid 2px #cbc8c8",
        marginBottom: "10px",
        fontSize: "4rem",
        fontWeight: "bold",
        textAlign: "center",
        lineHeight: "250px",
        color: "#cbc8c8",
    },
    shop_name: {
        marginBottom: "2rem",
    },
    shop_name_dummy: {
        height: "3rem",
    },
    form: {
        display: "flex",
    },
    button: {
        marginLeft: "20px",
    },
    dialog: {
        "& .MuiDialog-paper": {
            padding: "15px",
        },
    },
    print_button: {
        margin: "1rem",
        width: "300px",
    },
    printGrid: {},
}));

const AdminShopQRCreate = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [qrKey, setQrKey] = useState(null);
    const [shopName, setShopName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [open, setOpen] = useState(false);

    const componentRef = useRef();

    // QR表示ボタン押下
    const createShopQR = (e) => {
        e.preventDefault();

        const params = {
            shop_id: e.target.elements.shop_id.value,
        };

        dispatch(CreateQR(params)).then((res) => {
            const data = res.payload;
            if (data.result === "OK") {
                setQrKey(data.qr_key);
                setShopName(data.shop_name);
            } else {
                setQrKey(null);
                setErrorMessage(data.error_message);
                setOpen(true);
            }
        });
    };

    // エラーダイアログを閉じる
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container>
            <ButtonNavi left_label="戻る" left_link="/AdminHome" />
            <h1>加盟店QRコード</h1>

            <Grid
                className={classes.qr_grid}
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                {qrKey ? (
                    <>
                        <Grid
                            ref={componentRef}
                            container
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            className={classes.printGrid}
                        >
                            <h1> {shopName}</h1>
                            <QRCode
                                value={qrKey}
                                size={250}
                                fgColor="#333333"
                                imageSettings={{
                                    src: "/favicon.ico",
                                    height: 40,
                                    width: 40,
                                    excavate: true,
                                }}
                                className={classes.qr_canvas}
                            />
                        </Grid>
                        <ReactToPrint
                            trigger={() => (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.print_button}
                                >
                                    印刷する
                                </Button>
                            )}
                            content={() => componentRef.current}
                            // document
                            // Title={ここに保存時のファイル名をデフォルトを設定できる}
                        />
                        <p className={classes.shop_name}>
                            {shopName} のQRコード (右クリックで保存できます)
                        </p>
                    </>
                ) : (
                    <>
                        <span className={classes.qr_dummy}>QR</span>
                        <div className={classes.shop_name_dummy} />
                    </>
                )}
                <form onSubmit={createShopQR} className={classes.form}>
                    <TextField
                        name="shop_id"
                        label="加盟店ID"
                        placeholder="S*******"
                        required
                        variant="outlined"
                        autoFocus
                    />
                    <ButtonContainedPrimary
                        type="submit"
                        className={classes.button}
                    >
                        QR表示
                    </ButtonContainedPrimary>
                </form>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                className={classes.dialog}
            >
                <p>エラー：</p>
                <p>{errorMessage}</p>
            </Dialog>
        </Container>
    );
};

export default AdminShopQRCreate;
