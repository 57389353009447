import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetShopData } from "./ShopInformation";
import HttpFunction from "../components/utils/HttpFunction";
import CreateValidationErrorMessage from "../components/utils/CreateValidationErrorMessage";

import { GetNow } from "../components/utils/GetNow";

const now = GetNow();
const ymd =
    now.year +
    "-" +
    now.month +
    "-" +
    ("0" + (new Date().getDate() + 1)).slice(-2);
// Stateの初期状態
const initialState = {
    top_release_target: "222",
    new_or_fix: "new",
    notification_id: "undefined",
    preview_flag: false,
    control_kubun: "2",
    notification_title: "",
    notification_detail: "",
    notification_category: "1",
    image_path: "",
    image: null,
    button_url: "",
    release_start_date: ymd,
    release_end_date: ymd,
    indefinitely: false,
    error_message: "",
    delete_flag: false,
};

//現在の通知データの取得
export const GetNotice = createAsyncThunk(
    "AdminNotice/GetNotice",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;
        const thisState = thunkAPI.getState().AdminNotice;
        const listPage = thunkAPI.getState().HistoryTable.page;
        const api_key = informationState.admin.admin_token;
        const SetErrer = (message) => {
            dispatch(set_Notice(["error_message", message]));
        };
        const notification_id = arg.notification_id;

        if (notification_id == "undefined") {
            SetErrer(["サーバーエラー", "通知IDがありません"]);
        }
        const params = {
            page: listPage,
        };

        const url = "/api/admin_notices";
        const response = await axios.get(
            url + "?" + new URLSearchParams(params),
            {
                headers: {
                    "API-KEY": api_key,
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            }
        );
        return response.data;
    }
);

//更新
export const UpdateNotice = createAsyncThunk(
    "AdminNotice/UpdateNotice",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.admin.admin_token;
        const NoticeState = thunkAPI.getState().AdminNotice;
        const SetErrer = (message) => {
            dispatch(set_Notice(["error_message", message]));
        };
        const release_end_date = NoticeState.indefinitely
            ? "9999-12-31"
            : NoticeState.release_end_date;
        const params = {
            top_release_target: NoticeState.top_release_target,
            notification_id: NoticeState.notification_id,
            updated_at: NoticeState.updated_at,
            control_kubun: NoticeState.control_kubun,
            notification_title: NoticeState.notification_title,
            notification_detail: NoticeState.notification_detail,
            notification_category: NoticeState.notification_category,
            image_path: NoticeState.image_path,
            image: NoticeState.image,
            button_url: NoticeState.button_url,
            release_start_date: NoticeState.release_start_date,
            release_end_date: release_end_date,
            indefinitely: NoticeState.indefinitely,
            delete_flag: NoticeState.delete_flag,
        };
        HttpFunction("PUT", "/api/admin_notice", params, api_key)
            .then((data) => {
                for (let [key, value] of Object.entries(data)) {
                    dispatch(set_Notice([key, value]));
                }

                if (data.error_message) {
                    SetErrer([
                        "エラー",
                        data.validation_error
                            ? CreateValidationErrorMessage(
                                  data.validation_error
                              )
                            : data.error_message,
                    ]);
                } else {
                    SetErrer(["メッセージ", "通知を更新しました", "info"]);
                }

                dispatch(
                    GetNotice({ notification_id: NoticeState.notification_id })
                );
            })
            .catch((err) => {
                // console.log("err");
                // console.log(err);
                SetErrer(["サーバーエラー", "通知の更新に失敗しました"]);
            });
    }
);
//新規登録
export const CreateNotice = createAsyncThunk(
    "AdminNotice/CreateNotice",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.admin.admin_token;
        const NoticeState = thunkAPI.getState().AdminNotice;
        const SetErrer = (message) => {
            dispatch(set_Notice(["error_message", message]));
        };
        const release_end_date = NoticeState.indefinitely
            ? "9999-12-31"
            : NoticeState.release_end_date;
        const params = {
            top_release_target: NoticeState.top_release_target,
            control_kubun: NoticeState.control_kubun,
            notification_title: NoticeState.notification_title,
            notification_detail: NoticeState.notification_detail,
            notification_category: NoticeState.notification_category,
            image_path: NoticeState.image_path,
            image: NoticeState.image,
            button_url: NoticeState.button_url,
            release_start_date: NoticeState.release_start_date,
            release_end_date: release_end_date,
            indefinitely: NoticeState.indefinitely,
        };
        HttpFunction("POST", "/api/admin_notice", params, api_key)
            .then((data) => {
                if (data.error_message) {
                    SetErrer([
                        "エラー",
                        data.validation_error
                            ? CreateValidationErrorMessage(
                                  data.validation_error
                              )
                            : data.error_message,
                    ]);
                    return;
                } else {
                    SetErrer(["メッセージ", "通知を更新しました", "info"]);
                }

                for (let [key, value] of Object.entries(data)) {
                    // // console.log("key:" + key + " value:" + value);
                    dispatch(set_Notice([key, value]));
                }
                dispatch(set_Notice(["new_or_fix", "fix"]));
            })
            .catch((err) => {
                // console.log("err");
                // console.log(err);
                SetErrer(["サーバーエラー", "通知の登録に失敗しました"]);
            });
    }
);

const slice = createSlice({
    name: "AdminNotice",
    initialState,
    reducers: {
        set_Initial: (state, action) => {
            state = initialState;
            return state;
        },
        set_Notice: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];

            if (type) {
                state[name] = value;
                let new_type = state[type];
                new_type[name] = value;
                state[type] = new_type;
            } else {
                state[name] = value;
            }
            return state;
        },
    },
    extraReducers: {
        [GetNotice.pending]: (state, action) => {},

        [GetNotice.fulfilled]: (state, action) => {
            const data = action.payload;

            for (let [key, value] of Object.entries(data.data)) {
                if (value.notification_id == state.notification_id) {
                    for (let [ikey, ivalue] of Object.entries(value)) {
                        if (
                            ikey == "release_start_date" ||
                            ikey == "release_end_date"
                        ) {
                            state[ikey] = ivalue.slice(0, 10);
                        } else {
                            state[ikey] = ivalue;
                        }
                    }
                }
            }

            return state;
        },
        [GetNotice.rejected]: (state, action) => {
            state.error_message = [
                "ネットワークエラー",
                "通知データの取得に失敗しました",
            ];
        },
        [UpdateNotice.rejected]: (state, action) => {
            state.error_message = [
                "ネットワークエラー",
                "通知データの更新に失敗しました",
            ];
        },
        [CreateNotice.rejected]: (state, action) => {
            state.error_message = [
                "ネットワークエラー",
                "通知データの新規作成に失敗しました",
            ];
        },
    },
});

export default slice.reducer;
export const { set_Notice, set_Initial } = slice.actions;
