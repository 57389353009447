import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import TextBoxWithCopyButton from "../utils/TextBoxWithCopyButton";

import {
    set_value,
    Setkurukuru,
    GetPoint,
} from "../../stores/GeneralFriendInvitation";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const GeneralFriendInvitation = () => {
    const thisState = useSelector((state) => state.GeneralFriendInvitation);
    const dispatch = useDispatch();
    const InformationState = useSelector((state) => state.Information);

    // inputはuseridではなく本来は友達紹介コード
    const input = InformationState.user.invitation_cd;
    const onChange = () => {
        // console.log("onChange");
    };
    const openTip = thisState.openTip;
    const openTipMessage = thisState.openTipMessage;

    useEffect(() => {
        dispatch(Setkurukuru());
        dispatch(GetPoint())
            .then((res) => {
                dispatch(
                    set_value([
                        "friend_introduction_point",
                        res.payload.data.friend_introduction_point,
                    ])
                );
            })
            .catch((err) => {
                // console.log(err);
            });
    }, []);

    const handleClickButton = () => {
        dispatch(set_value(["openTip", true]));
        // console.log("handleClickButton");
    };
    const handleCloseTip = () => {
        // console.log("handleCloseTip");
        dispatch(set_value(["openTip", false]));
    };

    const handleClickButtonMessage = () => {
        dispatch(set_value(["openTipMessage", true]));
    };
    const handleCloseTipMessage = () => {
        dispatch(set_value(["openTipMessage", false]));
    };

    const share = () => {
        if (typeof flutter === "undefined") {
            alert("フィフティーカードアプリをご利用ください。");
        } else {
            // const shareMessage =
            //     "こんにちは。紹介コードを入力して【フィフティーカードアプリ】に会員登録すると､パカッティーがもらえます。\n（iPhoneの方はこちらからインストール:https://apps.apple.com/us/app/fifty-card/id1597208578）\n（Andoridの方はこちらからインストール：https://play.google.com/store/apps/details?id=net.fifty_card.app）\n紹介コード:『" +
            //     input +
            //     "』";
            const shareMessage = message;
            window.flutter_inappwebview.callHandler("Share", shareMessage);
        }
    };
    const message =
        "⭐フィフティーカードアプリ⭐\n会員登録で紹介コード【" +
        input +
        "】を入力すると\n今だけ" +
        thisState.friend_introduction_point +
        "パカッティーをプレゼントだよ😆\nパカッティーが貯まるといいことあるよ❤\n" +
        "iPhoneの方はこちらからインストール:https://apps.apple.com/us/app/fifty-card/id1597208578\nAndoridの方はこちらからインストール：https://play.google.com/store/apps/details?id=net.fifty_card.app";
    return (
        <React.Fragment>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item>
                    <h1>お友達招待</h1>
                </Grid>
                <Grid item>
                    友達を招待すると
                    <br /> 紹介者にもお友達にも <br />{" "}
                    {thisState.friend_introduction_point}
                    パカッティープレゼント！
                </Grid>
                <Grid style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <TextBoxWithCopyButton
                        input={input}
                        onChange={onChange}
                        openTip={openTip}
                        handleClickButton={handleClickButton}
                        handleCloseTip={handleCloseTip}
                    />
                </Grid>
                <Grid>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => share()}
                    >
                        招待する
                    </Button>
                </Grid>
                <Grid item style={{ marginTop: "2rem" }}>
                    ※招待文のコピーが
                    <br />
                    難しい場合はこちらを押してコピー↓
                </Grid>
                <Grid style={{ marginBottom: "1rem" }}>
                    <TextBoxWithCopyButton
                        input={message}
                        onChange={onChange}
                        openTip={openTipMessage}
                        handleClickButton={handleClickButtonMessage}
                        handleCloseTip={handleCloseTipMessage}
                    />
                </Grid>

                <img src="/images/Common/IMG_2178.jpg" width="80%" />
            </Grid>
        </React.Fragment>
    );
};

export default GeneralFriendInvitation;
