import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Stateの初期状態
const initialState = {
    error_message: "",
    before: {},
    slide_data: [
        {
            home_id: "",
            image_path: "image1",
            image_comment: "",
            active_flag: true,
        },
        {
            home_id: "",
            image_path: "image1",
            image_comment: "",
            active_flag: true,
        },
        {
            home_id: "",
            image_path: "image1",
            image_comment: "",
            active_flag: true,
        },
        {
            home_id: "",
            image_path: "image1",
            image_comment: "",
            active_flag: true,
        },
        {
            home_id: "",
            image_path: "image1",
            image_comment: "",
            active_flag: true,
        },
    ],
};

export const GetSlideData = createAsyncThunk(
    "AdminHomeSetting/GetSlideData",
    async (arg, thunkAPI) => {
        const informationState = thunkAPI.getState().Information;
        const dispatch = thunkAPI.dispatch;
        const api_key = informationState.admin.admin_token;

        const params = "";
        const url = "/api/home_slide_data";

        const response = await axios.get(
            url + "?" + new URLSearchParams(params),
            {
                headers: {
                    "API-KEY": api_key,
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            }
        );

        return response.data;
    }
);

export const HomeSlideSetting = createAsyncThunk(
    "AdminHomeSetting/HomeSlideSetting",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/home_slide_setting";
        const params = {
            ...arg, //必須
        };

        try {
            const response = await axios.put(url, params, {
                headers: {
                    "API-KEY": api_key,
                    "content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "AdminHomeSetting",
    initialState,
    reducers: {
        initialize: () => initialState,
        set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];

            if (type) {
                state[type][name] = value;
            } else {
                state[name] = value;
            }
        },
    },
    extraReducers: {
        [GetSlideData.pending]: (state, action) => {},
        [GetSlideData.fulfilled]: (state, action) => {
            if (action.payload.error_message) {
                state.error_message = [
                    "サーバーエラー",
                    action.payload.error_message,
                ];

                return;
            }
            for (let [key, value] of Object.entries(action.payload)) {
                state[key] = value;
                state.before[key] = value;
            }
        },
        [GetSlideData.rejected]: (state, action) => {
            state.error_message = [
                "サーバーエラー",
                "設定情報の取得に失敗しました",
            ];
        },
        [HomeSlideSetting.fulfilled]: (state, action) => {
            if (action.payload.result == 1) {
                alert("設定しました。");
                return;
            }
            if (action.payload.error_message) {
                state.error_message = [
                    "サーバーエラー",
                    action.payload.error_message,
                ];

                return;
            }
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_value, initialize } = slice.actions;
