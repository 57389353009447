import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
// Stateの初期状態
const initialState = {
    talent_list: [
        // {
        //     talent_id: "",
        //     name: "",
        //     image: "",
        //     new_flag: false,
        //     excellent_flag: false,
        // },
    ],
    talent_serch: {
        page: 0,
        page_count: 0,
        total: 0,
    },
    talent_detail: {
        talent_name: "",
        age: "",
        height: "",
        weight: "",
        sns_link: "",
        talent_pr: "",
        prefectures: "未選択",
        image_big: "",
        image: "",
        input_option: [],
        category: [],
    },
};
const debug = true;

const debugLog = (arg) => {
    if (!debug) return;
};
export const GetTalentList = createAsyncThunk(
    "TALENT/GetTalentList",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/talent_list";
        const params = arg;

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const GetDetail = createAsyncThunk(
    "TALENT/GetDetail",
    async (arg, thunkAPI) => {
        let url = "/api/talent_detail";
        const api_key = thunkAPI.getState().Information.admin.admin_token;

        const params = {
            talent_id: arg, //必須
        };

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const TalentCreate = createAsyncThunk(
    "TALENT/TalentCreate",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        let url = "/api/talent_create";

        const params = {
            ...arg, //必須
        };
        try {
            const response = await axios.post(url, params, {
                headers: {
                    "API-KEY": api_key,
                    "content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const TalentFix = createAsyncThunk(
    "TALENT/TalentFix",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        let url = "/api/talent_fix";

        const params = {
            ...arg, //必須
        };
        try {
            const response = await axios.put(url, params, {
                headers: {
                    "API-KEY": api_key,
                    "content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const TalentActive = createAsyncThunk(
    "TALENT/TalentActive",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        let url = "/api/talent_active";

        const params = {
            ...arg, //必須
        };

        try {
            const response = await axios.put(url, params, {
                headers: {
                    "API-KEY": api_key,
                    "content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);
export const TalentDelete = createAsyncThunk(
    "TALENT/TalentDelete",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        let url = "/api/talent_delete";

        const params = {
            ...arg, //必須
        };

        try {
            const response = await axios.delete(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);
function addItem(array, item) {
    return Array.from(new Set([...array, item])); //重複データが入らないようにするための対応
}

// Sliceを生成する
const slice = createSlice({
    name: "TalentDetail",
    initialState,
    reducers: {
        initialize: (state, action) => {
            if (action.payload) {
                state[action.payload] = initialState[action.payload];
            } else {
                return initialState;
            }
        },
        talent_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        [GetTalentList.fulfilled]: (state, action) => {
            const resdata = action.payload;
            state.talent_serch.page_count = resdata?.serch_info?.page_count;
            state.talent_serch.page = resdata?.serch_info?.page;
            state.talent_serch.total = resdata?.serch_info?.total;
            state.talent_list = resdata.talent_list;
        },
        [GetDetail.fulfilled]: (state, action) => {
            const resdata = action.payload;
            if (action.payload.result == "1") {
                state.talent_detail = resdata?.talent_info;
                state.talent_detail.input_option = resdata?.input_option;
                state.talent_detail.category = resdata?.category;
            }
        },
        [TalentCreate.pending]: (state, action) => {
            // state.talent_detail = action.meta.arg;
        },
        [TalentCreate.fulfilled]: (state, action) => {},
        [TalentCreate.rejected]: (state, action) => {},
        [TalentFix.fulfilled]: (state, action) => {},
        [TalentFix.rejected]: (state, action) => {},
        [TalentDelete.fulfilled]: (state, action) => {},
        [TalentActive.fulfilled]: (state, action) => {},
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { initialize, talent_set } = slice.actions;
