import React, { useEffect, useMemo } from "react";

import { useHistory } from "react-router-dom";
import { set_alert } from "../../stores/ErrorAlert";
import IconButton from "@material-ui/core/IconButton";

import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button, TextField, Grid } from "@material-ui/core";
import ShopApplicationPreview from "../modules/ShopApplicationPreview";
import { GetShopData } from "../../stores/ShopInformation";
import {
    release_info_set,
    AdminGetShopInformationApplication,
    RemandShopInformationApplication,
    ApprovalShopInformationApplication,
    setInitializeState,
} from "../../stores/ShopStoreApplication";

import Dialog from "@material-ui/core/Dialog";



import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { ApprovalFlag, PublicStatusFlag } from "../utils/FlagToViewName";
import ModalProgress from "../utils/ModalProgress";
import TextHorizon from "../utils/TextHorizon";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function AdminStoreApplication() {
    const thisState = useSelector((state) => state.AdminStoreApplication);
    const releaseInfoState = useSelector((state) => state.ShopStoreApplication);
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const path = history.location.pathname.split("/");
    const shop_id = path[2];
    const apply_id = path[3];

    useEffect(() => {
        dispatch(set_alert(releaseInfoState.error_message));
    }, [releaseInfoState.error_message]);

    useEffect(() => {
        // const shop_id = "S0006430";
        dispatch(setInitializeState());

        dispatch(release_info_set(["shop_id", shop_id]));
        dispatch(release_info_set(["apply_id", apply_id]));

        if (apply_id == "undefined" || apply_id == "null") {
            // console.log("申請が存在しない");
        } else {
            // console.log("申請が存在  :" + apply_id);
        }

        dispatch(AdminGetShopInformationApplication());
    }, []);

    const LinkFix = () => {
        // console.log("編集");
        history.push("/AdminStoreApplicationFix");
    };
    const LinkNew = () => {
        // console.log("新規");

        history.push("/AdminStoreApplicationNew/" + shop_id);
    };
    const Remand = () => {
        // console.log("差戻");
        handleClose();
        dispatch(RemandShopInformationApplication());
    };
    const Approval = () => {
        // console.log("承認");
        dispatch(ApprovalShopInformationApplication())
        .then(()=>
        {
            //画面再表示
            dispatch(setInitializeState());
            dispatch(release_info_set(["shop_id", shop_id]));
            dispatch(release_info_set(["apply_id", "undefined"]));
            dispatch(AdminGetShopInformationApplication());
            history.push(`/AdminStoreApplication/${shop_id}/undefined`);
        });       
    };
    const Back = () => {
        history.push("/AdminShopList");
    };

    const handleOpen = () => {
        dispatch(release_info_set(["remand_dialog", true]));
    };

    const handleClose = () => {
        dispatch(release_info_set(["remand_dialog", false]));
    };

    const RemandDialog = () => {
        return (
            <Dialog
                onClose={handleClose}
                aria-labelledby="review"
                open={releaseInfoState.remand_dialog}
            >
                <React.Fragment>
                    <Grid
                        style={{ padding: "1rem" }}
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        否認理由
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Grid container item xs={9}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    defaultValue={
                                        releaseInfoState.denial_reason
                                    }
                                    inputProps={{ maxLength: 255 }}
                                    onBlur={(e) =>
                                        dispatch(
                                            release_info_set([
                                                "denial_reason",
                                                e.target.value,
                                            ])
                                        )
                                    }
                                />
                            </Grid>
                            <Grid
                                container
                                item
                                xs={3}
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Button
                                    component="label"
                                    variant="contained"
                                    color="secondary"
                                    onClick={Remand}
                                >
                                    否認
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </Dialog>
        );
    };

    return (
        <React.Fragment>
            <IconButton onClick={Back} style={{ padding: "8px 0 24px" }}>
                <ChevronLeftIcon />
                <span style={{ fontSize: "70%" }}>戻る</span>
            </IconButton>

            <RemandDialog />
            <ModalProgress open={releaseInfoState.modal_progress} />
            <div
                style={{ margin: "2%", width: "96%" }}
                className={classes.root}
            >
                {apply_id != "undefined" && apply_id != "null" ? (
                    <h1>店舗申請情報確認</h1>
                ) : (
                    <h1>店舗公開情報確認</h1>
                )}

                <TextHorizon
                    name="公開ステータス"
                    value={PublicStatusFlag(releaseInfoState.public_status)}
                />
                <TextHorizon
                    name="申請ステータス"
                    value={ApprovalFlag(releaseInfoState.approval_flag)}
                />
                {releaseInfoState.approval_flag == "2" &&
                releaseInfoState.denial_reason ? (
                    <div
                        style={{
                            border: "solid 2px red",
                            marginLeft: "-1%",
                            paddingLeft: "1%",
                            marginRight: "-1%",
                            paddingRight: "1%",
                        }}
                    >
                        <TextHorizon
                            style={{ border: "solid red" }}
                            name="否認理由"
                            value={releaseInfoState.denial_reason}
                        />
                    </div>
                ) : (
                    ""
                )}
                <TextHorizon name="店舗名" value={releaseInfoState.shop_name} />
                {/* <div
                    style={{
                        margin: "2%",
                        width: "96%",
                    }}
                    className={classes.root}
                >
                    <ShopApplicationPreview
                        ApplicationData={releaseInfoState}
                    />
                </div> */}
                {releaseInfoState.detail_data ? (
                    <div style={{width: "96%",}}>
                        <div
                            style={{
                                margin: "1%",
                                width: "48%",
                                display: "inline-block",
                                verticalAlign: "top",
                            }}
                            className={classes.root}
                        >
                            <p>{"申請前"}</p>
                            <ShopApplicationPreview
                                ApplicationData={releaseInfoState.detail_data}
                            />
                        </div>
                        <div
                            style={{
                                margin: "1%",
                                width: "48%",
                                display: "inline-block",
                                verticalAlign: "top",
                            }}
                            className={classes.root}
                        >
                            <p>{"申請後"}</p>
                            <ShopApplicationPreview
                                ApplicationData={releaseInfoState}
                            />
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            margin: "2%",
                            width: "96%",
                        }}
                        className={classes.root}
                    >
                        <ShopApplicationPreview
                            ApplicationData={releaseInfoState}
                        />
                    </div>
                )}
                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    style={{
                        margin: "2%",
                        width: "96%",
                    }}
                >
                    {apply_id != "undefined" && apply_id != "null" ? (
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={LinkFix}
                                disabled={releaseInfoState.disable_button_fix}
                            >
                                編集
                            </Button>

                            <Button
                                variant="outlined"
                                color="default"
                                onClick={handleOpen}
                                disabled={
                                    releaseInfoState.disable_button_remand
                                }
                            >
                                差戻
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={Approval}
                                disabled={releaseInfoState.disable_button_apply}
                            >
                                承認
                            </Button>
                        </>
                    ) : (
                        <Button
                            variant="outlined"
                            color="default"
                            onClick={LinkNew}
                        >
                            新規作成
                        </Button>
                    )}
                </Grid>
            </div>
        </React.Fragment>
    );
}
