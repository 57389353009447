import React from "react";
import TextField from "@material-ui/core/TextField";

const OneTimePassword = (props) => {
    const {name, onBlur, helperText, ...other} = props;

    return (
        <React.Fragment>
            <TextField
                style={{ width: "100%" }}
                type="tel"
                inputProps={{maxLength: 4}}
                placeholder="4桁のパスワード"
                name={name}
                onBlur={onBlur}
                label="ワンタイムパスワード"
                helperText={helperText}
                style={{marginTop: "20px"}}
                {...other}
            />
        </React.Fragment>
    );
};

OneTimePassword.defaultProps = {
    helperText: "登録されているメールアドレスに送信された4桁のパスワードをご確認ください。"
};

export default OneTimePassword;
