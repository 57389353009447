import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { set_value } from "../../stores/AdminStoreApplication";
import { set_alert } from "../../stores/ErrorAlert";

import { shop_data } from "../../const/shop_data";
import PreviewSwitching from "./PreviewSwitching";
import ShopApplication from "./ShopApplication";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function StoreApplicationEdit(props) {
    const thisState = useSelector((state) => state.AdminStoreApplication);
    const releaseInfoState = props.releaseInfoState;
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(set_alert(releaseInfoState.error_message));
    }, [releaseInfoState.error_message]);

    const TabChange = (e, newValue) => {
        dispatch(set_value(["tab", newValue]));
    };

    return (
        <React.Fragment>
            <PreviewSwitching
                value={thisState.tab ? thisState.tab : 0}
                onChange={TabChange}
            />
            <ShopApplication
                preview={thisState.tab == 1}
                admin={props.admin}
                releaseInfoState={releaseInfoState}
            />

            <PreviewSwitching
                value={thisState.tab ? thisState.tab : 0}
                onChange={TabChange}
            />
        </React.Fragment>
    );
}
