import React from "react";
import { prefectures_list } from "../../const/prefectures_list";
import TextField from "@material-ui/core/TextField";
const SelectPrefectures = (props) => {
    const { ...other } = props;

    return (
        <>
            <TextField
                style={{ width: "100%" }}
                id="select-prefecture"
                select
                name="prefectures"
                label="都道府県"
                fullWidth
                variant="outlined"
                SelectProps={{
                    native: true,
                }}
                {...props}
                InputLabelProps={{ shrink: true }}  
            >
                <option value={""}>未選択</option>
                {prefectures_list.map((area, index) => (
                    <optgroup key={index} label={area.label}>
                        {area.pref.map((prefecture, index_in) => (
                            <option key={index_in} value={prefecture}>
                                {prefecture}
                            </option>
                        ))}
                    </optgroup>
                ))}
            </TextField>
        </>
    );
};

export default SelectPrefectures;
