import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/functions/AsyncFunction";

const initialState = {
    news_list: [],
    currentPage: 1,
    total: 0,
};

export const GetNewsList = (arg) => {
    return AsyncFunction({
        type_name: "NewsSetting/ニュース一覧取得",
        method: "get",
        url: "/api/GetNewsList",
        params: arg,
        api_key: "admin",
    });
};
export const PostNews = (arg) => {
    return AsyncFunction({
        type_name: "NewsSetting/ニュース投稿",
        method: "post",
        url: "/api/PostNews",
        params: arg,
        api_key: "admin",
    });
};
export const DeleteNews = (arg) => {
    return AsyncFunction({
        type_name: "NewsSetting/ニュース削除",
        method: "delete",
        url: "/api/DeleteNews",
        params: arg,
        api_key: "admin",
    });
};

const slice = createSlice({
    name: "NewsSetting",
    initialState,
    reducers: {
        NewsSettingSet: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) =>
                action.type.match("NewsSetting") &&
                action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;
                if (payload?.news_list) {
                    state.news_list = payload?.news_list;
                }
                if (payload?.currentPage) {
                    state.currentPage = payload?.currentPage;
                }
                if (payload?.total) {
                    state.total = payload?.total;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { NewsSettingSet } = slice.actions;
