import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import OneTimePassword from "../pages/OneTimePassword";
import Policy from "../pages/Policy";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { set_entry_value, initialize } from "../../stores/Entry";
import {
    set_value,
    ShopUserInfoGet,
    UserInfoGet,
} from "../../stores/Information";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import HttpFunction from "../utils/HttpFunction";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Error500Handling from "../utils/Error500Handling";
import { useHistory } from "react-router";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import IconButton from "@material-ui/core/IconButton";
import { TextFieldValidation } from "../utils/TextFields";
import { makeStyles } from "@material-ui/core";
import {
    ButtonContainedPrimary,
    ButtonOutlinedPrimary,
    ButtonTextPrimary,
} from "../utils/Buttons";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import SetUserTokenFlutter from "../utils/SetUserTokenFlultter";
import TokenErrorDialog from "../utils/TokenErrorDialog";
import { shoplist_set } from "../../stores/Shoplist";

const useStyles = makeStyles((theme) => ({
    button: {
        display: "block",
        margin: "auto",
    },
    stop_dialog: {
        "& .MuiDialog-paper": {
            padding: "15px",
        },
    },
}));

export const StopButton = (props) => {
    const { disabled } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleStop = () => {
        dispatch(set_value(["showDrawer", true]));
        dispatch(set_value(["showBottomNavigation", true]));
        dispatch(initialize());
        history.goBack();
    };

    return (
        <>
            <IconButton
                disabled={disabled}
                onClick={handleOpen}
                style={{ padding: "8px 0 24px" }}
            >
                <NavigateBeforeIcon />
                <span style={{ fontSize: "70%" }}>戻る</span>
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                className={classes.stop_dialog}
            >
                <p>会員登録を中止します。よろしいですか？</p>
                <Grid container style={{ textAlign: "center" }}>
                    <Grid item xs={6}>
                        <ButtonContainedPrimary onClick={handleClose}>
                            キャンセル
                        </ButtonContainedPrimary>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonOutlinedPrimary onClick={handleStop}>
                            OK
                        </ButtonOutlinedPrimary>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
};

export const MailInput = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.Entry);
    const [email, setEmail] = React.useState("");
    const [disable, setDisable] = React.useState(false);
    const [open500, setOpen500] = React.useState(false);

    const SendEmail = () => {
        setDisable(true);
        let url;
        if (thisState.entry_mode === "user") {
            url = "/api/send_onetime_mail";
        } else if (thisState.entry_mode === "shop") {
            url = "/api/send_onetime_mail_shop";
        }
        HttpFunction("POST", url, { email_address: email }, "")
            .then((data) => {
                dispatch(
                    set_entry_value(["error_message", data.error_message])
                );
                if (data.result === "OK") {
                    dispatch(
                        set_entry_value([
                            "email_flag",
                            false,
                            thisState.entry_mode,
                        ])
                    );
                    dispatch(
                        set_entry_value([
                            "onetime_flag",
                            true,
                            thisState.entry_mode,
                        ])
                    );
                    dispatch(set_entry_value(["email", data.email_address]));
                } else if (data.result === "NG") {
                    setDisable(false);
                } else if (data === 500) {
                    setOpen500(true);
                }
            })
            .catch((err) => {
                setOpen500(true);
            });
    };

    const handleClose500 = () => {
        setOpen500(false);
        setDisable(false);
    };

    return (
        <React.Fragment>
            {thisState.error_message && (
                <Alert severity="error">
                    <AlertTitle>エラー</AlertTitle>
                    {thisState.error_message}
                </Alert>
            )}
            <p>
                メールアドレスの認証を行います。ワンタイムパスワードを送信いたしますので、受信可能なメールアドレスを入力し、送信ボタンを押してください。
            </p>
            <p>
                ※すでにアカウントをお持ちの場合（加盟店アカウントは除く）は、登録済みのものとは別のメールアドレスをご使用ください。
            </p>
            <TextFieldValidation
                id="email"
                name="email"
                label="メールアドレス"
                type="email"
                placeholder="name@example.com"
                onBlur={(e) => setEmail(e.target.value)}
                inputProps={{ maxLength: 255 }}
                autoFocus
            />
            <div className="text-right">
                <ButtonContainedPrimary onClick={SendEmail} disabled={disable}>
                    送信
                </ButtonContainedPrimary>
            </div>
            <Error500Handling open={open500} onClose={handleClose500} />
        </React.Fragment>
    );
};

export const OneTimeInput = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.Entry);
    const [onetime, setOnetime] = React.useState("");
    const [disable, setDisable] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [open500, setOpen500] = React.useState(false);
    const [sendEmailCount, setCounter] = React.useState(0);
    const [reSendEmailDisabled, setReSendEmailDisabled] = React.useState(false);

    let send_onetime_url, send_mail_url;
    if (thisState.entry_mode === "user") {
        send_onetime_url = "/api/user_onetime_check_entry";
        send_mail_url = "/api/send_onetime_mail";
    } else if (thisState.entry_mode === "shop") {
        send_onetime_url = "/api/user_onetime_check_entry_shop";
        send_mail_url = "/api/send_onetime_mail_shop";
    }

    const handleBlur = (e) => {
        setOnetime(e.target.value);
    };

    const SendOneTime = () => {
        setDisable(true);

        const params = { email_address: thisState.email, one_time: onetime };

        HttpFunction("POST", send_onetime_url, params, "")
            .then((data) => {
                if (data.result === "OK") {
                    dispatch(
                        set_entry_value([
                            "onetime_flag",
                            false,
                            thisState.entry_mode,
                        ])
                    );
                    dispatch(
                        set_entry_value([
                            "policy_flag",
                            true,
                            thisState.entry_mode,
                        ])
                    );
                } else if (data.result === "NG") {
                    setDisable(false);
                    dispatch(
                        set_entry_value(["login_failed", data.login_failed])
                    );
                    setError(true);
                } else if (data === 500) {
                    setOpen500(true);
                }
            })
            .catch((err) => {
                setOpen500(true);
            });
    };

    const SendEmail = () => {
        setError(false);
        if (sendEmailCount < 3) {
            HttpFunction(
                "POST",
                send_mail_url,
                { email_address: thisState.email },
                ""
            )
                .then((data) => {
                    if (data === 500) {
                        setOpen500(true);
                    }
                })
                .catch((err) => {
                    setOpen500(true);
                });
            setCounter(sendEmailCount + 1);
        }
    };

    const handleClose500 = () => {
        setOpen500(false);
        setDisable(false);
    };

    useEffect(() => {
        if (sendEmailCount === 0) {
        } else if (sendEmailCount < 3) {
            setReSendEmailDisabled(true);
            setTimeout(function () {
                setReSendEmailDisabled(false);
            }, 30000);
        } else {
            setReSendEmailDisabled(true);
        }
    }, [sendEmailCount]);

    return (
        <React.Fragment>
            {error && (
                <Alert severity="error">
                    <AlertTitle>エラー</AlertTitle>
                    {1 <= thisState.login_failed &&
                    thisState.login_failed <= 4 ? (
                        <>
                            正しいワンタイムパスワードを入力してください。（5回失敗するとワンタイムパスワードが無効化されます。）
                            <br />
                            {`認証失敗回数：${thisState.login_failed}回`}
                        </>
                    ) : (
                        "ワンタイムパスワードが無効化されています。新しいワンタイムパスワードを発行するには、「メールの再送信」をクリックしてください。"
                    )}
                </Alert>
            )}
            {3 <= sendEmailCount && (
                <Alert severity="info">
                    <AlertTitle>メッセージ</AlertTitle>
                    再送上限に達しました。これ以上の再送信はできません。
                    <br />
                    再度ワンタイムパスワードを発行する場合はお手数ですが最初からやり直してください。
                </Alert>
            )}
            <OneTimePassword
                helperText="ご入力いただいたメールアドレスに送信された、4桁のワンタイムパスワードをご確認ください。"
                onBlur={handleBlur}
                autoFocus
            />
            <ButtonContainedPrimary
                disabled={disable}
                onClick={SendOneTime}
                style={{ display: "block", marginLeft: "auto" }}
            >
                送信
            </ButtonContainedPrimary>
            <ButtonTextPrimary
                onClick={SendEmail}
                style={{
                    display: "block",
                    marginLeft: "auto",
                    textTransform: "none",
                }}
                disabled={reSendEmailDisabled}
            >
                {`「${thisState.email}」へ再送信`}
            </ButtonTextPrimary>
            <Error500Handling open={open500} onClose={handleClose500} />
        </React.Fragment>
    );
};

export const PolicyAgreement = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.Entry);
    const [isDisabled, setIsDisabled] = React.useState(true);

    const handleChangeChecked = (event) => {
        setIsDisabled(!event.target.checked);
    };
    const handleClick = () => {
        dispatch(set_entry_value(["policy_flag", false, thisState.entry_mode]));
        dispatch(
            set_entry_value(["subscription_flag", true, thisState.entry_mode])
        );

        if (thisState.entry_mode == "shop") {
            dispatch(set_entry_value(["input_flag", true, "shop"]));
        }
    };

    return (
        <>
            <p>
                以下の利用規約をお読みください。同意される場合は、チェックボックスにチェックを入れて「次へ」ボタンを押してください。
            </p>
            <Policy />
            <FormControlLabel
                control={
                    <Checkbox
                        name="agree_policy"
                        onChange={handleChangeChecked}
                    />
                }
                label="規約に同意する"
                style={{ margin: "10px" }}
            />
            <Button
                variant="contained"
                disabled={isDisabled}
                style={{ display: "block", margin: "auto" }}
                onClick={handleClick}
            >
                次へ
            </Button>
        </>
    );
};

export const EntryComplete = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.Entry);
    const [open500, setOpen500] = React.useState(false);
    const [openFailedLogin, setOpenFailedLogin] = React.useState(false);

    const loginUser = () => {
        // Http通信で課金状況の確認と必要情報を取得する
        if (thisState.user.token) {
            const api_key = thisState.user.token;
            const type = "user";

            HttpFunction("POST", "/api/login_pay_check", "", api_key)
                .then((data) => {
                    if (data.token && !data.error_message) {
                        dispatch(set_value(["token", data.token, "user"]));
                        dispatch(
                            set_value([
                                "invitation_cd",
                                data.invitation_cd,
                                "user",
                            ])
                        );
                        dispatch(
                            set_value(["prefectures", data.prefectures, "user"])
                        );
                        dispatch(
                            shoplist_set(["serch_area", data.prefectures])
                        );
                        SetUserTokenFlutter(data.token, type);
                        dispatch(set_value(["user_login", true, "user"]));
                    } else if (data.error_message) {
                        dispatch(
                            set_entry_value([
                                "error_message",
                                data.error_message,
                            ])
                        );
                    } else {
                        setOpenFailedLogin(true);
                    }
                })
                .catch((err) => {
                    dispatch(
                        set_entry_value([
                            "error_message",
                            "サーバーとの接続に失敗しました。通信状況をお確かめの上再度お試しください。",
                        ])
                    );
                });
        } else {
            setOpenFailedLogin(true);
        }
    };

    const loginShop = () => {
        const api_key = thisState.shop.token;
        if (api_key !== null) {
            const type = "shop";
            dispatch(ShopUserInfoGet(api_key))
                .then((data) => {
                    if (data.payload.data.error_message) {
                        dispatch(
                            set_entry_value([
                                "error_message",
                                "サーバーとの接続に失敗しました。通信状況をお確かめの上、再度お試しください。",
                            ])
                        );
                    } else {
                        SetUserTokenFlutter(api_key, type);
                        dispatch(set_value(["showDrawer", true]));
                        dispatch(set_value(["showBottomNavigation", true]));
                        dispatch(set_value(["shop_login", true, "shop"]));
                        // history.push("/ShopStoreApplication");
                    }
                })
                .catch((e) => {
                    dispatch(
                        set_entry_value([
                            "error_message",
                            "サーバーとの接続に失敗しました。通信状況をお確かめの上再度お試しください。",
                        ])
                    );
                });
        } else {
            setOpenFailedLogin(true);
        }
    };

    const handleLogin = () => {
        if (thisState.entry_mode === "user") {
            loginUser();
        } else if (thisState.entry_mode === "shop") {
            loginShop();
        }
    };

    const handleClose500 = () => {
        setOpen500(false);
    };

    return (
        <React.Fragment>
            {thisState.error_message && (
                <Alert severity="error">
                    <AlertTitle>エラー</AlertTitle>
                    {thisState.error_message}
                    <p>
                        複数回発生する場合はお手数ですが最初からやり直してください。
                    </p>
                </Alert>
            )}
            <Typography style={{ textAlign: "center", margin: "20px 0" }}>
                会員登録が完了しました！
            </Typography>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <ButtonContainedPrimary onClick={handleLogin}>
                    ログイン
                </ButtonContainedPrimary>
            </Grid>
            <Error500Handling open={open500} onClick={handleClose500} />
            <TokenErrorDialog open={openFailedLogin} />
        </React.Fragment>
    );
};
