import React, { useEffect } from "react";
import { InputMaxLength } from "~CJS/atoms/TextFields";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      root: {
        "&.Mui-disabled": {
          color: "black",
          // opacity: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&.Mui-disabled": {
          // color: "black",
          // opacity: 1,
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        "&$shrink": {
          color: "black",
        },
      },
    },
  },
});

const AuditionSheetInput = (props) => {
  const { disabled, data, ...other } = props;

  const talent_id = data?.talent_id ? data.talent_id : "";
  const pr_message = data?.pr_message ? data.pr_message : "";

  useEffect(() => {
    if (data?.prefectures) {
      set_prefectures(data.prefectures);
    }
  }, [data?.prefectures]);

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <InputMaxLength
          label="タレントID"
          name="talent_id"
          inputProps={{ maxLength: 32 }}
          disabled={disabled}
          value={talent_id}
          placeholder="T_00000000"
        />

        <InputMaxLength
          label="PRメッセージ"
          name="pr_message"
          inputProps={{ maxLength: 256 }}
          disabled={disabled}
          multiline
          value={pr_message}
        />
      </ThemeProvider>
    </React.Fragment>
  );
};

export default AuditionSheetInput;
