import React, { useEffect } from "react";
import { set_entry_value, initialize } from "../../stores/Entry";
import { set_value } from "../../stores/Information";
import { useDispatch } from "react-redux";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {
    StopButton,
    MailInput,
    OneTimeInput,
    PolicyAgreement,
    EntryComplete,
} from "../modules/Entry";
import Divider from "@material-ui/core/Divider";
import { useSelector } from "react-redux";
import HttpFunction from "../utils/HttpFunction";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Grid from "@material-ui/core/Grid";
import Error500Handling from "../utils/Error500Handling";
import {
    TextFieldNormal,
    TextFieldReadOnly,
    TextFieldValidation,
    errorJudge,
} from "../utils/TextFields";
import Button from "@material-ui/core/Button";
import { ButtonContainedPrimary } from "../utils/Buttons";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

const EntryShopStepper = () => {
    const thisState = useSelector((state) => state.Entry);
    const dispatch = useDispatch();

    useEffect(() => {
        if (thisState.shop.email_flag || thisState.shop.onetime_flag) {
            dispatch(set_entry_value(["activeStep", 0, "shop"]));
        }

        if (thisState.shop.policy_flag) {
            dispatch(set_entry_value(["activeStep", 1, "shop"]));
        }
        if (thisState.shop.input_flag || thisState.shop.check_flag) {
            dispatch(set_entry_value(["activeStep", 2, "shop"]));
        }
        if (thisState.shop.fin_flag) {
            dispatch(set_entry_value(["activeStep", 3, "shop"]));
        }
    });

    return (
        <Stepper activeStep={thisState.shop.activeStep} alternativeLabel>
            <Step key="confirm-email">
                <StepLabel>アドレス認証</StepLabel>
            </Step>
            <Step key="agree-policy">
                <StepLabel>利用規約</StepLabel>
            </Step>
            <Step key="fill-userinfo">
                <StepLabel>会員情報</StepLabel>
            </Step>
            <Step key="completed">
                <StepLabel>完了</StepLabel>
            </Step>
        </Stepper>
    );
};

const EntryShop = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.Entry);

    // 会員情報入力画面
    const InfoInput = () => {
        const [info, setInfo] = React.useState({
            shop_id: "",
            shop_password: "",
            name: "",
            password: "",
            password2: "",
            invitation_code: "",
        });
        const [error, setError] = React.useState({
            shop_id: null,
            shop_password: null,
            name: null,
            password: null,
            password2: null,
        });
        const [showPass, setShowPass] = React.useState(false);

        const handleSetInfo = (e) => {
            setInfo({ ...info, [e.target.id]: e.target.value });
        };
        const handleChangePassword = (compare) => (e) => {
            const diff = e.target.value !== info[compare];
            setError({ ...error, ["password2"]: diff });
        };
        const handleChangeShowPass = (e) => {
            setShowPass(!showPass);
        };
        const handleGoCheck = () => {
            dispatch(set_entry_value(["info", info, "shop"]));
            dispatch(set_entry_value(["error", error, "shop"]));
            dispatch(set_entry_value(["input_flag", false, "shop"]));
            dispatch(set_entry_value(["check_flag", true, "shop"]));
        };
        const trueError = (name) => {
            setError({ ...error, [name]: true });
        };
        const falseError = (name) => {
            setError({ ...error, [name]: false });
        };

        useEffect(() => {
            setInfo(thisState.shop.info);
            setError(thisState.shop.error);
        }, [thisState.shop.input_flag]);

        return (
            <div>
                <TextFieldValidation
                    id="shop_id"
                    label="加盟店ID"
                    defaultValue={thisState.shop.info.shop_id}
                    onBlur={handleSetInfo}
                    trueError={() => trueError("shop_id")}
                    falseError={() => falseError("shop_id")}
                    inputProps={{ maxLength: 8 }}
                />
                <TextFieldValidation
                    id="shop_password"
                    label="加盟店パスワード"
                    defaultValue={thisState.shop.info.shop_password}
                    type={showPass ? "text" : "password"}
                    inputProps={{ maxLength: 255 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleChangeShowPass}
                                >
                                    {showPass ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onBlur={handleSetInfo}
                    trueError={() => trueError("shop_password")}
                    falseError={() => falseError("shop_password")}
                />

                <Divider />

                <TextFieldValidation
                    inputProps={{ maxLength: 255 }}
                    id="name"
                    label="名字（役職）"
                    discription="役職は、「店長」「社員」「バイト」など、自由に記入してください。"
                    defaultValue={thisState.shop.info.name}
                    onBlur={handleSetInfo}
                    trueError={() => trueError("name")}
                    falseError={() => falseError("name")}
                />
                <TextFieldReadOnly
                    id="email"
                    label="メールアドレス"
                    value={thisState.email}
                />
                <TextFieldValidation
                    id="password"
                    label="パスワード"
                    discription="半角英数字7文字以上で設定してください。アルファベットは大文字と小文字を区別します。"
                    type={showPass ? "text" : "password"}
                    defaultValue={thisState.shop.info.password}
                    inputProps={{
                        minLength: 7,
                        pattern: "^[a-zA-Z0-9]*$",
                        maxLength: 255,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleChangeShowPass}
                                >
                                    {showPass ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={handleChangePassword("password2")}
                    onBlur={handleSetInfo}
                    trueError={() => trueError("password")}
                    falseError={() => falseError("password")}
                />
                <TextFieldNormal
                    id="password2"
                    label="パスワード（確認）*"
                    type={showPass ? "text" : "password"}
                    defaultValue={thisState.shop.info.password2}
                    error={error.password2}
                    helperText={
                        error.password2 &&
                        "上で入力したパスワードと一致させてください。"
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleChangeShowPass}
                                >
                                    {showPass ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={handleChangePassword("password")}
                    onBlur={handleSetInfo}
                    inputProps={{
                        minLength: 7,
                        pattern: "^[a-zA-Z0-9]*$",
                        maxLength: 255,
                    }}
                />
                <TextFieldNormal
                    id="invitation_code"
                    label="招待コード"
                    defaultValue={thisState.shop.info.invitation_code}
                    onBlur={handleSetInfo}
                    inputProps={{ maxLength: 255 }}
                />

                <div className="text-right">
                    <ButtonContainedPrimary
                        onClick={handleGoCheck}
                        disabled={errorJudge(error)}
                    >
                        確認へ進む
                    </ButtonContainedPrimary>
                </div>
            </div>
        );
    };

    // 入力情報確認画面
    const InfoCheck = () => {
        const [open500, setOpen500] = React.useState(false);

        const handleBack = () => {
            dispatch(set_entry_value(["check_flag", false, "shop"]));
            dispatch(set_entry_value(["input_flag", true, "shop"]));
            dispatch(set_entry_value(["error_message", ""]));
        };

        const handleRegister = () => {
            const params = {
                shop_id: thisState.shop.info.shop_id,
                shop_password: thisState.shop.info.shop_password,
                nickname: thisState.shop.info.name,
                email_address: thisState.email,
                password: thisState.shop.info.password,
                invitation_cd: thisState.shop.info.invitation_code,
            };
            HttpFunction("POST", "/api/create_user_shop", params, "")
                .then((data) => {
                    // console.log("data: " + data);
                    dispatch(
                        set_entry_value(["error_message", data.error_message])
                    );
                    if (data.result === "OK") {
                        dispatch(
                            set_entry_value(["token", data.shop_token, "shop"])
                        );
                        dispatch(
                            set_entry_value(["check_flag", false, "shop"])
                        );
                        dispatch(set_entry_value(["fin_flag", true, "shop"]));
                    } else if (data.result === "NG") {
                    } else if (data === 500) {
                        setOpen500(true);
                    }
                })
                .catch((err) => {
                    // console.log("err: " + err);
                    setOpen500(true);
                });
        };

        const handleClose500 = () => {
            setOpen500(false);
        };

        return (
            <>
                {thisState.error_message && (
                    <Alert severity="error">
                        <AlertTitle>エラー</AlertTitle>
                        {thisState.error_message}
                    </Alert>
                )}
                <TextFieldReadOnly
                    id="shop-id-check"
                    label="加盟店ID"
                    value={thisState.shop.info.shop_id}
                />
                <TextFieldReadOnly
                    id="shop-password-check"
                    label="加盟店パスワード"
                    value={thisState.shop.info.shop_password}
                    type="password"
                />
                <TextFieldReadOnly
                    id="name-check"
                    label="名字（役職）"
                    value={thisState.shop.info.name}
                />
                <TextFieldReadOnly
                    id="email-check"
                    label="メールアドレス"
                    value={thisState.email}
                />
                <TextFieldReadOnly
                    id="password-check"
                    label="パスワード"
                    value={thisState.shop.info.password}
                    type="password"
                />
                <TextFieldReadOnly
                    id="invitation-code-check"
                    label="招待コード"
                    value={thisState.shop.info.invitation_code}
                />
                <Grid container style={{ textAlign: "center" }}>
                    <Grid item xs={6}>
                        <Button variant="contained" onClick={handleBack}>
                            修正する
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonContainedPrimary onClick={handleRegister}>
                            登録する
                        </ButtonContainedPrimary>
                    </Grid>
                </Grid>
                <Error500Handling open={open500} onClose={handleClose500} />
            </>
        );
    };

    useEffect(() => {
        dispatch(initialize());
        dispatch(set_entry_value(["entry_mode", "shop"]));
        dispatch(set_value(["showDrawer", false]));
        dispatch(set_value(["showBottomNavigation", false]));
    }, []);

    return (
        <div className="m-3">
            <StopButton disabled={thisState.shop.fin_flag} />
            <EntryShopStepper />
            {thisState.shop.email_flag ? <MailInput /> : ""}
            {thisState.shop.onetime_flag ? <OneTimeInput /> : ""}
            {thisState.shop.policy_flag ? <PolicyAgreement /> : ""}
            {thisState.shop.input_flag ? <InfoInput /> : ""}
            {thisState.shop.check_flag ? <InfoCheck /> : ""}
            {thisState.shop.fin_flag ? <EntryComplete /> : ""}
        </div>
    );
};

export default EntryShop;
