import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
    FormatTime,
    ParseTime,
    FormatOpeningHours,
} from "~/functions/DateTimeFunctions";
import { DataGrid } from "@material-ui/data-grid";
import {
    GetPointApplicationList,
    DeletePointApplication,
    PutPointApplication,
} from "~stores/AdminPointApplicationList";

const LabelFromType = (type) => {
    switch (type) {
        case "1":
            return "申請中";
        case "9":
            return "発送済";
        default:
            return "不明";
    }
};
const AdminPointApplicationList = () => {
    const dispatch = useDispatch();
    const PAState = useSelector((state) => state.AdminPointApplicationList);
    const [search_status, set_search_status] = useState("1");
    const [page, set_page] = useState(1);

    useEffect(() => {
        // const params = {
        //     search_type: search_type,
        // };
        dispatch(GetPointApplicationList({ status: search_status }));
    }, []);

    const HandleDelete = (row) => {
        const params = {
            page: page.currentPage,
            status: search_status,
            id: row.id,
        };
        dispatch(DeletePointApplication(params));
    };

    const columns = [
        { field: "id", headerName: "ID", width: 120, hide: true },
        {
            field: "product_id",
            headerName: "交換品ID",
        },
        {
            field: "user_id",
            headerName: "ユーザーID",
        },
        {
            field: "product_name",
            headerName: "商品名",
            width: 150,
            flex: true,
            valueGetter: (params) => params.row?.product_data.product_name,
        },
        {
            field: "status",
            headerName: "状況",
            width: 120,

            // valueGetter: (params) => LabelFromType(params.row?.status),
            renderCell: (params) => (
                <TextField
                    select
                    value={params.row?.status}
                    onChange={(e) => {
                        const req = {
                            id: params.row.id,
                            status: search_status,
                            page: page,
                            update_data: {
                                status: e.target.value,
                            },
                        };
                        dispatch(PutPointApplication(req));
                    }}
                >
                    {/* <option value="">すべて</option> */}
                    <option value="1">申請中</option>
                    <option value="9">発送済</option>
                </TextField>
            ),
        },
        {
            field: "created_at",
            headerName: "申請日",
            width: 150,
            valueGetter: (params) =>
                FormatTime(ParseTime(params.row.created_at), "jp_date_time"),
        },
        {
            field: "delete",
            headerName: "削除",
            width: 100,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => HandleDelete(params.row)}
                >
                    削除
                </Button>
            ),
        },
    ];

    const onPageChange = (page) => {
        const params = {
            page: page + 1,
            status: search_status,
        };

        set_page(page + 1);
        dispatch(GetPointApplicationList(params));
    };

    return (
        <React.Fragment>
            <ButtonNavi left_label="Home画面" left_link="/AdminHome" />
            <h1>パカッティー交換申請一覧</h1>

            <Grid style={{ padding: "0rem 2rem" }}>
                <TextField
                    select
                    value={search_status}
                    SelectProps={{ native: true }}
                    onChange={(e) => {
                        set_search_status(e.target.value);
                        const params = {
                            status: e.target.value,
                        };
                        dispatch(GetPointApplicationList(params));
                    }}
                >
                    <option value="">すべて</option>
                    <option value="1">申請中</option>
                    <option value="9">発送済</option>
                </TextField>
            </Grid>
            <Container>
                <div style={{ height: 800, width: "100%" }}>
                    <DataGrid
                        // autoHeight
                        rowHeight={50}
                        rows={PAState?.point_application_list}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        // checkboxSelection
                        disableSelectionOnClick
                        onPageChange={onPageChange}
                        paginationMode="server"
                        page={PAState.currentPage - 1}
                        rowCount={PAState.total}
                    />
                </div>
            </Container>
        </React.Fragment>
    );
};

export default AdminPointApplicationList;
