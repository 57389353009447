import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { user_info_set, GetUserInfo } from "../../stores/UserInfoChange";
import { set_value } from "../../stores/Information";
import { ButtonContainedPrimary } from "../utils/Buttons";
import { useHistory } from "react-router";
import TokenErrorDialog from "../utils/TokenErrorDialog";
import Error500Handling from "../utils/Error500Handling";
import { shoplist_set } from "../../stores/Shoplist";
import Avatar from "@material-ui/core/Avatar";

const MemberInformation = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user_info_state = useSelector((state) => state.UserInfoChange);
    const information_state = useSelector((state) => state.Information);

    const OnFixImportant = (e) => {
        dispatch(
            user_info_set([
                "email_address",
                information_state.user.email_address,
            ])
        );
        dispatch(user_info_set(["fix_type", "email"]));
        dispatch(user_info_set(["situation", e.currentTarget.name]));
        dispatch(set_value(["showDrawer", false]));
        dispatch(set_value(["showBottomNavigation", false]));
        history.push("/ImportantChangeMember");
    };

    const OnFixNormal = () => {
        history.push("/UserInfoChange");
    };

    const handleCloseError = () => {
        dispatch(user_info_set(["open_error_dialog", false]));
    };
    const handleClickOK = () => {
        dispatch(user_info_set(["open_token_error_dialog", false]));
    };

    const InformationItem = (props) => {
        return (
            <Container>
                <div>{props.title}</div>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <div style={{ paddingLeft: "10px", fontSize: "16px" }}>
                        {props.value}
                    </div>
                    {props.fix ? (
                        <Button
                            name={props.name}
                            variant="contained"
                            color="primary"
                            onClick={OnFixImportant}
                        >
                            変更
                        </Button>
                    ) : (
                        ""
                    )}
                </Grid>

                <Divider
                    style={{ marginTop: "0.3em", marginBottom: "0.5rem" }}
                />
            </Container>
        );
    };

    const SexName = (sex_num) => {
        let sex = "";
        switch (sex_num) {
            case "1":
                sex = "男性";
                break;
            case "2":
                sex = "女性";
                break;
            default:
                sex = "その他";
                break;
        }
        return sex;
    };

    const OnWithdrawal = () => {
        // history.push("/Withdrawal");
        // 退会時アンケート
        history.push("/WithdrawalConfirmation");
    };

    useEffect(() => {
        dispatch(GetUserInfo());
    }, []);

    useEffect(() => {
        dispatch(
            shoplist_set(["serch_area", information_state.user.prefectures])
        );
    }, [information_state.user.prefectures]);

    return (
        <>
            <Container fixed>
                {/* <h2>プロフィール</h2> */}
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Avatar style={{ width: "10rem", height: "10rem" }}>
                            {information_state.user.nickname}
                        </Avatar>
                    </Grid>
                    <Grid item xs>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <ButtonContainedPrimary
                                style={{ marginRight: "24px" }}
                                onClick={OnFixNormal}
                            >
                                編集
                            </ButtonContainedPrimary>
                        </Grid>
                        <InformationItem
                            title="ID"
                            name="user_id"
                            value={information_state.user.user_id}
                        />
                        <InformationItem
                            title="ニックネーム"
                            name="nickname"
                            value={information_state.user.nickname}
                        />
                        <InformationItem
                            title="性別"
                            value={SexName(information_state.user.sex)}
                            name="prefectures"
                        />
                        <InformationItem
                            title="生年月"
                            value={information_state.raw_year_month}
                            name="raw_year_month"
                        />
                        <InformationItem
                            title="都道府県"
                            value={information_state.user.prefectures}
                            name="prefectures"
                        />
                    </Grid>
                </Grid>
                <InformationItem
                    title="メールアドレス"
                    name="email_address"
                    value={information_state.user.email_address}
                    fix={true}
                />
                <InformationItem
                    title="パスワード"
                    value="***********"
                    name="password"
                    fix={true}
                />
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        border: "solid 1px gray",
                        padding: "1rem",
                    }}
                >
                    <Grid xs>
                        ランク: {information_state.user.rank_color_name}
                    </Grid>

                    <Grid xs>
                        {" "}
                        {information_state.user.point_sum}パカッティー
                    </Grid>
                </Grid>
                {/* <InformationItem
                    title="ポイント合計"
                    name="point_sum"
                    value={information_state.user.point_sum}
                /> */}
                {/* <InformationItem
                    title="ランク"
                    name="rank_color_name"
                    value={information_state.user.rank_color_name}
                /> */}

                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        marginTop: "0.5rem",
                        borderTop: "2px solid gray",
                        paddingTop: "0.5rem",
                    }}
                >
                    <Button
                        style={{ marginBottom: "0.4rem" }}
                        variant="contained"
                        color="primary"
                        onClick={() => history.push("/U_couponUseHistory")}
                        fullWidth
                    >
                        店舗利用履歴
                    </Button>
                    <Button
                        style={{ marginBottom: "0.4rem" }}
                        variant="contained"
                        color="primary"
                        onClick={() =>
                            history.push("/GeneralRedeemingPointList")
                        }
                        fullWidth
                    >
                        パカッティー交換
                    </Button>
                    <Button
                        style={{ marginBottom: "0.4rem" }}
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => history.push("/U_pointHistory")}
                    >
                        パカッティー履歴
                    </Button>
                    <Button
                        style={{
                            marginBottom: "0.4rem",
                            backgroundColor: "orange",
                        }}
                        variant="contained"
                        //color="primary"
                        href="https://talent-park.net/login/TalentLogin"
                        fullWidth
                    >
                        タレントパーク
                    </Button>
                    <Button
                        style={{ marginBottom: "0.4rem" }}
                        variant="contained"
                        color="primary"
                        onClick={OnWithdrawal}
                        fullWidth
                    >
                        退会について
                    </Button>
                </Grid>
            </Container>
            <TokenErrorDialog
                open={user_info_state.open_token_error_dialog}
                onClickOK={handleClickOK}
            />
            <Error500Handling
                open={user_info_state.open_error_dialog}
                GET={true}
                network={user_info_state.network_error}
                onClose={handleCloseError}
            />
        </>
    );
};

export default MemberInformation;
