import { Route, useLocation, Switch } from "react-router-dom";

import AdminHome from "~/pages/AdminHome";
import AdminPointSetting from "~/pages/AdminPointSetting";
import AdminEntryShopList from "~/pages/AdminEntryShopList";
import AdminStoreApplication from "~/pages/AdminStoreApplication";
import AdminCouponUseHistory from "~/pages/AdminCouponUseHistory";
import AdminFixHome from "~/pages/AdminFixHome";
import AdminRedeemingPointList from "~/pages/AdminRedeemingPointList";
import AdminStoreApplicationFix from "~/pages/AdminStoreApplicationFix";
import AdminStoreApplicationNew from "~/pages/AdminStoreApplicationNew";
import AdminNotice from "~/pages/AdminNotice";
import AdminCreateUser from "~/pages/AdminCreateUser";
import AdminShopList from "~/pages/AdminShopList";
import ImportantChangeAdmin from "~/pages/ImportantChangeAdmin";
import AdminNoticeList from "~/pages/AdminNoticeList";
import AdminShopEditing from "~/pages/AdminShopEditing";
import AdminSnap from "~/pages/AdminSnap";
import AdminHomeSetting from "~/pages/AdminHomeSetting";
import AdminShopQRCreate from "../pages/AdminShopQRCreate";

import AdminDocumentSetting from "~/pages/AdminDocumentSetting";
import AdminQASetting from "~/pages/AdminQASetting";
import AdminTagList from "~/pages/AdminTagList";

import AdminMascotList from "~/pages/AdminMascotList";
import AdminMascotNew from "~/pages/AdminMascotNew";
import AdminMascotDetail from "~/pages/AdminMascotDetail";
import AdminMascotEdit from "~/pages/AdminMascotEdit";
import AdminUserList from "~/pages/AdminUserList";
import AdminMemberList from "~/pages/AdminMemberList";
import AdminShopUsageHistory from "~/pages/AdminShopUsageHistory";
import AdminNewsSetting from "~/pages/ADMIN/AdminNewsSetting";
import AdminBlogSetting from "~/pages/ADMIN/AdminBlogSetting";
import AdminPointApplicationList from "~/pages/ADMIN/AdminPointApplicationList";
import AdminEnqueteSetting from "~/pages/ADMIN/AdminEnqueteSetting";

import TalentBankRoute from "~/route/TalentBankRoute";

const AdminRoute = () => {
    return (
        <>
            <Switch>
                <Route path="/AdminHome" component={AdminHome} exact />
                <Route
                    path="/AdminMascotList"
                    component={AdminMascotList}
                    exact
                />
                <Route
                    path="/AdminMascotNew"
                    component={AdminMascotNew}
                    exact
                />
                <Route
                    path="/AdminMascotDetail/:mascotId"
                    component={AdminMascotDetail}
                    exact
                />
                <Route
                    path="/AdminMascotEdit/:mascotId"
                    component={AdminMascotEdit}
                    exact
                />
                <Route path="/AdminUserList" component={AdminUserList} exact />
                <Route
                    path="/AdminDocumentSetting"
                    component={AdminDocumentSetting}
                    exact
                />
                <Route
                    path="/AdminMemberList"
                    component={AdminMemberList}
                    exact
                />
                <Route path="/AdminTagList" component={AdminTagList} exact />
                <Route
                    path="/AdminQASetting"
                    component={AdminQASetting}
                    exact
                />
                <Route
                    path="/AdminPointSetting"
                    component={AdminPointSetting}
                    exact
                />
                <Route
                    path="/AdminEntryShopList"
                    component={AdminEntryShopList}
                    exact
                />
                <Route
                    path="/AdminRedeemingPointList"
                    component={AdminRedeemingPointList}
                    exact
                />
                <Route
                    path="/AdminCreateUser"
                    component={AdminCreateUser}
                    exact
                />
                <Route
                    path="/AdminCouponUseHistory/:ShopID"
                    component={AdminCouponUseHistory}
                    exact
                />
                <Route path="/AdminFixHome" component={AdminFixHome} exact />
                <Route
                    path="/ImportantChangeAdmin"
                    component={ImportantChangeAdmin}
                    exact
                />
                <Route
                    path="/AdminNotice/:notification_id"
                    component={AdminNotice}
                    exact
                />
                <Route
                    path="/AdminNotice/:notification_id"
                    component={AdminNotice}
                    exact
                />
                <Route
                    path="/AdminNoticeList"
                    component={AdminNoticeList}
                    exact
                />
                <Route
                    path="/AdminShopEditing/:shop_id"
                    component={AdminShopEditing}
                    exact
                />
                <Route path="/AdminShopList" component={AdminShopList} exact />
                <Route
                    path="/AdminStoreApplication/:ShopID/:ApplyID"
                    component={AdminStoreApplication}
                    exact
                />
                <Route
                    path="/AdminStoreApplicationFix"
                    component={AdminStoreApplicationFix}
                    exact
                />
                <Route
                    path="/AdminStoreApplicationNew/:ShopID"
                    component={AdminStoreApplicationNew}
                    exact
                />
                <Route
                    path="/AdminHomeSetting"
                    component={AdminHomeSetting}
                    exact
                />
                <Route path="/AdminSnap/:ShopID" component={AdminSnap} exact />
                <Route
                    path="/AdminShopQRCreate"
                    component={AdminShopQRCreate}
                    exact
                />{" "}
                <Route
                    path="/AdminShopUsageHistory"
                    component={AdminShopUsageHistory}
                    exact
                />
                <Route
                    path="/AdminNewsSetting"
                    component={AdminNewsSetting}
                    exact
                />
                <Route
                    path="/AdminBlogSetting"
                    component={AdminBlogSetting}
                    exact
                />
                <Route
                    path="/AdminPointApplicationList"
                    component={AdminPointApplicationList}
                    exact
                />
                <Route
                    path="/AdminEnqueteSetting"
                    component={AdminEnqueteSetting}
                    exact
                />
                <Route>
                    <TalentBankRoute />
                </Route>
            </Switch>
        </>
    );
};
export default AdminRoute;
