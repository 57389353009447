import React from "react";
import { Grid, Chip, Divider, Button } from "@material-ui/core";

import TextHorizon from "../utils/TextHorizon";
import { useHistory } from "react-router-dom";
import ShopImageSlide from "../utils/ShopImageSlide";
import PinDrop from "@material-ui/icons/PinDrop";
import HalfPriceMenu from "../utils/HalfPriceMenu";
import SelectTarget from "../utils/SelectTarget";

const ShopApplicationPreview = (props) => {
    const ApplicationData = props.ApplicationData;

    const history = useHistory();
    // const [select, set_select] = React.useState("1");
    // console.log("█████████████████████████████████████");
    // console.log(ApplicationData);

    const path = history.location.pathname.split("/");
    const page_name = path[1];

    const input_list = {
        shop_prefectures: "都道府県",
        shop_city: "市区町村",
        shop_address1: "町名・番地",
        shop_address2: "建物名・部屋番号",
        access: "アクセス",
        business_hours: "営業時間",
        holiday: "定休日",
        reservation: "予約方法",
        url: "URL",
        sns: "SNS",
        shop_phone_number: "電話番号",
        shop_staff: "担当者",
    };

    // const HandleSelect = (val) => {
    //     set_select(val);
    // };
    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <ShopImageSlide
                    image_list={[
                        ApplicationData?.shop_image_small,
                        ApplicationData?.shop_image_2,
                        ApplicationData?.shop_image_3,
                        ApplicationData?.shop_image_4,
                        ApplicationData?.shop_image_5,
                    ]}
                />
                {/* <img
                    src={ApplicationData.shop_image_big}
                    onError={(e) => {
                        e.target.onerror = "null";
                        e.target.src = "/images/no_img.png";
                    }}
                    style={{ width: "100%", maxWidth: "550px" }}
                /> */}
            </Grid>

            {/* <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
            >
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => HandleSelect("1")}
                >
                    店舗情報
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => HandleSelect("2")}
                >
                    メニュー
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => HandleSelect("3")}
                >
                    スナップ
                </Button>
            </Grid> */}
            <>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={3}>
                        <b
                            style={{
                                color: "#ff3399",
                            }}
                        >
                            タグ
                        </b>
                    </Grid>
                    <Grid item xs={9}>
                        {/* {console.log(ApplicationData)} */}
                        {ApplicationData.tag_id_1 !== "---未選択---" &&
                            ApplicationData.tag_id_1 ? (
                            <Chip
                                variant="outlined"
                                label={ApplicationData.tag_id_1}
                            />
                        ) : (
                            ""
                        )}
                        {ApplicationData.tag_id_2 !== "---未選択---" &&
                            ApplicationData.tag_id_2 ? (
                            <Chip
                                variant="outlined"
                                label={ApplicationData.tag_id_2}
                            />
                        ) : (
                            ""
                        )}
                        {ApplicationData.tag_id_3 !== "---未選択---" &&
                            ApplicationData.tag_id_3 ? (
                            <Chip
                                variant="outlined"
                                label={ApplicationData.tag_id_3}
                            />
                        ) : (
                            ""
                        )}
                        {ApplicationData.tag_id_4 !== "---未選択---" &&
                            ApplicationData.tag_id_4 ? (
                            <Chip
                                variant="outlined"
                                label={ApplicationData.tag_id_4}
                            />
                        ) : (
                            ""
                        )}
                        {ApplicationData.tag_id_5 !== "---未選択---" &&
                            ApplicationData.tag_id_5 ? (
                            <Chip
                                variant="outlined"
                                label={ApplicationData.tag_id_5}
                            />
                        ) : (
                            ""
                        )}
                    </Grid>
                </Grid>
                <Divider
                    style={{
                        marginTop: "0.5rem",
                        marginBottom: "0.5rem",
                    }}
                />
            </>

            <TextHorizon name="サブタイトル" value={ApplicationData.store_pr} />
            <TextHorizon name="店舗説明" value={ApplicationData.shop_explain} />
            <TextHorizon
                name="fiftyメニュー"
                value={ApplicationData.shop_menu}
            />
            <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={1}
            >
                <Grid item xs={3}>
                    <b
                        style={{
                            color: "#ff3399",
                        }}
                    >
                        {"ターゲット"}
                    </b>
                </Grid>
                <Grid item xs={9}>
                    <SelectTarget
                        view={true}
                        value={ApplicationData.target}
                    />
                </Grid>
            </Grid>

            <React.Fragment>
                {/* <h2>詳細</h2> */}

                {Object.keys(input_list).map((key, index) => (
                    <React.Fragment key={key}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                            spacing={1}
                        >
                            <Grid item xs={3}>
                                <b
                                    style={{
                                        color: "#ff3399",
                                    }}
                                >
                                    {input_list[key]}
                                </b>
                            </Grid>
                            <Grid item xs={9}>
                                <div
                                    style={{ wordWrap: "break-word" }}
                                    className={
                                        "ShopInfo" + " " + "html-preview"
                                    }
                                    dangerouslySetInnerHTML={{
                                        __html: ApplicationData[key]
                                            ? ApplicationData[key].replace(
                                                /\n/g,
                                                "<br>"
                                            )
                                            : "",
                                    }}
                                ></div>
                            </Grid>
                        </Grid>
                        <Divider
                            style={{
                                marginTop: "0.5rem",
                                marginBottom: "0.5rem",
                            }}
                        />
                    </React.Fragment>
                ))}
                <>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item xs={3}>
                            <b
                                style={{
                                    color: "#ff3399",
                                }}
                            >
                                無料wifi
                            </b>
                        </Grid>
                        <Grid item xs={9}>
                            <div className="ShopInfo">
                                {ApplicationData.wifi == 1 ? "○" : "✕"}
                            </div>
                        </Grid>
                    </Grid>
                    <Divider
                        style={{
                            marginTop: "0.5rem",
                            marginBottom: "0.5rem",
                        }}
                    />
                </>
                <>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item xs={6}>
                            <b
                                style={{
                                    color: "#ff3399",
                                }}
                            >
                                緯度
                            </b>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="ShopInfo">
                                {ApplicationData.latitude}
                            </div>
                        </Grid>
                    </Grid>
                    <Divider
                        style={{
                            marginTop: "0.5rem",
                            marginBottom: "0.5rem",
                        }}
                    />
                </>
                <>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item xs={6}>
                            <b
                                style={{
                                    color: "#ff3399",
                                }}
                            >
                                経度
                            </b>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="ShopInfo">
                                {ApplicationData.longitude}
                            </div>
                        </Grid>
                    </Grid>
                    <Divider
                        style={{
                            marginTop: "0.5rem",
                            marginBottom: "0.5rem",
                        }}
                    />
                </>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={6}>
                        <b
                            style={{
                                color: "#ff3399",
                            }}
                        >
                            最寄り駅
                        </b>
                    </Grid>
                    <Grid item xs={6}>
                        <ClosestStation ApplicationData={ApplicationData} />
                    </Grid>
                </Grid>

                <React.Fragment>
                    {ApplicationData?.menu_data_list.length != 0
                        ? ApplicationData?.menu_data_list.map(
                            (menu_data, index) => (
                                <React.Fragment key={index}>
                                    {/* {index} */}
                                    <HalfPriceMenu menu_data={menu_data} />
                                </React.Fragment>
                            )
                        )
                        : "※半額メニューがありません。"}
                </React.Fragment>
            </React.Fragment>
            <BusinessHours ApplicationData={ApplicationData} />
            <>
                <Divider
                    style={{
                        marginTop: "0.5rem",
                        marginBottom: "0.5rem",
                    }}
                />
                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={3}>
                        <b
                            style={{
                                color: "#ff3399",
                            }}
                        >
                            {"備考"}
                        </b>
                    </Grid>
                    <Grid item xs={9}>
                        <div
                            style={{ wordWrap: "break-word" }}
                            className={
                                "ShopInfo" + " " + "html-preview"
                            }
                            dangerouslySetInnerHTML={{
                                __html: ApplicationData.remarks
                                    ? ApplicationData.remarks.replace(
                                        /\n/g,
                                        "<br>"
                                    )
                                    : "",
                            }}
                        ></div>
                    </Grid>
                </Grid>
                <Divider
                    style={{
                        marginTop: "0.5rem",
                        marginBottom: "0.5rem",
                    }}
                />
            </>
        </React.Fragment>
    );
};

const ClosestStation = (props) => {
    const { ApplicationData } = props;
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
        // alignItems="start"
        >
            <Grid item>
                {(ApplicationData?.closest_station_1 || ApplicationData?.closest_traffic_1) && (
                    <>
                        <PinDrop style={{ color: "red", fontSize: "0.8rem" }} />
                        {ApplicationData.closest_traffic_1}{" "}
                        {ApplicationData.closest_station_1}より{" "}
                        {ApplicationData.closest_means_1}
                        {ApplicationData.closest_station_minute_1}分
                        <br />
                    </>
                )}
                {(ApplicationData?.closest_station_2 || ApplicationData?.closest_traffic_2) && (
                    <>
                        <PinDrop style={{ color: "red", fontSize: "0.8rem" }} />
                        {ApplicationData.closest_traffic_2}{" "}
                        {ApplicationData?.closest_station_2}より{" "}
                        {ApplicationData.closest_means_2}
                        {ApplicationData?.closest_station_minute_2}分
                        <br />
                    </>
                )}
                {(ApplicationData?.closest_station_3 || ApplicationData?.closest_traffic_3) && (
                    <>
                        <PinDrop style={{ color: "red", fontSize: "0.8rem" }} />
                        {ApplicationData.closest_traffic_3}{" "}
                        {ApplicationData?.closest_station_3}より{" "}
                        {ApplicationData.closest_means_3}
                        {ApplicationData?.closest_station_minute_3}分
                    </>
                )}
            </Grid>
        </Grid>
    );
};
const BusinessHours = (props) => {
    const { ApplicationData } = props;

    const list = [
        { label: "日曜日", name: "sunday" },
        { label: "月曜日", name: "monday" },
        { label: "火曜日", name: "tuesday" },
        { label: "水曜日", name: "wednesday" },
        { label: "木曜日", name: "thursday" },
        { label: "金曜日", name: "friday" },
        { label: "土曜日", name: "saturday" },
    ];

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
                border: "solid 1px",
                padding: "0.2rem",
                borderRadius: "0.5rem",
            }}
        >
            {list.map((val, index) => (
                <React.Fragment key={index}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                        spacing={1}
                        style={{
                            padding: "0.1rem",
                        }}
                    >
                        <Grid item style={{ width: "5rem" }}>
                            {val.label}
                        </Grid>
                        <Grid item xs>
                            {!ApplicationData[`${val.name}_opening_time`] &&
                                !ApplicationData[`${val.name}_closing_time`] ? (
                                <>定休日</>
                            ) : (
                                <>


                                    {ApplicationData[`${val.name}_opening_time`]
                                        ?
                                        ApplicationData[`${val.name}_opening_time`].substring(0, 5)
                                    
                                        // ApplicationData[
                                        //     `${val.name}_opening_time`
                                        // ].substr(
                                        //     0,
                                        //     ApplicationData[
                                        //         `${val.name}_opening_time`
                                        //     ].length - 3
                                        // )

                                        : "不定"}
                                    ~
                                    {ApplicationData[`${val.name}_closing_time`]
                                        ?
                                        ApplicationData[`${val.name}_closing_time`].substring(0, 5)
                                        // ApplicationData[
                                        //     `${val.name}_closing_time`
                                        // ].substr(
                                        //     0,
                                        //     ApplicationData[
                                        //         `${val.name}_closing_time`
                                        //     ].length - 3
                                        // )
                                        : "不定"}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    );
};

export default ShopApplicationPreview;
