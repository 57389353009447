import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";


const useStyles = makeStyles((theme) => ({
    error_dialog: {
        "& .MuiDialog-paper": {
            padding: "15px",
            border: "2px solid orangered",
            display: "block",
        },
    },
    button: {
        display: "block",
        margin: "auto",
    },
}));

const TokenErrorDialog = (props) => {
    const { admin, onClickOK, ...other } = props;
    const history = useHistory();
    const classes = useStyles();
    const url = admin ? "/LoginAdmin" : "/FirstNavi";

    const handleRelogin = () => {
        if (onClickOK !== undefined) onClickOK();
        history.push(url);
    };

    return (
        <Dialog {...other} className={classes.error_dialog}>
            <p>ログイン情報の取得ができませんでした。再ログインを行ってください。</p>
            <Button
                variant="contained"
                className={classes.button}
                onClick={handleRelogin}
            >
                OK
            </Button>
        </Dialog>
    );
};

export default TokenErrorDialog;