import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";
import { SetRank } from "./Information";

// Stateの初期状態
const initialState = {
    check_list: [],
    item_list: "",
    open_modal: false,
    error_message: "",
    page: 1,
    last_page: 1,
    image_path: "",
    product_id: "",
    product_name: "",
    product_comment: "",
    necessary_point: 50,
    release_start_date: "2021-10-01 10:00:00",
    release_end_date: "9999-12-31 23:59:59",
    modal_progress: false,
    FixGift: false, //商品モーダルの変更または新規作成の判定フラグ
};

export const GetGiftList = createAsyncThunk(
    "AdminRedeemingPointList/GetGiftList",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;
        const redeemingPointState = thunkAPI.getState().AdminRedeemingPointList;
        const api_key = informationState.admin.admin_token;
        const params = {
            page: redeemingPointState.page,
        };
        const url = "/api/admin_point_items";

        const response = await axios.get(
            url + "?" + new URLSearchParams(params),
            {
                headers: {
                    "API-KEY": api_key,
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            }
        );
        return response.data;
    }
);

export const CreateGift = createAsyncThunk(
    "AdminRedeemingPointList/CreateGift",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;
        const redeemingPointState = thunkAPI.getState().AdminRedeemingPointList;
        const fix_value = redeemingPointState.fix_value;

        const api_key = informationState.admin.admin_token;
        const SetErrer = (message) => {
            dispatch(exchange_set(["error_message", message]));
        };
        if (!api_key) return;
        if (redeemingPointState && !redeemingPointState.product_name) {
            dispatch(exchange_set(["open_modal", false]));
            SetErrer(["入力エラー", "品名は必須項目です。"]);
            return;
        }
        if (redeemingPointState && !redeemingPointState.necessary_point) {
            dispatch(exchange_set(["open_modal", false]));
            SetErrer(["入力エラー", "必要パカッティーは必須項目です。"]);
            return;
        }
        if (redeemingPointState && !redeemingPointState.product_comment) {
            dispatch(exchange_set(["open_modal", false]));
            SetErrer(["入力エラー", "商品詳細は必須項目です。"]);
            return;
        }

        const params = {
            image: redeemingPointState.image_path,
            product_name: redeemingPointState.product_name,
            product_comment: redeemingPointState.product_comment,
            necessary_point: redeemingPointState.necessary_point,
            release_start_date: redeemingPointState.release_start_date,
            release_end_date: redeemingPointState.release_end_date,
        };
        // console.log(params);

        HttpFunction("POST", "/api/point_admin_setting", params, api_key)
            .then((data) => {
                if (data.status == 0) {
                    dispatch(exchange_set(["open_modal", false]));
                    SetErrer(["メッセージ", "交換品を登録しました 。", "info"]);
                    dispatch(GetGiftList());
                } else {
                    dispatch(exchange_set(["open_modal", false]));
                    SetErrer(["サーバーエラー", ""]);
                }

                // console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");
            })
            .catch((err) => {
                // console.log("サーバーエラー");
                // console.log(err);
                dispatch(exchange_set(["open_modal", false]));
                SetErrer(["サーバーエラー", ""]);
            });
    }
);

export const DeleteGift = createAsyncThunk(
    "AdminRedeemingPointList/DeleteGift",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;
        const redeemingPointState = thunkAPI.getState().AdminRedeemingPointList;
        const fix_value = redeemingPointState.fix_value;

        const api_key = informationState.admin.admin_token;

        if (!api_key) return;

        const params = {
            ...arg,
            delete_flag: "1",
        };

        HttpFunction("PUT", "/api/point_admin_setting", params, api_key)
            .then((data) => {
                if (data.status == 0) {
                    dispatch(GetGiftList());
                }
            })
            .catch((err) => {
                // console.log("サーバーエラー");
                // console.log(err);
                dispatch(
                    exchange_set(["error_message", ["サーバーエラー", ""]])
                );
            });
    }
);

export const FixGift = createAsyncThunk(
    "AdminRedeemingPointList/FixGift",
    async (arg, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const informationState = thunkAPI.getState().Information;
        const redeemingPointState = thunkAPI.getState().AdminRedeemingPointList;
        const fix_value = redeemingPointState.fix_value;

        const api_key = informationState.admin.admin_token;
        const SetErrer = (message) => {
            dispatch(exchange_set(["error_message", message]));
        };
        if (!api_key) return;
        if (redeemingPointState && !redeemingPointState.product_name) {
            dispatch(exchange_set(["open_modal", false]));
            SetErrer(["入力エラー", "品名は必須項目です。"]);
            return;
        }
        if (redeemingPointState && !redeemingPointState.necessary_point) {
            dispatch(exchange_set(["open_modal", false]));
            SetErrer(["入力エラー", "必要パカッティーは必須項目です。"]);
            return;
        }
        if (redeemingPointState && !redeemingPointState.product_comment) {
            dispatch(exchange_set(["open_modal", false]));
            SetErrer(["入力エラー", "商品詳細は必須項目です。"]);
            return;
        }

        const params = {
            product_id: redeemingPointState.product_id,
            image: redeemingPointState.image_path,
            product_name: redeemingPointState.product_name,
            product_comment: redeemingPointState.product_comment,
            necessary_point: redeemingPointState.necessary_point,
            release_start_date: redeemingPointState.release_start_date,
            release_end_date: redeemingPointState.release_end_date,
            updated_at: redeemingPointState.updated_at,
        };
        // console.log(params);

        HttpFunction("PUT", "/api/point_admin_setting", params, api_key)
            .then((data) => {
                if (data.status == 0) {
                    dispatch(exchange_set(["open_modal", false]));
                    SetErrer(["メッセージ", "交換品を変更しました 。", "info"]);
                    dispatch(GetGiftList());
                } else {
                    dispatch(exchange_set(["open_modal", false]));
                    SetErrer(["サーバーエラー", ""]);
                }
            })
            .catch((err) => {
                // console.log("サーバーエラー");
                // console.log(err);
                dispatch(
                    exchange_set(["error_message", ["サーバーエラー", ""]])
                );
            });
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "AdminRedeemingPointList",
    initialState,
    reducers: {
        initialize: () => initialState,
        exchange_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        [GetGiftList.pending]: (state, action) => {
            // // console.log(" 処理開始");
            state.modal_progress = true;
        },
        [GetGiftList.fulfilled]: (state, action) => {
            // console.log(action.payload);
            state.item_list = action.payload.data;
            state.last_page = action.payload.last_page;
            state.modal_progress = false;
        },
        [GetGiftList.rejected]: (state, action) => {
            // console.log(action);

            // console.log(action.payload);
            state.modal_progress = false;
            state.error_message = [
                "ネットワークエラー",
                "交換品の取得に失敗しました",
            ];
        },
        [CreateGift.rejected]: (state, action) => {
            state.open_modal = false;

            // console.log(action.payload);
            state.error_message = ["ネットワークエラー", "交換に失敗しました"];
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { exchange_set, initialize } = slice.actions;
