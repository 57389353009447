import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { GetNow } from "~/utils/GetNow";
const year_month = GetNow().year + "-" + GetNow().month;
// Stateの初期状態
const initialState = {
    vote_talent_list: [],
    talent_list: [],
    vote_serch: {
        page: 0,
        page_count: 0,
        total: 0,
        year_month: year_month,
    },
    selected_vote_id: "",
    new_poll: [],
    vote_list: [],
    vote_info: {
        page_count: 0,
        page: 1,
        total: 0,
    },
};

export const GetVoteList = createAsyncThunk(
    "TALENT/VoteList",
    async (arg, thunkAPI) => {
        const url = "/api/vote_list";
        const params = arg;

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const ShowVoteList = createAsyncThunk(
    "TALENT/ShowVoteList",
    async (arg, thunkAPI) => {
        const url = "/api/show_vate_list";
        const params = arg;
        const api_key = thunkAPI.getState().Information.admin.admin_token;

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const VoteDelete = createAsyncThunk(
    "TALENT/VoteDelete",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/vote_delete";
        const params = arg;

        try {
            const response = await axios.delete(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);
export const VoteSet = createAsyncThunk(
    "TALENT/VoteSet",
    async (arg, thunkAPI) => {
        const url = "/api/vote";
        const params = arg;

        try {
            const response = await axios.post(url, params, {
                headers: {
                    "content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);
export const CreateVate = createAsyncThunk(
    "TALENT/CreateVate",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/create_vate";
        const params = arg;

        try {
            const response = await axios.post(url, params, {
                headers: {
                    "API-KEY": api_key,
                    "content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);
export const SetPoll = createAsyncThunk(
    "TALENT/SetPoll",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/set_poll";
        const params = arg;

        try {
            const response = await axios.post(url, params, {
                headers: {
                    "API-KEY": api_key,
                    "content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "Vote",
    initialState,
    reducers: {
        initialize: (state, action) => {
            if (action.payload) {
                state[action.payload] = initialState[action.payload];
            } else {
                initialState;
            }
        },
        vote_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        // 企業検索
        [GetVoteList.pending]: (state, action) => {},
        [GetVoteList.fulfilled]: (state, action) => {
            const resdata = action.payload;

            state.selected_vote_id = action.meta.arg.vote_id;
            state.vote_talent_list = resdata.vote_list;
            state.talent_list = resdata.talent_data;
            // state.company_serch.page_count = resdata?.serch_info?.page_count;
            // state.company_serch.page = resdata?.serch_info?.page;
            // state.company_serch.total = resdata?.serch_info?.total;
        },
        [VoteSet.fulfilled]: (state, action) => {
            const resdata = action.payload;

            // state.company_list = resdata.company_list;
            // state.company_serch.page_count = resdata?.serch_info?.page_count;
            // state.company_serch.page = resdata?.serch_info?.page;
            // state.company_serch.total = resdata?.serch_info?.total;
        },
        [SetPoll.fulfilled]: (state, action) => {
            const resdata = action.payload;
            alert("投票数を編集しました");
        },
        [ShowVoteList.fulfilled]: (state, action) => {
            const resdata = action.payload;
            state.vote_list = resdata.vote_list;
            state.vote_info = resdata.vate_info;
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { initialize, vote_set } = slice.actions;
