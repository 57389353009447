import React from "react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";

import HttpFunction from "../utils/HttpFunction";
import { TextFieldNormal } from "../utils/TextFields";

const AdminCreateUser = () => {
    const history = useHistory();
    const information_state = useSelector((state) => state.Information);

    const [admin_email_address, SetAdminEmailAddress] = React.useState(
        "test005@digitalsolutions.co.jp"
    );
    const [admin_password, SetAdminPassword] = React.useState("Password!123");
    const [admin_nickname, SetAdminNickname] = React.useState("テストユーザー");
    const [shop_name, SetShopName] = React.useState("テストショップ");

    const [shop_id, SetShopId] = React.useState("S0000055");
    const [shop_password, SetShopPassword] = React.useState("Password!123");
    const [shop_user_email_address, SetShopUserEmailAddress] = React.useState(
        "test005@digitalsolutions.co.jp"
    );

    const onNewUserButton = () => {
        // // console.log(admin_email_address);
        // // console.log(admin_nickname);
        // // console.log(admin_password);

        const params = {
            email_address: admin_email_address,
            password: admin_password,
            nickname: admin_nickname,
        };
        // console.log(params);

        HttpFunction("POST", "/api/create_user_admin", params, "")
            .then((data) => {
                // console.log(data);
            })
            .catch((err) => {
                // console.log(err);
            });
    };
    const onCreateShopButton = () => {
        const api_key = information_state.admin.admin_token;

        const params = {
            shop_name: shop_name,
        };
        // console.log(params);
        // console.log(api_key);
        HttpFunction("POST", "/api/create_shop", params, api_key)
            .then((data) => {
                // console.log(data);
                // console.log("~~~~~password~~~~~~~~");
                // console.log(data.password);
                SetShopId(data.shop_id);
                SetShopPassword(data.password);
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    const onNewShopUserButton = () => {
        const params = {
            email_address: shop_user_email_address,
            password: admin_password,
            nickname: admin_nickname,
            shop_id: shop_id,
            shop_password: shop_password,
            invitation_cd: "",
        };
        // console.log(params);

        HttpFunction("POST", "/api/create_user_shop", params, "")
            .then((data) => {
                // console.log(data);
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    return (
        <React.Fragment>
            <h1>管理ユーザーの作成</h1>

            <TextFieldNormal
                label="メール"
                onChange={(e) => SetAdminEmailAddress(e.target.value)}
                value={admin_email_address}
                inputProps={{ maxLength: 255 }}
            />
            <TextFieldNormal
                label="パスワード"
                onChange={(e) => SetAdminPassword(e.target.value)}
                value={admin_password}
                inputProps={{ maxLength: 255 }}
            />
            <TextFieldNormal
                label="ニックネーム"
                onChange={(e) => SetAdminNickname(e.target.value)}
                value={admin_nickname}
                inputProps={{ maxLength: 255 }}
            />
            <div className="text-right">
                <Button
                    className="new-user-button"
                    onClick={() => onNewUserButton()}
                >
                    管理ユーザーの新規追加
                </Button>
            </div>

            <h1>加盟店の新規作成</h1>
            <TextFieldNormal
                label="ショップ名"
                onChange={(e) => SetShopName(e.target.value)}
                defaultValue={shop_name}
                inputProps={{ maxLength: 255 }}
            />
            <div className="text-right">
                <Button
                    className="new-user-button"
                    onClick={() => onCreateShopButton()}
                >
                    店舗新規追加
                </Button>
            </div>

            <h1>加盟店ユーザーの新規作成</h1>
            <TextFieldNormal
                label="ショップID"
                onChange={(e) => SetShopId(e.target.value)}
                value={shop_id}
                inputProps={{ maxLength: 8 }}
            />
            <TextFieldNormal
                label="店舗パスワード"
                onChange={(e) => SetShopPassword(e.target.value)}
                value={shop_password}
                inputProps={{ maxLength: 255 }}
            />
            <TextFieldNormal
                label="メール"
                onChange={(e) => SetShopUserEmailAddress(e.target.value)}
                value={shop_user_email_address}
                inputProps={{ maxLength: 255 }}
            />
            <div className="text-right">
                <Button
                    className="new-user-button"
                    onClick={() => onNewShopUserButton()}
                >
                    店舗ユーザー新規追加
                </Button>
            </div>
        </React.Fragment>
    );
};

export default AdminCreateUser;
