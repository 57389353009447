import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Stateの初期状態
const initialState = {
    qa_list: [],
    qa_detail: [],
    classification_name: "",
};

export const GetQAAll = createAsyncThunk(
    "管理/QA一覧取得",
    async (arg, thunkAPI) => {
        const informationState = thunkAPI.getState().Information;
        const dispatch = thunkAPI.dispatch;
        const api_key = informationState.admin.admin_token;

        const params = arg;
        const url = "/api/qa_all";

        const response = await axios.get(
            url + "?" + new URLSearchParams(params),
            {
                headers: {
                    "API-KEY": api_key,
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            }
        );

        return response.data;
    }
);

export const CreateQA = createAsyncThunk(
    "管理/QA分類作成",
    async (arg, thunkAPI) => {
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.admin.admin_token;

        const params = arg;
        const url = "/api/create_qa";

        const response = await axios.post(url, params, {
            headers: {
                "API-KEY": api_key,
                "Content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector(
                    'meta[name="csrf-token"]'
                ).content,
            },
        });

        return response.data;
    }
);

export const CreateQADetail = createAsyncThunk(
    "管理/QA詳細作成",
    async (arg, thunkAPI) => {
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.admin.admin_token;

        const params = arg;
        const url = "/api/create_qa_detail";

        const response = await axios.post(url, params, {
            headers: {
                "API-KEY": api_key,
                "Content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector(
                    'meta[name="csrf-token"]'
                ).content,
            },
        });

        return response.data;
    }
);

export const UpdateQA = createAsyncThunk(
    "管理/QA詳細更新",
    async (arg, thunkAPI) => {
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.admin.admin_token;

        const params = arg;
        const url = "/api/update_qa_detail";

        const response = await axios.put(url, params, {
            headers: {
                "API-KEY": api_key,
                "Content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector(
                    'meta[name="csrf-token"]'
                ).content,
            },
        });

        return response.data;
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "AdminQASetting",
    initialState,
    reducers: {
        initialize: () => initialState,
        set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];

            if (type) {
                state[type][name] = value;
            } else {
                state[name] = value;
            }
        },
    },
    extraReducers: {
        [GetQAAll.pending]: (state, action) => {},
        [GetQAAll.fulfilled]: (state, action) => {
            const payload = action.payload;
            state.qa_list = action.payload.qa_list;
            state.qa_detail = action.payload.qa_detail;
        },
        [GetQAAll.rejected]: (state, action) => {},
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_value, initialize } = slice.actions;
