import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    set_memberlist_value,
    changePoint,
    ChangeActive,
    ChangeEmail,
} from "../../stores/AdminMemberList";
import ModalGeneral from "../utils/ModalGeneral";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TextFieldNormal } from "../utils/TextFields";
import { ButtonContainedPrimary } from "../utils/Buttons";
import { makeStyles } from "@material-ui/core";
import TokenErrorDialog from "../utils/TokenErrorDialog";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Switch from "@material-ui/core/Switch";
import AdminGeneralUserDetail from "./AdminGeneralUserDetail";

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        paddingLeft: "40px",
        paddingRight: "40px",
        "& .MuiGrid-item": {
            marginBottom: "10px",
        },
    },
    modalTitle: {
        fontSize: "2rem",
        color: theme.palette.primary.main,
        textAlign: "center",
        marginBottom: "4rem",
    },
    centerButton: {
        display: "block",
        margin: "auto",
    },
}));

const InfoBox = ({ name, value }) => {
    return (
        <>
            <Grid item xs={4} style={{ fontSize: "1rem" }}>
                {name}
            </Grid>
            <Grid item xs={8} style={{ fontSize: "1.1rem" }}>
                {value}
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </>
    );
};

const MemberEditingDialog = () => {
    const dispatch = useDispatch();
    const memberlistState = useSelector((state) => state.AdminMemberList);
    const classes = useStyles();
    const [sign, setSign] = React.useState("加算");
    const [validError, setValidError] = React.useState(false);

    const [email, setEmail] = React.useState(memberlistState.edited_email);

    const handleClose = () => {
        dispatch(set_memberlist_value(["open_editing_dialog", false]));
        dispatch(set_memberlist_value(["show_alert", false]));
        dispatch(set_memberlist_value(["success", false]));
        setValidError(false);
    };
    const handleChange = (e) => {
        dispatch(set_memberlist_value(["success", false]));
        setSign(e.target.value);
        const isMinusSign = e.target.value === "減算";
        dispatch(set_memberlist_value(["isMinusSign", isMinusSign]));
    };
    const handleBlur = (e) => {
        dispatch(set_memberlist_value(["success", false]));
        if (e.target.value) setValidError(false);
        dispatch(set_memberlist_value(["adjusted_point", e.target.value]));
    };
    const handleAdjustPoint = () => {
        dispatch(set_memberlist_value(["show_alert", false]));

        const RegExp = /^[0-9]+$/;

        if (!RegExp.test(memberlistState.adjusted_point)) {
            setValidError(true);
            return;
        }

        let point = Number(memberlistState.adjusted_point);
        if (memberlistState.isMinusSign) point = -point;

        const params = {
            general_user_id: memberlistState.edited_user_id,
            point: point,
        };

        dispatch(changePoint(params));
    };

    const handleClickOK = () => {
        dispatch(set_memberlist_value(["open_token_error_dialog", false]));
    };

    const handleChangeEmail = (e) => {
        e.preventDefault();

        const params = {
            email: email,
            general_user_id: memberlistState.edited_user_id,
        };

        dispatch(ChangeEmail(params)).then((data) => {
            if (data?.payload?.message) {
                alert(data.payload.message);
            }
        });
    };

    const handleActive = () => {
        const active_flag = memberlistState.edited_active_flag == 1 ? 0 : 1;
        const params = {
            general_user_id: memberlistState.edited_user_id,
            active_flag: active_flag,
        };
        dispatch(ChangeActive(params))
            .then((data) => {
                dispatch(
                    set_memberlist_value(["edited_active_flag", active_flag])
                );
            })
            .catch((err) => {});
    };

    return (
        <>
            <ModalGeneral
                open={memberlistState.open_editing_dialog}
                onClose={handleClose}
            >
                <Container className={classes.modalContainer}>
                    <p className={classes.modalTitle}>会員詳細</p>
                    <Grid container>
                        <InfoBox
                            name="ユーザーID"
                            value={memberlistState.edited_user_id}
                        />
                        <InfoBox
                            name="ニックネーム"
                            value={memberlistState.edited_nickname}
                        />

                        <InfoBox
                            name="メールアドレス"
                            value={memberlistState.edited_email}
                        />

                        <div
                            style={{
                                marginTop: "25px",
                                marginBottom: "25px",
                                display: "contents",
                            }}
                        >
                            <Grid container>
                                <Grid item xs={4}>
                                    <p
                                        style={{
                                            fontSize: "1rem",
                                            padding: "5px 0 8px",
                                        }}
                                    >
                                        メールアドレス変更
                                    </p>
                                </Grid>

                                <form
                                    onSubmit={handleChangeEmail}
                                    style={{ display: "contents" }}
                                >
                                    <Grid item xs={3}>
                                        <TextFieldNormal
                                            // InputProps={{}}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            value={email}
                                            type="email"
                                        />
                                    </Grid>
                                    <Grid item xs={2} />

                                    <Grid item xs={3}>
                                        <ButtonContainedPrimary
                                            className={classes.centerButton}
                                            // onClick={}
                                            type="submit"
                                        >
                                            変更
                                        </ButtonContainedPrimary>
                                    </Grid>
                                </form>
                            </Grid>
                            <Divider />
                        </div>
                        <InfoBox
                            name="会員ランク"
                            value={memberlistState.edited_rank}
                        />
                        <InfoBox
                            name="パカッティー保有数"
                            value={`${memberlistState.edited_point} P`}
                        />
                    </Grid>
                    <div style={{ marginTop: "25px", marginBottom: "25px" }}>
                        <Grid container>
                            <Grid item xs={4}>
                                <p
                                    style={{
                                        fontSize: "1rem",
                                        padding: "5px 0 8px",
                                    }}
                                >
                                    パカッティー調整
                                </p>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    select
                                    value={sign}
                                    SelectProps={{ native: true }}
                                    onChange={handleChange}
                                >
                                    <option key="plus" value="加算">
                                        加算
                                    </option>
                                    <option key="minus" value="減算">
                                        減算
                                    </option>
                                </TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <TextFieldNormal
                                    error={validError}
                                    helperText={
                                        validError
                                            ? "0より大きい整数を半角で入力してください"
                                            : ""
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                P
                                            </InputAdornment>
                                        ),
                                    }}
                                    onBlur={handleBlur}
                                    inputProps={{ maxLength: 9 }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <ButtonContainedPrimary
                                    className={classes.centerButton}
                                    onClick={handleAdjustPoint}
                                >
                                    変更
                                </ButtonContainedPrimary>
                            </Grid>
                        </Grid>
                        <Divider />
                    </div>

                    <Grid container>
                        <InfoBox
                            name="無効化"
                            value={
                                <Switch
                                    checked={
                                        memberlistState.edited_active_flag ==
                                        "0"
                                            ? false
                                            : true
                                    }
                                    onChange={handleActive}
                                    name="checkedA"
                                    inputProps={{
                                        "aria-label": "secondary checkbox",
                                    }}
                                />
                            }
                        />
                    </Grid>

                    {memberlistState.success && (
                        <Alert severity="success">
                            {`${memberlistState.adjusted_point}P${sign}されました。`}
                        </Alert>
                    )}
                    {memberlistState.show_alert && (
                        <Alert severity="error">
                            <AlertTitle>
                                {memberlistState.error_title}
                            </AlertTitle>
                            {memberlistState.error_message}
                        </Alert>
                    )}

                    <AdminGeneralUserDetail
                        general_user_id={memberlistState.edited_user_id}
                    />
                </Container>
            </ModalGeneral>
            <TokenErrorDialog
                open={memberlistState.open_token_error_dialog}
                admin={true}
                onClickOK={handleClickOK}
            />
        </>
    );
};

export default MemberEditingDialog;
