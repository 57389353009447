import React from "react";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { InputMaxLength, InputDate, InputTime } from "~CJS/atoms/TextFields";
import InputImagePreview from "~CJS/atoms/InputImagePreview";
import SelectPrefectures from "~CJS/atoms/SelectPrefectures";
import { SelectTimeRange } from "~CJS/atoms/SelectRange";
import StatusCard from "~CJS/block/StatusCard";

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      root: {
        "&.Mui-disabled": {
          color: "black",
          // opacity: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&.Mui-disabled": {
          // color: "black",
          // opacity: 1,
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        "&$shrink": {
          color: "black",
        },
      },
    },
  },
});

const OfferDetailInput = (props) => {
  const { disabled, data, Create, ...other } = props;
  //Create は新規作成フラグ
  const offer_id = data?.offer_id;
  const offer_title = data?.offer_title;
  const company_id = data?.company_id ? data.company_id : "";
  const guarantee = data?.guarantee;
  const recruitment_count = data?.recruitment_count;
  const job_start_time = data?.job_start_time;
  const job_end_time = data?.job_end_time;
  const job_detail = data?.job_detail;
  const image_big = data?.image_big;
  const image = data?.image_small;
  const status = data?.status;
  const job_date = data?.job_date;
  const reason = data?.reason;
  const city = data?.city;
  const prefectures = data?.prefectures;
  const entry_close = data?.entry_close;

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <StatusCard status={status} reason={reason} offer />
        <InputImagePreview comment={false} height={200} image_big={image_big} image={image} disabled={disabled} />
        <InputMaxLength
          label="企業ID"
          name="company_id"
          inputProps={{ maxLength: 32 }}
          disabled={!Create && true}
          value={company_id}
          variant={Create ? "outlined" : "filled"}
          placeholder="例 C_00000000"
          required
        />
        {!Create && (
          <InputMaxLength
            label="オファーID"
            name="offer_id"
            inputProps={{ maxLength: 32 }}
            disabled={true}
            value={offer_id}
            variant="filled"
          />
        )}
        <InputMaxLength
          label="案件タイトル"
          name="offer_title"
          inputProps={{ maxLength: 32 }}
          disabled={disabled}
          value={offer_title}
          required
        />

        <SelectPrefectures disabled={disabled} value={prefectures} city={{ defaultValue: city }} />

        <InputMaxLength
          label="募集人数"
          name="recruitment_count"
          inputProps={{ maxLength: 10 }}
          disabled={disabled}
          value={recruitment_count}
          required
        />

        <InputMaxLength
          label="お仕事詳細"
          multiline
          name="job_detail"
          inputProps={{ maxLength: 50000 }}
          disabled={disabled}
          value={job_detail}
        />

        <InputDate
          label="案件日程"
          name="job_date"
          defaultValue="tomorrow"
          disabled={disabled}
          value={job_date}
          required
        />
        <SelectTimeRange
          start_name="job_start_time"
          end_name="job_end_time"
          start_label="案件開始予定時間"
          end_label="案件終了予定時間"
          disabled={disabled}
          start={job_start_time}
          end={job_end_time}
          noset
        />
        <InputTime
          label="募集終了"
          name="entry_close"
          defaultValue="after_week"
          disabled={disabled}
          value={entry_close}
          required
        />

        <InputMaxLength
          name="guarantee"
          label="ギャランティー"
          inputProps={{ maxLength: 254 }}
          disabled={disabled}
          value={guarantee}
          required
        />
      </ThemeProvider>
    </React.Fragment>
  );
};

export default OfferDetailInput;
