import React, { useState, useEffect } from "react";
import TagList from "../modules/TagList";
import { Button, Chip, TextField, Grid } from "@material-ui/core";

const SelectTags = (props) => {
    const [open, setOpen] = React.useState(false);

    const TagClick = (tag_name) => {
        setOpen(false);
        return props.onChange({
            target: { name: props.name, value: tag_name },
        });
    };
    const DelClick = (tag_name) => {
        return props.delete({
            target: { name: props.name, value: tag_name },
        });
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Grid
                style={{ marginTop: "0.2rem" }}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={2}>
                    {props.view_name}
                </Grid>
                <Grid item xs>
                    <Chip
                        style={{ width: "100%" }}
                        label={props.value}
                        onClick={handleOpen}
                        onDelete={DelClick}
                    ></Chip>
                </Grid>
            </Grid>
            <TagList
                TagListClick={handleClose}
                open={open}
                TagClick={TagClick}
            />
        </>
    );
};

export default SelectTags;
