import React from "react";
import Image from "react-bootstrap/Image";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { Button, Card, Grid, Divider } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

//景品カード
const ItemCard = (props) => {
    const data = props.data;
    const index = props.index;
    const OnExchange = props.OnExchange;
    const ButtonName = props.ButtonName;
    const OnFix = props.OnFix;
    const FixButtonName = props.FixButtonName;

    return (
        <Card>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid
                    item
                    xs={3}
                    style={{
                        padding: "0.5rem",
                    }}
                >
                    <Image
                        className="point_list_img"
                        src={data.image_path ? data.image_path : "null"}
                        fluid
                        onError={(e) => {
                            e.target.onerror = "null";
                            e.target.src = "/images/no_img.png";
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs
                    style={{
                        padding: "0.5rem",
                    }}
                >
                    <Grid container justifyContent="center">
                        <Divider />
                    </Grid>
                    <Grid
                        container
                        justifyContent="center"
                        style={{
                            fontSize: "1rem",
                            borderBottom: "solid 1px gray",
                            // minHeight: "4.5rem",
                        }}
                    >
                        {data.product_name}
                    </Grid>

                    <Grid container justifyContent="space-between">
                        {FixButtonName ? "商品番号: " + data?.product_id : ""}

                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-end"
                        >
                            <b
                                style={{
                                    paddingTop: "0.5rem",
                                    color: "red",
                                    fontSize: "1.8rem",
                                    fontStyle: "italic",
                                }}
                            >
                                {data.necessary_point}
                            </b>
                            <div
                                style={{
                                    paddingBottom: "0.4rem",
                                    paddingLeft: "0.3rem",
                                }}
                            >
                                パカッティー
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid container justifyContent="space-between">
                    個数:
                    {data.start_exchange_num}
                </Grid>
                <Grid container>
                    残り:
                    {data.exchange_num}
                </Grid> */}

                {/* <Grid container>
                    <Accordion square={true} style={{ width: "100%" }}>
                        <AccordionSummary>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                商品詳細を見る
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div
                                className="html-preview"
                                dangerouslySetInnerHTML={{
                                    __html: data.product_comment
                                        ? data.product_comment.replace(
                                              /\n/g,
                                              "<br>"
                                          )
                                        : "",
                                }}
                            ></div>
                        </AccordionDetails>
                    </Accordion>
                </Grid> */}

                <Grid
                    item
                    style={{
                        padding: "0.5rem",
                    }}
                >
                    <Button
                        name={index}
                        variant="contained"
                        color="primary"
                        onClick={OnExchange}
                    >
                        {ButtonName}
                    </Button>
                    {FixButtonName ? (
                        <Button
                            name={index}
                            variant="contained"
                            color="primary"
                            onClick={OnFix}
                            style={{ marginLeft: "1rem" }}
                        >
                            {FixButtonName}
                        </Button>
                    ) : (
                        ""
                    )}
                </Grid>
            </Grid>
        </Card>
    );
};

export default ItemCard;
