import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
    dialog: {
        minHeight: "10rem",
    },
    title: {
        fontSize: "1.2rem",
    },
    icon: {
        position: "absolute",
        right: "0",
        top: "0",
    },
    img_dialog: {
        padding: "8px,0px",
    },
    img: {
        width: "100%",
    },
});

const CommonDialog = (props) => {
    const classes = useStyles();
    const { onClose, open, title, children, TransitionComponent, ...other } =
        props;
    return (
        <Dialog
            onClose={props.onClose}
            open={props.open}
            className={classes.dialog}
            fullWidth
            TransitionComponent={props.TransitionComponent}
            maxWidth={"xl"}
        >
            {title ? (
                <DialogTitle disableTypography className={classes.title}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {title}
                    </Grid>
                </DialogTitle>
            ) : (
                ""
            )}

            <IconButton
                aria-label="close"
                className={classes.icon}
                onClick={props.onClose}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent dividers>{children}</DialogContent>
        </Dialog>
    );
};
export default CommonDialog;
