import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Button } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { prefectures_list } from "../../const/prefectures_list";
import { genre_list } from "../../const/genre_list";
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PinDropIcon from "@material-ui/icons/PinDrop";
import AnchorLink from "react-anchor-link-smooth-scroll";

import TagList from "./TagList";
import Image from "react-bootstrap/Image";

import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";

import SelectPrefectures from "../utils/SelectPrefectures";
import SelectPriceRange from "../utils/SelectPriceRange";

import SelectCity from "../utils/SelectCity";

import { shoplist_set, tag_set } from "../../stores/Shoplist";

import { HideFooter, ShowFooter } from "./BottomNavigation";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import TextField from "@material-ui/core/TextField";
import { FormatColorReset } from "@material-ui/icons";
import Select from "@material-ui/core/Select";

import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    imageListItemBar: {
        backgroundColor: "#00000000",
        fontSize: "5rem",
        textAlign: "center",
        // height: "50%",
        height: "100%",
    },
    ImageListSelected: {
        borderLeft: "solid 0.5rem #FEE2E0",
        borderTop: "solid 0.5rem #FEE2E0",
        borderRight: "solid 0.5rem #FEE2E0",
        borderBottom: "solid 0.5rem #FEE2E0",
        borderRadius: "1rem 1rem 0rem 0rem ",
        backgroundColor: "#FEE2E0",
        boxShadow: "0 0 10px 5px burlywood",
    },
    ImageListNoSelected: {
        border: "solid 0.5rem #00000000",
        fontSize: "0.5rem",
        backgroundColor: "#dadada",
        borderRadius: "1rem 1rem 0rem 0rem ",
    },
    formControl: {
        margin: theme.spacing(1),
    },
    osusumeList: {
        backgroundColor: "#FEE2E0",
        border: "solid #FEE2E0 0.5rem",
        width: "96%",
        margin: "0px 2%",
        padding: "0rem",
        minHeight: "220px",
    },
}));

export default function ShoplistSort(props) {
    const classes = useStyles();
    const thisState = useSelector((state) => state.Shoplist);
    const information_state = useSelector((state) => state.Information);
    const tagListState = useSelector((state) => state.TagList);

    const dispatch = useDispatch();

    const DistrictClick = (district) => {
        const flug = thisState[district] == true ? false : true;
        dispatch(shoplist_set([district, flug]));
    };
    const TagClick = (tag_name) => {
        const flug = thisState.tag_list[tag_name] == true ? false : true;

        dispatch(
            shoplist_set([
                "genre_filter",
                { 飲食: false, 美容: false, その他: false },
            ])
        );

        if (flug) {
            const new_serch_text = "#" + tag_name + " " + thisState.serch_text;
            dispatch(shoplist_set(["serch_text", new_serch_text]));
        } else {
            const new_serch_text = thisState.serch_text.replace(
                "#" + tag_name + " ",
                ""
            );
            dispatch(shoplist_set(["serch_text", new_serch_text]));
        }

        dispatch(tag_set([tag_name, flug]));
    };

    const ChengeSeachText = (e, a) => {
        if (!e.target.value) {
            dispatch(shoplist_set(["tag_list", ""]));
        }

        dispatch(shoplist_set(["serch_text", e.target.value]));
    };

    const ChengeSerchStationName = (e, a) => {
        dispatch(shoplist_set(["serch_station_name", e.target.value]));
    };
    const SelectGenre = (genre_name) => {
        dispatch(shoplist_set(["select_genre", genre_name]));
    };
    const TagListClick = () => {
        const flug = thisState.taglist == true ? false : true;
        dispatch(shoplist_set(["taglist", flug]));
    };
    const SelectArea = (e) => {
        dispatch(shoplist_set(["serch_area", e.target.value]));
    };
    const HandleSelectCity = (e) => {
        dispatch(shoplist_set(["serch_city", e.target.value]));
    };

    const SerchStert = props.SerchStert;

    const SelectOrder = (e) => {
        dispatch(shoplist_set(["order", e.target.value]));
    };

    const SelectLimit = (e) => {
        dispatch(shoplist_set(["limit", e.target.value]));
    };
    const isFirstRender = useRef(false);

    const mapView = () => {
        if (typeof flutter === "undefined") {
            alert("フィフティーカードアプリでご利用ください。");
        } else {
            const shoplists = [];
            for (let i = 0; i < thisState.shop_list_data.length; i++) {
                const shoplist = {
                    name: thisState.shop_list_data[i].shop_name,
                    latitude: thisState.shop_list_data[i].latitude,
                    longitude: thisState.shop_list_data[i].longitude,
                    webViewUrl: `${location.protocol}//${location.host}/ShopInformation/${thisState.shop_list_data[i].shop_id}/disabled_navigation`,
                };
                shoplists.push(shoplist);
            }

            window.flutter_inappwebview.callHandler("MapPage", shoplists);
        }
    };

    useEffect(() => {
        // このeffectは初回レンダー時のみ呼ばれるeffect
        isFirstRender.current = true;
    }, []);

    useEffect(() => {
        if (isFirstRender.current) {
            // 初回レンダー判定
            isFirstRender.current = false;
        } else {
            SerchStert();
        }
    }, [thisState.order, thisState.limit]);

    const imageButton = (props) => {
        const height = props.height ? props.height : 50;
        return (
            <ImageListItem
                key={props.title}
                className={
                    props.selected
                        ? classes.ImageListSelected
                        : classes.ImageListNoSelected
                }
                onClick={props.onClick}
                style={{
                    height: height,
                    // border: "solid chartreuse 0.5rem",
                }}
            >
                {/* <img src={props.image} style={{ borderRadius: "2rem" }} /> */}
                <ImageListItemBar
                    title={props.title}
                    className={classes.imageListItemBar}
                />
            </ImageListItem>
        );
    };
    const imageMenu = (props) => {
        const genre_data = [
            { title: "美容", image: "/images/genre/美容院.jpg" },
            { title: "飲食", image: "/images/genre/飲食.jpg" },
            { title: "その他", image: "/images/genre/エステ.jpg" },
        ];

        return (
            <ImageList
                cols={3}
                style={{ paddingTop: "1rem", margin: "-5px 2%", width: "96%" }}
            >
                {genre_data.map((value, index) =>
                    imageButton({
                        title: value.title,
                        image: value.image,
                        onClick: () => SelectGenre(value.title),
                        selected:
                            thisState.select_genre == value.title
                                ? true
                                : false,
                    })
                )}
            </ImageList>
        );
    };

    const AreaSearch = (props) => {
        return (
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={6}>
                    <SelectPrefectures
                        selectCity
                        disabled={false}
                        value={props.thisState.serch_area}
                        onChange={SelectArea}
                        size="small"
                    />
                </Grid>{" "}
                <Grid item xs={6}>
                    <SelectCity
                        disabled={false}
                        // variant={disabled ? "filled" : "outlined"}
                        prefectures={props.thisState.serch_area}
                        value={props.thisState.serch_city}
                        onChange={HandleSelectCity}
                        size="small"
                    />
                </Grid>
            </Grid>
        );
    };

    const ChengePriceRange = (e, a) => {
        if (!e.target.value) {
            dispatch(shoplist_set(["price_range", ""]));
        }

        dispatch(shoplist_set(["price_range", a]));
    };

    const GenreSearch = (props) => {
        return (
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                className={classes.osusumeList}
            >
                {Object.keys(tagListState.recommend_list).map(
                    (key, outindex) =>
                        thisState.select_genre == key && (
                            <div key={outindex + "_tagout"}>
                                <h4>おすすめタグ {key}</h4>
                                {tagListState.recommend_list[key] &&
                                    tagListState.recommend_list[key].map(
                                        (value, index) => (
                                            <Chip
                                                style={{
                                                    borderRadius: "8px",
                                                    marginLeft: "3px",
                                                }}
                                                key={index + "_taglist"}
                                                label={value.tag_name}
                                                name={value.tag_id}
                                                onClick={() =>
                                                    TagClick(value.tag_name)
                                                }
                                                color={
                                                    thisState.tag_list[
                                                        value.tag_name
                                                    ]
                                                        ? "primary"
                                                        : "default"
                                                }
                                            />
                                        )
                                    )}

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-end"
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    thisState.genre_filter[key]
                                                }
                                                onClick={(e) => {
                                                    if (thisState.serch_text) {
                                                        dispatch(
                                                            shoplist_set([
                                                                "serch_text",
                                                                "",
                                                            ])
                                                        );
                                                        dispatch(
                                                            shoplist_set([
                                                                "tag_list",
                                                                {},
                                                            ])
                                                        );
                                                    }

                                                    dispatch(
                                                        shoplist_set([
                                                            "genre_filter",
                                                            {
                                                                ...thisState.genre_filter,
                                                                [key]: e.target
                                                                    .checked,
                                                            },
                                                        ])
                                                    );
                                                }}
                                                defaultChecked
                                            />
                                        }
                                        label={key + "のすべて取得する。"}
                                    />

                                    <Button onClick={() => TagListClick()}>
                                        タグをもっと見る
                                    </Button>
                                </Grid>
                            </div>
                        )
                )}
            </Grid>
        );
    };

    return (
        <React.Fragment>
            {thisState.error_message ? (
                <Alert severity="error" style={{ width: "100%" }}>
                    <AlertTitle>エラー</AlertTitle>
                    {thisState.error_message}
                </Alert>
            ) : (
                ""
            )}

            <Container fixed>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                ></Grid>
            </Container>
            <Container fixed>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                ></Grid>
            </Container>

            <TagList
                open={thisState.taglist}
                TagListClick={TagListClick}
                thisState={thisState}
                TagClick={TagClick}
            />

            {/* <ImageList
                cols={1}
                onClick={() => DistrictClick("area_search_flug")}
                style={{
                    margin: "1rem",
                }}
            >
                {imageButton({
                    height: 100,
                    title: "エリアを選択する",
                    image: "/images/genre/日本地図1.png",
                })}
            </ImageList> */}

            <Container fixed>
                {/* {thisState.area_search_flug ? ( */}
                <AreaSearch
                    information_state={information_state}
                    thisState={thisState}
                />
                {/* ) : (
                    ""
                )} */}
            </Container>
            <Container fixed>
                <Grid
                    style={{ marginTop: "1rem" }}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={thisState.serch_text}
                        id="serch_text"
                        label="フリーワード検索"
                        inputProps={{ maxLength: 255 }}
                        onChange={ChengeSeachText}
                        onFocus={HideFooter}
                        onBlur={ShowFooter}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size="small"
                    />
                </Grid>
            </Container>
            <Container fixed>
                <Grid
                    style={{ marginTop: "1rem" }}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={thisState.serch_station_name}
                        id="serch_station_name"
                        label="駅名"
                        inputProps={{ maxLength: 255 }}
                        onChange={ChengeSerchStationName}
                        onFocus={HideFooter}
                        onBlur={ShowFooter}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size="small"
                    />
                </Grid>
            </Container>
            <SelectPriceRange
                onChange={ChengePriceRange}
                value={thisState.price_range}
            />
            <Container fixed style={{ padding: "0.1rem" }}>
                {imageMenu()}
            </Container>

            <ImageList
                cols={1}
                onClick={() => DistrictClick("genre_search_flug")}
            ></ImageList>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                {GenreSearch()}
            </Grid>

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box component="span" m={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={SerchStert}
                    >
                        <SearchIcon />
                        検索
                    </Button>
                </Box>

                {/* {thisState.serch_flag && thisState.shop_list_data ? (
                    <Box component="span" m={1}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => mapView()}
                        >
                            <PinDropIcon />
                            MAPで表示
                        </Button>
                    </Box>
                ) : (
                    ""
                )} */}
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <Select native defaultValue="5" onChange={SelectLimit}>
                    <option value={"5"}>1ページ5件</option>
                    <option value={"10"}>1ページ10件</option>
                </Select>
                <Select native defaultValue="" onChange={SelectOrder}>
                    <option value={"g.review"}>評価順</option>
                    <option value={"a.approval_date"}>新着順</option>
                    <option value={"i.recommend_flag"}>おすすめ順</option>
                    <option value={"t.updated_at"}>更新順</option>
                </Select>
            </Grid>
        </React.Fragment>
    );
}
