import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Stateの初期状態
const initialState = {
    error_flag: false,
    error_message: "",
};

// Sliceを生成する
const slice = createSlice({
    name: "ErrorHandler",
    initialState,
    reducers: {
        errorHandler_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith("/fulfilled"),
                (state, action) => {
                    const payload = action.payload;
                    if (process.env.MIX_APP_ENV === "local") {
                        console.debug(
                            `▼▼▼▼▼▼▼｢%c${action.type}%c｣のリクエスト▼▼▼▼▼▼▼▼▼▼▼▼▼▼`,
                            "font-weight: bold; font-size: large; color: red;",
                            ""
                        );
                        console.debug(action.meta.arg);
                        console.debug("｢" + action.type + "｣の戻り値");
                        console.debug(payload);
                        console.debug(
                            `▲▲▲▲▲▲▲｢%c${action.type}%c｣の通信終了▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲`,
                            "font-weight: bold; font-size: large; color: red;",
                            ""
                        );
                    }
                }
            )
            .addMatcher(
                (action) => action.type.startsWith("TALENT/"),
                (state, action) => {
                    const payload = action.payload;

                    if (payload?.result) {
                        if (
                            (payload.result == 2 || payload.result == "NG") &&
                            payload.error_message &&
                            !payload.disabled_error
                        ) {
                            state.error_flag = true;
                            state.error_message = payload.error_message;
                        }
                    }
                }
            )
            .addMatcher(
                (action) => action.type.endsWith("/rejected"),
                (state, action) => {
                    if (
                        action.payload.error_message &&
                        !action.payload.disabled_error
                    ) {
                        state.error_flag = true;
                        state.error_message = action.payload.error_message;
                    }
                }
            );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { errorHandler_set } = slice.actions;
