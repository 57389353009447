import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Stateの初期状態
const initialState = {
    openTip: false,
    openTipMessage: false,
    friend_introduction_point: 0,
};

export const Setkurukuru = createAsyncThunk(
    "くるくるの表示",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.user.token;

        const params = arg;
        let url = "/api/favorite";

        function delay(n) {
            return new Promise(function (resolve) {
                setTimeout(resolve, n * 1000);
            });
        }

        try {
            const response = await delay(0.2);

            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const GetPoint= createAsyncThunk(
    "ポイントの取得",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.user.token;

        const params = arg;
        let url = "/api/get_point";

        try {
            const response = await axios.get(
                url + "?" + new URLSearchParams(params),
                {
                    headers: {
                        "API-KEY": api_key,
                        "ontent-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "resources/js/stores/GeneralFriendInvitation.js",
    initialState,
    reducers: {
        set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_value } = slice.actions;
