import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { LinkButton } from "~CJS/atoms/LinkButton";
import Button from "@material-ui/core/Button";

import { useDispatch, useSelector } from "react-redux";

import { BackButton } from "~CJS/atoms/BackButton";
// import FixedBand from "~/atoms/FixedBand";
import { SwitchInput } from "~CJS/atoms/SwitchInput";

import CompanyInput from "~CJS/block/CompanyInput";
// import CompanyCard from "~CJS/block/CompanyCard";
import {
    initialize,
    GetCompanyDetail,
    CompanyFixApi,
} from "~stores/TALENTBANK/Company";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
const CompanyFix = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const companyState = useSelector((state) => state.Company);
    const path = history.location.pathname.split("/");
    const company_id = path[2];

    useEffect(() => {
        dispatch(initialize());
        dispatch(GetCompanyDetail({ company_id: company_id }));
    }, []);

    // const OfferDetailState = useSelector((state) => state.OfferDetail);

    const handleSubmit = (e) => {
        e.preventDefault();
        const elements = e.target?.elements;

        const getValue = (name) => {
            let value = "";
            if (elements[name]?.value) {
                value = elements[name]?.value;
            }

            return value;
        };

        const company_info = {
            email_address: getValue("email_address"),
            password: getValue("password"),
            image_big: getValue("image_big"),
            image_small: getValue("image"),
            company_name: getValue("company_name"),
            prefectures:
                getValue("prefectures") && getValue("prefectures") != "未選択"
                    ? getValue("prefectures")
                    : "",
            city: getValue("city"),
            address_1: getValue("address_1"),
            address_2: getValue("address_2"),
            company_page: getValue("company_page"),
            staff_email_address: getValue("staff_email_address"),
            company_id: company_id,
            active_flag: getValue("active_flag") == "true" ? 0 : 1,
            delete_flag: getValue("delete_flag") == "true" ? 1 : 0,
        };

        const params = {
            company_info: company_info,
            input_option: [],
            category: getValue("category"),
        };

        dispatch(CompanyFixApi(params)).then((action) => {
            if (action.payload.result == "1") {
                history.push(`/SponsorSearch`);
                alert("企業情報を更新しました。");
            }
        });
    };

    return (
        <React.Fragment>
            <ButtonNavi left_label="企業検索" left_link="/SponsorSearch" />

            <Typography variant="h1">企業編集</Typography>

            <Container>
                <form onSubmit={handleSubmit}>
                    <CompanyInput data={companyState.company_detail} />

                    <SwitchInput
                        label="無効"
                        name="active_flag"
                        value={
                            companyState.company_detail.active_flag == "1"
                                ? false
                                : true
                        }
                    />
                    <SwitchInput
                        label="削除"
                        name="delete_flag"
                        value={
                            companyState.company_detail.delete_flag == "1"
                                ? true
                                : false
                        }
                    />

                    <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        type="submit"
                    >
                        更新
                    </Button>
                </form>
            </Container>
        </React.Fragment>
    );
};

export default CompanyFix;
