import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import IconButton from "@material-ui/core/IconButton";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { ButtonContainedPrimary } from "../utils/Buttons";
import TokenErrorDialog from "../utils/TokenErrorDialog";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import {
    set_shopedit_value,
    initialize,
    GetInfo,
    ChangePass,
    ChangeStatus,
    DeleteShopUser,
} from "../../stores/AdminShopEditing";
import { useHistory } from "react-router";
import { makeStyles, withStyles } from "@material-ui/core";
import { InputMaxLength } from "~CJS/atoms/TextFields";

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: "600px",
    },
    field_name: {
        fontSize: "1rem",
        lineHeight: "50px",
        paddingLeft: "20px",
    },
    field_value: {
        fontSize: "1.1rem",
        lineHeight: "50px",
    },
    field_button: {
        lineHeight: "50px",
        textAlign: "right",
        paddingRight: "20px",
    },
    field_helper: {
        lineHeight: "50px",
        color: "#f3448d",
        textAlign: "right",
        paddingRight: "20px",
    },
    section_title: {
        fontSize: "1rem",
        fontWeight: "bold",
        color: "#626262",
        paddingLeft: "5px",
        marginBottom: "5px",
    },
    table_cell_top: {
        background: "pink",
        border: "1px solid gray",
        padding: "0.3rem",
        height: "2rem",
    },
    table_cell: {
        border: "1px solid gray",
        padding: "0.3rem",
        height: "2rem",
    },
}));

const BackButton = () => {
    const history = useHistory();

    const handleBack = () => {
        history.push("/AdminShopList");
    };

    return (
        <IconButton onClick={handleBack} style={{ padding: "8px 0 24px 8px" }}>
            <NavigateBeforeIcon />
            <span style={{ fontSize: "70%" }}>戻る</span>
        </IconButton>
    );
};

const GridDivider = () => {
    return (
        <Grid item xs={12}>
            <Divider />
        </Grid>
    );
};

const SkyblueSwitch = withStyles({
    switchBase: {
        "&$checked": {
            color: "#3ad3e3",
        },
        "&$checked + $track": {
            backgroundColor: "#57dfed",
        },
    },
    checked: {},
    track: {},
})(Switch);

const AdminShopEditing = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const thisState = useSelector((state) => state.AdminShopEditing);
    const classes = useStyles();

    const handleChangePass = () => {
        const params = {
            shop_id: thisState.shop_id,
            updated_at: thisState.updated_at,
        };

        dispatch(ChangePass(params));
    };

    const handleChangeToggle = (e) => {
        const value = e.target.checked ? "1" : "0";
        dispatch(set_shopedit_value([e.target.name, value]));
        dispatch(set_shopedit_value(["status_undetermined", true]));
    };

    const handleClickOK = () => {
        dispatch(set_shopedit_value(["open_token_error_dialog", false]));
    };

    useEffect(() => {
        dispatch(initialize());
        const path = history.location.pathname.split("/");
        const shop_id = path[2];
        dispatch(GetInfo({ shop_id: shop_id }));
    }, []);

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elements = e.target?.elements;

        const getValue = (name) => {
            let value = "";
            if (elements[name]?.value) {
                value = elements[name]?.value;
            }
            return value;
        };

        const params = {
            shop_id: thisState.shop_id,
            shop_name: getValue("shop_name"),
            recommend_flag: thisState.recommend_flag,
            special_flag: thisState.special_flag,
            active_flag: thisState.active_flag,
            updated_at: thisState.updated_at,
        };

        dispatch(ChangeStatus(params));
    };

    const DeleteUser = (val) => {
        // console.log("aaa");
        // console.log(val);

        dispatch(
            DeleteShopUser({ shop_user_id: val.user_id, shop_id: val.shop_id })
        );
    };

    return (
        <>
            <BackButton />
            <Container className={classes.container}>
                <h1>加盟店編集</h1>
                <form noValidate autoComplete="off" onSubmit={HandleSubmit}>
                    {thisState.show_alert && (
                        <Alert severity="error">
                            <AlertTitle>{thisState.error_title}</AlertTitle>
                            {thisState.error_message}
                        </Alert>
                    )}
                    <Grid container style={{ marginBottom: "50px" }}>
                        <GridDivider />
                        <Grid item xs={4} className={classes.field_name}>
                            店名
                        </Grid>
                        <Grid item xs={8} className={classes.field_value}>
                            <InputMaxLength
                                label=""
                                name="shop_name"
                                inputProps={{ maxLength: 255 }}
                                required
                                value={thisState.shop_name}
                            />
                        </Grid>
                        <GridDivider />
                        <Grid item xs={4} className={classes.field_name}>
                            加盟店ID
                        </Grid>
                        <Grid item xs={8} className={classes.field_value}>
                            {thisState.shop_id}
                        </Grid>
                        <GridDivider />
                        <Grid item xs={4} className={classes.field_name}>
                            加盟店パスワード
                        </Grid>
                        <Grid item xs={6} className={classes.field_value}>
                            {thisState.shop_password}
                        </Grid>
                        <Grid item xs={2} className={classes.field_button}>
                            <ButtonContainedPrimary
                                onClick={handleChangePass}
                                style={{ margin: "7px 0 7px" }}
                            >
                                生成
                            </ButtonContainedPrimary>
                        </Grid>
                        <GridDivider />
                    </Grid>
                    <p className={classes.section_title}>ステータス</p>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <GridDivider />
                        <Grid item xs={4} className={classes.field_name}>
                            おすすめ
                        </Grid>
                        <Grid item xs={8} className={classes.field_button}>
                            <SkyblueSwitch
                                checked={thisState.recommend_flag === "1"}
                                onChange={handleChangeToggle}
                                name="recommend_flag"
                            />
                        </Grid>
                        <GridDivider />
                        <Grid item xs={4} className={classes.field_name}>
                            優良店舗
                        </Grid>
                        <Grid item xs={8} className={classes.field_button}>
                            <SkyblueSwitch
                                checked={thisState.special_flag === "1"}
                                onChange={handleChangeToggle}
                                name="special_flag"
                            />
                        </Grid>
                        <GridDivider />
                        <Grid
                            item
                            xs={4}
                            className={classes.field_name}
                            style={{ backgroundColor: "#dbdbdb" }}
                        >
                            利用停止
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            className={classes.field_button}
                            style={{ backgroundColor: "#dbdbdb" }}
                        >
                            <Switch
                                checked={thisState.active_flag === "1"}
                                onChange={handleChangeToggle}
                                name="active_flag"
                            />
                        </Grid>
                        <GridDivider />
                        <Grid item xs={10} className={classes.field_helper}>
                            {thisState.status_undetermined
                                ? "変更が確定されていません"
                                : ""}
                        </Grid>
                        <Grid item xs={2} className={classes.field_button}>
                            <ButtonContainedPrimary
                                style={{ margin: "7px 0 7px" }}
                                fullWidth
                                type="submit"
                            >
                                変更
                            </ButtonContainedPrimary>
                        </Grid>
                    </Grid>
                    <TokenErrorDialog
                        open={thisState.open_token_error_dialog}
                        admin={true}
                        onClickOK={handleClickOK}
                    />

                    {thisState?.shop_user_list && (
                        <>
                            <p className={classes.section_title}>
                                店舗ユーザー情報
                            </p>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid
                                    item
                                    xs={4}
                                    className={classes.table_cell_top}
                                >
                                    名前
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className={classes.table_cell_top}
                                >
                                    メールアドレス
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    className={classes.table_cell_top}
                                >
                                    有効
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    className={classes.table_cell_top}
                                >
                                    削除
                                </Grid>
                            </Grid>
                            {thisState.shop_user_list.map((value, index) => (
                                <>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid
                                            item
                                            xs={4}
                                            className={classes.table_cell}
                                        >
                                            {value.nickname}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            className={classes.table_cell}
                                        >
                                            {value.email_address}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            className={classes.table_cell}
                                        >
                                            {value.active_flag == "1"
                                                ? "○"
                                                : "-"}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            className={classes.table_cell}
                                            style={{
                                                color: "red",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => DeleteUser(value)}
                                        >
                                            {/* {value.delete_flag == "1"
                                                ? "○"
                                                : "-"} */}
                                            {/* <button></button>
                                             */}
                                            削除
                                        </Grid>
                                    </Grid>
                                </>
                            ))}
                        </>
                    )}
                </form>
            </Container>
        </>
    );
};

export default AdminShopEditing;
