import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HttpFunction from "../components/utils/HttpFunction";

// Stateの初期状態
const initialState = {
    admin_user_list: [],
};

export const GetAdminUserList = createAsyncThunk(
    "AdminUser/GetAdminUserList",
    async (arg, thunkAPI) => {
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.admin.admin_token;

        const params = arg;
        const url = "/api/admin_user_list";

        const response = await axios.get(
            url + "?" + new URLSearchParams(params),
            {
                headers: {
                    "API-KEY": api_key,
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            }
        );

        return response.data;
    }
);
export const CreateAdminUser = createAsyncThunk(
    "AdminUser/CreateAdminUser",
    async (arg, thunkAPI) => {
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.admin.admin_token;

        const params = arg;
        const url = "/api/create_admin_user";

        const response = await axios.post(url, params, {
            headers: {
                "API-KEY": api_key,
                "Content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector(
                    'meta[name="csrf-token"]'
                ).content,
            },
        });

        return response.data;
    }
);
export const DeleteAdminUser = createAsyncThunk(
    "AdminUser/DeleteAdminUser",
    async (arg, thunkAPI) => {
        const informationState = thunkAPI.getState().Information;
        const api_key = informationState.admin.admin_token;

        const params = arg;
        const url = "/api/delete_admin_user";

        const response = await axios.delete(
            url + "?" + new URLSearchParams(params),
            {
                headers: {
                    "API-KEY": api_key,
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            }
        );

        return response.data;
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "AdminUser",
    initialState,
    reducers: {
        initialize: () => initialState,
        set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];

            if (type) {
                state[type][name] = value;
            } else {
                state[name] = value;
            }
        },
    },
    extraReducers: {
        [GetAdminUserList.fulfilled]: (state, action) => {
            const payload = action.payload;
            state.admin_user_list = payload.admin_user_list;
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_value, initialize } = slice.actions;
