import React, { useEffect } from "react";

import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
    DataGrid,
    GridToolbarContainer,
    useGridSlotComponentProps,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarColumnsButton,
} from "@material-ui/data-grid";
import jaJPDataGrid from "../../modules/JaJPDataGrid";

import { GetChatList } from "../../../stores/TALENTBANK/Chat";

import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

// import {
//     format,
//     formatDistance,
//     formatRelative,
//     subDays,
//     parse,
//     differenceInYears,
// } from "date-fns";
import ja from "date-fns/locale/ja";

const ChatList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const path = history.location.pathname.split("/");
    const company_id = path[2];
    const ChatState = useSelector((state) => state.Chat);
    const chat_list = ChatState.chat_list;
    const chat_list_info = ChatState.chat_list_info;

    // const history_list = AdminShopUsageHistoryState.history_list;
    // const [shop_id, set_shop_id] = React.useState("S0000000");

    const DetailClick = (row) => {
        history.push(
            `/Talent/ChatSerch?company_id=${company_id}&talent_id=${row?.talent_id}`
        );
    };

    const columns = [
        { field: "talent_id", headerName: "タレントID", width: 180 },
        //     { field: "user_id", headerName: "user_id", width: 130 },
        {
            field: "talent_name",
            headerName: "タレント名",
            width: 130,
            hide: false,
        },
        {
            field: "message",
            headerName: "メッセージ",
            flex: 1,
            valueGetter: (params) =>
                params.row?.message ? params.row?.message : "画像を送信",
        },

        {
            field: "詳細",
            headerName: "",
            width: 100,
            editable: false,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => DetailClick(params.row)}
                >
                    詳細
                </Button>
            ),
        },
    ];

    useEffect(() => {
        // dispatch(initialize());
        console.log({ page: chat_list_info.page });
        const page =
            ChatState.company_id == company_id
                ? Number(chat_list_info.page)
                : 1;

        const params = {
            page: page,
            company_id: company_id,
        };
        dispatch(GetChatList(params));
    }, []);

    const onPageChange = (page) => {
        console.log(chat_list_info.page);

        const params = {
            company_id: company_id,
            page: Number(page) + 1,
        };
        dispatch(GetChatList(params));
    };

    return (
        <React.Fragment>
            <Container>
                <ButtonNavi left_label="戻る" left_link={`/SponsorSearch`} />
                <h1>トークリスト</h1>

                <div style={{ height: 640, width: "100%" }}>
                    <DataGrid
                        getRowId={(row) => row.talent_id}
                        rows={chat_list}
                        columns={columns}
                        pageSize={10}
                        // rowsPerPageOptions={[5]}
                        // checkboxSelection
                        disableSelectionOnClick
                        onPageChange={onPageChange}
                        paginationMode="server"
                        page={chat_list_info.page - 1}
                        rowCount={chat_list_info.total}
                        localeText={jaJPDataGrid}
                        // AdminShopUsageHistoryState.lastPage

                        //
                    />
                </div>
            </Container>
        </React.Fragment>
    );
};

export default ChatList;
