import React from "react";
import Form from "react-bootstrap/Form";
import TextField from "@material-ui/core/TextField";

const SelectNoticeType = (props) => {
    return (
        <>
            <TextField
                fullWidth
                select
                size="small"
                name="notification_category"
                variant="outlined"
                SelectProps={{
                    native: true,
                }}
                {...props}
            >
                <option value={1}>通知一覧</option>
                <option value={2}>ポップアップ通知</option>
                {/* <option value={3}>既読制御ポップアップ通知</option> */}
            </TextField>
        </>
    );
};

export default SelectNoticeType;
