import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { DataGrid } from "@material-ui/data-grid";
import {
    GetBlogListGeneral,
    PostBlogShop,
    DeleteBlogShop,
    GetBlogListShop,
} from "~stores/Blog";
import { set_value } from "~stores/Information";
import Modal from "@material-ui/core/Modal";

import ReactImageBase64 from "react-image-base64";
import {
    FormatTime,
    ParseTime,
    FormatOpeningHours,
} from "~/functions/DateTimeFunctions";
import Pagination from "@material-ui/lab/Pagination";

const BlogList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const BlogState = useSelector((state) => state.Blog);
    const InformationState = useSelector((state) => state.Information);
    const [add_flag, set_add_flag] = useState(false);
    const [shop_id, set_shop_id] = useState("");
    const [blog_image, set_blog_image] = useState("");
    const search = useLocation().search;

    // const HandleImageClick = (data, name) => {
    //     set_blog_image(data.fileData);
    // };
    useEffect(() => {
        const query = new URLSearchParams(search);

        //const query_shop_id = query.get("shop_id");
        const page = query.get("page");
        // if (!query_shop_id) {
        //     dispatch(GetBlogListGeneral({ page: page }));
        //     return;
        // }
        // if (query_shop_id == "null") {
        //     dispatch(GetBlogListGeneral({ page: page }));
        //     return;
        // }
        // set_shop_id(query_shop_id);

        // const params = {
        //     page: page,
        //     shop_id: shop_id,
        // };

        // if (InformationState?.shop?.shop_login) {
        //     dispatch(
        //         GetBlogListShop({ ...params, serch_shop_id: query_shop_id })
        //     ).then((data) => {
        //         if (data?.payload?.shop_id) {
        //             data.payload.shop_id;

        //             dispatch(
        //                 set_value(["shop_id", data.payload.shop_id, "shop"])
        //             );
        //         }
        //     });
        // } else {
        //     dispatch(GetBlogListGeneral(params));
        // }

        dispatch(GetBlogListGeneral({ page: page }));
    }, []);

    // const HandleDeleteBlog = (row) => {
    //     const result = confirm("削除しますか?");
    //     if (!result) return;
    //     const params = {
    //         page: BlogState.currentPage,
    //         shop_id: shop_id,
    //         blog_id: row.id,
    //     };
    //     dispatch(DeleteBlogShop(params));
    // };

    // const HandleCreate = (e) => {
    //     e.preventDefault();
    //     const elem = e.target.elements;
    //     const params = {
    //         blog_image: blog_image,
    //         page: BlogState.currentPage,
    //         create_data: {
    //             text: elem?.text?.value,
    //             title: elem?.title?.value,
    //             shop_id: shop_id,
    //         },
    //     };

    //     dispatch(PostBlogShop(params)).then((data) => {
    //         if (data.payload.result != "OK") return;
    //         document.create_blog_form.reset();
    //         set_blog_image("");
    //         set_add_flag(false);
    //     });
    // };

    const onPageChange = (event, page) => {
        const query = new URLSearchParams(search);

        const query_shop_id = query.get("shop_id");

        const params = {
            page: page,
            shop_id: shop_id,
        };

        dispatch(
            GetBlogListGeneral({ ...params, serch_shop_id: query_shop_id })
        ).then((data) => {
            scrollTo(0, 0);
        });

        // dispatch(
        //     GetBlogListShop({ ...params, serch_shop_id: query_shop_id })
        // ).then((data) => {
        //     scrollTo(0, 0);
        // });
    };

    return (
        <React.Fragment>
            {/* {InformationState.shop.shop_login &&
            shop_id &&
            shop_id == InformationState.shop.shop_id ? (
                <ButtonNavi
                    left_label="戻る"
                    left_props={{
                        // onClick: () => history.goBack(),
                        onClick: () => history.goBack(),
                        // onClick: () => history.push(`/ShopStoreApplication`),
                    }}
                    right_label="投稿"
                    right_props={{ onClick: () => set_add_flag(true) }}
                />
            ) : (
                <ButtonNavi
                    left_label="戻る"
                    left_props={{
                        onClick: () => history.goBack(),
                        // onClick: () =>
                        //     history.push(`/ShopInformation/${shop_id}`),
                    }}
                />
            )} */}
            <ButtonNavi
                left_label="戻る"
                left_props={{
                    onClick: () => history.goBack(),
                }}
            />

            <h1>ブログ</h1>

            <Modal
                open={add_flag}
                onClose={() => {
                    set_add_flag(false);
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{
                        border: "solid 1px",
                        padding: "0rem 2rem",
                        margin: "2%",
                        width: "94%",
                        background: "#fff",
                        overflowY: "scroll",
                        height: "98vh",
                    }}
                >
                    <>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Button onClick={() => set_add_flag(false)}>
                                閉じる
                            </Button>
                        </Grid>
                        {/* <form
                            onSubmit={HandleCreate}
                            autoComplete="off"
                            name="create_blog_form"
                            key="1"
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                            >
                                <TextField
                                    style={{ marginBottom: "1rem" }}
                                    fullWidth
                                    label="タイトル"
                                    required
                                    name="title"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                ></TextField>

                                <ImageInput
                                    image={blog_image}
                                    HandleImageClick={HandleImageClick}
                                    name="blog_image"
                                />
                                <TextField
                                    style={{ marginBottom: "1rem" }}
                                    fullWidth
                                    label="本文"
                                    required
                                    multiline
                                    minRows={5}
                                    name="text"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                ></TextField>
                                <Button variant="outlined" type="submit">
                                    投稿
                                </Button>
                            </Grid>
                        </form> */}
                    </>
                </Grid>
            </Modal>
            {/* ) : (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Button
                        color="primary"
                        variant="contained"
                        style={{ padding: "0.5rem 1rem" }}
                        onClick={() => set_add_flag(true)}
                    >
                        追加
                    </Button>
                </Grid>
            )}
             */}

            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                style={{ padding: "1rem", background: "#ccc" }}
                id="BlogListTable"
            >
                {BlogState.blog_list.map((blog, index) => (
                    <React.Fragment key={index}>
                        <Grid
                            container
                            // direction="row"
                            // justifyContent="space-evenly"
                            // alignItems="flex-start"
                            style={{
                                background: "#FFF",
                                border: "solid 1px",
                                borderRadius: "1rem",
                                padding: "1rem",
                                marginBottom: "1rem",
                                maxWidth: "370px",
                            }}
                            spacing={3}
                        >
                            <Grid
                                item
                                sx={3}
                                onClick={() => {
                                    history.push(`/Blog?blog_id=${blog.id}`);
                                }}
                            >
                                <img
                                    src={blog.image_path}
                                    width="100px"
                                    height="80px"
                                />
                            </Grid>

                            <Grid item sx={9}>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="space-around"
                                    alignItems="flex-start"
                                >
                                    {/* {InformationState.shop.shop_login &&
                                    shop_id &&
                                    shop_id == InformationState.shop.shop_id ? (
                                        <Button
                                            onClick={() =>
                                                HandleDeleteBlog(blog)
                                            }
                                            style={{
                                                marginRight: "-2rem",
                                                marginTop: "-1.5rem",
                                                marginLeft: "auto",
                                            }}
                                            color="secondary"
                                        >
                                            削除
                                        </Button>
                                    ) : (
                                        ""
                                    )} */}
                                    <Grid
                                        item
                                        style={{
                                            fontSize: "1rem",
                                            fontWeight: "bold",
                                            width: "180px",
                                        }}
                                        onClick={() => {
                                            history.push(
                                                `/Blog?blog_id=${blog.id}`
                                            );
                                        }}
                                    >
                                        {blog.title}
                                    </Grid>
                                    <Grid item style={{ fontSize: "0.8rem" }}>
                                        投稿日{" "}
                                        {FormatTime(
                                            ParseTime(blog.updated_at),
                                            "jp_date_time"
                                        )}
                                    </Grid>
                                    <Grid item style={{ fontSize: "0.8rem" }}>
                                        投稿者{" "}
                                        {blog.nickname}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                ))}

                <Pagination
                    count={BlogState.lastPage}
                    variant="outlined"
                    page={BlogState.currentPage}
                    color="secondary"
                    onChange={onPageChange}
                />
            </Grid>
        </React.Fragment>
    );
};

const ImageInput = (props) => {
    const { image, HandleImageClick, name } = props;

    return (
        <Grid
            direction="column"
            justifyContent="center"
            alignItems="center"
            container
        >
            <label
                htmlFor={`image_upload_${name}`}
                style={{ marginBottom: "0" }}
            >
                <img
                    src={image ? image : "null"}
                    height="300px"
                    style={{
                        margin: "1rem 0rem",
                        width: "100%",
                        height: "100%",
                    }}
                    onError={(e) => {
                        e.target.onerror = "null";
                        e.target.src = "/images/upload.png";
                    }}
                />
            </label>
            {/* <Input type="file" id="image_upload" name="image" onChange={HandleChange} hidden /> */}
            {/* <img src={images?.fileData} /> */}
            <div style={{ display: "none" }}>
                <ReactImageBase64
                    maxFileSize={10485760}
                    thumbnail_size={500}
                    drop={false}
                    hidden={true}
                    // capture="environment"
                    multiple={true}
                    id={`image_upload_${name}`}
                    name={name}
                    handleChange={(data) => HandleImageClick(data, name)}
                />
            </div>
        </Grid>
    );
};

export default BlogList;
