import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import MascotInfo from "../modules/MascotInfo";
import {ButtonContainedPrimary} from "../utils/Buttons";
import { errorJudge } from "../utils/TextFields";
import {ButtonNavi} from "~CJS/block/ButtonNavi";
import { CreateMascot, set_admin_mascot } from "../../stores/AdminMascot";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            padding: "15px",
            width: "350px",
        },
    },
}));

const AdminMascotNew = () => {
    const thisState = useSelector((state) => state.AdminMascot);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const toggleActive = useRef(null);

    // 文字数以外のバリデーションがある項目のエラーチェック用変数
    const [error, setError] = useState({
        mascot_name: null,
        mascot_point: false,
        draw_title: null,
    });
    
    // バリデーションエラーがtrue/falseの際に、エラーチェック用変数の値を更新
    const trueError = (inputId) => {
        setError({ ...error, [inputId]: true });
    };
    const falseError = (inputId) => {
        setError({ ...error, [inputId]: false });
    };

    // 登録ボタン押下時
    const handleClickCreate = () => {
        var date = new Date();
        date.setDate(date.getDate() + 1);
        const release_start_date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00:00`;
        const active_flag = toggleActive.current.checked ? "0" : "1";
        const params = {
            mascot_name: thisState.mascot_name, 
            mascot_image_big: thisState.mascot_image_big, 
            mascot_image_small: thisState.mascot_image_small, 
            mascot_point: thisState.mascot_point,
            draw_title: thisState.draw_title,
            draw_detail: thisState.draw_detail,
            mascot_url: thisState.mascot_url,
            memo: thisState.memo,
            control_kubun: thisState.control_kubun,
            release_start_date: release_start_date,
            release_end_date: thisState.release_end_date,
            active_flag: active_flag,
        };
        dispatch(CreateMascot(params));
    };

    // 登録結果表示ダイアログを閉じる
    const handleCloseDialog = () => {
        if (thisState.open_success) {
            dispatch(set_admin_mascot(["open_success", false]));
            // 登録成功時は一覧に戻す
            dispatch(set_admin_mascot(["back_flag", true]));
            history.push("/AdminMascotList");
        } else {
            dispatch(set_admin_mascot(["open_failure", false]));
        }
    };

    const handleClickCancel = () => {
        dispatch(set_admin_mascot(["back_flag", true]));
    };

    return (
        <>
            <ButtonNavi 
                left_label="キャンセル"
                left_link="/AdminMascotList"
                left_props={{
                    onClick: handleClickCancel
                }}
            />

            <h1>ログインボーナス登録</h1>

            <MascotInfo trueError={trueError} falseError={falseError} toggleRef={toggleActive} />

            <ButtonContainedPrimary
                disabled={errorJudge(error) || (thisState.mascot_image_small === "")}
                onClick={handleClickCreate} 
                style={{display: "block", margin: "auto"}}
            >
                登録
            </ButtonContainedPrimary>

            {/* 新規登録の結果表示ダイアログ */}
            <Dialog
                open={thisState.open_success || thisState.open_failure}
                onClose={handleCloseDialog}
                className={classes.dialog}
            >
                {thisState.open_success && (
                    <>
                        <p>登録完了</p>
                        <p>マスコットは明日からアクセスボーナスに登場します。</p>
                    </>
                )}
                {thisState.open_failure && (
                    <>
                        <p>登録できませんでした。</p>
                        <p>{thisState.error_message}</p>
                    </>
                )}
            </Dialog>
        </>
    );
};


export default AdminMascotNew;
