import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import CameraAltIcon from "@material-ui/icons/CropFree";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

const useStyles = makeStyles({
    root: {
        width: "100%",
        position: "fixed",
        bottom: 0,
        paddingBottom: "0.5rem",
        "& .MuiBottomNavigationAction-root": {
            "@media (max-width: 768px)": {
                minWidth: "auto",
                padding: "6px 0",
            },
        },
        "& .Mui-selected": {
            flex: "1",
            color: "rgba(0, 0, 0, 0.54)",
            padding: "6",
            fontSize: "0.75rem",
        },
    },
});

export const HideFooter = () => {
    if (
        navigator.userAgent.indexOf("iPhone") != -1 ||
        navigator.userAgent.indexOf("Android") != -1 ||
        navigator.userAgent.indexOf("iPad") != -1
    ) {
        document.getElementsByClassName(
            "MuiBottomNavigation-root"
        )[0].style.display = "none";
    }
};

export const ShowFooter = () => {
    if (
        document.getElementsByClassName("MuiBottomNavigation-root")[0] &&
        document.getElementsByClassName("MuiBottomNavigation-root")[0].style
            .display == "none"
    ) {
        document.getElementsByClassName(
            "MuiBottomNavigation-root"
        )[0].style.display = "flex";
    }
};

export default function SimpleBottomNavigation() {
    const informationState = useSelector((state) => state.Information);
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    // 権限によるタブメニューの表示制御//////////////////

    // テスト用
    if (
        informationState.admin.admin_login &&
        informationState.user.user_login &&
        informationState.shop.shop_login
    ) {
        return (
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                showLabels
                className={classes.root}
            >
                <BottomNavigationAction
                    component={Link}
                    to="/"
                    label="ホーム"
                    icon={<HomeIcon />}
                />
                <BottomNavigationAction
                    component={Link}
                    to="/QRCodeDisplay"
                    label="会員証"
                    icon={<CreditCardIcon />}
                />
                <BottomNavigationAction
                    component={Link}
                    to="/ShopList"
                    label="検索"
                    icon={<SearchIcon />}
                />
                <BottomNavigationAction
                    component={Link}
                    to="/FavoriteShop"
                    label="お気に入り"
                    icon={<FavoriteIcon />}
                />
                <BottomNavigationAction
                    component={Link}
                    to="/GeneralFriendInvitation"
                    label="友達招待"
                    icon={<ContactMailIcon />}
                />
                {/* <BottomNavigationAction
                    component={Link}
                    to="/QRReading"
                    label="QRコード読み取り"
                    icon={<CameraAltIcon />}
                /> */}
            </BottomNavigation>
        );

        // 一般会員ログイン済み
    } else if (informationState.user.user_login) {
        return (
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                showLabels
                className={classes.root}
            >
                <BottomNavigationAction
                    component={Link}
                    to="/"
                    label="ホーム"
                    icon={<HomeIcon />}
                />
                <BottomNavigationAction
                    component={Link}
                    to="/QRCodeDisplay"
                    label="会員証"
                    icon={<CreditCardIcon />}
                />
                <BottomNavigationAction
                    component={Link}
                    to="/ShopList"
                    label="検索"
                    icon={<SearchIcon />}
                />
                <BottomNavigationAction
                    component={Link}
                    to="/FavoriteShop"
                    label="お気に入り"
                    icon={<FavoriteIcon />}
                />
                <BottomNavigationAction
                    component={Link}
                    to="/GeneralFriendInvitation"
                    label="友達招待"
                    icon={<ContactMailIcon />}
                />
            </BottomNavigation>
        );

        // 加盟店ログイン済み
    } else if (informationState.shop.shop_login) {
        return (
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                showLabels
                className={classes.root}
            >
                <BottomNavigationAction
                    component={Link}
                    to="/ShopStoreApplication"
                    label="ホーム"
                    icon={<HomeIcon />}
                />
                {/* <BottomNavigationAction
                    component={Link}
                    to="/QRReading"
                    label="QRコード読み取り"
                    icon={<CameraAltIcon />}
                /> */}
            </BottomNavigation>
        );

        // 管理者ログイン済み
    } else if (informationState.admin.admin_login) {
        return (
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                showLabels
                className={classes.root}
            ></BottomNavigation>
        );

        // 未ログイン(一般/加盟店)
    } else {
        return (
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                showLabels
                className={classes.root}
            >
                <BottomNavigationAction
                    component={Link}
                    to="/"
                    label="ホーム"
                    icon={<HomeIcon />}
                />
                <BottomNavigationAction
                    component={Link}
                    to="/ShopList"
                    label="検索"
                    icon={<SearchIcon />}
                />
            </BottomNavigation>
        );
    }

}
