import React, { useState, useEffect } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "react-bootstrap/Table";
import Image from "react-bootstrap/Image";
import { useHistory } from "react-router-dom";
import ButtonGeneral from "../utils/ButtonGeneral";
import Grid from "@material-ui/core/Grid";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { shop_info_set_value, GetShopData } from "../../stores/ShopInformation";
import { set_value } from "../../stores/Information";
import SnapList from "../modules/SnapList";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Favorite from "@material-ui/icons/Favorite";
import Checkbox from "@material-ui/core/Checkbox";
import HttpFunction from "../utils/HttpFunction";
import PinDrop from "@material-ui/icons/PinDrop";
import SelectTarget from "../utils/SelectTarget";
import ShopImageSlide from "../utils/ShopImageSlide";
import HalfPriceMenu from "../utils/HalfPriceMenu";
import TweetList from "../modules/TweetList";
import Box from "@material-ui/core/Box";
import PinDropIcon from "@material-ui/icons/PinDrop";
import { shoplist_set } from "../../stores/Shoplist";
import Rating from "@material-ui/lab/Rating";

const ShopInformation = () => {
    const history = useHistory();
    const path = history.location.pathname.split("/");
    const shop_id = path[2];
    const informationState = useSelector((state) => state.Information);
    const api_key = informationState.user.token;

    const [select, set_select] = React.useState("1");

    const HandleSelect = (val) => {
        set_select(val);
    };
    if (shop_id == "undefined") {
        history.push("/Errer");
    }

    const thisState = useSelector((state) => state.ShopInformation);
    const dispatch = useDispatch();
    const favorite_flag =
        thisState.shop_data.favorite_delete === "0" &&
            thisState.shop_data.favorite_active === "0"
            ? true
            : false;

    const disabled_navigation = path[3];

    if (disabled_navigation == "disabled_navigation") {
        dispatch(set_value(["showDrawer", false]));
        dispatch(set_value(["showBottomNavigation", false]));
    }

    let csrf_token = document.head.querySelector(
        'meta[name="csrf-token"]'
    ).content;

    const OnBack = () => {
        history.goBack();
    };

    const OpenMap = () => {
        // if (typeof flutter === "undefined") {
        //     alert("フィフティーカードアプリでご利用ください。");
        // } else {
        //     // console.log("flutter");
        //     let shoplists = [];
        //     let shoplist = {
        //         name: thisState.shop_data.shop_name,
        //         latitude: thisState.shop_data.latitude,
        //         longitude: thisState.shop_data.longitude,
        //     };
        //     shoplists.push(shoplist);
        //     window.flutter_inappwebview.callHandler("MapPage", shoplists);
        // }
        let shoplists = [];
        let shoplist = {
            ...thisState.shop_data,
            ...(thisState.shop_data?.menu_data_list
                ? thisState.shop_data.menu_data_list[0]
                : []),
            ...(thisState.shop_data?.tweet_list
                ? thisState.shop_data.tweet_list[0]
                : []),
        };
        shoplists.push(shoplist);
        dispatch(shoplist_set(["shop_list_data", shoplists || ""]));
        history.push("/Map");
    };
    useEffect(() => {
        dispatch(GetShopData(shop_id));
    }, []);

    const OnFavorite = (e) => {
        if (!api_key) return;
        const params = {
            shop_id: shop_id,
        };
        const type = favorite_flag ? "delete_favorite" : "add_favorite";
        HttpFunction("POST", "/api/" + type, params, api_key)
            .then((data) => {
                dispatch(GetShopData(shop_id));
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                style={{
                    position: "fixed",
                    marginTop: "-8.5px",
                    background: "white",
                    zIndex: "10",
                }}
            >
                {disabled_navigation == "disabled_navigation" ? (
                    ""
                ) : (
                    <IconButton
                        onClick={OnBack}
                    // style={{ padding: "8px 0 24px" }}
                    >
                        <ChevronLeftIcon />
                        {/* <span style={{ fontSize: "70%" }}></span> */}
                    </IconButton>
                )}
                <Grid
                    item
                    xs
                    style={{
                        display: "grid",
                    }}
                >
                    <Typography
                        variant="h1"
                        style={{
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: "2",
                            padding: "0px",
                        }}
                        // noWrap
                        align="center"
                    >
                        {thisState.shop_data.shop_name}
                    </Typography>
                </Grid>
                <Grid item style={{ width: "5.2rem", paddingTop: "0.8rem" }}>
                    {informationState.user.user_login ? (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={favorite_flag}
                                    icon={<FavoriteBorder />}
                                    checkedIcon={<Favorite />}
                                    className="favorite-icon"
                                    onChange={OnFavorite}
                                />
                            }
                            label={
                                <Typography style={{ fontSize: "0.5rem" }}>
                                    お気に入り
                                </Typography>
                            }
                            labelPlacement="bottom"
                        />
                    ) : (
                        ""
                    )}
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                style={{ marginTop: "5rem" }}
            >
                <Grid item xs={1}></Grid>
                <Grid item xs={7}>
                    {(thisState.shop_data?.closest_station_1 ||
                        thisState.shop_data?.closest_traffic_1) && (
                            <>
                                <PinDrop
                                    style={{ color: "red", fontSize: "0.8rem" }}
                                />
                                {thisState.shop_data.closest_traffic_1}{" "}
                                {thisState.shop_data.closest_station_1}より{" "}
                                {thisState.shop_data.closest_means_1}
                                {thisState.shop_data.closest_station_minute_1}分
                                <br />
                            </>
                        )}
                    {(thisState.shop_data?.closest_station_2 ||
                        thisState.shop_data?.closest_traffic_2) && (
                            <>
                                <PinDrop
                                    style={{ color: "red", fontSize: "0.8rem" }}
                                />
                                {thisState.shop_data?.closest_traffic_2}{" "}
                                {thisState.shop_data?.closest_station_2}より{" "}
                                {thisState.shop_data.closest_means_2}
                                {thisState.shop_data?.closest_station_minute_2}分
                                <br />
                            </>
                        )}
                    {(thisState.shop_data?.closest_station_3 ||
                        thisState.shop_data?.closest_traffic_3) && (
                            <>
                                <PinDrop
                                    style={{ color: "red", fontSize: "0.8rem" }}
                                />
                                {thisState.shop_data?.closest_traffic_3}{" "}
                                {thisState.shop_data?.closest_station_3}より{" "}
                                {thisState.shop_data.closest_means_3}
                                {thisState.shop_data?.closest_station_minute_3}分
                            </>
                        )}
                </Grid>
                <Grid item xs={4}>
                    <SelectTarget
                        view={true}
                        value={thisState.shop_data?.target}
                    />
                </Grid>{" "}
            </Grid>

            <div style={{ margin: "1rem" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                ></Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="flex-start"
                >
                    {/* <Image
                        src={
                            thisState.shop_data.shop_image_big
                                ? thisState.shop_data.shop_image_big
                                : "null"
                        }
                        fluid
                        onError={(e) => {
                            e.target.onerror = "null";
                            e.target.src = "/images/no_img.png";
                        }}
                    /> */}

                    <ShopImageSlide
                        image_list={[
                            thisState.shop_data?.shop_image_small,
                            thisState.shop_data?.shop_image_2,
                            thisState.shop_data?.shop_image_3,
                            thisState.shop_data?.shop_image_4,
                            thisState.shop_data?.shop_image_5,
                        ]}
                    />
                </Grid>
                <ShopComment thisState={thisState} />
                <TweetList tweet_list={thisState?.shop_data?.tweet_list} />

                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    style={{ marginBottom: "1rem" }}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => HandleSelect("1")}
                        disabled={select == "1"}
                    >
                        店舗情報
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => HandleSelect("2")}
                        disabled={select == "2"}
                    >
                        メニュー
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => HandleSelect("3")}
                        disabled={select == "3"}
                    >
                        スナップ
                    </Button>
                </Grid>

                {select == "1" && (
                    <>
                        <ShopInfo thisState={thisState} />
                        <BusinessHours thisState={thisState} />
                        <Divider
                            style={{
                                marginTop: "0.5rem",
                                marginBottom: "0.5rem",
                            }}
                        />
                        <GridTable
                            title={"備考"}
                            name={"remarks"}
                            content={thisState.shop_data["remarks"]}
                            shop_data={thisState.shop_data}
                        />
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <AccessMap
                                thisState={thisState}
                                OpenMap={OpenMap}
                            />

                            <Box component="span" m={1}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => HandleSelect("4")}
                                >
                                    レビューを見る
                                </Button>
                            </Box>
                        </Grid>
                    </>
                )}

                {select == "2" && (
                    <>
                        <CouponMenu thisState={thisState} />

                        <React.Fragment>
                            {thisState.shop_data?.menu_data_list?.length
                                ? thisState.shop_data?.menu_data_list.map(
                                    (menu_data, index) => (
                                        <React.Fragment key={index}>
                                            {/* {index} */}
                                            <HalfPriceMenu
                                                menu_data={menu_data}
                                            />
                                        </React.Fragment>
                                    )
                                )
                                : ""}
                        </React.Fragment>
                    </>
                )}

                {select == "3" && (
                    <>
                        {/* <h2>スナップ一覧</h2> */}
                        <SnapList shop_id={shop_id} />
                    </>
                )}

                {select == "4" && (
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {thisState.shop_data?.review_list.map((data, index) => (
                            <React.Fragment key={index}>
                                <Box
                                    component="span"
                                    m={1}
                                    style={{
                                        border: "1px solid rgb(0, 0, 0)",
                                        width: "100%",
                                        padding: "1rem",
                                    }}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-around"
                                        alignItems="center"
                                    >
                                        <Box>
                                            {data.nickname
                                                ? data.nickname
                                                : "匿名"}
                                        </Box>
                                        <Rating value={data.review} readOnly />
                                    </Grid>


                                    <Box
                                        style={{
                                            padding: "1rem",
                                        }}
                                    >
                                        {data.review_text}
                                    </Box>

                                    <Box
                                        style={{
                                            fontSize: '12px',
                                            textAlign: 'right'
                                        }}
                                    >
                                        {data.created_at}

                                    </Box>
                                </Box>
                            </React.Fragment>
                        ))}
                    </Grid>
                )}
            </div>
        </React.Fragment>
    );
};

const ShopComment = (props) => {
    return (
        <React.Fragment>
            {/* <h2>加盟店メッセージ</h2> */}

            <div
                style={{
                    color: "hotpink",
                    textAlign: "center",
                    fontSize: "1rem",
                    marginTop: "1rem",
                }}
            >
                {props.thisState.shop_data.store_pr}
            </div>
            <div
                style={{
                    margin: "1rem 0rem",
                    // border: "solid 1px ",
                    borderRadius: "1rem",
                    padding: "1rem",
                }}
                className={"html-preview"}
                dangerouslySetInnerHTML={{
                    __html: props.thisState.shop_data.shop_explain
                        ? props.thisState.shop_data.shop_explain.replace(
                            /\n/g,
                            "<br>"
                        )
                        : "",
                }}
            ></div>
        </React.Fragment>
    );
};
const CouponMenu = (props) => {
    return (
        <React.Fragment>
            {/* <h2>fiftyメニュー</h2> */}
            <div
                className={"MenuText" + " " + "html-preview"}
                dangerouslySetInnerHTML={{
                    __html: props.thisState.shop_data.shop_menu
                        ? props.thisState.shop_data.shop_menu.replace(
                            /\n/g,
                            "<br>"
                        )
                        : "",
                }}
            ></div>
        </React.Fragment>
    );
};

const GridTable = (props) => {
    const { title, content, name, shop_data } = props;

    let value = content;
    if (name === "location") {
        const shop_prefectures = shop_data?.shop_prefectures
            ? shop_data.shop_prefectures
            : "";
        const shop_city = shop_data?.shop_city ? shop_data.shop_city : "";
        const shop_address1 = shop_data?.shop_address1
            ? shop_data.shop_address1
            : "";
        const shop_address2 = shop_data?.shop_address2
            ? shop_data.shop_address2
            : "";

        value =
            shop_prefectures +
            shop_city +
            "<br>" +
            shop_address1 +
            "<br>" +
            shop_address2;
    } else if (name === "shop_phone_number") {
        value = shop_data?.shop_phone_number
            ? "<a href=tel:" +
            shop_data.shop_phone_number +
            ">" +
            shop_data.shop_phone_number +
            "</a>"
            : "";
    }

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={1}
            >
                <Grid item xs={3}>
                    <b>{title}</b>
                </Grid>
                <Grid item xs={9}>
                    <div
                        className={"ShopInfo" + " " + "html-preview"}
                        dangerouslySetInnerHTML={{
                            __html: value ? value.replace(/\n/g, "<br>") : "",
                        }}
                    ></div>
                </Grid>
            </Grid>
            <Divider style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />
        </React.Fragment>
    );
};

const input_list = {
    shop_name: "店舗名",
    store_pr: "サブタイトル",
    access: "アクセス",
    location: "住所",
    business_hours: "営業時間",
    shop_holiday: "定休日",
    reservation: "予約方法",
    shop_url: "URL",
    shop_sns: "SNS",
    shop_phone_number: "電話番号",
    shop_staff: "担当者",
};
const ShopInfo = (props) => {
    return (
        <React.Fragment>
            {/* <h2>詳細</h2> */}

            {Object.keys(input_list).map((key) => (
                <React.Fragment key={key}>
                    <GridTable
                        title={input_list[key]}
                        name={key}
                        content={props.thisState.shop_data[key]}
                        shop_data={props.thisState.shop_data}
                    />
                </React.Fragment>
            ))}

            <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={1}
            >
                <Grid item xs>
                    <b>無料Wi-Fi</b>
                </Grid>
                <Grid item xs>
                    <div
                        className={"ShopInfo" + " " + "html-preview"}
                        dangerouslySetInnerHTML={{
                            __html:
                                props.thisState?.shop_data?.wifi === "1"
                                    ? "〇"
                                    : "×",
                        }}
                    ></div>
                </Grid>
            </Grid>
            <Divider style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />
        </React.Fragment>
    );
};

const AccessMap = (props) => {
    return (
        // <React.Fragment>
        //     <h2>AccessMap</h2>
        //     <ButtonGeneral onClick={props.OpenMap}>マップを開く</ButtonGeneral>
        // </React.Fragment>
        <Box component="span" m={1}>
            <Button
                variant="contained"
                color="secondary"
                onClick={props.OpenMap}
            >
                <PinDropIcon />
                MAPで表示
            </Button>
        </Box>
    );
};

const BusinessHours = (props) => {
    const { thisState } = props;
    const ApplicationData = thisState.shop_data;

    const list = [
        { label: "日曜日", name: "sunday" },
        { label: "月曜日", name: "monday" },
        { label: "火曜日", name: "tuesday" },
        { label: "水曜日", name: "wednesday" },
        { label: "木曜日", name: "thursday" },
        { label: "金曜日", name: "friday" },
        { label: "土曜日", name: "saturday" },
    ];

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
                border: "solid 1px",
                padding: "0.2rem",
                borderRadius: "0.5rem",
            }}
        >
            営業時間
            {list.map((val, index) => (
                <React.Fragment key={index}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                        spacing={1}
                        style={{
                            padding: "0.1rem",
                            textAlign: "center",
                        }}
                    >
                        <Grid item style={{ width: "5rem" }}>
                            {val.label}
                        </Grid>
                        <Grid item xs>
                            {!ApplicationData[`${val.name}_opening_time`] &&
                                !ApplicationData[`${val.name}_closing_time`] ? (
                                <>定休日</>
                            ) : (
                                <>
                                    {ApplicationData[`${val.name}_opening_time`]
                                        ?
                                        ApplicationData[`${val.name}_opening_time`].substring(0, 5)
                                        // ApplicationData[
                                        //     `${val.name}_opening_time`
                                        // ].substr(
                                        //     0,
                                        //     ApplicationData[
                                        //         `${val.name}_opening_time`
                                        //     ].length - 3
                                        // )
                                        : "不定"}
                                    ~
                                    {ApplicationData[`${val.name}_closing_time`]
                                        ?
                                        ApplicationData[`${val.name}_closing_time`].substring(0, 5)
                                        // ApplicationData[
                                        //       `${val.name}_closing_time`
                                        //   ].substr(
                                        //       0,
                                        //       ApplicationData[
                                        //           `${val.name}_closing_time`
                                        //       ].length - 3
                                        //   )
                                        : "不定"}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    );
};
export default ShopInformation;
