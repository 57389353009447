import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Stateの初期状態
const initialState = {
    error_message: "",
    before: {},
    slide_data: [],
};

export const GetSlideData = createAsyncThunk(
    "TALENT/HomeSetting/GetSlideData",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/talentbank_top_slide";

        try {
            const response = await axios.get(
                url,
                {
                    headers: {
                        "API-KEY": api_key,
                        "Content-Type": "application/json",
                        "X-CSRF-Token": document.head.querySelector(
                            'meta[name="csrf-token"]'
                        ).content,
                    },
                }
            );

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const UpdateSlideData = createAsyncThunk(
    "TALENT/HomeSetting/UpdateSlideData",
    async (arg, thunkAPI) => {
        const api_key = thunkAPI.getState().Information.admin.admin_token;
        const url = "/api/talentbank_top_slide_update";

        try {
            const response = await axios.put(url, arg, {
                headers: {
                    "API-KEY": api_key,
                    "content-Type": "application/json",
                    "X-CSRF-Token": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

// Sliceを生成する
const slice = createSlice({
    name: "HomeSetting",
    initialState,
    reducers: {
        initialize: () => initialState,
        set_value: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];

            if (type) {
                state[type][name] = value;
            } else {
                state[name] = value;
            }
        },
    },
    extraReducers: {
        [GetSlideData.fulfilled]: (state, action) => {
            if (action.payload.error_message) {
                state.error_message = [
                    "サーバーエラー",
                    action.payload.error_message,
                ];

                return;
            }
            for (let [key, value] of Object.entries(action.payload)) {
                state[key] = value;
                state.before[key] = value;
            }
        },
        [GetSlideData.rejected]: (state, action) => {
            state.error_message = [
                "サーバーエラー",
                "設定情報の取得に失敗しました",
            ];
        },
        [UpdateSlideData.fulfilled]: (state, action) => {
            if (action.payload.result == 1) {
                alert("設定しました。");
                return;
            }
            if (action.payload.error_message) {
                state.error_message = [
                    "サーバーエラー",
                    action.payload.error_message,
                ];

                return;
            }
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { set_value, initialize } = slice.actions;
