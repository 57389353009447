
const jaJPDataGrid = {
    // Root
    noRowsLabel: '行がありません',
    noResultsOverlayLabel: '条件に当てはまるものがありません。',
    errorOverlayDefaultLabel: 'エラーが発生しました。',

    // Density selector toolbar button text
    toolbarDensity: '行間隔',
    toolbarDensityLabel: '行間隔',
    toolbarDensityCompact: 'コンパクト',
    toolbarDensityStandard: '標準',
    toolbarDensityComfortable: 'ひろめ',

    // Columns selector toolbar button text
    toolbarColumns: '表示列選択',
    toolbarColumnsLabel: '表示列選択',

    // Filters toolbar button text
    toolbarFilters: '更に絞り込み',
    toolbarFiltersLabel: 'フィルター表示',
    toolbarFiltersTooltipHide: 'フィルター非表示',
    toolbarFiltersTooltipShow: 'フィルター表示',
    toolbarFiltersTooltipActive: (count) => `${count}件のフィルターを適用中`,

    // Export selector toolbar button text
    toolbarExport: 'エクスポート',
    toolbarExportLabel: 'エクスポート',
    toolbarExportCSV: 'CSVダウンロード',

    // Columns panel text
    columnsPanelTextFieldLabel: '列検索',
    columnsPanelTextFieldPlaceholder: '検索条件を入力...',
    columnsPanelDragIconLabel: '列並べ替え',
    columnsPanelShowAllButton: 'すべて表示',
    columnsPanelHideAllButton: 'すべて非表示',

    // Filter panel text
    filterPanelAddFilter: 'フィルター追加',
    filterPanelDeleteIconLabel: '削除',
    filterPanelOperators: '条件',
    filterPanelOperatorAnd: 'And',
    filterPanelOperatorOr: 'Or',
    filterPanelColumns: '列',
    filterPanelInputLabel: '値',
    filterPanelInputPlaceholder: '値を入力...',

    // Filter operators text
    filterOperatorContains: '...を含む',
    filterOperatorEquals: '...に等しい',
    filterOperatorStartsWith: '...で始まる',
    filterOperatorEndsWith: '...で終わる',
    filterOperatorIs: '...である',
    filterOperatorNot: '...でない',
    filterOperatorAfter: '...より後ろ',
    filterOperatorOnOrAfter: '...以降',
    filterOperatorBefore: '...より前',
    filterOperatorOnOrBefore: '...以前',
    filterOperatorIsEmpty: '空白',
    filterOperatorIsNotEmpty: '空白でない',

    // Column menu text
    columnMenuLabel: 'メニュー',
    columnMenuShowColumns: '表示列選択',
    columnMenuFilter: 'フィルター',
    columnMenuHideColumn: 'この列を非表示にする',
    columnMenuUnsort: '並び替え解除',
    columnMenuSortAsc: '昇順',
    columnMenuSortDesc: '降順',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) => `${count}件のフィルターを適用中`,
    columnHeaderFiltersLabel: 'フィルター表示',
    columnHeaderSortIconLabel: '並び替え',

    // Rows selected footer text
    footerRowSelected: (count) => `${count}行を選択中`,

    // Total rows footer text
    footerTotalRows: '総行数:',
};

export default jaJPDataGrid;