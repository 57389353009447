import React, { useEffect } from "react";
import { StopButton, MailInput, OneTimeInput } from "../modules/Login";
import { login_set_value, initialize } from "../../stores/Login";
import { set_value , UserInfoGet} from "../../stores/Information";
import { user_info_set } from "../../stores/UserInfoChange";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import { ButtonTextPrimary, ButtonContainedPrimary } from "../utils/Buttons";
import { TextFieldValidation } from "../utils/TextFields";
import HttpFunction from "../utils/HttpFunction";
import Error500Handling from "../utils/Error500Handling";
import SetUserTokenFlutter from "../utils/SetUserTokenFlultter";
import ErrorDialog from "../utils/ErrorDialog";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { shoplist_set } from "../../stores/Shoplist";

const useStyles = makeStyles({
    button: {
        display: "block",
        margin: "auto",
    },
});


const PasswordInput = () => {
    const classes = useStyles();
    const thisState = useSelector((state) => state.Login);
    const dispatch = useDispatch();
    const history = useHistory();
    const [password, setPassword] = React.useState("");
    const [disable, setDisable] = React.useState(false);
    const [open500, setOpen500] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const setLogin = () => {
        dispatch(set_value(["user_login", true , "user"]));
        console.log("LOGIN<E<BERS.JS USER LOGIN!");
        // 開発用ローカル対応
        if (process.env.MIX_APP_ENV === "local") {
            history.push("/");
        }
    }

    const setUserInfo = (user_token) => {
        // console.log("setUserInfo!!");
        // console.log("user_token:" +user_token);
        const api_key = user_token;
        // console.log("api_key:"+api_key);
        if (api_key) {
            // console.log("token:" + api_key);
            const type = "user";
            dispatch(UserInfoGet(api_key))
                .then((data) => {
                    if(data.payload.data.error_message){
                        // console.log("error_message!");
                        setOpen(true);
                    } else {
                        dispatch(shoplist_set(["serch_area", data.payload.data.prefectures]));
                        SetUserTokenFlutter(api_key, type);
                        setLogin();
                    }
                }).catch((e) =>{
                    console.log("error:"+e);
                    setOpen(true);
                })
        } else {
            // console.log("token:" + api_key);
            setOpen(true);
        }
    }


    const tryLogin = () => {
        setDisable(true);
        const params = {
            email_address: thisState.user.email_address,
            password: password,
        };

        HttpFunction("POST", "/api/password_login", params, "")
            .then((data) => {
                // console.log(data);
                dispatch(login_set_value(["error_message", data.error_message]));
                if (data.result === "OK") {
                    setUserInfo(data.user_token);
                } else if (data.result === "NG") {
                    setDisable(false);
                } else if (data === 500) {
                    setOpen500(true);
                }
            })
            .catch((err) => {
                // console.log(err);
                if(err == 'TypeError: Failed to fetch') {
                    // console.log("ネットワークエラー");
                } else {
                    // console.log("ネットワークエラーでない");
                }
                setOpen500(true);
            });
    };

    const handleClose500 = () => {
        setOpen500(false);
        setDisable(false);
    };

    const handleForgetPass = () => {
        dispatch(user_info_set(["email_address", thisState.user.email_address]));
        dispatch(user_info_set(["forget_password", true]));
        dispatch(user_info_set(["situation", "password"]));
        dispatch(user_info_set(["fix_type", "email"]));
        history.push("/ImportantChangeMember");
    };

    const dialogClose = () => {
        setOpen(false);
        setDisable(false);
    }

    const retry = () => {
        dispatch(login_set_value(["password_flag", false]));
        dispatch(login_set_value(["email_flag", true]));
    }

    return (
        <>
            {thisState.error_message && (
                <Alert severity="error">
                    <AlertTitle>エラー</AlertTitle>
                    {thisState.error_message}
                </Alert>
            )}
            <TextFieldValidation
                label="パスワード"
                id="password"
                type="password"
                defaultValue={password}
                autoFocus
                onBlur={(e) => setPassword(e.target.value)}
            />
            <div className="text-right">
                <ButtonContainedPrimary
                    disabled={disable}
                    onClick={tryLogin}
                >
                    ログイン
                </ButtonContainedPrimary>
            </div>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <ButtonTextPrimary onClick={handleForgetPass}>
                    パスワードをお忘れの方
                </ButtonTextPrimary>
            </Grid>
            <ErrorDialog open={open} >
                <p>
                    ログイン処理中にエラーが発生しました。再度お試しください。
                </p>
                <p>
                    また複数回発生する場合はお手数ですが、メールアドレス入力からやり直してください。
                </p>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Button
                        variant="contained"
                        className={classes.button}
                        onClick={retry}
                    >
                        最初からやり直す
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.button}
                        onClick={dialogClose}
                    >
                        OK
                    </Button>
                </Grid>
            </ErrorDialog>
            <Error500Handling open={open500} onClose={handleClose500} />
        </>
    );
};

const LoginMembers = () => {
    const thisState = useSelector((state) => state.Login);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!thisState.back_flag) dispatch(initialize());
        dispatch(login_set_value(["error_message", ""]));
        dispatch(login_set_value(["back_flag", false]));
        dispatch(login_set_value(["login_mode", "user"]));
        dispatch(set_value(["showDrawer", false]));
        dispatch(set_value(["showBottomNavigation", false]));
    }, []);

    return (
        <>
            <div style={{padding: "15px 0 0 15px"}}>
                <StopButton />
            </div>
            <div style={{padding: "0 30px 0"}}>
                <h2>ログイン</h2>
                {thisState.email_flag && <MailInput />}
                {thisState.onetime_flag && <OneTimeInput />}
                {thisState.password_flag && <PasswordInput />}
            </div>
        </>
    );
};

export default LoginMembers;
