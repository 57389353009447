import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {shopuser_info_set, initialize} from "../../stores/ShopUserInfoChange";
import HttpFunction from "../utils/HttpFunction";
import { Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { TextFieldNormal } from "../utils/TextFields";
import { ButtonContainedPrimary } from "../utils/Buttons";
import { useHistory } from "react-router";
import ErrorDialog from "../utils/ErrorDialog";
import Button from "@material-ui/core/Button";
import TokenErrorDialog from "../utils/TokenErrorDialog";
import { HideFooter, ShowFooter } from "../modules/BottomNavigation";

const useStyles = makeStyles({
    Input: {
        marginTop: "1rem",
    },
    button: {
        display: "block",
        margin: "auto",
    },
});

const ShopUserInfoChange = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const informationState = useSelector((state) => state.Information);
    const thisState = useSelector((state) => state.ShopUserInfoChange);
    const [nickError, setNickError] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openFailedLogin, setOpenFailedLogin] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("エラーが発生しました。ユーザー情報表示画面へ戻ります。");

    useEffect(() =>{
        dispatch(shopuser_info_set(["shop_user_name", informationState.shop.shop_user_name]));
        dispatch(shopuser_info_set(["shop_updated_at", informationState.shop.shop_updated_at]));
    },[])

    const InputChange = (e) => {
        // console.log("e.currentTarget.name:"+e.currentTarget.name);
        if(e.currentTarget.name === "nickname") {
            // console.log("e.currentTarget.value:"+e.currentTarget.value);
            dispatch(shopuser_info_set(["shop_user_name", e.currentTarget.value]));
            if(!thisState.shop_user_name) {
                setNickError(false);
            }
        }
        ShowFooter(); 
    };

    const OnBack = () => {
        dispatch(initialize());
        history.push("/ShopUserInformation");
    }

    const OnSbubmit = () => {
        // console.log("henkousuru!");
        if(!thisState.shop_user_name) {
            setNickError(true);
            return;
        }
        const api_key = informationState.shop.shop_token;
        // console.log("nickname:"+thisState.shop_user_name);
        const params = {
            nickname:thisState.shop_user_name,
            updated_at:thisState.shop_updated_at
        }
        if(!api_key) {
            setOpenFailedLogin(true);
        }
        HttpFunction("PUT", "/api/shop_user_information", params, api_key)
            .then((data) =>{
                // console.log(data);
                if(!data.error_message) {
                    OnBack();
                } else if (data.error_message.startsWith("ログイン情報の取得ができませんでした")) {
                    setOpenFailedLogin(true);
                } else {
                    setErrorMessage(data.error_message+"プロフィール表示画面へ戻ります。");
                    setOpen(true);
                }
            })
            .catch((err) => {
                // console.log(JSON.stringify(err));
                setErrorMessage("情報の更新に失敗しました。プロフィール表示画面へ戻ります。");
                setOpen(true);
            })
    }
    
    return(
        <React.Fragment>
            <Container fixed>
                <h2>ユーザー情報の変更</h2>
                <TextFieldNormal
                    className={classes.Input}
                    label="名字（役職）"
                    name="nickname"
                    error={nickError}
                    helperText={nickError ? "入力してください" : ""}
                    onFocus={HideFooter}
                    onBlur={InputChange}
                    defaultValue={informationState.shop.shop_user_name}
                    inputProps={{maxLength: 255}}
                />
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginTop: "1rem" }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={OnBack}
                    >
                        戻る
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={OnSbubmit}
                    >
                        変更する
                    </Button>
                </Grid>
            </Container>
            <ErrorDialog open={open}>
                <p>
                    {errorMessage}
                </p>
                <Button
                    variant="contained"
                    className={classes.button}
                    onClick={OnBack}
                >
                    OK
                </Button>
            </ErrorDialog>
            <TokenErrorDialog open={openFailedLogin} />
        </React.Fragment>
    );
}

export default ShopUserInfoChange;