import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { DataGrid } from "@material-ui/data-grid";
import { GetBlogList, PostBlog, DeleteBlog } from "~stores/AdminBlogSetting";

import ReactImageBase64 from "react-image-base64";
import {
    FormatTime,
    ParseTime,
    FormatOpeningHours,
} from "~/functions/DateTimeFunctions";

const AdminBlogSetting = () => {
    const dispatch = useDispatch();
    const BlogSettingState = useSelector((state) => state.AdminBlogSetting);
    const [add_flag, set_add_flag] = useState(false);
    //const [shop_id, set_shop_id] = useState("");
    const [blog_image, set_blog_image] = useState("");

    const HandleImageClick = (data, name) => {
        set_blog_image(data.fileData);
    };
    useEffect(() => {
        dispatch(GetBlogList());
    }, []);

    const HandleDeleteBlog = (row) => {
        const params = {
            page: BlogSettingState.currentPage,
            //shop_id: shop_id,
            blog_id: row.id,
        };
        dispatch(DeleteBlog(params));
    };

    const columns = [
        { field: "id", headerName: "ID", width: 60 },
        //{ field: "shop_id", headerName: "shop_id", width: 120 },
        {
            field: "image",
            headerName: "image",
            width: 100,
            renderCell: (params) => (
                <>
                    <img src={params.row.image_path} height="50px" />
                </>
            ),
        },
        {
            field: "title",
            headerName: "タイトル",
            flex: true,
        },
        {
            field: "text",
            headerName: "本文",
            flex: true,
        },
        {
            field: "updated_at",
            headerName: "投稿日時",
            width: 180,
            valueGetter: (params) =>
                FormatTime(ParseTime(params.row.updated_at), "jp_date_time"),
        },

        {
            field: "",
            headerName: "",
            width: 100,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => HandleDeleteBlog(params.row)}
                >
                    削除
                </Button>
            ),
        },
    ];

    const HandleCreate = (e) => {
        e.preventDefault();
        const elem = e.target.elements;
        const params = {
            blog_image: blog_image,
            page: BlogSettingState.currentPage,
            create_data: {
                text: elem?.text?.value,
                title: elem?.title?.value,
                //shop_id: elem?.shop_id?.value,
            },
        };

        dispatch(PostBlog(params)).then((data) => {
            if (data.payload.result != "OK") return;
            document.create_blog_form.reset();
            set_blog_image("");
        });
    };

    const onPageChange = (page) => {
        const params = {
            page: page + 1,
            //shop_id: shop_id,
        };
        dispatch(GetBlogList(params));
    };

    return (
        <React.Fragment>
            <ButtonNavi left_label="Home画面" left_link="/AdminHome" />
            <h1>ブログ管理</h1>
            {add_flag ? (
                <>
                    <form
                        onSubmit={HandleCreate}
                        autoComplete="off"
                        name="create_blog_form"
                        key="1"
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                                border: "solid 1px",
                                padding: "2rem",
                                marginBottom: "2rem",
                                margin: "2%",
                                width: "96%",
                            }}
                        >
                            {/* <TextField
                                style={{ marginBottom: "1rem" }}
                                fullWidth
                                label="店舗ID"
                                required
                                name="shop_id"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ maxLength: 8 }}
                                placeholder="S0000000"
                            ></TextField> */}
                            <TextField
                                style={{ marginBottom: "1rem" }}
                                fullWidth
                                label="タイトル"
                                required
                                name="title"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            ></TextField>

                            <ImageInput
                                image={blog_image}
                                HandleImageClick={HandleImageClick}
                                name="blog_image"
                            />
                            <TextField
                                style={{ marginBottom: "1rem" }}
                                fullWidth
                                label="本文"
                                required
                                multiline
                                minRows={5}
                                name="text"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            ></TextField>
                            <Button variant="outlined" type="submit">
                                投稿
                            </Button>
                        </Grid>
                    </form>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Button onClick={() => set_add_flag(false)}>
                            閉じる
                        </Button>
                    </Grid>
                </>
            ) : (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Button
                        color="primary"
                        variant="contained"
                        style={{ padding: "0.5rem 1rem" }}
                        onClick={() => set_add_flag(true)}
                    >
                        追加
                    </Button>
                </Grid>
            )}
            {/* <Grid style={{ padding: "0rem 2rem" }}>
                <TextField
                    label="店舗ID"
                    value={shop_id}
                    onChange={(e) => {
                        set_shop_id(e.target.value);
                    }}
                    onBlur={(e) => {
                        const params = {
                            shop_id: e.target.value,
                        };
                        dispatch(GetBlogList(params));
                    }}
                    placeholder="S0000000"
                    inputProps={{ maxLength: 8 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                ></TextField>
            </Grid> */}
            <Container>
                <DataGrid
                    autoHeight
                    rows={BlogSettingState?.blog_list}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    // checkboxSelection
                    disableSelectionOnClick
                    onPageChange={onPageChange}
                    paginationMode="server"
                    page={BlogSettingState.currentPage - 1}
                    rowCount={BlogSettingState.total}
                />
            </Container>
        </React.Fragment>
    );
};

const ImageInput = (props) => {
    const { image, HandleImageClick, name } = props;

    return (
        <Grid
            direction="column"
            justifyContent="center"
            alignItems="center"
            container
        >
            <label
                htmlFor={`image_upload_${name}`}
                style={{ marginBottom: "0" }}
            >
                <img
                    src={image ? image : "null"}
                    height="300px"
                    style={{ margin: "1rem" }}
                    onError={(e) => {
                        e.target.onerror = "null";
                        e.target.src = "/images/upload.png";
                    }}
                />
            </label>
            {/* <Input type="file" id="image_upload" name="image" onChange={HandleChange} hidden /> */}
            {/* <img src={images?.fileData} /> */}
            <div style={{ display: "none" }}>
                <ReactImageBase64
                    maxFileSize={10485760}
                    thumbnail_size={500}
                    drop={false}
                    hidden={true}
                    // capture="environment"
                    multiple={true}
                    id={`image_upload_${name}`}
                    name={name}
                    handleChange={(data) => HandleImageClick(data, name)}
                />
            </div>
        </Grid>
    );
};

export default AdminBlogSetting;
