import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { ButtonContainedPrimary } from "../utils/Buttons";
import HistoryTable from "../modules/HistoryTable";
import { useDispatch, useSelector } from "react-redux";
import {
    set_memberlist_value,
    initialize,
    GetMemberList,
} from "../../stores/AdminMemberList";
import { set_table_value, initialize_table } from "../../stores/HistoryTable";
import ModalGeneral from "../utils/ModalGeneral";
import { TextFieldNormal } from "../utils/TextFields";
import TextField from "@material-ui/core/TextField";
import SelectPrefectures from "../utils/SelectPrefectures";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import TokenErrorDialog from "../utils/TokenErrorDialog";
import MemberEditingDialog from "../modules/MemberEditingDialog";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { ButtonNavi } from "~CJS/block/ButtonNavi";

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        paddingLeft: "40px",
        paddingRight: "40px",
    },
    modalTitle: {
        fontSize: "1.8rem",
        color: theme.palette.primary.main,
        textAlign: "center",
    },
    select: {
        "& .MuiFormLabel-root.Mui-disabled": {
            color: "inherit",
        },
    },
    label: {
        fontSize: "16px",
        marginBottom: 0,
    },
    condition: {
        marginBottom: "15px",
    },
    searchButton: {
        display: "block",
        margin: "auto",
    },
}));

const SearchModal = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.AdminMemberList);
    const classes = useStyles();
    const [condition, setCondition] = React.useState({
        user_id: thisState.user_id,
        email_address: thisState.email_address,
        prefectures: thisState.prefectures,
        all_prefectures: thisState.all_prefectures,
        user_rank_type: thisState.user_rank_type,
        all_ranks: thisState.all_ranks,
        user_type: thisState.user_type,
        order: thisState.order,
        sort: thisState.sort,
        talent_flag: thisState.talent_flag,
    });

    const handleCloseSearchModal = () => {
        dispatch(set_memberlist_value(["open_search_dialog", false]));
    };
    const handleInput = (e) => {
        setCondition({ ...condition, [e.target.name]: e.target.value });
    };
    const handleChangeCheck = (e) => {
        setCondition({ ...condition, [e.target.name]: e.target.checked });
    };

    const handleClickSearch = () => {
        dispatch(set_memberlist_value(["user_id", condition.user_id]));
        dispatch(
            set_memberlist_value(["email_address", condition.email_address])
        );
        dispatch(set_memberlist_value(["prefectures", condition.prefectures]));
        dispatch(
            set_memberlist_value(["all_prefectures", condition.all_prefectures])
        );
        dispatch(
            set_memberlist_value(["user_rank_type", condition.user_rank_type])
        );
        dispatch(set_memberlist_value(["all_ranks", condition.all_ranks]));
        dispatch(set_memberlist_value(["user_type", condition.user_type]));
        dispatch(set_memberlist_value(["order", condition.order]));
        dispatch(set_memberlist_value(["sort", condition.sort]));
        dispatch(set_memberlist_value(["talent_flag", condition.talent_flag]));
        dispatch(set_table_value(["page", 1]));
        dispatch(
            set_memberlist_value(["search_triger", thisState.search_triger + 1])
        );
        dispatch(set_memberlist_value(["open_search_dialog", false]));
    };

    return (
        <ModalGeneral open onClose={handleCloseSearchModal}>
            <Container className={classes.modalContainer}>
                <p className={classes.modalTitle}>条件設定</p>
                <TextFieldNormal
                    label="ユーザーID検索"
                    discription="検索したいIDを完全一致で入力してください。"
                    name="user_id"
                    defaultValue={condition.user_id}
                    onBlur={handleInput}
                    inputProps={{ maxLength: 8 }}
                />
                <TextFieldNormal
                    label="メールアドレス検索"
                    discription="検索したいメールアドレスを完全一致で入力してください。"
                    name="email_address"
                    defaultValue={condition.email_address}
                    onBlur={handleInput}
                    inputProps={{ maxLength: 255 }}
                />
                <div className={classes.condition}>
                    <SelectPrefectures
                        disabled={condition.all_prefectures}
                        className={classes.select}
                        value={condition.prefectures}
                        onChange={handleInput}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="all_prefectures"
                                checked={condition.all_prefectures}
                                onChange={handleChangeCheck}
                            />
                        }
                        label="すべて"
                    />
                </div>
                <div className={classes.condition}>
                    <TextField
                        label="会員ランク"
                        select
                        name="user_rank_type"
                        disabled={condition.all_ranks}
                        defaultValue={condition.user_rank_type}
                        className={classes.select}
                        fullWidth
                        SelectProps={{ native: true }}
                        onChange={handleInput}
                    >
                        <option key="1" value="1">
                            ピンク
                        </option>
                        <option key="2" value="2">
                            ブルー
                        </option>
                        <option key="3" value="3">
                            グリーン
                        </option>
                        <option key="4" value="4">
                            イエロー
                        </option>
                        <option key="5" value="5">
                            ホワイト
                        </option>
                    </TextField>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="all_ranks"
                                checked={condition.all_ranks}
                                onChange={handleChangeCheck}
                            />
                        }
                        label="すべて"
                    />
                </div>
                <div className={classes.condition}>
                    <p className={classes.label}>会員区分</p>
                    <RadioGroup
                        name="user_type"
                        value={condition.user_type}
                        onChange={handleInput}
                    >
                        <Grid container>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="有効ユーザー"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    value="0"
                                    control={<Radio />}
                                    label="退会ユーザー"
                                />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </div>
                <FormControlLabel
                    control={
                        <Checkbox
                            name="talent_flag"
                            checked={condition?.talent_flag ? true : false}
                            onChange={handleChangeCheck}
                        />
                    }
                    label="タレントのみ"
                />
                <Divider className={classes.condition} />
                <Grid container className={classes.condition}>
                    <Grid item>
                        <p
                            className={classes.label}
                            style={{ padding: "5px 24px 8px 0" }}
                        >
                            並び替え
                        </p>
                    </Grid>
                    <Grid item>
                        <TextField
                            select
                            name="order"
                            value={condition.order}
                            SelectProps={{ native: true }}
                            onChange={handleInput}
                        >
                            <option key="created_at" value="created_at">
                                登録日順
                            </option>
                            <option
                                key="paying_expiration_date"
                                value="paying_expiration_date"
                            >
                                課金有効期限順
                            </option>
                        </TextField>
                        <TextField
                            select
                            name="sort"
                            value={condition.sort}
                            SelectProps={{ native: true }}
                            onChange={handleInput}
                        >
                            <option key="asc" value="asc">
                                昇順
                            </option>
                            <option key="desc" value="desc">
                                降順
                            </option>
                        </TextField>
                    </Grid>
                </Grid>
                <ButtonContainedPrimary
                    className={classes.searchButton}
                    onClick={handleClickSearch}
                >
                    検索
                </ButtonContainedPrimary>
            </Container>
        </ModalGeneral>
    );
};

const AdminMemberList = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.AdminMemberList);
    const tableState = useSelector((state) => state.HistoryTable);

    // ---------一覧表内のボタン用の関数-------------
    const handleToEditing = (params) => {
        dispatch(
            set_memberlist_value(["edited_active_flag", params.row.active_flag])
        );
        dispatch(set_memberlist_value(["edited_user_id", params.id]));
        dispatch(
            set_memberlist_value([
                "edited_nickname",
                params.getValue(params.id, "nickname"),
            ])
        );
        dispatch(
            set_memberlist_value([
                "edited_email",
                params.getValue(params.id, "email_address"),
            ])
        );
        dispatch(
            set_memberlist_value([
                "edited_rank",
                params.getValue(params.id, "user_rank_color_name"),
            ])
        );
        dispatch(
            set_memberlist_value([
                "edited_point",
                params.getValue(params.id, "point_sum"),
            ])
        );
        dispatch(set_memberlist_value(["open_editing_dialog", true]));
    };
    // ---------------------------------------------

    const columns = [
        {
            field: "user_id",
            headerName: "ユーザーID",
            width: 105,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "nickname",
            headerName: "ニックネーム",
            flex: 1,
            minWidth: 120,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "email_address",
            headerName: "メールアドレス",
            width: 200,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "user_rank_color_name",
            headerName: "ランク",
            width: 90,
            align: "center",
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "prefectures",
            headerName: "都道府県",
            width: 90,
            align: "center",
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "raw_year_month",
            headerName: "生年月",
            width: 80,
            align: "center",
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "sex",
            headerName: "性別",
            valueFormatter: (params) => {
                switch (params.value) {
                    case "1":
                        return "男性";
                    case "2":
                        return "女性";
                    case "3":
                    default:
                        return "その他";
                }
            },
            width: 70,
            align: "center",
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "created_at",
            headerName: "登録日",
            valueFormatter: (params) =>
                params.value.substr(0, 10).replace(/-/g, "/"),
            align: "center",
            width: 100,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "user_type",
            headerName: "区分",
            valueFormatter: () => {
                switch (thisState.user_type) {
                    case "1":
                        return "有効";
                    case "0":
                    default:
                        return "退会";
                }
            },
            align: "center",
            width: 70,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "talent_flag",
            headerName: "タレント",
            valueFormatter: (params) => {
                switch (params.value) {
                    case "1":
                        return "◯";
                    case "0":
                    default:
                        return "";
                }
            },
            align: "center",
            width: 100,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "paying_expiration_date",
            headerName: "課金有効期限",
            valueFormatter: (params) => {
                if (params.value === null) return "-";
                return params.value.substr(0, 10).replace(/-/g, "/");
            },
            align: "center",
            width: 120,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "edit",
            headerName: "詳細",
            renderCell: (params) => (
                <ButtonContainedPrimary onClick={() => handleToEditing(params)}>
                    詳細
                </ButtonContainedPrimary>
            ),
            align: "center",
            width: 80,
            headerAlign: "center",
            sortable: false,
        },
    ];

    const handleOpenSearchModal = () => {
        dispatch(set_memberlist_value(["open_search_dialog", true]));
    };

    const getMemberList = (page) => {
        const search_data = {
            user_id: thisState.user_id,
            email_address: thisState.email_address,
            prefectures: thisState.all_prefectures
                ? null
                : thisState.prefectures,
            user_rank_type: thisState.all_ranks
                ? null
                : thisState.user_rank_type,
            paying_active: thisState.user_type === "1" ? "1" : null,
            talent_flag: thisState.talent_flag,
        };
        const params = {
            order: thisState.order,
            sort: thisState.sort,
            page: page,
        };
        for (let condition in search_data) {
            if (search_data[condition]) {
                params[`search_data[${condition}]`] = search_data[condition];
            }
        }
        // console.log(params);

        dispatch(GetMemberList(params));
    };

    const handleClickOK = () => {
        dispatch(set_memberlist_value(["open_token_error_dialog", false]));
    };

    useEffect(() => {
        dispatch(initialize_table());
        dispatch(initialize());
    }, []);

    useEffect(() => {
        getMemberList(tableState.page);
    }, [tableState.page, thisState.search_triger]);

    return (
        <Container>
            <ButtonNavi left_label="Home画面" left_link="/AdminHome" />
            <h1>会員一覧</h1>
            {thisState.show_alert && (
                <Alert severity="error">
                    <AlertTitle>{thisState.error_title}</AlertTitle>
                    {thisState.error_message}
                </Alert>
            )}
            <Grid container>
                <Grid item style={{ margin: "10px" }}>
                    <ButtonContainedPrimary onClick={handleOpenSearchModal}>
                        検索条件変更
                    </ButtonContainedPrimary>
                </Grid>
                <Grid item style={{ margin: "10px", fontSize: "x-large" }}>
                    総会員数
                    <div
                        style={{
                            display: "inline-block",
                            fontSize: "xx-large",
                            fontWeight: "bold",
                            marginLeft: "0.5rem",
                        }}
                    >
                        {thisState.total_user}
                    </div>
                    人
                </Grid>
            </Grid>

            {thisState.open_search_dialog && <SearchModal />}
            <HistoryTable
                rows={thisState.member_list}
                columns={columns}
                recordsPerPageOptions={[tableState.per_page]}
                getRowId={(row) => row.user_id}
                needsToolbar={false}
                disableColumnMenu={true}
            />
            <TokenErrorDialog
                open={thisState.open_token_error_dialog}
                admin={true}
                onClickOK={handleClickOK}
            />
            <MemberEditingDialog />
        </Container>
    );
};

export default AdminMemberList;
